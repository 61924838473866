import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  List,
  ListItemButton,
  Typography,
  useTheme,
  IconButton,
  Divider,
  CircularProgress,
} from '@mui/material'
import { Stage, Layer, Image, Transformer } from 'react-konva'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import {
  CheckedCircleIcon,
  CircleCheckIcon,
  CloseBlackIcon,
  CloseCuteIcon,
  PlusIcon2,
  PlusMobileIcon,
  TrashCanIcon,
  TrashCanIcon2,
  TrashCanIconMobile,
  UploadIcon,
} from 'theme/icon'
import { allowedTypes, base64ToBlob, s3UriToUrl } from 'utils/common'
import { v4 as uuidv4 } from 'uuid'
import { apis } from 'apis'
import axios from 'axios'
import { MdDriveFolderUpload } from 'react-icons/md'
import { DndContext, DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { CreateButton } from './CustomStyle'
import { BarLoader, PuffLoader, SyncLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'
import { English, Korean } from 'hooks/useLanguage'

export const InpaintCanvas = ({
  bboxs,
  setBboxs,
  urls,
  setUrls,
  isOpen,
  onClose,
  isLogo,
  isInpaint,
  url,
  images,
  setImages,
  step,
  setStep,
  setSelectIds,
  customSize = { width: 1024, height: 1024 },
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const productInputRef = useRef(null)
  const logoInputRef = useRef(null)
  const [backgroundImage, setBackgroundImage] = useState(null)

  const [scale, setScale] = useState(1)

  const [loadings, setLoadings] = useState({
    product: false,
    logo: false,
    upload: false,
    url: false,
  })

  const stageRef = useRef(null)
  const bgImageRef = useRef(null)
  const trRef = useRef(null)

  const popupWidth = 1280
  const popupHeight = 808

  const canvasW = 896
  const canvasH = 748

  const overlayImageDefaultW = 300
  const overlayImageDefaultH = 300

  // useEffect(() => {
  //   console.log('--------------')
  //   console.log('images', images)
  //   console.log('--------------')
  // }, [images])

  const addImage = (type, imageData) => {
    const newImage = {
      id: uuidv4(),
      type,
      ...imageData,
    }
    setImages(prevImages => [...prevImages, newImage])
  }
  const resetAll = () => {
    // setImages([])

    trRef.current.nodes([])
    trRef.current.getLayer().batchDraw()
  }

  const updateImage = (id, updates) => {
    setImages(prevImages => prevImages.map(img => (img.id === id ? { ...img, ...updates } : img)))
  }

  const removeImage = id => {
    setImages(prevImages => prevImages.filter(img => img.id !== id))

    // Remove Transformer if the deleted image was selected
    if (trRef.current) {
      const selectedNode = trRef.current.nodes()[0]
      if (selectedNode && selectedNode.id() === id) {
        trRef.current.nodes([])
        trRef.current.getLayer().batchDraw()
      }
    }
  }

  const handleImageUpload = (file, type) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = e => {
        const img = new window.Image()
        img.src = e.target.result
        img.onload = () => {
          const scale = Math.min(
            overlayImageDefaultW / img.width,
            overlayImageDefaultH / img.height
          )
          const newImage = {
            id: uuidv4(),
            type,
            src: e.target.result,
            name: file.name,
            file,
            image: img,
            width: img.width * scale,
            height: img.height * scale,
            x: (canvasW - img.width * scale) / 2,
            y: (canvasH - img.height * scale) / 2,
          }

          setImages(prevImages => {
            const existingIndex = prevImages.findIndex(img => img.type === type)
            if (existingIndex !== -1) {
              const updatedImages = [
                ...prevImages.slice(0, existingIndex),
                newImage,
                ...prevImages.slice(existingIndex + 1),
              ]
              return [
                ...updatedImages.filter(img => img.type !== 'logo'),
                ...updatedImages.filter(img => img.type === 'logo'),
              ]
            } else {
              const updatedImages = [...prevImages, newImage]
              return [
                ...updatedImages.filter(img => img.type !== 'logo'),
                ...updatedImages.filter(img => img.type === 'logo'),
              ]
            }
          })
        }
      }
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    setLoadings(p => ({ ...p, url: true }))
    if (url) {
      const img = new window.Image()
      img.src = url
      img.onload = () => {
        const scale = Math.min(canvasW / img.width, canvasH / img.height)
        setScale(scale)
        setBackgroundImage({
          image: img,
          x: (canvasW - img.width * scale) / 2,
          y: (canvasH - img.height * scale) / 2,
          width: img.width * scale,
          height: img.height * scale,
        })
        setLoadings(p => ({ ...p, url: false }))
      }
    } else {
      const scale = Math.min(canvasW / customSize.width, canvasH / customSize.height)
      setScale(scale)
      setBackgroundImage({
        fill: '#eeeeee',
        x: (canvasW - customSize.width * scale) / 2,
        y: (canvasH - customSize.height * scale) / 2,
        width: customSize.width * scale,
        height: customSize.height * scale,
      })
    }
  }, [url])

  const handleTransformEnd = id => {
    const node = trRef.current.nodes()[0]

    if (node) {
      const scaleX = node.scaleX()
      const scaleY = node.scaleY()

      node.scaleX(1)
      node.scaleY(1)

      const newWidth = Math.max(50, node.width() * scaleX)
      const newHeight = Math.max(50, node.height() * scaleY)

      updateImage(id, {
        width: newWidth,
        height: newHeight,
      })
    }
  }

  const constrainPosition = (newPos, shapeWidth, shapeHeight) => {
    if (!backgroundImage) return newPos

    return {
      x: Math.max(
        backgroundImage.x,
        Math.min(newPos.x, backgroundImage.x + backgroundImage.width - shapeWidth)
      ),
      y: Math.max(
        backgroundImage.y,
        Math.min(newPos.y, backgroundImage.y + backgroundImage.height - shapeHeight)
      ),
    }
  }

  const handleDragMove = (e, id) => {
    const img = images.find(img => img.id === id)
    if (img) {
      const newPos = constrainPosition({ x: e.target.x(), y: e.target.y() }, img.width, img.height)
      e.target.position(newPos)
    }
  }

  const handleDragEnd = (e, id) => {
    const img = images.find(img => img.id === id)
    if (img) {
      const newPos = constrainPosition({ x: e.target.x(), y: e.target.y() }, img.width, img.height)
      updateImage(id, {
        x: newPos.x,
        y: newPos.y,
      })
    }
  }

  const calculateNode = async () => {
    setLoadings(p => ({ ...p, upload: true }))

    const backgroundNode = bgImageRef.current
    const imageNodes = images.map(img => ({
      ...img,
      node: stageRef.current.findOne(`#${img.id}`),
    }))

    const bgPos = backgroundNode.getClientRect()

    imageNodes.forEach(img => {
      const imagePos = img.node.getClientRect()

      setBboxs(p => {
        return {
          ...p,
          [img.type]: [
            Math.round((imagePos.x - bgPos.x) / scale),
            Math.round((imagePos.y - bgPos.y) / scale),
            Math.round((imagePos.x + imagePos.width - bgPos.x) / scale),
            Math.round((imagePos.y + imagePos.height - bgPos.y) / scale),
          ],
        }
      })
    })

    for (const img of images) {
      const fileType = img.file.type.split('/')[1]
      const presignedData = (await apis.data.presignedURL(fileType)).data

      console.log(img)

      try {
        await axios.put(presignedData.presigned_url, img.file, {
          headers: {
            'Content-Type': img.file.type,
          },
        })
        console.log('File uploaded successfully:', img.name)
      } catch (error) {
        console.error('Error uploading file:', error)
      }

      const s3Url = s3UriToUrl(presignedData.s3_file_path)

      setUrls(p => {
        return { ...p, [img.type]: s3Url }
      })
    }

    setLoadings(p => ({ ...p, upload: false }))
    setStep(p => p + 1)
    onClose(resetAll)
  }

  // useEffect(() => {
  //   console.log(bboxs)
  //   console.log(urls)
  //   console.log(scale)
  // }, [bboxs, urls])

  // 드래그 애니메이션을 위한
  const [isDragging, setIsDragging] = useState({ logo: false, product: false })

  const handleDragOver = (event, type) => {
    event.preventDefault()
    setIsDragging(prev => ({ ...prev, [type]: true }))
  }

  const handleDragLeave = (event, type) => {
    event.preventDefault()
    setIsDragging(prev => ({ ...prev, [type]: false }))
  }

  const handleDrop = (event, type) => {
    event.preventDefault()
    setIsDragging(prev => ({ ...prev, [type]: false }))
    const file = event.dataTransfer.files[0]
    if (file && allowedTypes.includes(file.type)) {
      handleImageUpload(file, type)
    }
  }

  // 드래그해서 삭제하기 위한
  const [activeId, setActiveId] = useState(null)

  const deleteDragStart = event => {
    setActiveId(event.active.id)
  }

  const deleteDragEnd = event => {
    setActiveId(null)

    if (event.over && event.over.id === 'trash') {
      removeImage(event.active.id)
    }
  }

  const logoImageInfo = images.find(img => img.type === 'logo')
  const productImageInfo = images.find(img => img.type === 'product')

  const productUploadHandler = async e => {
    const name = e.target.name
    setLoadings(p => ({ ...p, [name]: true }))
    const formData = new FormData()

    formData.append('image', e.target.files[0])
    const sodImage = await apis.appfront.getSod(formData)
    const blobImage = base64ToBlob(JSON.parse(sodImage.data.sod_res.body), 'image/png')

    handleImageUpload(blobImage, name)
    setLoadings(p => ({ ...p, [name]: false }))
  }

  const logoUploadHandler = async e => {
    const name = e.target.name
    setLoadings(p => ({ ...p, [name]: true }))
    const formData = new FormData()

    formData.append('logo_image', e.target.files[0])
    const sodImage = await apis.appfront.getCroppedLogoSod(formData)
    console.log(sodImage)
    const blobImage = base64ToBlob(sodImage.data.sod_result, 'image/png')

    handleImageUpload(blobImage, name)
    setLoadings(p => ({ ...p, [name]: false }))
  }

  const dragHandler = async (file, type) => {
    setLoadings(p => ({ ...p, [type]: true }))
    const formData = new FormData()

    formData.append('image', file)
    const sodImage = await apis.appfront.getSod(formData)
    const blobImage = base64ToBlob(JSON.parse(sodImage.data.sod_res.body), 'image/png')

    handleImageUpload(blobImage, type)
    setLoadings(p => ({ ...p, [type]: false }))
  }
  if (!backgroundImage) return
  return (
    <DndContext onDragStart={deleteDragStart} onDragEnd={deleteDragEnd}>
      <Dialog
        open={isOpen}
        onClose={(e, r) => {
          if (r === 'backdropClick' || r === 'escapeKeyDown') return

          onClose(resetAll)
        }}
        sx={{
          overflow: 'auto',
          '& .MuiDialog-paper': {
            width: popupWidth,
            height: popupHeight,
            maxHeight: 'none',
            maxWidth: 'none',
          },
        }}
      >
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
          <Stack
            sx={{
              width: '38.4rem',
              position: 'relative',
              // overflow: 'hidden',
              p: '1.6rem 2rem',
              gap: '1.6rem',
              '& .logo-image': {
                width: '2.6rem',
                mr: '0.8rem',
              },

              '& .logo-text': {
                fontSize: '2rem',
                fontWeight: 700,
                lineHeight: 'normal',
              },

              '& .upload-box': {
                width: '100%',

                justifyContent: 'center',
                alignItems: 'center',
                gap: '1.2rem',
                backgroundColor: '#eeeeee',
                borderRadius: '15px',
                '& svg': {
                  transition: 'all 0.3s ease',
                },

                ' & .text': {
                  fontSize: '1.6rem',
                  fontWeight: 600,
                  color: '#808080',
                },
                ' &.logo': {
                  py: '3.6rem ',
                  minHeight: '12.8rem',
                  height: '12.8rem',
                },

                ' &.product': {
                  py: '3.6rem ',
                  minHeight: '36.4rem',
                  height: '36.4rem',
                },
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <img className="logo-image" src="/static/images/logo/logo_image.png" />
              <Typography className="logo-text">Draph Editor</Typography>
            </Box>

            {isLogo &&
              (logoImageInfo ? (
                <Stack
                  className={`upload-box logo`}
                  sx={{ p: '1.6rem !important', position: 'relative' }}
                >
                  <DraggableImage
                    id={logoImageInfo.id}
                    src={logoImageInfo.src}
                    type="logo"
                    removeImage={removeImage}
                  />
                </Stack>
              ) : (
                <AnimatedUploadBox
                  type={'logo'}
                  onDrop={(file, type) => handleImageUpload(file, type)}
                  onClick={() => {
                    logoInputRef.current.click()
                  }}
                  loadings={loadings}
                />
              ))}

            {isInpaint &&
              (productImageInfo ? (
                <Stack
                  className={`upload-box product`}
                  sx={{ p: '1.6rem !important', position: 'relative' }}
                >
                  <Box sx={{ height: '100%' }}>
                    <DraggableImage
                      id={productImageInfo.id}
                      src={productImageInfo.src}
                      type="product"
                      removeImage={removeImage}
                    />
                  </Box>
                </Stack>
              ) : (
                <AnimatedUploadBox
                  type={'product'}
                  onDrop={dragHandler}
                  onClick={() => {
                    productInputRef.current.click()
                  }}
                  loadings={loadings}
                />
              ))}

            <TrashZone removeImage={removeImage} />

            {/* <DragOverlay>
              {activeId ? (
                <img
                  src={images.find(img => img.id === activeId).src}
                  style={{ width: 100, height: 100, objectFit: 'contain' }}
                />
              ) : null}
            </DragOverlay> */}

            {/* <Stack
            className="upload-box logo"
            onClick={() => logoInputRef.current.click()}
            onDragOver={handleDragOver}
            onDrop={e => handleDrop(e, 'logo')}
          >
            <>
              <PlusIcon2 />
              <Typography className="text">로고 이미지를 업로드해주세요</Typography>
            </>
          </Stack>

          <Stack
            className="upload-box product"
            onClick={() => productInputRef.current.click()}
            onDragOver={handleDragOver}
            onDrop={e => handleDrop(e, 'product')}
          >
            <PlusIcon2 />
            <Typography className="text">상품 이미지를 업로드해주세요</Typography>
          </Stack> */}

            <input
              type="file"
              ref={productInputRef}
              style={{ display: 'none' }}
              accept={allowedTypes.join(', ')}
              name="product"
              onChange={productUploadHandler}
            />

            <input
              type="file"
              ref={logoInputRef}
              style={{ display: 'none' }}
              accept={allowedTypes.join(', ')}
              name="logo"
              onChange={logoUploadHandler}
            />

            {/* {images.map(img => (
            <Box
              key={img.id}
              sx={{
                position: 'relative',
                width: '100%',
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                m: '0.7rem',
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <img
                  src={img.src}
                  alt={img.name}
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
                <IconButton
                  onClick={() => removeImage(img.id)}
                  sx={{
                    position: 'absolute',
                    top: -4,
                    right: -6,
                    minWidth: '20px',
                    width: '20px',
                    height: '20px',
                    p: 0,
                  }}
                >
                  <CloseBlackIcon />
                </IconButton>
              </Box>

              <Typography
                variant="caption"
                sx={{
                  display: 'block',
                  width: '26rem',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  ml: '0.6rem',
                }}
              >
                {img.name}
              </Typography>
            </Box>
          ))} */}
            <CreateButton
              onClick={calculateNode}
              sx={{ borderRadius: '30px', height: '4.8rem' }}
              disabled={
                loadings.upload ||
                (isInpaint && isLogo ? images.length < 2 : images.length < 1) ||
                (!isInpaint && !isLogo)
              }
            >
              {loadings.upload ? (
                <BarLoader color="#808080" size={8} />
              ) : (
                <CenterAlignBox className="content" sx={{ alignItems: 'center' }}>
                  <CircleCheckIcon color={theme.palette.common.white} />
                  <Typography
                    sx={{
                      ml: '0.8rem',
                      fontSize: '2.4rem',
                      fontWeight: 600,
                      color: theme => theme.palette.common.white,
                    }}
                  >
                    {t('button.confirm')}
                  </Typography>
                </CenterAlignBox>
              )}
            </CreateButton>
          </Stack>
          <Divider flexItem orientation="vertical" />
          <Stack sx={{ flexGrow: 1, justifyContent: 'start', alignItems: 'center' }}>
            <Box
              sx={{
                flex: 1,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '1.8rem',
                fontWeight: 600,
                p: '1.2rem 1.8rem',
              }}
            >
              <Korean>제품 영역 지정</Korean>
              <English>Product location</English>

              <Box className="close">
                <IconButton
                  sx={{ p: '0.8rem' }}
                  onClick={() => {
                    setSelectIds([])
                    onClose(resetAll)
                  }}
                >
                  <CloseCuteIcon />
                </IconButton>
              </Box>
            </Box>

            {loadings.url ? (
              <Box
                width={canvasW}
                height={canvasH}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <CircularProgress color="inherit" size={80} thickness={2} />
              </Box>
            ) : (
              <Stage
                width={canvasW}
                height={canvasH}
                ref={stageRef}
                style={{ backgroundColor: '#EEEEEE' }}
              >
                <Layer>
                  {backgroundImage?.image ? (
                    <Image
                      image={backgroundImage.image}
                      width={backgroundImage.width}
                      height={backgroundImage.height}
                      x={(canvasW - backgroundImage.width) / 2}
                      y={(canvasH - backgroundImage.height) / 2}
                      ref={bgImageRef}
                      opacity={0.6}
                    />
                  ) : (
                    <Image
                      fill={backgroundImage.fill}
                      width={backgroundImage.width}
                      height={backgroundImage.height}
                      x={(canvasW - backgroundImage.width) / 2}
                      y={(canvasH - backgroundImage.height) / 2}
                      ref={bgImageRef}
                      opacity={0.6}
                    />
                  )}
                  {images.map(img => (
                    <Image
                      key={img.id}
                      id={img.id}
                      image={img.image}
                      width={img.width}
                      height={img.height}
                      x={img.x}
                      y={img.y}
                      draggable
                      onDragMove={e => handleDragMove(e, img.id)}
                      onDragEnd={e => handleDragEnd(e, img.id)}
                      onClick={() => {
                        if (trRef.current) {
                          trRef.current.nodes([stageRef.current.findOne(`#${img.id}`)])
                          trRef.current.getLayer().batchDraw()
                        }
                      }}
                      opacity={1}
                    />
                  ))}
                  <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    rotateEnabled={false}
                    onTransformEnd={() => {
                      const node = trRef.current.nodes()[0]
                      if (node) {
                        handleTransformEnd(node.id())
                      }
                    }}
                    keepRatio={true}
                    enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                    boundBoxFunc={(oldBox, newBox) => {
                      if (newBox.width < 50 || newBox.height < 50) {
                        return oldBox
                      }

                      const bgImageNode = bgImageRef.current
                      const bgPos = bgImageNode.getClientRect()

                      const aspectRatio = oldBox.width / oldBox.height

                      if (newBox.width / newBox.height !== aspectRatio) {
                        newBox.height = newBox.width / aspectRatio
                      }

                      newBox.x = Math.max(
                        bgPos.x,
                        Math.min(newBox.x, bgPos.x + bgPos.width - newBox.width)
                      )
                      newBox.y = Math.max(
                        bgPos.y,
                        Math.min(newBox.y, bgPos.y + bgPos.height - newBox.height)
                      )

                      if (newBox.width > bgPos.width) {
                        newBox.width = bgPos.width
                        newBox.height = newBox.width / aspectRatio
                      }
                      if (newBox.height > bgPos.height) {
                        newBox.height = bgPos.height
                        newBox.width = newBox.height * aspectRatio
                      }

                      return newBox
                    }}
                  />
                </Layer>
              </Stage>
            )}
          </Stack>
        </Box>
      </Dialog>
    </DndContext>
  )
}

const AnimatedUploadBox = ({ type, onDrop, onClick, loadings }) => {
  const theme = useTheme()
  const [isDragging, setIsDragging] = useState(false)

  const handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
    if (!isDragging) setIsDragging(true)
  }

  const handleDragLeave = event => {
    event.preventDefault()
    event.stopPropagation()

    setIsDragging(false)
  }

  const handleDrop = event => {
    event.preventDefault()
    setIsDragging(false)
    const file = event.dataTransfer.files[0]
    if (file) onDrop(file, type)
  }

  return (
    <Stack
      className={`upload-box ${type} ${isDragging ? 'dragging' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={onClick}
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1.2rem',
        backgroundColor: '#eeeeee',
        borderRadius: '15px',
        transition: 'all 0.3s ease',
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',

        '&.dragging': {
          // backgroundColor: '#d0d0d0',
          transform: 'scale(1.01)',
          boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        },
        '&.logo': {
          py: '3.6rem',
          minHeight: '12.8rem',
        },
        '&.product': {
          py: '3.6rem',
          minHeight: '36.4rem',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          transition: 'all 0.3s ease',
          transform: isDragging ? 'translateY(100%)' : 'translateY(0)',
          opacity: isDragging ? 0 : 1,
          pointerEvents: 'none',
        }}
      >
        {loadings[type] ? (
          <SyncLoader color={'#808080'} size={13} />
        ) : (
          <>
            <PlusIcon2 sx={{ fontSize: '2rem' }} />
            <Typography
              sx={{
                fontSize: '1.6rem',
                fontWeight: 600,
                color: '#808080',
                mt: '1.2rem',
              }}
            >
              <Korean>{`${type === 'logo' ? '로고' : '상품'} 이미지를 업로드해주세요`}</Korean>
              <English>Upload</English>
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          transition: 'all 0.3s ease',
          transform: isDragging ? 'translateY(0)' : 'translateY(-100%)',
          opacity: isDragging ? 1 : 0,
          pointerEvents: 'none',
        }}
      >
        <MdDriveFolderUpload size="2.8rem" color="#808080" />
        <Typography
          className="text"
          sx={{
            fontSize: '1.6rem',
            fontWeight: 600,
            color: '#808080',
            mt: '1.2rem',
          }}
        >
          <Korean>이미지를 놓으세요</Korean>
          <English>Drop an image</English>
        </Typography>
      </Box>
    </Stack>
  )
}

const DraggableImage = ({ id, src, type, removeImage }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
    data: { type },
  })

  const style = {
    transform: CSS.Translate.toString(transform),
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // overflow: 'hidden',
      }}
    >
      <img
        ref={setNodeRef}
        style={{
          ...style,
          width: '100%', // 변경: maxWidth에서 width로
          height: '100%', // 변경: maxHeight에서 height로
          objectFit: 'contain',
          zIndex: 1,
          cursor: 'grab',
        }}
        {...listeners}
        {...attributes}
        src={src}
      />
    </Box>
  )
}

const TrashZone = ({ removeImage }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'trash',
  })

  return (
    <Stack
      ref={setNodeRef}
      sx={{
        width: '100%',
        // height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed #808080',
        borderRadius: '15px',
        backgroundColor: isOver ? 'rgba(255, 0, 0, 0.1)' : 'transparent',
        transition: 'background-color 0.3s',
        py: '3.3rem',
      }}
    >
      <TrashCanIconMobile sx={{ width: '2.4rem' }} />
      <CenterAlignStack sx={{ mt: '0.6rem' }}>
        <Typography
          sx={{
            color: '#808080',
            // lineHeight: 'normal',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 500,
          }}
        >
          <Korean>
            삭제를 원하실 땐?
            <br /> 여기로 이미지를 드래그 앤 드랍하세요!
          </Korean>
          <English>
            When deleting,
            <br />
            Just drag and drop the image here!
          </English>
        </Typography>
      </CenterAlignStack>
    </Stack>
  )
}
