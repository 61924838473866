import { Container, Box } from '@mui/material'
import { DialogButton, ShadowButton } from 'components'
import { English, Korean } from 'hooks/useLanguage'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const serviceH3Style = {
  fontSize: 20,
  marginTop: '32px',
  marginBottom: '15px',
  marginLeft: '14px',
  fontWeight: 600,
}
const serviceH4Style = {
  marginBottom: '8px',
  marginTop: '12px',
  fontSize: 17.5,
}
const servicePStyle = { paddingLeft: '10px', fontSize: 15.5 }
const serviceOlStyle = { listStyleType: 'decimal', marginLeft: '3rem' }

const serviceOlStyleEN = {
  listStyleType: 'decimal',
  '& li': { paddingBottom: '8px', paddingTop: '8px' },
}

const privacyH3Style = {
  marginTop: '32px',
  marginBottom: '5px',
  fontWeight: 600,
}
const privacyLiStyle1 = { listStyleType: 'decimal', paddingLeft: '20px' }
const privacyLiStyle2 = { listStyleType: 'decimal', paddingLeft: '18px' }

const nestedLiStyle = { marginLeft: '1.5rem' }
export default function PrivacyEn() {
  const { version } = useParams()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const privacyVersion = {
    1: (
      <Container sx={{ py: '3rem' }}>
        <h2
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            fontWeight: 800,
          }}
        >
          AGREEMENT TO OUR LEGAL TERMS
        </h2>
        <p style={{ textAlign: 'end' }}>Last updated December 12, 2022 </p>
        <br />
        <p>
          This privacy notice for Draph, Inc (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;),
          describes how and why we might collect, store, use, and/or share (&quot;process&quot;)
          your information when you use our services (&quot;Services&quot;), such as when you:{' '}
          <br /> <br />
          Questions or concerns? Reading this privacy notice will help you understand your privacy
          rights and choices. If you do not agree with our policies and practices, please do not use
          our Services.
        </p>
        <br />
        <h3> SUMMARY OF KEY POINTS </h3>
        <p>
          This summary provides key points from our privacy notice, but you can find out more
          details about any of these topics by clicking the link following each key point or by
          using our table of contents below to find the section you are looking for.
        </p>
        <br />
        <p>
          What personal information do we process? When you visit, use, or navigate our Services, we
          may process personal information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn more about personal
          information you disclose to us.
        </p>
        <br />
        <p>
          Do we process any sensitive personal information? We do not process sensitive personal
          information.
        </p>
        <br />
        <p>
          Do we receive any information from third parties? We may receive information from public
          databases, marketing partners, social media platforms, and other outside sources. Learn
          more about information collected from other sources.
        </p>
        <br />
        <p>
          How do we process your information? We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud prevention, and to
          comply with law. We may also process your information for other purposes with your
          consent. We process your information only when we have a valid legal reason to do so.
          Learn more about how we process your information.
        </p>
        <br />
        <p>
          In what situations and with which parties do we share personal information? We may share
          information in specific situations and with specific third parties. Learn more about when
          and with whom we share your personal information.
        </p>
        <br />
        <p>
          What are your rights? Depending on where you are located geographically, the applicable
          privacy law may mean you have certain rights regarding your personal information. Learn
          more about your privacy rights.
        </p>
        <br />
        <p>
          How do you exercise your rights? The easiest way to exercise your rights is by submitting
          a data subject access request, or by contacting us. We will consider and act upon any
          request in accordance with applicable data protection laws.
        </p>
        <br />
        <p>
          Want to learn more about what we do with any information we collect? Review the privacy
          notice in full.
        </p>
        <br />
        <h2
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            fontWeight: 800,
            marginTop: '20px',
          }}
        >
          TABLE OF CONTENTS
        </h2>
        <Box component={'ol'} sx={{ ml: '3rem', ...serviceOlStyleEN }}>
          <li>WHAT INFORMATION DO WE COLLECT? </li>
          <li> HOW DO WE PROCESS YOUR INFORMATION? </li>
          <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? </li>
          <li> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </li>
          <li> HOW DO WE HANDLE YOUR SOCIAL LOGINS? </li>
          <li> IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? </li>
          <li>HOW LONG DO WE KEEP YOUR INFORMATION? </li>
          <li> DO WE COLLECT INFORMATION FROM MINORS? </li>
          <li>WHAT ARE YOUR PRIVACY RIGHTS? </li>
          <li> CONTROLS FOR DO-NOT-TRACK FEATURES </li>
          <li> DO WE MAKE UPDATES TO THIS NOTICE? </li>
          <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </li>
          <li> HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? </li>
        </Box>

        <h4 style={serviceH4Style}>1. WHAT INFORMATION DO WE COLLECT?</h4>
        <br />
        <h5 style={serviceH4Style}>Personal information you disclose to us </h5>
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> We collect personal information that you provide to us.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          We collect personal information that you voluntarily provide to us when you register on
          the Services, express an interest in obtaining information about us or our products and
          Services, when you participate in activities on the Services, or otherwise when you
          contact us.
        </p>

        <br />
        <p style={serviceOlStyleEN}>
          Sensitive Information. We do not process sensitive information.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          All personal information that you provide to us must be true, complete, and accurate, and
          you must notify us of any changes to such personal information.
        </p>
        <br />
        <h5 style={serviceH4Style}>Information automatically collected </h5>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address
          and/or browser and device characteristics — is collected automatically when you visit our
          Services.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          We automatically collect certain information when you visit, use, or navigate the
          Services. This information does not reveal your specific identity (like your name or
          contact information) but may include device and usage information, such as your IP
          address, browser and device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how and when you use our
          Services, and other technical information. This information is primarily needed to
          maintain the security and operation of our Services, and for our internal analytics and
          reporting purposes.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          Like many businesses, we also collect information through cookies and similar
          technologies.
        </p>
        <br />

        <h4 style={serviceH4Style}>2. HOW DO WE PROCESS YOUR INFORMATION? </h4>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> We process your information to provide, improve, and administer
          our Services, communicate with you, for security and fraud prevention, and to comply with
          law. We may also process your information for other purposes with your consent.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          We process your personal information for a variety of reasons, depending on how you
          interact with our Services, including:
        </p>
        <br />

        <h4 style={serviceH4Style}>
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?{' '}
        </h4>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> We may share information in specific situations described in
          this section and/or with the following third parties.
        </p>
        <br />
        <ol>
          We may need to share your personal information in the following situations:
          <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
            <strong>Business Transfers.</strong> We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to another company.
          </li>
          <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
            <strong>Affiliates.</strong> We may share your information with our affiliates, in which
            case we will require those affiliates to honor this privacy notice. Affiliates include
            our parent company and any subsidiaries, joint venture partners, or other companies that
            we control or that are under common control with us.
          </li>
          <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
            <strong>Business Partners.</strong>We may share your information with our business
            partners to offer you certain products, services, or promotions.
          </li>
        </ol>
        <br />

        <h4 style={serviceH4Style}>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> We may use cookies and other tracking technologies to collect
          and store your information.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to
          access or store information. Specific information about how we use such technologies and
          how you can refuse certain cookies is set out in our Cookie Notice.
        </p>
        <br />

        <h4 style={serviceH4Style}>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </h4>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> If you choose to register or log in to our Services using a
          social media account, we may have access to certain information about you.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          Our Services offer you the ability to register and log in using your third-party social
          media account details (like your Facebook or Twitter logins). Where you choose to do this,
          we will receive certain profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media provider concerned,
          but will often include your name, email address, friends list, and profile picture, as
          well as other information you choose to make public on such a social media platform.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          We will use the information we receive only for the purposes that are described in this
          privacy notice or that are otherwise made clear to you on the relevant Services. Please
          note that we do not control, and are not responsible for, other uses of your personal
          information by your third-party social media provider. We recommend that you review their
          privacy notice to understand how they collect, use, and share your personal information,
          and how you can set your privacy preferences on their sites and apps.
        </p>
        <br />

        <h4 style={serviceH4Style}>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h4>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> We may transfer, store, and process your information in
          countries other than your own.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          Our servers are located in. If you are accessing our Services from outside, please be
          aware that your information may be transferred to, stored, and processed by us in our
          facilities and by those third parties with whom we may share your personal information
          (see &quot;WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?&quot; above), in and
          other countries.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or
          Switzerland, then these countries may not necessarily have data protection laws or other
          similar laws as comprehensive as those in your country. However, we will take all
          necessary measures to protect your personal information in accordance with this privacy
          notice and applicable law.
        </p>
        <br />

        <h4 style={serviceH4Style}>7. HOW LONG DO WE KEEP YOUR INFORMATION? </h4>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required by law.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          We will only keep your personal information for as long as it is necessary for the
          purposes set out in this privacy notice, unless a longer retention period is required or
          permitted by law (such as tax, accounting, or other legal requirements).
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          When we have no ongoing legitimate business need to process your personal information, we
          will either delete or anonymize such information, or, if this is not possible (for
          example, because your personal information has been stored in backup archives), then we
          will securely store your personal information and isolate it from any further processing
          until deletion is possible.
        </p>
        <br />

        <h4 style={serviceH4Style}>8. DO WE COLLECT INFORMATION FROM MINORS? </h4>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> We do not knowingly collect data from or market to children
          under 18 years of age.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          We do not knowingly solicit data from or market to children under 18 years of age. By
          using the Services, you represent that you are at least 18 or that you are the parent or
          guardian of such a minor and consent to such minor dependent’s use of the Services. If we
          learn that personal information from users less than 18 years of age has been collected,
          we will deactivate the account and take reasonable measures to promptly delete such data
          from our records. If you become aware of any data we may have collected from children
          under age 18, please contact us by phone at 82-70-8065-4414, email at hi@draph.ai, or by
          mail to 180, Yeoksam-ro, Gangnam-gu, Seoul, Republic of Korea 06248, South Korea.
        </p>
        <br />

        <h4 style={serviceH4Style}>9. WHAT ARE YOUR PRIVACY RIGHTS? </h4>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> You may review, change, or terminate your account at any time.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          Withdrawing your consent: If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on the applicable law,
          you have the right to withdraw your consent at any time. You can withdraw your consent at
          any time by contacting us by using the contact details provided in the section &quot;HOW
          CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          However, please note that this will not affect the lawfulness of the processing before its
          withdrawal nor, when applicable law allows, will it affect the processing of your personal
          information conducted in reliance on lawful processing grounds other than consent.
        </p>

        <br />
        <h5 style={serviceH4Style}>Account Information </h5>
        <br />
        <p style={serviceOlStyleEN}>
          If you would at any time like to review or change the information in your account or
          terminate your account, you can:
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          Upon your request to terminate your account, we will deactivate or delete your account and
          information from our active databases. However, we may retain some information in our
          files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <br />

        <h4 style={serviceH4Style}>10. CONTROLS FOR DO-NOT-TRACK FEATURES </h4>
        <br />
        <p style={serviceOlStyleEN}>
          Most web browsers and some mobile operating systems and mobile applications include a
          Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy
          preference not to have data about your online browsing activities monitored and collected.
          At this stage no uniform technology standard for recognizing and implementing DNT signals
          has been finalized. As such, we do not currently respond to DNT browser signals or any
          other mechanism that automatically communicates your choice not to be tracked online. If a
          standard for online tracking is adopted that we must follow in the future, we will inform
          you about that practice in a revised version of this privacy notice.
        </p>
        <br />
        <h4 style={serviceH4Style}>11. DO WE MAKE UPDATES TO THIS NOTICE? </h4>
        <br />
        <p style={serviceOlStyleEN}>
          <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant
          with relevant laws.
        </p>
        <br />
        <p style={serviceOlStyleEN}>
          We may update this privacy notice from time to time. The updated version will be indicated
          by an updated &quot;Revised&quot; date and the updated version will be effective as soon
          as it is accessible. If we make material changes to this privacy notice, we may notify you
          either by prominently posting a notice of such changes or by directly sending you a
          notification. We encourage you to review this privacy notice frequently to be informed of
          how we are protecting your information.
        </p>
        <br />

        <h4 style={serviceH4Style}>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </h4>
        <br />
        <p style={serviceOlStyleEN}>
          If you have questions or comments about this notice, you may contact us by post at:
        </p>
        <br />
        <ol style={{ listStyleType: 'none' }}>
          <li style={{ fontWeight: 600 }}>Draph, Inc </li>
          <li style={{ fontWeight: 600 }}>180, Yeoksam-ro, Gangnam-gu </li>
          <li style={{ fontWeight: 600 }}>Seoul, Republic of Korea 06248 </li>
          <li style={{ fontWeight: 600 }}>South Korea </li>
          <li style={{ fontWeight: 600 }}>Phone: 82-70-8065-4414 </li>
          <li style={{ fontWeight: 600 }}> hi@draph.ai </li>
        </ol>
        <br />
        <h4 style={serviceH4Style}>
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?{' '}
        </h4>
        <br />
        <p style={serviceOlStyleEN}>
          Based on the applicable laws of your country, you may have the right to request access to
          the personal information we collect from you, change that information, or delete it. To
          request to review, update, or delete your personal information, please fill out and submit
          a data subject access request.
        </p>
        <br />

        {/* <ShadowButton
          sx={{ mt: '3rem' }}
          onClick={() => {
            navigate(`/social/privacy/${versionInfo - 1}`)
          }}
          disabled={() => {
            if (versionInfo === 1) return true
          }}
        >
          이전 개인정보처리방침
        </ShadowButton> */}
      </Container>
    ),
  }
  const versionInfo = version || Object.keys(privacyVersion).length

  return privacyVersion[versionInfo]
}
