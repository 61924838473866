import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  IconButton,
  Input,
  keyframes,
  MenuItem,
  Popover,
  Select,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import {
  bannerTemplateParamsAtom,
  chipsAtom,
  bannerConfigAtom,
  portfolioDetailAtom,
  unusualPortfolioConfigUpdateAtom,
  userAtom,
  bannerPagesAtom,
  loadingAtom,
} from 'atoms'
import axios from 'axios'
import {
  BannerArtworkList,
  BannerTemplatesList,
  BannerUserInfo,
  BannerV2default,
  CenterAlignBox,
  CenterAlignStack,
  CreateButton,
  FlexBasis,
  InpaintCanvas,
  SelectMainCategory,
  SelectTemplate,
  triggerGA4UploadEventManualConfig,
} from 'components'
import { BannerTemplate2 } from 'components/portfolio/BannerConfig'
import { IMG_BASE64 } from 'components/portfolio/RegenerateDialog'
import { usePortfolio } from 'hooks/usePortfolio'
import { useArtworkQuery, useSelectedTemplateData } from 'hooks/useReactQuery'
import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { BsArrow90DegRight, BsChevronRight } from 'react-icons/bs'
import { MdSettingsInputComponent } from 'react-icons/md'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  ArrowUpInCircleIcon,
  CloseIcon,
  DropdownArrowIcon,
  MinusIcon,
  NextIcon,
  PlusIcon,
  PrevIcon,
  PrevPageIcon,
  RightArrowIcon,
  SelectSideIcon,
} from 'theme/icon'
import { fontSize } from './test/Toolbar'
import { PROMPT_BUTTON, PROMPT_OPTIONS, PROMPT_SELECT_IMAGE } from 'config'
import { isDev, translatePromptKoToEn } from 'utils/common'
import CustomTextFieldComponent, { CustomTextFieldComponent2 } from 'components/SelectWithinInput'
import { AnimatePresence, motion } from 'framer-motion'
import { BannerPrompt } from 'components/portfolio/BannerPrompt'
import { English, Korean } from 'hooks/useLanguage'
import { TabContext, TabPanel } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { getAccessToken } from 'utils/cookie'
import { Navigate } from 'react-router-dom'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { DisplayOnlyCards } from 'components/portfolio/BannerTemplatesList'
import { APPBAR_MOBILE } from 'layouts/main/MainAppBar'

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(1px); }
  50% { transform: translateX(-1px); }
  75% { transform: translateX(1px); }
  100% { transform: translateX(0); }
`

export default function BannerUploadV2() {
  const theme = useTheme()
  const token = getAccessToken()

  const [selectedIds, setSelectedIds] = useState([])
  const [sizeSettings, setSizeSettings] = useState({ width: 160, height: 320 }) // 설정 API 반영용
  const [anchorEl, setAnchorEl] = useState(null)
  const [tempSize, setTempSize] = useState(sizeSettings) // 설정 변경 용
  const [inpaintOpen, setInpaintOpen] = useState(false)
  const [bboxs, setBboxs] = useState({})
  const [urls, setUrls] = useState({})
  const [images, setImages] = useState([])

  const [step, setStep] = useState(0)

  const [backgroundUserPrompt, setBackgroundUserPrompt] = useState('')
  const [noticeDialogOpen, setNoticeDialogOpen] = useState(true)
  // const [noticeDialogOpen, setNoticeDialogOpen] = useState(!isDev)

  // 진짜 prompt를 위한 상태
  const [promptValues, setPromptValues] = useState({})

  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [templateSearchParams, setTemplateSearchParams] = useRecoilState(bannerTemplateParamsAtom)
  const [isUploadLoading, setIsUploadLoading] = useRecoilState(loadingAtom)

  const user = useRecoilValue(userAtom)

  // const [bannerConfig, setbannerConfig] = useRecoilState(bannerConfigAtom)

  // useEffect(() => {
  //   setbannerConfig(bannerConfig)
  // }, [portfolioDetail])

  const reset = () => {
    setBboxs({})
    setUrls({})
    setSelectedIds([])
    setImages([])
    setStep(0)
    setIsUploadLoading(false)
  }

  useEffect(() => {
    return () => {
      setIsUploadLoading(false)
    }
  }, [])

  // artworks
  const { getArtworks } = useArtworkQuery({ portfolioId: portfolioDetail.id })
  const { data, refetch } = getArtworks

  const { selectedTemplateData, isInpaint, isLogo, isLoading, isError } =
    useSelectedTemplateData(selectedIds)

  const handleSettingsClick = event => {
    setAnchorEl(event.currentTarget)
    setTempSize(sizeSettings)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSizeChange = e => {
    const { name, value } = e.target
    setTempSize(prev => ({ ...prev, [name]: Number(value) }))
  }

  const handleSizeSubmit = () => {
    setSizeSettings(tempSize)
    handleClose()
  }

  useEffect(() => {
    if (selectedTemplateData) {
      setImages([])
      setSizeSettings(selectedTemplateData.canvasSize)
    }

    if (selectedIds?.length > 0 && (isInpaint || isLogo)) {
      setInpaintOpen(true)
    }
  }, [selectedIds])

  // useEffect(() => {
  //   console.log('promptValues', promptValues)
  // }, [promptValues])

  const uploadBannerV2 = () => {
    const formData = new FormData()

    const prompt = translatePromptKoToEn(promptValues)

    const adjustedShapes = selectedTemplateData.shapes.map(shape => {
      const shapeCopy = { ...shape }

      if (shape.config.type === 'background') {
        // inpaint 가 있고 직접 기입한게 있으면 backgroundUserPrompt 로 없으면 템플릿으로
        return {
          ...shape,
          config: {
            ...shape.config,
            ...(isInpaint
              ? { bg_inpaint_prompt: prompt || shape.config.bg_inpaint_prompt }
              : { bg_concept_prompt: prompt || shape.config.bg_concept_prompt }),

            bg_inpaint_image_bbox: bboxs?.product || [],
            bg_inpaint_image_url: urls?.product || '',
          },
        }
      }

      if (shape.config.type === 'inpaint') {
        return {
          ...shape,
          ...(bboxs?.product && {
            x: bboxs?.product[0],
            y: bboxs?.product[1],
            width: bboxs?.product[2] - bboxs?.product[0],
            height: bboxs?.product[3] - bboxs?.product[1],
          }),
          config: {
            ...shape.config,
            bg_inpaint_image_bbox: bboxs?.product || [],
            bg_inpaint_image_url: urls?.product || '',
          },
        }
      }

      if (shape.config.type === 'logo') {
        return {
          ...shape,
          ...(bboxs?.logo && {
            x: bboxs?.logo[0],
            y: bboxs?.logo[1],
            width: bboxs?.logo[2] - bboxs?.logo[0],
            height: bboxs?.logo[3] - bboxs?.logo[1],
          }),
          config: {
            ...shape.config,
            bbox: bboxs?.logo || [],
            logo_image_url: urls?.logo || '',
          },
        }
      }

      return shapeCopy
    })

    // const finalShapes = adjustedShapes.map(shape => {
    //   if (shape.config.type === 'background') {
    //     return {
    //       ...shape,
    //       config: {
    //         ...shape.config,
    //         bg_inpaint_image_bbox: inpaintBbox || [],
    //         bg_inpaint_image_url: inpaintURL || '',
    //       },
    //     }
    //   }

    //   return shape
    // })

    const bannerPresets = {
      canvasSize:
        !sizeSettings.width || !sizeSettings.height
          ? selectedTemplateData.canvasSize
          : { width: sizeSettings.width, height: sizeSettings.height },
      shapes: adjustedShapes,
      templateId: selectedIds[0],
      defaultConfig: bannerConfig,
      promptObj: promptValues,
    }

    // // 나중에 이사가야해
    formData.append('user_id', user.id)
    formData.append('portfolio_id', portfolioDetail.id)
    formData.append('username', user.username)
    // formData.append('banner_template_id_list', JSON.stringify(selectedIds))
    formData.append('banner_presets', JSON.stringify([bannerPresets, bannerPresets, bannerPresets]))

    apis.appfront
      .makeBannerV2(formData)
      .then(r => {
        reset()
        refetch()
      })
      .finally(() => {
        setIsUploadLoading(false)
      })

    const eventParams = {
      image_type: templateSearchParams.subcategory,
      theme: selectedIds[0],
    }

    triggerGA4UploadEventManualConfig(eventParams, 'banner_upload')
  }

  if (!token) {
    return <Navigate to="/overview/banner" replace />
  }

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ '& .config': { width: '100%', backgroundColor: '#161616' } }}>
          <CenterAlignStack className="config">
            <BannerV2ConfigStep
              promptValues={promptValues}
              setPromptValues={setPromptValues}
              sizeSettings={sizeSettings}
              setSizeSettings={setSizeSettings}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              uploadBannerV2={uploadBannerV2}
              step={step}
              setStep={setStep}
            />
          </CenterAlignStack>

          {/* 직접 만들 때 프롬프트 */}

          {/* <Box>
          <IconButton onClick={handleSettingsClick}>
            <MdSettingsInputComponent />
          </IconButton>
        </Box>
        <BannerV2default />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ p: 2 }}>
            <TextField
              name="width"
              label="Width"
              type="number"
              value={tempSize.width}
              onChange={handleSizeChange}
              sx={{ mb: 2 }}
            />
            <TextField
              name="height"
              label="Height"
              type="number"
              value={tempSize.height}
              onChange={handleSizeChange}
              sx={{ mb: 2 }}
            />
            <Button onClick={handleSizeSubmit} variant="contained">
              적용
            </Button>
          </Box>
        </Popover>

        {(isInpaint || isLogo) && (
          <Button
            onClick={() => {
              setInpaintOpen(true)
            }}
          >
            상품을 넣고 위치를 지정해야죠 ?
          </Button>
        )}
        <TextField
          label="프롬프트"
          type="text"
          value={backgroundUserPrompt}
          onChange={e => {
            setBackgroundUserPrompt(e.target.value)
          }}
          sx={{ width: '40rem' }}
        />

        <Button onClick={uploadBannerV2} disabled={!selectedIds.length} variant="outlined">
          전송 !
        </Button> */}

          <InpaintCanvas
            bboxs={bboxs}
            setBboxs={setBboxs}
            urls={urls}
            setUrls={setUrls}
            isOpen={inpaintOpen}
            isInpaint={isInpaint}
            isLogo={isLogo}
            url={selectedTemplateData?.shapes[0].config.bg_inpaint_background_image_url}
            onClose={(callback = () => {}) => {
              setInpaintOpen(false)
              callback()
            }}
            images={images}
            setImages={setImages}
            step={step}
            setStep={setStep}
            setSelectIds={setSelectedIds}
          />
        </CenterAlignStack>
        <Box sx={{ width: '100%', background: 'white' }}>
          <BannerArtworkList />
        </Box>
        {!isDev && <NoticeDialog open={noticeDialogOpen} setOpen={setNoticeDialogOpen} />}
      </Desktop>
      <Mobile>
        <MobileNotice />
      </Mobile>
    </>
  )
}

function MobileNotice() {
  return (
    <Mobile>
      <CenterAlignStack
        className="asdfrqwer"
        sx={{
          width: '100%',
          minHeight: `calc(100vh - ${APPBAR_MOBILE} - ${APPBAR_MOBILE})`,
          height: `calc(100vh - ${APPBAR_MOBILE} - ${APPBAR_MOBILE})`,
        }}
      >
        <DisplayOnlyCards />
      </CenterAlignStack>
    </Mobile>
  )
}

function BannerV2ConfigStep({
  promptValues,
  setPromptValues,
  selectedIds,
  setSelectedIds,
  sizeSettings,
  setSizeSettings,
  uploadBannerV2,
  step,
  setStep,
}) {
  const theme = useTheme()
  const [selectedBox, setSelectedBox] = useState('manual')
  const [templateSearchParams, setTemplateSearchParams] = useRecoilState(bannerTemplateParamsAtom)

  const resetCurrentCursorIndex = useResetRecoilState(bannerPagesAtom)

  useEffect(() => {
    setTemplateSearchParams(p => ({ ...p, category: selectedBox }))
  }, [selectedBox])

  const stepComponentLists = useMemo(() => {
    // 기본 stepComponentLists 설정
    const baseSteps = [
      // {
      //   component: BannerCategory,
      //   title: (
      //     <>
      //       <Korean>제작 방식을 선택해주세요.</Korean>
      //       <English>
      //         Please select your <br />
      //         preferred creation method.
      //       </English>
      //     </>
      //   ),
      //   props: { selectedBox, setSelectedBox },
      // },
    ]

    // selectedBox에 따라 다음 단계 설정
    const nextSteps =
      selectedBox === 'auto'
        ? [
            {
              component: BannerTemplatesList,
              title: (
                <>
                  <Korean>어떤 콘셉트로 생성할까요?</Korean>
                  <English>Which concept would you like to use?</English>
                </>
              ),
              props: {
                selectedIds,
                setSelectedIds,
              },
            },
            { component: BannerCategory, title: '내용 입력' },
          ]
        : [
            {
              component: SelectMainCategory,
              title: (
                <>
                  <Korean>어떤 메인 이미지를 생성할까요?</Korean>
                  <English>Which main image would you like to create?</English>
                </>
              ),
              subtitle: (
                <>
                  <Korean>선택하신 이미지는 메인 영역의 이미지로 구성됩니다.</Korean>
                  <English>The selected image will be used as the main image.</English>
                </>
              ),
              props: {
                selectedIds,
                setSelectedIds,
                handleSelectCategory: () => {
                  setTemplateSearchParams(p => ({ ...p, enabled: true }))
                  setStep(1)
                },
              },
            },
            {
              component: SelectTemplate,
              title: (
                <>
                  <Korean>구도를 선택해주세요.</Korean>
                  <English>Please select the composition.</English>
                </>
              ),
              subtitle: (
                <>
                  <Korean>선택하신 레이아웃을 반영하여 AI가 새롭게 이미지를 제작합니다.</Korean>
                  <English>AI will generate a new image based on the selected layout.</English>
                </>
              ),
              props: {
                selectedIds,
                setSelectedIds,
              },
            },

            {
              component: BannerPrompt,
              title: (
                <>
                  <Korean>어떤 이미지인지 설명해주세요.</Korean>
                  <English>Describe what the image is.</English>
                </>
              ),
              subtitle: (
                <>
                  <Korean>해당 장면이 촬영된 장면에 대한 간략한 설명을 덧붙여주세요.</Korean>
                  <English>Please add a brief description of the shot.</English>
                </>
              ),

              props: {
                templateType: templateSearchParams.subcategory,
                promptValues,
                setPromptValues,
                sizeSettings,
                setSizeSettings,
                uploadBannerV2,
                selectedIds,
              },
            },
            {
              component: BannerUserInfo,
              title: (
                <>
                  <Korean>제품에 대한 정보를 알려주세요.</Korean>
                  <English>Tell us about your product.</English>
                </>
              ),
              subtitle: (
                <>
                  <Korean>브랜드, 제품에 대한 정보를 간략하게 설명해주세요.</Korean>
                  <English>Describe your brand, product, or service</English>
                </>
              ),
              props: { uploadBannerV2, selectedIds },
            },
          ]

    return [...baseSteps, ...nextSteps]
  }, [selectedBox, promptValues, selectedIds, sizeSettings])

  if (stepComponentLists.length === 0) return null
  const CurrentStepComponent = stepComponentLists[step].component
  const currentTitle = stepComponentLists[step].title
  const currentSubtitle = stepComponentLists[step].subtitle

  const currentProps = stepComponentLists[step].props

  const handleNext = () => {
    if (step < stepComponentLists.length - 1) {
      setStep(step + 1)
      resetCurrentCursorIndex()

      if (step === 0) {
        setTemplateSearchParams(p => ({ ...p, enabled: true }))
      }
    }
  }

  const handlePrev = () => {
    if (step > 0) {
      setStep(step - 1)
      resetCurrentCursorIndex()
    }
  }

  return (
    <CenterAlignStack
      sx={{
        width: '128rem',
        p: '8.4rem 0 0 0',

        '& .title': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'inherit',

          '& .typo': {
            fontSize: '4.4rem',
            fontWeight: 700,
            color: theme.palette.common.white,
            lineHeight: 1.1,
            '&.subtitle': {
              fontSize: '2.4rem',
              fontWeight: 400,
              mt: '1.6rem',
            },
          },

          '& .button': {
            width: '14.9rem',
            height: '5.2rem',
            color: theme.palette.common.white,
            fontSize: '2.8rem',
            fontWeight: 600,
            border: `2px solid ${theme.palette.common.white}`,
            borderRadius: '3rem',

            '&.Mui-disabled': {
              borderColor: '#919EAB',
              color: '#919EAB',
              '& path': { fill: '#919EAB' },
            },

            '& .MuiButton-startIcon': {
              mr: '1.9rem',
            },

            '& .MuiButton-endIcon': {
              ml: '1.9rem',
            },
          },
        },
        '& .subtitle': {
          fontSize: '2.4rem',
          fontWeight: 400,
          color: theme.palette.common.white,
        },
      }}
    >
      <Box className="title" sx={{ position: 'relative', width: '100%' }}>
        {step > 0 && (
          <Button
            className="button"
            variant="outlined"
            onClick={handlePrev}
            startIcon={
              <PrevIcon
                sx={{ width: '1.16rem', height: '2rem' }}
                color={theme.palette.common.white}
              />
            }
            sx={{ position: 'absolute', left: 0, top: '0.2rem' }}
          >
            <Korean>이전</Korean>
            <English>Prev</English>
          </Button>
        )}
        <CenterAlignStack>
          <CenterAlignStack sx={{ width: '100%' }}>
            <Typography className="typo" sx={{ width: '100%', textAlign: 'center' }}>
              {currentTitle}
            </Typography>
            {currentSubtitle && (
              <Typography className="typo subtitle" sx={{ width: '100%', textAlign: 'center' }}>
                {currentSubtitle}
              </Typography>
            )}
          </CenterAlignStack>
        </CenterAlignStack>
        {step < stepComponentLists.length - 1 && (
          <Button
            className="button"
            variant="outlined"
            onClick={handleNext}
            endIcon={
              <NextIcon
                sx={{ width: '1.16rem', height: '2rem' }}
                color={theme.palette.common.white}
              />
            }
            sx={{
              position: 'absolute',
              right: 0,
              top: '0.2rem',
            }}
            disabled={
              templateSearchParams.subcategory === 'interaction' ||
              (step > 0 && !selectedIds.length)
            }
          >
            <Korean>다음</Korean>
            <English>Next</English>
          </Button>
        )}
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CurrentStepComponent {...currentProps} />
      </Box>
    </CenterAlignStack>
  )
}
const BannerCategory = ({ selectedBox, setSelectedBox }) => {
  const theme = useTheme()

  const OPTIONS_AUTO = [
    <>
      <Korean>SNS 광고 디자인</Korean>
      <English>Social Media Ad Design</English>
    </>,
    <>
      <Korean>광고 포스터</Korean>
      <English>Advertisement Poster</English>
    </>,
    <>
      <Korean>상세페이지</Korean>
      <English>Product Detail Page</English>
    </>,
    <>
      <Korean>온라인 배너</Korean>
      <English>Online Banners</English>
    </>,
    <>
      <Korean>인스타그램 광고</Korean>
      <English>Instagram Ads</English>
    </>,
  ]
  const OPTIONS_MANUAL = [
    <>
      <Korean> 상품 컨셉 사진</Korean>
      <English>Product Concept Photo</English>
    </>,
    <>
      <Korean>단순 모델 컷</Korean>
      <English>Basic Model Photo</English>
    </>,
    <>
      <Korean>상호작용 모델 컷</Korean>
      <English>Interactive Model Photo</English>
    </>,
    <>
      <Korean>다양한 문구 디자인</Korean>
      <English>Various Text Designs</English>
    </>,
  ]

  const commonStyles = {
    width: '50rem',
    borderRadius: '30px',
    padding: '5.6rem 5rem 4.8rem 5rem',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  }

  const boxTextStyles = {
    fontSize: '2.8rem',
    fontWeight: 800,
  }

  const noticeTextStyles = {
    fontSize: '2rem',
    fontWeight: 400,
  }

  const optionsStyles = {
    p: '4rem',
    borderRadius: '30px',
    minHeight: '31.6rem',
  }

  const optionsTitleStyles = {
    fontSize: '2.4rem',
    fontWeight: 700,
  }

  const optionsLiStyles = {
    fontSize: '2rem',
    fontWeight: 400,
  }

  const buttonStyles = {
    mt: '3.6rem',
    width: '100%',
    borderRadius: '30px',
    fontSize: '2rem',
    fontWeight: 600,
    p: '1rem',
  }

  const gradientBackground = 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)'

  const noticeText = {
    auto: (
      <>
        <Korean>
          실제 제작된 결과물 중 하나를 선택하여
          <br />
          동일한 방식으로 제작해요.
        </Korean>
        <English>
          Select one of our existing designs <br />
          and create your banner in the same manner.
        </English>
      </>
    ),
    manual: (
      <>
        <Korean>
          원하는 템플릿이 없다면
          <br />
          직접 설정하여 제작할 수 있어요.
        </Korean>
        <English>
          If you don’t find a template you want, <br /> you can customize and create it yourself.
        </English>
      </>
    ),
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4.8rem',
        mt: '8rem',
        pb: '12.8rem',

        '& .MuiTypography-root': {
          lineHeight: 'normal',
        },
        '& .box': {
          ...commonStyles,
          '&.selected': {
            backgroundColor: theme.palette.common.white,
            '& .box-text': {
              ...boxTextStyles,
              background: gradientBackground,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textFillColor: 'transparent',
            },
            '& .notice-text': {
              ...noticeTextStyles,
              color: '#161616',
            },
            '& .options': {
              ...optionsStyles,
              backgroundColor: '#F5F6FA',
            },
            '& .options-title': {
              ...optionsTitleStyles,
              color: '#161616',
            },
            '& .options-li': {
              ...optionsLiStyles,
              color: '#161616',
            },

            '& .icon': {
              width: '2rem',
              height: '2rem',
              borderRadius: '50%',
              position: 'relative',
              background: gradientBackground,
              padding: '3px',
              mr: '0.8rem',
              '&::after': {
                content: '""',
                position: 'absolute',
                top: '3px',
                left: '3px',
                right: '3px',
                bottom: '3px',
                background: '#F5F6FA',
                borderRadius: '50%',
              },
            },
            '& .button': {
              ...buttonStyles,
              background: gradientBackground,
              color: theme.palette.common.white,
            },
          },
          '&:not(.selected)': {
            backgroundColor: '#505050',
            '& .box-text': {
              ...boxTextStyles,
              color: '#ffffff',
            },
            '& .notice-text': {
              ...noticeTextStyles,
              color: theme.palette.common.white,
            },
            '& .options': {
              ...optionsStyles,
              backgroundColor: '#303030',
            },
            '& .options-title': {
              ...optionsTitleStyles,
              color: theme.palette.common.white,
            },
            '& .options-li': {
              ...optionsLiStyles,
              color: theme.palette.common.white,
            },

            '& .icon': {
              width: '2rem',
              height: '2rem',
              borderRadius: '50%',
              position: 'relative',
              background: gradientBackground,
              padding: '3px',
              mr: '0.8rem',
              '&::after': {
                content: '""',
                position: 'absolute',
                top: '3px',
                left: '3px',
                right: '3px',
                bottom: '3px',
                background: '#303030',
                borderRadius: '50%',
              },
            },
            '& .button': {
              ...buttonStyles,
              background: '#989898',
              color: theme.palette.common.white,
            },
          },
        },
        '& .notice': {
          m: '1.6rem 0 2.8rem 0',
        },
        '& .options-stack': {
          mt: '2.4rem',
          gap: '1.6rem',
        },

        '& .tag': {
          p: '0.65rem 1rem',
          fontSize: '1.6rem',
          fontWeight: 700,
          background: gradientBackground,
          color: theme.palette.common.white,
          borderRadius: '4px',
        },
      }}
    >
      {[
        // 'auto',
        'manual',
      ].map(boxType => (
        <Stack
          key={boxType}
          className={`box ${selectedBox === boxType ? 'selected' : ''}`}
          onClick={() => setSelectedBox(boxType)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
            <Typography className="box-text">
              {boxType === 'auto' ? (
                <>
                  <Korean>콘셉트로 AI 제작</Korean>
                  <English>Select Concept</English>
                </>
              ) : (
                <>
                  <Korean>직접 제작하기</Korean>
                  <English>Create Manually</English>
                </>
              )}
            </Typography>
            {boxType === 'auto' && (
              <CenterAlignBox className="tag">
                <Korean>인기있는 옵션</Korean>
                <English>Popular</English>
              </CenterAlignBox>
            )}
          </Box>

          <Stack className="notice">
            <Typography className="notice-text">{noticeText[boxType]}</Typography>
          </Stack>

          <Stack className="options">
            <Typography className="options-title">
              {boxType === 'auto' ? (
                <>
                  <Korean>선택 가능한 다양한 템플릿 옵션</Korean>
                  <English>Various templates available</English>
                </>
              ) : (
                <>
                  <Korean>선택 가능한 다양한 옵션</Korean>
                  <English>Various options available</English>
                </>
              )}
            </Typography>
            <Stack className="options-stack">
              {(boxType === 'auto' ? OPTIONS_AUTO : OPTIONS_MANUAL).map((option, i) => (
                <Box key={i} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box className="icon" />
                  <Typography className="options-li">{option}</Typography>
                </Box>
              ))}
            </Stack>
          </Stack>

          <CenterAlignStack className="button">
            <Korean>선택</Korean>
            <English>Select</English>
          </CenterAlignStack>
        </Stack>
      ))}
    </Box>
  )
}

// 잡다한 것들

const NoticeDialog = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          borderRadius: '10px',
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)', // 배경을 더 진하게 설정
          },
        },
      }}
    >
      <Stack sx={{ backgroundColor: '#303030', position: 'relative' }}>
        <Box
          sx={{
            background: 'transparent',
            fontWeight: 700,
            fontSize: '2.4rem',
            position: 'absolute',
            color: '#fff',
            top: '18.4rem',
            left: '2.6rem',
          }}
        >
          <Korean>
            현재 접속하신 서비스는
            <br /> 광고 베타 서비스입니다.
          </Korean>
          <English>
            The current service is
            <br /> an advertising beta service.
          </English>
        </Box>
        <img src={`/static/images/bannerNotice.png`} style={{ width: '40rem' }} />
        <Stack
          sx={{
            color: '#fff',
            p: '2rem 2.6rem',
            fontSize: '1.4rem',
            lineHeight: '2rem',
            width: '40rem',
            '& .star': {
              mt: '1.2rem',
              fontSize: '1.3rem',
              lineHeight: '1.6rem',
              color: '#D9D9D9',
              position: 'relative',
              '&::before': {
                content: '"*"',
                // position: 'absolute',
                // left: '-8px',
                // color: '#D9D9D9',
              },
            },
          }}
        >
          <Korean>
            베타기간이 종료되면,
            <br />
            지금 접속하신 서비스에서 기존 광고 기능을 이용하실 수 있어요!
            <span className="star">
              현재 광고 문구는 생성되지 않습니다. 추후 업데이트 될 예정입니다.
            </span>
          </Korean>
          <English>
            When the beta period ends,
            <br />
            you will be able to use the existing advertising features in the service you are
            currently accessing!
            <span className="star">
              The ad copy generation feature is not yet available. <br /> It will be updated soon.
            </span>
          </English>
        </Stack>

        <CenterAlignBox sx={{ pb: '3.2rem' }}>
          {' '}
          <CreateButton
            sx={{
              width: '34.6rem',
              borderRadius: '10px',
              fontWeight: 600,
              fontSize: '1.6rem',
            }}
            onClick={() => setOpen(false)}
          >
            {t('button.confirm')}
          </CreateButton>
        </CenterAlignBox>
      </Stack>
    </Dialog>
  )
}
