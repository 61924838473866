import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { apis } from 'apis'
import { currentMenuAtom, userAtom, userCreditAtom } from 'atoms'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import useCreditWarningDialog from 'hooks/useCreditWarningDialog'
import { English, Korean } from 'hooks/useLanguage'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { creditPolicyDictSelector } from 'selector'

const SCALE_W = 2048
const SCALE_H = 2048
const CREDIT_TYPE = 'piece_upscale'

export function UpscaleConfirmDialog({ open, setOpen, actionParams, callback }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useRecoilState(userAtom)
  const [userCredit, setUserCredit] = useRecoilState(userCreditAtom)
  const creditPolicy = useRecoilValue(creditPolicyDictSelector)
  const currentMenu = useRecoilValue(currentMenuAtom)
  const { showCreditWarningDialog } = useCreditWarningDialog()

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    setUserCredit(credit)
    return credit
  }

  const price = creditPolicy[CREDIT_TYPE]

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    setOpen(false)
  }

  const handleAction = async () => {
    const credit = await checkUserCredit()
    if (credit < price) {
      // ----- GA4 event -----
      window.gtag('event', 'not_enough_credit_imp', {
        menu: currentMenu?.id,
      })
      // ---------------------

      showCreditWarningDialog()
      handleClose()
      return
    }

    setIsLoading(true)
    // ----- GA4 event -----
    window.gtag('event', 'upscale', { method: 'upscale_complete' })
    // ---------------------
    try {
      const res = await apis.appfront.upscaler(actionParams)
      callback(res)
    } catch (error) {
      alert(t('common.error'))
    } finally {
      checkUserCredit()
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { borderRadius: '2rem' } }}>
      <DialogContent
        sx={{
          width: { lg: '40rem' },
          py: '3.2rem',
          '& .text': {
            color: theme => theme.palette.common.black,
            lineHeight: 1.6,
            fontSize: { lg: '1.4rem' },
            fontWeight: 500,
            '&.light': { fontSize: '1.3rem', fontWeight: 400 },

            '& .highlight-blue': {
              fontSize: '1.3rem',
              fontWeight: 600,
              color: 'white',
              background: '#2C4DFF',
              borderRadius: '4px',
              py: '2.5px',
              px: '6.5px',
            },
            '& .highlight-skyblue': {
              fontSize: '1.3rem',
              fontWeight: 500,
              color: '#2C4DFF',
              background: '#E3ECFF',
              borderRadius: '4px',
              py: '4.5px',
              px: '12px',
            },
          },
        }}
      >
        <CenterAlignStack>
          <Typography className="text">
            <Korean>
              생성 결과물을 <span className="highlight-blue">고화질의 이미지</span>로 변환해요.
            </Korean>
            <English>
              This feature converts images to{' '}
              <span className="highlight-blue">high resolution</span>
            </English>
          </Typography>
          <Typography className="text">
            <Korean>화질개선 기능은 1크레딧이 추가로 사용됩니다.</Korean>
            <English>An additional 1 credit will be used.</English>
          </Typography>

          <Typography className="text light" sx={{ mt: '2rem' }}>
            <Korean>
              <span className="highlight-skyblue">예상 해상도</span> {SCALE_W} * {SCALE_H} 로 화질이
              개선됩니다.
            </Korean>
            <English>
              <span className="highlight-skyblue">Expected resolution</span> {SCALE_W} * {SCALE_H}
            </English>
          </Typography>
        </CenterAlignStack>
      </DialogContent>
      <DialogActions
        sx={{
          p: 0,
          '& .button': {
            width: '50%',
            height: '4.4rem',
            fontSize: '1.6rem',
            fontWeight: 600,
            m: 0,
            borderRadius: 0,
            '&.left': {
              background: '#F8F8F8',
              color: theme => theme.palette.common.black,
            },
            '&.right': {
              background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
              color: 'white',
            },
          },
        }}
      >
        <Button
          className="button left cancel"
          variant="contained"
          onClick={() => {
            // ----- GA4 event -----
            window.gtag('event', 'upscale', { method: 'cancel' })
            // ---------------------
            handleClose()
          }}
          sx={{}}
        >
          {t('button.cancel')}
        </Button>

        <Button className="button right action" variant="contained" onClick={handleAction}>
          {t('button.proceed')}
        </Button>
      </DialogActions>
      <Backdrop
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: theme => theme.zIndex.drawer + 1,
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  )
}
