import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import { blogPostsAtom, blogTabAtom, languageAtom } from 'atoms'
import {
  CenterAlignBox,
  CenterAlignStack,
  LanguageSelect,
  LanguageSelectV2,
  ScrollToTop,
  SearchInput,
} from 'components'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { SearchIcon } from 'theme/icon'
import { getS3ImageSrc, removeTags } from 'utils/common'
import { setCookie } from '../utils/cookie'

const subtitle = { fontSize: { lg: '2rem', xs: '1.2rem' }, fontWeight: 400, lineHeight: 'normal' }

export default function Blog() {
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const pathnameLang = pathname.match(/\/blog\/([a-z]{2})/)[1]

  const [searchText, setSearchText] = useState('')
  const [blogPosts, setBlogPosts] = useRecoilState(blogPostsAtom)
  const [tabValue, setTabValue] = useRecoilState(blogTabAtom)

  const [loadging, setLoading] = useState(true)

  const [visibleBlogPosts, setVisibleBlogPosts] = useState([])
  const [filteredBlogPosts, setFilteredBlogPosts] = useState([])
  const [lang, setLang] = useRecoilState(languageAtom)

  const resetTabValue = useResetRecoilState(blogTabAtom)

  const searchHandle = () => {
    if (blogPosts) {
      const searchedBlogPost = blogPosts.filter((b, i) => {
        return b.title.includes(searchText) || removeTags(b.content_html).includes(searchText)
      })
      setFilteredBlogPosts(searchedBlogPost)
      setTabValue('search')
    }
  }

  const refreshBlogPosts = lang => {
    setLoading(true)
    apis.blog.getArticles(lang).then(r => {
      const blogPosts = r.data

      setLoading(false)
      setBlogPosts(blogPosts)
    })
  }

  const reset = () => {
    resetTabValue()
  }

  useEffect(() => {
    setLang(pathnameLang)
    i18n.changeLanguage(pathnameLang)
    window.localStorage.setItem('i18nextLng', pathnameLang)
    setCookie('language', pathnameLang)
    // refreshBlogPosts(pathnameLang)

    return () => {
      reset()
    }
  }, [])

  useEffect(() => {
    refreshBlogPosts(pathnameLang)
  }, [pathnameLang])

  // useEffect(() => {
  //   localStorage.setItem('i18nextLng', pathnameLang)
  //   setLang(pathnameLang)
  //   const updatedUrl = pathname.replace(/\/blog\/[a-z]{2}/, `/blog/${pathnameLang}`)

  //   if (!pathname.includes(selectedLang)) {
  //     navigate(`${updatedUrl}`)
  //     refreshBlogPosts()
  //   }
  // }, [lang])

  return (
    <>
      <CenterAlignStack sx={{}}>
        <BlogTitle
          searchText={searchText}
          setSearchText={setSearchText}
          searchHandle={searchHandle}
        />
        {loadging ? (
          <PuffLoader />
        ) : (
          <BlogContents
            visibleBlogPosts={visibleBlogPosts}
            setVisibleBlogPosts={setVisibleBlogPosts}
            filteredBlogPosts={filteredBlogPosts}
            setFilteredBlogPosts={setFilteredBlogPosts}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
        )}
        <ScrollToTop upside={true} />
      </CenterAlignStack>
    </>
  )
}

const BlogTitle = ({ searchText, setSearchText, searchHandle }) => {
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()

  return (
    <CenterAlignStack
      sx={{
        m: { lg: '5.6rem 0 4.6rem 0', xs: '2.4rem 0 0.8rem 0' },
        gap: { lg: '3.2rem', xs: '2rem' },
        width: { lg: '118.5rem', xs: '32rem' },
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'absolute', right: 0 }}>
        <LanguageSelectV2 theme="light" />
      </Box>
      <CenterAlignStack sx={{ gap: { lg: '1.6rem', xs: '1rem' }, pt: { lg: 0, xs: '3rem' } }}>
        <Typography
          sx={{ fontSize: { lg: '3rem', xs: '1.8rem' }, fontWeight: 800, lineHeight: 'normal' }}
        >
          {t('blog.main_title')}
        </Typography>
        <CenterAlignStack>
          <Typography sx={subtitle}>{t('blog.subtitle_1')}</Typography>
          <Typography sx={subtitle}>{t('blog.subtitle_2')}</Typography>
          <Typography sx={subtitle}>{t('blog.subtitle_3')}</Typography>
        </CenterAlignStack>
      </CenterAlignStack>

      <SearchInput
        width={isMobile ? '32rem' : '34rem'}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        sx={{
          '& .MuiInputBase-root': {
            width: isMobile ? '32rem' : '34rem',
            pr: '0.2rem',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={searchHandle}>
                <SearchIcon id="search-icon" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </CenterAlignStack>
  )
}

const BlogContents = ({
  visibleBlogPosts,
  setVisibleBlogPosts,
  filteredBlogPosts,
  setFilteredBlogPosts,
  tabValue,
  setTabValue,
}) => {
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const BLOGS_PER_PAGE = isMobile ? 6 : 9

  const [page, setPage] = useState(1)

  const [blogPosts, setBlogPosts] = useRecoilState(blogPostsAtom)
  const resetBlogPosts = useResetRecoilState(blogPostsAtom)

  // const [visibleBlogPosts, setVisibleBlogPosts] = useState([])
  // const [filteredBlogPosts, setFilteredBlogPosts] = useState([])

  const LIMIT_PAGE = Math.ceil(filteredBlogPosts.length / BLOGS_PER_PAGE)

  useEffect(() => {})

  const handleChange = (event, newValue) => {
    setTabValue(newValue)

    // if (newValue === 'total') return setFilteredBlogPosts(blogPosts)

    // const filteredPost = blogPosts.filter(p => {
    //   return p.category === newValue
    // })

    // // console.log('filteredPost', filteredPost)
    // setFilteredBlogPosts(filteredPost)
  }

  useEffect(() => {
    if (blogPosts.length) {
      setFilteredBlogPosts(blogPosts)
    }
  }, [blogPosts])

  useEffect(() => {
    if (tabValue === 'total') return setFilteredBlogPosts(blogPosts)

    const filteredPost = blogPosts.filter(p => {
      return p.category === tabValue
    })
    setFilteredBlogPosts(filteredPost)
  }, [tabValue])

  useEffect(() => {
    const startIndex = 0
    const endIndex = page * BLOGS_PER_PAGE
    setVisibleBlogPosts(prev => [...filteredBlogPosts.slice(startIndex, endIndex)])
  }, [filteredBlogPosts, page])

  const tabMenu = [
    { label: t('blog.total'), value: 'total' },
    { label: t('blog.news'), value: 'news' },
    { label: t('blog.tips'), value: 'tips' },
    { label: t('blog.insights'), value: 'insights' },
    { label: '', value: 'search' },
  ]

  const nothingStyle = {
    textAlign: 'center',
    width: '100%',
    fontSize: { lg: '2.2rem', xs: '1.3rem' },
    fontWeight: 500,
    color: '#303030',
  }

  return (
    <Stack
      sx={{
        justifyContent: { lg: 'flex-start', xs: 'center' },
        width: { lg: '118.5rem', xs: '32rem' },
        mb: { lg: '7.2rem', xs: '4.2rem' },
      }}
    >
      <TabContext value={tabValue}>
        <TabList
          onChange={handleChange}
          aria-label="lab API tabs example"
          TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
          sx={{
            ml: { lg: '-1.9rem', xs: '0' },
            mb: { lg: '1.6rem', xs: 0 },
            '& .MuiTabs-indicator': {
              height: '1px',
              bottom: '1.56rem',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'transparent',
            },
            '& .MuiTabs-indicatorSpan': {
              // maxWidth: 40,
              width: { lg: 'calc(100% - 3.8rem)', xs: 'calc(100% - 1.2rem)' },
              backgroundColor: theme.palette.draph.blue,
            },
          }}
        >
          {tabMenu.map((t, i) => {
            return (
              <Tab
                label={t.label}
                value={t.value}
                key={t.label}
                sx={{
                  p: { lg: '1.6rem 1.9rem', xs: '1.6rem 0.6rem' },
                  minWidth: 'auto',
                  maxWidth: 'none',
                  fontSize: { lg: '2rem', xs: '1.3rem' },
                  fontWeight: 400,
                  display: t.value === 'search' ? 'none' : 'flex',
                }}
              />
            )
          })}
        </TabList>

        <CenterAlignBox
          sx={{
            flexFlow: 'wrap',
            rowGap: { lg: '6rem', xs: '2.8rem' },
            columnGap: { lg: '2.8rem', xs: '1.6rem' },
            justifyContent: 'flex-start',
          }}
        >
          {filteredBlogPosts.length ? (
            visibleBlogPosts.map((b, i) => {
              return <BlogThumbnail blog={b} key={i} width={isMobile ? '15.2' : '37.6'} />
            })
          ) : tabValue === 'search' ? (
            <Stack sx={{ width: '100%', m: { lg: '8rem 0 5rem 0', xs: '3rem 0 1rem 0' } }}>
              <Typography sx={nothingStyle}>{t('blog.search_nothing_1')}</Typography>
              <Typography sx={nothingStyle}>{t('blog.search_nothing_2')}</Typography>
              <Typography sx={nothingStyle}>{t('blog.search_nothing_3')}</Typography>
            </Stack>
          ) : (
            <Stack sx={{ width: '100%', m: { lg: '8rem 0 5rem 0', xs: '3rem 0 1rem 0' } }}>
              <Typography sx={nothingStyle}>{t('blog.filter_nothing_1')}</Typography>
              <Typography sx={nothingStyle}>{t('blog.filter_nothing_2')}</Typography>
            </Stack>
          )}
        </CenterAlignBox>

        <CenterAlignBox>
          <Button
            onClick={() => {
              setPage(page + 1)
            }}
            variant="outlined"
            disabled={LIMIT_PAGE === page || filteredBlogPosts.length <= BLOGS_PER_PAGE}
            sx={{
              width: { lg: '30rem', xs: '32rem' },
              height: { lg: '6rem', xs: '4.8rem' },
              fontSize: { lg: '2.2rem', xs: '1.8rem' },
              borderRadius: { lg: '1rem', xs: '1rem' },
              border: '2px solid #303030',
              fontWeight: 800,
              mt: '6rem',
            }}
          >
            {Math.ceil(LIMIT_PAGE) < 2
              ? t('blog.see_more')
              : `${t('blog.see_more')}(${page}/${Math.ceil(LIMIT_PAGE)})`}
          </Button>
        </CenterAlignBox>
      </TabContext>
    </Stack>
  )
}

export const BlogThumbnail = ({ blog, width = '37.6', ratio = '4 / 3' }) => {
  const { t, i18n } = useTranslation()
  const { pathname } = useLocation()
  const isMobile = useMobileMediaQuery()
  const [hover, setHover] = useState(false)
  const pathnameLang = pathname.match(/\/blog\/([a-z]{2})/)[1]
  const navigate = useNavigate()

  return (
    <Stack
      sx={{
        gap: { lg: '2rem', xs: '1.2rem' },
        width: width + 'rem',
        cursor: 'pointer',
        // boxShadow: hover ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
        // transition: 'box-shadow 0.3s ease-in-out',
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => {
        navigate(`/blog/${pathnameLang}/${blog.category}/${blog.id}`)
      }}
    >
      <Box sx={{ display: 'block', overflow: 'hidden' }}>
        <img
          src={getS3ImageSrc(blog.cover_image_url)}
          style={{
            width: width + 'rem',
            aspectRatio: ratio,

            objectFit: 'cover',
            transition: 'all 1s cubic-bezier(0.104, 0.204, 0.492, 1)',
            willChange: 'scale',
            scale: hover ? '115%' : '100%',
          }}
        />
      </Box>

      <Typography sx={{ fontSize: { lg: '2rem', xs: '1.2rem' }, fontWeight: 400 }}>
        {t(`blog.${blog.category}`)}
      </Typography>
      <Divider
        flexItem
        orientation="horizontal"
        sx={{
          borderColor: '#303030',
          borderBottomWidth: { lg: '0.2rem', xs: '0.1rem' },
          width: '2rem',
          borderStyle: 'inset',
        }}
      />
      <Box sx={{}}>
        <Typography
          sx={{
            fontSize: { lg: '2.8rem', xs: '1.4rem' },
            fontWeight: 600,
            lineHeight: 'normal',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            wordBreak: 'break-all',
            display: '-webkit-box',
            WebkitLineClamp: 2, // 원하는 라인수
            WebkitBoxOrient: 'vertical',
            height: { lg: '7rem', xs: '3.5rem' },
          }}
        >
          {blog.title}
        </Typography>
      </Box>
    </Stack>
  )
}
