import { forwardRef, useEffect, useRef, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { apis } from 'apis'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Button,
  Link,
  IconButton,
  InputAdornment,
  FormGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
  Stack,
  CircularProgress,
  TextField,
  Typography,
  Box,
  Grid,
  Divider,
  MenuItem,
  Avatar,
  Dialog,
  DialogContent,
  RadioGroup,
  Radio,
  OutlinedInput,
  Slide,
  Select,
} from '@mui/material'
import {
  AgreeDialog,
  CenterAlignBox,
  CenterAlignStack,
  FlexBasis,
  RegisterBox,
  TempLayoutDiv,
  FormErrorMessage,
  TextInput,
  PhoneInput,
  DialogButton,
  CheckPasswordDialog,
  SpanDraphBlue,
  MobileSwipeableDrawer,
  ShowUserState,
  LeaveNoticeDialog,
} from '../components'

import { AiFillEye, AiFillEyeInvisible, AiOutlineCamera } from 'react-icons/ai'

import styled from '@emotion/styled'
import * as yup from 'yup'
import { FormCheckbox, RegisterLabel } from 'components'
import { Controller, useFieldArray, useForm, useController } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import { userAtom, userCreditAtom } from 'atoms'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import useConfirm from 'hooks/useConfirm'
import { allowedTypes, getS3ImageSrc, isKo, nullZero, PWD_REGEX, trasnferDate } from 'utils/common'
import {
  AgreeCheckedSquareIcon,
  AgreeSquareIcon,
  CameraIcon,
  CheckedSquareIcon2,
  SearchIcon,
  SquareIcon2,
} from 'theme/icon'
import { padding } from '@mui/system'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { MYPAGE_PERCENTAGE_WIDHT, MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import { removeUserCookie } from 'utils/cookie'
import { t } from 'i18next'
import { English, Korean } from 'hooks/useLanguage'
import { LeaveDialog, SelectReasonDialog } from 'components/ShowUserState'
import { userSubInfoAtom } from '../atoms'

export default function UserProfile() {
  const { t, i18n } = useTranslation()

  const options = [
    { id: 999, label: t('select_reason_dialog.reasons_8') },
    { id: 2, label: t('select_reason_dialog.reasons_2') },
    { id: 3, label: t('select_reason_dialog.reasons_3') },
    { id: 4, label: t('select_reason_dialog.reasons_4') },
    { id: 5, label: t('select_reason_dialog.reasons_5') },
    { id: 6, label: t('select_reason_dialog.reasons_6') },
    { id: 7, label: t('select_reason_dialog.reasons_7') },
    { id: 1, label: t('select_reason_dialog.reasons_1') },
  ]

  const { showConfirm } = useConfirm()
  const navigate = useNavigate()
  const [user, setUser] = useState({})
  const [userAtomState, setUserAtomState] = useRecoilState(userAtom)
  const [image, setImage] = useState('')

  const [openShowUserStateDialog, setOpenShowUserStateDialog] = useState(false)
  const [openCheckPasswordDialog, setOpenCheckPasswordDialog] = useState(false)
  const [openLeaveNoticeDialog, setOpenLeaveNoticeDialog] = useState(false)
  const [openSelectReasonDialog, setOpenSelectReasonDialog] = useState(false)
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [etcText, setEtcText] = useState('')

  const [categories, setCategories] = useState([])
  const [showPassword, setShowPassword] = useState({
    password: false,
    newPassword: false,
    checkedPassword: false,
  })

  const currentUser = useRecoilValue(userAtom)
  const [currentSubscription, setCurrentSubscription] = useRecoilState(userSubInfoAtom)
  const [userCredit, setUserCredit] = useRecoilState(userCreditAtom)

  useEffect(() => {
    apis.user.getUserSubscription().then(response => {
      setCurrentSubscription(response.data)
    })

    apis.user.getCredit().then(response => {
      // console.log(response.data.credit)
      setUserCredit(response.data.credit ?? 0)
    })

    return () => {}
  }, [])

  const isNCommerceUser = currentUser.login_sns === 'naver_commerce'

  const theme = useTheme()

  const agreeLabelStyle = isKo(i18n)
    ? {
        fontWeight: 300,
        fontSize: '2rem',
        color: theme.palette.common.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '48rem',
      }
    : {
        fontWeight: 300,
        fontSize: '1.6rem',
        color: theme.palette.common.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '70rem',
      }

  const agreeSubLabelStyle = isKo(i18n)
    ? {
        fontWeight: 300,
        fontSize: '2rem',
        color: theme.palette.common.black,
      }
    : {
        fontWeight: 300,
        fontSize: '1.6rem',
        color: theme.palette.common.black,
      }

  const mobileAgreeLabelStyle = {
    fontWeight: 400,
    fontSize: '1.5rem',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  }

  const mobileAgreeSubLabelStyle = {
    fontWeight: 400,
    fontSize: '1.5rem',
    color: theme.palette.common.black,
  }

  const fileInput = useRef(null)

  const handleProfileImage = event => {
    const file = event.target.files[0]
    const sizeInMB = file.size / 1024 / 1024
    if (sizeInMB > 2) {
      showConfirm({ content: t('profile.profile_warning_2'), alertOnly: true })

      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setImage(reader.result)
    }
  }

  const handleClick = () => {
    fileInput.current.click()
  }

  // useEffect(() => {
  //   apis.user.getCategory().then(response => {
  //     const categories = response.data
  //     setCategories(categories)
  //     const arr = []
  //     let inner = []

  //     categories.forEach((category, idx) => {
  //       inner.push(category)
  //       if (idx % 4 === 3) {
  //         arr.push(inner)
  //         inner = []
  //       }

  //       if (idx === categories.length - 1) {
  //         arr.push(inner)
  //       }
  //     })
  //   })
  // }, [])

  const UserProfileSchema = yup.object({
    password: !userAtomState.login_sns && yup.string().required(t('profile.profile_warning_1')),
    // newPassword: yup.string().min(2, '비밀번호를 2글자 이상 입력해주세요.'),
    newPassword: yup.string().test({
      name: 'optionalValidation',
      test: function (value) {
        // 값이 존재하면 (작성될 때만 밸리데이션 수행)
        if (value !== '') {
          return yup
            .string()
            .min(4, t('register.password_error_2'))
            .max(20, t('register.password_error_3'))
            .matches(PWD_REGEX || '', t('register.password_error_1'))
            .isValidSync(value)
        }
        // 값이 존재하지 않으면 (확인 없이 통과)
        return true
      },
    }),
    checkedPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], t('register.password_check_error_1')),
    phone: yup
      .string()
      .required(t('register.mobile_error_2'))
      .matches(/^\d{3}\d{3,4}\d{4}$/, {
        message: t('register.mobile_error_1'),
        excludeEmptyString: true,
      }),
  })

  const {
    formState: { isDirty, dirtyFields, errors, isSubmitting },
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      newPassword: '',
      checkedPassword: '',
      company: '',
      nickname: '',
      gender: '',
      phone: '',
      phone1: '',
      phone2: '',
      phone3: '',
      website: '',
      category: [],
      agreeAdsEmail: false,
      agreeAdsSMS: false,
      agreeMarketing: false,
    },
    // 에러 원인... 처리해야함... 왜 user가 애초에 빈 값으로 들어오지 ㅠㅠ..
    // values: user,
    resolver: yupResolver(UserProfileSchema),
    mode: 'onSubmit',
  })

  useEffect(() => {
    apis.user.getUser().then(response => {
      const data = { ...response.data }
      for (const key in data) {
        data[key] = data[key] || ''
      }

      data.phone = data.phone.replace(/-/g, '')
      if (data.phone.length === 10) {
        data.phone2 = data.phone.slice(3, 6)
        data.phone3 = data.phone.slice(6, 10)
      }
      if (data.phone.length === 11) {
        data.phone2 = data.phone.slice(3, 7)
        data.phone3 = data.phone.slice(7, 11)
      }
      data.agreeAdsEmail = data.agree_ad_email
      data.agreeAdsSMS = data.agree_ad_sms
      data.agreeMarketing = data.agree_marketing

      setUser(data)

      setImage(getS3ImageSrc(data.photo))
      reset(data)
    })
  }, [])

  useEffect(() => {
    setValue('phone2', getValues('phone2').replace(/[^0-9]/g, ''))
    setValue('phone3', getValues('phone3').replace(/[^0-9]/g, ''))
    if (watch('phone2') === '' && watch('phone3') === '') {
      setValue('phone', '')
      clearErrors('phone')
    }
  }, [watch('phone2'), watch('phone3')])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'category',
  })

  useEffect(() => {})

  const onSubmit = values => {
    if (
      !userAtomState.login_sns &&
      values.newPassword.length > 0 &&
      values.newPassword.length < 3
    ) {
      showConfirm({ content: t('profile.profile_warning_3'), alertOnly: true })
      return
    }

    delete values.checkedPassword
    delete values.credit
    delete values.grade
    delete values.is_admin
    delete values.role
    delete values.photo

    // if (values.phone.length === 0) {
    //   values.phone = null
    //   setValue('phone', '')
    // }

    // if (values.website.length === 0) {
    //   values.website = null
    //   setValue('website', '')
    // }

    const body = new FormData()
    Object.entries(values).forEach(item => body.append(item[0], item[1]))
    if (fileInput.current.files[0]) {
      body.append('photo', fileInput.current.files[0])
    }

    body.delete('category')

    body.delete('newPassword')
    body.append('new_password', values.newPassword)

    body.append('agree_ad_email', values.agreeAdsEmail)
    body.append('agree_ad_sms', values.agreeAdsSMS)
    body.append('agree_marketing', values.agreeMarketing)

    // values.category.forEach(v => body.append(`category`, v))

    apis.user
      .updateUser(body)
      .then(() => {
        showConfirm({
          content: t('profile.profile_edit_complete'),
          alertOnly: true,
          onConfirm: () => {
            apis.user
              .getUser()
              .then(response => {
                setUser(response.data)
                setUserAtomState(response.data)
                setImage(getS3ImageSrc(response.data.photo))
              })
              .then(() => {
                window.location.reload()
              })
          },
        })
      })
      .catch(() => {
        showConfirm({
          content: t('profile.profile_warning_4'),
          alertOnly: true,
        })
      })
  }

  const handleCategoryChange = (category, isChecked) => {
    // console.log(category, isChecked)
    if (isChecked) {
      append(category.id)
    } else {
      const selectedArray = getValues('category')
      const index = selectedArray.findIndex(item => item === category.id)
      remove(index)
    }
  }

  // useEffect(() => {
  //   setValue(
  //     'phone',
  //     getValues('phone')
  //       ?.replace(/[^0-9]/g, '')
  //       .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
  //       .replace(/(-{1,2})$/g, '')
  //   )
  // }, [watch('phone')])

  const onChange = event => {
    const state = event.target.checked

    setValue('agreeAdsSMS', state)
    setValue('agreeAdsEmail', state)
  }

  const submitErrorHandler = () => {
    const anchor = document.querySelector(`#anchor_profile`)

    anchor.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  const deleteAccount = () => {
    const planId = currentSubscription?.plan_id
    const currentSubscriptionEndDate = currentSubscription?.end_date
    const nextSubscription = currentSubscription?.next_subscription ?? {}

    const isSubscription = Boolean(
      (planId && !currentSubscriptionEndDate) || Object.keys(nextSubscription).length
    )
    const hasCredit = Boolean(userCredit > 0 ?? false)

    // ----- GA4 event -----
    window.gtag('event', 'withdrawal_begin', {})
    // ---------------------

    // if (isSubscription || hasCredit) {
    //   setOpenUnsubscribeDialog(true)
    // } else {
    //   if (!userAtomState.login_sns) {
    //     setOpenCheckPasswordDialog(true)
    //   } else {
    //     setOpenSelectReasonDialog(true)
    //   }
    // }

    setOpenShowUserStateDialog(true)
  }

  const endNCommerceSubscription = () => {
    showConfirm({
      alertOnly: true,
      content: '네이버 커머스 솔루션 연동 해지는 커머스솔루션마켓에서 가능합니다.',
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, submitErrorHandler)}
      autoComplete="off"
      style={{ overflow: 'hidden', width: { lg: MYPAGE_PERCENTAGE_WIDHT, xs: '100%' } }}
      id="anchor_profile"
    >
      <CenterAlignStack
        sx={{
          width: '100%',
          pl: { lg: MYPAGE_SIDE_MARGIN },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            paddingBottom: { lg: '2.6rem', xs: '2rem' },
          }}
        >
          <Avatar
            src={image}
            sx={{
              width: { lg: '16.8rem', xs: '13rem' },
              height: { lg: '16.8rem', xs: '13rem' },
              position: 'relative',
            }}
          />

          <input
            type="file"
            style={{ display: 'none' }}
            accept={allowedTypes.join(', ')}
            onChange={handleProfileImage}
            id="user-photo-file"
            ref={fileInput}
          />
          <label htmlFor="user-photo-file">
            <IconButton
              onClick={handleClick}
              sx={{ position: 'absolute', bottom: { lg: '1.3rem', xs: '0.7rem' }, right: '-1rem' }}
            >
              <Desktop>
                <CameraIcon />
              </Desktop>

              <Mobile>
                <CameraIcon style={{ width: '4rem', height: '4rem' }} />
              </Mobile>
            </IconButton>
          </label>
        </Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: { lg: '2rem', xs: '1.6rem' },
            lineHeight: { lg: '2.4rem', xs: '1.7rem' },
          }}
        >
          {user.username}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: { lg: '1.8rem', xs: '1.4rem' } }}>
          {user.email}
        </Typography>
        <Desktop>
          <CenterAlignStack sx={{ width: '100%', pb: '6.8rem', pt: '3.7rem' }}>
            <Stack sx={{ gap: '5px' }}>
              {!userAtomState.login_sns && (
                <>
                  <RegisterBox>
                    <RegisterLabel required={true} label={t('profile.old_password')} />
                    <TextInput
                      name="password"
                      autoComplete="current-password"
                      type={showPassword.password ? 'text' : 'password'}
                      control={control}
                      error={!!errors.password}
                      englishMode={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() =>
                                setShowPassword(prev => ({ ...prev, password: !prev.password }))
                              }
                            >
                              {showPassword.password ? <AiFillEye /> : <AiFillEyeInvisible />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('profile.old_password')}
                    />
                  </RegisterBox>
                  {errors.password && <FormErrorMessage message={errors.password?.message} />}
                  <RegisterBox>
                    <RegisterLabel label={t('profile.new_password')} />
                    <TextInput
                      name="newPassword"
                      autoComplete="current-password"
                      type={showPassword.newPassword ? 'text' : 'password'}
                      control={control}
                      error={!!errors.newPassword}
                      englishMode={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() =>
                                setShowPassword(prev => ({
                                  ...prev,
                                  newPassword: !prev.newPassword,
                                }))
                              }
                            >
                              {showPassword.newPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('profile.new_password')}
                    />
                  </RegisterBox>
                  {errors.newPassword && <FormErrorMessage message={errors.newPassword?.message} />}
                  <RegisterBox>
                    <RegisterLabel label={t('profile.new_password_check')} />
                    <TextInput
                      name="checkedPassword"
                      autoComplete="current-password"
                      type={showPassword.checkedPassword ? 'text' : 'password'}
                      control={control}
                      error={!!errors.checkedPassword}
                      englishMode={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() =>
                                setShowPassword(prev => ({
                                  ...prev,
                                  checkedPassword: !prev.checkedPassword,
                                }))
                              }
                            >
                              {showPassword.checkedPassword ? (
                                <AiFillEye />
                              ) : (
                                <AiFillEyeInvisible />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('profile.new_password_check')}
                    />
                  </RegisterBox>
                  {errors.checkedPassword && (
                    <FormErrorMessage message={errors.checkedPassword?.message} />
                  )}
                </>
              )}
              <RegisterBox>
                <RegisterLabel required={false} label={t('register.nickname')} />
                <TextInput
                  control={control}
                  name="nickname"
                  error={!!errors.nickname}
                  placeholder={t('register.nickname')}
                />
              </RegisterBox>
              {errors.nickname && <FormErrorMessage message={errors.nickname?.message} />}

              <RegisterBox>
                <FlexBasis
                  flexBasis={['46%', '54%']}
                  sx={{ display: 'flex', justifyContent: ' center' }}
                >
                  <RegisterLabel required={true} label={t('register.mobile')} />
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: '2rem' }}>
                    <PhoneInput
                      control={control}
                      name="phone1"
                      sx={{ width: '10rem' }}
                      inputProps={{ maxLength: 3 }}
                      error={!!errors.phone}
                      onChange={e => {
                        setValue('phone1', e.target.value)
                        setValue('phone', e.target.value + watch('phone2') + watch('phone3'))
                      }}
                    />
                    <Typography px={1.4}>-</Typography>
                    <PhoneInput
                      control={control}
                      name="phone2"
                      inputProps={{ maxLength: 4 }}
                      error={!!errors.phone}
                      onChange={e => {
                        setValue('phone2', e.target.value)
                        setValue('phone', watch('phone1') + e.target.value + watch('phone3'))
                      }}
                    />
                    <Typography px={1.4}>-</Typography>
                    <PhoneInput
                      control={control}
                      name="phone3"
                      inputProps={{ maxLength: 4 }}
                      error={!!errors.phone}
                      onChange={e => {
                        setValue('phone3', e.target.value)
                        setValue('phone', watch('phone1') + watch('phone2') + e.target.value)
                      }}
                    />
                  </Box>

                  {/* <TextInput
                control={control}
                name="phone"
                placeholder="휴대폰 번호"
                inputProps={{ maxLength: 13 }}
                error={!!errors.phone}
              /> */}
                </FlexBasis>
              </RegisterBox>
              {errors.phone && <FormErrorMessage message={errors.phone?.message} />}
              <RegisterBox>
                <RegisterLabel required={false} label={t('register.website')} />
                <TextInput
                  control={control}
                  name="website"
                  error={!!errors.website}
                  placeholder={t('register.website_placeholder')}
                />
              </RegisterBox>
              {errors.website && <FormErrorMessage message={errors.website?.message} />}
            </Stack>
          </CenterAlignStack>

          <Stack sx={{ mt: '3%', position: 'relative' }}>
            {' '}
            <FormCheckbox
              sx={{ mb: '1rem' }}
              name="agreeMarketing"
              control={control}
              label={
                <Typography
                  sx={{
                    ...agreeLabelStyle,
                  }}
                >
                  &nbsp;{t('register.agree_marketing')}
                  <AgreeDialog
                    category="agreeMarketing"
                    openButton={
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    }
                  />
                </Typography>
              }
              checked={watch('agreeMarketing') || false}
            />
            <FormControlLabel
              sx={{
                marginLeft: '-8px',
                marginRight: '16px',
                marginBottom: '0.2rem',
              }}
              label={
                <Typography
                  sx={{
                    ...agreeLabelStyle,
                  }}
                >
                  &nbsp;{t('register.agree_ads')}
                  <AgreeDialog
                    category="agreeAdvertisement"
                    openButton={
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    }
                  />
                </Typography>
              }
              control={
                <Checkbox
                  icon={<AgreeSquareIcon />}
                  checkedIcon={<AgreeCheckedSquareIcon />}
                  checked={(watch('agreeAdsEmail') && watch('agreeAdsSMS')) || false}
                  indeterminate={!(watch('agreeAdsEmail') && watch('agreeAdsSMS'))}
                  indeterminateIcon={<AgreeSquareIcon />}
                  onChange={onChange}
                />
              }
            />
            {/* <Divider sx={{ mt: '0rem', mb: '1rem' }} /> */}
            <Stack sx={{ ml: '3rem' }}>
              <FormCheckbox
                name="agreeAdsEmail"
                control={control}
                label={
                  <Typography
                    sx={{
                      ...agreeSubLabelStyle,
                    }}
                  >
                    &nbsp;{t('profile.agreeAdsEmail')}
                  </Typography>
                }
                checked={watch('agreeAdsEmail') || false}
                sx={{ mb: '0.7rem', pl: '4.1rem', backgroundColor: theme.palette.common.lightgray }}
              />
              <FormCheckbox
                name="agreeAdsSMS"
                control={control}
                label={
                  <Typography
                    sx={{
                      ...agreeSubLabelStyle,
                    }}
                  >
                    &nbsp;{t('profile.agreeAdsSMS')}
                  </Typography>
                }
                checked={watch('agreeAdsSMS') || false}
                sx={{ pl: '4.1rem', backgroundColor: theme.palette.common.lightgray }}
              />
            </Stack>
            <Button
              variant="text"
              sx={{
                position: 'absolute',
                color: '#808080',
                borderBottom: '1px solid #808080',
                top: isKo(i18n) ? '28.7rem' : '27rem',
                left: isKo(i18n) ? '62rem' : '75rem',
                width: 'max-content',
                fontWeight: 400,
                fontSize: '1.8rem',
                borderRadius: 0,
                padding: '0',
                lineHeight: 1.8,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={deleteAccount}
            >
              &nbsp;{t('profile.withdraw')}&nbsp;
            </Button>
          </Stack>
          <LoadingButton
            size="large"
            type="submit"
            // onClick={handleClick}
            // endIcon={<SendIcon />}
            loading={isSubmitting}
            // loadingPosition="end"
            variant="contained"
            sx={{
              width: '38.4rem',
              color: theme.palette.common.white,
              mt: '5.6rem',
              mb: '8.2rem',
            }}
          >
            &nbsp;{t('profile.submit')}
          </LoadingButton>

          {isNCommerceUser && (
            <Button
              variant="text"
              sx={{
                color: '#808080',
                borderBottom: '1px solid #808080',
                width: 'max-content',
                fontWeight: 400,
                fontSize: '1.8rem',
                borderRadius: 0,
                padding: 0,
                mt: '-4rem',
                mb: '3rem',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={endNCommerceSubscription}
            >
              네이버 커머스 솔루션 연동 해지하기
            </Button>
          )}
        </Desktop>
        <Mobile>
          <CenterAlignStack sx={{ width: '100%', pt: '2.7rem' }}>
            <Stack>
              {!userAtomState.login_sns && (
                <>
                  <Box display="flex" sx={{ mb: '0.8rem' }}>
                    <RegisterLabel required={true} label={t('profile.old_password')} />
                    <FormErrorMessage message={errors.password?.message} />
                  </Box>
                  <TextInput
                    name="password"
                    autoComplete="current-password"
                    type={showPassword.password ? 'text' : 'password'}
                    control={control}
                    error={!!errors.password}
                    englishMode={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() =>
                              setShowPassword(prev => ({ ...prev, password: !prev.password }))
                            }
                          >
                            {showPassword.password ? <AiFillEye /> : <AiFillEyeInvisible />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('profile.old_password')}
                  />
                  <Box display="flex" sx={{ mb: '0.8rem' }}>
                    <RegisterLabel label={t('profile.new_password')} />
                    <FormErrorMessage message={errors.newPassword?.message} />
                  </Box>
                  <TextInput
                    name="newPassword"
                    autoComplete="current-password"
                    type={showPassword.newPassword ? 'text' : 'password'}
                    control={control}
                    error={!!errors.newPassword}
                    englishMode={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() =>
                              setShowPassword(prev => ({ ...prev, newPassword: !prev.newPassword }))
                            }
                          >
                            {showPassword.newPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('profile.new_password')}
                  />
                  <Box display="flex" sx={{ mb: '0.8rem' }}>
                    <RegisterLabel label={t('profile.new_password_check')} />
                    <FormErrorMessage message={errors.checkedPassword?.message} />
                  </Box>
                  <TextInput
                    name="checkedPassword"
                    autoComplete="current-password"
                    type={showPassword.checkedPassword ? 'text' : 'password'}
                    control={control}
                    error={!!errors.checkedPassword}
                    englishMode={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() =>
                              setShowPassword(prev => ({
                                ...prev,
                                checkedPassword: !prev.checkedPassword,
                              }))
                            }
                          >
                            {showPassword.checkedPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('profile.new_password_check')}
                  />
                </>
              )}

              <Box display="flex" sx={{ mb: '0.8rem' }}>
                <RegisterLabel required={false} label={t('register.nickname')} />
                <FormErrorMessage message={errors.nickname?.message} />
              </Box>
              <TextInput control={control} name="nickname" error={!!errors.nickname} />

              <Box display="flex" sx={{ mb: '0.8rem' }}>
                <RegisterLabel required={false} label={t('register.mobile')} />
                <FormErrorMessage message={errors.phone?.message} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <PhoneInput
                  control={control}
                  name="phone1"
                  sx={{ width: '10rem' }}
                  inputProps={{ maxLength: 3 }}
                  error={!!errors.phone1}
                  placeholder="010"
                  disabled
                />
                <hr
                  style={{
                    width: '1.2rem',
                    margin: '0px 1.1rem 1.8rem 1.1rem',
                    border: '0.4px solid black',
                  }}
                />
                <PhoneInput
                  control={control}
                  name="phone2"
                  inputProps={{ maxLength: 4 }}
                  error={!!errors.phone2}
                  onChange={e => {
                    setValue('phone2', e.target.value)
                    setValue('phone', watch('phone1') + e.target.value + watch('phone3'))
                  }}
                />
                <hr
                  style={{
                    width: '1.2rem',
                    margin: '0px 1.1rem 1.8rem 1.1rem',
                    border: '0.4px solid black',
                  }}
                />
                <PhoneInput
                  control={control}
                  name="phone3"
                  inputProps={{ maxLength: 4 }}
                  error={!!errors.phone3}
                  onChange={e => {
                    setValue('phone3', e.target.value)
                    setValue('phone', watch('phone1') + watch('phone2') + e.target.value)
                  }}
                />
              </Box>
              <Box display="flex" sx={{ mb: '0.8rem' }}>
                <RegisterLabel required={false} label={t('register.website')} />
                <FormErrorMessage message={errors.website?.message} />
              </Box>
              <TextInput control={control} name="website" error={!!errors.website} />
            </Stack>
          </CenterAlignStack>

          <Stack sx={{ width: '36rem', padding: '0 2rem' }}>
            <FormCheckbox
              sx={{ mb: '1rem' }}
              name="agreeMarketing"
              control={control}
              smallIcon={true}
              label={
                <Typography
                  sx={{
                    ...mobileAgreeLabelStyle,
                  }}
                >
                  &nbsp;{t('register.agree_marketing')}
                  <AgreeDialog
                    category="agreeMarketing"
                    openButton={
                      <IconButton>
                        <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                      </IconButton>
                    }
                  />
                </Typography>
              }
              checked={watch('agreeMarketing') || false}
            />
            <FormControlLabel
              sx={{ marginLeft: '-11px', marginRight: '16px', marginBottom: '0.2rem' }}
              label={
                <Typography
                  sx={{
                    ...mobileAgreeLabelStyle,
                  }}
                >
                  &nbsp;{t('register.agree_ads')}
                  <AgreeDialog
                    category="agreeAdvertisement"
                    openButton={
                      <IconButton>
                        <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                      </IconButton>
                    }
                  />
                </Typography>
              }
              control={
                <Checkbox
                  icon={<SquareIcon2 />}
                  checkedIcon={<CheckedSquareIcon2 />}
                  checked={(watch('agreeAdsEmail') && watch('agreeAdsSMS')) || false}
                  indeterminate={!(watch('agreeAdsEmail') && watch('agreeAdsSMS'))}
                  indeterminateIcon={<SquareIcon2 />}
                  onChange={onChange}
                />
              }
            />
            {/* <Divider sx={{ mt: '0rem', mb: '1rem' }} /> */}
            <Stack>
              <FormCheckbox
                name="agreeAdsEmail"
                control={control}
                label={
                  <Typography
                    sx={{
                      ...mobileAgreeSubLabelStyle,
                    }}
                  >
                    &nbsp;{t('profile.agreeAdsEmail')}
                  </Typography>
                }
                checked={watch('agreeAdsEmail') || false}
                sx={{ mb: '0.7rem', pl: '4.1rem', backgroundColor: theme.palette.common.lightgray }}
              />
              <FormCheckbox
                name="agreeAdsSMS"
                control={control}
                label={
                  <Typography
                    sx={{
                      ...mobileAgreeSubLabelStyle,
                    }}
                  >
                    &nbsp;{t('profile.agreeAdsSMS')}
                  </Typography>
                }
                checked={watch('agreeAdsSMS') || false}
                sx={{ pl: '4.1rem', backgroundColor: theme.palette.common.lightgray }}
              />
            </Stack>
          </Stack>
          <Box sx={{ position: 'relative' }}>
            <LoadingButton
              size="large"
              type="submit"
              // onClick={handleClick}
              // endIcon={<SendIcon />}
              loading={isSubmitting}
              // loadingPosition="end"
              variant="contained"
              sx={{
                width: '32rem',
                height: ' 4.8rem',
                color: theme.palette.common.white,
                mt: '4rem',
                mb: '10.7rem',
              }}
            >
              &nbsp;{t('profile.submit')}
            </LoadingButton>
            <Button
              variant="text"
              sx={{
                position: 'absolute',
                color: '#808080',
                borderBottom: '1px solid #808080',
                right: 0,
                bottom: '3.1rem',
                width: 'max-content',
                fontWeight: 400,
                fontSize: '1.8rem',
                padding: 0,
                borderRadius: 0,
                lineHeight: 1.8,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={deleteAccount}
            >
              &nbsp;{t('profile.withdraw')}
            </Button>
          </Box>
        </Mobile>

        <ShowUserState
          open={openShowUserStateDialog}
          setOpen={setOpenShowUserStateDialog}
          openNextDialog={openCheckPasswordDialog}
          setOpenNextDialog={setOpenCheckPasswordDialog}
          openNextDialogforSNS={openLeaveNoticeDialog}
          setOpenNextDialogforSNS={setOpenLeaveNoticeDialog}
          currentUser={currentUser}
          currentSubscription={currentSubscription}
          userCredit={userCredit}
        />

        {openCheckPasswordDialog && (
          <CheckPasswordDialog
            open={openCheckPasswordDialog}
            setOpen={setOpenCheckPasswordDialog}
            openNextDialog={openLeaveNoticeDialog}
            setOpenNextDialog={setOpenLeaveNoticeDialog}
            type="withdrawal" // GA4용.. 이 페이지(회원정보수정)에서 비밀번호 입력하는 경우가 탈퇴밖에 없어서 이렇게 처리! 변경사항 있으면 수정해야합니다
          />
        )}

        <LeaveNoticeDialog
          open={openLeaveNoticeDialog}
          setOpen={setOpenLeaveNoticeDialog}
          openNextDialog={openSelectReasonDialog}
          setOpenNextDialog={setOpenSelectReasonDialog}
          currentUser={currentUser}
          currentSubscription={currentSubscription}
          userCredit={userCredit}
        />

        <SelectReasonDialog
          options={options}
          open={openSelectReasonDialog}
          setOpen={setOpenSelectReasonDialog}
          openNextDialog={openLeaveDialog}
          setOpenNextDialog={setOpenLeaveDialog}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          etcText={etcText}
          setEtcText={setEtcText}
        />

        <LeaveDialog
          options={options}
          open={openLeaveDialog}
          setOpen={setOpenLeaveDialog}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          etcText={etcText}
          setEtcText={setEtcText}
          type="withdrawal"
        />
      </CenterAlignStack>
    </form>
  )
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

// function LeaveNoticeDialog({ open, setOpen, openNextDialog, setOpenNextDialog }) {
//   const theme = useTheme()

//   const handleAction = () => {
//     setOpen(false)
//     setOpenNextDialog(true)
//   }

//   const handleClose = () => {
//     setOpen(false)
//   }

//   const inputFontStyle = { fontSize: '1.4rem', fontWeight: 500 }

//   return (
//     <>
//       <Desktop>
//         <Dialog
//           open={open}
//           onClose={handleClose}
//           TransitionComponent={Transition}
//           sx={{
//             '& .MuiPaper-root.MuiDialog-paper': {
//               width: { lg: '48rem', xs: '320px' },
//               margin: { lg: '20px', xs: 0 },
//               borderRadius: '20px',
//             },
//           }}
//         >
//           <DialogContent
//             sx={{
//               overflowY: 'hidden',
//               padding: { lg: '3rem 0', xs: '33px 0 25px 0' },
//             }}
//           ></DialogContent>
//         </Dialog>
//       </Desktop>
//       <Mobile>
//         <MobileSwipeableDrawer
//           open={open}
//           onOpen={() => {
//             return open
//           }}
//           onClose={handleClose}
//         ></MobileSwipeableDrawer>
//       </Mobile>
//     </>
//   )
// }
