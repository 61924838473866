import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CenterAlignStack, PricingCard, CenterAlignBox } from 'components'
import { usePricing } from 'hooks/usePricing'
import { useRecoilValue, useRecoilState } from 'recoil'
import { userSubInfoAtom, partnerPlansAtom, userAtom } from 'atoms'
import { useTranslation } from 'react-i18next'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { English, Korean } from 'hooks/useLanguage'
import { CheckIcon } from 'theme/icon'
import { utcToLocal } from 'utils/common'

export function PartnersPromotionPricing() {
  const location = useLocation()
  const navigate = useNavigate()

  const [info, setInfo] = useState()
  const [userSubId, setUserSubId] = useState(null)

  const userSubInfo = useRecoilValue(userSubInfoAtom)
  const userInfo = useRecoilValue(userAtom)

  const [partnerPlans, setPartnerPlans] = useRecoilState(partnerPlansAtom)

  const { requestPay } = usePricing()
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    return () => {
      setPartnerPlans({})
    }
  }, [])

  useEffect(() => {
    if (location.state?.code) {
      setInfo(location.state)
    } else {
      navigate('/pricing', { replace: true })
    }
  }, [location.state])

  useEffect(() => {
    if (
      info?.code
      // && info?.partner?.id
    ) {
      getPromotionData()
    }
  }, [info])

  useEffect(() => {
    if (userInfo.id) {
      if (userSubInfo?.plan_id) {
        setUserSubId(userSubInfo.plan_id)
      }
    }
  }, [userInfo, userSubInfo])

  const getPromotionData = () => {
    apis.partners
      .getPromotionPricing({
        code: info?.code,
        partner_id: info?.partner?.id,
      })
      .then(response => {
        if (response.data) {
          const d = response.data

          const subs = d.filter(p => p.plan_type.includes('subscription'))
          const payGo = d.filter(p => p.plan_type.includes('paygo'))

          const p = {}

          if (subs.length > 0) {
            p.subscription = subs
          }

          if (payGo.length > 0) {
            p.payGo = payGo
          }

          if (subs.length < 1 && payGo.length < 1) {
            alert('잘못된 접근입니다.')
            navigate('/pricing', { replace: true })
          }

          setPartnerPlans(p)
        }
      })
      .catch(e => {
        alert('잘못된 접근입니다.')
        navigate('/pricing', { replace: true })
      })
  }

  return (
    <>
      <CenterAlignStack sx={{ mb: { lg: '0.8rem', xs: '1.2rem' }, px: { lg: 0, xs: '2rem' } }}>
        <Typography
          sx={{
            pt: { lg: '5.2rem', xs: '3.6rem' },
            lineHeight: 'normal',
            pb: '1.6rem',
            fontSize: { lg: '3.6rem', xs: '2.2rem' },
            fontWeight: 800,
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
          }}
          component="div"
        >
          {t('pricing.title_1') + (isMobile ? '\n' : '') + t('pricing.title_2')}
        </Typography>
        <Box
          sx={{
            mb: { lg: '3rem', xs: '1.6rem' },
            display: 'flex',
            flexDirection: 'column',
            gap: { lg: '0.3rem', xs: '0.2rem' },
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: '1.6rem', xs: '1.2rem' },
              fontWeight: 400,
              lineHeight: 'normal',
              '& span': { fontWeight: 600 },
            }}
          >
            <CheckIcon />
            <span
              style={{
                display: 'inline-block',
                width: isMobile ? '0.3rem' : '0.8rem',
              }}
            />
            <Korean>
              {t('pricing.subtitle_1_a')}
              <span>
                {t('pricing.subtitle_1_b')}
                {t('pricing.subtitle_1_c')}
              </span>
              {t('pricing.subtitle_1_d')}
            </Korean>

            <English>
              {t('pricing.subtitle_1_a')}
              {isMobile && <br />}
              <span style={{ fontWeight: 400, marginLeft: isMobile ? '1.7rem' : '0' }}>
                {t('pricing.subtitle_1_b')}
              </span>
              <span>{t('pricing.subtitle_1_c')}</span>
              {t('pricing.subtitle_1_d')}
            </English>
          </Typography>
          <Typography
            sx={{
              fontSize: { lg: '1.6rem', xs: '1.2rem' },
              fontWeight: 400,
              '& span': { fontWeight: 600 },
            }}
            component="div"
          >
            <CheckIcon />

            <Korean>
              <span
                style={{
                  display: 'inline-block',
                  width: isMobile ? '0.3rem' : '0.8rem',
                }}
              />
              {t('pricing.subtitle_2_a')} <span> {t('pricing.subtitle_2_b')} </span>{' '}
              {t('pricing.subtitle_2_c')}
              {isMobile ? <br /> : ' '}
              <span style={{ marginLeft: isMobile ? '1.7rem' : 0, fontWeight: 400 }}>
                {t('pricing.subtitle_2_d')}
              </span>
            </Korean>
            <English>
              <span
                style={{
                  display: 'inline-block',
                  width: isMobile ? '0.3rem' : '0.8rem',
                }}
              />
              <span>{t('pricing.subtitle_2_a')}</span> {t('pricing.subtitle_2_b')}
              {isMobile ? <br /> : ' '}
              <span style={{ marginLeft: isMobile ? '1.7rem' : 0, fontWeight: 400 }}>
                {t('pricing.subtitle_2_c')}
              </span>
              {isMobile ? <br /> : ' '}
              <span style={{ marginLeft: isMobile ? '1.7rem' : 0, fontWeight: 400 }}>
                {t('pricing.subtitle_2_d')}
              </span>
            </English>
          </Typography>

          {info?.end_date && (
            <Stack sx={{ mt: '1rem' }}>
              <Typography
                sx={{
                  fontSize: { lg: '1.6rem', xs: '1.2rem' },
                  fontWeight: 400,
                  // '& svg path': { fill: 'red' },
                  '& span': { fontWeight: 600 },
                  '& .highlight': {
                    background: theme => theme.palette.draph.lighterblue,
                    py: '2px',
                    px: '4px',
                  },
                }}
                component="div"
              >
                <CheckIcon />
                <span
                  style={{
                    display: 'inline-block',
                    width: isMobile ? '0.3rem' : '0.8rem',
                  }}
                />
                <span>
                  <Korean>본 요금제는 한시적으로만 결제 가능합니다.</Korean>
                  <English></English>
                </span>
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '1.6rem', xs: '1.2rem' },
                  fontWeight: 400,
                  // '& svg path': { fill: 'red' },
                  '& span': { fontWeight: 600 },
                  '& .highlight': {
                    background: theme => theme.palette.draph.lighterblue,
                    py: '2px',
                    px: '4px',
                  },
                }}
                component="div"
              >
                <CheckIcon />
                <span
                  style={{
                    display: 'inline-block',
                    width: isMobile ? '0.3rem' : '0.8rem',
                  }}
                />
                <Korean>결제 가능 기간 : </Korean>
                <English></English>
                <span className="highlight">{utcToLocal(info.start_date, 'YYYY.MM.DD')}</span>
                {' - '}
                <span className="highlight">{utcToLocal(info.end_date, 'YYYY.MM.DD')}</span>
              </Typography>
            </Stack>
          )}
        </Box>

        <CenterAlignBox gap={4} sx={{ mt: '2rem', mb: '4.5rem' }}>
          {Object.keys(partnerPlans).map(p => (
            <PricingCard
              key={p}
              name={p}
              plan={partnerPlans[p]}
              requestPay={requestPay}
              userSubId={userSubId}
              userSubInfo={userSubInfo}
              setOpenNonMemberDialog={() => {}}
              highlight={p === 'subscription'}
              requestPaypal={() => {
                // 지원하지 않음
              }}
              partnerPlans={partnerPlans}
            />
          ))}
        </CenterAlignBox>
      </CenterAlignStack>
    </>
  )
}
