import { Box, Typography, useTheme } from '@mui/material'
import {
  PORTFOLIO_CONFIG_DEFAULT,
  currentMenuAtom,
  defaultPortfolioAtom,
  dragDropOpenAtom,
  isBulkUploadAtom,
  portfolioArtworkAtom,
  portfolioConfigAtom,
  portfolioDetailAtom,
  portfolioTypeAtom,
  uploadDialogOpenAtom,
  uploadFilesAndUrlAtom,
  uploadFilesAtom,
  userAtom,
} from 'atoms'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'

import * as config from 'config'
import { useCheckImage, useRefineUploadFiles } from 'hooks/useRefineUploadFiles'

import { DragAndDropEdge } from 'theme/icon'
import { CenterAlignStack } from './CenterAlignContainer'
import { useTranslation } from 'react-i18next'
import useConfirm from 'hooks/useConfirm'
import { PORTFOLIO_TYPE_UPLOAD, PORTFOLIO_TYPE_NORMAL } from 'config'

import { apis } from 'apis'

export function DragDropFullSize({ uploadImgLimit = 20 }) {
  const checkImage = useCheckImage(uploadImgLimit)
  const refineUploadFiles = useRefineUploadFiles(uploadImgLimit)

  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const user = useRecoilValue(userAtom)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isBulkUpload, setIsBulkUpload] = useRecoilState(isBulkUploadAtom)
  const [uploadFiles, setUploadFiles] = useRecoilState(uploadFilesAtom)

  const { showConfirm } = useConfirm()

  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const portfolioArtworklimit = portfolioDetail.is_default ? Infinity : 200

  const portfolioArtwork = useRecoilValue(portfolioArtworkAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const resetDragDropOpen = useResetRecoilState(dragDropOpenAtom)
  const resetUploadFiles = useResetRecoilState(uploadFilesAtom)
  const resetUploadOpen = useResetRecoilState(uploadDialogOpenAtom)
  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const currentMenu = useRecoilValue(currentMenuAtom)
  const isPortfolioDetail = currentMenu.id === 'portfolio'

  const isSegment = portfolioType === config.PORTFOLIO_TYPE_FACE
  const isNotShowConfig = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION
  const segmentService = portfolioType === config.PORTFOLIO_TYPE_FACE ? 'face' : 'mannequin'

  useEffect(() => {
    return () => {
      resetDragDropOpen()
      if (isPortfolioDetail) {
        // resetUploadFiles()
        // resetUploadOpen()
      }
    }
  }, [])

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover' || e.type === 'dragmove') {
      setDragDropOpen(true)
    } else if (e.type === 'dragleave') {
      // console.log('leave')
      setDragDropOpen(false)
    }
  }

  // const handlePortfolioDetail = file => {
  //   const allowedTypes = ['application/zip', 'application/x-zip-compressed']
  //   if (file && allowedTypes.includes(file.type)) {
  //     setFiles(file)
  //   } else {
  //     showConfirm({ content: '확장자(.zip)을 확인해주세요 !', alertOnly: true })
  //   }
  // }

  const handleDrop = async function (e) {
    console.log('drop?')
    e.preventDefault()
    setDragDropOpen(false)

    if (e.dataTransfer.files) {
      if (!isBulkUpload) {
        const uploadFiles = Array.prototype.slice.call(e.dataTransfer.files)

        if (isPortfolioDetail) {
          refineUploadFiles(uploadFiles)
          setUploadOpen(true)
        } else {
          checkImage({
            uploadFiles: uploadFiles,
            files,
            setFiles,
            mannequinMode: isSegment,
            keepShowingOn: !(isSegment || isNotShowConfig),
            segmentService: segmentService,
          })
        }
      } else {
        const uploadFiles = Array.prototype.slice.call(e.dataTransfer.files, 0, 1)

        const allowedTypes = ['application/zip', 'application/x-zip-compressed']
        const zipfiles = uploadFiles.filter(f => allowedTypes.includes(f.type)).slice(0, 1)
        const otherfiles = uploadFiles.filter(f => !allowedTypes.includes(f.type))

        if (otherfiles.length) {
          showConfirm({
            content: (
              <Typography>
                {t('upload_dialog.warning_6')}
                <br />
                {t('upload_dialog.warning_7')}
              </Typography>
            ),
            alertOnly: true,
          })
        }

        if (zipfiles.length) {
          setFiles(zipfiles)
        }
      }
      e.dataTransfer.value = ''
    }

    // ----- GA4 event -----
    window.gtag('event', 'drag_and_drop', {
      menu: currentMenu?.id,
    })
    // ---------------------
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          zIndex: 9999,
          inset: 0,
          backgroundColor: 'rgba(0, 0, 0, 0);',
          display: dragDropOpen ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      ></Box>

      <Box
        sx={{
          position: 'fixed',
          zIndex: 9997,
          inset: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.60);',
          display: dragDropOpen ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DragAndDropEdge style={{ position: 'absolute', top: 48, left: 48 }} />
        <DragAndDropEdge
          style={{ position: 'absolute', top: 48, right: 48, transform: 'rotate(90deg)' }}
        />
        <DragAndDropEdge
          style={{ position: 'absolute', bottom: 48, right: 48, transform: 'rotate(180deg)' }}
        />
        <DragAndDropEdge
          style={{ position: 'absolute', bottom: 48, left: 48, transform: 'rotate(270deg)' }}
        />

        <CenterAlignStack>
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: '8.5rem',
              lineHeight: 'normal',
              color: theme.palette.common.white,
            }}
          >
            Drag & Drop
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: '6rem', color: theme.palette.common.white }}>
            {t('drag_drop')}
          </Typography>
        </CenterAlignStack>
      </Box>
    </>
  )
}
