import { MHidden } from 'components/@material-extend'
import {
  Box,
  Typography,
  Checkbox,
  Grid,
  Divider,
  IconButton,
  Stack,
  TextField,
  Pagination,
  Button,
  Select,
  MenuItem,
  ToggleButton,
  Backdrop,
  CircularProgress,
  Skeleton,
} from '@mui/material'
import {
  CenterAlignBox,
  CenterAlignStack,
  TempLayoutDiv,
  FlexBasis,
  ArtworksImageSwiperSlideDialog,
  RegenerateDialog,
  AddPieceDialog,
  SingleImageDialog,
  CustomPagination,
  SearchInputMobile,
  ScrollToTop,
  CustomTooltip,
  SizeControlDialog,
  ControlSizeDialog,
  GIFDialog,
  CreateBgForNukkiDialog,
  TwoImageSlider,
  NaverProductButton,
  ArtowrkDownloadButton,
  RegenerateButton,
  DeleteSelectedButton,
  IntroduceGIF,
  MoveArtworkToPortfolio,
  OptionViewerDialog,
  triggerGA4DownloadEvent,
  getGA4EventNameByPortfolioType,
  CreatePortfolioWithConfigDialog,
  BannerGeneratedImageLayout,
  BannerControlBoxLayout,
  ImageSwiperDialog2,
} from 'components'

import {
  selector,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import {
  artworkPagingSelector,
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
  sortedPiecesArtworkListSelector,
} from 'selector'
import {
  portfolioAtom,
  portfolioDetailAtom,
  portfolioArtworkAtom,
  artworkInViewAtom,
  userAtom,
  confirmDialogAtom,
  artworkViewConfigAtom,
  artworkRegenCountAtom,
  portfolioConfigAtom,
  artworkListsIsFoldedAtom,
  ArtworkListModPieceErrorAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  defaultPortfolioAtom,
  portfolioTypeAtom,
  uploadDialogOpenAtom,
  segmentStepAtom,
  regenDialogOpenAtom,
  blockDragDropAtom,
  retryMannequinAtom,
  languageAtom,
  appMenuAtom,
  currentMenuAtom,
  portfolioLastArtwork,
  artworkPageAtom,
  regenerateUploadFilesAndUrlAtom,
  segmentLoadingAtom,
} from 'atoms'
import { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react'
import { styled, useTheme, alpha } from '@mui/material/styles'
import {
  GIFstandardDate,
  GIFCompogenDate,
  getPieceType,
  getQueryParam,
  getS3ImageSrc,
  imageUrlToFile,
  isKo,
  notify,
  padZeros,
  setWatermark,
  sortPieces,
  utcToLocal,
} from 'utils/common'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  motion,
  useAnimation,
  AnimateSharedLayout,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from 'framer-motion'

import { AiFillEdit as EditIcon, AiFillSave as SaveIcon, AiOutlineConsoleSql } from 'react-icons/ai'
import * as config from 'config'

import { DotLoader, PuffLoader, PulseLoader, SyncLoader } from 'react-spinners'

import { useInView } from 'react-intersection-observer'
import { apis } from 'apis'

import JSZip from 'jszip'
import axios from 'axios'
import _, { isMap, result } from 'lodash'
import { saveAs } from 'file-saver'
import useConfirm from 'hooks/useConfirm'
import {
  DotIcon,
  DownloadIcon,
  TrashCanIcon,
  PencilIcon,
  CircleIcon,
  CheckedCircleIcon,
  CirclePlusIcon,
  SelectSideIconThin,
  ErrorTriangleIcon,
  ArtworkWarningIcon,
  CircleLightbulbIcon,
} from 'theme/icon'
import { PAGE_HEADER_HEIGHT, PAGE_HEADER_HEIGHT_MOBILE } from 'pages/PortfolioDetail'

import { useSnackbar } from 'notistack'
import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { MOBILE_PORTFOLIO_WIDTH_LIMIT } from 'pages/Portfolio'
import { RIGHT_CLICK_ALLOW_USER } from 'App.js'
import moment from 'moment'
import { ArtworkRowImageSwiper } from './ArtworkRowImageSwiper'
import { useTranslation } from 'react-i18next'
import { useRefineUploadFiles, useUploadHook } from 'hooks/useRefineUploadFiles'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'
import { modelBgRegenDialogAtom, modelFaceRegenDialogAtom } from '../../atoms'
import { ModelBgRegenDialog } from '../../pages/ModelBg'
import { NHNProductButton, NHNProductOutlineButton } from 'pages/NHNCommerce'
import { getDownloadPieceFileName } from '../../utils/common'
import { NO_SO_UPLOAD_NAME, PORTFOLIO_CONFIG_DEFAULT_BACK } from 'config'
import { Cafe24ProductButton } from 'pages/Cafe24'
import { DESKTOP_CONTENT_WIDTH, checkedURLforNoCache, processImages } from './ArtworkList'
import { ArtworkControl, ArtworkError, GuestArtworkControl } from './ArtworkRowControl'
import { DelayInfoForNonSubscriber, DelayInfoForSubscriber } from './ArtworkEtc'

const checkCanMakeGIF = (p, created) => {
  let can =
    (p.path?.includes('imggen_result') ||
      p.path?.includes('simplegen_result') ||
      p.path?.includes('compogen_result')) &&
    !p.path.includes('animated') &&
    moment(created) - GIFstandardDate >= 0

  if (p.path?.includes('compogen_result')) {
    can = can && moment(created) - GIFCompogenDate >= 0
  }
  return can
}

export const ADDPIECE_SELECTOR = 'addpiecetemp'

const MOBILE_MARGIN_X = '2rem'

const StyledArtworkListBox = styled(Box)(({ theme }) => ({
  width: '100%',
  borderTop: '0.2rem solid',
  // flex: 1,
  // [theme.breakpoints.down('lg')]: {},
}))

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  borderBottom: '0.1rem solid',

  width: '100%',
  minHeight: '3rem',
  display: 'flex',
  alignItems: 'center',
  height: '7rem',
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.down('lg')]: {
    height: '5rem',
    position: 'sticky',
    justifyContent: 'center',
    top: 0,
    zIndex: 3,
  },
}))

const DESKTOP_FLEX_BASIS = ['10%', '15%', '50%', '25%']
const MOBILE_FLEX_BASIS = DESKTOP_FLEX_BASIS

const getArtworkArray = o => {
  let arr = []
  Object.entries(o).forEach(([key, v]) => {
    arr = arr.concat(v)
  })
  return arr
}

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 50,
    transition: { ease: [0.78, 0.14, 0.15, 0.86] },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { ease: [0.78, 0.14, 0.15, 0.86] },
  },
}

export function GuestArtworkRow({
  idx,
  initArtwork,
  isOwner,
  checked,
  setChecked,
  checkedArtworkPiece,
  setCheckedArtworkPiece,
  currentConfig,
  highestCheckboxState,
  setHighestCheckboxState,
  refreshArtworks,
  redoAnimation,
  showDialog,
  setSelectedCommonConfig,
  setCreatePortfolioDialogOpen,
  showAdIdx,
}) {
  const { editUploadFile } = useUploadHook(regenerateUploadFilesAndUrlAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [artwork, setArtwork] = useState(initArtwork)
  const [pieceChecked, setPieceChecked] = useState([])
  const [selectedPiece, setSelectedPiece] = useState([])

  const [pieceImageSize, setPieceImageSize] = useState({})

  const [canMakeGIF, setCanMakeGIF] = useState(false)
  const [openGIFDialog, setOpenGIFDialog] = useState(false)
  const [canMakeGIFPieces, setCanMakeGIFPieces] = useState([])
  const [intervalId, setIntervalId] = useState()

  const [goToPiece, setGoToPiece] = useState()

  const [currentPiece, setCurrentPiece] = useState()
  const [pieceIdx, setPieceIdx] = useState(null)

  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)
  const [retryMannequin, setRetryMannequin] = useRecoilState(retryMannequinAtom)

  const user = useRecoilValue(userAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)
  const [modPieceError, setModPieceError] = useRecoilState(ArtworkListModPieceErrorAtom)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isBanner = portfolioType === config.PORTFOLIO_TYPE_BANNER
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const isFunFamePage = portfolioType === config.PORTFOLIO_TYPE_FAME
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE
  const [segmentStep, setSegmentStep] = useRecoilState(segmentStepAtom)
  const onlyOneOutput = isRemoveBgPage || isFacePage

  const renderErrorRetryButton =
    !(isFunFamePage || isBundlePage) &&
    config.ARTWORK_ERROR_STATUS.includes(artwork.status) &&
    artwork?.err_type?.toString() !== '10001'

  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const isMobile = useMobileMediaQuery()

  const [portfolioArtworkList, setPortfolioArtworkList] = useRecoilState(portfolioArtworkAtom)

  const { t } = useTranslation()
  const [redoAnimationStart, setRedoAnimationStart] = useState(false)
  const [lastArtwork, setLastArtwork] = useRecoilState(portfolioLastArtwork)

  const { showConfirm } = useConfirm()

  const artworkDone = artwork.status === config.ARTWORK_DONE_STATUS
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)

  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    }
  }, [intervalId])

  useEffect(() => {
    if (config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status) && !intervalId) {
      subscribeStatus()
    } else if (config.ARTWORK_DONE_STATUS === artwork.status?.toLowerCase() && intervalId) {
      unsubscribeStatus()
    } else if (config.ARTWORK_ERROR_STATUS.includes(artwork.status?.toLowerCase()) && intervalId) {
      unsubscribeStatus()
    }
  }, [artwork.id, artwork.status])

  useEffect(() => {
    if (modPieceError) {
      showConfirm({
        alertOnly: true,
        content: <Typography>사이즈 조절 시 오류가 발생하였습니다.</Typography>,
      })
    }
    setModPieceError(false)
  }, [artwork])

  const controls = useAnimation()
  const [ref, inView] = useInView()
  const theme = useTheme()

  useEffect(() => {
    setArtwork(initArtwork)
  }, [initArtwork])

  useEffect(() => {
    if (
      checked[idx] &&
      [config.ARTWORK_DONE_STATUS, ...config.ARTWORK_ERROR_STATUS].includes(artwork.status)
    ) {
      setPieceChecked(Array(artwork?.pieces?.length).fill(true))
    }

    // highestCheckboxState false는 직접 가장 최상단을 껐을 때
    if (checked.every(v => !v) && highestCheckboxState === false) {
      setPieceChecked(Array(artwork?.pieces?.length).fill(false))
    }
  }, [checked[idx]])

  useEffect(() => {
    if (idx === 0 && portfolioArtworkList?.length > 0) {
      if (portfolioArtworkList[0].id === artwork.id) {
        // 포트폴리오의 가장 최근 아트워크
        setLastArtwork({ ...artwork })
      }
    }
    if (config.ARTWORK_DONE_STATUS !== artwork.status && !pieceChecked.every(x => x === false)) {
      // 재생성한 경우 등.. 체크 임의로 해제시켜줌
      setPieceChecked(Array(artwork?.pieces?.length).fill(false))
    }
  }, [artwork.status])

  useEffect(() => {
    const p = []
    pieceChecked.forEach((c, i) => {
      if (c) {
        p.push(artwork.pieces.map(piece => piece)[i])
      }
    })
    setCheckedArtworkPiece(prev => {
      const newData = { ...prev }
      newData[artwork.id] = p
      return newData
    })

    setSelectedPiece(p.map(piece => piece.path))

    if (!artworkError) {
      setChecked(prev => {
        prev[idx] = pieceChecked.every(v => v) && pieceChecked.length > 0
        return prev
      })
    }
  }, [pieceChecked])

  useEffect(() => {
    if (currentPiece?.path) {
      setCanMakeGIF(checkCanMakeGIF(currentPiece, artwork.created))
    }
  }, [currentPiece])

  useEffect(() => {
    if (artwork.pieces) {
      setCanMakeGIFPieces(artwork.pieces.filter(p => checkCanMakeGIF(p, artwork.created)))
    }
  }, [artwork.pieces])

  useEffect(() => {
    if (inView) {
      controls.start('show')
    }
  }, [controls, inView])

  const notifyArtworkDone = artworkName => {
    const title = t('artwork_list.message.artwork_generate_done')
    const body = isKo
      ? `${artworkName ?? '-'}${t('artwork_list.message.check_artwork_results')}`
      : `Check out the results for ${artworkName ?? '-'} now!
    `
    const options = {
      body,
      icon: isKo
        ? 'https://d1p0kjf7jiqoy5.cloudfront.net/static/logo_image_ko.png'
        : 'https://d1p0kjf7jiqoy5.cloudfront.net/static/logo_image_en.png',
    }

    if ('Notification' in window && Notification.permission === 'granted') {
      return new Notification(title, options)
    }
  }

  const subscribeStatus = () => {
    const x = setInterval(() => {
      apis.guest
        .getGuestArtwork(artwork.id, portfolioType)
        .then(response => {
          const a = response.data
          // console.log(a)
          a.pieces = sortPieces(a)

          setArtwork(a)

          if (config.ARTWORK_DONE_STATUS === a.status?.toLowerCase()) {
            notifyArtworkDone(a.name)

            if (config.ARTWORK_MODPIECE_ERROR_STATUS === a.feedback_status) {
              setModPieceError(true)
            }
          } else if (config.ARTWORK_ERROR_STATUS.includes(a.status?.toLowerCase())) {
            notify(`오류 - ${a.name}`)
            refreshArtworks()
          }
        })
        .catch(e => {
          console.log(e)
        })
    }, 2500)
    setIntervalId(x)
  }

  const unsubscribeStatus = () => {
    clearInterval(intervalId)
    setIntervalId(null)
  }

  const handleCheck = e => {
    const c = [...checked]
    c[idx] = e.target.checked

    if (e.target.checked) {
      setPieceChecked(Array(artwork?.pieces?.length).fill(true))
    } else {
      setPieceChecked(Array(artwork?.pieces?.length).fill(false))
    }
    setChecked(c)
    setHighestCheckboxState(true)
  }

  const errorRetry = async () => {
    setArtwork({ ...artwork, status: 'retry' })

    const checkArtwork = await apis.portfolio.getArtwork(portfolioId, artwork.id)
    if (config.ARTWORK_IN_PROGRESS_STATUS.includes(checkArtwork.data.status)) {
      // 이미 진행중인 상태의 아트워크 - 백엔드로 중복 요청 하지 않고 화면상에서 진행 상태만 업데이트하여 보도록 함
      return
    }

    const artworkConfig = JSON.parse(artwork.config)

    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', portfolioId)
    formData.append('retry_type', 'default')

    const genOptions = {
      ...PORTFOLIO_CONFIG_DEFAULT_BACK,
      gen_shadow: artworkConfig.gen_shadow,
      gen_face: artworkConfig.gen_face,
      object_category: artworkConfig.object_category ?? 'auto',
      object_sub_category: artworkConfig.object_sub_category ?? 'auto',
      object_boundary: artworkConfig.object_boundary ?? 'none',
      object_angle: artworkConfig.object_angle ?? 'auto',
      flag_generate: artworkConfig.flag_generate,
      flag_complex_cmp: artworkConfig.flag_complex_cmp,
      flag_simple_cmp: artworkConfig.flag_simple_cmp,
      flag_white_cmp: artworkConfig.flag_white_cmp,

      flag_gen_compo: artworkConfig.flag_gen_compo ?? false,
      flag_bg_expansion: artworkConfig.flag_bg_expansion ?? false,
      flag_multiblob_sod: artworkConfig.flag_multiblob_sod ?? false,

      flag_human_background: artworkConfig.flag_human_background ?? false,
      SO_length_scale: artworkConfig.SO_length_scale ?? 'auto',

      flag_facemorphing: artworkConfig.flag_facemorphing ?? false,
      facemorphing_race: artworkConfig.facemorphing_race ?? 'asian',
      facemorphing_gender: artworkConfig.facemorphing_gender ?? 'none',

      output_size_w: artworkConfig.output_size_w ?? 0,
      output_size_h: artworkConfig.output_size_h ?? 0,

      selected_bg_ids: artworkConfig.selected_bg_ids ?? '',

      theme: '',
      theme_background: '',
      theme_template: artworkConfig.theme_template ?? 'auto',
      theme_custom: artworkConfig.theme_custom ?? '',

      output_size_list: artworkConfig.output_size_list ?? [],

      simple_bg_color_list:
        artworkConfig.simple_bg_color_list ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }

    // ------
    formData.append('gen_options', JSON.stringify(genOptions))

    apis.appfront
      .retry(formData)
      .then(() => {
        apis.portfolio
          .updateArtworkFeedback(portfolioId, artwork.id, { feedback: 'retry' })
          .then(response => {})
      })
      .catch(() => {
        setArtwork({ ...artwork, status: 'error' })
      })
  }

  const mannErrorRetry = async () => {
    // TODO TODO TODO TODO TODO

    setRetryMannequin({ artworkId: artwork.id, config: JSON.parse(artwork.config), isRetry: true })
    editUploadFile(artwork.uploaded, {
      mannequinMode: true,
      service: 'mannequin',
      artworkId: artwork.id,
    })
    setUploadOpen(true)
  }

  const refreshArtwork = () => {
    apis.portfolio.getArtwork(portfolioId, artwork.id).then(response => {
      if (response.data) {
        setArtwork(response.data)
      }
    })
  }

  const handleCreatePortfolioWithConfig = () => {
    const artworkConfig = JSON.parse(artwork.config)
    setSelectedCommonConfig(artworkConfig)
    setCreatePortfolioDialogOpen(true)

    // ----- GA4 event -----
    window.gtag('event', 'suggest_portfolio', { menu: 'click' }) // 포트폴리오 생성 유도 클릭
    // ---------------------
  }

  return (
    <motion.div
      variants={itemVariants}
      initial="hidden"
      animate={controls}
      ref={ref}
      onAnimationComplete={() => {
        if (redoAnimation) {
          setRedoAnimationStart(true)
        } else {
          setRedoAnimationStart(false)
        }
      }}
    >
      <div style={{ position: 'relative' }}>
        <div
          id={`a_${artwork.id}`}
          style={{
            position: 'absolute',
            top: '-100px',
          }}
        ></div>
      </div>

      <CenterAlignBox
        sx={{
          py: { lg: '6rem', xs: '1.5rem' },
          borderBottom: '0.05rem solid',
          borderColor: theme.palette.common.black,
          backgroundColor: pieceChecked.some(v => v)
            ? theme => theme.palette.draph.lighterblue
            : theme => theme.palette.common.white,
        }}
      >
        {/* 데스크탑용 레이아웃 - v2 */}
        <Desktop>
          <Stack direction="row" sx={{ width: DESKTOP_CONTENT_WIDTH }}>
            {/* 1) 체크박스 */}
            <Box sx={{ width: '3.8rem' }}>
              {(artworkDone || artworkError) && (
                <Checkbox
                  sx={{ pt: 0 }}
                  checked={checked[idx] ?? false}
                  onChange={handleCheck}
                ></Checkbox>
              )}
            </Box>

            {/* 2) 생성 결과 이미지 스와이퍼 영역 */}
            <CenterAlignStack sx={{ width: '51.2rem' }}>
              <ArtworkRowImageSwiper
                artwork={artwork}
                setArtwork={setArtwork}
                artworkIdx={idx}
                checked={pieceChecked}
                setChecked={setPieceChecked}
                hideThumbSwiper={onlyOneOutput}
                setCurrentPiece={setCurrentPiece}
                canMakeGIF={canMakeGIF} // 현재 선택된(크게 보이고 있는) 피스가 gif 생성 가능한지
                goToPiece={goToPiece}
                setGoToPiece={setGoToPiece}
                refreshArtworks={refreshArtworks}
                showDialog={showDialog}
                pieceImageSize={pieceImageSize}
                setPieceImageSize={setPieceImageSize}
              />
            </CenterAlignStack>

            {/* 3) 설정 표시 및 기능 버튼 */}
            <CenterAlignBox sx={{ px: '2.4rem' }}>
              {artworkError ? (
                <ArtworkError
                  artwork={artwork}
                  errorRetry={isMannequinPage ? mannErrorRetry : errorRetry}
                />
              ) : (
                <GuestArtworkControl
                  artwork={artwork}
                  artworkIdx={idx}
                  showAdIdx={showAdIdx}
                  setArtwork={setArtwork}
                  currentPiece={currentPiece}
                  selectedPiece={selectedPiece}
                  refreshArtworks={refreshArtworks}
                  refreshArtwork={refreshArtwork}
                  processImages={processImages}
                  canMakeGIFPieces={canMakeGIFPieces}
                  handleOpenGIFDialog={() => {
                    setOpenGIFDialog(true)
                  }}
                  pieceImageSize={pieceImageSize}
                  handleCreatePortfolioWithConfig={handleCreatePortfolioWithConfig}
                />
              )}
            </CenterAlignBox>
          </Stack>
        </Desktop>

        {/* 모바일용 레이아웃 - v1 */}
        <Mobile>
          <Stack sx={{ maxWidth: MOBILE_PORTFOLIO_WIDTH_LIMIT }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{
                width: MOBILE_PORTFOLIO_WIDTH_LIMIT,
                px: MOBILE_MARGIN_X,
                height: '24rem',
              }}
            >
              <UploadedImage artwork={artwork} setArtwork={setArtwork} />
              <Stack sx={{ height: '100%', alignItems: 'end' }}>
                <Box sx={{ height: '3.6rem', display: 'flex', alignItems: 'center' }}>
                  {(artworkDone || artworkError) && (
                    <Checkbox
                      sx={{
                        width: '2.5rem',
                        height: '2.5rem',
                        p: 0,
                        // '& .MuiSvgIcon-root': { width: '2.5rem', height: '2.5rem' },
                      }}
                      checked={checked[idx] ?? false}
                      onChange={handleCheck}
                    ></Checkbox>
                  )}
                </Box>
                {artworkDone ? (
                  <Box
                    sx={{
                      marginTop: '1.3rem',
                      height: 'auto',
                      width: '13.2rem', // 업로드 이미지 height와 맞춤
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ControlBoxLayout
                      artwork={artwork}
                      setArtwork={setArtwork}
                      selectedPiece={selectedPiece}
                      isOwner={isOwner}
                      pieceIdx={pieceIdx}
                      currentConfig={currentConfig}
                      redoAnimationStart={redoAnimationStart}
                    />
                  </Box>
                ) : (
                  <CenterAlignBox
                    sx={{
                      height: '16.5rem', // 업로드 이미지 height와 맞춤
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    {renderErrorRetryButton ? (
                      <Button
                        color="warning"
                        variant="outlined"
                        component="span"
                        onClick={isMannequinPage ? mannErrorRetry : errorRetry}
                        sx={{
                          width: '9rem',
                          height: '3.5rem',
                          borderWidth: '0.1rem',
                        }}
                      >
                        {t('button.retry')}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </CenterAlignBox>
                )}
              </Stack>
            </Stack>
            <CenterAlignBox width="100%">
              {config.ARTWORK_DONE_STATUS === artwork.status ||
              (config.ARTWORK_DONE_STATUS === artwork.status &&
                config.ARTWORK_ADDPIECE_ERROR_STATUS === artwork.feedback_status) ? ( // 피스 추가 진행하다가 에러가 난 경우.. 정상 생성 완료된 아트워크처럼 보여줘야함
                <GeneratedImageLayout
                  refreshArtworks={refreshArtworks}
                  artwork={artwork}
                  setArtwork={setArtwork}
                  setSelectedPiece={setSelectedPiece}
                  checked={pieceChecked}
                  setChecked={setPieceChecked}
                  pieceIdx={pieceIdx}
                  setPieceIdx={setPieceIdx}
                />
              ) : (
                <Stack sx={{ py: '2rem' }}>
                  <ProgressLayout
                    artwork={artwork}
                    setArtwork={setArtwork}
                    pieceIdx={pieceIdx}
                    setPieceIdx={setPieceIdx}
                    refreshArtworks={refreshArtworks}
                  />
                </Stack>
              )}
            </CenterAlignBox>
          </Stack>
        </Mobile>
      </CenterAlignBox>

      <Divider flexItem />

      {openGIFDialog && (
        <GIFDialog
          artworkId={artwork.id}
          open={openGIFDialog}
          setOpen={setOpenGIFDialog}
          usePieces={canMakeGIFPieces}
          refreshArtworks={refreshArtworks}
          refreshCallback={() => {
            setGoToPiece(-1)
          }}
        />
      )}
    </motion.div>
  )
}

// ~~~~
// ~~~~

// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// -----------------------------
// 아래는 구버전 모바일뷰 유지를 위해 남겨둠

const uploadedImageBoxStyle = {
  position: 'relative',
  width: '100%',
  aspectRatio: '1 / 1',
  // width: { lg: '13rem', xs: '16.5rem' },
  // height: { lg: '13rem', xs: '16.5rem' },
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
}
const uploadedImageStyle = {
  width: '16.5rem',
  maxWidth: 'auto',
  height: '100%',
  objectFit: 'cover',
}
const GENERATED_IMAGE_IN_ROW = 3
const GENERATED_IMAGE_LIMIT = GENERATED_IMAGE_IN_ROW * 2

function UploadedImage({ artwork, setArtwork }) {
  const [nameEdit, setNameEdit] = useState(false)
  const [hover, setHover] = useState(false)
  const [openImageDialog, setOpenImageDialog] = useState(false)
  const isDesktop = useDesktopMediaQuery()
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [optionViewerOpen, setOptionViewerOpen] = useState(false)
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isBannerPage = portfolioType === config.PORTFOLIO_TYPE_BANNER
  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId
  const { t, i18n } = useTranslation()

  const user = useRecoilValue(userAtom)

  const toggleEdit = () => {
    setNameEdit(e => !e)
  }

  const saveArtworkName = e => {
    const value = e.target.value
    apis.portfolio.updateArtwork(portfolioId, artwork.id, { name: value }).then(response => {
      setArtwork({ ...artwork, name: response.data.name })
      setNameEdit(false)
    })
  }

  // const saveArtworkName = e => {
  //   const value = e.target.value
  //   const beforeConfig = JSON.parse(artwork.config)
  //   const afterConfig = JSON.stringify({ ...beforeConfig, somethingSpecial: e.target.value })
  //   apis.portfolio
  //     .updateArtwork(portfolioId, artwork.id, { config: afterConfig })
  //     .then(response => {
  //       // setArtwork({ ...artwork, config: response.data.config })
  //       setNameEdit(false)
  //     })
  // }

  const isNoSO = artwork.name === NO_SO_UPLOAD_NAME

  return (
    <CenterAlignStack
      sx={{ width: { lg: '17.2rem', xs: '16.5rem' }, ...(isBannerPage && { width: '13.2rem' }) }}
    >
      <CenterAlignStack
        direction="row"
        sx={{
          mb: { lg: '1rem', xs: '1.3rem' },
          width: '100%',
          height: '3.6rem',
          ...(isBannerPage && { mb: '0.4rem' }),
        }}
      >
        {nameEdit ? (
          <Box
            sx={{
              display: 'flex',
              width: 'calc(100% - 2.2rem)',
              maxWidth: 'calc(100% - 2.2rem)',
              textOverflow: 'ellipsis',
            }}
          >
            <TextField
              size="small"
              autoFocus
              defaultValue={artwork.name}
              onKeyPress={e => {
                if (e.key === 'Enter') saveArtworkName(e)
              }}
              onBlur={saveArtworkName}
              sx={{
                width: '16rem',
                '& input': { fontSize: '1.3rem', px: '0.5rem' },
                '& .MuiInputBase-root.MuiOutlinedInput-root': { p: 0 },
              }}
            ></TextField>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: 'calc(100% - 2.2rem)',
              minWidth: 'calc(100% - 2.2rem)',
              // overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography noWrap sx={{ width: 'min-content' }} fontSize="1.3rem" fontWeight={600}>
              {isNoSO ? t('banner_config.no_product') : artwork.name}
            </Typography>
          </Box>
        )}

        {config.ARTWORK_DONE_STATUS === artwork.status &&
          (nameEdit ? (
            <IconButton
              onClick={toggleEdit}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                  '& svg': { color: theme => theme.palette.draph.blue },
                },
              }}
            >
              <SaveIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={toggleEdit}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                  '& path': { stroke: theme => theme.palette.draph.blue },
                },
              }}
            >
              <PencilIcon />
            </IconButton>
          ))}
      </CenterAlignStack>
      <Box
        sx={uploadedImageBoxStyle}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!isNoSO) {
              setOpenImageDialog(true)
            }
          }}
        >
          {/* <SearchIcon size="2rem" /> */}
        </Box>
        {!isNoSO ? (
          artwork.uploaded && (
            <img
              src={getS3ImageSrc(artwork.uploaded)}
              style={{ ...uploadedImageStyle, ...(isBannerPage && { width: '13.2rem' }) }}
            />
          )
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#ABABAB',
              width: '13.2rem',
              height: '13.2rem',
            }}
          >
            <Typography
              sx={{ fontSize: '2rem', color: '#fff', fontWeight: 700, textAlign: 'center' }}
            >
              {t('banner_config.no_product')}
            </Typography>
          </Box>
        )}
      </Box>

      <Typography
        display={isDesktop ? 'flex' : 'none'}
        fontSize={{ lg: '1.5rem', xs: '1.2rem' }}
        fontWeight={500}
        sx={{ mt: { lg: '1.6rem', xs: '0.5rem' }, ...(isBannerPage && { mt: '1.2rem' }) }}
      >
        {utcToLocal(artwork.created, 'YYYY/MM/DD HH:mm')}
      </Typography>
      {(user.is_admin || RIGHT_CLICK_ALLOW_USER.includes(user.id)) &&
        isDesktop &&
        !isBannerPage && (
          <>
            <Box
              sx={{
                border: '1px solid gray',
                mt: 1,
                p: 1,
                width: '30rem',
                color: 'gray',
                marginLeft: '-200px',
                fontSize: '1.4rem',
              }}
            >
              {artwork.config &&
                JSON.stringify({
                  category: JSON.parse(artwork.config)?.category,
                  sub_category: JSON.parse(artwork.config)?.sub_category,
                  angle: JSON.parse(artwork.config)?.angle,
                }).replaceAll(',', ',  ')}
              <br />
              <br />
              [마지막 배경 검색 필터]
              <br />
              {artwork.config && JSON.parse(artwork.config)?.bg_search_filter
                ? JSON.stringify(JSON.parse(artwork.config)?.bg_search_filter)?.replaceAll(
                    ',',
                    ', '
                  )
                : '정보없음'}
              <br />
              <br />
              [지정된 아웃풋 사이즈]
              <br />
              W: {JSON.stringify(JSON.parse(artwork.config)?.output_w)} / H:
              {JSON.stringify(JSON.parse(artwork.config)?.output_h)} <br />
              <Button
                onClick={() => {
                  setOptionViewerOpen(true)
                }}
              >
                옵션보기
              </Button>
            </Box>
          </>
        )}
      <SingleImageDialog
        open={openImageDialog}
        setOpen={setOpenImageDialog}
        image={getS3ImageSrc(artwork.uploaded)}
      />
      {optionViewerOpen && (
        <OptionViewerDialog
          open={optionViewerOpen}
          setOpen={setOptionViewerOpen}
          artwork={artwork}
        />
      )}
    </CenterAlignStack>
  )
}

// function ProgressLayout({ artwork, setArtwork, pieceIdx, setPieceIdx, refreshArtworks }) {
//   return <></>
// }

function GeneratedImageLayout({
  refreshArtworks,
  artwork,
  setArtwork,
  setSelectedPiece,
  checked,
  setChecked,
  pieceIdx,
  setPieceIdx,
}) {
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const navigate = useNavigate()

  const [expand, setExpand] = useState(true)
  const [openImageDialog, setOpenImageDialog] = useState(null)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [controlSizeDialog, setControlSizeDialog] = useState(false)
  const [canAddPiece, setCanAddPiece] = useState(false)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isBannerPage = portfolioType === config.PORTFOLIO_TYPE_BANNER
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const isFunFamePage = portfolioType === config.PORTFOLIO_TYPE_FAME
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION
  const isModelBgPage = portfolioType === config.PORTFOLIO_TYPE_MODELBG
  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE

  const oneByone = isRemoveBgPage || isFacePage
  const twoBytwo = isMannequinPage || isFunFamePage || isBgExpansionPage || isModelBgPage
  const oneBytwo = isBundlePage

  const twoBytwoStyle = { width: { lg: '34rem', xs: '32.5rem' } }
  const oneBytwoStyle = { width: { lg: '42rem', xs: '32.5rem' } }

  const isMobile = useMobileMediaQuery()
  const user = useRecoilValue(userAtom)

  const isMultiBlob = JSON.parse(artwork.config)?.flag_multiblob_sod ?? false

  useEffect(() => {
    if (
      !isMultiBlob &&
      !twoBytwo &&
      !oneBytwo &&
      (Math.abs(artwork.used_credit) >= 1 ||
        user.master_user_id !== null ||
        user.grade === 'enterprise')
    ) {
      setCanAddPiece(true)
    } else {
      setCanAddPiece(false)
    }
  }, [artwork.id, user.id])

  useEffect(() => {
    const p = []
    checked.forEach((c, i) => {
      if (c) {
        p.push(artwork.pieces.map(piece => piece.path)[i])
      }
    })
    setSelectedPiece(p)
  }, [checked])

  const openImageSwiper = imageIdx => e => {
    setPieceIdx(imageIdx)
    window.history.pushState({ popup: true }, '')
    setOpenImageDialog(true)
  }

  useEffect(() => {
    const handlePopstate = () => {
      // console.log('-------event start')
      if (openImageDialog) {
        setOpenImageDialog(false)
      }
    }
    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [openImageDialog])

  useEffect(() => {
    if (artwork.pieces) {
      setChecked(Array(artwork.pieces?.length).fill(false))
    }
  }, [artwork.pieces])

  const gridProps = {
    columns: 12,
    gap: { lg: 1.6, xs: 1 }, // rem 은 알아먹질않아서 ..
    justifyContent: 'flex-start',
    width: { lg: '52rem', xs: '32.5rem' },
    ...(twoBytwo && twoBytwoStyle),
    ...(oneBytwo && oneBytwoStyle),
  }

  switch (true) {
    case isBannerPage:
      return (
        <>
          <BannerGeneratedImageLayout
            refreshArtworks={refreshArtworks}
            artwork={artwork}
            setArtwork={setArtwork}
            setSelectedPiece={setSelectedPiece}
            checked={checked}
            setChecked={setChecked}
            pieceIdx={pieceIdx}
            setPieceIdx={setPieceIdx}
            setOpenImageDialog={setOpenImageDialog}
          />
          {openImageDialog && (
            <ArtworksImageSwiperSlideDialog
              open={openImageDialog}
              setOpen={setOpenImageDialog}
              items={artwork.pieces ?? []}
              pieceIdx={pieceIdx}
              setPieceIdx={setPieceIdx}
              setControlSizeDialog={setControlSizeDialog}
              artwork={artwork}
              setArtwork={setArtwork}
              refreshArtworks={refreshArtworks}
            />
          )}
        </>
      )

    default:
      return (
        <CenterAlignStack sx={{ width: '100%', justifyContent: 'center' }}>
          {!oneByone ? (
            <Grid container {...gridProps}>
              {artwork.pieces?.map((piece, idx) => {
                const path = piece.path

                return (
                  <GeneratedImage
                    key={idx}
                    idx={idx}
                    path={checkedURLforNoCache(path)}
                    openImageSwiper={openImageSwiper(idx)}
                    checked={checked}
                    setChecked={setChecked}
                    download={piece.download}
                  />
                )
              })}

              {/* 추가 피스 생성 버튼 */}
              {artwork.pieces?.length < 15 && canAddPiece && (
                <Grid item>
                  <Box
                    sx={{
                      ...generatedImageStyle,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: '4px 4px 20px 0px #0000000D',
                      cursor: 'pointer',
                      position: 'relative',
                      backgroundColor: '#fff',
                    }}
                    onClick={
                      isOwner
                        ? () => {
                            setOpenAddDialog(true)
                            // ----- GA4 event -----
                            window.gtag(
                              'event',
                              !portfolioType ? 'portfolio_bg_regen_begin' : 'upload_bg_regen_begin',
                              {}
                            )
                            // ---------------------
                          }
                        : null
                    }
                  >
                    {artwork.feedback_status === config.ARTWORK_ADDPIECE_ERROR_STATUS && (
                      <Stack
                        direction="row"
                        sx={{
                          position: 'absolute',
                          top: { lg: '1.4rem', xs: '0.4rem' },
                          alignItems: 'center',
                        }}
                      >
                        <Desktop>
                          <ErrorTriangleIcon />
                        </Desktop>
                        <Typography
                          color="#FF2323B2"
                          fontSize={{ lg: '1.2rem', xs: '1rem' }}
                          fontWeight={500}
                          sx={{ ml: '0.4rem', mt: { lg: 0, xs: '0.5rem' } }}
                        >
                          다시 시도해주세요
                        </Typography>
                      </Stack>
                    )}
                    <CirclePlusIcon />
                  </Box>
                </Grid>
              )}

              {canAddPiece ? (
                <>
                  {artwork.pieces?.length < 15 &&
                    artwork.pieces?.length % GENERATED_IMAGE_IN_ROW < 2 && (
                      <GeneratedImage path="" />
                    )}
                  {artwork.pieces?.length < 15 &&
                    artwork.pieces?.length % GENERATED_IMAGE_IN_ROW === 0 && (
                      <GeneratedImage path="" />
                    )}
                </>
              ) : (
                !(twoBytwo || oneBytwo) && (
                  <>
                    {artwork.pieces?.length < 15 &&
                      artwork.pieces?.length % GENERATED_IMAGE_IN_ROW > 0 && (
                        <GeneratedImage path="" />
                      )}
                    {artwork.pieces?.length < 15 &&
                      artwork.pieces?.length % GENERATED_IMAGE_IN_ROW === 1 && (
                        <GeneratedImage path="" />
                      )}
                  </>
                )
              )}
            </Grid>
          ) : (
            <>
              {/* {artwork.pieces[0].path} */}

              <GeneratedImage
                key={0}
                idx={0}
                path={checkedURLforNoCache(artwork.pieces[0]?.path)}
                openImageSwiper={openImageSwiper(0)}
                checked={checked}
                setChecked={setChecked}
                download={artwork.pieces[0]?.download}
              />
            </>
          )}

          {openImageDialog && (
            <ArtworksImageSwiperSlideDialog
              open={openImageDialog}
              setOpen={setOpenImageDialog}
              items={artwork.pieces ?? []}
              pieceIdx={pieceIdx}
              setPieceIdx={setPieceIdx}
              setControlSizeDialog={setControlSizeDialog}
              artwork={artwork}
              setArtwork={setArtwork}
              refreshArtworks={refreshArtworks}
            />
          )}

          <AddPieceDialog
            open={openAddDialog}
            setOpen={setOpenAddDialog}
            artwork={artwork}
            setArtwork={setArtwork}
          />

          {controlSizeDialog && (
            <ControlSizeDialog
              open={controlSizeDialog}
              setOpen={setControlSizeDialog}
              pieceIdx={pieceIdx}
              artwork={artwork}
              setArtwork={setArtwork}
            />
          )}
        </CenterAlignStack>
      )
  }
}

const ImageCheckbox = styled(Checkbox)(({ theme }) => ({
  cursor: 'pointer',
  position: 'absolute',
  bottom: 0,
  right: 0,
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
    borderRadius: '2rem',
  },
  '&:hover': {
    '& .MuiSvgIcon-root': {
      boxShadow: '0px 0px 4px rgba(0, 232, 185, 0.4)',
    },
  },
}))

const generatedImageStyle = {
  width: { lg: '16rem', xs: '10rem' },
  height: { lg: '16rem', xs: '10rem' },
  objectFit: 'cover',
}

const mannequinStyle = {
  width: { lg: '16rem', xs: '15.2rem' },
  height: { lg: '16rem', xs: '15.2rem' },
  objectFit: 'cover',
}

const bgRemoveImageStyle = {
  width: { lg: '34rem', xs: '32rem' },
  height: { lg: '34rem', xs: '32rem' },
  objectFit: 'cover',
}

const bundleImageStyle = {
  width: { lg: '20rem', xs: '15.2rem' },
  height: { lg: '20rem', xs: '15.2rem' },
  objectFit: 'cover',
}

export const bgRemovedStyle = {
  background:
    'linear-gradient(45deg, lightgray 25%, transparent 25%), linear-gradient(-45deg, lightgray 25%, transparent 25%), linear-gradient(45deg, transparent 75%, lightgray 75%), linear-gradient(-45deg, transparent 75%, lightgray 75%)',
  backgroundSize: '20px 20px' /* 크기 조정 */,
  backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0',
}

function GeneratedImage({
  idx,
  path,
  openImageSwiper,
  checked,
  setChecked,
  thumbnailMode,
  download,
}) {
  const [hover, setHover] = useState(false)
  const [src, setSrc] = useState('')
  const [isScaleDown, setIsScaleDown] = useState(false)

  const isMobile = useMobileMediaQuery()

  const isRemovedBg = path?.includes('bgremoved')

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION
  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE

  const oneByone = isRemoveBgPage || isFacePage

  const user = useRecoilValue(userAtom)

  const handleCheck = e => {
    const c = [...checked]
    c[idx] = e.target.checked
    setChecked(c)
  }

  useEffect(() => {
    setSrc(getS3ImageSrc(path).replace('/shadow_results/', '/thumb/'))
  }, [path])

  const imageSize = isMobile ? 10 : 16

  return (
    <Grid item sx={{}}>
      {path ? (
        <motion.div
          key={idx}
          initial={thumbnailMode && { y: 5, opacity: 0 }}
          animate={thumbnailMode && { y: 0, opacity: 1 }}
          transition={thumbnailMode && { duration: 0.3 }}
        >
          <Box
            sx={{
              position: 'relative',
              cursor: thumbnailMode ? '' : 'pointer',
              opacity: thumbnailMode ? 0.4 : 1,
            }}
          >
            <Box onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
              {/* hover 할때 돋보기 아이콘 나오는 것 없애고 클릭가능한 껍데기?항상 표시 */}
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  // backdropFilter: 'saturate(50%) blur(1px)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={openImageSwiper}
              >
                {/* <SearchIcon size="2rem" /> */}
              </Box>
              {user.is_admin && (
                <Typography
                  sx={{
                    position: 'absolute',
                    left: '0.6rem',
                    top: '0.4rem',
                    animationName: 'glowText',
                    animationTimingFunction: 'ease-in-out',
                    animationDuration: '4s',
                    animationIterationCount: 'infinite',
                  }}
                >
                  {download}
                </Typography>
              )}
              <Box
                component="img"
                src={src}
                onLoad={e => {
                  if (
                    imageSize * 10 > e.target.naturalWidth ||
                    imageSize * 10 > e.target.naturalHeight
                  ) {
                    setIsScaleDown(true)
                  }
                }}
                // src={getS3ImageSrc(path).replace('/shadow_results/', '/thumb/')}
                sx={{
                  ...generatedImageStyle,
                  ...(isRemovedBg && bgRemovedStyle),
                  ...(isMannequinPage && mannequinStyle),
                  ...(oneByone && bgRemoveImageStyle),
                  ...(isBundlePage && bundleImageStyle),
                  ...(isBgExpansionPage && mannequinStyle), // TODO 생성 이미지 개수 4개로 업데이트되면 수정
                  ...(isScaleDown && { objectFit: 'scale-down' }),
                }}
              />
            </Box>

            {!thumbnailMode &&
              !isRemoveBgPage && ( // TODO 생성 이미지 개수 4개로 업데이트되면 수정
                <ImageCheckbox
                  disableRipple
                  checked={
                    checked?.length > 0 && typeof checked[idx] !== typeof undefined
                      ? checked[idx]
                      : false
                  }
                  onChange={handleCheck}
                  icon={
                    <CircleIcon
                      style={isMobile ? { width: '16px', height: '16px' } : {}}
                      color={isRemovedBg ? '#909090' : 'common.white'}
                      fill={isRemovedBg ? 'rgb(209, 209, 209, 0.6)' : '#FFFFFF50'}
                    />
                  }
                  checkedIcon={
                    <CheckedCircleIcon style={isMobile ? { width: '16px', height: '16px' } : {}} />
                  }
                  sx={{
                    '& .MuiSvgIcon-root': {
                      boxShadow: hover ? '0px 0px 4px rgba(0, 232, 185, 0.4)' : '',
                    },
                  }}
                />
              )}
          </Box>
        </motion.div>
      ) : (
        <Box component="div" sx={{ ...generatedImageStyle, cursor: 'unset' }}></Box>
      )}
    </Grid>
  )
}

// TODO transition 조정
export function EmptyRow({ messageOverride, sx = {} }) {
  const { t, i18n } = useTranslation()
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const [message, setMessage] = useState(t('no_image'))

  const portfolioDetailConfig = portfolioDetail.config

  useEffect(() => {
    setMessage(t('no_image'))
  }, [i18n.language])

  return (
    <motion.div
      style={{ display: 'flex', justifyContent: 'center' }}
      initial={{ y: -30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.8,
        // delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <CenterAlignBox
        sx={{
          width: 'max-content',
          pt: '5rem',
          pb: '5rem',
          minHeight: '18rem',
          ...sx,
        }}
      >
        {messageOverride ?? message}
      </CenterAlignBox>
    </motion.div>
  )
}

function ControlBoxLayout({
  artwork,
  setArtwork,
  selectedPiece,
  isOwner,
  currentConfig,
  redoAnimationStart,
  pieceIdx,
}) {
  const { t } = useTranslation()
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom) // 현재 포트폴리오 정보
  const portfolio = useRecoilValue(portfolioAtom) // 유저의 모든 포트폴리오 목록

  const [artworkList, setArtworkList] = useRecoilState(portfolioArtworkAtom)
  const [user, setUser] = useRecoilState(userAtom)
  const [regenCount, setRegenCount] = useRecoilState(artworkRegenCountAtom)

  const creditPolicy = useRecoilValue(creditPolicyDictSelector)

  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const { showConfirm } = useConfirm()
  const { editUploadFile } = useUploadHook(regenerateUploadFilesAndUrlAtom)

  const [regenButtonHover, setRegenButtonHover] = useState(false)

  const [nButtonLoading, setNButtonLoading] = useState(false)
  const [nhnButtonLoading, setNHNButtonLoading] = useState(false)
  const [cafe24ButtonLoading, setCafe24ButtonLoading] = useState(false)

  const [likeSelected, setLikeSelected] = useState(false)
  const [dislikeSelected, setDislikeSelected] = useState(false)
  const [redoButtonClass, setRedoButtonClass] = useState('')
  const [selectedPortfolio, setSelectedPortfolio] = useState('')
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)
  const [segmentStep, setSegmentStep] = useRecoilState(segmentStepAtom)
  const [retryMannequin, setRetryMannequin] = useRecoilState(retryMannequinAtom)

  const [modelBgRegenDialog, setModelBgRegenDialog] = useRecoilState(modelBgRegenDialogAtom)
  const [faceRegenDialog, setFaceRegenDialog] = useRecoilState(modelFaceRegenDialogAtom)

  const [openRegenDialog, setOpenRegenDialog] = useState(false)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)
  // const [openRegenDialog, setOpenRegenDialog] = useRecoilState(regenDialogOpenAtom)
  const [openGIFDialog, setOpenGIFDialog] = useState(false)
  const [createImageDialog, setCreateImageDialog] = useState(false)

  const [placeholder, setPlaceholder] = useState('다른 포트폴리오 이동')

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const currentMenu = useRecoilValue(currentMenuAtom)

  const isBannerPage = portfolioType === config.PORTFOLIO_TYPE_BANNER
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const isFunFamePage = portfolioType === config.PORTFOLIO_TYPE_FAME
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION
  const isModelBgPage = portfolioType === config.PORTFOLIO_TYPE_MODELBG
  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE

  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const GIFableDate = moment(artwork?.created) - GIFstandardDate >= 0

  const isNCommerceUser = user.login_sns === 'naver_commerce'
  const isNHNCommerceUser = user.login_sns === 'nhn_commerce'
  const isCafe24CommerceUser = user.login_sns === 'cafe24_commerce'
  const isCommerceUser = isNCommerceUser || isNHNCommerceUser || isCafe24CommerceUser

  const onlyOneOutput = isRemoveBgPage || isFacePage
  const downloadDeleteOnly = isFunFamePage || isBundlePage || isBgExpansionPage

  // GIF 가능한 것 Select
  const GIFable = artwork.pieces.filter(
    p =>
      (p.path?.includes('imggen_result') || p.path?.includes('simplegen_result')) &&
      !p.path.includes('animated')
  )

  const usePieces = GIFable.filter(p => {
    const piecesPath = []

    artwork.pieces?.forEach(p => {
      if (/imggen_result|simplegen_result/.test(p.path)) {
        piecesPath.push(p.path)
      }
    })

    const regex = /(.*)((imggen|simplegen)_result_(\d+)_(\d+))/
    if (!p.path.match(regex)) return []

    const match = p.path.match(regex)[0]
    const newString = match.replace(/(.*\/)(imggen|simplegen)/, (match, p1, p2) => {
      return p1 + 'animated_' + p2 // Prefix 'animated_'를 추가
    })

    return !piecesPath.includes(`${newString}.gif`)
  })
  //

  const refreshArtworks = () => {
    apis.portfolio.getAllPortfolioArtwork(portfolioId).then(response => {
      setArtworkList(response.data)
    })
  }

  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const freeBadgeStyle = {
    '& #free-badge-text': { color: '#FFFFFF' },
    background: theme => theme.palette.draph.blue,
  }
  useEffect(() => {
    const feedbackStatus = artwork.feedback_status
    if (feedbackStatus === 'like') {
      setLikeSelected(true)
      setDislikeSelected(false)
    } else if (feedbackStatus === 'dislike') {
      setLikeSelected(false)
      setDislikeSelected(true)
    }
  }, [artwork])

  useEffect(() => {
    if (likeSelected) {
      setDislikeSelected(false)
    }
  }, [likeSelected])

  useEffect(() => {
    if (dislikeSelected) {
      setLikeSelected(false)
    }
  }, [dislikeSelected])

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const refreshArtwork = () => {
    apis.portfolio.getArtwork(portfolioId, artwork.id).then(response => {
      if (response.data) {
        setArtwork(response.data)
      }
    })
  }

  const refreshRegenCount = () => {
    apis.portfolio.getArtworkRegenCount().then(response => {
      if (response.data) {
        setRegenCount(response.data)
      }
    })
  }

  const download = async () => {
    const pieces = artwork.pieces
    if (user.use_credit_on.includes('artwork_download')) {
      const credit = await checkUserCredit()

      if (credit < creditPolicy.artwork_download) {
        showConfirm({
          alertOnly: true,
          content: <Typography>{t('common.credit_warning')}</Typography>,
        })
        return
      }
    }

    const selected = selectedPiece.map(l => getS3ImageSrc(l))

    if (selected.length < 1 && !onlyOneOutput) {
      showConfirm({
        alertOnly: true,
        content: <Typography>이미지를 선택 후 다운로드해주세요.</Typography>,
      })
      return
    }

    const artworkConfig = JSON.parse(artwork.config)

    // ----- GA4 event -----
    if (config.DEFAULT_PORTFOLIO_TYPES.includes(portfolioType)) {
      const ev = getGA4EventNameByPortfolioType(portfolioType, 'download')
      triggerGA4DownloadEvent({
        eventName: ev,
        params: { count: selected.length },
        method: 'row',
      })
    } else if (artworkConfig.flag_bg_expansion) {
      triggerGA4DownloadEvent({
        eventName: 'ai_canvas_exp_download',
        params: { count: selected.length },
        method: 'row',
      })
    } else if (!portfolioType) {
      const artworkConfig = JSON.parse(artwork.config)
      triggerGA4DownloadEvent({
        eventName: 'portfolio_download',
        artworkConfig,
        user,
        artwork,
        count: selected.length,
        method: 'row',
      })
    } else {
      const artworkConfig = JSON.parse(artwork.config)
      triggerGA4DownloadEvent({
        artworkConfig,
        user,
        artwork,
        count: selected.length,
        method: 'row',
      })
    }

    // ---------------------

    const promises = []
    const paths = []
    const ax = axios.create()
    const artworkName = artwork.name.replace('.' + _.last(artwork.name.split('.')), '')

    if (selected.length === 1 || onlyOneOutput) {
      const url = checkedURLforNoCache(
        onlyOneOutput ? getS3ImageSrc(artwork.pieces[0].path) : selected[0]
      )

      const img = new Image()
      img.src = url

      const blob = await fetch(url + `?w=${Date.now().toString()}`).then(r => r.blob())

      const ext = _.last(_.first(_.last(url.split('/')).split('?')).split('.'))
      const idx = 1

      const piece = pieces.filter(p => url.includes(p.path))[0]

      const fileName =
        piece?.result_filename?.length > 0
          ? piece.result_filename
          : getDownloadPieceFileName({
              prefix: artworkName,
              idx: idx,
              width: img.width,
              height: img.height,
              ext: ext,
            })

      saveAs(blob, fileName)
      paths.push(onlyOneOutput ? artwork.pieces[0].path : selectedPiece[0])

      if (isOwner) {
        apis.portfolio.updateArtworkDownload(portfolioId, artwork.id, { paths }).then(response => {
          const d = response.data.total_download

          refreshArtworks()
          checkUserCredit()
          if (d) {
            setArtwork({ ...artwork, download: d })
            const updated = [...artworkList].map(a => {
              if (a.id === artwork.id) {
                return { ...a, download: d }
              } else return a
            })

            setArtworkList(updated)
          }
        })
      }
    } else {
      selected.forEach((url, i) => {
        paths.push(selectedPiece[i])
        if (!url) return

        promises.push(
          ax.get(checkedURLforNoCache(url) + `?w=${Date.now().toString()}`, {
            responseType: 'blob',
          })
        )
      })

      const zip = new JSZip()
      const dirName = artworkName
      zip.folder(dirName)

      Promise.all(promises).then(results => {
        processImages(results, dirName, artworkName, pieces)

        if (isOwner) {
          apis.portfolio
            .updateArtworkDownload(portfolioId, artwork.id, { paths })
            .then(response => {
              const d = response.data.total_download

              refreshArtworks()
              checkUserCredit()
              if (d) {
                setArtwork({ ...artwork, download: d })
                const updated = [...artworkList].map(a => {
                  if (a.id === artwork.id) {
                    return { ...a, download: d }
                  } else return a
                })
                setArtworkList(updated)
              }
            })
        }
      })
    }
  }

  const toggleLike = () => {
    const newStatus = !likeSelected
    let feedback = null
    if (newStatus) {
      feedback = 'like'
    }

    apis.portfolio.updateArtworkFeedback(portfolioId, artwork.id, { feedback })
    setLikeSelected(newStatus)
    onFeedbackChange(feedback)
  }

  const toggleDislike = () => {
    const newStatus = !dislikeSelected
    let feedback = null
    if (newStatus) {
      feedback = 'dislike'
    }

    apis.portfolio.updateArtworkFeedback(portfolioId, artwork.id, { feedback })
    setDislikeSelected(newStatus)
    onFeedbackChange(feedback)
  }

  const onFeedbackChange = feedback => {
    if (feedback === 'dislike') {
      setRedoButtonClass('shake')
    } else {
      setRedoButtonClass('')
    }
  }

  const handleRegenerate = () => {
    setOpenRegenDialog(true)
    setBlockDragDrop(true)
  }

  const mannequinRegenrate = async () => {
    // TODO TODO TODO TODO TODO

    setRetryMannequin({ artworkId: artwork.id, config: JSON.parse(artwork.config), isRetry: true })
    editUploadFile(artwork.uploaded, {
      mannequinMode: true,
      service: 'mannequin',
      artworkId: artwork.id,
    })
    setUploadOpen(true)
    setSegmentStep(0)
  }

  const handleModelbgRegenerate = () => {
    setModelBgRegenDialog({
      open: true,
      artwork: artwork,
      setArtwork: setArtwork,
      refreshArtwork: refreshArtwork,
      refreshRegenCount: refreshRegenCount,
    })
  }
  const handleFaceRegenerate = () => {
    setFaceRegenDialog({
      open: true,
      artwork: artwork,
      setArtwork: setArtwork,
      refreshArtwork: refreshArtwork,
      refreshRegenCount: refreshRegenCount,
    })
  }

  const regenerateArtwork = async ({
    category,
    angle,
    themeAir,
    themeTemplate,
    themeCustom,
    retryType = 'regenerate',
  }) => {
    const checkArtwork = await apis.portfolio.getArtwork(portfolioId, artwork.id)
    if (config.ARTWORK_IN_PROGRESS_STATUS.includes(checkArtwork.data.status)) {
      // 이미 진행중인 상태의 아트워크 - 백엔드로 중복 요청 하지 않고 화면상에서 진행 상태만 업데이트하여 보도록 함
      refreshArtwork()
      refreshRegenCount()
      return
    }

    const feedback = 'regenerate_auto'
    const artworkConfig = JSON.parse(artwork.config)

    const isSimpleConfig =
      !artworkConfig.flag_complex_cmp && !artworkConfig.flag_generate ? 'simple' : 'concept'

    // ----- GA4 event -----
    window.gtag('event', portfolioType ? feedback : 'portfolio_regenerate_auto', {
      theme: isSimpleConfig === 'simple' ? null : themeCustom || themeTemplate,
    })
    // ---------------------

    setArtwork({ ...artwork, status: feedback })

    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', portfolioId)
    formData.append('retry_type', retryType ?? 'regenerate')

    // formData.append('options', JSON.stringify(options))

    const genOptions = {
      // object_category: category?.length > 0 ? category : 'auto',
      // object_angle: angle?.length > 0 ? angle : 'auto',
      // theme_air: '',
      // theme_template: themeTemplate,
      // theme_custom: themeCustom,
      ...PORTFOLIO_CONFIG_DEFAULT_BACK,
      gen_shadow: artworkConfig.gen_shadow,
      gen_face: artworkConfig.gen_face,
      object_category: category?.length > 0 ? category : 'auto',
      object_sub_category: artworkConfig.object_sub_category ?? 'auto',
      object_boundary: artworkConfig.object_boundary ?? 'none',
      object_angle: angle?.length > 0 ? angle : 'auto',
      flag_generate: artworkConfig.flag_generate,
      flag_complex_cmp: artworkConfig.flag_complex_cmp,
      flag_simple_cmp: artworkConfig.flag_simple_cmp,
      flag_white_cmp: artworkConfig.flag_white_cmp,

      flag_gen_compo: artworkConfig.flag_gen_compo ?? false,
      flag_bg_expansion: artworkConfig.flag_bg_expansion ?? false,
      flag_multiblob_sod: artworkConfig.flag_multiblob_sod ?? false,

      // 별도의 재생성 함수를 사용하는 기능들용 필드들 - 디폴트만 세팅
      flag_human_background: false,
      SO_length_scale: 'auto',
      flag_facemorphing: false,
      facemorphing_race: 'asian',
      facemorphing_gender: 'none',
      // ----------------------------------

      selected_bg_ids: artworkConfig.selected_bg_ids ?? '',

      output_size_w: artworkConfig.output_size_w ?? 0,
      output_size_h: artworkConfig.output_size_h ?? 0,

      theme: artworkConfig.theme,
      theme_air: '',
      theme_template: themeTemplate,
      theme_custom: themeCustom,

      output_size_list: artworkConfig.output_size_list ?? [],

      simple_bg_color_list:
        artworkConfig.simple_bg_color_list ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }
    formData.append('gen_options', JSON.stringify(genOptions))

    // formData.append('theme_air', '')

    // formData.append('flag_generate', artworkConfig.flag_generate ?? true)
    // formData.append('flag_complex_cmp', artworkConfig.flag_complex_cmp ?? true)
    // formData.append('flag_simple_cmp', artworkConfig.flag_simple_cmp ?? true)
    // formData.append('flag_white_cmp', artworkConfig.flag_white_cmp ?? true)

    // formData.append('output_size_w', artworkConfig.output_size_w)
    // formData.append('output_size_h', artworkConfig.output_size_h)

    // formData.append('output_size_list', artworkConfig.output_size_list ?? [])
    // formData.append(
    //   'simple_bg_color_list',
    //   artworkConfig.simple_bg_color_list ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList
    // )

    if (category?.length > 0) formData.append('category', category)
    if (angle?.length > 0) formData.append('angle', angle)

    const scrollTargetId = `a_${artwork.id}`
    // querySelector 에 불가능한 특수문자 앞에  \를 넣어줘야한다고 함.
    const anchorIdTranslation = scrollTargetId.replace(/[#[\].():'"~,]/g, '\\$&')

    const anchor = document.querySelector(`#${anchorIdTranslation}`)

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }

    apis.portfolio
      .updateArtworkFeedback(portfolioId, artwork.id, {
        feedback,
        themeTemplate,
        themeCustom,
      })
      .then(() => {
        refreshArtwork()
        apis.appfront
          .retry(formData)
          .then(() => {
            // refreshArtworks()
            refreshArtwork()
          })
          .catch(error => {
            console.log(error)
            refreshArtwork()
          })
        refreshRegenCount()
      })
  }

  const handleDelete = () => {
    showConfirm({
      title: t('artworkList.image_delete'),
      content: (
        <p>
          {t('artworkList.image_delete_comment_1')} {onlyOneOutput ? 1 : selectedPiece.length}
          {t('artworkList.image_delete_comment_2')}
        </p>
      ),
      onConfirm: () => {
        if (artwork.pieces.length === selectedPiece.length || onlyOneOutput) {
          apis.portfolio.deleteArtworks(portfolioId, { artworkIds: [artwork.id] }).then(() => {
            refreshArtworks()
          })
        } else {
          apis.portfolio
            .deletePiece(portfolioId, artwork.id, {
              data: { paths: [...selectedPiece] },
            })
            .then(() => {
              refreshArtworks()
            })
        }
        // ----- GA4 event -----
        window.gtag('event', 'image_delete', {
          menu: currentMenu?.id,
          method: 'row',
          count: selectedPiece.length,
        })
        // ---------------------
      },
    })
  }

  const handleNaverProductImage = () => {
    if (selectedPiece.length !== 1) {
      showConfirm({
        alertOnly: true,
        content: <Typography>한 장의 생성 이미지를 선택해주세요.</Typography>,
      })
      return
    }
    const nChannelProductNo = artwork.id.split('_').length >= 2 ? artwork.id.split('_')[1] : null
    // 9359734030
    if (nChannelProductNo) {
      setNButtonLoading(true)
      apis.naverCommerce.getChannelProductDetail(nChannelProductNo).then(response => {
        const p = response.data

        if (!p.originProduct) {
          showConfirm({
            alertOnly: true,
            content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
          })
          setNButtonLoading(false)
          return
        }

        let productName = p.originProduct?.name

        if (p.smartstoreChannelProduct?.channelProductName?.length > 0) {
          productName = p.smartstoreChannelProduct.channelProductName
        }

        showConfirm({
          content: (
            <Typography>
              스토어 상품{' '}
              <span style={{ fontWeight: 800 }}>
                [{nChannelProductNo} {productName}]
              </span>{' '}
              의 대표이미지가 즉시 교체됩니다.
            </Typography>
          ),
          onConfirm: () => {
            apis.naverCommerce
              .updateProductImage({
                channel_product_no: nChannelProductNo,
                images: [getS3ImageSrc(selectedPiece[0])],
              })
              .then(response => {
                setNButtonLoading(false)

                // ----- GA4 event -----
                window.gtag('event', 'nstore_replace', {
                  menu: productName,
                  output_url: selectedPiece[0],
                })
                // ---------------------

                if (response.data.success) {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>상품 이미지가 교체되었습니다.</Typography>,
                  })
                } else {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>{t('common.error')}</Typography>,
                  })
                }
              })
          },
        })
      })
    }
  }

  const handleNHNProductImage = () => {
    if (selectedPiece.length !== 1) {
      showConfirm({
        alertOnly: true,
        content: <Typography>한 장의 생성 이미지를 선택해주세요.</Typography>,
      })
      return
    }
    const productNo =
      artwork.id.split('shopby_').length >= 2 ? artwork.id.split('shopby_').at(1) : null

    if (productNo) {
      setNHNButtonLoading(true)
      apis.nhnCommerce.getProductDetail(productNo).then(response => {
        const p = response.data

        if (!p.mallProduct) {
          showConfirm({
            alertOnly: true,
            content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
          })
          setNHNButtonLoading(false)
          return
        }

        const productName = p.mallProduct?.productName

        showConfirm({
          content: (
            <Typography>
              스토어 상품{' '}
              <span style={{ fontWeight: 800 }}>
                [{productNo} {productName}]
              </span>{' '}
              의 대표이미지가 즉시 교체됩니다.
            </Typography>
          ),
          onConfirm: () => {
            apis.nhnCommerce
              .updateProductImage({
                product_no: productNo,
                images: [getS3ImageSrc(selectedPiece[0])],
              })
              .then(response => {
                setNHNButtonLoading(false)

                // ----- GA4 event -----
                window.gtag('event', 'shopby_replace', {
                  menu: productName,
                  output_url: selectedPiece[0],
                })
                // ---------------------
                if (response.data.success) {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>상품 이미지가 교체되었습니다.</Typography>,
                  })
                } else {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>{t('common.error')}</Typography>,
                  })
                }
              })
              .finally(() => {
                setNHNButtonLoading(false)
              })
          },
        })
      })
    } else {
      showConfirm({
        alertOnly: true,
        content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
      })
    }
  }

  const handleCafe24ProductImage = () => {
    if (selectedPiece.length !== 1) {
      showConfirm({
        alertOnly: true,
        content: <Typography>한 장의 생성 이미지를 선택해주세요.</Typography>,
      })
      return
    }

    const productNo =
      artwork.id.split('cafe24_').length >= 2 ? artwork.id.split('cafe24_').at(1) : null

    if (productNo) {
      setCafe24ButtonLoading(true)
      apis.cafe24Commerce.getProductDetail(productNo).then(response => {
        const p = response.data.product

        if (!p || !p.product_no) {
          showConfirm({
            alertOnly: true,
            content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
          })
          setCafe24ButtonLoading(false)
          return
        }

        const productName = p.product_name

        showConfirm({
          content: (
            <Typography>
              스토어 상품{' '}
              <span style={{ fontWeight: 800 }}>
                [{productNo} {productName}]
              </span>{' '}
              의 대표이미지가 즉시 교체됩니다.
            </Typography>
          ),
          onConfirm: () => {
            apis.cafe24Commerce
              .updateProductImage({
                product_no: productNo,
                image_s3_url: [getS3ImageSrc(selectedPiece[0])],
              })
              .then(response => {
                setCafe24ButtonLoading(false)

                // ----- GA4 event -----
                window.gtag('event', 'cafe24_replace', {
                  menu: productName,
                  output_url: selectedPiece[0],
                })
                // ---------------------
                if (response.data.success) {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>상품 이미지가 교체되었습니다.</Typography>,
                  })
                } else {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>{t('common.error')}</Typography>,
                  })
                }
              })
              .finally(() => {
                setCafe24ButtonLoading(false)
              })
          },
        })
      })
    } else {
      showConfirm({
        alertOnly: true,
        content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
      })
    }
  }

  const getPieceDownloadSum = () => {
    const dArr = artwork.pieces.map(p => p.download)
    const s = dArr.reduce((sum, v) => {
      return sum + (v === null ? 0 : v)
    }, 0)
    return s
  }

  switch (true) {
    case isBannerPage:
      return (
        <BannerControlBoxLayout
          artwork={artwork}
          setArtwork={setArtwork}
          selectedPiece={selectedPiece}
          isOwner={isOwner}
          pieceIdx={pieceIdx}
          currentConfig={currentConfig}
          redoAnimationStart={redoAnimationStart}
        />
      )

    default:
      return (
        <CenterAlignStack sx={{ width: { lg: 'auto', xs: '13.2rem' } }}>
          {isRemoveBgPage ? (
            <>
              <Desktop>
                <ArtowrkDownloadButton
                  downloadCount={getPieceDownloadSum()}
                  handleClick={download}
                />
                <Divider
                  flexItem
                  sx={{
                    m: { lg: '2rem 0', xs: isCommerceUser ? '1rem 0' : '1.4rem 0 1.2rem 0' },
                    borderColor: '#E2E2E2',
                  }}
                />
                <CenterAlignStack
                  direction="row"
                  spacing={{ lg: 1, xs: 0 }}
                  sx={{
                    width: '100%',
                    height: { lg: '3.8rem', xs: '2rem' },
                    justifyContent: { lg: 'center', xs: 'space-between' },
                  }}
                >
                  {isOwner && (
                    <RegenerateButton
                      handleClick={handleRegenerate}
                      isOwner={isOwner}
                      buttonClass={redoButtonClass}
                      redoAnimationStart={redoAnimationStart}
                      type="removebg"
                    />
                  )}
                  <DeleteSelectedButton handleDelete={handleDelete} disabled={!isOwner} />
                </CenterAlignStack>
              </Desktop>
              <Mobile>
                <CenterAlignStack
                  direction="row"
                  spacing={0}
                  sx={{
                    width: '100%',
                    height: '2rem',
                    justifyContent: 'space-between',
                  }}
                >
                  <ArtowrkDownloadButton
                    downloadCount={getPieceDownloadSum()}
                    handleClick={download}
                    slim={!isRemoveBgPage && isCommerceUser}
                    sx={{ width: '6.4rem', fontSize: '1rem', px: 0 }}
                  />
                  <DeleteSelectedButton handleDelete={handleDelete} disabled={!isOwner} />
                </CenterAlignStack>
              </Mobile>

              <Divider
                flexItem
                sx={{ m: { lg: '2rem 0', xs: '1.2rem 0' }, borderColor: '#E2E2E2' }}
              />

              <CenterAlignStack>
                <CenterAlignBox sx={{ alignItems: 'center' }}>
                  <img
                    src="/static/images/bgRemove/bgRemove_1.png"
                    style={{
                      width: isMobile ? '5.4rem' : '9.6rem',
                      height: isMobile ? '5.4rem' : '9.6rem',
                      ...bgRemovedStyle,
                      backgroundSize: '10px 10px' /* 크기 조정 */,
                      backgroundPosition: '0 0, 0 5px, 5px -5px, -5px 0',
                    }}
                  />

                  <img
                    src="/static/images/main/click/clickArea_arrow.png"
                    style={{
                      width: isMobile ? '1.2rem' : '2.2rem',
                      height: isMobile ? '0.4rem' : '0.8rem',
                      transform: 'scaleY(-1) rotate(12deg)',
                      margin: isMobile ? '0px 0.6rem 0px 0.6rem' : '0 0.81rem 0 0.86rem',
                    }}
                  />
                  <TwoImageSlider
                    duration={6}
                    size={{
                      width: { lg: '9.6rem', xs: '5.4rem' },
                      height: { lg: '9.6rem', xs: '5.4rem' },
                    }}
                    image1="/static/images/bgRemove/bgImage_1.jpeg"
                    image2="/static/images/bgRemove/bgImage_2.jpeg"
                  />
                </CenterAlignBox>
                <Typography
                  sx={{
                    fontSize: { lg: '1.2rem', xs: '1rem' },
                    lineHeight: 'normal',
                    fontWeight: 500,
                    mt: '1rem',
                    mb: '0.8rem',
                    width: { lg: 'auto', xs: 'max-content' },
                    transform: { lg: 'scale(1)', xs: 'scale(0.8)' },
                  }}
                >
                  {t('artwork_list.control.removebg_info_1')}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    width: { lg: '23.2rem', xs: '13.2rem' },
                    height: { lg: '3.8rem', xs: '2.2rem' },
                    fontSize: { lg: '1.4rem', xs: '1rem' },
                    fontWeight: { lg: 700, xs: 600 },
                  }}
                  onClick={() => {
                    // ----- GA4 event -----
                    window.gtag('event', 'removebg_img_generate_begin', {})
                    // ---------------------
                    setCreateImageDialog(true)
                  }}
                >
                  {t('button.generate_now')}
                </Button>
              </CenterAlignStack>
            </>
          ) : isMannequinPage ? (
            <>
              <ArtowrkDownloadButton downloadCount={artwork.download} handleClick={download} />
              <Divider
                flexItem
                sx={{
                  m: { lg: '2rem 0', xs: '1.4rem 0 1.2rem 0' },
                  borderColor: '#E2E2E2',
                }}
              />
              <CenterAlignStack
                direction="row"
                spacing={{ lg: 1, xs: 0 }}
                sx={{
                  width: '100%',
                  height: { lg: '3.8rem', xs: '2rem' },
                  justifyContent: { lg: 'center', xs: 'space-between' },
                }}
              >
                {isOwner && (
                  <RegenerateButton
                    handleClick={mannequinRegenrate}
                    isOwner={isOwner}
                    buttonClass={redoButtonClass}
                    redoAnimationStart={redoAnimationStart}
                    type="regenerate"
                  />
                )}
                <DeleteSelectedButton handleDelete={handleDelete} disabled={!isOwner} />
              </CenterAlignStack>
            </>
          ) : isModelBgPage || isFacePage ? (
            <>
              <ArtowrkDownloadButton downloadCount={artwork.download} handleClick={download} />
              <Divider
                flexItem
                sx={{
                  m: { lg: '2rem 0', xs: '1.4rem 0 1.2rem 0' },
                  borderColor: '#E2E2E2',
                }}
              />
              <CenterAlignStack
                direction="row"
                spacing={{ lg: 1, xs: 0 }}
                sx={{
                  width: '100%',
                  height: { lg: '3.8rem', xs: '2rem' },
                  justifyContent: { lg: 'center', xs: 'space-between' },
                }}
              >
                {isOwner && (
                  <RegenerateButton
                    // handleClick={handleRegenerate}
                    handleClick={
                      isModelBgPage
                        ? handleModelbgRegenerate
                        : isFacePage
                        ? handleFaceRegenerate
                        : () => {}
                    }
                    isOwner={isOwner}
                    buttonClass={redoButtonClass}
                    // redoAnimationStart={redoAnimationStart}
                    type="regenerate"
                  />
                )}
                <DeleteSelectedButton handleDelete={handleDelete} disabled={!isOwner} />
              </CenterAlignStack>
            </>
          ) : downloadDeleteOnly ? (
            <>
              <ArtowrkDownloadButton
                downloadCount={getPieceDownloadSum()}
                handleClick={download}
                slim={isCommerceUser}
              />
              <DeleteSelectedButton
                fullSize
                handleDelete={handleDelete}
                disabled={!isOwner}
                sx={{ mt: { lg: '1.5rem', xs: '1rem' } }}
              />
            </>
          ) : (
            <>
              <ArtowrkDownloadButton
                downloadCount={getPieceDownloadSum()}
                handleClick={download}
                slim={isCommerceUser}
              />
              {isNCommerceUser && (
                <NaverProductButton
                  text="대표 이미지 교체"
                  type="update"
                  onClick={handleNaverProductImage}
                  loading={nButtonLoading}
                />
              )}
              {isNHNCommerceUser && (
                <NHNProductOutlineButton
                  text="대표 이미지 교체"
                  type="update"
                  onClick={handleNHNProductImage}
                  loading={nhnButtonLoading}
                />
              )}
              {isCafe24CommerceUser && (
                <Cafe24ProductButton
                  text="대표 이미지 교체"
                  type="update"
                  onClick={handleCafe24ProductImage}
                  loading={cafe24ButtonLoading}
                />
              )}
              <Divider
                flexItem
                sx={{
                  m: { lg: '2rem 0', xs: isCommerceUser ? '1rem 0' : '1.4rem 0 1.2rem 0' },
                  borderColor: '#E2E2E2',
                }}
              />
              {config.ARTWORK_REGEN_STATUS[1] === artwork.feedback_status ? (
                <CenterAlignStack direction="row" spacing={1} sx={{ py: 2 }}>
                  <p>수동 재생성 요청됨</p>
                </CenterAlignStack>
              ) : (
                <CenterAlignStack
                  direction="row"
                  spacing={{ lg: 1, xs: 0 }}
                  sx={{
                    width: '100%',
                    height: { lg: '3.8rem', xs: '2rem' },
                    justifyContent: { lg: 'center', xs: 'space-between' },
                  }}
                >
                  {isOwner && (
                    <RegenerateButton
                      handleClick={handleRegenerate}
                      isOwner={isOwner}
                      buttonClass={redoButtonClass}
                      redoAnimationStart={redoAnimationStart}
                      type="regenerate"
                    />
                  )}
                  <DeleteSelectedButton handleDelete={handleDelete} disabled={!isOwner} />
                </CenterAlignStack>
              )}

              {!!usePieces.length && GIFableDate && (
                <>
                  <Divider
                    flexItem
                    sx={{
                      m: { lg: '2rem 0', xs: isCommerceUser ? '1.2rem 0' : '1.4rem 0 1.2rem 0' },
                      borderColor: '#E2E2E2',
                    }}
                  />
                  <IntroduceGIF
                    handleButtonClick={() => {
                      setOpenGIFDialog(true)

                      // ----- GA4 event -----
                      window.gtag('event', portfolioType ? 'gif_begin' : 'portfolio_gif_begin', {
                        method: 'floating',
                      })
                      // ---------------------
                    }}
                  />
                </>
              )}
            </>
          )}

          {/* --------------------------------------------- */}

          {openGIFDialog && (
            <GIFDialog
              artworkId={artwork.id}
              open={openGIFDialog}
              setOpen={setOpenGIFDialog}
              usePieces={usePieces}
              refreshArtworks={refreshArtworks}
            />
          )}

          {openRegenDialog && (
            <RegenerateDialog
              open={openRegenDialog}
              setOpen={setOpenRegenDialog}
              artwork={artwork}
              regenerateArtwork={regenerateArtwork}
              showWarning={currentConfig?.display}
            />
          )}

          {isRemoveBgPage && (
            <CreateBgForNukkiDialog
              open={createImageDialog}
              setOpen={setCreateImageDialog}
              artwork={artwork}
            />
          )}
        </CenterAlignStack>
      )
  }
}

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  '&.MuiCircularProgress-root': {
    color: '#7DA3FF',
  },

  '& .MuiCircularProgress-svg': {
    transform: 'scaleY(-1)',

    '& .MuiCircularProgress-circle': {
      '&::after': {
        content: '" *"',
        color: theme => theme.palette.common.red,
      },
    },
  },
}))

const totalStage = 3

const t0 = (2 * Math.PI) / (totalStage + 1)
const d0 = 360 / (totalStage + 1)

// --------------------
function ProgressLayout({ artwork, setArtwork, pieceIdx, setPieceIdx, refreshArtworks }) {
  const { t, i18n } = useTranslation()
  const isKo = i18n.language === 'ko'
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState(false)
  const [intervalId, setIntervalId] = useState()
  const [user, setUser] = useRecoilState(userAtom)
  const [modPieceError, setModPieceError] = useRecoilState(ArtworkListModPieceErrorAtom)

  const { showConfirm } = useConfirm()
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION
  const isModelBgPage = portfolioType === config.PORTFOLIO_TYPE_MODELBG
  const isBannerPage = portfolioType === config.PORTFOLIO_TYPE_BANNER

  const twoBytwo = isMannequinPage || isBgExpansionPage || isModelBgPage || isBannerPage

  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const notifyArtworkDone = artworkName => {
    const title = t('artwork_list.message.artwork_generate_done')
    const body = isKo
      ? `${artworkName ?? '-'}${t('artwork_list.message.check_artwork_results')}`
      : `Check out the results for ${artworkName ?? '-'} now!
    `
    const options = {
      body,
      icon: isKo
        ? 'https://d1p0kjf7jiqoy5.cloudfront.net/static/logo_image_ko.png'
        : 'https://d1p0kjf7jiqoy5.cloudfront.net/static/logo_image_en.png',
    }

    if ('Notification' in window && Notification.permission === 'granted') {
      return new Notification(title, options)
    }
  }

  const thumbnailMode =
    !config.ARTWORK_ADDPIECE_STATUS !== artwork.status &&
    !config.ARTWORK_MODPIECE_STATUS !== artwork.status &&
    !config.ARTWORK_ERROR_STATUS.includes(artwork.status) &&
    artwork.pieces?.filter(p => p.id).length > 0 &&
    !config.ARTWORK_REGEN_STATUS.includes(artwork.status)

  const r = thumbnailMode ? (isMobile ? 4.5 : 5.5) : isMobile ? 8 : 10 // 원형 progress 의 반지름 길이 (단위는 rem)

  // const checkUserCredit = async () => {
  //   const res = await apis.user.getCredit()
  //   const credit = res.data.credit
  //   setUser({ ...user, credit })
  //   return credit
  // }

  // useEffect(() => {
  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, [intervalId])

  // useEffect(() => {
  //   setProgress(config.ARTWORK_STATUS_DICT[artwork.status]?.progress)
  // }, [artwork])

  // useEffect(() => {
  //   if (config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status) && !intervalId) {
  //     subscribeStatus()
  //   } else if (config.ARTWORK_DONE_STATUS === artwork.status?.toLowerCase() && intervalId) {
  //     unsubscribeStatus()
  //   } else if (config.ARTWORK_ERROR_STATUS.includes(artwork.status?.toLowerCase()) && intervalId) {
  //     unsubscribeStatus()
  //   }
  // }, [artwork.id, artwork.status])

  // const subscribeStatus = () => {
  //   const x = setInterval(() => {
  //     if (!portfolioId) return
  //     apis.portfolio
  //       .getArtwork(portfolioId, artwork.id)
  //       .then(response => {
  //         const a = response.data
  //         // console.log(a)
  //         a.pieces = sortPieces(a)

  //         setArtwork(a)

  //         if (config.ARTWORK_DONE_STATUS === a.status?.toLowerCase()) {
  //           notifyArtworkDone(a.name)

  //           if (config.ARTWORK_MODPIECE_ERROR_STATUS === a.feedback_status) {
  //             setModPieceError(true)
  //           }
  //           if (isBannerPage) {
  //             refreshArtworks()
  //           }

  //           tutorial.mode &&
  //             tutorial.step === '14_5' &&
  //             setTutorial(v => ({ ...v, step: 15, artworkId: a.id }))
  //         } else if (config.ARTWORK_ERROR_STATUS.includes(a.status?.toLowerCase())) {
  //           notify(`오류 - ${a.name}`)
  //           refreshArtworks()
  //           if (tutorial.mode && tutorial.step === '14_5') {
  //             setTutorial(v => ({ ...v, step: 14 }))
  //             showConfirm({
  //               alertOnly: true,
  //               content: (
  //                 <Typography sx={{ lineHeight: '1.3' }}>
  //                   사진 속 상품을 인지하지 못해 오류가 발생하였습니다. 오류 발생 시, 크레딧
  //                   차감되지 않습니다.
  //                   <br />
  //                   <br />
  //                   <span style={{ color: theme.palette.draph.blue, fontWeight: 600 }}>
  //                     다른
  //                   </span>{' '}
  //                   사진을 올려 튜토리얼을 완료해주세요 !
  //                 </Typography>
  //               ),
  //             })
  //           }
  //         }
  //       })
  //       .catch(e => {
  //         console.log(e)
  //       })
  //   }, 2500)
  //   setIntervalId(x)
  // }

  // const unsubscribeStatus = () => {
  //   clearInterval(intervalId)
  //   setIntervalId(null)
  // }

  const gridProps = {
    columns: 12,
    gap: { lg: 1.6, xs: 1 }, // rem 은 알아먹질않아서 ..
    justifyContent: 'flex-start',
    width: { lg: twoBytwo ? '34rem' : '52rem', xs: '32.5rem' },
  }

  if (config.ARTWORK_ERROR_STATUS.includes(artwork.status)) {
    const msg = artwork.err_type
      ? t(`error_message.${artwork.err_type}`)
        ? t([`error_message.${artwork.err_type}`, `error_message.default`])
        : t(`error_message.default`)
      : t(`error_message.default`)

    const msgArr = msg.split('\n')

    const showDelayInfoForSub =
      artwork.err_type?.toString() === '10003' &&
      !(artwork.used_credit < 0) &&
      user.subscription_plan
    const showDelayInfoForNonSub =
      artwork.err_type?.toString() === '10003' && artwork.used_credit < 0 && !user.subscription_plan

    return (
      <>
        <CenterAlignStack>
          <ArtworkWarningIcon />
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 400, textAlign: 'center', mt: '1rem' }}>
            {msgArr.map((s, i) => (
              <span key={i}>
                {s} <br />
              </span>
            ))}

            {
              // 생성 지연 케이스인 경우 (10003), 구독자 여부에 따라 추가 메세지 표시
              showDelayInfoForSub ? (
                <Box sx={{ mt: '2.4rem' }}>
                  <DelayInfoForSubscriber />
                </Box>
              ) : showDelayInfoForNonSub ? (
                <Box sx={{ mt: '2.4rem' }}>
                  <DelayInfoForNonSubscriber />
                </Box>
              ) : (
                <></>
              )
            }
          </Typography>
        </CenterAlignStack>
      </>
    )
  }

  return (
    <>
      {!thumbnailMode ? (
        <Box
          sx={{
            position: 'relative',
            width: `${2 * r}rem`,
            height: `${2 * r}rem`,
          }}
        >
          {/* 두꺼운 회색 바탕 원 */}
          <Box sx={{ position: 'absolute' }}>
            <CircularProgress
              variant="determinate"
              value={100}
              thickness={2.7}
              size={`${2 * r}rem`}
              sx={{
                color: '#EFEFEF',
              }}
            />
          </Box>

          <Typography
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: `${2 * r}rem`,
              textAlign: 'center',
            }}
            fontSize={{ lg: '1.8rem', xs: '1.5rem' }}
            fontWeight={700}
            color="#000000"
          >
            {(artwork.feedback_status === config.ARTWORK_ADDPIECE_STATUS ||
              artwork.feedback_status === config.ARTWORK_MODPIECE_STATUS) &&
            config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)
              ? t('configOrDict.ARTWORK_STATUS_DICT.add')
              : config.ARTWORK_STATUS_DICT[artwork.status]?.text
              ? t(
                  `configOrDict.ARTWORK_STATUS_DICT.${
                    config.ARTWORK_STATUS_DICT[artwork.status]?.text
                  }`
                )
              : ''}
          </Typography>

          {config.ARTWORK_ERROR_STATUS.includes(artwork.status) && artwork.err_type && (
            <Typography
              sx={{
                position: 'absolute',
                left: '50%',
                top: '125%',
                width: '50rem',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              }}
              fontSize={{ lg: '1.8rem', xs: '1.5rem' }}
              fontWeight={500}
            >
              {t([`error_message.${artwork.err_type}`, `error_message.default`])}
            </Typography>
          )}

          {(config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status) ||
            config.ARTWORK_REGEN_STATUS.includes(artwork.status)) && (
            <>
              {/* 돌아가는 애니메이션 원 */}
              <Box
                sx={{
                  position: 'absolute',
                  transform: 'scaleX(-1)',
                }}
              >
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    // color: 'gray',
                    // opacity: '0.5',
                    color: '#C2D4FF',
                    animationDuration: '1500ms',
                  }}
                  size={`${2 * r}rem`}
                  thickness={1}
                />
              </Box>

              {/* 실제 progress 표시 원 */}

              <StyledCircularProgress
                variant="determinate"
                value={progress}
                thickness={1}
                size={`${2 * r}rem`}
              />

              {artwork.feedback_status !== config.ARTWORK_ADDPIECE_STATUS &&
                artwork.feedback_status !== config.ARTWORK_MODPIECE_STATUS &&
                config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx > 0 && (
                  <Box
                    sx={{
                      position: 'relative',
                      borderRadius: '50%',
                      transform: `rotate(-${
                        config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx * d0
                      }deg)`,
                      transition: 'transform 0.3s linear',
                    }}
                    width={`${2 * r}rem`}
                    height={`${2 * r}rem`}
                  >
                    <CenterAlignBox
                      sx={{
                        width: '4.5rem',
                        height: '4.5rem',
                        position: 'absolute',
                        borderRadius: '50%',
                        alignItems: 'center',
                        backgroundColor: theme => theme.palette.draph.blue,
                        transform: `rotate(${
                          config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx * d0
                        }deg)`,
                        left: `${r - 4.5 / 2}rem`,
                        top: `-${4.5 / 2}rem`,
                        transition: 'transform 0.3s linear',

                        boxShadow: '2px 2px 10px rgba(77, 128, 255, 0.4)',

                        // left: `${r + indexPosition[0] - 4.5 / 2}rem`,
                        // top: `${r - indexPosition[1] - 4.5 / 2}rem`,
                        // transition: 'left 1s, top 1s',
                      }}
                    >
                      <Typography fontSize="2rem" fontWeight={800} color="#FFFFFF">
                        {config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx}
                      </Typography>
                    </CenterAlignBox>
                  </Box>
                )}
            </>
          )}
        </Box>
      ) : (
        <Grid container {...gridProps}>
          {artwork.pieces?.map((piece, idx) => {
            const path = piece.path
            // display none 처리할 아이 선택자
            const isHidden =
              artwork.feedback_status === config.ARTWORK_MODPIECE_STATUS &&
              artwork.status !== config.ARTWORK_DONE_STATUS &&
              // 여기 처리해야함
              JSON.parse(artwork.config)?.mod_piece_id === piece.id

            return isHidden ? null : (
              <GeneratedImage
                key={idx}
                idx={idx}
                path={checkedURLforNoCache(path)}
                thumbnailMode={true}
              />
            )
          })}

          <Grid item>
            <Box
              sx={{
                ...generatedImageStyle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '4px 4px 20px 0px #0000000D',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: `${2 * r}rem`,
                  height: `${2 * r}rem`,
                }}
              >
                {/* 두꺼운 회색 바탕 원 */}
                <Box sx={{ position: 'absolute' }}>
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    thickness={2.7}
                    size={`${2 * r}rem`}
                    sx={{
                      color: '#EFEFEF',
                    }}
                  />
                </Box>

                <Typography
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: `${2 * r}rem`,
                    textAlign: 'center',
                  }}
                  fontSize={{ lg: '1.5rem', xs: '1.2rem' }}
                  fontWeight={700}
                  color="#000000"
                >
                  {artwork.feedback_status === config.ARTWORK_ADDPIECE_STATUS &&
                  config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)
                    ? t('configOrDict.ARTWORK_STATUS_DICT.add')
                    : artwork.feedback_status === config.ARTWORK_MODPIECE_STATUS &&
                      config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)
                    ? t('configOrDict.ARTWORK_STATUS_DICT.size')
                    : config.ARTWORK_STATUS_DICT[artwork.status]?.text
                    ? t(
                        `configOrDict.ARTWORK_STATUS_DICT.${
                          config.ARTWORK_STATUS_DICT[artwork.status]?.text
                        }`
                      )
                    : ''}
                </Typography>

                {(config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status) ||
                  config.ARTWORK_REGEN_STATUS.includes(artwork.status)) && (
                  <>
                    {/* 돌아가는 애니메이션 원 */}
                    <Box
                      sx={{
                        position: 'absolute',
                        transform: 'scaleX(-1)',
                      }}
                    >
                      <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                          // color: 'gray',
                          // opacity: '0.5',
                          color: '#C2D4FF',
                          animationDuration: '1500ms',
                        }}
                        size={`${2 * r}rem`}
                        thickness={1}
                      />
                    </Box>

                    {/* 실제 progress 표시 원 */}

                    <StyledCircularProgress
                      variant="determinate"
                      value={progress}
                      thickness={1}
                      size={`${2 * r}rem`}
                    />

                    {artwork.feedback_status !== config.ARTWORK_ADDPIECE_STATUS &&
                      artwork.feedback_status !== config.ARTWORK_MODPIECE_STATUS &&
                      config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx > 0 && (
                        <Box
                          sx={{
                            position: 'relative',
                            borderRadius: '50%',
                            transform: `rotate(-${
                              config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx * d0
                            }deg)`,
                            transition: 'transform 0.3s linear',
                          }}
                          width={`${2 * r}rem`}
                          height={`${2 * r}rem`}
                        >
                          <CenterAlignBox
                            sx={{
                              width: '2.5rem',
                              height: '2.5rem',
                              position: 'absolute',
                              borderRadius: '50%',
                              alignItems: 'center',
                              backgroundColor: theme => theme.palette.draph.blue,
                              transform: `rotate(${
                                config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx * d0
                              }deg)`,
                              left: `${r - 2.5 / 2}rem`,
                              top: `-${2.5 / 2}rem`,
                              transition: 'transform 0.3s linear',

                              boxShadow: '2px 2px 10px rgba(77, 128, 255, 0.4)',

                              // left: `${r + indexPosition[0] - 4.5 / 2}rem`,
                              // top: `${r - indexPosition[1] - 4.5 / 2}rem`,
                              // transition: 'left 1s, top 1s',
                            }}
                          >
                            <Typography fontSize="1.3rem" fontWeight={800} color="#FFFFFF">
                              {config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx}
                            </Typography>
                          </CenterAlignBox>
                        </Box>
                      )}
                  </>
                )}
              </Box>
            </Box>
          </Grid>

          {artwork.pieces?.length % GENERATED_IMAGE_IN_ROW < GENERATED_IMAGE_IN_ROW - 1 && (
            <GeneratedImage path="" />
          )}
          {artwork.pieces?.length % GENERATED_IMAGE_IN_ROW === 0 && <GeneratedImage path="" />}
        </Grid>
      )}
    </>
  )
}
