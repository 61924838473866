import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/css/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot, atom, useRecoilState, useRecoilValue } from 'recoil'
// import './i18n'
import { userAtom } from 'atoms'
import { Loading, ScrollToTopComponenet } from 'components'
import { RFC_2822 } from 'moment'
import axios from 'axios'
import i18n from './i18n'
import { HelmetProvider } from 'react-helmet-async'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ScrollToTopComponenet />
    <RecoilRoot>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </RecoilRoot>
  </BrowserRouter>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

// 구글 태그매니저 - 사용하지않기루 ..
// const gtmScript = document.createElement('script')
// const gtmTxt = document.createTextNode(
//   `;(function (w, d, s, l, i) {
//     w[l] = w[l] || []
//     w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
//     var f = d.getElementsByTagName(s)[0],
//       j = d.createElement(s),
//       dl = l != 'dataLayer' ? '&l=' + l : ''
//     j.async = true
//     j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
//     f.parentNode.insertBefore(j, f)
//   })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_GA_TAGMANAGER_ID}')`
// )
// gtmScript.appendChild(gtmTxt)
// document.getElementsByTagName('head')[0].appendChild(gtmScript)

// const gtmNoScript = document.createElement('noscript')
// const gtmIframe = document.createElement('iframe')
// gtmIframe.setAttribute(
//   'src',
//   `https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GA_TAGMANAGER_ID}`
// )
// gtmIframe.setAttribute('height', 0)
// gtmIframe.setAttribute('width', 0)
// gtmIframe.setAttribute('width', 0)
// gtmIframe.style.display = 'none'
// gtmIframe.style.visibility = 'hidden'
// gtmNoScript.appendChild(gtmIframe)
// document.getElementsByTagName('body')[0].appendChild(gtmNoScript)

// GA4(gtag) config

const paypleScript = document.createElement('script')
const paypleSrc = process.env.REACT_APP_PAYPLE_SRC
paypleScript.setAttribute('src', paypleSrc)
document.getElementsByTagName('head')[0].appendChild(paypleScript)

const convRoot = document.getElementById('conversion-root')
const naScriptSrc = document.createElement('script')

naScriptSrc.setAttribute('type', 'text/javascript')
naScriptSrc.setAttribute('src', '//wcs.naver.net/wcslog.js')
const naScript = document.createElement('script')
naScript.setAttribute('type', 'text/javascript')

const conv = document.createTextNode(
  `
    function naverAnalytics() {
      if(!window.wcs_add) window.wcs_add = {};
      window.wcs_add["wa"] = "1e97b02a77de900";
      if(window.wcs) {
        wcs_do();
      }}
    `
)
naScript.appendChild(conv)

if (process.env.NODE_ENV === 'production') {
  convRoot.appendChild(naScriptSrc)
  convRoot.appendChild(naScript)
}
window.onload = () => {
  if (window.naverAnalytics) window.naverAnalytics()
}
