import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import {
  Link,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Divider,
  SwipeableDrawer,
} from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import {
  CenterAlignBox,
  CenterAlignStack,
  FormTextField,
  GoogleLogin,
  KakaoLogin,
  NaverLogin,
  StyledFormTextField,
  FormErrorMessage,
  RegisterBox,
  TextInput,
  FormCheckbox,
  AgreeDialog,
  UserWithdraw,
} from 'components'
import { RegisterLabel, PhoneInput } from 'components/RegisterComponents'
import { apis } from 'apis'
import { AiFillEye as OpenedEyeIcon, AiFillEyeInvisible as ClosedEyeIcon } from 'react-icons/ai'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useRecoilState } from 'recoil'
import { tutorialAtom, userAtom } from 'atoms'
import { styled, useTheme } from '@mui/material/styles'
import {
  AgreeCheckedSquareIcon,
  AgreeSquareIcon,
  CheckedSquareIcon,
  CheckedSquareIcon2,
  SearchIcon,
  SquareIcon,
  SquareIcon2,
} from 'theme/icon'
import { motion } from 'framer-motion'
import { RiThermometerFill } from 'react-icons/ri'
import { MHidden } from 'components/@material-extend'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useSnackbar } from 'notistack'
import useConfirm from 'hooks/useConfirm'
import moment from 'moment'
import { iOS, isKo } from 'utils/common'
import { promotionInfo, promotionInfoKeys } from './promotionEvents/DomeggookEvent'
import { useNavigateForPromotion } from 'hooks/useNavigateWithQuery'
import { useTranslation } from 'react-i18next'
import { LinkSnsAccountDialog } from '../components/SocialLogin'
import { linkSnsAccountDialogAtom } from '../atoms'
import { Korean, English } from 'hooks/useLanguage'
import * as cookie from 'utils/cookie'
import { PHONE_REGEX } from '../utils/common'

const StyledCheckbox = styled(FormControlLabel)(({ theme }) => ({
  paddingLeft: '3px',

  '& .MuiCheckbox-root': {
    color: 'blue',
  },

  '& .MuiTypography-root': {
    fontSize: '12px',
    fontWeight: 400,
    // color: theme.palette.common.black,
    color: 'black',
  },
}))

const buttonStyle = {
  width: '384px',
}

export const buttonMobileStyle = {
  width: '320px',
  height: '48px',
  mt: '23px',
  fontSize: '18px',
  fontWeight: '800',
}

const TextButtonStyle = {
  fontSize: '12px',
  fontWeight: 400,
  color: 'black',
  cursor: 'pointer',
}

export default function Login({
  naverCommerce = false,
  naverCommerceHandleRegister = null,
  nhnCommerce = false,
  nhnCommerceHandleRegister = null,
}) {
  const initUsername = localStorage.getItem('rememberMe')
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const [openWithdrawInfo, setOpenWithdrawInfo] = useState(false)
  const [withdrawInfo, setWithdrawInfo] = useState({})

  const loginSchema = yup.object().shape({
    username: yup.string().required(t('register.id_error_1')),
    password: yup
      .string()
      .min(2, t('register.password_error_4'))
      .required(t('register.password_error_4')),
  })

  const [open, setOpen] = useState(false)
  const [openMergeDialog, setOpenMergeDialog] = useState(false)
  const [socialLogin, setSocialLogin] = useState({
    open: false,
    socialName: null,
    data: {},
    agreeInfo: null,
  })

  useEffect(() => {
    // 사용자의 지역 정보 가져오기
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     position => {
    //       console.log('User Location:', position.coords.latitude, position.coords.longitude)
    //       // 여기에서 얻은 위치 정보를 서버로 보내거나 활용할 수 있습니다.
    //     },
    //     error => {
    //       console.error('Error getting location:', error.message)
    //     }
    //   )
    // } else {
    //   console.error('Geolocation is not supported by this browser.')
    // }
    // console.log('User Region:', userRegion)
    // 여기에서 가져온 지역 정보를 활용하여 원하는 작업을 수행합니다.

    // ----- GA4 event -----
    window.gtag('event', 'login_begin', {})
    // ---------------------
  }, [])

  const [loading, setLoading] = useState(false)
  const [remember, setRemember] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const [user, setUser] = useRecoilState(userAtom)

  const guestRedirect = cookie.getCookie(cookie.guestRedirectPath)

  const loginLandingAddress =
    localStorage.getItem('landingPoint', '/api_main') ?? guestRedirect ?? '/generate/upload'

  const promotionId = '4'

  const location = useLocation()
  const { navigateEvent, navigateWithQuery } = useNavigateForPromotion()

  useEffect(() => {
    if (localStorage.getItem('promotionQuery')) return

    for (const key of promotionInfoKeys) {
      if (promotionInfo[key].query === location.search) {
        localStorage.setItem('promotionQuery', promotionInfo[key].query)
        break
      }
    }

    return () => {
      localStorage.removeItem('landingPoint')
    }
  }, [])

  const {
    formState: { isDirty, dirtyFields, errors },
    control,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit', // validation will trigger on submit
    defaultValues: {
      username: initUsername ?? '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  })

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (initUsername) {
      setRemember(true)
    }
  }, [])

  const onSubmit = values => {
    setLoading(true)
    apis.user
      .login(values)
      .then(response => {
        const d = response.data

        // 탈퇴 유예 기간인 경우
        if (!d.active && d.leave_requested_at) {
          setWithdrawInfo({ ...d, pw: values.password })
          setOpenWithdrawInfo(true)
          return
        }

        // ----------------

        if (remember) {
          localStorage.setItem('rememberMe', values.username)
        } else {
          localStorage.removeItem('rememberMe')
        }
        const accessToken = d.access_token
        const refreshToken = d.refresh_token
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)

        // ----- GA4 event -----
        window.gtag('event', 'login', {
          method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
        })
        // ---------------------

        navigateEvent({ replace: true }, loginLandingAddress)
      })
      .catch(e => {
        console.log(e)
        if (e.response.status === 401) {
          alert(t('login.login_error_1'))
        } else {
          alert(t('common.error'))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmitNhn = values => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    if (code) {
      apis.nhnCommerce
        .login({ ...values, code })
        .then(response => {
          if (response.data.access_token) {
            // 정상 로그인 처리
            const d = response.data

            const accessToken = d.access_token
            const refreshToken = d.refresh_token
            setAccessToken(accessToken)
            setRefreshToken(refreshToken)

            // ----- GA4 event -----
            window.gtag('event', 'login', {
              method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
            })
            // ---------------------

            navigate('/generate/upload')
          } else {
            alert(response.data.message)
          }
        })
        .catch(e => {
          if (e.response.status === 401) {
            alert(t('login.login_error_1'))
          } else {
            alert(t('common.error'))
          }
        })
    }
  }

  const onSubmitNaver = values => {
    setLoading(true)

    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')
    if (token) {
      apis.naverCommerce
        .linkUser({ ...values, jwe: token })
        .then(response => {
          if (response.data.access_token) {
            // 정상 로그인 처리
            const d = response.data

            const accessToken = d.access_token
            const refreshToken = d.refresh_token
            setAccessToken(accessToken)
            setRefreshToken(refreshToken)

            // ----- GA4 event -----
            window.gtag('event', 'login', {
              method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
            })
            // ---------------------

            navigate('/generate/upload')
          } else {
            if (['E01', 'E02'].includes(response.data.error)) {
              alert(response.data.message)
            }
          }
        })
        .catch(e => {
          if (e.response.status === 401) {
            alert(t('login.login_error_1'))
          } else {
            alert(t('common.error'))
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      alert(t('login.login_error_2'))
      setLoading(false)
    }

    // apis.user
    //   .login(values)
    //   .then(response => {
    //     const d = response.data

    //     if (remember) {
    //       localStorage.setItem('rememberMe', values.username)
    //     } else {
    //       localStorage.removeItem('rememberMe')
    //     }
    //     const accessToken = d.access_token
    //     const refreshToken = d.refresh_token
    //     setAccessToken(accessToken)
    //     setRefreshToken(refreshToken)

    //     // ----- GA4 event -----
    //     window.gtag('event', 'login', {
    //       method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
    //     })
    //     // ---------------------

    //     navigateEvent({ replace: true })

    //     if (!localStorage.getItem('promotionQuery')) {
    //       apis.user.checkEvent(promotionId).then(response => {
    //         const isApply = response.data.applied
    //         // 튜토 OFF
    //         // setTutorial(prev => ({ step: 0, mode: !isApply }))
    //       })
    //     }
    //   })
    //   .catch(e => {
    //     console.log(e)
    //     if (e.response.status === 401) {
    //       alert('아이디 또는 비밀번호를 확인해주세요.')
    //     } else {
    //       alert('오류가 발생하였습니다.')
    //     }
    //   })
    //   .finally(() => {
    //     setLoading(false)
    //   })
  }

  return (
    <>
      <motion.div
        initial={{ x: 10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Desktop>
          <CenterAlignStack sx={{ pt: '80px' }}>
            <Typography
              fontSize="30px"
              fontWeight={800}
              sx={{ color: theme => (naverCommerce ? theme.palette.naver : 'auto') }}
            >
              {naverCommerce
                ? t('login.naver_commerce_login')
                : nhnCommerce
                ? t('login.nhn_commerce_login')
                : t('login.login')}
            </Typography>
            {naverCommerce && (
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={naverCommerceHandleRegister}
              >
                {t('login.naver_sign_up')}
              </Typography>
            )}
            {nhnCommerce && (
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={nhnCommerceHandleRegister}
              >
                {t('login.nhn_sign_up')}
              </Typography>
            )}

            <form
              onSubmit={
                naverCommerce
                  ? handleSubmit(onSubmitNaver)
                  : nhnCommerce
                  ? handleSubmit(onSubmitNhn)
                  : handleSubmit(onSubmit)
              }
            >
              <Stack sx={{ pt: '32.5px', pb: '46.5px' }}>
                <StyledFormTextField
                  placeholder={t('login.id')}
                  name="username"
                  autoComplete="username"
                  control={control}
                  errors={errors}
                  sx={{
                    marginTop: '10px !important',
                    '& .MuiInputBase-root': {
                      width: '384px !important',
                      height: '50px !important',
                      padding: '16px !important',
                      fontSize: '16px !important',
                    },
                  }}
                />

                {errors.username?.message.length > 0 && (
                  <FormErrorMessage message={errors.username?.message} />
                )}

                <StyledFormTextField
                  placeholder={t('login.password')}
                  name="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  control={control}
                  errors={errors}
                  englishMode={true}
                  sx={{
                    '& .MuiInputBase-root': {
                      width: '384px !important',
                      height: '50px !important',
                      padding: '16px !important',
                      fontSize: '16px !important',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowPassword(prev => !prev)}>
                          {showPassword ? (
                            <OpenedEyeIcon style={{ width: '16px', height: '16px' }} />
                          ) : (
                            <ClosedEyeIcon style={{ width: '16px', height: '16px' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.password?.message.length > 0 && (
                  <FormErrorMessage message={errors.password?.message} />
                )}

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%', mb: '10px' }}
                >
                  {!naverCommerce && !nhnCommerce && (
                    <>
                      <StyledCheckbox
                        control={
                          <Checkbox
                            disableRipple
                            checked={remember}
                            icon={<SquareIcon style={{ width: '16px', height: '16px' }} />}
                            checkedIcon={
                              <CheckedSquareIcon style={{ width: '16px', height: '16px' }} />
                            }
                            onChange={e => setRemember(e.target.checked)}
                          />
                        }
                        label={t('login.remember_id')}
                      />
                      <Stack direction="row" alignItems="center">
                        <Typography
                          sx={TextButtonStyle}
                          onClick={() => {
                            navigateWithQuery('/findID')

                            // ----- GA4 event -----
                            window.gtag('event', 'find_id', { method: 'begin' })
                            // ---------------------
                          }}
                        >
                          {t('login.forget_id')}
                        </Typography>

                        <DividerIcon style={{ marginLeft: '9px', marginRight: '9px' }} />

                        <Typography
                          sx={TextButtonStyle}
                          onClick={() => {
                            navigateWithQuery('/findPW')
                            // ----- GA4 event -----
                            window.gtag('event', 'find_pw', { method: 'begin' })
                            // ---------------------
                          }}
                        >
                          {t('login.reset_pw')}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Stack>

                <LoadingButton
                  size="large"
                  type="submit"
                  // onClick={handleClick}
                  // endIcon={<SendIcon />}
                  loading={loading}
                  // loadingPosition="end"
                  variant="contained"
                  sx={{
                    width: '384px',
                    height: '60px',
                    fontWeight: 800,
                    fontSize: '20px',
                    lineHeight: '24px',
                  }}
                >
                  {t('button.login')}
                </LoadingButton>
              </Stack>
            </form>

            {!naverCommerce && !nhnCommerce && (
              <>
                <Typography fontSize="2.5rem" fontWeight={700}>
                  {t('login.SNS_sign_in')}
                </Typography>

                <CenterAlignBox sx={{ pt: '40px', pb: '60px' }} gap="30px">
                  <GoogleLogin
                    setOpen={setOpen}
                    socialLogin={socialLogin}
                    setSocialLogin={setSocialLogin}
                    setOpenWithdrawInfo={setOpenWithdrawInfo}
                    setWithdrawInfo={setWithdrawInfo}
                  />

                  <Korean>
                    <NaverLogin
                      setOpen={setOpen}
                      socialLogin={socialLogin}
                      setSocialLogin={setSocialLogin}
                      setOpenWithdrawInfo={setOpenWithdrawInfo}
                      setWithdrawInfo={setWithdrawInfo}
                    />
                    <KakaoLogin
                      setOpen={setOpen}
                      socialLogin={socialLogin}
                      setSocialLogin={setSocialLogin}
                      setOpenWithdrawInfo={setOpenWithdrawInfo}
                      setWithdrawInfo={setWithdrawInfo}
                    />
                  </Korean>
                </CenterAlignBox>

                {/* <Button
                  size="large"
                  variant="outlined"
                  sx={{ ...buttonStyle, mb: '4rem' }}
                  onClick={() => {
                    navigate('/register')
                    // ----- GA4 event -----
                    window.gtag('event', 'sign_up_method', { method: 'general' })
                    // ---------------------
                  }}
                >
                  {t('login.email_sign_up')}
                </Button> */}

                <CenterAlignStack direction="row" sx={{ pb: '1.5rem' }}>
                  <Typography
                    sx={{
                      fontSize: { lg: '1.6rem', xs: '1.4rem' },
                      fontWeight: 500,
                      mr: '1rem',
                    }}
                  >
                    <Korean>드랩아트 계정이 없으신가요?</Korean>
                    <English>Don&apos;t you have an account?</English>
                  </Typography>
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      fontWeight: 800,
                      fontSize: { lg: '1.6rem', xs: '1.4rem' },
                      color: theme => theme.palette.draph.newblue,
                    }}
                    onClick={() => {
                      navigate('/join')
                      // ----- GA4 event -----
                      // window.gtag('event', 'sign_up_method', {})
                      // ---------------------
                    }}
                  >
                    <Korean>3초만에 회원가입</Korean>
                    <English>Sign up now</English>
                  </Typography>
                </CenterAlignStack>

                <Korean>
                  <img
                    src={'/static/images/event/short_event_banner_pc.png'}
                    style={{ width: '38.4rem', marginBottom: '4rem', cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/event/ko/normal/sns_review_event')
                    }}
                  />
                </Korean>
              </>
            )}
          </CenterAlignStack>
        </Desktop>

        <Mobile>
          <CenterAlignStack sx={{ pt: '70px' }}>
            <Typography
              fontSize="24px"
              fontWeight={800}
              sx={{ color: theme => (naverCommerce ? theme.palette.naver : 'auto') }}
            >
              {naverCommerce
                ? t('login.naver_commerce_login')
                : nhnCommerce
                ? t('login.nhn_commerce_login')
                : t('login.login')}
            </Typography>
            {naverCommerce && (
              <Typography
                sx={{
                  fontSize: '1.3rem',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={naverCommerceHandleRegister}
              >
                {t('login.naver_sign_up')}
              </Typography>
            )}
            {nhnCommerce && (
              <Typography
                sx={{
                  fontSize: '1.3rem',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={nhnCommerceHandleRegister}
              >
                {t('login.nhn_sign_up')}
              </Typography>
            )}
            <form
              onSubmit={
                naverCommerce
                  ? handleSubmit(onSubmitNaver)
                  : nhnCommerce
                  ? handleSubmit(onSubmitNhn)
                  : handleSubmit(onSubmit)
              }
            >
              <Stack sx={{ py: '20px' }}>
                <StyledFormTextField
                  placeholder={t('login.id')}
                  name="username"
                  autoComplete="username"
                  control={control}
                  errors={errors}
                />

                {errors.username?.message.length > 0 && (
                  <FormErrorMessage message={errors.username?.message} />
                )}

                <StyledFormTextField
                  placeholder={t('login.password')}
                  name="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  control={control}
                  errors={errors}
                  englishMode={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ '& .MuiButtonBase-root': { mr: '0 !important' } }}
                      >
                        <IconButton edge="end" onClick={() => setShowPassword(prev => !prev)}>
                          {showPassword ? <OpenedEyeIcon /> : <ClosedEyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.password?.message.length > 0 && (
                  <FormErrorMessage message={errors.password?.message} />
                )}

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%', mb: '10px' }}
                >
                  {!naverCommerce && !nhnCommerce && (
                    <>
                      <StyledCheckbox
                        control={
                          <Checkbox
                            disableRipple
                            checked={remember}
                            icon={<SquareIcon />}
                            checkedIcon={<CheckedSquareIcon />}
                            onChange={e => setRemember(e.target.checked)}
                          />
                        }
                        label={t('login.remember_id')}
                      />
                      <Stack direction="row" alignItems="center">
                        <Typography
                          sx={TextButtonStyle}
                          onClick={() => {
                            navigateWithQuery('/findID')
                            // ----- GA4 event -----
                            window.gtag('event', 'find_id', { method: 'begin' })
                            // ---------------------
                          }}
                        >
                          {t('login.forget_id')}
                        </Typography>

                        <DividerIcon style={{ marginLeft: '9px', marginRight: '9px' }} />

                        <Typography
                          sx={TextButtonStyle}
                          onClick={() => {
                            navigateWithQuery('/findPW')
                            // ----- GA4 event -----
                            window.gtag('event', 'find_pw', { method: 'begin' })
                            // ---------------------
                          }}
                        >
                          {t('login.reset_pw')}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Stack>

                <LoadingButton
                  size="large"
                  type="submit"
                  // onClick={handleClick}
                  // endIcon={<SendIcon />}
                  loading={loading}
                  // loadingPosition="end"
                  variant="contained"
                  sx={buttonMobileStyle}
                >
                  {t('button.login')}
                </LoadingButton>
              </Stack>
            </form>

            {!naverCommerce && !nhnCommerce && (
              <>
                <Typography fontSize="1.6rem" fontWeight={700}>
                  {t('login.SNS_sign_in')}
                </Typography>
                <CenterAlignBox
                  sx={{ pt: '3rem', pb: '2.5rem', justifyContent: 'center', alignItems: 'center' }}
                  gap="40px"
                >
                  <GoogleLogin
                    setOpen={setOpen}
                    socialLogin={socialLogin}
                    setSocialLogin={setSocialLogin}
                    setOpenWithdrawInfo={setOpenWithdrawInfo}
                    setWithdrawInfo={setWithdrawInfo}
                  />

                  <Korean>
                    <NaverLogin
                      setOpen={setOpen}
                      socialLogin={socialLogin}
                      setSocialLogin={setSocialLogin}
                      setOpenWithdrawInfo={setOpenWithdrawInfo}
                      setWithdrawInfo={setWithdrawInfo}
                    />
                    <KakaoLogin
                      setOpen={setOpen}
                      socialLogin={socialLogin}
                      setSocialLogin={setSocialLogin}
                      setOpenWithdrawInfo={setOpenWithdrawInfo}
                      setWithdrawInfo={setWithdrawInfo}
                    />
                  </Korean>
                </CenterAlignBox>

                {/* <Button
                  size="large"
                  variant="outlined"
                  sx={buttonMobileStyle}
                  onClick={() => {
                    navigate('/register')
                    // ----- GA4 event -----
                    window.gtag('event', 'sign_up_method', { method: 'general' })
                    // ---------------------
                  }}
                >
                  {t('login.email_sign_up')}
                </Button> */}

                <CenterAlignStack direction="row" sx={{ py: '1.5rem' }}>
                  <Typography
                    sx={{
                      fontSize: { lg: '1.6rem', xs: '1.4rem' },
                      fontWeight: 500,
                      mr: '1rem',
                    }}
                  >
                    <Korean>드랩아트 계정이 없으신가요?</Korean>
                    <English>Don&apos;t you have an account?</English>
                  </Typography>
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      fontWeight: 800,
                      fontSize: { lg: '1.6rem', xs: '1.4rem' },
                      color: theme => theme.palette.draph.newblue,
                    }}
                    onClick={() => {
                      navigate('/join')
                      // ----- GA4 event -----
                      // window.gtag('event', 'sign_up_method', {})
                      // ---------------------
                    }}
                  >
                    <Korean>3초만에 회원가입</Korean>
                    <English>Sign up now</English>
                  </Typography>
                </CenterAlignStack>
              </>
            )}
            <Korean>
              <img
                src={'/static/images/event/short_event_banner_mo.png'}
                style={{ width: '32rem', cursor: 'pointer', margin: '0' }}
                onClick={() => {
                  navigate('/event/ko/normal/sns_review_event')
                }}
              />
            </Korean>
          </CenterAlignStack>
        </Mobile>
      </motion.div>
      <LoginAddinfoDialog
        open={open}
        setOpen={setOpen}
        socialLogin={socialLogin}
        setSocialLogin={setSocialLogin}
      />

      <LinkSnsAccountDialog open={openMergeDialog} setOpen={setOpenMergeDialog} />
      <UserWithdraw open={openWithdrawInfo} setOpen={setOpenWithdrawInfo} info={withdrawInfo} />
    </>
  )
}

function DividerIcon(props) {
  return (
    <svg
      width="1"
      height="8"
      viewBox="0 0 1 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line x1="0.25" y1="1.09278e-08" x2="0.25" y2="8" stroke="#808080" strokeWidth="0.5" />
    </svg>
  )
}

export const LoginAddinfoDialog = ({ open, setOpen, socialLogin, setSocialLogin }) => {
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const promotionId = '4'
  const { showConfirm } = useConfirm()
  const { t, i18n } = useTranslation()

  const { navigateEvent } = useNavigateForPromotion()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const RegisterSchema = yup.object({
    agreeAge: socialLogin.socialName === 'google' && yup.boolean().oneOf([true], ' ').required(' '),
    phone: yup
      .string()
      .required(t('register.mobile_error_2'))
      .matches(PHONE_REGEX, {
        message: t('register.mobile_error_1'),
        excludeEmptyString: true,
      }),
  })

  const {
    formState: { isDirty, dirtyFields, errors, isSubmitting },
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    setFocus,
    register,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues: {
      phone: '',
      phone1: isKo(i18n) ? '010' : '',
      phone2: '',
      phone3: '',
      agreeAge: false,
      agreeTerms: false,
      agreeData: false,
      agreeMarketing: false,
      agreeAdvertisement: false,
    },
    resolver: yupResolver(RegisterSchema),
    mode: 'onSubmit',
  })

  const [linkAccountDialog, setLinkAccountDialog] = useRecoilState(linkSnsAccountDialogAtom)

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', 'sign_up_begin', {})
      // ---------------------

      checkDuplicateEmail()
    }
  }, [open])

  useEffect(() => {
    setValue('phone2', getValues('phone2').replace(/[^0-9]/g, ''))
    setValue('phone3', getValues('phone3').replace(/[^0-9]/g, ''))

    if (watch('phone2') === '' && watch('phone3') === '') {
      setValue('phone', '')
    }
  }, [watch('phone2'), watch('phone3')])

  const checkDuplicateEmail = () => {
    apis.user
      .checkDuplicateEmail({ data: socialLogin.data, sns: socialLogin.socialName })
      .then(response => {
        if (response.data.exist) {
          setOpen(false)
          openLinkDialog(response.data)
        } else {
          if (['kakao', 'naver'].includes(socialLogin.socialName)) {
            // 휴대폰 번호를 디폴트로 받게 되어 바로 제출하지 않음
            // //////onSubmit()
          } else {
            // 구글은 현재 다이얼로그에서  드랩 자체 이용동의를 따로 받음
          }
        }
      })
  }

  const openLinkDialog = data => {
    setLinkAccountDialog({
      ...linkAccountDialog,
      open: true,
      exist: data.exist,
      username: data.username,
      id: data.id,

      sns: socialLogin.socialName,
      email:
        socialLogin.socialName === 'kakao'
          ? socialLogin.data.kakao_account.email
          : socialLogin.data.email,
      data: socialLogin.data,
      existSns: data.exist_sns,
    })
  }

  const onSubmit = values => {
    const apisValues = { ...values }

    delete apisValues.phone1
    delete apisValues.phone2
    delete apisValues.phone3

    if (socialLogin.socialName === 'kakao') {
      const checkList = ['Terms', 'Data', 'Marketing', 'Advertisement']
      const agreeArray = socialLogin.agreeInfo.tag?.map(info => info.split('_')[0]) ?? []

      // id / snsName 기입

      checkList.forEach(item => {
        // agreeArray에 현재 아이템이 포함되어 있는지 확인
        if (agreeArray.includes(item)) {
          apisValues[`agree${item}`] = true
        } else {
          apisValues[`agree${item}`] = false
        }
      })

      apisValues.agreeTerms = true
      apisValues.agreeData = true
      apisValues.agreeAge = true
      apisValues.login_sns = socialLogin.socialName
      apisValues.login_sns_id = socialLogin.data.id
      apisValues.email = socialLogin.data.kakao_account.email
    }

    if (socialLogin.socialName === 'google') {
      apisValues.agreeTerms = true
      apisValues.agreeData = true
      apisValues.login_sns = socialLogin.socialName
      apisValues.login_sns_id = socialLogin.data.sub
      apisValues.email = socialLogin.data.email
    }

    if (socialLogin.socialName === 'naver') {
      const checkList = ['Terms', 'Data', 'Marketing', 'Advertisement']
      const agreeArray =
        socialLogin.data.agreementInfos?.map(info => info.termCode.split('_')[0]) ?? []

      checkList.forEach(item => {
        // agreeArray에 현재 아이템이 포함되어 있는지 확인
        if (agreeArray.includes(item)) {
          apisValues[`agree${item}`] = true
        } else {
          apisValues[`agree${item}`] = false
        }
      })

      apisValues.agreeTerms = true
      apisValues.agreeData = true
      apisValues.agreeAge = true
      apisValues.login_sns = socialLogin.socialName
      apisValues.login_sns_id = socialLogin.data.id
      apisValues.email = socialLogin.data.email
      apisValues.gender = socialLogin.data.gender
      apisValues.accessToken = socialLogin.accessToken
    }

    apisValues.language = i18n.language

    apis.user
      .createSnsUser(apisValues)
      .then(response => {
        const d = response.data

        // 회원 생성 후 즉시 로그인 처리
        const accessToken = d.access_token
        const refreshToken = d.refresh_token
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)

        // ----- GA4 event -----
        window.gtag('event', 'sign_up', {
          method: socialLogin.socialName,
          date: moment().local().format('YYYY-MM-DD'),
        })
        window.gtag('event', 'login', {
          method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
        })
        window.gtag_report_conversion_register()
        // ---------------------

        // ----- Meta(facebook) pixel event -----
        window.fbq('track', 'CompleteRegistration')
        // --------------------------------------
        // ----- linkedin event -----
        window.lintrk('track', { conversion_id: 17109481 })
        // --------------------------

        navigateEvent({ replace: true, state: { isNewUser: true } }, '/generate/upload')

        if (!localStorage.getItem('promotionQuery')) {
          apis.user.checkEvent(promotionId).then(response => {
            const isApply = response.data.applied
            // 튜토 OFF
            // setTutorial(prev => ({ step: 0, mode: !isApply }))
          })
        }
      })
      .catch(e => {
        console.log(e)
        if (e.response.status === 400) {
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('register.email_code_error_1')}</Typography>,
          })
        } else {
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('common.error')}</Typography>,
          })
        }
      })

    setOpen(false)
  }

  const onChange = event => {
    const state = event.target.checked

    setValue('agreeAge', state)
    setValue('agreeMarketing', state)
    setValue('agreeAdvertisement', state)
  }

  const theme = useTheme()
  const titleStyle = { fontSize: { lg: '2.6rem', xs: '2rem' }, fontWeight: 700 }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: '90rem',
              maxWidth: '90rem',
              borderRadius: '20px',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <DialogContent sx={{ p: { lg: '3rem 0 3rem 0' } }}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <CenterAlignStack>
                <Typography sx={{ ...titleStyle }}>{t('register.add_info')}</Typography>

                <Stack
                  sx={{
                    width: '78rem',
                    mb: '2.3rem',
                    '& .input-row-container': {
                      my: '2rem',

                      '& .input-row': {},
                    },
                  }}
                >
                  <Box className="input-row-container">
                    <CenterAlignStack direction="row" className="input-row">
                      <RegisterLabel required={true} label={t('register.mobile')} />

                      <Stack>
                        <CenterAlignStack direction="row" className="input-wrapper">
                          <Box sx={{ display: 'flex', alignItems: 'center', mr: '2rem' }}>
                            <PhoneInput
                              control={control}
                              name="phone1"
                              id="phone1"
                              // sx={{ width: '10rem', height: '4rem' }}
                              inputProps={{ maxLength: isKo(i18n) ? 3 : 4 }}
                              error={!!errors.phone1}
                              placeholder={isKo(i18n) ? '010' : ''}
                              disabled={isKo(i18n)}
                            />
                            <Typography px={1.45}>ㅡ</Typography>
                            <PhoneInput
                              control={control}
                              name="phone2"
                              id="phone2"
                              inputProps={{ maxLength: 4 }}
                              error={!!errors.phone}
                              // sx={{ width: '10rem', height: '4rem' }}
                              onChange={e => {
                                setValue('phone2', e.target.value)
                                setValue(
                                  'phone',
                                  watch('phone1') + e.target.value + watch('phone3')
                                )
                              }}
                            />
                            <Typography px={1.45}>ㅡ</Typography>
                            <PhoneInput
                              control={control}
                              name="phone3"
                              id="phone3"
                              // sx={{ width: '10rem', height: '4rem' }}
                              inputProps={{ maxLength: 4 }}
                              error={!!errors.phone}
                              onChange={e => {
                                setValue('phone3', e.target.value)
                                setValue(
                                  'phone',
                                  watch('phone1') + watch('phone2') + e.target.value
                                )
                              }}
                            />
                          </Box>
                        </CenterAlignStack>
                      </Stack>
                    </CenterAlignStack>

                    <CenterAlignStack direction="row">
                      <RegisterLabel required={false} label="" />
                      <FormErrorMessage
                        message={errors.phone?.message}
                        style={{ width: 'unset' }}
                      />
                    </CenterAlignStack>
                  </Box>

                  {socialLogin.socialName === 'google' && (
                    <>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontWeight: 500, fontSize: '2.0rem' }}>
                            &nbsp;&nbsp; {t('register.agree_all')}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            icon={<AgreeSquareIcon />}
                            checkedIcon={<AgreeCheckedSquareIcon />}
                            checked={
                              watch('agreeAge') &&
                              watch('agreeMarketing') &&
                              watch('agreeAdvertisement')
                            }
                            indeterminate={
                              !(
                                watch('agreeAge') &&
                                watch('agreeMarketing') &&
                                watch('agreeAdvertisement')
                              )
                              // watch('agreeAge') !== watch('agreeTerms') ||
                              // watch('agreeTerms') !== watch('agreeData') ||
                              // watch('agreeData') !== watch('agreeAge') ||
                              // watch('agreeMarketing') !== watch('agreeAge') ||
                              // watch('agreeMarketing') !== watch('agreeTerms') ||
                              // watch('agreeMarketing') !== watch('agreeData')
                            }
                            indeterminateIcon={<AgreeSquareIcon />}
                            onChange={onChange}
                          />
                        }
                      />
                      <Divider sx={{ my: '1.3rem', borderColor: '#BBBBBB' }} />

                      <FormCheckbox
                        name="agreeAge"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '1.6rem',
                                fontWeight: 300,
                              },
                              errors.agreeAge
                                ? { color: theme.palette.common.red }
                                : { color: 'black' }
                            )}
                          >
                            &nbsp;{t('register.over_14')}
                          </Typography>
                        }
                        checked={getValues('agreeAge')}
                        sx={{ mb: '0.5rem' }}
                      />

                      <FormCheckbox
                        name="agreeMarketing"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '1.6rem',
                                fontWeight: 300,
                              },
                              errors.agreeMarketing ? { color: 'red' } : { color: 'black' }
                            )}
                          >
                            &nbsp;{t('register.agree_marketing')}
                            <AgreeDialog
                              category="agreeMarketing"
                              openButton={
                                <IconButton>
                                  <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                                </IconButton>
                              }
                            />
                          </Typography>
                        }
                        checked={getValues('agreeMarketing')}
                        sx={{ mb: '0.5rem' }}
                      />

                      <FormCheckbox
                        name="agreeAdvertisement"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '1.6rem',

                                fontWeight: 300,
                              },
                              errors.agreeAdvertisement ? { color: 'red' } : { color: 'black' }
                            )}
                          >
                            &nbsp;{t('register.agree_ads')}
                            <AgreeDialog
                              category="agreeAdvertisement"
                              openButton={
                                <IconButton>
                                  <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                                </IconButton>
                              }
                            />
                          </Typography>
                        }
                        checked={getValues('agreeAdvertisement')}
                        sx={{ mb: '0.4rem' }}
                      />
                    </>
                  )}
                </Stack>

                <LoadingButton
                  size="large"
                  type="submit"
                  // onClick={handleClick}
                  // endIcon={<SendIcon />}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  // loadingPosition="end"
                  variant="contained"
                  sx={{
                    width: '32rem',
                    fontSize: '2.2rem',
                    fontWeight: 800,
                    color: theme.palette.common.white,
                  }}
                >
                  {t('register.sign_up')}
                </LoadingButton>
              </CenterAlignStack>
            </form>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={() => {}}
          anchor="bottom"
          sx={{
            '& .MuiBackdrop-root': {
              background: 'rgba(0, 0, 0, 0.5) ',
            },
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <DialogContent sx={{ p: '3.3rem 0 4.3rem 0' }}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <CenterAlignStack>
                <Typography sx={{ ...titleStyle }}>{t('register.add_info')}</Typography>

                <Stack sx={{ width: '32rem', mb: '2.3rem' }}>
                  <Box className="input-row-container">
                    <Stack>
                      <RegisterLabel required={true} label={t('register.mobile')} />

                      <Stack>
                        <CenterAlignStack direction="row" className="input-wrapper">
                          <Box sx={{ display: 'flex', alignItems: 'center', mr: '2rem' }}>
                            <PhoneInput
                              control={control}
                              name="phone1"
                              id="phone1"
                              // sx={{ width: '10rem', height: '4rem' }}
                              inputProps={{ maxLength: 3 }}
                              error={!!errors.phone1}
                              placeholder="010"
                              disabled
                            />
                            <Typography px={1.45}>ㅡ</Typography>
                            <PhoneInput
                              control={control}
                              name="phone2"
                              id="phone2"
                              inputProps={{ maxLength: 4 }}
                              error={!!errors.phone}
                              // sx={{ width: '10rem', height: '4rem' }}
                              onChange={e => {
                                setValue('phone2', e.target.value)
                                setValue(
                                  'phone',
                                  watch('phone1') + e.target.value + watch('phone3')
                                )
                              }}
                            />
                            <Typography px={1.45}>ㅡ</Typography>
                            <PhoneInput
                              control={control}
                              name="phone3"
                              id="phone3"
                              // sx={{ width: '10rem', height: '4rem' }}
                              inputProps={{ maxLength: 4 }}
                              error={!!errors.phone}
                              onChange={e => {
                                setValue('phone3', e.target.value)
                                setValue(
                                  'phone',
                                  watch('phone1') + watch('phone2') + e.target.value
                                )
                              }}
                            />
                          </Box>
                        </CenterAlignStack>
                      </Stack>
                    </Stack>

                    <CenterAlignStack direction="row">
                      <RegisterLabel required={false} label="" />
                      <FormErrorMessage
                        message={errors.phone?.message}
                        style={{ width: 'unset' }}
                      />
                    </CenterAlignStack>
                  </Box>

                  {socialLogin.socialName === 'google' && (
                    <>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontWeight: 500, fontSize: '1.6rem' }}>
                            &nbsp;&nbsp; {t('register.agree_all')}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            icon={<SquareIcon2 />}
                            checkedIcon={<CheckedSquareIcon2 />}
                            checked={
                              watch('agreeAge') &&
                              watch('agreeMarketing') &&
                              watch('agreeAdvertisement')
                            }
                            indeterminate={
                              !(
                                watch('agreeAge') &&
                                watch('agreeMarketing') &&
                                watch('agreeAdvertisement')
                              )
                              // watch('agreeAge') !== watch('agreeTerms') ||
                              // watch('agreeTerms') !== watch('agreeData') ||
                              // watch('agreeData') !== watch('agreeAge') ||
                              // watch('agreeMarketing') !== watch('agreeAge') ||
                              // watch('agreeMarketing') !== watch('agreeTerms') ||
                              // watch('agreeMarketing') !== watch('agreeData')
                            }
                            indeterminateIcon={<SquareIcon2 />}
                            onChange={onChange}
                          />
                        }
                      />
                      <Divider sx={{ my: '1.3rem', borderColor: '#BBBBBB' }} />

                      <FormCheckbox
                        name="agreeAge"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '1.6rem',
                                fontWeight: 300,
                              },
                              errors.agreeAge
                                ? { color: theme.palette.common.red }
                                : { color: 'black' }
                            )}
                          >
                            &nbsp;{t('register.over_14')}
                          </Typography>
                        }
                        checked={getValues('agreeAge')}
                        sx={{ mb: '0.5rem' }}
                      />

                      <FormCheckbox
                        name="agreeMarketing"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '1.6rem',
                                fontWeight: 300,
                              },
                              errors.agreeMarketing ? { color: 'red' } : { color: 'black' }
                            )}
                          >
                            &nbsp;{t('register.agree_marketing')}
                            <AgreeDialog
                              category="agreeMarketing"
                              openButton={
                                <IconButton>
                                  <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                                </IconButton>
                              }
                            />
                          </Typography>
                        }
                        checked={getValues('agreeMarketing')}
                        sx={{ mb: '0.5rem' }}
                      />

                      <FormCheckbox
                        name="agreeAdvertisement"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '1.6rem',
                                fontWeight: 300,
                              },
                              errors.agreeAdvertisement ? { color: 'red' } : { color: 'black' }
                            )}
                          >
                            &nbsp;{t('register.agree_ads')}
                            <AgreeDialog
                              category="agreeAdvertisement"
                              openButton={
                                <IconButton>
                                  <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                                </IconButton>
                              }
                            />
                          </Typography>
                        }
                        checked={getValues('agreeAdvertisement')}
                        sx={{ mb: '0.4rem' }}
                      />
                    </>
                  )}
                </Stack>

                <LoadingButton
                  size="large"
                  type="submit"
                  // onClick={handleClick}
                  // endIcon={<SendIcon />}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  // loadingPosition="end"
                  variant="contained"
                  sx={{
                    width: '32rem',
                    fontSize: '2.2rem',
                    fontWeight: 800,
                    color: theme.palette.common.white,
                  }}
                >
                  {t('register.sign_up')}
                </LoadingButton>
              </CenterAlignStack>
            </form>
          </DialogContent>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}
