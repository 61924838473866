import axios from 'axios'
import { configData } from 'config'
import * as cookie from 'utils/cookie'
import moment from 'moment'
import { sendApiErrorAlert } from 'utils/common'

// let isTokenRefreshing = false

const dataAxios = axios.create({
  baseURL: configData.DATA_URL,
  // withCredentials: true,
  // headers: {
  //   time_delta: moment().utcOffset() / 60,
  // },
})

// dataAxios.interceptors.request.use(config => {
//   const accessToken = cookie.getAccessToken()
//   config.headers.Authorization = `Bearer ${accessToken}`
//   return config
// })

dataAxios.interceptors.response.use(
  response => response,
  async error => {
    const { response } = error

    console.log(error)

    return Promise.reject(error)
  }
)
export default dataAxios
