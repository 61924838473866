import React, { useState, useMemo, useEffect } from 'react'
import { portfolioDetailAtom } from 'atoms'
import { useArtworkQuery, useDeleteArtworks } from 'hooks/useReactQuery'
import { useRecoilValue } from 'recoil'
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Stack,
  useTheme,
  Skeleton,
} from '@mui/material'
import CustomPagination from 'components/CustomPagination'
import { EmptyRow } from './ArtworkRow'
import BannerArtworkRow from './BannerArtworkRow'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { DeleteSelectedButton } from './ArtworkControl'
import { ImageSwiperDialog2 } from './ImageSwiperDialog2'
import BannerEditorV2 from './BannerEditorV2'
import SingleImageDialog from './SingleImageDialog'
import { HashLoader } from 'react-spinners'

export default function BannerArtworkList() {
  const theme = useTheme()
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const [checkedArtworks, setCheckedArtworks] = useState({})

  // Uploaded Image Viewer
  const [openSingleDialog, setOpenSingleDialog] = useState(false)
  const [uploadImage, setUploadImage] = useState('')

  // Piece Image Viewer
  const [openImageDialog, setOpenImageDialog] = useState(false)
  const [imageDialogArtwork, setImageDialogArtwork] = useState({})
  const [imageDialogPieceIdx, setImageDialogPieceIdx] = useState(0)
  const [pieceImageSize, setPieceImageSize] = useState({})

  // Banner Editor
  const [open, setOpen] = useState(false)
  const [editorDialogArtwork, setEditorDialogArtwork] = useState({})

  const {
    data: artworks,
    refetch,
    isLoading,
  } = useArtworkQuery({ portfolioId: portfolioDetail.id }).getArtworks

  const { mutate: deleteArtworks, isPending: isDeleting } = useDeleteArtworks()

  const paginatedArtworks = useMemo(() => {
    if (!artworks) return []
    const startIndex = (currentPage - 1) * itemsPerPage
    return artworks.slice(startIndex, startIndex + itemsPerPage)
  }, [artworks, currentPage])

  const totalPages = useMemo(() => {
    if (!artworks) return 0
    return Math.ceil(artworks.length / itemsPerPage)
  }, [artworks])

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }

  const handleMainCheckboxChange = event => {
    const isChecked = event.target.checked
    const newCheckedArtworks = {}
    paginatedArtworks.forEach(artwork => {
      newCheckedArtworks[artwork.id] = isChecked
    })
    setCheckedArtworks(prevState => ({
      ...prevState,
      ...newCheckedArtworks,
    }))
  }

  const handleRowCheckboxChange = artworkId => {
    setCheckedArtworks(prev => ({
      ...prev,
      [artworkId]: !prev[artworkId],
    }))
  }

  const handleDelete = () => {
    const artworksToDelete = Object.entries(checkedArtworks)
      .filter(([_, isChecked]) => isChecked)
      .map(([id]) => id)

    if (artworksToDelete.length > 0) {
      deleteArtworks({ portfolioId: portfolioDetail.id, artworkIds: artworksToDelete })
    }
  }

  const isAllChecked =
    paginatedArtworks.length > 0 && paginatedArtworks.every(artwork => checkedArtworks[artwork.id])

  const showDialog = (artwork, pIdx, addInfo) => {
    setImageDialogArtwork({ ...artwork, ...addInfo })
    setImageDialogPieceIdx(pIdx)

    setOpenImageDialog(true)
  }

  if (isLoading)
    return (
      <CenterAlignStack sx={{ width: '100%', pt: '10.8rem', pb: '2rem' }}>
        <Stack
          sx={{
            width: '128rem',
            '& .title': {
              fontSize: '4.8rem',
              fontWeight: 800,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '2rem',
              justifyContent: 'space-between',
            }}
          >
            <Typography className="title">Artworks History</Typography>
          </Box>

          <CenterAlignBox sx={{ p: '10rem' }}>
            <HashLoader size={80} color={theme.palette.draph.blue} />
          </CenterAlignBox>
        </Stack>
      </CenterAlignStack>
    )

  return (
    <CenterAlignStack sx={{ width: '100%', pt: '10.8rem', pb: '2rem' }}>
      <Stack
        sx={{
          width: '128rem',
          '& .title': {
            fontSize: '4.8rem',
            fontWeight: 800,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: '2rem',
            justifyContent: 'space-between',
          }}
        >
          <Typography className="title">Artworks History</Typography>
          <CustomPagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            size="small"
            showFirstButton={false}
            showLastButton={false}
            boundaryCount={1}
            siblingCount={1}
            sx={{ minWidth: '15rem' }}
          />
        </Box>
        <Stack>
          {artworks.length === 0 ? (
            <EmptyRow />
          ) : (
            paginatedArtworks.map((artwork, index) => (
              <BannerArtworkRow
                key={artwork.id}
                index={index}
                initArtwork={artwork}
                isChecked={checkedArtworks[artwork.id] || false}
                onCheckboxChange={() => handleRowCheckboxChange(artwork.id)}
                idx={index + 1 + (currentPage - 1) * itemsPerPage}
                showDialog={showDialog}
                pieceImageSize={pieceImageSize}
                setOpen={setOpen}
                setPieceImageSize={setPieceImageSize}
                editorDialogArtwork={editorDialogArtwork}
                setEditorDialogArtwork={setEditorDialogArtwork}
                setOpenSingleDialog={setOpenSingleDialog}
                setUploadImage={setUploadImage}
              />
            ))
          )}
          {Boolean(totalPages) && (
            <CenterAlignStack>
              <CustomPagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                size="small"
                showFirstButton={false}
                showLastButton={false}
                boundaryCount={1}
                siblingCount={1}
              />
            </CenterAlignStack>
          )}
        </Stack>
        {/* <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isAllChecked}
                    onChange={handleMainCheckboxChange}
                    indeterminate={
                      paginatedArtworks.some(artwork => checkedArtworks[artwork.id]) &&
                      !isAllChecked
                    }
                  />
                </TableCell>
                <TableCell>이미지</TableCell>
                <TableCell>아트워크 정보</TableCell>
                <TableCell>
                  <DeleteSelectedButton handleDelete={handleDelete} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {artworks.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <EmptyRow />
                  </TableCell>
                </TableRow>
              ) : (
                paginatedArtworks.map((artwork, index) => (
                  <BannerArtworkRow
                    key={artwork.id}
                    initArtwork={artwork}
                    isChecked={checkedArtworks[artwork.id] || false}
                    onCheckboxChange={() => handleRowCheckboxChange(artwork.id)}
                    idx={index + 1 + (currentPage - 1) * itemsPerPage}
                    showDialog={showDialog}
                    pieceImageSize={pieceImageSize}
                    setOpen={setOpen}
                    setPieceImageSize={setPieceImageSize}
                    editorDialogArtwork={editorDialogArtwork}
                    setEditorDialogArtwork={setEditorDialogArtwork}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer> */}
        {/* <Button variant="contained" onClick={refetch} sx={{ mt: 2 }}>
          Refresh Artworks
        </Button> */}
      </Stack>

      {openImageDialog && (
        <ImageSwiperDialog2
          open={openImageDialog}
          setOpen={setOpenImageDialog}
          pieceIdx={imageDialogPieceIdx}
          setPieceIdx={setImageDialogPieceIdx}
          artwork={imageDialogArtwork}
          setArtwork={setImageDialogArtwork}
        />
      )}

      {openSingleDialog && (
        <SingleImageDialog
          open={openSingleDialog}
          setOpen={setOpenSingleDialog}
          image={uploadImage}
        />
      )}

      {open && (
        <BannerEditorV2
          open={open}
          setOpen={setOpen}
          artwork={editorDialogArtwork}
          setArtwork={setEditorDialogArtwork}
        />
      )}
    </CenterAlignStack>
  )
}
