import { yupResolver } from '@hookform/resolvers/yup'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import { blogPostsAtom, blogTabAtom, languageAtom } from 'atoms'
import {
  ActionButton,
  Arrow,
  CenterAlignBox,
  CenterAlignStack,
  FeatureIntroduction,
  FormErrorMessage,
  FormTextField,
  MobileSwipeableDrawer,
  ScrollToTop,
  SearchInput,
  TextItemList,
} from 'components'
import { English, Korean } from 'hooks/useLanguage'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { LandingCommonLayout } from 'layouts'
import { useEffect, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { useRecoilState, useResetRecoilState } from 'recoil'
import {
  AgreeCheckedSquareIcon,
  CheckedSquareIcon,
  CheckedSquareIcon2,
  CheckedSquareIcon3,
  SearchIcon,
  SquareIcon3,
} from 'theme/icon'
import { EMAIL_REGEX, getS3ImageSrc, removeTags } from 'utils/common'
import * as yup from 'yup'
import { LayoutTail } from './Overview'

const PROMOTION_EVENT_ID = 'ebook_8laZlFmyz6'
const EBOOK_ZIP_FILENAME = '상품썸네일 1000개 만드는 공식.zip'
const DOWNLOAD_ANCHOR_ID = 'ebook_8laZlFmyz6_download'

export default function EBook() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useMobileMediaQuery()

  const [openAgreeDialog, setOpenAgreeDialog] = useState(false)
  const [time, setTime] = useState(60 * 60 * 24 * 3)

  const title = (
    <>
      {/* <Typography className="text main">
        요즘 다들 AI로 썸네일 만드는데 <br />
        어떻게 하는지 알고 계신가요?
      </Typography> */}
    </>
  )

  const exampleTitle = <>문제 상황</>

  const examples = [
    {
      content: (
        <>
          다 똑같은 상품을 판매하고,{' '}
          <Mobile>
            <br />
          </Mobile>
          모두 다 똑같은 판매 이미지를 사용하고 있다.
        </>
      ),
    },
    {
      content: <>상품 등록 한번 하면 천년만년 같은 이미지만 쓴다.</>,
    },
    {
      content: (
        <>
          카테고리 묶여서 너무 스트레스!{' '}
          <Mobile>
            <br />
          </Mobile>
          아이템위너도 묶여서 더 스트레스!
        </>
      ),
    },
  ]

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().matches(EMAIL_REGEX, t('register.email_error_1')).required(),
    agree: yup.boolean().oneOf([true], ' ').required(' '),
  })

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      agree: false,
    },
  })

  const onSubmit = () => {
    const values = getValues()

    apis.common
      .applyEvent(PROMOTION_EVENT_ID, {
        name: values.name,
        email: values.email,
      })
      .then(response => {
        if (response.data.success) {
          //
          const anchor = document.getElementById(DOWNLOAD_ANCHOR_ID)
          if (anchor) {
            anchor.click()

            // ----- GA4 event -----
            window.gtag('event', 'promotion_complete', {})
            // ---------------------
            // ----- Meta(facebook) pixel event -----
            window.fbq('track', 'SubmitApplication')
            // --------------------------------------
          }
          reset()
        }
      })
  }

  return (
    <>
      <LandingCommonLayout>
        {/* 상단 */}
        <FeatureIntroduction
          title={title}
          sx={{
            '& .action-button': {
              '&:hover': {
                '& path': {
                  fill: 'url(#paint0_linear_11435_2771)',
                },
              },
            },
          }}
          buttonText={
            <>
              전자책 무료 다운로드 <Arrow className="arrow-icon" />
            </>
          }
          image={`/static/images/index_page/ebook_main${isMobile ? '_mobile' : ''}.png`}
          handleClickButton={() => {
            const anchor = document.getElementById('download-ebook-form')
            if (anchor) {
              anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
          }}
        />

        <Box sx={{ mt: { lg: '5.2rem', xs: '3.2rem' }, mb: { lg: '12rem', xs: '8.4rem' } }}>
          <TextItemList itemTitle={exampleTitle} items={examples} />
        </Box>

        <CenterAlignStack
          sx={{
            width: '100vw',
            overflowX: 'hidden',
            '& .gradient-text': {
              background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
              backgroundClip: 'text',
              color: 'transparent',
            },
          }}
        >
          {/* 전자책 소개 1 */}
          <CenterAlignStack
            sx={{
              width: '100%',
              background: '#202020',
            }}
          >
            <LayoutTail />

            <CenterAlignStack
              sx={{
                pt: { lg: '8.8rem', xs: '5.6rem' },

                '& .text': {
                  color: 'white',
                  display: 'flex',
                  fontSize: { lg: '3.6rem', xs: '2rem' },
                  fontWeight: 500,

                  '& .decor': {
                    background: 'white',
                    alignItems: 'center',
                    width: { lg: '4.4rem', xs: '2.6rem' },
                    height: { lg: '4.4rem', xs: '2.6rem' },
                    borderRadius: '4px',
                  },
                },
              }}
            >
              <Typography className="text">하나만 바꾸면 모든 것이 바뀝니다.</Typography>
              <CenterAlignBox className="text">
                그것은 바로 &nbsp;
                <CenterAlignStack direction="row" spacing={{ lg: '0.8rem', xs: '0.4rem' }}>
                  <CenterAlignBox className="decor">
                    <span className="gradient-text">썸</span>
                  </CenterAlignBox>
                  <CenterAlignBox className="decor">
                    <span className="gradient-text">네</span>
                  </CenterAlignBox>
                  <CenterAlignBox className="decor">
                    <span className="gradient-text">일</span>
                  </CenterAlignBox>
                </CenterAlignStack>
              </CenterAlignBox>
            </CenterAlignStack>

            <Box
              sx={{
                mt: { lg: '3.9rem', xs: '2.8rem' },
                mb: { lg: '2.8rem', xs: '2.8rem' },
                width: { lg: '60rem', xs: '32rem' },
                height: { lg: '60rem', xs: '32rem' },
                '& img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '1rem',
                },
              }}
            >
              <img src="/static/images/index_page/ebook_thumb.png" />
            </Box>

            <CenterAlignStack
              sx={{
                '& .text': {
                  color: 'white',
                  display: 'flex',
                  fontSize: { lg: '3.2rem', xs: '1.6rem' },
                  fontWeight: 400,

                  '&.bold': {
                    fontWeight: 700,
                  },
                  '& span': {
                    textDecoration: 'underline',
                  },
                },
              }}
            >
              <Typography className="text">내 상품이 상위노출 안되는 이유의</Typography>
              <CenterAlignBox className="text bold">
                <span>99%</span>는 이미지입니다.
              </CenterAlignBox>
            </CenterAlignStack>

            <CenterAlignBox
              sx={{
                mt: { lg: '14rem', xs: '9.8rem' },
                width: { lg: '67.7rem', xs: '32rem' },
                position: 'relative',
                '& .text': {
                  position: 'absolute',
                  color: 'white',
                  fontSize: { lg: '3.6rem', xs: '2rem' },
                  fontWeight: 700,
                  top: { lg: '14.1rem', xs: '6.7rem' },
                },
              }}
            >
              <img src="/static/images/index_page/ebook_1.png" />

              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  background: 'linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 100%)',
                }}
              />
              <Typography className="text">그래서, 드랩이 준비했습니다!</Typography>
            </CenterAlignBox>
          </CenterAlignStack>

          {/* 전자책 소개 2 */}
          <CenterAlignStack
            sx={{ width: '100%', background: 'black', pb: { lg: '14rem', xs: '7rem' } }}
          >
            <LayoutTail bgColor="black" tailColor="#202020" />

            <CenterAlignStack
              sx={{
                pt: { lg: '8.8rem', xs: '5.6rem' },
                '& .text': {
                  color: 'white',
                  fontSize: { lg: '3.6rem', xs: '2rem' },
                  fontWeight: 600,

                  '&.decor': {
                    mt: { lg: '2rem', xs: '1rem' },
                    alignItems: 'center',
                    width: { lg: '42rem', xs: '21.2rem' },
                    height: { lg: '6.8rem', xs: '3.6rem' },
                    background: 'white',
                    borderRadius: { lg: '1rem', xs: '0.6rem' },
                    '& span': {
                      fontWeight: 800,
                    },
                  },
                },
              }}
            >
              <Typography className="text">총 100개 컨셉,</Typography>
              <Typography className="text">상품별로, 시즌별로</Typography>
              <CenterAlignBox className="text decor">
                <span className="gradient-text">다양한 썸네일 만드는 법</span>
              </CenterAlignBox>
            </CenterAlignStack>

            <Box sx={{ width: { lg: '128rem', xs: '32rem' }, mt: { lg: '3rem', xs: '1.5rem' } }}>
              <img src="/static/images/index_page/ebook_2.png" />
            </Box>
          </CenterAlignStack>

          {/* 전자책 다운로드 부분 */}
          <CenterAlignStack
            sx={{ width: '100%', background: '#00168B', pb: { lg: '1rem', xs: '4.8rem' } }}
          >
            <LayoutTail bgColor="#00168B" tailColor="black" />

            <CenterAlignStack
              sx={{
                mt: { lg: '6rem', xs: '5.6rem' },
                '& .text': {
                  color: 'white',
                  display: 'flex',
                  fontSize: { lg: '3.6rem', xs: '2rem' },
                  fontWeight: 600,

                  '&.decor': {
                    alignItems: 'center',
                    width: { lg: '20.4rem', xs: '10.8rem' },
                    height: { lg: '5.2rem', xs: '2.8rem' },
                    background: 'white',
                    borderRadius: { lg: '1rem', xs: '0.6rem' },
                    '& span': {
                      fontSize: { lg: '3.2rem', xs: '1.8rem' },
                      fontWeight: 700,
                    },
                  },
                },
              }}
            >
              <Typography className="text">미드저니와 드랩아트를 사용하여,</Typography>
              <CenterAlignBox>
                <CenterAlignBox className="text decor">
                  <span className="gradient-text">한방에 썸네일</span>
                </CenterAlignBox>
                <Typography className="text"> &nbsp; 만드는 법을 알려드립니다.</Typography>
              </CenterAlignBox>
            </CenterAlignStack>

            <Stack
              sx={{
                flexDirection: { lg: 'row', xs: 'column' },
                mt: { lg: '5.6rem', xs: '4rem' },
                alignItems: { lg: 'start', xs: 'center' },
              }}
            >
              <Box
                sx={{
                  width: { lg: '39rem', xs: '19.5rem' },
                  '& img': {
                    boxShadow: '-10px 10px 20px 0px #00000080',
                  },
                }}
              >
                <img src="/static/images/index_page/ebook_3.png" />
              </Box>
              <CenterAlignStack sx={{ ml: { lg: '4.4rem' }, mt: { lg: 0, xs: '4.8rem' } }}>
                <Stack
                  sx={{
                    width: { lg: '38rem', xs: '32rem' },

                    '& .title, & .item': {
                      width: '100%',
                      height: { lg: '6rem', xs: '4.4rem' },
                    },

                    '& .title': {
                      borderRadius: '1rem 1rem 0 0',
                      background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: { lg: '2.4rem', xs: '1.6rem' },
                      fontWeight: 700,
                    },
                    '& .item': {
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: { lg: '2rem', xs: '1.6rem' },
                      fontWeight: 500,
                      pl: { lg: '1.6rem', xs: '1.4rem' },

                      '& .gradient-text': {
                        fontWeight: 700,
                      },

                      '&:nth-of-type(odd)': {
                        background: '#F2F3F9',
                      },
                      '&:nth-of-type(even)': {
                        background: 'white',
                      },
                      '&:nth-last-of-type(1)': {
                        borderRadius: '0 0 1rem 1rem',
                      },
                    },
                  }}
                >
                  <Box className="title">전자책 목차 (Total 34페이지)</Box>
                  <Box className="item">
                    1. 미드저니로&nbsp;<span className="gradient-text">이미지 생성하는 방법</span>
                  </Box>
                  <Box className="item">
                    2. 프롬프트 바꿔서&nbsp;
                    <span className="gradient-text">새로운 이미지 얻기</span>
                  </Box>
                  <Box className="item">
                    3. 미드저니 최적화 프롬프트&nbsp;
                    <span className="gradient-text">100개 리스트</span>
                  </Box>
                  <Box className="item">
                    4. 내가 가진 제품&nbsp;
                    <span className="gradient-text">사진 누끼따기</span>
                  </Box>
                  <Box className="item">
                    5. 누끼 이미지와 미드저니&nbsp;
                    <span className="gradient-text">이미지 합성하기</span>
                  </Box>
                </Stack>

                <Box
                  sx={{
                    mt: { lg: '2rem', xs: '1.6rem' },
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'white',
                    borderRadius: '1rem',
                    py: { lg: '1.2rem', xs: '0.8rem' },
                    pl: { lg: '1.1rem', xs: '0.9rem' },
                    '& .title': {
                      color: 'white',
                      fontSize: { lg: '1.6rem', xs: '1.2rem' },
                      fontWeight: 700,
                      background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                      px: { lg: '1.1rem', xs: '1.2rem' },
                      py: { lg: '0.7rem', xs: '0.5rem' },
                      borderRadius: '0.6rem',
                      mr: { lg: '1.2rem', xs: '0.8rem' },
                      lineHeight: { lg: 1.3, xs: 1.5 },
                    },
                    '& .text': {
                      fontSize: { lg: '2rem', xs: '1.4rem' },
                      fontWeight: 600,
                    },
                  }}
                >
                  <CenterAlignBox className="title">보너스</CenterAlignBox>
                  <span className="text gradient-text">포토샵 없이 상품 썸네일 만드는 법</span>
                </Box>
              </CenterAlignStack>
            </Stack>
          </CenterAlignStack>

          <CenterAlignStack sx={{ width: '100%' }}>
            {/* 아래방향 삼각형 배경색 */}
            <Box
              sx={{
                width: '0px',
                height: '0px',
                borderLeft: '50vw solid transparent',
                borderRight: '50vw solid transparent',
                borderTop: { lg: '28.8rem solid #00168B', xs: '10.4rem solid #00168B' },
                position: 'absolute',
              }}
            />

            {/* 전자책 다운로드 폼 */}
            <CenterAlignStack
              sx={{
                position: 'relative',
              }}
            >
              <ArrowInCirciel sx={{ top: { lg: '24px', xs: 0 }, mb: { lg: 0, xs: '2.4rem' } }} />
              <CenterAlignStack
                id="download-ebook-form"
                sx={{
                  width: { lg: '82.2rem', xs: '32rem' },
                  borderRadius: {
                    lg: '2rem',
                    xs: '1rem',
                  },
                  background: '#FFFFFFE5',
                  boxShadow: '10px -10px 50px 0px #00000033',
                  pt: { lg: '4.5rem', xs: '3rem' },
                  pb: { lg: '4rem', xs: '3rem' },

                  '& .title': {
                    fontSize: { lg: '2.8rem', xs: '1.6rem' },
                    fontWeight: 600,
                    color: 'black',
                    mb: { lg: '2.8rem', xs: '2rem' },
                  },

                  '& .item-row': {
                    justifyContent: { lg: 'space-between', xs: 'right' },
                  },

                  '& .item-name': {
                    mr: { lg: '2rem', xs: '1rem' },
                    fontSize: { lg: '2.4rem', xs: '1.4rem' },
                    fontWeight: 500,
                    color: 'black',
                    '&::after': {
                      content: '"*"',
                      color: theme => theme.palette.common.red,
                      marginLeft: '4px',
                    },
                  },

                  '& .item-value': {
                    width: { lg: '56rem', xs: '20.6rem' },
                    '& .MuiInputBase-root ': { p: 0 },

                    '& input': {
                      background: 'white',
                      border: '1px solid #D9E1EC',
                      p: 0,
                      px: '1rem',
                      height: { lg: '6rem', xs: '3.6rem' },
                      fontSize: { lg: '2rem', xs: '1.4rem' },
                    },
                  },
                }}
              >
                <Typography className="title">지금 전자책을 다운로드하세요!</Typography>
                <Stack
                  spacing={{ lg: '1.6rem', xs: '0.8rem' }}
                  sx={{ pb: { lg: '3.2rem', xs: '2.4rem' } }}
                >
                  <CenterAlignStack direction="row" className="item-row">
                    <Typography className="item-name">이름</Typography>
                    <FormTextField
                      className="item-value"
                      name="name"
                      control={control}
                      errors={errors}
                    />
                  </CenterAlignStack>
                  <CenterAlignStack direction="row" className="item-row">
                    <Typography className="item-name">업무 메일</Typography>
                    <FormTextField
                      className="item-value"
                      name="email"
                      control={control}
                      errors={errors}
                    />
                  </CenterAlignStack>
                  {/* <FormErrorMessage
                      style={{ paddingTop: 0, paddingBottom: 0, height: 'unset' }}
                      motionStyle={{ marginTop: '3px', paddingRight: '5px' }}
                      message={errors.email?.message}
                    /> */}
                </Stack>

                <FormCheckbox
                  name="agree"
                  control={control}
                  errors={errors}
                  sx={{ maxWidth: { lg: 'unset', xs: '28rem' } }}
                  label={
                    <Typography
                      onClick={() => {
                        setOpenAgreeDialog(true)
                      }}
                      className="checkbox-label"
                      sx={{
                        fontSize: { lg: '2.2rem', xs: '1.4rem' },
                        fontWeight: 500,
                        '&::after': {
                          content: '"*"',
                          color: theme => theme.palette.common.red,
                          marginLeft: '2px',
                        },
                      }}
                    >
                      {t('api_info.inquiry_agree')}
                    </Typography>
                  }
                />
                <a
                  id={DOWNLOAD_ANCHOR_ID}
                  href="/static/files/ebook/aithumbnail_ebook_v2_0820.zip"
                  download={EBOOK_ZIP_FILENAME}
                  style={{ display: 'none' }}
                ></a>

                <ActionButton
                  disabled={!isValid}
                  sx={{
                    mt: { lg: '2rem', xs: '1rem' },
                    fontSize: { lg: '2.4rem', xs: '1.6rem' },
                    fontWeight: 700,
                    lineHeight: 1.4,
                    width: { lg: '40rem', xs: '26rem' },
                    py: { lg: '1rem', xs: '1rem' },
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  전자책 무료 다운로드
                </ActionButton>

                <CenterAlignBox
                  sx={{
                    mt: { lg: '2.4rem', xs: '1.2rem' },
                    alignItems: 'center',
                    '& .text, & .timer': {
                      color: 'black',
                      fontSize: { lg: '2rem  !important', xs: '1.2rem !important' },
                      fontWeight: 400,
                    },
                  }}
                >
                  <Typography className="text">무료 다운로드 가능 : </Typography>&nbsp;
                  <Timer
                    time={time}
                    setTime={setTime}
                    style={{ color: 'black', fontSize: { lg: '2rem' }, fontWeight: 400 }}
                  />
                </CenterAlignBox>
              </CenterAlignStack>
            </CenterAlignStack>
          </CenterAlignStack>
        </CenterAlignStack>

        <AgreeDialog open={openAgreeDialog} setOpen={setOpenAgreeDialog} />
      </LandingCommonLayout>
    </>
  )
}

const ArrowInCirciel = ({ sx }) => (
  <Box
    sx={{
      position: 'relative',
      display: 'flex',
      '& svg.arrow': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
      ...sx,
    }}
  >
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="white" />
    </svg>
    <svg
      className="arrow"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 15L0.339745 0L17.6603 0L9 15Z" fill="url(#paint0_linear_11259_2431)" />
      <defs>
        <linearGradient
          id="paint0_linear_11259_2431"
          x1="19"
          y1="5"
          x2="-1"
          y2="5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C4DFF" />
          <stop offset="1" stopColor="#8D00FC" />
        </linearGradient>
      </defs>
    </svg>
  </Box>
)

const FormCheckbox = ({ control, name, rules = {}, checked, ...props }) => {
  const {
    field: { ref, value, ...fieldprops },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <>
      <Desktop>
        <FormControlLabel
          {...props}
          sx={{ ml: '-8px' }}
          control={
            <Checkbox
              icon={<SquareIcon3 />}
              checkedIcon={<CheckedSquareIcon3 />}
              {...fieldprops}
              checked={value}
              inputRef={ref}
            />
          }
        />
      </Desktop>

      <Mobile>
        <FormControlLabel
          {...props}
          control={
            <Checkbox
              icon={<SquareIcon3 sx={{ width: '1.4rem', height: '1.4rem' }} />}
              checkedIcon={<CheckedSquareIcon3 sx={{ width: '1.4rem', height: '1.4rem' }} />}
              {...fieldprops}
              checked={value}
              inputRef={ref}
            />
          }
        />
      </Mobile>
    </>
  )
}

const AgreeDialog = ({ open, setOpen }) => {
  const { t } = useTranslation()

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          sx={{ '& .MuiPaper-root': { borderRadius: '2rem' } }}
        >
          <DialogContent>
            {agreeDialogContent}

            <CenterAlignStack sx={{ width: '100%' }}>
              <Button
                variant="contained"
                sx={{
                  width: { lg: '14rem', xs: '12rem' },
                  height: { lg: '5rem', xs: '4.8rem' },
                  fontSize: { lg: '2rem', xs: '1.8rem' },
                  fontWeight: { lg: 800, xs: 800 },
                  borderRadius: '1rem',
                }}
                onClick={() => {
                  setOpen(false)
                }}
              >
                {t('button.close')}
              </Button>
            </CenterAlignStack>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {}}
          onClose={() => {
            setOpen(false)
          }}
        >
          <Stack sx={{ px: '2rem' }}>{agreeDialogContent}</Stack>
          <CenterAlignStack sx={{ width: '100%', mb: '2.2rem' }}>
            <Button
              variant="contained"
              sx={{
                width: { lg: '14rem', xs: '12rem' },
                height: { lg: '5rem', xs: '4.8rem' },
                fontSize: { lg: '2rem', xs: '1.8rem' },
                fontWeight: { lg: 800, xs: 800 },
                borderRadius: '1rem',
              }}
              onClick={() => {
                setOpen(false)
              }}
            >
              {t('button.close')}
            </Button>
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

const agreeDialogContent = (
  <Stack
    sx={{
      width: { lg: '34.6rem', xs: '32rem' },
      '& .title': {
        fontSize: '1.4rem',
        fontWeight: 700,
        mb: '0.4rem',
      },
      '& .content': {
        fontSize: '1.2rem',
        fontWeight: 400,
        lineHeight: 1.2,
      },
    }}
  >
    <Typography className="title">
      <Korean>개인정보 수집 및 이용 동의</Korean>
      <English>Consent to Collection and Use of Personal Information</English>
    </Typography>
    <Typography className="content" sx={{ mb: '2.4rem' }}>
      <Korean>
        {`고객이 입력한 개인정보는 '정보통신망 이용촉진 및 정보보호 등 에 관한 법률'에 따라
      주식회사 드랩이 수집, 이용 시 본인의 동의를 얻어야 하는 정보입니다. 입력하신
      개인정보는 아래의 목적으로만 사용되며, 이외의 용도로는 이용되지 않습니다. 드랩아트
      프로모션 참여 고객은 개인정보 수집 · 이용에 대하여 동의를 거부할 권리가 있으며,
      미동의 시 드랩아트 프로모션 참여를 하실 수 없습니다.`}
      </Korean>
      <English>
        {`According to the 'Act on Promotion of Information and Communications Network Utilization and Information Protection, etc.', Draph Co., Ltd. requires your consent to collect and use your personal information. The personal information you provide will be used solely for the purposes outlined below and will not be used for any other purposes. You have the right to refuse consent to the collection and use of your personal information. However, if you do not consent, you will not be able to apply Draph Art promotions.
`}
      </English>
    </Typography>

    <Typography className="title">
      <Korean>개인정보 수집 및 이용 목적</Korean>
      <English>Purpose of Collecting and Using Personal Information</English>
    </Typography>
    <Typography className="content" sx={{ mb: '2.4rem' }}>
      <Korean>드랩아트 프로모션 참여</Korean>
      <English>Applying for Draph Art promotion</English>
    </Typography>

    <Typography className="title">
      <Korean>수집하는 개인정보 항목</Korean>
      <English>Items of Personal Information Collected</English>
    </Typography>
    <Typography className="content" sx={{ mb: '2.4rem' }}>
      <Korean>이름, 업무 메일</Korean>
      <English>Name, Business Email</English>
    </Typography>

    <Typography className="title">
      <Korean>개인정보 보유 및 이용 기간</Korean>
      <English>Retention and Use Period of Personal Information</English>
    </Typography>
    <Typography className="content" sx={{ mb: '2.4rem' }}>
      <Korean>
        드랩아트 프로모션 참여 후 1년 (단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에
        따라 보관)
      </Korean>
      <English>
        1 year after applying Draph Art promotion (However, if required by relevant laws and
        regulations, the information will be retained according to those laws).
      </English>
    </Typography>
  </Stack>
)

const Timer = ({ time, setTime, style }) => {
  const theme = useTheme()

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(prevTime => prevTime - 1)
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const formatTime = time => {
    const seconds = time % 60
    const minutes = Math.floor((time % 3600) / 60)
    const hours = Math.floor((time % (3600 * 24)) / 3600)
    const days = Math.floor(time / (3600 * 24))

    return time > 0
      ? `${days}일 ${hours}시간 ${minutes}분 ${seconds.toString().padStart(2, '0')}초`
      : ''
  }

  return (
    <span
      className="timer"
      style={{
        fontWeight: 600,
        fontSize: '1.3rem',
        ...style,
      }}
    >
      {formatTime(time)}
    </span>
  )
}
