import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import {
  indexAtom,
  mannequinLoadingAtom,
  modelRenerateDialogAtom,
  portfolioDetailAtom,
  portfolioTypeAtom,
  regenerateUploadFilesAndUrlAtom,
  removeMaskImgAtom,
  retryMannequinAtom,
  segmentLoadingAtom,
  selectedMaskImgAtom,
  uploadFilesAndUrlAtom,
  uploadLoadingAtom,
  userAtom,
} from 'atoms'
import { useApiHandler } from 'hooks/useApiHandler'
import { English, Korean } from 'hooks/useLanguage'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { CloseCuteIcon, EraserIcon, RefreshIcon } from 'theme/icon'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { CreateButton, CustomOutlinedInput, SpanDraphBlue } from './CustomStyle'
import { StyledToggleButton } from './portfolio/BannerConfig'
import {
  MODEL,
  PORTFOLIO_TYPE_FACE,
  PORTFOLIO_TYPE_MODELBG,
  PORTFOLIO_TYPE_MANNEQUIN,
} from 'config'
import { AutoThumb } from './portfolio/ConfigComponent'
import { Segment } from './fragment/Segment'
import { useFragment } from 'hooks/useFragement'
import { useResetNpy } from 'hooks/useResetNpy'
import { Mannequin } from 'pages'
import MobileSwipeableDrawer from './MobileSwipeableDrawer'
import { DialogButton } from './DialogButtons'
import { getUserType } from 'utils/user'
import { imgMergeAndGrayScale } from './portfolio/UploadMannequinDialog'
import { usePortfolio } from 'hooks/usePortfolio'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import useConfirm from 'hooks/useConfirm'
import { BarLoader } from 'react-spinners'
import { triggerGA4UploadEventManualConfig } from './portfolio/Header'

export function ModelRenerateDialog() {
  const user = useRecoilValue(userAtom)

  const files = useRecoilValue(regenerateUploadFilesAndUrlAtom)
  const [dialog, setDialog] = useRecoilState(modelRenerateDialogAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const retryMannequin = useRecoilValue(retryMannequinAtom)
  const [loading, setLoading] = useRecoilState(mannequinLoadingAtom)

  const [gender, setGender] = useState('female')
  const [model, setModel] = useState('auto')
  const [prompt, setPrompt] = useState('')
  const [age, setAge] = useState('adult')
  const [regenerating, setRegenerating] = useState(false)

  const { artwork, setArtwork, refreshArtwork, refreshRegenCount, pieceImageSize, currentPieceId } =
    dialog
  const { callApi, abortApi } = useApiHandler({ apiKey: 'getNpyMaps', apis: apis.appfront })

  const resetDialog = useResetRecoilState(modelRenerateDialogAtom)
  const resetRegenerateUploadFilesAndUrlAtom = useResetRecoilState(regenerateUploadFilesAndUrlAtom)
  const resetUploadFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const { t } = useTranslation()
  const { resetAllState } = useResetNpy()
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()
  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()
  const { showConfirm } = useConfirm()

  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isFacePage = portfolioType === PORTFOLIO_TYPE_FACE
  const isModelBgPage = portfolioType === PORTFOLIO_TYPE_MODELBG
  const isMannequinPage = portfolioType === PORTFOLIO_TYPE_MANNEQUIN

  const isDisabled = () => {
    if (regenerating) return true

    if (isFacePage || isMannequinPage) {
      return !selectedMaskImg.length || segmentLoading
    }

    if (isModelBgPage) {
      // ModelBg 페이지에 대한 특별한 조건이 필요하다면 여기에 추가
      return false // 또는 ModelBg 페이지에 맞는 조건
    }

    // 기본적으로 비활성화하지 않음
    return false
  }
  const reset = () => {
    resetDialog()
    resetAllState()
    abortApi()
    files.forEach(image => URL.revokeObjectURL(image.url))
    resetRegenerateUploadFilesAndUrlAtom()
    setGender('female')
    setModel('auto')
    setPrompt('')
    setAge('adult')
    setRegenerating(false)
    if (isMannequinPage) {
      resetUploadFiles()
    }
  }

  useEffect(() => {
    if (!artwork || !artwork.config) return

    if (retryMannequin.config) {
      const config = retryMannequin.config

      setGender(config.is_male ? 'male' : 'female')
      setAge(config.age ?? 'adult')
      setPrompt(config.prompt ?? '')
    }
    if (isFacePage || isModelBgPage) {
      const config = JSON.parse(artwork?.config)
      setGender(config.is_male ? 'male' : 'female')
      setModel(config.model_name ?? 'auto')
    }
  }, [retryMannequin, artwork])

  const handleClose = (e, r) => {
    if (r && (r === 'backdropClick' || r === 'escapeKeyDown')) return
    reset()
  }

  useEffect(() => {
    if (!artwork || !artwork.config) return

    const config = JSON.parse(artwork.config)
    // console.log('config:', config)
    // console.log('artwork:', artwork)
    // console.log('pieceImageSize:', pieceImageSize[currentPieceId])
    // console.log('model:', model)
    // console.log('gender:', gender)
    // const race = config?.facemorphing_race
    // if (race) {
    //   setRace(race)
    // }

    // const g = config?.facemorphing_gender
    // if (g) {
    //   setGender(g === 'man' ? 'male' : 'female')
    // }
  }, [artwork, model, gender])

  useEffect(() => {
    if (dialog.open) {
      // ----- GA4 event -----
      // window.gtag('event', 'model_face_regenerate_begin', {})
      // ---------------------
    }
  }, [dialog.open])

  const handleRegenerate = () => {
    switch (true) {
      case isMannequinPage:
        return async () => {
          setLoading(true)
          setRegenerating(true)
          const feedback = 'regenerate_auto'

          const mask = await imgMergeAndGrayScale(selectedMaskImg)

          const formData = new FormData()

          if (!retryMannequin.isRetry) {
            const ready = await prepareUpload([{}], null, null, null, true)
            if (!ready.success) return
          }
          const config = retryMannequin.config

          formData.append('image', files[0].file)
          formData.append('binary_mask', mask)
          formData.append('user_id', user.id)
          formData.append('username', user.username)
          formData.append('user_type', getUserType(user))
          formData.append('gen_type', retryMannequin.isRetry ? 'retry' : 'initial')
          formData.append('is_male', gender === 'male')
          formData.append('mann2man_type', config?.mann2man_type ?? 'mann')

          formData.append('portfolio_id', portfolioDetail.id)
          formData.append('artwork_id', retryMannequin.isRetry ? retryMannequin.artworkId : '')
          formData.append('prompt', prompt)
          formData.append('age', age)

          // ----- GA4 event -----
          triggerGA4UploadEventManualConfig(
            {
              image_type: config?.mann2man_type === 'human' ? '일반 이미지' : '마네킹',
              menu: gender === 'male' ? '남' : '여', // 성별
              method: age === 'baby' ? '아기' : age === 'child' ? '어린이' : '성인', // 연령대
              theme: prompt.length > 0, // 배경 테마가 공란인지(false) 입력값있는지(true)
            },
            retryMannequin.isRetry ? 'mannequin_regenerate' : 'mannequin_complete'
          )
          // ---------------------

          try {
            if (retryMannequin.isRetry) {
              // Update artwork feedback

              await apis.portfolio.updateArtworkFeedback(
                portfolioDetail.id,
                retryMannequin.artworkId,
                { feedback }
              )

              // Get man-to-man data
              await apis.appfront.getMann2man(formData)

              if ('Notification' in window && Notification.permission === 'default') {
                showBrowserNotificationDialog()
              }

              // Create a new config object
              const beforeConfig = retryMannequin.config
              const afterConfig = JSON.stringify({
                ...beforeConfig,
                age: age,
                is_male: gender === 'male',
                prompt,
              })

              // Update artwork with new config
              const response = await apis.portfolio.updateArtwork(
                portfolioDetail.id,
                retryMannequin.artworkId,
                {
                  config: afterConfig,
                }
              )

              // Refresh artworks and close the modal
              refreshArtworks()
              handleClose()
            } else {
              await apis.appfront.getMann2man(formData)

              if ('Notification' in window && Notification.permission === 'default') {
                showBrowserNotificationDialog()
              }

              handleClose()
              refreshArtworks()
              reset()
            }
          } catch (error) {
            showConfirm({
              content: t('upload_dialog.warning'),
              alertOnly: true,
            })
          } finally {
            setLoading(false)
          }
        }

      case isFacePage:
        return async () => {
          setLoading(true)
          setRegenerating(true)
          const feedback = 'regenerate_auto'

          const mask = await imgMergeAndGrayScale(selectedMaskImg)

          const formData = new FormData()

          formData.append('image', files[0].file)
          formData.append('binary_mask', mask)
          formData.append('user_id', user.id)
          formData.append('username', user.username)
          formData.append('user_type', getUserType(user))
          formData.append('gen_type', 'retry')
          formData.append('is_male', gender === 'male')

          formData.append('portfolio_id', portfolioDetail.id)
          formData.append('artwork_id', artwork.id)
          formData.append('age', age)
          formData.append('model_name', model)

          // ----- GA4 event -----
          // triggerGA4UploadEventManualConfig(
          //   retryMannequin.isRetry
          //     ? { theme: prompt.length > 0 }
          //     : {
          //         image_type: config?.mann2man_type === 'human' ? '일반 이미지' : '마네킹',
          //         menu: gender === 'male' ? '남' : '여', // 성별
          //         method: age === 'baby' ? '아기' : age === 'child' ? '어린이' : '성인', // 연령대
          //         theme: prompt.length > 0, // 배경 테마가 공란인지(false) 입력값있는지(true)
          //       },
          //   retryMannequin.isRetry ? 'mannequin_regenerate' : 'mannequin_complete'
          // )
          // ---------------------

          try {
            // Update artwork feedback

            await apis.portfolio.updateArtworkFeedback(portfolioDetail.id, artwork.id, { feedback })

            // Get man-to-man data
            await apis.appfront.genFace(formData)

            if ('Notification' in window && Notification.permission === 'default') {
              showBrowserNotificationDialog()
            }

            // Create a new config object
            const beforeConfig = JSON.parse(artwork.config)
            const afterConfig = JSON.stringify({
              ...beforeConfig,
              is_male: gender === 'male',
              model_name: model,
            })

            // Update artwork with new config
            const response = await apis.portfolio.updateArtwork(portfolioDetail.id, artwork.id, {
              config: afterConfig,
            })

            // Refresh artworks and close the modal
            refreshArtworks()
            handleClose()
          } catch (error) {
            showConfirm({
              content: t('upload_dialog.warning'),
              alertOnly: true,
            })
          } finally {
            setLoading(false)
          }
        }

      case isModelBgPage:
        return async () => {
          try {
            const feedback = 'regenerate_auto'
            const artworkConfig = JSON.parse(artwork.config)

            // ----- GA4 event -----
            triggerGA4UploadEventManualConfig(
              {
                menu: gender === 'male' ? '남' : '여',
                method: model.sort().join(', '),
              },
              'modelbg_regenerate'
            )
            // ---------------------

            setArtwork({ ...artwork, status: feedback })
            handleClose()

            const formData = new FormData()
            formData.append('user_id', user.id)
            formData.append('username', user.username)
            formData.append('user_type', getUserType(user))
            formData.append('artwork_id', artwork.id)
            formData.append('portfolio_id', artwork.portfolio_id)
            formData.append('retry_type', 'regenerate')

            const genOptions = {
              ...artworkConfig,
              flag_human_background: true,
              is_male: gender === 'male',
              model_name: model,
              // 변경하여 적용할 config
            }
            formData.append('gen_options', JSON.stringify(genOptions))

            await apis.portfolio.updateArtworkFeedback(artwork.portfolio_id, artwork.id, {
              feedback,
            })
            refreshArtwork()

            try {
              await apis.appfront.retry(formData)
              await apis.portfolio.updateArtwork(portfolioDetail.id, artwork.id, {
                config: JSON.stringify(genOptions),
              })
            } catch (error) {
              console.log(error)
            } finally {
              refreshArtwork()
            }

            refreshRegenCount()
          } catch (error) {
            console.log(error)
            alert('오류가 발생하였습니다')
            refreshArtwork()
          }
        }
    }

    // const feedback = 'regenerate_auto'
    // const artworkConfig = JSON.parse(artwork.config)
    // // ----- GA4 event -----
    // window.gtag('event', 'model_face_regenerate', { method: `${gender}_${race}` })
    // // ---------------------
    // setArtwork({ ...artwork, status: feedback })
    // const formData = new FormData()
    // formData.append('user_id', user.id)
    // formData.append('username', user.username)
    // formData.append('user_type', getUserType(user))
    // formData.append('artwork_id', artwork.id)
    // formData.append('portfolio_id', artwork.portfolio_id)
    // formData.append('retry_type', 'regenerate')
    // const genOptions = {
    //   ...artworkConfig,
    //   flag_human_background: true,
    //   // 변경하여 적용할 config
    //   flag_facemorphing: true,
    //   facemorphing_race: race,
    //   facemorphing_gender: gender === 'male' ? 'man' : 'woman',
    //   //
    // }
    // formData.append('gen_options', JSON.stringify(genOptions))
    // // handleClose()
    // apis.portfolio
    //   .updateArtworkFeedback(artwork.portfolio_id, artwork.id, {
    //     feedback,
    //   })
    //   .then(() => {
    //     handleClose()
    //     refreshArtwork()
    //     apis.appfront
    //       .retry(formData)
    //       .then(() => {
    //         refreshArtwork()
    //       })
    //       .catch(error => {
    //         console.log(error)
    //         refreshArtwork()
    //       })
    //     refreshRegenCount()
    //   })
    //   .catch(() => {
    //     alert('오류가 발생하였습니다')
    //     refreshArtwork()
    //   })
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={dialog.open}
          onClose={handleClose}
          sx={{
            overflowX: 'hidden',

            '& .MuiDialog-paper': {
              minWidth: '80rem',
              borderRadius: '10px',
              p: '4.4rem 4rem',
            },
          }}
        >
          <IconButton
            disableFocusRipple
            disableTouchRipple
            disableRipple
            sx={{ position: 'absolute', top: '3.4rem', right: '3.4rem' }}
            onClick={handleClose}
          >
            <CloseCuteIcon sx={{ width: '1.2rem', height: '1.2rem' }} />
          </IconButton>

          {artwork && <ConfigPannel gender={gender} prompt={prompt} age={age} model={model} />}
          <CenterAlignBox>
            <ModelDialogContent
              gender={gender}
              setGender={setGender}
              model={model}
              setModel={setModel}
              prompt={prompt}
              setPrompt={setPrompt}
              age={age}
              setAge={setAge}
            />
          </CenterAlignBox>

          {/* <DialogContent sx={{}}>
            <CenterAlignStack>
              <Typography sx={{ fontSize: '2rem', fontWeight: 700, py: '1rem' }}>
                {t('regenerate_dialog.regenerate')}
              </Typography>
            </CenterAlignStack>

            <ModelFaceUploadOptions
              gender={gender}
              setGender={setGender}
              race={race}
              setRace={setRace}
            />
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', pt: '1rem', pb: '3rem' }}>
            <DialogButton
              handleClose={handleClose}
              actionText={t('regenerate_dialog.button_regenerate')}
              handleAction={handleRegenerate}
            />
          </DialogActions> */}

          <CenterAlignStack>
            <CreateButton onClick={handleRegenerate()} disabled={isDisabled()}>
              {regenerating ? (
                <BarLoader />
              ) : (
                <Box
                  className="content"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'transform 0.3s ease-in-out', // transition 추가
                  }}
                >
                  <RefreshIcon
                    size={'medium'}
                    color="white"
                    style={{ width: '1.6rem', height: '2rem' }}
                  />
                  &nbsp;
                  {t('regenerate_dialog.button_regenerate')}
                </Box>
              )}
            </CreateButton>
          </CenterAlignStack>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={dialog.open}
          onOpen={() => {
            return dialog.open
          }}
          onClose={handleClose}
          sx={{ mb: '3rem' }}
        >
          <CenterAlignStack sx={{ mb: '3rem' }}>
            <CenterAlignBox>
              <ModelDialogContent
                gender={gender}
                setGender={setGender}
                model={model}
                setModel={setModel}
                prompt={prompt}
                setPrompt={setPrompt}
                age={age}
                setAge={setAge}
              />
            </CenterAlignBox>
            <DialogButton
              handleClose={handleClose}
              actionText={regenerating ? <BarLoader /> : t('regenerate_dialog.button_regenerate')}
              handleAction={handleRegenerate()}
              actionprops={{ disabled: isDisabled() }}
            />
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

const ModelDialogContent = ({
  gender,
  setGender,
  model,
  setModel,
  prompt,
  setPrompt,
  age,
  setAge,
}) => {
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()
  const [dialog, setDialog] = useRecoilState(modelRenerateDialogAtom)
  const { artwork, setArtwork, refreshArtwork, refreshRegenCount } = dialog

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isFacePage = portfolioType === PORTFOLIO_TYPE_FACE
  const isModelBgPage = portfolioType === PORTFOLIO_TYPE_MODELBG
  const isMannequinPage = portfolioType === PORTFOLIO_TYPE_MANNEQUIN

  const hasSegment = isMannequinPage || isFacePage
  const hasModel = isModelBgPage || isFacePage

  return (
    <Box
      sx={{
        width: '100%',
        // backgroundColor: 'pink',
        display: 'flex',
        justifyContent: 'center',
        m: { lg: '2.8rem 0 3.6rem 0', xs: '1.2rem 0 2.8rem 0' },
        flexDirection: { lg: 'row', xs: 'column' },
      }}
    >
      {hasSegment && <SegmentComponent />}
      <Mobile>
        {hasSegment && <Divider flexItem orientation="horizontal" sx={{ my: '2.4rem' }} />}
      </Mobile>

      {hasModel && (
        <ModelCommonConfig
          gender={gender}
          setGender={setGender}
          model={model}
          setModel={setModel}
          prompt={prompt}
          setPrompt={setPrompt}
          age={age}
          setAge={setAge}
          multiple={isModelBgPage}
        />
      )}
      {isMannequinPage && (
        <MannequinConfig
          gender={gender}
          setGender={setGender}
          model={model}
          setModel={setModel}
          prompt={prompt}
          setPrompt={setPrompt}
          age={age}
          setAge={setAge}
        />
      )}
    </Box>
  )
}

const ConfigPannel = ({ gender, age, prompt, model }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [dialog, setDialog] = useRecoilState(modelRenerateDialogAtom)

  const { artwork, setArtwork, refreshArtwork, refreshRegenCount, pieceImageSize, currentPieceId } =
    dialog

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isFacePage = portfolioType === PORTFOLIO_TYPE_FACE
  const isModelBgPage = portfolioType === PORTFOLIO_TYPE_MODELBG
  const isMannequinPage = portfolioType === PORTFOLIO_TYPE_MANNEQUIN

  const config = JSON.parse(artwork?.config)

  const defaultConfigList = [
    'theme_template',
    'object_category',
    'flag_white_cmp',
    'size',
    'flag_multiblob_sod',
    'gen_face',
  ]

  const [showConfigList, setShowConfigList] = useState(defaultConfigList)

  useEffect(() => {
    let l = defaultConfigList
    if (isFacePage) {
      l = ['size', 'facemorphing_gender', 'facemorphing_race', 'is_male', 'model_name']
    } else if (isModelBgPage) {
      l = ['size', 'is_male', 'model_name']
    } else if (isMannequinPage) {
      l = ['is_male', 'size', 'age', 'prompt']
    }
    setShowConfigList(l)
  }, [])

  return (
    <Stack
      sx={{
        width: '100%',
        '& .center': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'inherit',
        },

        '& .tab': {
          p: '0.8rem 3rem',
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          borderRadius: '10px 10px 0 0',
          fontSize: '1.3rem',
          fontWeight: 700,
          width: '20rem',
        },

        '& .config': {
          p: '2rem 2.8rem',
          backgroundColor: '#F8F8F8',

          borderRadius: '0 10px 10px 10px ',
          gap: '6rem',
          width: 'inherit',

          '& p': {
            display: 'block',
            fontSize: '1.4rem',
            '& span': {
              fontWeight: 600,
            },
          },
        },
      }}
    >
      <Box className="center tab">{t('upload_config.current_config')}</Box>
      <Box
        className="config"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showConfigList.includes('size') &&
          pieceImageSize[currentPieceId]?.w &&
          pieceImageSize[currentPieceId]?.h && (
            <Typography>
              <span>{t('upload_config.image_size')} :&nbsp;</span>
              {pieceImageSize[currentPieceId].w}px * {pieceImageSize[currentPieceId].h}px
            </Typography>
          )}
        {/* 
        {showConfigList.includes('facemorphing_gender') && config.facemorphing_gender && (
          <Typography>
            <span>{t('mannequin.gender')}: </span>
            {config.facemorphing_gender === 'man' ? t(`button.male`) : t(`button.female`)}
          </Typography>
        )}

        {showConfigList.includes('facemorphing_race') &&
          config.facemorphing_gender &&
          config.facemorphing_race && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{}}>
                <span>
                  {' '}
                  <Korean>모델</Korean>
                  <English>Model</English> :&nbsp;
                </span>
              </Typography>
              <Box sx={{ width: '3.2rem' }}>
                <img
                  src={`/static/images/model_example/${
                    config.facemorphing_gender === 'man' ? 'male' : 'female'
                  }_${config.facemorphing_race}.png`}
                />
              </Box>
            </Box>
          )} */}

        {showConfigList.includes('is_male') && (
          <Typography>
            <span>{t('mannequin.gender')}: </span>
            {gender === 'male' ? t(`button.male`) : t(`button.female`)}
          </Typography>
        )}

        {showConfigList.includes('age') && (
          <Typography>
            <span>{t('mannequin.age')}: </span>
            {t(`button.${age}`)}
          </Typography>
        )}

        {showConfigList.includes('prompt') && (
          <Stack direction="row">
            <Typography sx={{ display: 'block', fontSize: '1.4rem' }}>
              <span>{t('mannequin.background_config')}: </span>
            </Typography>
            <Typography
              sx={{
                maxWidth: '10rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              <span className="item-value" style={{ fontWeight: 400 }}>
                &nbsp;
                {prompt}
              </span>
            </Typography>
          </Stack>
        )}

        {/* 얼굴 변경 - 모델 네임 */}
        {isFacePage && showConfigList.includes('model_name') && config.model_name && (
          <CenterAlignStack direction="row" spacing={model === 'auto' ? '0.4rem' : '1rem'}>
            <span style={{ fontSize: '1.4rem', fontWeight: 600 }}>
              <Korean>모델: </Korean>
              <English>Model: </English>
            </span>

            {model === 'auto' ? (
              <Typography style={{ fontSize: '1.4rem' }}>자동</Typography>
            ) : (
              <Box sx={{ width: '4rem' }}>
                <img
                  style={{ borderRadius: '1rem' }}
                  src={`/static/images/model_example/${gender}_${model}.png`}
                />
              </Box>
            )}
          </CenterAlignStack>
        )}

        {/* 모델 생성 - 모델 네임 */}
        {isModelBgPage && showConfigList.includes('model_name') && config.model_name && (
          <CenterAlignStack direction="row" spacing={model[0] === 'auto' ? '0.4rem' : '1rem'}>
            <span style={{ fontSize: '1.4rem', fontWeight: 600 }}>
              <Korean>모델: </Korean>
              <English>Model: </English>
            </span>

            {model[0] === 'auto' ? (
              <Typography style={{ fontSize: '1.4rem' }}>자동</Typography>
            ) : (
              model.map(n => {
                return (
                  <Box sx={{ width: '4rem' }} key={n}>
                    <img
                      style={{ borderRadius: '1rem' }}
                      src={`/static/images/model_example/${gender}_${n}.png`}
                    />
                  </Box>
                )
              })
            )}
          </CenterAlignStack>
        )}
      </Box>
    </Stack>
  )
}

export const ModelCommonConfig = ({
  gender,
  setGender,
  model,
  setModel,
  prompt,
  setPrompt,
  age,
  setAge,
  multiple = false,
}) => {
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const genderList = [
    { label: t('button.female'), value: 'female' },
    { label: t('button.male'), value: 'male' },
  ]

  return (
    <CenterAlignStack
      sx={{
        '& .config-title': {
          fontSize: '1.4rem',
          fontWeight: 700,
          lineHeight: 'normal',
          textAlign: { lg: 'center', xs: 'center' },
        },

        '& .item': {
          position: 'relative',
          width: { lg: '9.2rem', xs: '7.4rem' },
          height: { lg: '9.2rem', xs: '7.4rem' },
          cursor: 'pointer',

          '& .selected': {
            // border: theme => `3px solid ${theme.palette.draph.blue}`,
            borderRadius: '1rem',
            opacity: 1,
          },
        },
      }}
    >
      <Stack sx={{ alignItems: 'center', gap: '0.2rem' }}>
        <Typography sx={{ fontWeight: 800, lineHeight: 'normal' }}>
          {t('upload_header.face_bg_title')}{' '}
          <sup
            style={{
              color: theme.palette.error.main,
              fontWeight: 500,
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              verticalAlign: 'text-top',
            }}
          >
            &#42;
          </sup>
        </Typography>
        <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
          {t('upload_header.model_notice_1')}
        </Typography>
      </Stack>
      <CenterAlignStack
        className="model"
        sx={{
          width: '100%',
          position: 'relative',
          alignItems: 'center',
          gap: { lg: '3.3rem', xs: '1.6rem' },
          flexDirection: { lg: 'column', xs: 'column' },
          mt: { lg: '2.8rem', xs: '1.8rem' },
        }}
      >
        <Stack
          sx={{
            gap: { lg: '0.8rem', xs: '0.8rem' },
            justifyContent: { lg: 'center', xs: 'center' },
          }}
        >
          <Typography className="config-title">{t('mannequin.gender')}</Typography>
          <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '1.6rem' } }}>
            {genderList.map((g, i) => {
              return (
                <StyledToggleButton
                  key={g.value}
                  value={g.value}
                  selected={gender === g.value}
                  onChange={e => {
                    setGender(e.target.value)
                    setModel(multiple ? ['auto'] : 'auto')
                  }}
                  sx={{
                    minWidth: { lg: '8rem', xs: '15.2rem' },
                    height: '3.2rem',
                    fontSize: '1.4rem',

                    border: `2px solid`,
                    borderColor: `transparent`,
                    fontWeight: 600,
                    color: theme.palette.common.black,

                    '&.Mui-selected': {
                      boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                      color: theme.palette.draph.blue,
                      background: 'none',
                      borderColor: theme.palette.draph.blue,
                      outline: 'none',
                      fontWeight: 800,
                    },
                    ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                  }}
                >
                  {g.label}
                </StyledToggleButton>
              )
            })}
          </Box>
        </Stack>

        <Stack
          sx={{
            gap: { lg: '0.8rem', xs: '0.6rem' },
            justifyContent: { lg: 'center', xs: 'center' },
          }}
        >
          <Typography className="config-title">{t('upload_header.race')}</Typography>
          {multiple ? (
            <Stack
              direction="row"
              gap={{ lg: '1rem', xs: '1rem' }}
              sx={{
                width: isMobile ? '21rem' : '19.4rem',
                flexFlow: 'wrap',
                '& .item': {
                  position: 'relative',
                  width: { lg: '9.2rem', xs: '10rem' },
                  height: { lg: '9.2rem', xs: '10rem' },
                  cursor: 'pointer',

                  '& .selected': {
                    // border: theme => `3px solid ${theme.palette.draph.blue}`,
                    borderRadius: '1rem',
                    opacity: 1,
                  },

                  '& .disabled': {
                    // border: theme => `3px solid ${theme.palette.draph.blue}`,
                    filter: 'grayscale(1)',
                  },
                },
              }}
            >
              <Box
                className={'item'}
                onClick={e => {
                  setModel(p => ['auto'])
                }}
                sx={{
                  borderRadius: '1rem',
                  border: model.includes('auto') ? `1px solid transparent` : `1px solid #D9D9D9`,
                  transition: 'border 0.2s ease-in',
                }}
              >
                <AutoThumb theme={theme} selected={model.includes('auto')} />
                <Box
                  className={`${model.includes('auto') ? 'selected' : ''}`}
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    borderRadius: '10px',
                    border: theme => `3px solid ${theme.palette.draph.blue}`,
                    opacity: 0,
                    transition: 'opacity 0.2s ease-in',
                  }}
                />
              </Box>

              {MODEL[gender].map(r => (
                <Box
                  key={r.id}
                  value={r.id}
                  className={`item`}
                  onClick={e => {
                    setModel(p => {
                      // 이미 항목이 있는지 확인
                      const index = p.indexOf(r.id)
                      if (index > -1) {
                        // 항목이 있으면 제거
                        return p.filter((_, i) => i !== index)
                      } else {
                        // 항목이 없으면 추가
                        return [...p, r.id].filter(v => v !== 'auto')
                      }
                    })
                  }}
                >
                  <img
                    className={`item ${model.includes('auto') ? 'disabled' : ''}`}
                    style={{ borderRadius: '10px' }}
                    src={`/static/images/model_example/${gender}_${r.id}.png`}
                  />

                  <Box
                    className={`${model.includes(r.id) ? 'selected' : ''}`}
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                      borderRadius: '1rem',
                      border: theme => `3px solid ${theme.palette.draph.blue}`,
                      opacity: 0,
                      transition: 'opacity 0.3s ease-in',
                    }}
                  />
                </Box>
              ))}
            </Stack>
          ) : (
            <Stack
              direction="row"
              gap={{ lg: '1rem', xs: '1rem' }}
              sx={{
                width: isMobile ? '21rem' : '19.4rem',
                flexFlow: 'wrap',
                '& .item': {
                  position: 'relative',
                  width: { lg: '9.2rem', xs: '10rem' },
                  height: { lg: '9.2rem', xs: '10rem' },
                  cursor: 'pointer',

                  '& .selected': {
                    // border: theme => `3px solid ${theme.palette.draph.blue}`,
                    borderRadius: '1rem',
                    opacity: 1,
                  },
                },
              }}
            >
              <Box
                className={'item'}
                onClick={e => {
                  setModel('auto')
                }}
                sx={{
                  borderRadius: '1rem',
                  border: model === 'auto' ? `1px solid transparent` : `1px solid #D9D9D9`,
                  transition: 'border 0.2s ease-in',
                }}
              >
                <AutoThumb theme={theme} selected={model.includes('auto')} />
                <Box
                  className={`${model === 'auto' ? 'selected' : ''}`}
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    borderRadius: '10px',
                    border: theme => `3px solid ${theme.palette.draph.blue}`,
                    opacity: 0,
                    transition: 'opacity 0.2s ease-in',
                  }}
                />
              </Box>

              {MODEL[gender].map(r => (
                <Box
                  key={r.id}
                  value={r.id}
                  className={`item`}
                  onClick={e => {
                    setModel(r.id)
                  }}
                >
                  <img
                    className={`item`}
                    style={{ borderRadius: '10px' }}
                    src={`/static/images/model_example/${gender}_${r.id}.png`}
                  />

                  <Box
                    className={`${r.id === model ? 'selected' : ''}`}
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                      borderRadius: '1rem',
                      border: theme => `3px solid ${theme.palette.draph.blue}`,
                      opacity: 0,
                      transition: 'opacity 0.3s ease-in',
                    }}
                  />
                </Box>
              ))}
            </Stack>
          )}
        </Stack>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}

export const MannequinConfig = ({ gender, setGender, prompt, setPrompt, age, setAge }) => {
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()
  const [dialog, setDialog] = useRecoilState(modelRenerateDialogAtom)

  const [isFocused, setIsFocused] = useState(false)

  const onFocus = () => {
    setIsFocused(true)
  }
  const onBlur = () => {
    setIsFocused(false)
  }

  const onChange = e => {
    setPrompt(e.target.value)
  }

  const genderList = [
    { label: t('button.female'), value: 'female' },
    { label: t('button.male'), value: 'male' },
  ]

  const ageList = [
    { label: t('button.baby'), value: 'baby' },
    { label: t('button.child'), value: 'child' },
    { label: t('button.adult'), value: 'adult' },
  ]

  return (
    <CenterAlignStack sx={{}}>
      <Stack sx={{ alignItems: 'center', gap: '0.2rem' }}>
        <Typography sx={{ fontWeight: 800, lineHeight: 'normal' }}>
          {t('upload_header.model_bg_title')}{' '}
          <sup
            style={{
              color: theme.palette.error.main,
              fontWeight: 500,
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              verticalAlign: 'text-top',
            }}
          >
            &#42;
          </sup>
        </Typography>
        <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
          {t('upload_header.model_notice_1')}
        </Typography>
      </Stack>
      <CenterAlignStack
        className="model"
        sx={{
          width: '100%',
          position: 'relative',
          alignItems: 'start',
          gap: { lg: '3.3rem', xs: '2rem' },
          flexDirection: { lg: 'column', xs: 'column' },
          mt: { lg: '2.8rem', xs: '1.8rem' },
        }}
      >
        <Stack
          sx={{
            gap: { lg: '0.8rem', xs: '0.8rem' },
            justifyContent: { lg: 'start', xs: 'start' },
          }}
        >
          <Typography
            sx={{
              fontSize: '1.4rem',
              fontWeight: 600,
              lineHeight: 'normal',
              textAlign: { lg: 'start', xs: 'start' },
            }}
          >
            {t('mannequin.gender')}
          </Typography>
          <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '1.2rem' } }}>
            {genderList.map((g, i) => {
              return (
                <StyledToggleButton
                  key={g.value}
                  value={g.value}
                  selected={gender === g.value}
                  onChange={e => {
                    setGender(e.target.value)
                  }}
                  sx={{
                    minWidth: { lg: '8rem', xs: '10rem' },
                    height: '3.2rem',
                    fontSize: '1.4rem',

                    border: `2px solid`,
                    borderColor: `transparent`,
                    fontWeight: 600,
                    color: theme.palette.common.black,

                    '&.Mui-selected': {
                      boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                      color: theme.palette.draph.blue,
                      background: 'none',
                      borderColor: theme.palette.draph.blue,
                      outline: 'none',
                      fontWeight: 800,
                    },
                    ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                  }}
                >
                  {g.label}
                </StyledToggleButton>
              )
            })}
          </Box>
        </Stack>

        <Stack sx={{ gap: '0.8rem' }}>
          <Typography
            sx={{
              fontSize: '1.4rem',
              fontWeight: 600,
              lineHeight: 'normal',
              textAlign: { lg: 'start', xs: 'start' },
            }}
          >
            {t('mannequin.age')}
          </Typography>
          <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '1.2rem' } }}>
            {ageList.map((a, i) => {
              return (
                <StyledToggleButton
                  key={a.value}
                  value={a.value}
                  selected={age === a.value}
                  onChange={e => {
                    setAge(e.target.value)
                  }}
                  sx={{
                    minWidth: { lg: '8rem', xs: '10rem' },
                    height: '3.2rem',
                    fontSize: '1.4rem',

                    border: `2px solid`,
                    borderColor: `transparent`,
                    fontWeight: 600,
                    color: theme.palette.common.black,

                    '&.Mui-selected': {
                      boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                      color: theme.palette.draph.blue,
                      background: 'none',
                      borderColor: theme.palette.draph.blue,
                      outline: 'none',
                      fontWeight: 800,
                    },
                    ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                  }}
                >
                  {a.label}
                </StyledToggleButton>
              )
            })}
          </Box>
        </Stack>
      </CenterAlignStack>
      <CenterAlignStack
        className="theme"
        sx={{
          width: '100%',
          position: 'relative',
          alignItems: 'start',
          gap: '1.6rem',
          mt: { lg: '3.6rem', xs: '2rem' },
        }}
      >
        <Stack sx={{ gap: '0.8rem', width: '100%' }}>
          <Stack sx={{ gap: '0.2rem' }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '1.4rem',
                lineHeight: 'normal',
                textAlign: { lg: 'start', xs: 'start' },
              }}
            >
              {t('mannequin.background_config')}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.4rem',
                lineHeight: '1.2',
                textAlign: { lg: 'start', xs: 'start' },
              }}
            >
              <SpanDraphBlue style={{ fontWeight: 600 }}>
                {' '}
                {t('mannequin.step_1_prompt_comment_a')}
              </SpanDraphBlue>
              {t('mannequin.step_1_prompt_comment_b')}
              <SpanDraphBlue> {t('mannequin.step_1_prompt_comment_c')}</SpanDraphBlue>
            </Typography>
          </Stack>

          <CustomOutlinedInput
            value={prompt}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            type="text"
            inputProps={{ maxLength: 1000 }}
            multiline
            minRows={isMobile ? 2 : 5}
            startAdornment={
              <div style={{ position: 'absolute', top: '2.2rem', left: '1.2rem' }}>
                {!isFocused && !prompt.length && (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        backgroundColor: theme.palette.common.black,
                        color: theme.palette.common.white,
                        p: '0.3rem 0.6rem',
                        fontSize: '1.2rem',
                        fontWeight: 600,
                        borderRadius: '6px',
                        lineHeight: 'normal',
                      }}
                    >
                      {t('upload_header.custom_template')}
                    </Box>
                  </InputAdornment>
                )}
              </div>
            }
            sx={{
              height: 'auto !important',
              lineHeight: '1.6',
              width: isMobile ? '32rem !important' : '28.8rem',
              '& input': {
                fontSize: '1.3rem !important',
              },

              p: '1rem 1.4rem',
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderWidth: '0.1rem',
                  borderColor: '#000000',
                  borderRadius: '0px',
                },
              },
            }}
          />
        </Stack>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}

const SegmentComponent = () => {
  const theme = useTheme()

  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()

  const { handleRedo, handleReset, handleUndo } = useFragment()

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)

  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [index, SetIndex] = useRecoilState(indexAtom)

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isMannequinPage = portfolioType === PORTFOLIO_TYPE_MANNEQUIN

  // 설정 삭제 및 업로드 사진 삭제
  useEffect(() => {
    resetFiles()
  }, [])

  return (
    <CenterAlignStack
      sx={{
        mr: { lg: isMannequinPage ? '3rem' : '2rem', xs: 0 },
        '& .roundStyle': {
          borderRadius: '3rem',
          border: 0,
          fontSize: '1.2rem',

          boxShadow: ' 2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
          fontWeight: 600,
          '&.Mui-disabled': {
            border: 'none',
            '& svg path': {
              stroke: '#919EAB',
            },
          },
          '&:hover': {
            boxShadow: ' 2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
            background: 'unset',
            color: theme => theme.palette.draph.blue,
            '& svg path': {
              stroke: theme => theme.palette.draph.blue,
              transition: 'stroke 0.3s',
            },
          },
          '& svg path': {
            transition: 'stroke 0.3s',
          },
          '& svg': {
            mr: '0.5rem',
          },
        },
      }}
    >
      <Stack sx={{ alignItems: 'center', gap: '0.2rem' }}>
        <Typography sx={{ fontWeight: 800, lineHeight: 'normal' }}>
          {isMannequinPage ? t('mannequin.step_1_title') : t('regenerate_dialog.face_title')}
          <sup
            style={{
              color: theme.palette.error.main,
              fontWeight: 500,
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              verticalAlign: 'text-top',
            }}
          >
            &#42;
          </sup>
        </Typography>
        <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
          {isMannequinPage ? t('mannequin.step_1_title') : t('regenerate_dialog.face_notice_1')}
        </Typography>
      </Stack>
      <CenterAlignStack
        sx={{
          width: { lg: '40rem', xs: '32rem' },
          height: { lg: '33.4rem', xs: '25.2rem' },
          backgroundColor: '#D9D9D9',
          justifyContent: 'center',
          mt: '1.8rem',
        }}
      >
        <Segment
          widthLimit={isMobile ? 320 : 400}
          heightLimit={isMobile ? 252 : 334}
          fileAtom={regenerateUploadFilesAndUrlAtom}
        />
      </CenterAlignStack>
      <CenterAlignBox sx={{ gap: '1.2rem', mt: '1.6rem' }}>
        <Button
          variant="outlined"
          className="roundStyle"
          sx={{ width: '9.2rem', height: '2.8rem' }}
          onClick={handleReset}
          disabled={segmentLoading}
        >
          <EraserIcon />
          {t('regenerate_dialog.bg_edit_reset')}
        </Button>

        <Button
          variant="outlined"
          className="roundStyle"
          sx={{ width: '9.2rem', height: '2.8rem' }}
          onClick={handleUndo}
          disabled={index === -1 || segmentLoading}
        >
          <svg
            width="1rem"
            height="1rem"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 11L1 6L6 1"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 6H11.5C12.2223 6 12.9375 6.14226 13.6048 6.41866C14.272 6.69506 14.8784 7.10019 15.3891 7.61091C15.8998 8.12163 16.3049 8.72795 16.5813 9.39524C16.8577 10.0625 17 10.7777 17 11.5C17 12.2223 16.8577 12.9375 16.5813 13.6048C16.3049 14.272 15.8998 14.8784 15.3891 15.3891C14.8784 15.8998 14.272 16.3049 13.6048 16.5813C12.9375 16.8577 12.2223 17 11.5 17H8"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {t('regenerate_dialog.bg_edit_undo')}
        </Button>
        <Button
          variant="outlined"
          className="roundStyle"
          sx={{ width: '9.2rem', height: '2.8rem' }}
          onClick={handleRedo}
          disabled={removeMaskImg.length === 0 || segmentLoading}
        >
          <svg
            width="1rem"
            height="1rem"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 11L17 6L12 1"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 6H6.5C5.04131 6 3.64236 6.57946 2.61091 7.61091C1.57946 8.64236 1 10.0413 1 11.5C1 12.2223 1.14226 12.9375 1.41866 13.6048C1.69506 14.272 2.10019 14.8784 2.61091 15.3891C3.64236 16.4205 5.04131 17 6.5 17H10"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {t('regenerate_dialog.bg_edit_redo')}
        </Button>
      </CenterAlignBox>
    </CenterAlignStack>
  )
}
