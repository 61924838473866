import { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric'
import { AlignGuidelines } from 'fabric-guideline-plugin'
import FontFaceObserver from 'fontfaceobserver'
import {
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import useGridHandler from 'hooks/editor/useGridHandler'
import { useGuidelines } from 'hooks/editor/useGuidelineHandler'
import { CenterAlignStack } from 'components'
import { scalingLogic, snapLogic } from 'utils/editorHelper'
import { Stage, Layer, Image, Rect, Text } from 'react-konva'
import Konva from 'konva'

export default function TestPage17() {
  return (
    <>
      {/* <Test1 /> */}
      {/* <Test3 /> */}
      {/* <Test2 /> */}
      {/* <Test4 /> */}
      <Test7 />
    </>
  )
}

const Test1 = () => {
  const canvasRef = useRef(null)
  const [canvas, setCanvas] = useState(null)
  const [fontFamily, setFontFamily] = useState('Arial')
  const [fontSize, setFontSize] = useState(22)
  const [fontColor, setFontColor] = useState('#000000')

  const addText = () => {
    const text = createText(canvas, 'Add', 100, 200)

    canvas.add(text)
  }

  useEffect(() => {
    const canvasInstance = new fabric.Canvas(canvasRef.current, {})

    const imageUrl = 'https://cdn.newspenguin.com/news/photo/202003/1400_3528_3311.jpg'

    setCanvas(canvasInstance)

    const guideline = new AlignGuidelines({
      canvas: canvasInstance,
    })

    guideline.init()
    fabric.Image.fromURL(
      imageUrl,
      function (img) {
        // 이미지 원본 크기
        const imgWidth = img.width
        const imgHeight = img.height

        // 캔버스 최대 크기
        const maxCanvasWidth = 500
        const maxCanvasHeight = 400

        // 이미지 비율 계산
        const widthRatio = maxCanvasWidth / imgWidth
        const heightRatio = maxCanvasHeight / imgHeight

        // 가장 작은 비율을 기준으로 이미지 크기 조정
        const scale = Math.min(widthRatio, heightRatio)

        canvasInstance.setZoom(scale)

        // 이미지 크기 조정
        // img.scale(scale)

        // 캔버스 크기 조정
        // canvasInstance.setWidth(img.width * scale)
        // canvasInstance.setHeight(img.height * scale)

        // 캔버스에 이미지 추가
        canvasInstance.setBackgroundImage(img, canvasInstance.renderAll.bind(canvasInstance))

        // 이미지와 가이드라인 설정
      },
      { crossOrigin: 'anonymous' }
    )
    // 두 개의 텍스트 박스 생성
    const text1 = createText(canvasInstance, 'Click to Edit', 100, 100)
    const text2 = createText(canvasInstance, 'Another Text', 100, 200)

    canvasInstance.add(text1, text2)
    canvasInstance.setActiveObject(text1)
    // 첫 번째 텍스트 박스를 편집 모드로 설정
    text1.enterEditing()

    const handleKeyDown = e => {
      if (e.key === 'Delete') {
        const activeObject = canvasInstance.getActiveObject()
        console.log(activeObject, activeObject.type)
        if (activeObject && !activeObject.isEditing) {
          // 단일 객체 삭제
          if (!activeObject.type || activeObject.type !== 'activeSelection') {
            canvasInstance.remove(activeObject)
          } else {
            // 그룹화된 객체 삭제
            activeObject.forEachObject(obj => {
              canvasInstance.remove(obj)
            })
            // 그룹 해제
            canvasInstance.discardActiveObject()
          }
          // canvasInstance.requestRenderAll()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    // 컴포넌트 언마운트 시 이벤트 리스너 제거

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    const activeObject = canvas?.getActiveObject()
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set({
        fontFamily,
        fontSize,
        fill: fontColor,
      })
      canvas.requestRenderAll()
    }
  }, [fontFamily, fontSize, fontColor, canvas])

  const alignLeft = () => {
    const activeObject = canvas.getActiveObject()
    const activeObjectLeft = -(activeObject.width / 2)

    activeObject.forEachObject(obj => {
      obj.set({
        left: activeObjectLeft,
      })
      obj.setCoords()
      canvas.requestRenderAll()
    })
  }

  const alignCenter = () => {
    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.type === 'activeSelection') {
      activeObject.forEachObject(obj => {
        obj.set({
          left: 0 - (obj.width * obj.scaleX) / 2,
        })
        obj.setCoords()
      })
    }
    canvas.requestRenderAll()
  }
  const alignRight = () => {
    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.type === 'activeSelection') {
      const activeObjectLeft = activeObject.width / 2
      activeObject.forEachObject(obj => {
        obj.set({
          left: activeObjectLeft - obj.width * obj.scaleX,
        })
        obj.setCoords()
      })
    }
    canvas.requestRenderAll()
  }

  const handleDownload = () => {
    const originalOpacity = canvas.backgroundImage.opacity
    canvas.backgroundImage.set('opacity', 1)
    canvas.renderAll()

    // 캔버스 상태를 이미지로 변환하여 다운로드

    const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    const link = document.createElement('a')
    link.download = 'my-canvas-image.png'
    link.href = image
    link.click()

    // 이미지 다운로드 후 원래 투명도로 되돌림
    canvas.backgroundImage.set('opacity', originalOpacity)
    canvas.renderAll()
  }

  return (
    <div>
      <button onClick={handleDownload}>다운로드</button>
      <button onClick={addText}>Add Text</button>
      <button onClick={alignLeft}>좌</button>
      <button onClick={alignCenter}>중</button>
      <button onClick={alignRight}>우</button>
      <br />
      <label>
        Font Family:
        <input value={fontFamily} onChange={e => setFontFamily(e.target.value)} />
      </label>
      <br />
      <label>
        Font Size:
        <input
          type="number"
          value={fontSize}
          onChange={e => setFontSize(parseInt(e.target.value, 10))}
        />
      </label>
      <br />
      <label>
        Font Color:
        <input type="color" value={fontColor} onChange={e => setFontColor(e.target.value)} />
      </label>
      <br />
      <Box sx={{ width: '10rem' }}>
        <canvas ref={canvasRef} />
      </Box>
    </div>
  )
}

const createText = (canvasInstance, text, left, top) => {
  const textbox = new fabric.Textbox(text, {
    left: left,
    top: top,
    // width: 300,
    fontFamily: 'Arial',
    fontSize: 22,
    fill: '#000000',

    // backgroundColor: 'rgba(123,45,26,0.7)',
  })

  textbox.setControlsVisibility({
    mt: false, // 상단 중앙 조절점 비활성화
    mb: false, // 하단 중앙 조절점 비활성화
  })

  textbox.on('deselected', function () {
    if (this.text === '') {
      canvasInstance.remove(this)
    }
  })

  return textbox
}

const Test7 = () => {
  const [image, setImage] = useState(null)
  const imageRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 400, height: 300 })

  // Filter states
  const [brightness, setBrightness] = useState(0)
  const [contrast, setContrast] = useState(0)
  const [saturation, setSaturation] = useState(0)
  const [hue, setHue] = useState(0)
  const [noise, setNoise] = useState(0)
  const [pixelSize, setPixelSize] = useState(1)
  const [blurRadius, setBlurRadius] = useState(0)
  const [enhance, setEnhance] = useState(0)

  // 색상 균형을 위한 새로운 states
  const [red, setRed] = useState(0)
  const [green, setGreen] = useState(0)
  const [blue, setBlue] = useState(0)

  useEffect(() => {
    const img = new window.Image()
    img.crossOrigin = 'anonymous'
    img.src = 'https://cdn.newspenguin.com/news/photo/202003/1400_3528_3311.jpg'
    img.onload = () => {
      const aspectRatio = img.width / img.height
      const newWidth = Math.min(400, img.width)
      const newHeight = newWidth / aspectRatio
      setDimensions({ width: newWidth, height: newHeight })
      setImage(img)
    }
  }, [])

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.cache()
      imageRef.current.getLayer().batchDraw()
    }
  }, [
    image,
    brightness,
    contrast,
    saturation,
    hue,
    noise,
    pixelSize,
    blurRadius,
    enhance,
    red,
    green,
    blue,
  ])

  const handleFilterChange = filterSetter => (event, newValue) => {
    filterSetter(newValue)
  }

  const ColorBalance = function (imageData) {
    const data = imageData.data
    for (let i = 0; i < data.length; i += 4) {
      data[i] = data[i] + red // Red channel
      data[i + 1] = data[i + 1] + green // Green channel
      data[i + 2] = data[i + 2] + blue // Blue channel
    }
  }

  const activeFilters = [
    brightness !== 0 && Konva.Filters.Brighten,
    contrast !== 0 && Konva.Filters.Contrast,
    (saturation !== 0 || hue !== 0) && Konva.Filters.HSL,
    noise !== 0 && Konva.Filters.Noise,
    pixelSize !== 1 && Konva.Filters.Pixelate,
    blurRadius !== 0 && Konva.Filters.Blur,
    enhance !== 0 && Konva.Filters.Enhance,
    (red !== 0 || green !== 0 || blue !== 0) && ColorBalance,
  ].filter(Boolean)

  return (
    <Box sx={{ width: '100%', display: 'flex', gap: '2.5rem' }}>
      <Stage width={dimensions.width} height={dimensions.height}>
        <Layer>
          <Image
            ref={imageRef}
            image={image}
            width={dimensions.width}
            height={dimensions.height}
            filters={activeFilters}
            brightness={brightness}
            contrast={contrast}
            saturation={saturation}
            hue={hue}
            noise={noise}
            pixelSize={pixelSize}
            blurRadius={blurRadius}
            enhance={enhance}
          />

          <Text
            text="dfdfd"
            filters={activeFilters}
            brightness={brightness}
            contrast={contrast}
            saturation={saturation}
            hue={hue}
            noise={noise}
            pixelSize={pixelSize}
            blurRadius={blurRadius}
            enhance={enhance}
          />
        </Layer>
      </Stage>

      <Stack sx={{ width: '30rem' }}>
        <Typography gutterBottom>밝기</Typography>
        <Slider
          value={brightness}
          onChange={handleFilterChange(setBrightness)}
          min={-1}
          max={1}
          step={0.1}
        />

        <Typography gutterBottom>대비</Typography>
        <Slider
          value={contrast}
          onChange={handleFilterChange(setContrast)}
          min={-100}
          max={100}
          step={1}
        />

        <Typography gutterBottom>채도</Typography>
        <Slider
          value={saturation}
          onChange={handleFilterChange(setSaturation)}
          min={-2}
          max={2}
          step={0.1}
        />

        <Typography gutterBottom>색조</Typography>
        <Slider value={hue} onChange={handleFilterChange(setHue)} min={0} max={360} step={1} />

        <Typography gutterBottom>노이즈</Typography>
        <Slider value={noise} onChange={handleFilterChange(setNoise)} min={0} max={1} step={0.01} />

        <Typography gutterBottom>픽셀화</Typography>
        <Slider
          value={pixelSize}
          onChange={handleFilterChange(setPixelSize)}
          min={1}
          max={20}
          step={1}
        />

        <Typography gutterBottom>블러</Typography>
        <Slider
          value={blurRadius}
          onChange={handleFilterChange(setBlurRadius)}
          min={0}
          max={40}
          step={1}
        />

        <Typography gutterBottom>선명도 향상</Typography>
        <Slider
          value={enhance}
          onChange={handleFilterChange(setEnhance)}
          min={0}
          max={1}
          step={0.1}
        />

        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          색상 균형
        </Typography>

        <Typography gutterBottom>빨강</Typography>
        <Slider
          value={red}
          onChange={handleFilterChange(setRed)}
          min={-50}
          max={50}
          step={1}
          sx={{
            '& .MuiSlider-track': { backgroundColor: 'red' },
            '& .MuiSlider-thumb': { backgroundColor: 'red' },
          }}
        />

        <Typography gutterBottom>초록</Typography>
        <Slider
          value={green}
          onChange={handleFilterChange(setGreen)}
          min={-50}
          max={50}
          step={1}
          sx={{
            '& .MuiSlider-track': { backgroundColor: 'green' },
            '& .MuiSlider-thumb': { backgroundColor: 'green' },
          }}
        />

        <Typography gutterBottom>파랑</Typography>
        <Slider
          value={blue}
          onChange={handleFilterChange(setBlue)}
          min={-50}
          max={50}
          step={1}
          sx={{
            '& .MuiSlider-track': { backgroundColor: 'blue' },
            '& .MuiSlider-thumb': { backgroundColor: 'blue' },
          }}
        />
      </Stack>
    </Box>
  )
}
const WordArtStyles = {
  basic: {
    fontFamily: 'Arial, sans-serif',
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#FF00FF',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    transform: 'perspective(500px) rotateX(20deg)',
    display: 'inline-block',
  },
  neon: {
    fontFamily: 'Tahoma, sans-serif',
    fontSize: '54px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    textShadow:
      '0 0 5px #FFA500, 0 0 15px #FFA500, 0 0 20px #FFA500, 0 0 40px #FFA500, 0 0 60px #FF0000, 0 0 10px #FF8D00, 0 0 98px #FF0000',
    animation: 'blink 12s infinite',
  },
  rainbow: {
    fontFamily: 'Comic Sans MS, cursive',
    fontSize: '42px',
    fontWeight: 'bold',
    background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: 'rainbow 5s linear infinite',
  },
  metallic: {
    fontFamily: 'Impact, sans-serif',
    fontSize: '60px',
    fontWeight: 'bold',
    color: '#FFF',
    textShadow:
      '0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
    background: 'linear-gradient(to bottom, #D5DEE7 0%, #E8EBF2 50%, #E2E7ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  retro: {
    fontFamily: '"Press Start 2P", cursive',
    fontSize: '36px',
    color: '#00FF00',
    textShadow: '2px 2px 0 #4074b5, 4px 4px 0 #9E9E9E',
    letterSpacing: '4px',
    animation: 'glitch 3s infinite',
  },
}

const WordArt = ({ text, style = 'basic' }) => {
  return <div style={WordArtStyles[style]}>{text}</div>
}

const Test3 = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px' }}>
      <WordArt text="기본 스타일" style="basic" />
      <WordArt text="네온 사인" style="neon" />
      <WordArt text="무지개 색상" style="rainbow" />
      <WordArt text="메탈릭 효과" style="metallic" />
      <WordArt text="레트로 게임" style="retro" />
    </div>
  )
}

const Test4 = () => {
  const iframeRef = useRef(null)

  useEffect(() => {
    const handleMessage = event => {
      if (event.origin === 'https://www.photopea.com') {
        console.log('Message from Photopea:', event.data)
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [])

  const sendImageToAPI = async () => {
    if (iframeRef.current) {
      // Get the current document as PNG
      iframeRef.current.contentWindow.postMessage("app.activeDocument.saveToOE('png')", '*')

      // Listen for the response
      window.addEventListener('message', async function handlePNGMessage(e) {
        if (
          e.origin === 'https://www.photopea.com' &&
          e.data.startsWith('data:image/png;base64,')
        ) {
          window.removeEventListener('message', handlePNGMessage)

          // Here's where you send the image data to your API
          try {
            const response = await fetch('YOUR_API_ENDPOINT', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ image: e.data }),
            })
            const result = await response.json()
            console.log('Success:', result)
          } catch (error) {
            console.error('Error:', error)
          }
        }
      })
    }
  }

  return (
    <div>
      <iframe
        ref={iframeRef}
        src="https://www.photopea.com"
        width="800"
        height="600"
        allow="fullscreen"
      />
      <button onClick={sendImageToAPI}>Send Image to API</button>
    </div>
  )
}
