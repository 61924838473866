import crudAxios from './configs/crud'

export const portfolioAPI = {
  getAllportfolio: () => {
    return crudAxios.get('/portfolio')
  },
  createPortfoilo: body => {
    return crudAxios.post('/portfolio', body)
  },
  getAllArtwork: (page = null, pageSize = null) => {
    let url = '/portfolio/artwork'
    if (page && pageSize) {
      url += `?page=${page}&page_size=${pageSize}`
    }
    return crudAxios.get(url)
  },
  getArtworkRegenCount: () => {
    return crudAxios.get('/portfolio/artwork/regenerate/count')
  },
  getPortfolio: portfolioId => {
    return crudAxios.get(`/portfolio/${portfolioId}`)
  },
  updatePortfolio: (portfolioId, body) => {
    return crudAxios.put(`/portfolio/${portfolioId}`, body)
  },
  deletePortfolio: portfolioId => {
    return crudAxios.delete(`/portfolio/${portfolioId}`)
  },
  deleteMultiPortfolio: portfolioIds => {
    return crudAxios.delete(`/portfolio`, { data: portfolioIds })
  },

  getAllPortfolioArtwork: portfolioId => {
    const isArr = Array.isArray(portfolioId)
    if (isArr) {
      return crudAxios.get(`/portfolio/artwork`, { params: { portfolio_ids: portfolioId } })
    } else {
      return crudAxios.get(`/portfolio/${portfolioId}/artwork`)
    }
  },
  createArtwork: (portfolioId, body) => {
    return crudAxios.post(`/portfolio/${portfolioId}/artwork`, body)
  },
  getArtwork: (portfolioId, artworkId) => {
    return crudAxios.get(`/portfolio/${portfolioId}/artwork/${artworkId}`)
  },
  updateArtwork: (portfolioId, artworkId, body) => {
    return crudAxios.put(`/portfolio/${portfolioId}/artwork/${artworkId}`, body)
  },
  // updatePiece: (portfolioId, artworkId, body) => {
  //   return crudAxios.put(`/portfolio/${portfolioId}/artwork/${artworkId}/piece`, body)
  // },
  deleteArtwork: (portfolioId, artworkId) => {
    return crudAxios.delete(`/portfolio/${portfolioId}/artwork/${artworkId}`)
  },
  deleteArtworks: (portfolioId, { artworkIds }) => {
    return crudAxios.post(`/portfolio/${portfolioId}/artwork/delete`, {
      artwork_ids: artworkIds,
    })
  },

  getPiece: (portfolioId, artworkId, pieceID) => {
    return crudAxios.get(`/portfolio/${portfolioId}/artwork/${artworkId}/piece/${pieceID}`)
  },
  createPiece: (portfolioId, artworkId, body) => {
    return crudAxios.post(`/portfolio/${portfolioId}/artwork/${artworkId}/piece`, body)
  },
  deletePiece: (portfolioId, artworkId, body) => {
    return crudAxios.delete(`/portfolio/${portfolioId}/artwork/${artworkId}/piece`, body)
  },

  updatePiece: (portfolioId, artworkId, body) => {
    return crudAxios.put(`/portfolio/${portfolioId}/artwork/${artworkId}/piece`, body)
  },

  updatePieceInfo: (portfolioId, artworkId, pieceId, body) => {
    return crudAxios.put(`/portfolio/${portfolioId}/artwork/${artworkId}/piece/${pieceId}`, body)
  },

  updateArtworkStatus: (portfolioId, artworkId, { status }) => {
    return crudAxios.put(`/portfolio/${portfolioId}/artwork/${artworkId}/status`, { status })
  },
  updateArtworkDownload: (portfolioId, artworkId, { paths }) => {
    return crudAxios.put(`/portfolio/${portfolioId}/artwork/${artworkId}/download`, { paths })
  },
  updateMultiArtworkDownload: (portfolioId, body) => {
    return crudAxios.put(`/portfolio/${portfolioId}/artwork/download`, body)
  },
  updateArtworkFeedback: (
    portfolioId,
    artworkId,
    { feedback, content, addPieceCount, modPieceId, themeTemplate, themeCustom, useCreditType = '' }
  ) => {
    return crudAxios.put(`/portfolio/${portfolioId}/artwork/${artworkId}/feedback`, {
      feedback,
      content,
      add_piece_count: addPieceCount,
      mod_piece_id: modPieceId,
      theme_template: themeTemplate,
      theme_custom: themeCustom,
      use_credit_type: useCreditType,
    })
  },

  updateBannerArtworkFeedback: (
    portfolioId,
    artworkId,
    { feedback, content, addPieceCount, modPieceId, themeTemplate, themeCustom }
  ) => {
    return crudAxios.put(`/portfolio/${portfolioId}/artwork/${artworkId}/banner_feedback`, {
      feedback,
      content,
      add_piece_count: addPieceCount,
      mod_piece_id: modPieceId,
      theme_template: themeTemplate,
      theme_custom: themeCustom,
    })
  },

  getDefaultPortfolio: (type = null) => {
    // 유저의 미분류 포트폴리오 또는, 특정 메뉴 전용 포트폴리오 조회
    let path = '/portfolio/default'
    if (type) {
      path += `?type=${type}`
    }
    return crudAxios.get(path)
  },
  getDefaultPortfolioList: (types = []) => {
    const path = '/portfolio/default/list'
    return crudAxios.get(path, { params: { types } })
  },

  updatePieceImage: (portfolioId, artworkId, pieceId, body) => {
    return crudAxios.post(`/portfolio/${portfolioId}/artwork/${artworkId}/piece/${pieceId}`, body)
  },

  setUploadProgress: (portfolioId, body) => {
    return crudAxios.post(`/portfolio/${portfolioId}/upload_progress`, body)
  },
  deleteUploadProgress: (portfolioId, uid) => {
    return crudAxios.delete(`/portfolio/${portfolioId}/upload_progress/${uid}`)
  },
}
