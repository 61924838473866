import { Box, Button, useTheme, withTheme } from '@mui/material'
import { autoScalingModeAtom, uploadFilesAndUrlAtom } from 'atoms'
import { Korean } from 'hooks/useLanguage'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Layer, Rect, Stage, Text, Transformer, Image } from 'react-konva'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ZoomInOutButton } from './ZoomInOutButton'

const CANVAS_WIDTH = 480
const CANVAS_HEIGHT = 480

export function BgExpansionCanvas({
  width,
  height,
  setPortfolioConfig,
  image,
  setImage,
  whiteRect,
  setWhiteRect,
  error,
  setError,
}) {
  const files = useRecoilValue(uploadFilesAndUrlAtom)
  const minSizeW = files[0].size[0]
  const minSizeH = files[0].size[1]

  const stageRef = useRef(null)
  const whiteRectRef = useRef(null)
  const imageRef = useRef(null)
  const trRef = useRef(null)

  const [selectedIds, setSelectedIds] = useState(null)
  const [stageScale, setStageScale] = useState(1)
  const [autoScalingMode, setAutoScalingMode] = useRecoilState(autoScalingModeAtom)
  const [isDragging, setIsDragging] = useState(false)
  const [RTSize, setRTSize] = useState([minSizeW, minSizeH])

  const [cursor, setCursor] = useState('default')

  const theme = useTheme()

  const whiteW = Number(width) ? Math.max(Number(width), minSizeW) : minSizeW
  const whiteH = Number(height) ? Math.max(Number(height), minSizeH) : minSizeH

  useEffect(() => {
    return () => {
      setAutoScalingMode(true)
    }
  }, [])

  const scaleCalculate = (w, h) => {
    const maxLength = Math.max(w, h)

    if (maxLength <= 480) {
      setStageScale(0.9)
      return 0.9
    }

    if (maxLength > 480 && maxLength <= 800) {
      setStageScale(0.5)
      return 0.5
    }

    if (maxLength > 800 && maxLength <= 1000) {
      setStageScale(0.35)
      return 0.35
    }

    if (maxLength > 1000 && maxLength <= 1500) {
      setStageScale(0.26)
      return 0.26
    }

    if (maxLength > 1500 && maxLength <= 2000) {
      setStageScale(0.19)
      return 0.19
    }
  }

  useEffect(() => {
    const newWhiteRect = {
      x: (CANVAS_WIDTH - whiteW) / 2,
      y: (CANVAS_HEIGHT - whiteH) / 2,
      width: whiteW,
      height: whiteH,
      id: 'whiteRect',
    }

    setWhiteRect(newWhiteRect)
    setTimeout(() => setSelectedIds('whiteRect'), 0)

    const stage = stageRef.current

    const newScale = autoScalingMode ? scaleCalculate(whiteH, whiteW) : stage.scaleX()

    stage.scale({ x: newScale, y: newScale })

    setStageScale(newScale)

    stage.position({
      x: (CANVAS_WIDTH - newScale * CANVAS_WIDTH) / 2,
      y: (CANVAS_HEIGHT - newScale * CANVAS_HEIGHT) / 2,
    })

    stage.batchDraw()
  }, [whiteW, whiteH])

  useEffect(() => {
    if (files[0] && files[0].url && autoScalingMode) {
      const img = new window.Image()
      img.src = files[0].url

      img.onload = () => {
        setImage({
          image: img,
          x: (CANVAS_WIDTH - minSizeW) / 2,
          y: (CANVAS_HEIGHT - minSizeH) / 2,
          width: minSizeW,
          height: minSizeH,
          id: 'uploadedImage',
        })
      }
    }
  }, [files, whiteW, whiteH])

  // useEffect(() => {
  //   if (!image || !whiteRect) return
  //   const bbox = [
  //     Math.round(image.x - whiteRect.x),
  //     Math.round(image.y - whiteRect.y),
  //     Math.round(image.x - whiteRect.x) + Math.round(image.width),
  //     Math.round(image.y - whiteRect.y) + Math.round(image.height),
  //   ]
  //   console.log(bbox)
  // })

  // 스크롤 확대 축소
  const handleWheel = e => {
    if (e.evt.ctrlKey) {
      e.evt.preventDefault()
      const scaleBy = 1.1
      const stage = stageRef.current
      const oldScale = stage.scaleX()

      let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy
      newScale = Math.max(0.1, Math.min(newScale, 1))

      stage.scale({ x: newScale, y: newScale })

      setStageScale(newScale)

      stage.position({
        x: (CANVAS_WIDTH - newScale * CANVAS_WIDTH) / 2,
        y: (CANVAS_HEIGHT - newScale * CANVAS_HEIGHT) / 2,
      })

      stage.batchDraw()
    }
  }

  const clickZoomOut = () => {
    const scaleBy = 1.3
    const stage = stageRef.current
    const oldScale = stage.scaleX()

    let newScale = oldScale / scaleBy
    newScale = Math.max(0.1, Math.min(newScale, 1))

    stage.scale({ x: newScale, y: newScale })

    setStageScale(newScale)

    stage.position({
      x: (CANVAS_WIDTH - newScale * CANVAS_WIDTH) / 2,
      y: (CANVAS_HEIGHT - newScale * CANVAS_HEIGHT) / 2,
    })

    stage.batchDraw()
  }

  const clickZoomIn = () => {
    const scaleBy = 1.3
    const stage = stageRef.current
    const oldScale = stage.scaleX()

    let newScale = oldScale * scaleBy
    newScale = Math.max(0.1, Math.min(newScale, 1))

    stage.scale({ x: newScale, y: newScale })

    setStageScale(newScale)

    stage.position({
      x: (CANVAS_WIDTH - newScale * CANVAS_WIDTH) / 2,
      y: (CANVAS_HEIGHT - newScale * CANVAS_HEIGHT) / 2,
    })

    stage.batchDraw()
  }

  const checkDeselect = e => {
    const clickedOnEmpty = e.target === e.target.getStage()
    if (clickedOnEmpty) {
      setSelectedIds(null)
    }
  }

  const handleTransformStart = () => {
    setAutoScalingMode(false)
    setIsDragging(true)
  }

  const handleTransformEnd = e => {
    const node = whiteRectRef.current
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()
    setIsDragging(false)
    node.scaleX(1)
    node.scaleY(1)
    node.width(Math.max(5, node.width() * scaleX))
    node.height(Math.max(5, node.height() * scaleY))

    setWhiteRect({
      ...whiteRect,
      width: node.width(),
      height: node.height(),
    })

    setPortfolioConfig(p => ({
      ...p,
      outputSizeList: [
        {
          w: Math.min(Math.max(Math.round(node.width()), minSizeW), 2000),
          h: Math.min(Math.max(Math.round(node.height()), minSizeH), 2000),
        },
      ],
    }))
  }

  const handleTransform = e => {
    const node = whiteRectRef.current
    const imageNode = imageRef.current

    const scaleX = node.scaleX()
    const scaleY = node.scaleY()

    node.scaleX(1)
    node.scaleY(1)
    node.width(Math.max(5, node.width() * scaleX))
    node.height(Math.max(5, node.height() * scaleY))

    const nodeTopLeftX = node.x()
    const nodeTopLeftY = node.y()
    const nodeBottomRigthX = node.x() + node.width()
    const nodeBottomRigthY = node.y() + node.height()

    const imageNodeTopLeftX = imageNode.x()
    const imageNodeTopLeftY = imageNode.y()
    const imageNodeBottomRigthX = imageNode.x() + imageNode.width()
    const imageNodeBottomRigthY = imageNode.y() + imageNode.height()

    imageNode.setAttrs({
      x: Math.floor(
        nodeBottomRigthX < imageNodeBottomRigthX
          ? nodeBottomRigthX - imageNode.width()
          : Math.max(nodeTopLeftX, imageNodeTopLeftX)
      ),
      y: Math.floor(
        nodeBottomRigthY < imageNodeBottomRigthY
          ? nodeBottomRigthY - imageNode.height()
          : Math.max(nodeTopLeftY, imageNodeTopLeftY)
      ),
    })

    setImage({
      ...image,
      x: Math.floor(
        nodeBottomRigthX < imageNodeBottomRigthX
          ? nodeBottomRigthX - imageNode.width()
          : Math.max(nodeTopLeftX, imageNodeTopLeftX)
      ),

      y: Math.floor(
        nodeBottomRigthY < imageNodeBottomRigthY
          ? nodeBottomRigthY - imageNode.height()
          : Math.max(nodeTopLeftY, imageNodeTopLeftY)
      ),
    })

    if (
      Math.max(Math.round(node.width()), minSizeW) < 2000 &&
      Math.max(Math.round(node.height()), minSizeH) < 2000
    ) {
      setError(false)
    }

    setRTSize([
      Math.min(Math.max(Math.round(node.width()), minSizeW), 2000),
      Math.min(Math.max(Math.round(node.height()), minSizeH), 2000),
    ])
  }

  const boundBoxFunc = useCallback(
    (oldBox, newBox) => {
      // const stage = stageRef.current
      // const scale = stage.scaleX()

      // if (newBox.width <= minSizeW * stageScale) {
      //   return { ...oldBox }
      // }
      // if (newBox.height <= minSizeH * stageScale) {
      //   return { ...oldBox }
      // }

      // if (newBox.width >= 2000 * stageScale) {
      //   return oldBox
      // }
      // if (newBox.height >= 2000 * stageScale) {
      //   return oldBox
      // }

      // return newBox

      const stage = stageRef.current
      const scale = stage.scaleX()

      let finalWidth = newBox.width
      let finalHeight = newBox.height
      let finalX = newBox.x
      let finalY = newBox.y

      const imageNode = imageRef.current
      const imagePos = imageNode.getClientRect()

      // 너비 제한
      if (newBox.width < Math.ceil(minSizeW * stageScale)) {
        finalWidth = minSizeW * stageScale
        finalX = (CANVAS_WIDTH - finalWidth) / 2 // x 좌표 유지
      } else if (newBox.width > Math.ceil(2000 * stageScale)) {
        setError(true)
        finalWidth = Math.ceil(2000 * stageScale)
        finalX = (CANVAS_WIDTH - finalWidth) / 2 // x 좌표 유지
      }

      // 높이 제한
      if (newBox.height < Math.ceil(minSizeH * stageScale)) {
        finalHeight = minSizeH * stageScale
        finalY = (CANVAS_HEIGHT - finalHeight) / 2 // y 좌표 유지
      } else if (newBox.height > Math.ceil(2000 * stageScale)) {
        setError(true)
        finalHeight = Math.ceil(2000 * stageScale)
        finalY = (CANVAS_HEIGHT - finalHeight) / 2 // y 좌표 유지
      }

      return { ...newBox, x: finalX, y: finalY, width: finalWidth, height: finalHeight }
    },
    [minSizeW, minSizeH, stageScale]
  )

  useEffect(() => {
    if (selectedIds === 'whiteRect' && trRef.current) {
      trRef.current.nodes([whiteRectRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [selectedIds, whiteRect])

  const constrainPosition = (newPos, shapeWidth, shapeHeight) => {
    if (!whiteRect) return newPos

    return {
      x: Math.max(whiteRect.x, Math.min(newPos.x, whiteRect.x + whiteRect.width - shapeWidth)),
      y: Math.max(whiteRect.y, Math.min(newPos.y, whiteRect.y + whiteRect.height - shapeHeight)),
    }
  }

  const handleDragMove = e => {
    const newPos = constrainPosition(
      { x: e.target.x(), y: e.target.y() },
      image.width,
      image.height
    )
    e.target.position(newPos)
    setImage({
      ...image,
      x: newPos.x,
      y: newPos.y,
    })
  }

  const handleDragEnd = e => {
    const newPos = constrainPosition(
      { x: e.target.x(), y: e.target.y() },
      image.width,
      image.height
    )
    setImage({
      ...image,
      ...newPos,
    })
  }

  const handleMouseEnter = useCallback(() => {
    setCursor('move')
    document.body.style.cursor = 'move'
  }, [])

  const handleMouseLeave = useCallback(() => {
    setCursor('default')
    document.body.style.cursor = 'default'
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      {isDragging && (
        <Box
          sx={{
            position: 'absolute',
            top: error ? '-8.2rem' : '-6.4rem',
            gap: '11.6rem',
            left: '14.8rem',
            zIndex: 9999,
            display: 'flex',
            '& .size-box': {
              backgroundColor: theme.palette.common.white,
              textAlign: 'right',
              width: '5rem',
            },
          }}
        >
          <Box className="size-box">{RTSize[0]}</Box>
          <Box className="size-box">{RTSize[1]}</Box>
        </Box>
      )}
      <ZoomInOutButton clickZoomIn={clickZoomIn} clickZoomOut={clickZoomOut} />
      <Stage
        width={CANVAS_WIDTH}
        height={CANVAS_HEIGHT}
        ref={stageRef}
        onWheel={handleWheel}
        // onClick={checkDeselect}
        style={{
          // border: `3px solid ${theme.palette.primary.main}`,
          backgroundColor: '#808080',
        }}
      >
        <Layer>
          {whiteRect && (
            <>
              <Rect
                ref={whiteRectRef}
                x={whiteRect.x}
                y={whiteRect.y}
                width={whiteW}
                height={whiteH}
                fill="white"
                stroke="gray"
                onClick={() => {
                  setSelectedIds('whiteRect')
                }}
              />
            </>
          )}
          {image && (
            <Image
              ref={imageRef}
              {...image}
              draggable
              onDragMove={handleDragMove}
              onDragEnd={handleDragEnd}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              opacity={cursor === 'move' ? 0.7 : 1}
            />
          )}
          {selectedIds === 'whiteRect' && whiteRectRef.current && (
            <>
              <Transformer
                ref={trRef}
                onTransformEnd={handleTransformEnd}
                onTransformStart={handleTransformStart}
                onTransform={handleTransform}
                boundBoxFunc={boundBoxFunc}
                keepRatio={false}
                rotateEnabled={false}
                centeredScaling={true}
                flipEnabled={false}
              />
            </>
          )}
        </Layer>
      </Stage>
    </Box>
  )
}

const SizeDisplay = ({ x, y, width, height, stageScale }) => {
  const theme = useTheme()
  return (
    <Text
      x={x}
      y={y - 40 / stageScale}
      width={width}
      height={40 / stageScale}
      text={`${Math.round(width)}x${Math.round(height)}`}
      fontSize={1.3 / stageScale}
      fontStyle="bold"
      align="center"
      verticalAlign="middle"
      fill={theme.palette.draph.blue}
    />
  )
}
