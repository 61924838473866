import React, { useState, useEffect, useRef } from 'react'
import { styled, keyframes } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`

const CustomTextField = styled(TextField)(
  ({ theme, required, minWidth = '25rem', maxWidth = '40rem', isMultiline, isAnimating }) => ({
    '& .MuiInputBase-root': {
      minHeight: '4rem',
      fontSize: '2rem',
      fontWeight: 700,
      backgroundColor: required ? theme.palette.draph.blue : theme.palette.common.white,
      color: required ? theme.palette.common.white : theme.palette.draph.blue,
      borderRadius: '10px',
      paddingLeft: '1.6rem',
      paddingRight: '1.6rem',
      width: 'auto',
      minWidth,
      maxWidth,
      animation: isAnimating ? `${pulse} 0.5s ease-in-out` : 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: isMultiline ? 'normal' : 'nowrap',
    },
    width: '100%',
  })
)

const CustomTextFieldComponent = React.forwardRef(
  (
    { value, required, onChange, placeholder, minWidth = '25rem', maxWidth = '40rem', ...props },
    ref
  ) => {
    const [fieldWidth, setFieldWidth] = useState(minWidth)
    const [isMultiline, setIsMultiline] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)
    const canvasRef = useRef(null)
    const inputRef = useRef(null)

    const measureText = text => {
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')
      context.font = '2rem Pretendard'
      const words = text.split(' ')
      const wordWidths = words.map(word => context.measureText(word).width)
      const spaceWidth = context.measureText(' ').width
      const totalWidth =
        wordWidths.reduce((sum, width) => sum + width, 0) + (words.length - 1) * spaceWidth
      return totalWidth
    }

    useEffect(() => {
      if (value) {
        const textWidth = measureText(value)
        const minWidthPx = parseInt(minWidth) * 10
        const maxWidthPx = parseInt(maxWidth) * 10
        const newWidth = Math.min(Math.max(textWidth + 40, minWidthPx), maxWidthPx)
        const shouldBeMultiline = textWidth + 40 > maxWidthPx
        setIsMultiline(shouldBeMultiline)
        setFieldWidth(shouldBeMultiline ? maxWidth : `${newWidth / 10}rem`)
      } else {
        setFieldWidth(minWidth)
        setIsMultiline(false)
      }
    }, [value, minWidth, maxWidth])

    const handleKeyDown = event => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        animateCompletion()
        if (inputRef.current) {
          inputRef.current.blur()
        }
      }
    }

    const animateCompletion = () => {
      setIsAnimating(true)
      setTimeout(() => setIsAnimating(false), 500)
    }

    return (
      <>
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <CustomTextField
          inputRef={el => {
            inputRef.current = el
            if (typeof ref === 'function') {
              ref(el)
            } else if (ref) {
              ref.current = el
            }
          }}
          value={value}
          required={required}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          fullWidth
          multiline={isMultiline}
          minWidth={minWidth}
          maxWidth={maxWidth}
          isMultiline={isMultiline}
          isAnimating={isAnimating}
          InputProps={{
            sx: {
              width: fieldWidth,
              p: 0,
              '& input, & textarea': {
                width: '100%',
                boxSizing: 'border-box',
                p: 0,
                overflow: 'visible',
                textOverflow: 'clip',
                whiteSpace: isMultiline ? 'normal' : 'nowrap',
              },
            },
          }}
          sx={{
            '& .MuiInputBase-root': {
              width: fieldWidth,
              height: isMultiline ? 'auto' : '4rem',
              minHeight: '4rem',
            },
          }}
          {...props}
        />
      </>
    )
  }
)

CustomTextFieldComponent.displayName = 'CustomTextFieldComponent'

export default CustomTextFieldComponent

const CustomTextField2 = styled(TextField)(({ theme, required, isFocused }) => ({
  '& .MuiInputBase-root': {
    height: '4rem',
    fontSize: '2rem',
    fontWeight: 700,
    backgroundColor: required ? theme.palette.draph.blue : theme.palette.common.white,
    color: required ? theme.palette.common.white : theme.palette.draph.blue,
    borderRadius: '10px',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    animation: isFocused ? `${pulse} 0.5s ease-out forwards` : 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  width: '100%',
}))

export const CustomTextFieldComponent2 = ({
  inputRef,
  value,
  isRequired,
  onChange,
  promptType,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const internalRef = useRef(null)

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      setIsFocused(true)
      if (internalRef.current) {
        internalRef.current.blur()
      }
    }
  }

  return (
    <CustomTextField2
      inputRef={el => {
        internalRef.current = el
        if (inputRef) {
          inputRef.current = el
        }
      }}
      value={value}
      required={isRequired}
      onChange={e => onChange(e, `${promptType}Custom`)}
      onKeyDown={handleKeyDown}
      variant="outlined"
      size="small"
      placeholder="직접 입력"
      fullWidth
      isFocused={isFocused}
      InputProps={{
        sx: {
          width: 'auto',
          p: 0,
          '& input': {
            width: '100%',
            minWidth: '21.8rem',
            boxSizing: 'border-box',
            p: 0,
          },
        },
      }}
      {...props}
    />
  )
}

const CustomTextFieldRegen2 = styled(TextField)(({ theme, required, isFocused }) => ({
  '& .MuiInputBase-root': {
    height: '2rem',
    fontSize: '1.2rem',
    fontWeight: 700,
    backgroundColor: theme.palette.draph.blue,
    color: theme.palette.common.white,
    borderRadius: '4px',
    paddingLeft: '1.6rem',
    // paddingRight: '1.6rem',
    animation: isFocused ? `${pulse} 0.5s ease-out forwards` : 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  width: '100%',
}))

export const CustomTextFieldComponentRegen2 = ({
  inputRef,
  value,
  isRequired,
  onChange,
  promptType,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const internalRef = useRef(null)

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      setIsFocused(true)
      if (internalRef.current) {
        internalRef.current.blur()
      }
    }
  }

  return (
    <CustomTextFieldRegen2
      inputRef={el => {
        internalRef.current = el
        if (inputRef) {
          inputRef.current = el
        }
      }}
      value={value}
      required={isRequired}
      onChange={e => onChange(e, `${promptType}Custom`)}
      onKeyDown={handleKeyDown}
      variant="outlined"
      size="small"
      placeholder="직접 입력"
      fullWidth
      isFocused={isFocused}
      InputProps={{
        sx: {
          width: 'auto',
          p: 0,
          '& input': {
            width: '100%',
            minWidth: '21.8rem',
            boxSizing: 'border-box',
            p: 0,
          },
        },
      }}
      {...props}
    />
  )
}
