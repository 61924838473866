import axios, { Axios } from 'axios'
import { mlAxios as mlDefaultAxios, mlPrevAxios, mlStgAxios } from 'apis/configs/ml'
import { configData } from 'config'
import { json } from 'react-router-dom'

// localStorage 확인

const getAxios = () => {
  const stg = localStorage.getItem('draph-stg') === 'true'
  const prev = localStorage.getItem('draph-prev') === 'true'
  if (stg) {
    return mlStgAxios
  }
  if (prev) {
    return mlPrevAxios
  }
  return mlDefaultAxios
}

export const bgAPI = {
  uploadBgManual: (body, params) => {
    return getAxios().post('/bg_upload_manual/', body, params)
  },

  uploadBgAuto: (body, params) => {
    return getAxios().post('/bg_upload_auto/', body, params)
  },

  uploadBgSD: (body, params) => {
    return getAxios().post('/bg_upload_sd/', body, params)
  },

  updateBg: (backgroundId, body) => {
    return getAxios().put(`/update/${backgroundId}`, body)
  },

  masking: (body, params) => {
    return getAxios().post('/masking/', body, params)
  },
}

export const frontAPI = {
  upload: (body, config = {}) => {
    return getAxios().post('/upload/', body, config)
  },

  retry: body => {
    return getAxios().post('/retry/', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    })
  },

  uploadPiece: (body, params) => {
    return getAxios().post('/upload/piece/', body, params)
  },

  editMask: ({ type, artworkId, additionalMask, sodUrl, refine = false }) => {
    let path = `/editmask/?artwork_id=${artworkId}`
    path += `&mbrush_url=${sodUrl ?? ''}`

    const formData = new FormData()
    formData.append('ai_mode', type === 'ai')
    formData.append('refine_mode', refine === 'refine')
    if (additionalMask) {
      formData.append('additional_mask', additionalMask)
    }
    return getAxios().post(path, formData)
  },
  restoreMask: ({ artworkId }) => {
    return getAxios().post(`/restoremask/?artwork_id=${artworkId}`)
  },

  croppedSod: artworkId => {
    return getAxios().get(`/cropped_sod/?artwork_id=${artworkId}`)
  },

  getAnimatedFile: body => {
    return getAxios().post('/animated_img/', body)
  },

  getNpy: body => {
    return getAxios().post('/mann_get_embed/', body)
  },

  getMann2man: body => {
    return getAxios().post('/mann2man/', body)
  },

  getNpyMaps: (body, config) => {
    return getAxios().post('/mann_get_binmaps/', body, config)
  },

  getEditNpyMaps: (body, config) => {
    return getAxios().post('/mann_edit_binmaps/', body, config)
  },

  getLogoSod: body => {
    return getAxios().post('/logo_sod/', body)
  },

  getCroppedLogoSod: body => {
    return getAxios().post('/cropped_logo_sod/', body)
  },

  getFunCeleb: body => {
    return getAxios().post('/fun_celeb/', body)
  },

  uploadZip: (body, params) => {
    return getAxios().post('/upload_zip/', body, params)
  },

  makeBanner: body => {
    return getAxios().post('/banner/', body)
  },

  makeBannerV2: body => {
    return getAxios().post('/upload_banner_v2/', body)
  },

  retryBannerV2: body => {
    return getAxios().post('/retry_banner_v2/', body)
  },

  genFace: body => {
    return getAxios().post('/gen_face/', body)
  },
  upscaler: params => {
    return getAxios().post('/upscaler/', {}, { params })
  },

  getSod: body => {
    return getAxios().post('/get_sod/', body)
  },

  recommendText: params => {
    return getAxios().post('/banner_v2_retry_copy_recomm/', {}, { params })
  },

  getSnapshot: body => {
    return getAxios().post('/banner_v2_snapshot/', body)
  },

  copyRetry: body => {
    return getAxios().post('/banner_v2_retry_copy_recomm/', body)
  },
}
