import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  keyframes,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { bannerTemplateParamsAtom, loadingAtom } from 'atoms'
import { CenterAlignBox } from 'components/CenterAlignContainer'
import { CreateButton, CustonBannerToggleButton } from 'components/CustomStyle'
import CustomTextFieldComponent, {
  CustomTextFieldComponent2,
  CustomTextFieldComponentRegen2,
} from 'components/SelectWithinInput'
import { PROMPT_BUTTON, PROMPT_OPTIONS, PROMPT_SELECT_IMAGE } from 'config'
import { AnimatePresence, motion } from 'framer-motion'
import { English, Korean } from 'hooks/useLanguage'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BarLoader } from 'react-spinners'
import { useRecoilState, useRecoilValue } from 'recoil'
import { CloseIcon, DownArrowIcon, MinusIcon, PlusIcon, UploadIcon } from 'theme/icon'
import { isKo, translatePromptKoToEn } from 'utils/common'

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(1px); }
  50% { transform: translateX(-1px); }
  75% { transform: translateX(1px); }
  100% { transform: translateX(0); }
`

const ToggleButton = styled(Button)(({ theme, selected }) => ({
  p: '0 2rem',
  height: '4rem',
  backgroundColor: selected ? theme.palette.common.white : '#303030',
  color: selected ? theme.palette.draph.blue : 'white',
  fontWeight: selected ? 700 : 500,
  fontSize: '2rem',
  '&:hover': {
    backgroundColor: selected ? theme.palette.common.white : '#404040',
  },

  '&.Mui-disabled': {
    backgroundColor: theme.palette.draph.blue,
  },
  transition: 'all 0.3s ease',
}))

const ToggleButtonRegen = styled(Button)(({ theme, selected }) => ({
  padding: '0.2rem 0.8rem',
  height: '2rem',
  minWidth: '5rem',
  backgroundColor: selected ? theme.palette.common.black : '#E9E9E9',
  color: selected ? theme.palette.common.white : theme.palette.common.black,
  fontWeight: selected ? 700 : 500,
  fontSize: '1.2rem',
  '&:hover': {
    backgroundColor: selected ? theme.palette.common.black : '#E9E9E9',
  },

  '&.Mui-disabled': {
    backgroundColor: theme.palette.draph.blue,
  },
  transition: 'all 0.3s ease',
}))

export const BannerPrompt = ({
  templateType = '',
  promptValues,
  setPromptValues,
  setSizeSettings,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const [buttonList, setButtonList] = useState([])
  const [selectedPrompt, setSelectedPrompt] = useState([])
  const boxRef = useRef(null)
  const [boxHeight, setBoxHeight] = useState('auto')
  const [imageSelectMode, setImageSelectMode] = useState(false)
  const [selectedImages, setSelectedImages] = useState(null)

  const [isUploadLoading, setIsUploadLoading] = useRecoilState(loadingAtom)

  useEffect(() => {
    if (boxRef.current) {
      if (imageSelectMode) {
        const newHeight = boxRef.current.offsetHeight + 72
        setBoxHeight(newHeight)
      } else {
        setBoxHeight('52rem')
      }
    }
  }, [selectedPrompt, imageSelectMode])
  // const [promptValues, setPromptValues] = useState({})

  useEffect(() => {
    const list = [...PROMPT_BUTTON.common]

    // 서브 카테고리에 따라 추가되는 프롬프트 버튼관련 코드 (현재 모델에서 성별, 국적 프롬프트 안 먹혀서 제거)
    // if (PROMPT_BUTTON[templateType]) {
    //   list.push(...PROMPT_BUTTON[templateType])
    // }

    setButtonList(list)

    const required = [
      ...list.filter(button => button.required).map(button => button.value),
      'custom',
    ]

    setSelectedPrompt(required)
    const initialValues = {}
    required.forEach(prompt => {
      initialValues[prompt] = PROMPT_OPTIONS[prompt]?.[0]?.value || ''
    })

    setPromptValues(initialValues)
  }, [templateType])

  const handleToggle = value => {
    setSelectedPrompt(prev => {
      if (prev.includes(value)) {
        // Remove the value
        const newSelected = prev.filter(item => item !== value)
        // Also remove the value from promptValues
        setPromptValues(prevValues => {
          const newValues = { ...prevValues }

          if (newValues[value] === 'custom') {
            delete newValues[`${value}Custom`]
          }
          delete newValues[value]

          return newValues
        })

        if (PROMPT_SELECT_IMAGE.includes(value)) {
          setImageSelectMode(false)
          setSelectedImages(null)
        }

        return newSelected
      } else {
        // Add the value
        const newSelected = [...prev, value]

        // Set default value for the new prompt
        setPromptValues(prevValues => ({
          ...prevValues,
          [value]: PROMPT_OPTIONS[value]?.[0]?.value || '',
        }))
        if (PROMPT_SELECT_IMAGE.includes(value)) {
          setImageSelectMode(true)
          setSelectedImages(value)
        }

        return newSelected
      }
    })
  }

  const handleSelectChange = (event, promptType) => {
    setPromptValues(prev => {
      const newValues = {
        ...prev,
        [promptType]: event.target.value,
      }
      if (event.target.value === 'custom') {
        const customName = `${promptType}Custom`
        return { ...newValues, [customName]: '' }
      } else {
        delete newValues[`${promptType}Custom`]
      }

      return newValues
    })
  }

  const handleImageSelectChange = (value, promptType) => {
    setPromptValues(prev => {
      const newValues = {
        ...prev,
        [promptType]: value,
      }
      if (value === 'custom') {
        const customName = `${promptType}Custom`
        return { ...newValues, [customName]: '' }
      } else {
        delete newValues[`${promptType}Custom`]
      }

      return newValues
    })
  }

  const handleDelete = promptType => {
    setSelectedPrompt(prev => prev.filter(item => item !== promptType))
    setPromptValues(prevValues => {
      const newValues = { ...prevValues }

      if (newValues[promptType] === 'custom') {
        delete newValues[`${promptType}Custom`]
      }

      delete newValues[promptType]

      if (PROMPT_SELECT_IMAGE.includes(promptType)) {
        setImageSelectMode(false)
        setSelectedImages(null)
      }

      return newValues
    })
  }
  const label =
    PROMPT_BUTTON.common.find(button => button.value === selectedImages)?.label ||
    PROMPT_BUTTON[templateType]?.find(button => button.value === selectedImages)?.label ||
    ''

  const renderPromptContent = promptType => {
    const options = PROMPT_OPTIONS[promptType] || []
    const value = promptValues[promptType] || options[0]?.value || ''
    const customValue = promptValues[`${promptType}Custom`] || null
    const label =
      PROMPT_BUTTON.common.find(button => button.value === promptType)?.label ||
      PROMPT_BUTTON[templateType]?.find(button => button.value === promptType)?.label ||
      ''

    const isRequired =
      [PROMPT_BUTTON.common, PROMPT_BUTTON.model].flat().find(button => button.value === promptType)
        ?.required || false

    const valueLabel = PROMPT_OPTIONS[promptType]?.find(
      option => option.value === promptValues[promptType]
    )?.label

    return (
      <Box
        onClick={e => {
          e.stopPropagation()
        }}
        key={promptType}
      >
        <PromptContentRenderer
          key={promptType}
          promptType={promptType}
          options={options}
          value={value}
          customValue={customValue}
          label={label}
          handleSelectChange={handleSelectChange}
          handleDelete={handleDelete}
          templateType={templateType}
          isRequired={isRequired}
          imageSelectMode={imageSelectMode}
          setImageSelectMode={setImageSelectMode}
          valueLabel={valueLabel}
          setSelectedImages={setSelectedImages}
        />
      </Box>
    )
  }

  const selectArray = PROMPT_OPTIONS[selectedImages]?.find(opt => opt.value === 'custom')
    ? [
        PROMPT_OPTIONS[selectedImages][PROMPT_OPTIONS[selectedImages].length - 1],
        ...PROMPT_OPTIONS[selectedImages]?.slice(0, -1),
      ]
    : PROMPT_OPTIONS[selectedImages]

  const COMMON_SIZE = [
    { label: '1:1', value: { width: 1024, height: 1024 } },
    { label: '16:9', value: { width: 1820, height: 1024 } },
    { label: '9:16', value: { width: 1024, height: 1820 } },
    { label: '4:3', value: { width: 1365, height: 1024 } },
    { label: '3:4', value: { width: 1024, height: 1365 } },
    { label: '3:2', value: { width: 1536, height: 1024 } },
    { label: '2:3', value: { width: 1024, height: 1536 } },
  ]

  const handleSize = e => {
    const { name, value } = e.target

    // 빈 문자열이면 빈 값으로 설정
    if (value === '') {
      setSizeSettings(prev => ({ ...prev, [name]: '' }))
      return
    }

    // 숫자로 변환하여 저장
    setSizeSettings(prev => ({ ...prev, [name]: Number(value) }))
  }

  const handleBlur = e => {
    const { name, value } = e.target

    // 빈 값이면 처리하지 않음
    if (value === '') return

    let numValue = Number(value)

    // blur 시에 값 범위 제한
    if (numValue > 2000) {
      numValue = 2000
    }
    if (numValue < 1) {
      numValue = 1
    }

    setSizeSettings(prev => ({ ...prev, [name]: numValue }))
  }

  return (
    <Stack
      sx={{
        width: '112.8rem',
        mb: '14.4rem',

        '& .size-box': {
          width: '100%',
          borderRadius: '30px',
          display: 'flex',
          backgroundColor: '#303030',
          p: '1.2rem 3.3rem',
          height: '6.4rem',
          mt: '4.8rem',
          alignItems: 'center',
          alignContent: 'flex-start',

          '& .fixed': {
            display: 'flex',
            gap: '1.2rem',
            alignItems: 'center',
            color: '#D9D9D9',
            fontSize: '2rem',
            fontWeight: 600,

            '& .toggle': {
              width: '8rem',
              fontSize: '2rem',
              height: '4rem',
              fontWeight: 500,
              borderRadius: '10px',
              backgroundColor: 'rgba(80, 80, 80, 1)',
              color: 'rgba(171, 171, 171, 1)',
              '&.Mui-selected': {
                fontWeight: 600,
                backgroundColor: theme.palette.draph.blue,
                color: theme.palette.common.white,

                '&:hover': {
                  backgroundColor: theme.palette.draph.blue,
                  color: theme.palette.common.white,
                },
              },
            },
          },

          '& .floating': {
            display: 'flex',
            gap: '1.2rem',
            alignItems: 'center',
            color: '#D9D9D9',
            fontSize: '2rem',
            fontWeight: 600,
          },
        },

        '& .display': {
          width: '100%',
          borderRadius: '30px',
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor: '#303030',
          p: '3.6rem 4rem',
          gap: '0.8rem',
          height: boxHeight,
          mt: '4.8rem',
          // mt: '1.6rem',
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          transition: 'height 0.3s ease-in-out',
        },

        '& .button-list': {
          gap: '1.6rem',
          mt: '2rem',
          flexFlow: 'wrap',
          opacity: imageSelectMode ? 0 : 1,
          transition: 'opacity 0.3s ease-in',
        },

        '& .selected-image': {
          width: '100%',
          borderRadius: '30px 30px 0 0',
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor: '#303030',
          gap: '0.8rem',
          height: '62.8rem',
          overflow: 'auto',
          p: '3.2rem 2rem 0.6rem 2rem', // 하단 패딩 추가
          mt: '2rem',
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          position: 'relative', // 그라데이션 위치 설정을 위해 추가

          // 스크롤바 숨기기
          '&::-webkit-scrollbar': {
            display: 'none',
          },

          // Firefox에서도 스크롤바 숨기기
          scrollbarWidth: 'none',

          // IE에서도 스크롤바 숨기기
          '-ms-overflow-style': 'none',

          // // 스크롤바 스타일링
          // '&::-webkit-scrollbar': {
          //   width: '8px',
          //   borderRadius: '4px',
          // },
          // '&::-webkit-scrollbar-track': {
          //   background: '#303030',
          //   borderRadius: '4px',
          //   // 상단과 하단에만 마진을 주어 스크롤바 트랙이 borderRadius에 맞도록 조정
          //   margin: '30px 0',
          // },
          // '&::-webkit-scrollbar-thumb': {
          //   background: '#888',
          //   borderRadius: '4px',
          //   // 스크롤바 썸네일이 트랙 안에 완전히 들어가도록 border 추가
          //   border: '2px solid #303030',

          //   // 최소 높이 설정으로 너무 작아지지 않도록
          //   minHeight: '40px',
          // },
          // '&::-webkit-scrollbar-thumb:hover': {
          //   background: '#555',
          // },

          // 하단 그라데이션 추가
          // '&::after': {
          //   content: '""',
          //   position: 'absolute',
          //   bottom: 0,
          //   left: 0,
          //   right: 0,
          //   height: '4.6rem',
          //   background: 'linear-gradient(180deg, rgba(48, 48, 48, 0) 0%, rgba(48, 48, 48, 1) 100%)',
          //   pointerEvents: 'none', // 그라데이션이 클릭을 방해하지 않도록 설정
          // },

          '& .gradient-box': {
            content: '""',
            position: 'sticky',
            width: '100%',
            bottom: '-1.4rem',
            left: 0,
            right: 0,
            height: '4.6rem',
            background: 'linear-gradient(180deg, rgba(48, 48, 48, 0) 0%, rgba(48, 48, 48, 1) 100%)',
            pointerEvents: 'none', // 그라데이션이 클릭을 방해하지 않도록 설정
          },
        },
      }}
    >
      {/* <Box className="size-box">
        <Box className="fixed">
          {t('banner_v2.ratio')}
          {COMMON_SIZE.map(s => {
            return (
              <CustonBannerToggleButton
                className="toggle"
                key={s.label}
                selected={
                  sizeSettings.width === s.value.width && sizeSettings.height === s.value.height
                }
                onClick={() => {
                  setSizeSettings(s.value)
                }}
              >
                {s.label}
              </CustonBannerToggleButton>
            )
          })}
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{ mx: '2.8rem', border: '1px solid #989898' }}
        />
        <Box className="floating">
          {t('banner_v2.size')}
          <TextField
            name="width"
            type="number"
            value={sizeSettings.width}
            onChange={handleSize}
            placeholder="가로"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    ml: 0,
                    mr: '0.8rem',
                    '& .MuiTypography-root': {
                      color: '#ABABAB',
                    },
                  }}
                >
                  px
                </InputAdornment>
              ),
            }}
            sx={{
              width: '12.4rem',
              p: 0,
              backgroundColor: '#505050',
              borderRadius: '10px',
              color: '#ABABAB',
              '& .MuiOutlinedInput-root': {
                p: 0,
              },

              '& .MuiOutlinedInput-input': {
                p: '0rem 1rem',
                height: '4rem',
                backgroundColor: '#505050',
                borderRadius: '10px',
                color: '#ABABAB',
                textAlign: 'center',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            onBlur={handleBlur}
          />
          <TextField
            name="height"
            type="number"
            value={sizeSettings.height}
            onChange={handleSize}
            placeholder="세로"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    ml: 0,
                    mr: '0.8rem',
                    '& .MuiTypography-root': {
                      color: '#ABABAB',
                    },
                  }}
                >
                  px
                </InputAdornment>
              ),
            }}
            sx={{
              width: '12.4rem',
              p: 0,
              backgroundColor: '#505050',
              borderRadius: '10px',
              color: '#ABABAB',
              '& .MuiOutlinedInput-root': {
                p: 0,
              },

              '& .MuiOutlinedInput-input': {
                p: '0rem 1rem',
                height: '4rem',
                backgroundColor: '#505050',
                borderRadius: '10px',
                color: '#ABABAB',
                textAlign: 'center',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            onBlur={handleBlur}
          />
        </Box>
      </Box> */}
      <Box
        className="display"
        // onClick={() => {
        //   if (!selectedPrompt.includes('custom')) {
        //     setSelectedPrompt(p => [...p, 'custom'])
        //   }
        // }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.8rem' }} ref={boxRef}>
          {selectedPrompt.map(renderPromptContent)}
        </Box>
      </Box>

      <AnimatePresence>
        {!imageSelectMode && (
          <motion.div
            className="button-list"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              gap: '1.6rem',
              marginTop: '2rem',
              display: 'flex',
              flexFlow: 'wrap',
            }}
          >
            {buttonList.map((button, index) => (
              <ToggleButton
                key={index}
                value={button.value}
                selected={selectedPrompt.includes(button.value)}
                onClick={() => handleToggle(button.value)}
                disabled={button.required}
              >
                {isKo(i18n) ? button.label : button.value}
                {!button.required && (
                  <Box sx={{ ml: '0.5rem' }}>
                    {selectedPrompt.includes(button.value) ? (
                      <MinusIcon
                        style={{ width: '1.5rem', height: '1.5rem' }}
                        color={theme.palette.draph.blue}
                      />
                    ) : (
                      <PlusIcon style={{ width: '1.5rem', height: '1.5rem' }} color="#D9D9D9" />
                    )}
                  </Box>
                )}
              </ToggleButton>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {imageSelectMode && (
          <motion.div
            className="selected-image"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            style={{
              gap: '2.4rem',
              marginTop: '2rem',
              display: 'flex',
              flexFlow: 'wrap',
            }}
          >
            <CenterAlignBox sx={{ position: 'relative', width: '100%' }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '2.4rem',
                  color: theme.palette.common.white,
                  textTransform: 'capitalize',
                }}
              >
                {isKo(i18n) ? label : selectedImages}
              </Typography>
              <Button
                sx={{
                  position: 'absolute',
                  right: 0,
                  width: '14rem',
                  height: '4rem',
                  fontSize: '2rem',
                  fontWeight: 600,
                  backgroundColor: '#505050',
                  borderRadius: '30px',

                  '& .typo': {
                    fontSize: '2rem',
                    fontWeight: 600,
                    color: theme.palette.common.white,
                  },
                  '&:hover': {
                    backgroundColor: 'white',
                    '& .typo': {
                      background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                      WebkitBackgroundClip: 'text',
                      backgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      textFillColor: 'transparent',
                    },
                  },
                }}
                onClick={() => {
                  setSelectedImages(null)
                  setImageSelectMode(false)
                }}
              >
                <Typography className="typo">{t('banner_v2.complete')}</Typography>
              </Button>
            </CenterAlignBox>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'wrap',
                gap: '1rem',
                rowGap: '2.4rem',
                position: 'relative',
              }}
            >
              {selectArray.map(opt => {
                return (
                  <SelectImageCard
                    key={opt.value}
                    option={opt}
                    selectedImages={selectedImages}
                    selected={promptValues[selectedImages] === opt.value}
                    handleImageSelectChange={handleImageSelectChange}
                  />
                )
              })}

              <Box className="gradient-box"></Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>

      {/* <CreateButton
        sx={{ mt: '8rem', borderRadius: '30px', height: '6.8rem' }}
        disabled={
          // !(
          //   bannerConfig.product_name &&
          //   bannerConfig.product_feature &&
          //   bannerConfig.brand_name &&
          //   bannerConfig.brand_feature
          // ) ||
          isUploadLoading
        }
        onClick={() => {
          setIsUploadLoading(true)
          // const body = { config: bannerConfig }

          // apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
          //   setPortfolioDetail({ ...portfolioDetail, ...response.data })
          // })

          uploadBannerV2()
        }}
      >
        {isUploadLoading ? (
          <BarLoader width={150} />
        ) : (
          <Typography
            className="content"
            sx={{
              fontWeight: 700,
              fontSize: '2.4rem',
              color: theme.palette.common.white,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <UploadIcon
              sx={{ marginRight: '0.5rem', width: '2rem', height: '2rem' }}
              strokeWidth="3"
            />
            {isKo(i18n) ? 'AI 생성하기' : 'Generate'}
          </Typography>
        )}
      </CreateButton> */}
    </Stack>
  )
}

const CustomSelect = styled(Select)(({ theme, required, custom }) => ({
  backgroundColor: required ? theme.palette.draph.blue : theme.palette.common.white,
  borderRadius: '10px',
  minWidth: custom ? '30rem' : 'none',
  fontSize: '2rem',
  fontWeight: 700,
  height: '4rem',
  color: required ? theme.palette.common.white : theme.palette.draph.blue,
  width: 'auto',
  '& .MuiSelect-select': {
    paddingRight: '32px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& path': {
    stroke: required
      ? `${theme.palette.common.white} !important`
      : `${theme.palette.draph.blue} !important`,
  },

  '& .Mui-disabled': {
    cursor: 'pointer',
    color: required ? theme.palette.common.white : theme.palette.draph.blue,
    '-webkit-text-fill-color': required ? theme.palette.common.white : theme.palette.draph.blue,
  },
}))

const CustomTextField = styled(TextField)(({ theme, required }) => ({
  '& .MuiInputBase-root': {
    height: '4rem',
    fontSize: '2rem',
    fontWeight: 700,
    backgroundColor: required ? theme.palette.draph.blue : theme.palette.common.white,
    color: required ? theme.palette.common.white : theme.palette.draph.blue,
    borderRadius: '10px',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  width: '100%',
}))

const PromptContentRenderer = ({
  promptType,
  options,
  value,
  customValue,
  label,
  handleSelectChange,
  handleDelete,
  templateType,
  isRequired,
  imageSelectMode,
  setImageSelectMode,
  valueLabel,
  setSelectedImages,
}) => {
  const menuProps = {
    PaperProps: {
      style: {
        borderRadius: '10px',
      },
    },
  }

  const theme = useTheme()

  const customInputRef = useRef(null)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (value === 'custom' && customInputRef.current) {
      customInputRef.current.focus()
    }
  }, [value])

  const selectImageStyle = {}

  const renderContent = () => {
    switch (promptType) {
      case 'gender':
        return (
          <>
            {/* <Typography className="typo">모델 성별은 </Typography> */}
            <CustomSelect
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelect>
            {/* <Typography className="typo">입니다.</Typography> */}
          </>
        )

      case 'nation':
        return (
          <CustomSelect
            value={value}
            onChange={e => handleSelectChange(e, promptType)}
            sx={{ mr: '0.8rem' }}
            required={isRequired}
            MenuProps={menuProps}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {isKo(i18n) ? option.label : option.value}
              </MenuItem>
            ))}
          </CustomSelect>
        )

      case 'product':
        return (
          <>
            <Korean>
              <Typography className="typo">{`${label}는`}</Typography>
              <Box
                sx={{
                  position: 'relative',
                  animation:
                    customValue === null && value === 'custom'
                      ? `${shakeAnimation} 0.4s ease-in-out 0s 3`
                      : 'none',
                }}
              >
                <CustomSelect
                  value={value}
                  onChange={e => handleSelectChange(e, promptType)}
                  sx={{
                    mx: '0.8rem',
                    animation:
                      value === 'custom' ? `${shakeAnimation} 0.4s ease-in-out 0s 3` : 'none',
                  }}
                  required={isRequired}
                  custom={Boolean(value === 'custom')}
                  MenuProps={menuProps}
                >
                  {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {isKo(i18n) ? option.label : option.value}
                    </MenuItem>
                  ))}
                </CustomSelect>

                {value === 'custom' && (
                  <Box
                    sx={{
                      display: 'inline-block',
                      // minWidth: '10rem',
                      position: 'absolute',
                      left: '0.8rem',
                      animation: `${shakeAnimation} 0.4s ease-in-out 0s 3`,
                    }}
                  >
                    <CustomTextField
                      inputRef={customInputRef}
                      value={customValue}
                      required={isRequired}
                      onChange={e => handleSelectChange(e, `${promptType}Custom`)}
                      variant="outlined"
                      size="small"
                      placeholder={isKo(i18n) ? '직접 입력' : 'Please complete it.'}
                      fullWidth
                      InputProps={{
                        sx: {
                          width: 'auto',
                          p: 0,
                          '& input': {
                            width: '100%',
                            minWidth: '24rem',
                            boxSizing: 'border-box',
                            p: 0,
                          },
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Typography className="typo">입니다.</Typography>
            </Korean>

            <English>
              <Typography className="typo">The product type is</Typography>
              <Box
                sx={{
                  position: 'relative',
                  animation:
                    customValue === null && value === 'custom'
                      ? `${shakeAnimation} 0.4s ease-in-out 0s 3`
                      : 'none',
                }}
              >
                <CustomSelect
                  value={value}
                  onChange={e => handleSelectChange(e, promptType)}
                  sx={{
                    mx: '0.8rem',
                    animation:
                      value === 'custom' ? `${shakeAnimation} 0.4s ease-in-out 0s 3` : 'none',
                  }}
                  required={isRequired}
                  custom={Boolean(value === 'custom')}
                  MenuProps={menuProps}
                >
                  {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {isKo(i18n) ? option.label : option.value}
                    </MenuItem>
                  ))}
                </CustomSelect>

                {value === 'custom' && (
                  <Box
                    sx={{
                      display: 'inline-block',
                      // minWidth: '10rem',
                      position: 'absolute',
                      left: '0.8rem',
                      animation: `${shakeAnimation} 0.4s ease-in-out 0s 3`,
                    }}
                  >
                    <CustomTextField
                      inputRef={customInputRef}
                      value={customValue}
                      required={isRequired}
                      onChange={e => handleSelectChange(e, `${promptType}Custom`)}
                      variant="outlined"
                      size="small"
                      placeholder="Please complete it."
                      fullWidth
                      InputProps={{
                        sx: {
                          width: 'auto',
                          p: 0,
                          '& input': {
                            width: '100%',
                            minWidth: '24rem',
                            boxSizing: 'border-box',
                            p: 0,
                          },
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>
            </English>
          </>
        )
      // case 'background':
      //   return (
      //     <>
      //       <Box
      //         sx={{
      //           position: 'relative',
      //           animation:
      //             customValue === null && value === 'custom'
      //               ? `${shakeAnimation} 0.4s ease-in-out 0s 3`
      //               : 'none',
      //         }}
      //       >
      //         <CustomSelect
      //           value={value}
      //           onChange={e => handleSelectChange(e, promptType)}
      //           sx={{ mr: '0.8rem', ...(value === 'custom' && { width: '30rem' }) }}
      //           required={isRequired}
      //           MenuProps={menuProps}
      //           disabled={true}
      //           onClick={() => {
      //             setImageSelectMode(p => !p)
      //             setSelectedImages(promptType)
      //           }}
      //         >
      //           {options.map(option => (
      //             <MenuItem key={option.value} value={option.value}>
      //               {isKo(i18n) ? option.label : option.value}
      //             </MenuItem>
      //           ))}
      //         </CustomSelect>
      //         {/* <Box
      //           sx={{
      //             backgroundColor: isRequired
      //               ? theme.palette.draph.blue
      //               : theme.palette.common.white,
      //             borderRadius: '10px',
      //             minWidth: value === 'custom' ? '26.4rem' : 'none',
      //             fontSize: '2rem',
      //             fontWeight: 700,
      //             height: '4rem',
      //             color: isRequired ? theme.palette.common.white : theme.palette.draph.blue,
      //             width: 'auto',
      //             cursor: 'pointer',
      //             display: 'flex',
      //             justifyContent: 'center',
      //             alignItems: 'center',
      //             px: '1.6rem',
      //             mx: '0.8rem',
      //           }}
      //           onClick={() => {
      //             setImageSelectMode(p => !p)
      //             setSelectedImages(promptType)
      //           }}
      //         >
      //           {valueLabel}
      //         </Box> */}
      //         {value === 'custom' && (
      //           <Box
      //             sx={{
      //               display: 'inline-block',
      //               minWidth: '10rem',
      //               position: 'absolute',
      //               left: '0.8rem',
      //               top: 0,
      //               animation: `${shakeAnimation} 0.4s ease-in-out 0s 3`,
      //             }}
      //           >
      //             <CustomTextFieldComponent2
      //               inputRef={customInputRef}
      //               value={customValue}
      //               required={isRequired}
      //               onChange={e => handleSelectChange(e, `${promptType}Custom`)}
      //               variant="outlined"
      //               size="small"
      //                     placeholder={isKo(i18n) ? '직접 입력' : 'Please complete it.'}
      //               fullWidth
      //               InputProps={{
      //                 sx: {
      //                   width: 'auto',
      //                   p: 0,
      //                   '& input': {
      //                     width: '100%',
      //                     minWidth: '21.8rem',
      //                     boxSizing: 'border-box',
      //                     p: 0,
      //                   },
      //                 },
      //               }}
      //             />
      //           </Box>
      //         )}
      //       </Box>
      //       <Typography className="typo">배경</Typography>
      //     </>
      //   )

      case 'style':
        return (
          <>
            <CustomSelect
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelect>
            <Typography className="typo">{t('banner_v2.style')}</Typography>
          </>
        )

      case 'lightning':
        return (
          <>
            <CustomSelect
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
              MenuProps={menuProps}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelect>
            <Typography className="typo">{t('banner_v2.lightning')}</Typography>
          </>
        )

      case 'camera':
        return (
          <>
            <English>
              {' '}
              <Typography className="typo">Photos taken with&nbsp;</Typography>
            </English>
            <CustomSelect
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              disabled={true}
              MenuProps={menuProps}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelect>
            <Korean>
              <Typography className="typo">로 촬영된 사진</Typography>
            </Korean>
          </>
        )

      case 'film':
        return (
          <>
            <CustomSelect
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
              MenuProps={menuProps}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelect>
            <Typography className="typo">{t('banner_v2.film')}</Typography>
          </>
        )
      // case 'lens':
      //   return (
      //     <>
      //       <CustomSelect
      //         value={value}
      //         onChange={e => handleSelectChange(e, promptType)}
      //         sx={{ mr: '0.8rem' }}
      //         required={isRequired}
      //         disabled={true}
      //         onClick={() => {
      //           setImageSelectMode(p => !p)
      //           setSelectedImages(promptType)
      //         }}
      //         MenuProps={menuProps}
      //       >
      //         {options.map(option => (
      //           <MenuItem key={option.value} value={option.value}>
      //             {isKo(i18n) ? option.label : option.value}
      //           </MenuItem>
      //         ))}
      //       </CustomSelect>
      //       <Typography className="typo">으로 촬영된 사진</Typography>
      //     </>
      //   )

      case 'angle':
        return (
          <>
            <CustomSelect
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
              MenuProps={menuProps}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelect>
            <Korean>
              <Typography className="typo">카메라 앵글</Typography>
            </Korean>
          </>
        )

      case 'filter':
        return (
          <>
            <CustomSelect
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
              MenuProps={menuProps}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelect>
            <Korean>
              <Typography className="typo">필터</Typography>
            </Korean>
          </>
        )

      case 'tone':
        return (
          <>
            {' '}
            <Box
              sx={{
                position: 'relative',
                animation:
                  customValue === null && value === 'custom'
                    ? `${shakeAnimation} 0.4s ease-in-out 0s 3`
                    : 'none',
              }}
            >
              <CustomSelect
                value={value}
                onChange={e => handleSelectChange(e, promptType)}
                sx={{
                  mr: '0.8rem',
                  animation:
                    value === 'custom' ? `${shakeAnimation} 0.4s ease-in-out 0s 3` : 'none',
                }}
                required={isRequired}
                custom={Boolean(value === 'custom')}
                MenuProps={menuProps}
              >
                {options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {isKo(i18n) ? option.label : option.value}
                  </MenuItem>
                ))}
              </CustomSelect>
              {value === 'custom' && (
                <Box
                  sx={{
                    display: 'inline-block',
                    minWidth: '10rem',
                    position: 'absolute',
                    left: '0.8rem',
                    animation: `${shakeAnimation} 0.4s ease-in-out 0s 3`,
                  }}
                >
                  <CustomTextField
                    inputRef={customInputRef}
                    value={customValue}
                    required={isRequired}
                    onChange={e => handleSelectChange(e, `${promptType}Custom`)}
                    variant="outlined"
                    size="small"
                    placeholder={isKo(i18n) ? '직접 입력' : 'Please complete it.'}
                    fullWidth
                    InputProps={{
                      sx: {
                        width: 'auto',
                        '& input': {
                          width: '100%',
                          minWidth: '25rem',
                          boxSizing: 'border-box',
                          p: 0,
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
            <Typography className="typo">{t('banner_v2.tone')}</Typography>
          </>
        )

      case 'custom':
        return (
          <>
            {' '}
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  display: 'inline-block',
                  minWidth: '10rem',
                  // position: 'absolute',
                  left: '0.8rem',
                }}
              >
                <CustomTextFieldComponent
                  ref={customInputRef}
                  value={customValue}
                  required={isRequired}
                  onChange={e => handleSelectChange(e, `custom`)}
                  placeholder={isKo(i18n) ? '배경 정보를 입력해주세요.' : 'Please complete it.'}
                  minWidth="25rem"
                  maxWidth="104.8rem"
                />
              </Box>
            </Box>
          </>
        )

      default:
        return (
          <>
            <Typography className="typo">{`${label}은(는)`}</Typography>
            <CustomSelect
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mx: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelect>
            <Typography className="typo">입니다</Typography>
          </>
        )
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        // height: '4rem',
        '& .typo': { color: 'white', fontSize: '2rem', fontWeight: 700 },
      }}
    >
      {renderContent()}
      {!isRequired && (
        <IconButton
          onClick={() => handleDelete(promptType)}
          sx={{ ml: '0.4rem', p: '0.8rem' }}
          disableRipple
        >
          <CloseIcon sx={{ width: '1.2rem', height: '1.2rem' }} color="#808080" />
        </IconButton>
      )}
    </Box>
  )
}

const SelectImageCard = ({
  option,
  selectedImages,
  selected,
  handleImageSelectChange,
  regenMode = false,
}) => {
  const gradientBackground = 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)'
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  return (
    <Stack
      value={option.value}
      sx={{
        borderRadius: '13px',
        width: '20.8rem',
        maxHeight: '46.2rem',
        cursor: 'pointer',
        '& .box': {
          borderRadius: '0 0 13px 13px',
          transition: 'all 0.3s ease',
          p: '1.2rem',
          '& .prompt-title': {
            fontWeight: 600,
            fontSize: '1.4rem',
            lineHeight: 'normal',
          },
          '& .body': {
            mt: '0.8rem',
            fontWeight: 400,
            fontSize: '1.1rem',
            lineHeight: 1.43,
          },
          '&.selected': {
            backgroundColor: '#fff',
            lineBreak: 'strict',
            wordBreak: 'keep-all',
            '& .typo': {
              background: gradientBackground,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textFillColor: 'transparent',
            },

            '& .suggestion-box': {
              width: '100%',
              p: '1rem 0.8rem',
              backgroundColor: 'rgba(242, 246, 255, 1)',
              borderRadius: '4px',
              mt: '0.8rem',

              '& .suggestion-typo': {
                fontSize: '1rem',
                width: 'fit-content',
                color: theme.palette.common.white,
              },

              '& .suggestion-typo.prompt-title': {
                p: '0.2rem 0.65rem',
                fontWeight: 600,
                background: gradientBackground,
                borderRadius: '4px',
                mb: '0.4rem',
              },

              '& .suggestion-typo.notice': {
                p: '0 0.2rem',
                fontWeight: 600,
                background: gradientBackground,
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textFillColor: 'transparent',
                borderRadius: '4px',
              },
            },
          },

          '&:not(.selected)': {
            backgroundColor: '#505050',
            lineBreak: 'strict',
            wordBreak: 'keep-all',
            '& .typo': {
              color: '#ffffff',
            },

            '& .suggestion-box': {
              width: '100%',
              p: '1rem 0.8rem',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '4px',
              mt: '0.8rem',

              '& .suggestion-typo': {
                fontSize: '1rem',
                width: 'fit-content',
                color: theme.palette.common.white,
              },

              '& .suggestion-typo.prompt-title': {
                fontWeight: 600,
                background: 'rgba(255, 255, 255, 0.2)',
                p: '0.2rem 0.65rem',
                borderRadius: '4px',
                mb: '0.4rem',
              },

              '& .suggestion-typo.notice': {
                fontWeight: 600,
                p: '0 0.2rem',

                // background: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '4px',
              },
            },
          },
        },
      }}
      onClick={() => {
        handleImageSelectChange(option.value, selectedImages)
      }}
    >
      <img
        src={option.src}
        style={{
          width: '20.8rem',
          height: '20.8rem',
          objectFit: 'cover',
          borderRadius: '13px 13px 0 0 ',
        }}
      />

      <Stack className={`box ${selected ? 'selected' : ''}`}>
        <Typography className="prompt-title typo">
          {isKo(i18n) ? option.label : option.value}
        </Typography>
        <Typography className="body typo">{isKo(i18n) ? option.bodyKo : option.bodyEn}</Typography>

        {option.suggest && (
          <Box className="suggestion-box">
            <CenterAlignBox className="suggestion-typo prompt-title">
              <Korean>추천 제품 카테고리</Korean>
              <English>Recommended</English>
            </CenterAlignBox>
            <CenterAlignBox className="suggestion-typo notice">
              {isKo(i18n) ? option.suggestKo : option.suggestEn}
            </CenterAlignBox>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export const BannerPromptRegenerate = ({
  templateType = '',
  promptValues,
  setPromptValues,
  directConfigUpdate,
  category,
}) => {
  const theme = useTheme()
  const [buttonList, setButtonList] = useState([])
  const [selectedPrompt, setSelectedPrompt] = useState([])
  const [boxHeight, setBoxHeight] = useState('auto')
  const [imageSelectMode, setImageSelectMode] = useState(false)
  const [selectedImages, setSelectedImages] = useState(null)

  const boxRef = useRef(null)

  const [animationOrigin, setAnimationOrigin] = useState({ top: 0, left: 0 })
  const contentRefs = useRef({})

  //
  useEffect(() => {
    // auto 일 때는 모두 선택하는걸로 required 삭제 !
    const list =
      category === 'manual'
        ? [...PROMPT_BUTTON.common]
        : PROMPT_BUTTON.common.map(({ required, ...rest }) => rest)

    // 서브 카테고리에 따라 추가되는 프롬프트 버튼관련 코드 (현재 모델에서 성별, 국적 프롬프트 안 먹혀서 제거)
    // if (PROMPT_BUTTON[templateType]) {
    //   list.push(...PROMPT_BUTTON[templateType])
    // }

    setButtonList(list)
  }, [templateType, category])

  useEffect(() => {
    if (boxRef.current) {
      if (imageSelectMode) {
        const newHeight = boxRef.current.offsetHeight - 72
        setBoxHeight(newHeight)
      } else {
        setBoxHeight('20.8rem')
      }
    }
  }, [selectedPrompt, imageSelectMode])

  // useEffect(() => {
  //   directConfigUpdate('bg_inpaint_prompt', translatePromptKoToEn(promptValues))
  // }, [promptValues])

  // const [promptValues, setPromptValues] = useState({})

  useEffect(() => {
    const promptValuesKeys = Object.keys(promptValues).map(key =>
      key === 'customCustom' ? 'custom' : key
    )

    setSelectedPrompt(promptValuesKeys)
  }, [])

  const handleToggle = value => {
    setSelectedPrompt(prev => {
      if (prev.includes(value)) {
        // Remove the value
        const newSelected = prev.filter(item => item !== value)
        // Also remove the value from promptValues
        setPromptValues(prevValues => {
          const newValues = { ...prevValues }

          if (newValues[value] === 'custom') {
            delete newValues[`${value}Custom`]
          }
          delete newValues[value]

          return newValues
        })

        if (PROMPT_SELECT_IMAGE.includes(value)) {
          setImageSelectMode(false)
          setSelectedImages(null)
        }

        return newSelected
      } else {
        // Add the value
        const newSelected = [...prev, value]

        // Set default value for the new prompt
        setPromptValues(prevValues => ({
          ...prevValues,
          [value]: PROMPT_OPTIONS[value]?.[0]?.value || '',
        }))
        if (PROMPT_SELECT_IMAGE.includes(value)) {
          setImageSelectMode(true)
          setSelectedImages(value)
        }

        return newSelected
      }
    })
  }

  const handleSelectChange = (event, promptType) => {
    setPromptValues(prev => {
      const newValues = {
        ...prev,
        [promptType]: event.target.value,
      }
      if (event.target.value === 'custom') {
        const customName = `${promptType}Custom`
        return { ...newValues, [customName]: '' }
      } else {
        delete newValues[`${promptType}Custom`]
      }

      return newValues
    })
  }

  const handleImageSelectChange = (value, promptType) => {
    setPromptValues(prev => {
      const newValues = {
        ...prev,
        [promptType]: value,
      }
      if (value === 'custom') {
        const customName = `${promptType}Custom`
        return { ...newValues, [customName]: '' }
      } else {
        delete newValues[`${promptType}Custom`]
      }

      return newValues
    })
  }

  const handleDelete = promptType => {
    setSelectedPrompt(prev => prev.filter(item => item !== promptType))
    setPromptValues(prevValues => {
      const newValues = { ...prevValues }

      if (newValues[promptType] === 'custom') {
        delete newValues[`${promptType}Custom`]
      }

      delete newValues[promptType]

      if (PROMPT_SELECT_IMAGE.includes(promptType)) {
        setImageSelectMode(false)
        setSelectedImages(null)
      }

      return newValues
    })
  }
  const label =
    PROMPT_BUTTON.common.find(button => button.value === selectedImages)?.label ||
    PROMPT_BUTTON[templateType]?.find(button => button.value === selectedImages)?.label ||
    ''

  const renderPromptContent = promptType => {
    const options = PROMPT_OPTIONS[promptType] || []
    const value = promptValues[promptType] || options[0]?.value || ''
    const customValue =
      promptType === 'custom' ? promptValues.custom : promptValues[`${promptType}Custom`] || null
    const label =
      PROMPT_BUTTON.common.find(button => button.value === promptType)?.label ||
      PROMPT_BUTTON[templateType]?.find(button => button.value === promptType)?.label ||
      ''

    const isRequired =
      [PROMPT_BUTTON.common, PROMPT_BUTTON.model].flat().find(button => button.value === promptType)
        ?.required || false

    const valueLabel = PROMPT_OPTIONS[promptType]?.find(
      option => option.value === promptValues[promptType]
    )?.label

    return (
      <Box
        onClick={e => {
          e.stopPropagation()
        }}
        key={promptType}
      >
        <PromptContentRendererRegen
          key={promptType}
          promptType={promptType}
          options={options}
          value={value}
          customValue={customValue}
          label={label}
          handleSelectChange={handleSelectChange}
          handleDelete={handleDelete}
          templateType={templateType}
          isRequired={isRequired}
          imageSelectMode={imageSelectMode}
          setImageSelectMode={setImageSelectMode}
          valueLabel={valueLabel}
          setSelectedImages={setSelectedImages}
        />
      </Box>
    )
  }

  // 가장 밑에 직접입력(custom) 이 있을 경우, 앞으로 끌어오기 분기 위해서 함 (Background 용 지금은 사용 안함)
  const selectArray = PROMPT_OPTIONS[selectedImages]?.find(opt => opt.value === 'custom')
    ? [
        PROMPT_OPTIONS[selectedImages][PROMPT_OPTIONS[selectedImages].length - 1],
        ...PROMPT_OPTIONS[selectedImages]?.slice(0, -1),
      ]
    : PROMPT_OPTIONS[selectedImages]

  return (
    <Stack
      sx={{
        width: 'inherit',
        mb: imageSelectMode ? '0rem' : '0rem',
        '& .display': {
          width: '100%',
          borderRadius: '6px',
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor: '#fff',
          p: '0.8rem',
          gap: '0.6rem',
          height: boxHeight,
          // mt: '7.2rem',
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          transition: 'height 0.3s ease-in-out',
        },

        '& .button-list': {
          gap: '0.4rem',
          mt: '2rem',
          flexFlow: 'wrap',
          opacity: imageSelectMode ? 0 : 1,
          transition: 'opacity 0.3s ease-in',
        },

        '& .selected-image': {
          width: '32.8rem',
          borderRadius: '10px',
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor: '#fff',
          gap: '0.8rem',
          // height: '62.8rem',
          overflow: 'hidden',
          p: '1.8rem 1.3rem 1.6rem 1.3rem', // 하단 패딩 추가

          alignItems: 'flex-start',
          alignContent: 'flex-start',
          position: 'relative', // 그라데이션 위치 설정을 위해 추가
          boxShadow: '0px -4px 20px 0px rgba(0, 0, 0, 0.1)',

          // 스크롤바 스타일링 (웹킷 브라우저용)
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#303030',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },

          // 하단 그라데이션 추가
          // '&::after': {
          //   content: '""',
          //   position: 'absolute',
          //   bottom: 0,
          //   left: 0,
          //   right: 0,
          //   height: '4.6rem',
          //   background: 'linear-gradient(180deg, rgba(48, 48, 48, 0) 0%, rgba(48, 48, 48, 1) 100%)',
          //   pointerEvents: 'none', // 그라데이션이 클릭을 방해하지 않도록 설정
          // },
        },
      }}
    >
      <Box
        className="display"
        onClick={() => {
          if (!selectedPrompt.includes('custom')) {
            setSelectedPrompt(p => [...p, 'custom'])
          }
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.6rem' }} ref={boxRef}>
          {selectedPrompt.map(renderPromptContent)}{' '}
          <Box sx={{ width: '100%', position: 'relative' }}>
            <AnimatePresence>
              {imageSelectMode && (
                <motion.div
                  className="selected-image"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                  style={{
                    top: '1rem',
                    left: '-1.5rem',
                    gap: '1.6rem',
                    marginTop: '0rem',
                    display: 'flex',
                    flexFlow: 'wrap',
                  }}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <CenterAlignBox sx={{ position: 'relative', width: '100%' }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '1.6rem',
                        color: theme.palette.common.black,
                      }}
                    >
                      {label}
                    </Typography>
                    <IconButton
                      sx={{
                        position: 'absolute',
                        right: 0,
                        // width: '14rem',
                        // height: '4rem',
                        fontSize: '2rem',
                        fontWeight: 600,
                        // backgroundColor: '#505050',
                        borderRadius: '0px',

                        '& .typo': {
                          fontSize: '2rem',
                          fontWeight: 600,
                          color: theme.palette.common.white,
                        },
                        '&:hover': {
                          backgroundColor: 'white',
                          '& .typo': {
                            background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                            WebkitBackgroundClip: 'text',
                            backgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            textFillColor: 'transparent',
                          },
                        },
                      }}
                      onClick={() => {
                        setSelectedImages(null)
                        setImageSelectMode(false)
                      }}
                    >
                      <DownArrowIcon />
                    </IconButton>
                  </CenterAlignBox>

                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexFlow: 'wrap',
                      gap: '1rem',
                      rowGap: '2.4rem',
                    }}
                  >
                    {selectArray.map(opt => {
                      return (
                        <SelectImageCardRegen
                          key={opt.value}
                          option={opt}
                          selectedImages={selectedImages}
                          selected={promptValues[selectedImages] === opt.value}
                          handleImageSelectChange={handleImageSelectChange}
                        />
                      )
                    })}
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>
        </Box>
      </Box>

      <AnimatePresence>
        {!imageSelectMode && (
          <motion.div
            className="button-list"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              gap: '0.4rem',
              marginTop: '1.2rem',
              display: 'flex',
              flexFlow: 'wrap',
            }}
          >
            {buttonList.map((button, index) => (
              <ToggleButtonRegen
                key={index}
                value={button.value}
                selected={selectedPrompt.includes(button.value)}
                onClick={() => handleToggle(button.value)}
                disabled={button.required}
              >
                {button.label}
                {!button.required && (
                  <Box sx={{ ml: '0.5rem' }}>
                    {selectedPrompt.includes(button.value) ? (
                      <MinusIcon
                        style={{ width: '0.9rem', height: '0.9rem' }}
                        color={theme.palette.common.white}
                      />
                    ) : (
                      <PlusIcon
                        style={{ width: '0.9rem', height: '0.9rem' }}
                        color={theme.palette.common.black}
                      />
                    )}
                  </Box>
                )}
              </ToggleButtonRegen>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  )
}

const PromptContentRendererRegen = ({
  promptType,
  options,
  value,
  customValue,
  label,
  handleSelectChange,
  handleDelete,
  templateType,
  isRequired,
  imageSelectMode,
  setImageSelectMode,
  valueLabel,
  setSelectedImages,
}) => {
  const menuProps = {
    PaperProps: {
      style: {
        borderRadius: '10px',
      },
    },
  }

  const theme = useTheme()

  const customInputRef = useRef(null)
  const { i18n, t } = useTranslation()

  useEffect(() => {
    if (value === 'custom' && customInputRef.current) {
      customInputRef.current.focus()
    }
  }, [value])

  const renderContent = () => {
    switch (promptType) {
      case 'gender':
        return (
          <>
            {/* <Typography className="typo">모델 성별은 </Typography> */}
            <CustomSelectRegen
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelectRegen>
            {/* <Typography className="typo">입니다.</Typography> */}
          </>
        )

      case 'nation':
        return (
          <CustomSelectRegen
            value={value}
            onChange={e => handleSelectChange(e, promptType)}
            sx={{ mr: '0.8rem' }}
            required={isRequired}
            MenuProps={menuProps}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {isKo(i18n) ? option.label : option.value}
              </MenuItem>
            ))}
          </CustomSelectRegen>
        )

      case 'product':
        return (
          <>
            <Typography className="typo">{`${label}는`}</Typography>
            <Box
              sx={{
                position: 'relative',
                height: '2rem',
                animation:
                  customValue === null && value === 'custom'
                    ? `${shakeAnimation} 0.4s ease-in-out 0s 3`
                    : 'none',
              }}
            >
              <CustomSelectRegen
                value={value}
                onChange={e => handleSelectChange(e, promptType)}
                sx={{
                  mx: '0.8rem',
                  animation:
                    value === 'custom' ? `${shakeAnimation} 0.4s ease-in-out 0s 3` : 'none',
                }}
                required={isRequired}
                custom={Boolean(value === 'custom')}
                MenuProps={menuProps}
              >
                {options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {isKo(i18n) ? option.label : option.value}
                  </MenuItem>
                ))}
              </CustomSelectRegen>

              {value === 'custom' && (
                <Box
                  sx={{
                    display: 'inline-block',
                    minWidth: '5rem',
                    position: 'absolute',
                    left: '0.8rem',
                    top: '0.1rem',
                    animation: `${shakeAnimation} 0.4s ease-in-out 0s 3`,
                  }}
                >
                  <CustomTextFieldRegen
                    inputRef={customInputRef}
                    value={customValue}
                    required={isRequired}
                    onChange={e => handleSelectChange(e, `${promptType}Custom`)}
                    variant="outlined"
                    size="small"
                    placeholder={isKo(i18n) ? '직접 입력' : 'Please complete it.'}
                    fullWidth
                    InputProps={{
                      sx: {
                        width: 'auto',
                        p: 0,
                        '& input': {
                          // width: '100%',
                          minWidth: '5rem',
                          boxSizing: 'border-box',
                          p: 0,
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>

            <Typography className="typo">입니다.</Typography>
          </>
        )
      case 'background':
        return (
          <>
            <Box
              sx={{
                position: 'relative',
                animation:
                  customValue === null && value === 'custom'
                    ? `${shakeAnimation} 0.4s ease-in-out 0s 3`
                    : 'none',
              }}
            >
              {/* <Box
                sx={{
                  backgroundColor: isRequired
                    ? theme.palette.draph.blue
                    : theme.palette.common.white,
                  borderRadius: '10px',
                  minWidth: value === 'custom' ? '26.4rem' : 'none',
                  fontSize: '2rem',
                  fontWeight: 700,
                  height: '4rem',
                  color: isRequired ? theme.palette.common.white : theme.palette.draph.blue,
                  width: 'auto',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  px: '1.6rem',
                  mx: '0.8rem',
                }}
                onClick={() => {
                  setImageSelectMode(p => !p)
                  setSelectedImages(promptType)
                }}
              >
                {valueLabel}
              </Box> */}
              <CustomSelectRegen
                value={value}
                onChange={e => handleSelectChange(e, promptType)}
                sx={{ mr: '0.8rem', ...(value === 'custom' && { width: '21rem' }) }}
                required={isRequired}
                disabled={true}
                onClick={() => {
                  setImageSelectMode(p => !p)
                  setSelectedImages(promptType)
                }}
                MenuProps={menuProps}
              >
                {options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {isKo(i18n) ? option.label : option.value}
                  </MenuItem>
                ))}
              </CustomSelectRegen>
              {value === 'custom' && (
                <Box
                  sx={{
                    display: 'inline-block',
                    position: 'absolute',
                    left: 0,
                    top: '0.13rem',
                    animation: `${shakeAnimation} 0.4s ease-in-out 0s 3`,
                  }}
                >
                  <CustomTextFieldComponentRegen2
                    inputRef={customInputRef}
                    value={customValue}
                    required={isRequired}
                    onChange={e => handleSelectChange(e, `${promptType}Custom`)}
                    variant="outlined"
                    size="small"
                    placeholder={isKo(i18n) ? '직접 입력' : 'Please complete it.'}
                    fullWidth
                    InputProps={{
                      sx: {
                        width: 'auto',
                        p: 0,
                        '& input': {
                          width: '100%',
                          minWidth: '17rem',
                          boxSizing: 'border-box',
                          p: 0,
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
            <Typography className="typo">배경</Typography>
          </>
        )

      case 'style':
        return (
          <>
            <CustomSelectRegen
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelectRegen>
            <Typography className="typo">스타일</Typography>
          </>
        )

      case 'lightning':
        return (
          <>
            <CustomSelectRegen
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelectRegen>
            <Typography className="typo">조명스타일</Typography>
          </>
        )

      case 'camera':
        return (
          <>
            <CustomSelectRegen
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelectRegen>
            <Typography className="typo">로 촬영된 사진</Typography>
          </>
        )

      case 'film':
        return (
          <>
            <CustomSelectRegen
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelectRegen>
            <Typography className="typo">필름 스타일</Typography>
          </>
        )
      case 'lens':
        return (
          <>
            <CustomSelectRegen
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mr: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
              disabled={true}
              onClick={() => {
                setImageSelectMode(p => !p)
                setSelectedImages(promptType)
              }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelectRegen>
            <Typography className="typo">으로 촬영된 사진</Typography>
          </>
        )
      case 'tone':
        return (
          <>
            {' '}
            <Box
              sx={{
                position: 'relative',
                height: '2rem',
                animation:
                  customValue === null && value === 'custom'
                    ? `${shakeAnimation} 0.4s ease-in-out 0s 3`
                    : 'none',
              }}
            >
              <CustomSelectRegen
                value={value}
                onChange={e => handleSelectChange(e, promptType)}
                sx={{
                  mr: '0.8rem',
                  animation:
                    value === 'custom' ? `${shakeAnimation} 0.4s ease-in-out 0s 3` : 'none',
                }}
                required={isRequired}
                custom={Boolean(value === 'custom')}
                MenuProps={menuProps}
              >
                {options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {isKo(i18n) ? option.label : option.value}
                  </MenuItem>
                ))}
              </CustomSelectRegen>

              {value === 'custom' && (
                <Box
                  sx={{
                    display: 'inline-block',
                    minWidth: '5rem',
                    position: 'absolute',
                    left: '0rem',
                    top: '0.1rem',
                    animation: `${shakeAnimation} 0.4s ease-in-out 0s 3`,
                  }}
                >
                  <CustomTextFieldRegen
                    inputRef={customInputRef}
                    value={customValue}
                    required={isRequired}
                    onChange={e => handleSelectChange(e, `${promptType}Custom`)}
                    variant="outlined"
                    size="small"
                    placeholder={isKo(i18n) ? '직접 입력' : 'Please complete it.'}
                    fullWidth
                    InputProps={{
                      sx: {
                        width: 'auto',
                        p: 0,
                        '& input': {
                          // width: '100%',
                          minWidth: '5rem',
                          boxSizing: 'border-box',
                          p: 0,
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
            <Typography className="typo">{label}</Typography>
          </>
        )

      case 'custom':
        return (
          <>
            {' '}
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  display: 'inline-block',
                  minWidth: '10rem',
                  // position: 'absolute',
                  left: '0.8rem',
                }}
              >
                <CustomTextFieldComponent
                  ref={customInputRef}
                  value={customValue}
                  required={isRequired}
                  onChange={e => handleSelectChange(e, `custom`)}
                  placeholder={isKo(i18n) ? '배경 정보를 입력해주세요.' : 'Please complete it.'}
                  minWidth="29rem"
                  maxWidth="29rem"
                  fontSize="1.2rem"
                  minHeight="2rem"
                  textColor={theme.palette.common.white}
                  bgColor={theme.palette.draph.blue}
                  borderRadius={'6px'}
                  sx={{ pr: 0 }}
                />
              </Box>
            </Box>
          </>
        )
      default:
        return (
          <>
            <Typography className="typo">{`${label}은(는)`}</Typography>
            <CustomSelectRegen
              value={value}
              onChange={e => handleSelectChange(e, promptType)}
              sx={{ mx: '0.8rem' }}
              required={isRequired}
              MenuProps={menuProps}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {isKo(i18n) ? option.label : option.value}
                </MenuItem>
              ))}
            </CustomSelectRegen>
            <Typography className="typo">입니다</Typography>
          </>
        )
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        // height: '4rem',
        '& .typo': { color: theme.palette.common.black, fontSize: '1.2rem', fontWeight: 700 },
      }}
    >
      {renderContent()}
      {!isRequired && (
        <IconButton onClick={() => handleDelete(promptType)} sx={{ p: '0.6rem' }} disableRipple>
          <CloseIcon sx={{ width: '0.8rem', height: '0.8rem' }} color="#808080" />
        </IconButton>
      )}
    </Box>
  )
}

const CustomSelectRegen = styled(Select)(({ theme, required, custom }) => ({
  backgroundColor: required ? theme.palette.draph.blue : theme.palette.common.black,
  borderRadius: '6px',
  minWidth: custom ? '14rem' : 'none',
  fontSize: '1.2rem',
  fontWeight: 700,
  height: '2rem',
  color: theme.palette.common.white,
  padding: 0,
  width: 'auto',
  '& .MuiSelect-select': {
    padding: '0 3.2rem 0 1.2rem !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& svg': {
    width: '0.8rem',
    height: '0.96rem',
  },

  '& path': {
    stroke: `${theme.palette.common.white} !important`,
  },

  '& .Mui-disabled': {
    color: theme.palette.common.white,
    '-webkit-text-fill-color': theme.palette.common.white,
  },
}))

const CustomTextFieldRegen = styled(TextField)(({ theme, required }) => ({
  '& .MuiInputBase-root': {
    height: '2rem',
    fontSize: '1.2rem',
    fontWeight: 700,
    backgroundColor: required ? theme.palette.draph.blue : theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: '6px',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  width: '12rem',
}))

const SelectImageCardRegen = ({
  option,
  selectedImages,
  selected,
  handleImageSelectChange,
  regenMode = false,
}) => {
  const gradientBackground = 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)'
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const [hover, setHover] = useState(false)

  const handleClick = e => {
    e.stopPropagation() // 이벤트 전파 중단
    handleImageSelectChange(option.value, selectedImages)
  }

  return (
    <Stack
      value={option.value}
      sx={{
        borderRadius: '5px',
        width: 'calc((100% - 1rem) / 2)',
        maxHeight: '25.6rem',
        cursor: 'pointer',
        position: 'relative',
        '& .image-box': {
          width: '100%',
          height: '21.6rem',
          objectFit: 'cover',
          borderRadius: '5px 5px 0 0 ',

          '&.hover': {
            filter: 'blur(1px)',
          },
        },

        '& .image-title-box': {
          width: '100%',
          borderRadius: '0 0 5px 5px',
          backgroundColor: theme.palette.common.black,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '4rem',

          '& .title-text': {
            fontWeight: 600,
            fontSize: '1.4rem',
            color: theme.palette.common.white,
          },
          '&.selected': {
            backgroundColor: theme.palette.draph.blue,
          },
        },

        '& .info-stack': {
          position: 'absolute',
          top: '0rem',
          left: '0rem',
          backgroundColor: 'rgba(0,0,0,0.5)',
          width: '100%',
          minHeight: '100%',
          borderRadius: '5px 5px 0 0 ',
          p: '1rem',
          justifyContent: option.suggestKo ? 'space-between' : 'flex-end',

          '& .suggestion-title': {
            width: 'fit-content',
            borderRadius: '4px',
            p: '0.2rem 0.4rem',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            color: theme.palette.common.white,
            fontWeight: 600,
            fontSize: '1rem',
          },

          '& .suggestion-notice': {
            width: '100%',
            mt: '0.4rem',
            color: theme.palette.common.white,
            fontWeight: 400,
            fontSize: '1rem',
          },
        },
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      onClick={handleClick}
    >
      <img className={`image-box ${hover ? 'hover' : ''}`} src={option.src} />

      <Stack className={`image-title-box ${selected ? 'selected' : ''}`}>
        <Typography className="title-text">{isKo(i18n) ? option.label : option.value}</Typography>
      </Stack>

      <AnimatePresence>
        {hover && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              minHeight: 'calc(100% - 4rem)',
            }}
          >
            <Stack className="info-stack">
              {option.suggestKo && (
                <Box className="suggestion">
                  <CenterAlignBox className="suggestion-title">
                    <Korean>추천 제품 카테고리</Korean>
                    <English>Recommended</English>
                  </CenterAlignBox>
                  <CenterAlignBox className="suggestion-notice">
                    {isKo(i18n) ? option.suggestKo : option.suggestEn}
                  </CenterAlignBox>
                </Box>
              )}

              <Typography
                className="suggestion-title"
                sx={{ p: '0.8rem !important', fontWeight: '400 !important' }}
              >
                {isKo(i18n) ? option.bodyKo : option.bodyEn}
              </Typography>
            </Stack>
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  )
}
