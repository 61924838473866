import { Box, Button, Divider } from '@mui/material'
import { useState } from 'react'
import { MinusIcon, PlusIcon, PlusIcon2, PlusMobileIcon, ZoomInIcon, ZoomOutIcon } from 'theme/icon'

export function ZoomInOutButton({ clickZoomIn = () => {}, clickZoomOut = () => {} }) {
  const [hover, setHover] = useState(false)

  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        bottom: '2rem',
        right: 'calc(50% - 5rem)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        width: '10rem',
        height: '4rem',
        transition: 'background-color 0.3s ease-in',
        cursor: 'pointer',
        borderRadius: '30px',
        zIndex: 1,
        boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.1)',

        '&:hover': {
          backgroundColor: 'rgba(255,255,255)',
        },

        '& .zoomButton': {
          width: '5rem',
          height: '4rem',
          minWidth: 'auto',

          '&:hover': {
            backgroundColor: 'transparent',
          },

          '&.left': {
            borderTopRightRadius: '30px',
            borderBottomRightRadius: '30px',
            padding: '0 1.6rem 0 1rem',

            '&:hover:not(:disabled)': {
              '& path': {
                fill: 'rgba(44, 77, 255, 1)',
              },
            },
          },

          '&.right': {
            borderTopLeftRadius: '30px',
            borderBottomLeftRadius: '30px',
            padding: '0 1rem 0 1.6rem',

            '&:hover:not(:disabled)': {
              '& path': {
                fill: 'rgba(44, 77, 255, 1)',
              },
            },
          },

          '& path': {
            fill: hover ? 'rgba(48, 48, 48, 1)' : 'white',
            transition: 'fill 0.3s ease-in-out',
          },

          '&:disabled path': {
            fill: 'rgba(187, 187, 187, 1)',
          },
        },
      }}
      onMouseOver={() => {
        setHover(true)
      }}
      onMouseOut={() => {
        setHover(false)
      }}
    >
      <Button className="zoomButton right" disableTouchRipple onClick={clickZoomOut}>
        <ZoomOutIcon />
      </Button>
      <Divider
        flexItem
        orientation="vertical"
        sx={{
          height: '1.6rem',
          alignSelf: 'center',
          borderColor: hover ? 'rgba(48, 48, 48, 1)' : 'rgba(238, 238, 238, 1)',
          transition: 'border-color 0.3s ease-in',
        }}
      />
      <Button className="zoomButton left" disableTouchRipple onClick={clickZoomIn}>
        <ZoomInIcon />
      </Button>
    </Box>
  )
}
