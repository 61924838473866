import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom'
// material
import { styled, useTheme } from '@mui/material/styles'

import { CenterAlignBox, CenterAlignStack, TempLayoutDiv } from 'components'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { MypagesMenu, MypagesRouterLink } from './MypagesSideBar'
import { APPBAR_DESKTOP } from './main/MainAppBar'
import { Korean } from 'hooks/useLanguage'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

export const MOBILE_SECOND_APPBAR = '4.9rem'

const RootStyle = styled('div')(({ theme }) => ({
  width: 0,
  height: 0,
  position: 'relative',
}))

// ----------------------------------------------------------------------

export default function InfoSideBar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const styledList = { fontSize: '14px' }

  return (
    <>
      <Desktop>
        <MypagesMenu sx={{ height: 'fit-content', minWidth: '20rem' }}>
          <Stack sx={{ height: '100%' }}>
            <MypagesRouterLink
              to="/board"
              className={pathname === '/board' && 'selected'}
              sx={{ background: '#fff' }}
            >
              {t('info_side_menu.notice')}
            </MypagesRouterLink>

            <MypagesRouterLink
              to="/guide"
              className={pathname === '/guide' && 'selected'}
              sx={{ background: '#fff' }}
            >
              {t('info_side_menu.guide')}
            </MypagesRouterLink>

            <MypagesRouterLink
              to="/FAQ"
              className={pathname === '/FAQ' && 'selected'}
              sx={{ background: '#fff' }}
            >
              {t('info_side_menu.faq')}
            </MypagesRouterLink>
            <MypagesRouterLink
              to="/inquiry"
              className={pathname === '/inquiry' && 'selected'}
              sx={{ background: '#fff' }}
            >
              {t('info_side_menu.inquiry')}
            </MypagesRouterLink>
          </Stack>
        </MypagesMenu>
      </Desktop>

      <Mobile>
        <CenterAlignBox
          sx={{
            width: '100%',
            px: '0.5rem',
            height: MOBILE_SECOND_APPBAR,
            alignItems: 'center',
            justifyContent: 'space-around',
            position: 'sticky',
            top: '5.2rem',
            borderBottom: '1px solid black',
            backgroundColor: theme.palette.common.white,
            zIndex: 1,
          }}
        >
          <Typography
            sx={{
              ...styledList,
              fontWeight: pathname === '/board' ? 800 : 700,
              color: pathname === '/board' ? theme.palette.draph.blue : theme.palette.common.black,
            }}
          >
            <RouterLink to="/board" style={{ color: 'inherit', textDecoration: 'none' }}>
              {t('info_side_menu.notice')}
            </RouterLink>
          </Typography>
          <Typography
            sx={{
              ...styledList,
              fontWeight: pathname === '/guide' ? 800 : 700,
              color: pathname === '/guide' ? theme.palette.draph.blue : theme.palette.common.black,
            }}
          >
            <RouterLink to="/guide" style={{ color: 'inherit', textDecoration: 'none' }}>
              드랩 가이드
            </RouterLink>
          </Typography>
          <Typography
            sx={{
              ...styledList,
              fontWeight: pathname === '/FAQ' ? 800 : 700,
              color: pathname === '/FAQ' ? theme.palette.draph.blue : theme.palette.common.black,
            }}
          >
            <RouterLink to="/FAQ" style={{ color: 'inherit', textDecoration: 'none' }}>
              {t('info_side_menu.faq')}
            </RouterLink>
          </Typography>

          <Typography
            sx={{
              ...styledList,
              fontWeight: pathname === '/inquiry' ? 800 : 700,
              color:
                pathname === '/inquiry' ? theme.palette.draph.blue : theme.palette.common.black,
            }}
          >
            <RouterLink to="/inquiry" style={{ color: 'inherit', textDecoration: 'none' }}>
              {t('info_side_menu.inquiry')}
            </RouterLink>
          </Typography>
        </CenterAlignBox>
        {/* <Divider flexItem sx={{ backgroundColor: 'black' }} /> */}
      </Mobile>
    </>
  )
}
