import {
  dateFilterAtom,
  dateFilterOnAtom,
  portfolioAtom,
  searchPortfolioAtom,
  portfolioArtworkAtom,
  artworkViewConfigAtom,
  userAtom,
  portfolioDetailAtom,
  backgroundNameFilterAtom,
  backgroundAtom,
  backgroundFilterAtom,
  artworkDataAtom,
  portfolioConfigAtom,
  creditPolicyAtom,
  searchUserPortfolioAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  uploadOptionAtom,
  guestPortfolioAtom,
} from 'atoms'
import { atom, selector, useRecoilState } from 'recoil'
import moment from 'moment'
import 'moment-timezone'
import { shuffle } from 'lodash'
import { arrayChangeIdx, sortPieces } from 'utils/common'
import { portfolioTypeAtom } from './atoms'
import * as conf from 'config'

export const filteredPortfolioSelector = selector({
  key: 'filteredPortfolio',
  get: ({ get }) => {
    const pAtom = get(portfolioAtom)
    const dateRange = get(dateFilterAtom)
    const dateFilterOn = get(dateFilterOnAtom)
    const searchPortfolio = get(searchPortfolioAtom)
    const searchUserPortfolio = get(searchUserPortfolioAtom)

    const user = get(userAtom)

    if (!pAtom) return null

    let sorted
    if (user.is_admin) {
      sorted = [...pAtom].sort(
        (a, b) => new Date(b.latest_artwork_created) - new Date(a.latest_artwork_created)
      )
    } else {
      // p.created 는 UTC 라서 local timezone으로 수정
      sorted = [...pAtom].sort((a, b) => new Date(b.created) - new Date(a.created))
    }

    const portfolios = sorted.map(p => {
      if (p.created) {
        const utcDate = moment.utc(p.created).toDate()
        const localDate =
          moment(utcDate).local().format('YYYY-MM-DDTHH:mm ') +
          moment.tz(moment.tz.guess()).zoneName()

        return {
          ...p,
          created: localDate,
        }
      }

      return p
    })

    if (!portfolios.length) {
      return []
    }

    const filteredByDate = dateFilterOn
      ? portfolios.filter(p => {
          const portfolioDate = new Date(p.created.slice(0, 10))
          const startDate = dateRange[0].startDate.setHours(0, 0, 0, 0)
          const endDate = dateRange[0].endDate.setHours(23, 59, 59, 999)
          return portfolioDate - startDate >= 0 && endDate - portfolioDate >= 0
        })
      : portfolios

    const filteredByName = searchPortfolio
      ? filteredByDate.filter(p => p.name.includes(searchPortfolio))
      : filteredByDate

    const filteredByUser = searchUserPortfolio
      ? filteredByName.filter(
          p =>
            p.user_id.toString() === searchUserPortfolio || p.username.includes(searchUserPortfolio)
        )
      : filteredByName

    return filteredByUser
  },
})

export const groupArtworkByDateSelector = selector({
  // 날짜로 묶어 최근순 정렬
  key: 'groupArtworkByDate',
  get: ({ get }) => {
    const artworkList = get(portfolioArtworkAtom)
    const group = {}
    artworkList.forEach(a => {
      const date = a.created.slice(0, 10)
      if (!group[date]) {
        group[date] = []
      }
      group[date].push(a)
    })

    const sorted = Object.keys(group)
      .sort((a, b) => new Date(b) - new Date(a))
      .reduce((obj, key) => {
        obj[key] = group[key]
        return obj
      }, {})

    return sorted
  },
})

const genGroupKey = (config, keyConfigs) => {
  // 아트워크 그룹화 기준
  // const keyConfigs = [
  //   'object_category',
  //   'object_sub_category',
  //   'gen_face',
  //   'gen_shadow',
  //   'object_boundary',
  //   'theme_template',
  //   'theme_custom',
  // ]

  let keyString = ''
  keyConfigs.forEach((s, i) => {
    if (i > 0) {
      keyString += `_${config[s]}`
    } else {
      keyString += config[s]
    }
  })
  return keyString.replace(/ /g, '_')
}

export const artworkPagingSelector = selector({
  key: 'artworkPaging',
  get: ({ get }) => {
    const artworkList = get(sortedPiecesArtworkListSelector)
    if (!artworkList) return null

    const viewConfig = get(artworkViewConfigAtom)
    let l = artworkList

    if (viewConfig.filters.downloaded === 'downloaded') {
      l = l.filter(a => a.download > 0)
    } else if (viewConfig.filters.downloaded === 'notDownloaded') {
      l = l.filter(a => a.download < 1)
    }

    if (viewConfig.filters.name) {
      l = l.filter(a => a.name.includes(viewConfig.filters.name))
    }

    l = l.reduce(
      (r, e, i) => (i % viewConfig.pageSize ? r[r.length - 1].push(e) : r.push([e])) && r,
      []
    )

    const groupList = []

    l.forEach((pageData, idx) => {
      const group = {}
      let currentKeyString = ''
      let currentKeyIndex = -1

      const bannerKeyConfigs = ['humanDepict', 'bgDepict', 'brandFeature', 'productFeature']

      const defaulKeyConfigs = [
        'object_category',
        'object_sub_category',
        'gen_face',
        'gen_shadow',
        'object_boundary',
        'theme_template',
        'theme_custom',
        'flag_complex_cmp',
        'flag_generate',
      ]

      pageData.forEach((a, i) => {
        const config = a.config ? JSON.parse(a?.config) : {}
        const isBanner = config.artwork_type === 'banner'

        const keyString = config
          ? genGroupKey(config, isBanner ? bannerKeyConfigs : defaulKeyConfigs)
          : 'none'

        if (currentKeyString !== keyString) {
          currentKeyIndex += 1
          currentKeyString = keyString
          group[`${currentKeyIndex}_${currentKeyString}`] = []
        }
        const aa = { ...a, idxInPage: i }
        group[`${currentKeyIndex}_${currentKeyString}`].push(aa)
      })

      groupList.push(group)
    })

    return groupList
  },
})

export const isPortfolioOwnerSelector = selector({
  key: 'isPortfolioOwner',
  get: ({ get }) => {
    const user = get(userAtom)
    const portfolioDetail = get(portfolioDetailAtom)
    return user.id === portfolioDetail.user_id
  },
})

export const portfolioUploadConfigSelector = selector({
  key: 'portfolioUploadConfig',
  get: ({ get }) => {
    const d = get(portfolioDetailAtom)

    const portfolioType = get(portfolioTypeAtom)
    const isRemoveBg = portfolioType === conf.PORTFOLIO_TYPE_REMOVEBG

    let config = {
      gen_shadow: true,
      gen_face: true,
      object_category: 'auto',
      object_sub_category: 'auto',
      object_boundary: 'none',
      object_angle: 'auto',
      model_gender: 'auto',
      flag_generate: true,
      flag_complex_cmp: true,
      flag_simple_cmp: true, // 단색
      flag_white_cmp: true, // 누끼
      output_size_w: 0,
      output_size_h: 0, // 0 means auto
      flag_gen_compo: false,
      flag_bg_expansion: false,
      flag_multiblob_sod: false,
      selected_bg_ids: '',

      flag_human_background: false,
      SO_length_scale: 'auto',

      flag_facemorphing: false,
      facemorphing_race: 'asian',
      facemorphing_gender: 'none',

      theme: '',
      theme_background: '',
      theme_template: 'auto',
      theme_custom: '',

      output_size_list: [],
      simple_bg_color_list: PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }
    const EXCEPT_LIST = [
      'genShadow',
      'genFace',
      'category',
      'subCategory',
      'objectBoundary',
      'objectAngle',
      'bgGenerate',
      'bgComplex',
      'bgSimple',
      'bgWhite',
      'flagGenCompo',
      'flagBgExpansion',
      'flagMultiblobSod',
      'flagHumanBackground',
      'SOLengthScale',
      'flagFacemorphing',
      'facemorphingRace',
      'facemorphingGender',
      'outputWidth',
      'outputHeight',
      'theme',
      'theme_background',
      'theme_template',
      'theme_custom',
      'outputSizeList',
      'simpleBgColorList',
      'name',
      'modelGender',
      'saveOutputConfig',
      'bg_expansion_bbox',
    ]
    if (d.config) {
      // 있는것 제외한 것 필터링 후 더해줌
      const filteredConfig = Object.keys(d.config).reduce((acc, key) => {
        if (!EXCEPT_LIST.includes(key)) {
          acc[key] = d.config[key]
        }
        return acc
      }, {})

      config = {
        gen_shadow: d.config.genShadow,
        gen_face: d.config.genFace,
        object_category: d.config.category ?? 'auto',
        object_sub_category: d.config.subCategory ?? 'auto',
        object_boundary: d.config.objectBoundary ?? 'none',
        object_angle: d.config.objectAngle ?? 'auto',
        flag_generate: d.config.bgGenerate,
        flag_complex_cmp: d.config.bgComplex,
        flag_simple_cmp: d.config.bgSimple,
        flag_white_cmp: d.config.bgWhite,

        flag_gen_compo: d.config.flagGenCompo ?? false,
        flag_bg_expansion: d.config.flagBgExpansion ?? false,
        flag_multiblob_sod: d.config.flagMultiblobSod ?? false,

        flag_human_background: d.config.flagHumanBackground ?? false,
        SO_length_scale: d.config.SOLengthScale ?? 'auto',

        flag_facemorphing: d.config.flagFacemorphing ?? false,
        facemorphing_race: d.config.facemorphingRace ?? 'asian',
        facemorphing_gender: d.config.facemorphingGender ?? 'none',

        output_size_w: d.config.outputWidth ?? 0,
        output_size_h: d.config.outputHeight ?? 0,

        selected_bg_ids: '',

        theme: '',
        theme_background: '',
        theme_template: d.config.theme_template ?? 'auto',
        theme_custom: d.config.theme_custom ?? '',
        bg_expansion_bbox: d.config.bg_expansion_bbox ?? [],

        output_size_list: d.config.outputSizeList
          ? d.config.outputSizeList.map(o => ({ output_w: o.w, output_h: o.h }))
          : [],
        simple_bg_color_list:
          d.config.simpleBgColorList ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
        ...filteredConfig,
      }
    }
    const DEFAULT_BGREMOVE_CONFIG = {
      gen_shadow: true,
      gen_face: false,
      object_category: 'auto',
      object_sub_category: 'auto',
      object_boundary: 'none',
      object_angle: 'auto',
      model_gender: 'auto',
      flag_generate: false,
      flag_complex_cmp: false,
      flag_simple_cmp: false,
      flag_white_cmp: true,
      output_size_w: 0,
      output_size_h: 0, // 0 means auto
      flag_gen_compo: false,
      flag_bg_expansion: false,
      flag_multiblob_sod: false,
      selected_bg_ids: '',

      flag_human_background: false,
      SO_length_scale: 'auto',

      flag_facemorphing: false,
      facemorphing_race: 'asian',
      facemorphing_gender: 'none',

      theme: '',
      theme_background: '',
      theme_template: 'auto',
      theme_custom: '',

      output_size_list: [],
      simple_bg_color_list: PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }

    const DEFAULT_BGEXPANSION_CONFIG = {
      gen_shadow: true,
      gen_face: false,
      object_category: 'auto',
      object_sub_category: 'auto',
      object_boundary: 'none',
      object_angle: 'auto',
      model_gender: 'auto',
      flag_generate: true,
      flag_complex_cmp: true,
      flag_simple_cmp: true,
      flag_white_cmp: true,
      output_size_w: 0,
      output_size_h: 0, // 0 means auto
      flag_gen_compo: false,
      flag_bg_expansion: true,
      flag_multiblob_sod: false,
      selected_bg_ids: '',

      flag_human_background: false,
      SO_length_scale: 'auto',

      flag_facemorphing: false,
      facemorphing_race: 'asian',
      facemorphing_gender: 'none',

      theme: '',
      theme_background: '',
      theme_template: 'auto',
      theme_custom: '',

      output_size_list: [],
      simple_bg_color_list: PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }

    let returnConfig = config
    if (isRemoveBg) {
      returnConfig = DEFAULT_BGREMOVE_CONFIG
    } else if (portfolioType === conf.PORTFOLIO_TYPE_BGEXPANSION) {
      returnConfig = {
        ...DEFAULT_BGEXPANSION_CONFIG,
        output_size_w: d.config?.outputWidth ?? 0,
        output_size_h: d.config?.outputHeight ?? 0,
        output_size_list: d.config?.outputSizeList
          ? d.config.outputSizeList.map(o => ({ output_w: o.w, output_h: o.h }))
          : [],
      }
    }
    return returnConfig
  },
})

export const guestPortfolioUploadConfigSelector = selector({
  key: 'guestPortfolioUploadConfig',
  get: ({ get }) => {
    const d = get(guestPortfolioAtom)

    const portfolioType = get(portfolioTypeAtom)
    const isRemoveBg = portfolioType === conf.PORTFOLIO_TYPE_REMOVEBG

    let config = {
      gen_shadow: true,
      gen_face: true,
      object_category: 'auto',
      object_sub_category: 'auto',
      object_boundary: 'none',
      object_angle: 'auto',
      model_gender: 'auto',
      flag_generate: true,
      flag_complex_cmp: true,
      flag_simple_cmp: true, // 단색
      flag_white_cmp: true, // 누끼
      output_size_w: 0,
      output_size_h: 0, // 0 means auto
      flag_gen_compo: false,
      flag_bg_expansion: false,
      flag_multiblob_sod: false,
      selected_bg_ids: '',

      flag_human_background: false,
      SO_length_scale: 'auto',

      flag_facemorphing: false,
      facemorphing_race: 'asian',
      facemorphing_gender: 'none',

      theme: '',
      theme_background: '',
      theme_template: 'auto',
      theme_custom: '',

      output_size_list: [],
      simple_bg_color_list: PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }
    const EXCEPT_LIST = [
      'genShadow',
      'genFace',
      'category',
      'subCategory',
      'objectBoundary',
      'objectAngle',
      'bgGenerate',
      'bgComplex',
      'bgSimple',
      'bgWhite',
      'flagGenCompo',
      'flagBgExpansion',
      'flagMultiblobSod',
      'flagHumanBackground',
      'SOLengthScale',
      'flagFacemorphing',
      'facemorphingRace',
      'facemorphingGender',
      'outputWidth',
      'outputHeight',
      'theme',
      'theme_background',
      'theme_template',
      'theme_custom',
      'outputSizeList',
      'simpleBgColorList',
      'name',
      'modelGender',
      'saveOutputConfig',
      'bg_expansion_bbox',
    ]
    if (d.config) {
      // 있는것 제외한 것 필터링 후 더해줌
      const filteredConfig = Object.keys(d.config).reduce((acc, key) => {
        if (!EXCEPT_LIST.includes(key)) {
          acc[key] = d.config[key]
        }
        return acc
      }, {})

      config = {
        gen_shadow: d.config.genShadow,
        gen_face: d.config.genFace,
        object_category: d.config.category ?? 'auto',
        object_sub_category: d.config.subCategory ?? 'auto',
        object_boundary: d.config.objectBoundary ?? 'none',
        object_angle: d.config.objectAngle ?? 'auto',
        flag_generate: d.config.bgGenerate,
        flag_complex_cmp: d.config.bgComplex,
        flag_simple_cmp: d.config.bgSimple,
        flag_white_cmp: d.config.bgWhite,

        flag_gen_compo: d.config.flagGenCompo ?? false,
        flag_bg_expansion: d.config.flagBgExpansion ?? false,
        flag_multiblob_sod: d.config.flagMultiblobSod ?? false,

        flag_human_background: d.config.flagHumanBackground ?? false,
        SO_length_scale: d.config.SOLengthScale ?? 'auto',

        flag_facemorphing: d.config.flagFacemorphing ?? false,
        facemorphing_race: d.config.facemorphingRace ?? 'asian',
        facemorphing_gender: d.config.facemorphingGender ?? 'none',

        output_size_w: d.config.outputWidth ?? 0,
        output_size_h: d.config.outputHeight ?? 0,

        selected_bg_ids: '',

        theme: '',
        theme_background: '',
        theme_template: d.config.theme_template ?? 'auto',
        theme_custom: d.config.theme_custom ?? '',
        bg_expansion_bbox: d.config.bg_expansion_bbox ?? [],

        output_size_list: d.config.outputSizeList
          ? d.config.outputSizeList.map(o => ({ output_w: o.w, output_h: o.h }))
          : [],
        simple_bg_color_list:
          d.config.simpleBgColorList ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
        ...filteredConfig,
      }
    }
    const DEFAULT_BGREMOVE_CONFIG = {
      gen_shadow: true,
      gen_face: false,
      object_category: 'auto',
      object_sub_category: 'auto',
      object_boundary: 'none',
      object_angle: 'auto',
      model_gender: 'auto',
      flag_generate: false,
      flag_complex_cmp: false,
      flag_simple_cmp: false,
      flag_white_cmp: true,
      output_size_w: 0,
      output_size_h: 0, // 0 means auto
      flag_gen_compo: false,
      flag_bg_expansion: false,
      flag_multiblob_sod: false,
      selected_bg_ids: '',

      flag_human_background: false,
      SO_length_scale: 'auto',

      flag_facemorphing: false,
      facemorphing_race: 'asian',
      facemorphing_gender: 'none',

      theme: '',
      theme_background: '',
      theme_template: 'auto',
      theme_custom: '',

      output_size_list: [],
      simple_bg_color_list: PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }

    const DEFAULT_BGEXPANSION_CONFIG = {
      gen_shadow: true,
      gen_face: false,
      object_category: 'auto',
      object_sub_category: 'auto',
      object_boundary: 'none',
      object_angle: 'auto',
      model_gender: 'auto',
      flag_generate: true,
      flag_complex_cmp: true,
      flag_simple_cmp: true,
      flag_white_cmp: true,
      output_size_w: 0,
      output_size_h: 0, // 0 means auto
      flag_gen_compo: false,
      flag_bg_expansion: true,
      flag_multiblob_sod: false,
      selected_bg_ids: '',

      flag_human_background: false,
      SO_length_scale: 'auto',

      flag_facemorphing: false,
      facemorphing_race: 'asian',
      facemorphing_gender: 'none',

      theme: '',
      theme_background: '',
      theme_template: 'auto',
      theme_custom: '',

      output_size_list: [],
      simple_bg_color_list: PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }

    let returnConfig = config
    if (isRemoveBg) {
      returnConfig = DEFAULT_BGREMOVE_CONFIG
    } else if (portfolioType === conf.PORTFOLIO_TYPE_BGEXPANSION) {
      returnConfig = {
        ...DEFAULT_BGEXPANSION_CONFIG,
        output_size_w: d.config?.outputWidth ?? 0,
        output_size_h: d.config?.outputHeight ?? 0,
        output_size_list: d.config?.outputSizeList
          ? d.config.outputSizeList.map(o => ({ output_w: o.w, output_h: o.h }))
          : [],
      }
    }
    return returnConfig
  },
})

export const backgroundFilterSelector = selector({
  key: 'backgroundFilterSelected',
  get: ({ get }) => {
    const nameFilter = get(backgroundNameFilterAtom)
    // const filter = get(backgroundFilterAtom)
    const backgroundList = get(backgroundAtom)

    if (!backgroundList) return []

    let result = backgroundList?.filter(bg =>
      bg.name.toLowerCase().includes(nameFilter.toLowerCase())
    )

    // if (filter.user && !filter.pinned) {
    //   result = result.filter(bg => bg.own)
    // } else if (!filter.user && filter.pinned) {
    //   result = result.filter(bg => bg.pinned)
    // } else if (filter.user && filter.pinned) {
    //   result = result.filter(bg => bg.pinned || bg.own)
    // }

    if (result.length === 0) {
      result = null
    }

    return result
  },
})

export const showcaseImageSelector = selector({
  key: 'showcaseImage',
  get: ({ get }) => {
    const artworks = get(artworkDataAtom)

    const randomNum = num => {
      return Math.floor(Math.random() * num) // 0~10까지의 무작위 정수 반환
    }

    const result = []

    if (!artworks || artworks.length === 0) return

    while (result.length < 7) {
      for (const artwork of artworks) {
        let maxDownload = -Infinity
        let maxLastDownload = null
        let candidates = []

        for (const piece of artwork.pieces) {
          if (piece.download !== null && !result.some(r => r.pieces === piece)) {
            if (piece.download > maxDownload) {
              candidates = [piece]
              maxDownload = piece.download
              maxLastDownload = piece.last_download
            } else if (piece.download === maxDownload) {
              if (piece.last_download > maxLastDownload) {
                candidates = [piece]
                maxLastDownload = piece.last_download
              } else if (piece.last_download === maxLastDownload) {
                candidates.push(piece)
              }
            }
          }
        }
        if (candidates.length === 0) {
          candidates.push(...artwork.pieces)
        }
        const artworksCopy = { ...artwork, pieces: candidates[randomNum(candidates.length)] }
        result.push(artworksCopy)

        if (result.length >= 10) {
          break
        }
      }
    }

    return shuffle(result)
  },
})

export const portfolioBgTypeSelector = selector({
  key: 'portfolioBgTypeSelector',
  get: ({ get }) => {
    const portfolioConfig = get(portfolioConfigAtom)
    const bgTypes = [
      {
        title: '누끼(투명 배경)',
        name: 'bgWhite', // 그림자 없는 누끼 + 흰배경
        src: '/static/images/bg_example/example1.png',
        value: portfolioConfig.bgWhite,
      },
      {
        title: '단색/텍스쳐 배경',
        name: 'bgSimple', // 지금은 흰배경 + 그림자 있음
        src: '/static/images/bg_example/example2.png',
        // value: portfolioConfig.bgSimple,
        value: portfolioConfig.bgSimple,
      },
      {
        title: '생성 배경',
        name: 'bgGenerate',
        src: '/static/images/bg_example/example4.png',
        value: portfolioConfig.bgGenerate,
      },
      {
        title: '사진 배경',
        name: 'bgComplex',
        src: '/static/images/bg_example/example3.png',
        value: portfolioConfig.bgComplex,
      },
    ]

    return bgTypes
  },
})

export const userPortfolioNamesSelector = selector({
  key: 'userPortfolioNamesSelector',
  get: ({ get }) => {
    const portfolio = get(portfolioAtom)
    return portfolio?.map(p => p.name)
  },
})

export const creditPolicyDictSelector = selector({
  key: 'creditPolicyDictSelector',
  get: ({ get }) => {
    const cp = get(creditPolicyAtom)
    const d = {}
    cp.forEach(p => {
      d[p.type] = p.value
    })
    return d
  },
})

export const sortedPiecesArtworkListSelector = selector({
  key: 'sortedPiecesArtworkList',
  get: ({ get }) => {
    const artwork = get(portfolioArtworkAtom)
    if (!artwork) return null

    const sortedPiecesArtwork = artwork.map((a, index) => {
      const sorted = sortPieces(a)

      return { ...a, pieces: sorted }
    })

    return sortedPiecesArtwork
  },
})
