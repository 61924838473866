import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import MobileSwipeableDrawer from './MobileSwipeableDrawer'
import { useEffect, useState } from 'react'
import { apis } from 'apis'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export function PartnerPricingDialog({ open, setOpen, partner }) {
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': {
              width: '38rem',
              borderRadius: '1rem',
            },
          }}
        >
          <DialogContent sx={{ px: '2.2rem' }}>
            <Content partner={partner} />
          </DialogContent>
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <Box sx={{ px: '2rem' }}>
            <Content partner={partner} />
          </Box>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

function Content({ partner }) {
  const [code, setCode] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const navigate = useNavigate()

  const authPartner = () => {
    apis.partners
      .auth({
        partner_id: partner?.id,
        code,
      })
      .then(response => {
        if (response.data?.event_id) {
          navigate('/pricing/promotion', {
            state: {
              event_id: response.data.event_id,
              code,
              partner,
            },
          })
        } else {
          alert('유효하지 않은 정보입니다.')
        }
      })
  }

  useEffect(() => {
    if (partner?.start_date && partner?.end_date) {
      const utcMomentS = moment.utc(partner.start_date)
      const kstMomentS = utcMomentS.tz('Asia/Seoul')
      const formattedDateS = kstMomentS.format('YYYY.MM.DD')

      const utcMomentE = moment.utc(partner.end_date)
      const kstMomentE = utcMomentE.tz('Asia/Seoul')
      const formattedDateE = kstMomentE.format('YYYY.MM.DD')

      setStartDate(formattedDateS)
      setEndDate(formattedDateE)
    }
  }, [partner])

  return (
    <>
      <Stack>
        <Typography
          sx={{ fontSize: { lg: '2.4rem', xs: '1.6rem' }, fontWeight: { lg: 700, xs: 800 } }}
        >
          환영합니다!
        </Typography>
        <Typography
          sx={{ fontSize: { lg: '1.6rem', xs: '1.3rem' }, fontWeight: { lg: 500, xs: 400 } }}
        >
          본 요금제는 파트너사 회원만 확인 가능합니다.
        </Typography>
        <Box
          sx={{
            width: '100%',
            background: '#F8F8F8',
            px: { lg: '1.8rem', xs: '1.6rem' },
            py: '1.6rem',
            borderRadius: '1rem',
            mt: { lg: '2rem', xs: '2.4rem' },
            mb: { lg: '0.6rem', xs: 0 },
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 400, lineHeight: 1.2 }}>
            본 요금제는 한시적으로만 가입 가능하며, <br />
            결제 가능 기간이 지나면 <br />
            동일한 가격으로 가입하실 수 없습니다.
          </Typography>
        </Box>

        <Divider flexItem sx={{ my: '2.4rem' }} />

        <Stack
          sx={{
            width: '100%',
            '& .row': {
              width: '100%',
              justifyContent: 'space-between',
              mb: { lg: '2rem', xs: '1.9rem' },
              '& .name': {
                color: '#989898',
                fontSize: { lg: '1.6rem', xs: '1.4rem' },
                fontWeight: 500,
              },
              '& .value': {
                fontSize: { lg: '1.6rem', xs: '1.4rem' },
                fontWeight: 500,
              },

              '& .input-value': {
                px: '1.2rem',
                py: '0.4rem',
                width: '18rem',
                '& input': {
                  p: 0,
                  fontSize: { lg: '1.4rem', xs: '1.2rem' },
                  fontWeight: 400,
                },
              },
            },
          }}
        >
          <Stack direction="row" className="row">
            <Typography className="name">파트너사</Typography>
            <Typography className="value" sx={{ color: theme => theme.palette.draph.newblue }}>
              {partner?.name}
            </Typography>
          </Stack>
          <Stack direction="row" className="row">
            <Typography className="name">결제 가능 기간</Typography>
            <Typography className="value">
              {startDate} - {endDate}
            </Typography>
          </Stack>
          <Stack direction="row" className="row">
            <Typography className="name">코드번호</Typography>
            <OutlinedInput
              className="input-value"
              value={code}
              onChange={e => setCode(e.target.value)}
            />
          </Stack>
        </Stack>
        <Stack sx={{ width: '100%', display: 'flex', alignItems: 'end' }}>
          <Button
            variant="contained"
            onClick={authPartner}
            sx={{ width: '9.9rem', height: '4rem' }}
          >
            확인
          </Button>
        </Stack>
      </Stack>
    </>
  )
}
