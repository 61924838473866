import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  GlobalStyles,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Stack,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material'
import {
  CenterAlignBox,
  CenterAlignStack,
  CustonBannerToggleButton,
  EditorCanvas,
  OpacityMotionDiv,
} from 'components'
import {
  BlueCircleCheckIcon,
  CheckedCircleIcon,
  CheckedCircleIconNotistack,
  CircleCheckIcon,
  CloseCuteIcon,
  DropdownArrowIcon,
  PlusIcon,
  PlusIcon2,
  PlusMobileIcon,
  RegenerateIcon,
} from 'theme/icon'
import { motion, AnimatePresence } from 'framer-motion'
import { base64ToBlob, getS3ImageSrc, imageToFile, s3UriToUrl } from 'utils/common'
import { bannerEachFilterAtom, portfolioDetailAtom, userAtom } from 'atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { apis } from 'apis'
import { BannerPromptRegenerate } from './BannerPrompt'
import { useQueries } from '@tanstack/react-query'
import { useArtworkPolling, useArtworkQuery } from 'hooks/useReactQuery'
import axios from 'axios'

const EDITOR_LISTS = [
  // {
  //   avatar: '/static/images/test_sample/a2.webp',
  //   title: '기본 설정 레이어',
  //   // subtitle: '상품-스튜디오',
  // },
  { avatar: '/static/images/test_sample/a3.webp', title: '메인 배경' },
  { avatar: '/static/images/test_sample/a4.jpeg', title: '텍스트' },
  { avatar: '/static/images/test_sample/a3.webp', title: '로고' },
  {
    avatar: '/static/images/test_sample/a5.png',
    title: '디자인 요소',
  },
  {
    avatar: '/static/images/test_sample/a4.jpeg',
    title: '필터 레이어',
  },
]

const adjustShape = shape => ({
  ...shape,
  width: Math.ceil(shape.width),
  height: Math.ceil(shape.height),
  x: Math.ceil(shape.x),
  y: Math.ceil(shape.y),
  config: {
    ...shape.config,
    bbox: [
      Math.ceil(shape.x),
      Math.ceil(shape.y),
      Math.ceil(shape.x + shape.width),
      Math.ceil(shape.y + shape.height),
    ],
  },
})

export default function BannerEditorV2({ open, setOpen, artwork, setArtwork }) {
  const artworkConfig = useMemo(() => {
    return JSON.parse(artwork.config)
  }, [artwork])

  const artworkPieces = useMemo(() => {
    return artwork.pieces
  }, [artwork])

  const user = useRecoilValue(userAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)

  const [selectedPiece, setSelectedPiece] = useState(artworkPieces[0])
  const [selectedId, setSelectedId] = useState(null)
  const [selectedLayerId, setSelectedLayerId] = useState(null)

  const [initshapes, setInitshapes] = useState([])

  const [uploading, setUploading] = useState(false)

  const [shapes, setShapes] = useState([])

  const { individualArtwork, error, modPieceError, refetch } = useArtworkPolling(
    portfolioDetail.id,
    artwork.id,
    true
  )

  // Editor Canvas의 stage 영역을 DOM
  const stageRef = useRef(null)

  console.log(stageRef?.current?.findOne(`#${selectedLayerId}`))

  useEffect(() => {
    if (individualArtwork) {
      setArtwork(individualArtwork)
    }
  }, [individualArtwork, individualArtwork?.status, individualArtwork?.pieces])

  const directConfigUpdate = (configName, configValue) => {
    setShapes(prev => {
      return prev.map(s =>
        s.id === selectedLayerId ? { ...s, config: { ...s.config, [configName]: configValue } } : s
      )
    })
  }

  // 폰트 넣기
  const getFontFaces = fonts => {
    if (fonts && fonts.map) {
      return fonts
        .map(font => {
          const format = font.cdn_path.split('.').at(-1)

          return `
            @font-face {
              font-family: '${font.id}';
              src: url('${font.cdn_path}') format('${format}');
              font-weight: normal;
              font-style: normal;
            }
          `
        })
        .join('\n')
    }
  }

  const fontLists = ['ChosunCentennial', 'Chosunilbo_myungjo']

  // React Query로 폰트 데이터 가져오기
  const fontQueries = useQueries({
    queries: fontLists.map(font => ({
      queryKey: ['font', font],
      queryFn: () => apis.common.getFont(font),
      select: response => response.data,
      enabled: Boolean(font), // font가 있을 때만 쿼리 실행
    })),
  })

  const allFonts = fontQueries
    .map(query => query.data)
    .filter(Boolean)
    .flat()

  const handleClose = (e, r) => {
    if (r === 'backdropClick') return

    setOpen(false)
  }

  // useEffect(() => {
  //   console.log(artwork)
  //   console.log(artworkPieces)
  //   console.log(user)
  //   console.log(portfolioDetail)
  // }, [artwork])

  const updatePieceHandler = async ({ hasChangeFilter = false }) => {
    // const adjustConfig = {
    //   ...selectedPiece.config,
    //   shapes: shapes.map(s => {
    //     return adjustShape(s)
    //   }),
    // }

    // setInitshapes(adjustConfig.shapes)

    // const snapshotParams = {
    //   user_id: user.id,
    //   portfolio_id: portfolioDetail.id,
    //   artwork_id: artwork.id,
    //   piece_id: selectedPiece.config.piece_id,
    //   banner_presets: JSON.stringify(adjustConfig),
    // }

    try {
      const selectedLayerRef = stageRef?.current?.findOne(`#${selectedLayerId}`)
      const dataURL = selectedLayerRef.toDataURL()
      console.log('------')
      console.log(dataURL)
      const imageFile = base64ToBlob(dataURL, 'image/png')

      const presignedData = (await apis.data.presignedURL('png')).data

      await axios.put(presignedData.presigned_url, imageFile, {
        headers: {
          'Content-Type': imageFile.type,
        },
      })

      const s3Url = s3UriToUrl(presignedData.s3_file_path)

      console.log(s3Url)

      const adjustConfig = {
        ...selectedPiece.config,
        shapes: shapes.map(s => {
          if (s.id === selectedLayerId) {
            return {
              ...adjustShape(s),
              ...(!hasChangeFilter && { result_s3_url: s3Url, filter_flag: false }),
              ...(hasChangeFilter && { filtered_result_s3_url: s3Url, filter_flag: true }),
            }
          }
          return adjustShape(s)
        }),
      }

      setInitshapes(adjustConfig.shapes)

      const snapshotParams = {
        user_id: user.id,
        portfolio_id: portfolioDetail.id,
        artwork_id: artwork.id,
        piece_id: selectedPiece.config.piece_id,
        banner_presets: JSON.stringify(adjustConfig),
      }

      const r = await apis.appfront.getSnapshot(snapshotParams)
      refetch()
      console.log('------')
    } catch (err) {
      console.error('Upload failed:', error)
      throw error
    }

    // try {
    //   const r = await apis.appfront.getSnapshot(snapshotParams)
    //   refetch()
    // } catch (err) {
    //   console.log(err)
    // }
  }

  const retryHandler = layer => {
    setUploading(true)

    // console.log(layer)
    // const formData = new FormData()

    // // Basic form data setup
    // const basicFormData = {
    //   user_id: user.id,
    //   username: user.username,
    //   portfolio_id: portfolioDetail.id,
    //   artwork_id: artwork.id,
    //   piece_id: selectedPiece.config.piece_id,
    // }

    // Object.entries(basicFormData).forEach(([key, value]) => {
    //   formData.append(key, value)
    // })

    // // Helper function to adjust shape dimensions

    // let processedShapes

    // if (layer.config.type === 'inpaint') {
    //   const filteredShapes = shapes.filter(
    //     shape => shape.config.type === 'inpaint' || shape.config.type === 'background'
    //   )

    //   let inpaintBbox = null
    //   let inpaintMaskBbox = null

    //   // First pass: adjust shapes and collect bboxes
    //   const adjustedShapes = filteredShapes.map(shape => {
    //     const adjusted = adjustShape(shape)

    //     if (shape.config.type === 'inpaint') {
    //       inpaintBbox = adjusted.config.bbox
    //     } else if (shape.config.type === 'inpaint_mask') {
    //       inpaintMaskBbox = adjusted.config.bbox
    //     }

    //     return adjusted
    //   })

    //   // Second pass: apply inpaint bbox to background
    //   processedShapes = adjustedShapes.map(shape =>
    //     shape.config.type === 'background' && inpaintBbox
    //       ? {
    //           ...shape,
    //           config: {
    //             ...shape.config,
    //             bg_inpaint_image_bbox: inpaintBbox,
    //           },
    //         }
    //       : shape
    //   )
    // } else {
    //   // Handle other layer types
    //   // const filteredShapes = shapes.filter(shape => shape.config.type === layerType)
    //   processedShapes = [adjustShape(layer)]
    // }

    // formData.append('modified_banner_presets', JSON.stringify(processedShapes))

    // return apis.appfront.retryBannerV2(formData).then(response => {
    //   return response
    // })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiModal-backdrop': {
            backgroundColor: '#30303099',
            backdropFilter: 'blur(20px)',
          },
          '& .MuiDialog-paper': {
            minWidth: '1280px',
            minHeight: '808px',
            borderRadius: '16px',
          },
        }}
      >
        <GlobalStyles styles={getFontFaces(allFonts)} />

        <CenterAlignStack sx={{ width: '100%' }}>
          <EditorBar handleClose={handleClose} title={artwork?.name} />

          <Box sx={{ display: 'flex', width: '100%' }}>
            <LayerPart
              selectedPiece={selectedPiece}
              setSelectedPiece={setSelectedPiece}
              shapes={shapes}
              setShapes={setShapes}
              setSelectedId={setSelectedId}
              retryHandler={retryHandler}
              updatePieceHandler={updatePieceHandler}
              selectedLayerId={selectedLayerId}
              setSelectedLayerId={setSelectedLayerId}
              directConfigUpdate={directConfigUpdate}
              initshapes={initshapes}
              setInitshapes={setInitshapes}
            />
            <CanvasPart
              artworkPieces={artworkPieces}
              selectedPiece={selectedPiece}
              setSelectedPiece={setSelectedPiece}
              shapes={shapes}
              setShapes={setShapes}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              selectedLayerId={selectedLayerId}
              setSelectedLayerId={setSelectedLayerId}
              initshapes={initshapes}
              setInitshapes={setInitshapes}
              stageRef={stageRef}
            />
          </Box>
        </CenterAlignStack>
      </Dialog>
    </>
  )
}

const EditorBar = ({ handleClose, title = '닥터지 바쿠치올 포어젯 세럼' }) => {
  return (
    <CenterAlignBox
      sx={{
        justifyContent: 'space-between',
        height: '6rem',
        alignItems: 'center',
        width: '100%',
        p: '1.8rem 2rem',
        '& img': {
          width: '2.8rem',
        },

        '& .logo': {
          display: 'flex',
          gap: '0.8rem',
        },

        '& .close': {
          display: 'flex',
          width: '15.348rem',
          justifyContent: 'end',
        },

        '& .title': {
          fontSize: '1.8rem',
          fontWeight: 600,
        },
      }}
    >
      <Box className="logo">
        <img src="/static/images/logo/logo_image.png" />
        <Typography sx={{ fontSize: '2rem', fontWeight: 700 }}>Draph Editor</Typography>
      </Box>

      <Typography className="title">{title}</Typography>

      <Box className="close">
        <IconButton sx={{ p: '0.8rem' }} onClick={handleClose}>
          <CloseCuteIcon />
        </IconButton>
      </Box>
    </CenterAlignBox>
  )
}

const LayerPart = ({
  selectedPiece,
  setSelectedPiece,
  shapes,
  setShapes,
  setSelectedId,
  retryHandler,
  updatePieceHandler,
  selectedLayerId,
  setSelectedLayerId,
  directConfigUpdate,
  initshapes,
  setInitshapes,
}) => {
  const theme = useTheme()

  const [selectedLayer, setSelectedLayer] = useState()

  // selectedLayer 읽기용 무조건 읽기용으로 사용할 것 layer => shapes 로 변경 흐름은 되지 않음
  useEffect(() => {
    setSelectedLayer(shapes.find(l => l.id === selectedLayerId))
  }, [shapes, selectedLayerId])

  // const selectedLayer = shapes.find(l => l.id === selectedLayerId)

  useEffect(() => {
    // console.log('selectedLayerId', selectedLayerId)
    console.log('selectedLayer', selectedLayer)
    // console.log(shapes)
  }, [selectedLayer])

  return (
    <Stack
      sx={{
        width: '38.4rem',
        position: 'relative',
        overflow: 'hidden',
        // backgroundColor: 'rgba(41,163,27,0.3)',
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },

        '& .MuiListItemButton-root.default': {
          height: '6rem',
          p: '1.2rem 2.4rem 1.2rem 2rem',

          '&:hover': {
            backgroundColor: theme.palette.draph.lighterblue, // 호버 시 배경색 변경
          },

          '& .MuiListItemAvatar-root': {
            mr: '1.2rem',

            '& .MuiAvatar-root': {
              width: '3.6rem',
              height: '3.6rem',
            },
          },

          '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: '1.6rem',
            lineHeight: 'normal',
            mb: '0.2rem',
          },

          '& .MuiListItemText-secondary': {
            fontWeight: 400,
            fontSize: '1.2rem',
            lineHeight: 'normal',
          },
        },
      }}
    >
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: selectedLayer ? -384 : 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <List disablePadding>
          {shapes.map((l, i) => (
            <ListItemButton
              className="default"
              disableGutters
              disableRipple
              key={i}
              onClick={() => {
                setSelectedLayerId(l.id)
                // if (l.config?.type !== 'background') {
                //   setSelectedId(l.id)
                // }
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <img src={l.avatar} alt={l.title} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={l.title} secondary={l.subtitle} />
              <Box sx={{ transform: 'rotate(-90deg)' }}>
                <DropdownArrowIcon size="xs" color={theme.palette.common.black} />
              </Box>
            </ListItemButton>
          ))}
          <ListItemButton className="default">
            <CenterAlignBox
              sx={{
                backgroundColor: '#989898',
                width: '3.6rem',
                height: '3.6rem',
                borderRadius: '50%',
                alignItems: 'center',
                mr: '1.2rem',
              }}
            >
              <PlusMobileIcon style={{ width: '1.8rem', height: '1.8rem' }} color="white" />
            </CenterAlignBox>
            <Typography sx={{ fontWeight: 700, fontSize: '1.6rem', lineHeight: 'normal' }}>
              구성 요소 추가
            </Typography>
          </ListItemButton>
        </List>
      </motion.div>
      <AnimatePresence>
        {selectedLayer && (
          <motion.div
            initial={{ x: 384 }}
            animate={{ x: 0 }}
            exit={{ x: 384 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <LayerSettings
              selectedPiece={selectedPiece}
              selectedLayer={selectedLayer}
              setSelectedLayer={setSelectedLayer}
              onBack={() => {
                setShapes(initshapes)
                setSelectedLayerId(null)
                setSelectedId(null)
              }}
              directConfigUpdate={directConfigUpdate}
              updatePieceHandler={updatePieceHandler}
              shapes={shapes}
              retryHandler={() => retryHandler(selectedLayer)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  )
}

const CanvasPart = ({
  artworkPieces,
  selectedPiece,
  setSelectedPiece,
  shapes,
  setShapes,
  selectedId,
  setSelectedId,
  selectedLayerId,
  setSelectedLayerId,
  initshapes,
  setInitshapes,
  stageRef,
}) => {
  return (
    <CenterAlignStack
      sx={{
        width: '100%',
        flex: 1,
        background: '#EEEEEE',
        minHeight: '748px',
        p: '23px 0 21px 0',
      }}
    >
      <Stack sx={{ minHeight: '52px', width: '100%' }}>아이콘 메뉴 들어가야하는 자리</Stack>
      <CenterAlignStack sx={{ minHeight: '571px', width: '100%' }}>
        <EditorCanvas
          piece={selectedPiece}
          shapes={shapes}
          setShapes={setShapes}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          selectedLayerId={selectedLayerId}
          setSelectedLayerId={setSelectedLayerId}
          initshapes={initshapes}
          setInitshapes={setInitshapes}
          stageRef={stageRef}
        />
      </CenterAlignStack>
      <Stack sx={{ minHeight: '77px', width: '100%', pt: '21px' }}>
        <CenterAlignBox sx={{ gap: '0.8rem' }}>
          {artworkPieces.map(p => {
            const src = getS3ImageSrc(p.path)
            const isSelected = p.id === selectedPiece.id
            return (
              <Box sx={{ position: 'relative' }} key={p.id}>
                <img
                  src={src}
                  style={{
                    width: '5.6rem',
                    height: '5.6rem',
                    objectFit: 'contain',
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                  onClick={() => {
                    setSelectedPiece(p)
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: -3,
                    left: -3,
                    right: -3,
                    bottom: -3,
                    border: '3px solid #2C4DFF',
                    borderRadius: '7px',
                    pointerEvents: 'none',
                    opacity: isSelected ? 1 : 0,
                    transition: 'opacity 0.2s ease-in',
                  }}
                />
              </Box>
            )
          })}
        </CenterAlignBox>
      </Stack>
    </CenterAlignStack>
  )
}

const LayerSettings = ({
  selectedPiece,
  selectedLayer,
  setSelectedLayer,
  onBack,
  retryHandler,
  updatePieceHandler,
  directConfigUpdate,
  shapes,
}) => {
  const theme = useTheme()

  const pieceConfig = useMemo(() => {
    return selectedPiece.config
  }, [selectedPiece])

  return (
    <Stack
      sx={{
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },

        '& .MuiListItemButton-root.default': {
          height: '6rem',
          p: '1.2rem 2.8rem 1.2rem 2.8rem',

          '&:hover': {
            backgroundColor: theme.palette.draph.lighterblue, // 호버 시 배경색 변경
          },

          '& .MuiListItemAvatar-root': {
            mr: '1.2rem',

            '& .MuiAvatar-root': {
              width: '3.6rem',
              height: '3.6rem',
            },
          },

          '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: '1.6rem',
            lineHeight: 'normal',
            mb: '0.2rem',
          },

          '& .MuiListItemText-secondary': {
            fontWeight: 400,
            fontSize: '1.2rem',
            lineHeight: 'normal',
          },
        },
      }}
    >
      <ListItemButton
        className="default"
        disableGutters
        disableRipple
        onClick={onBack}
        selected={true}
      >
        <Box sx={{ transform: 'rotate(90deg)', ml: '1.2rem' }}>
          <DropdownArrowIcon size="xs" color={theme.palette.common.black} />
        </Box>
        <ListItemAvatar sx={{ ml: '1.2rem' }}>
          <Avatar>
            <img src={selectedLayer.avatar} alt={selectedLayer.title} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={selectedLayer.title} secondary={selectedLayer.subtitle} />
      </ListItemButton>

      <CenterAlignBox
        sx={{
          my: '2.4rem',
          gap: '0.8rem',
          '& .box': {
            width: '16.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 600,
            alignItems: 'center',
            borderRadius: '4px',
            cursor: 'pointer',

            '& .content': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              transition: 'transform 0.3s ease-in-out', // transition 추가
            },

            '&.regenerate': {
              color: theme.palette.draph.blue,
              backgroundColor: theme.palette.draph.lighterblue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },

            '&.accept': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.draph.blue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },
          },
        }}
      >
        <CenterAlignBox className="box regenerate" onClick={retryHandler}>
          <Box className="content">
            <RegenerateIcon color={theme.palette.draph.blue} />
            재생성
          </Box>
        </CenterAlignBox>

        <CenterAlignBox
          className="box accept"
          onClick={() =>
            updatePieceHandler({
              hasChangeFilter:
                selectedLayer.config.type === 'image' ||
                selectedLayer.config.image_type === 'paste',
            })
          }
        >
          <Box className="content">
            <CircleCheckIcon color={theme.palette.common.white} />
            위치
          </Box>
        </CenterAlignBox>
      </CenterAlignBox>

      <Stack
        sx={{
          px: '2rem',
          overflowY: 'auto',
          height: '60rem',
          '& .title': { fontSize: '1.6rem', fontWeight: 600, lineHeight: '20px' },

          '& .box': {
            width: '16.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 600,
            alignItems: 'center',
            borderRadius: '4px',
            cursor: 'pointer',

            '& .content': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              transition: 'transform 0.3s ease-in-out', // transition 추가
            },

            '&.regenerate': {
              color: theme.palette.draph.blue,
              backgroundColor: theme.palette.draph.lighterblue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },

            '&.accept': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.draph.blue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },
          },
        }}
      >
        {selectedLayer.config.type === 'background' && (
          <BackgroundSetting
            pieceConfig={pieceConfig}
            selectedLayer={selectedLayer}
            directConfigUpdate={directConfigUpdate}
            setSelectedLayer={setSelectedLayer}
            retryHandler={retryHandler}
          />
        )}

        {selectedLayer.config.type === 'text' && (
          <TextSettings
            pieceConfig={pieceConfig}
            selectedLayer={selectedLayer}
            directConfigUpdate={directConfigUpdate}
            setSelectedLayer={setSelectedLayer}
            retryHandler={retryHandler}
          />
        )}

        {(selectedLayer.config.type === 'image' || selectedLayer.config.image_type === 'paste') && (
          <ImagePasteSettings
            pieceConfig={pieceConfig}
            selectedLayer={selectedLayer}
            directConfigUpdate={directConfigUpdate}
            setSelectedLayer={setSelectedLayer}
            retryHandler={retryHandler}
          />
        )}
      </Stack>
    </Stack>

    //     {layer.title === '메인 배경' && <BackgroundSettings />}
    //     {layer.title === '텍스트' && <TextSettings />}
  )
}

const BackgroundSetting = ({
  pieceConfig,
  selectedLayer,
  setSelectedLayer,
  retryHandler,
  directConfigUpdate,
}) => {
  const theme = useTheme()
  const [subcategory, setSubcategory] = useState(null)

  const [localPrompt, setLocalPrompt] = useState(pieceConfig.promptObj)

  const t2iClickHandler = () => {
    setSubcategory('t2i')

    directConfigUpdate('bg_inpaint_background_image_url', '')
    directConfigUpdate('bg_inpaint_background_image_bbox', [])

    // setSelectedLayer(p => ({
    //   ...p,
    //   config: {
    //     ...p.config,
    //     bg_inpaint_background_image_url: '',
    //     bg_inpaint_background_image_bbox: [],
    //   },
    // }))
  }

  return (
    <Stack
      sx={{
        '& .type': {
          display: 'flex',
          gap: '0.8rem',
          mt: '1.6rem',
        },
        '& .bg-type': {
          width: '8rem',
          height: '12rem',
          backgroundColor: '#eeeeee',
          borderRadius: '4px',
          position: 'relative',
        },

        '& .bg-border': {
          width: '8rem',
          height: '12rem',

          borderRadius: '4px',
          position: 'absolute',
          border: `2px solid ${theme.palette.draph.blue}`,
        },
        '& .bg-title': {
          bottom: '1rem',
          fontSize: '1.2rem',
          fontWeight: 700,
          position: 'absolute',
          width: '100%',
          textAlign: 'center',
        },

        '& .prompt': {
          mt: '2.8rem',
        },
      }}
    >
      <Typography className="title">배경 타입 변경</Typography>

      <Box className="type">
        <Box className="bg-type" onClick={t2iClickHandler}>
          <Typography className="bg-title">t2i</Typography>
          {subcategory === 't2i' && <Box className="bg-border"></Box>}
        </Box>
        <Box
          className="bg-type"
          onClick={() => {
            setSubcategory('ref')
          }}
        >
          <Typography className="bg-title">레퍼런스</Typography>
          {subcategory === 'ref' && <Box className="bg-border"></Box>}
        </Box>
      </Box>

      {subcategory && (
        <OpacityMotionDiv>
          <Stack className="prompt">
            <Typography className="title">프롬프트</Typography>

            <Box
              sx={{
                width: '100%',
                p: '0.8rem',
                backgroundColor: '#f8f8f8',
                borderRadius: '6px',
                mt: '1.6rem',
              }}
            >
              <Box>
                <BannerPromptRegenerate
                  promptValues={localPrompt}
                  setPromptValues={setLocalPrompt}
                  directConfigUpdate={directConfigUpdate}
                />
              </Box>
            </Box>
          </Stack>
        </OpacityMotionDiv>
      )}
    </Stack>
  )
}

const TextSettings = ({
  pieceConfig,
  selectedLayer,
  setSelectedLayer,
  retryHandler,
  directConfigUpdate,
}) => {
  const copyTypeLists = [
    { value: 'info', label: '정보전달' },
    { value: 'question', label: '궁금증유발' },
    { value: 'review', label: '인기강조' },
    { value: 'price', label: '가격강조' },
    { value: 'brand', label: '브랜드명' },
    { value: 'product', label: '상품명' },
  ]

  const theme = useTheme()
  const [subcategory, setSubcategory] = useState(null)

  const [localPrompt, setLocalPrompt] = useState(pieceConfig.promptObj)

  const t2iClickHandler = () => {
    setSelectedLayer(p => ({
      ...p,
      config: {
        ...p.config,
        bg_inpaint_background_image_url: '',
        bg_inpaint_background_image_bbox: [],
      },
    }))
  }

  return (
    <Stack
      sx={{
        '& .button-box': {
          display: 'flex',
          width: '100%',
          gap: '1rem',
          flexFlow: 'wrap',
          mt: '1.7rem',
        },
        '& .copy-button': {
          width: 'calc((100% - 2rem) / 3)',
          // height: '3.2rem',
          // border: 'none',
          // p: 0,
          // backgroundColor: '#eeeeee',
          // fontSize: '1.2rem',
          // fontWeight: 700,
          // color: theme.palette.common.black,

          // '&:hover': {
          //   backgroundColor: '#eeeeee',
          //   color: theme.palette.common.black,
          // },

          // '&.Mui-selected': {
          //   backgroundColor: theme.palette.draph.blue,
          //   color: theme.palette.common.white,
          // },
        },

        '& .text-stack': {
          mt: '2.4rem',
        },

        '& .text-border': {
          width: '8rem',
          height: '12rem',

          borderRadius: '4px',
          position: 'absolute',
          border: `2px solid ${theme.palette.draph.blue}`,
        },
      }}
    >
      <Stack className="copy-stack">
        <Typography className="title">카피라이트 타입</Typography>

        <Box className="button-box">
          {copyTypeLists.map(b => {
            return (
              <CustonBannerToggleButton
                key={b.value}
                className="copy-button"
                variant="cantained"
                selected={selectedLayer.config.text_type === b.value}
                onClick={() => {
                  directConfigUpdate('text_type', b.value)
                }}
              >
                {b.label}
              </CustonBannerToggleButton>
            )
          })}
        </Box>
      </Stack>

      <Stack className="text-stack">
        <Typography className="title">텍스트 디자인</Typography>
      </Stack>
    </Stack>
  )
}

const ImagePasteSettings = ({
  pieceConfig,
  selectedLayer,
  setSelectedLayer,
  retryHandler,
  directConfigUpdate,
}) => {
  const theme = useTheme()
  const [eachFilter, setEachFilter] = useRecoilState(bannerEachFilterAtom)
  const [filterType, setFilterType] = useState('original') // original, grain, nature, lightning, blackAndWhite, custom

  const onChangeHandler = e => {
    const value = e.target.value
    const name = e.target.name
    setFilterType('custom')
    setEachFilter(p => {
      return { ...p, [name]: value }
    })
  }

  const filterHandler = (type, value) => () => {
    setFilterType(type)
    setEachFilter(value)
  }

  const photoFilters = [
    {
      value: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        noise: 0,
        blurRadius: 0,
        enhance: 0,
        red: 0,
        green: 0,
        blue: 0,
      },
      label: '원본',
      type: 'original',
      image: '/static/images/theme_template/kitchen/default.png',
    },
    {
      value: {
        brightness: 0.4,
        contrast: 0,
        saturation: 0,
        noise: 0,
        blurRadius: 0,
        enhance: 0,
        red: 10,
        green: 0,
        blue: 0,
      },
      label: '스튜디오\n그레인',
      type: 'grain',
      image: '/static/images/theme_template/bubble/default.png',
    },
    {
      value: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        noise: 0,
        blurRadius: 0,
        enhance: 0,
        red: 0,
        green: 0,
        blue: 0,
      },
      label: '자연 채광',
      type: 'nature',
      image: '/static/images/theme_template/half/default.png',
    },

    {
      value: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        noise: 0,
        blurRadius: 0,
        enhance: 0,
        red: 0,
        green: 0,
        blue: 0,
      },
      label: '스튜디오\n라이팅',
      type: 'lightning',
      image: '/static/images/theme_template/office/default.png',
    },

    {
      value: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        noise: 0,
        blurRadius: 0,
        enhance: 0,
        red: 0,
        green: 0,
        blue: 0,
      },
      label: '흑백',
      type: 'blackAndWhite',
      image: '/static/images/theme_template/office/default.png',
    },
  ]

  return (
    <Stack
      sx={{
        '& .photo-filters': { display: 'flex', flexFlow: 'wrap', gap: '0.8rem', mt: '1.6rem' },
        '& .photo-filter': {
          width: '7.6rem',
          height: '12rem',
          borderRadius: '4px',
          cursor: 'pointer',
          '&.box': {
            backgroundColor: '#eee',
            position: 'relative',
          },
          '&.image': {
            objectFit: 'contain',
          },
          '&.selected-box': {
            position: 'absolute',
            border: `1px solid ${theme.palette.draph.blue}`,
          },
          '& .photo-filter-name': {
            fontWeight: 700,
            fontSize: '1.2rem',
            position: 'absolute',
            transform: 'translate(-50%, 0%)',
            left: '50%',
            bottom: '1rem',
            wordBreak: 'keep-all',
            textAlign: 'center',
          },
        },
      }}
    >
      <Typography className="title">카피라이트 타입</Typography>
      <Box className="photo-filters">
        {photoFilters.map(p => {
          return (
            <Box
              className="photo-filter box"
              key={p.label}
              onClick={filterHandler(p.type, p.value)}
            >
              {/* <img className="photo-filter image" src={p.image} /> */}
              <Box className="photo-filter-name">{p.label}</Box>
              {p.type === filterType && <Box className="photo-filter selected-box"></Box>}
            </Box>
          )
        })}
      </Box>

      <Typography gutterBottom>밝기</Typography>
      <Slider
        value={eachFilter.brightness}
        name={'brightness'}
        onChange={onChangeHandler}
        min={-0.5}
        max={0.5}
        step={0.01}
      />

      <Typography gutterBottom>대비</Typography>
      <Slider
        value={eachFilter.contrast}
        name={'contrast'}
        onChange={onChangeHandler}
        min={-100}
        max={100}
        step={1}
      />

      <Typography gutterBottom>채도</Typography>
      <Slider
        value={eachFilter.saturation}
        name={'saturation'}
        onChange={onChangeHandler}
        min={-2}
        max={2}
        step={0.1}
      />

      <Typography gutterBottom>노이즈</Typography>
      <Slider
        value={eachFilter.noise}
        name={'noise'}
        onChange={onChangeHandler}
        min={0}
        max={1}
        step={0.01}
      />

      <Typography gutterBottom>블러</Typography>
      <Slider
        value={eachFilter.blurRadius}
        name={'blurRadius'}
        onChange={onChangeHandler}
        min={0}
        max={40}
        step={1}
      />

      <Typography gutterBottom>선명도 향상</Typography>
      <Slider
        value={eachFilter.enhance}
        name={'enhance'}
        onChange={onChangeHandler}
        min={0}
        max={1}
        step={0.1}
      />

      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        색상 균형
      </Typography>

      <Typography gutterBottom>빨강</Typography>
      <Slider
        value={eachFilter.red}
        name={'red'}
        onChange={onChangeHandler}
        min={-50}
        max={50}
        step={1}
        sx={{
          '& .MuiSlider-track': { backgroundColor: 'red' },
          '& .MuiSlider-thumb': { backgroundColor: 'red' },
        }}
      />

      <Typography gutterBottom>초록</Typography>
      <Slider
        value={eachFilter.green}
        name={'green'}
        onChange={onChangeHandler}
        min={-50}
        max={50}
        step={1}
        sx={{
          '& .MuiSlider-track': { backgroundColor: 'green' },
          '& .MuiSlider-thumb': { backgroundColor: 'green' },
        }}
      />

      <Typography gutterBottom>파랑</Typography>
      <Slider
        value={eachFilter.blue}
        name={'blue'}
        onChange={onChangeHandler}
        min={-50}
        max={50}
        step={1}
        sx={{
          '& .MuiSlider-track': { backgroundColor: 'blue' },
          '& .MuiSlider-thumb': { backgroundColor: 'blue' },
        }}
      />
      <Button
        onClick={() => {
          console.log(eachFilter)
        }}
      >
        명세 추출
      </Button>
    </Stack>
  )
}
