import { useRecoilState } from 'recoil'
import { allowBrowserNotiDialogAtom } from 'atoms'
import { getBrowser } from 'utils/common'

export default function useBrowserNotificationDialog() {
  const [dialog, setDialog] = useRecoilState(allowBrowserNotiDialogAtom)

  const showBrowserNotificationDialog = props => {
    // 24.10.28 엣지브라우저, 크롬브라우저에서 버그가 있어 일단 아예 사용하지 않도록 조치
    // 필요시 브라우저 감지하여 제한적으로 사용
    getBrowser()

    // setDialog({ ...dialog, open: true, ...props })
  }

  return {
    dialog,
    setDialog,
    showBrowserNotificationDialog,
  }
}
