// material
import { alpha, styled, useTheme } from '@mui/material/styles'
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Skeleton,
  Divider,
  ListItemIcon,
  Menu,
  Avatar,
  Collapse,
  Card,
  Backdrop,
} from '@mui/material'

import { Link as RouterLink, useNavigate, useLocation, Link } from 'react-router-dom'

import { MdMenuOpen } from 'react-icons/md'

import {
  TempLayoutDiv,
  UserInfoDrawer,
  CenterAlignBox,
  LanguageSelect,
  MenuDrawer,
  ServiceTitle,
  StyledLink,
  BackgroundSkeleton,
  CenterAlignStack,
  GradationButton,
  GradationOutlineButton,
  LineBannerSwiper,
} from 'components'

import {
  appMenuAtom,
  userAtom,
  languageAtom,
  tutorialAtom,
  artworkListsIsFoldedAtom,
  promotionAnchorAtom,
  noticeBannersAtom,
  serverStatusAtom,
  subMenuAtom,
  dragDropOpenAtom,
  portfolioTypeAtom,
  userSubInfoAtom,
  creditPlansAtom,
  userCreditAtom,
  subEventValidAtom,
} from 'atoms'
import { useRecoilValue, useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil'

import { removeUserCookie, getAccessToken } from 'utils/cookie'
import { MHidden } from 'components/@material-extend'
import { BsBorderWidth } from 'react-icons/bs'
import { Fragment, useEffect, useState } from 'react'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import {
  MypageIcon,
  PricingIcon,
  RightArrowIcon,
  RightArrowShortIcon,
  LogoutIcon,
  CouponIcon,
  MypageIcon2,
  ImageIcon,
  CoinStackIcon,
  CouponIcon2,
  LanguageIcon,
  LogoutIcon2,
  APIIcon,
} from 'theme/icon'
import { useScroll } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper'
import { useNavigateForPromotion } from 'hooks/useNavigateWithQuery'
import { promotionInfo, promotionInfoKeys } from 'pages/promotionEvents/DomeggookEvent'
import { apis } from 'apis'
// import { GENERATE_SUB, MAINTAIN_MENU, MODEL_SUB, appMenuDefault } from './MainConfig'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { iOS, isFullUrl, isKo, isMoblieDevice, numberCommaFormatter } from 'utils/common'
import { SUB_EVENT_ID } from 'pages/Pricing'
import i18n from 'i18n'
import { ENGLISH_LANGUAGES } from 'config'
import { useMenu } from 'hooks/useMenu'
import { setCookie } from '../../utils/cookie'
import { fontSize } from 'pages/test/Toolbar'

// ----------------------------------------------------------------------

export const APPBAR_MOBILE = '5.2rem'
export const APPBAR_NONMEMBER_MOBILE = '9.2rem'
export const APPBAR_DESKTOP = '8rem'
export const APPBAR_SUBMENU = '4.7rem'
export const LINEBANNER_HEIGHT = '4.6rem'
export const LINEBANNER_HEIGHT_MOBILE = '4rem'
export const APPBAR_NONMEMBER_DESKTOP = '12.6rem'

const MenuItemStyle = { fontSize: '1.3rem', fontWeight: 700 }

const AppbarUpperBox = styled(Box)(({ theme }) => ({
  padding: '1.4rem 0',
  maxHeight: LINEBANNER_HEIGHT,
  width: '128rem',
  backgroundColor: `${theme.palette.common.white}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: `${theme.palette.common.black}`,
  [theme.breakpoints.down('lg')]: {
    maxHeight: LINEBANNER_HEIGHT_MOBILE,
  },
}))

const AppbarDownerBox = styled(Box)(({ theme }) => ({
  width: '128rem',
  height: '8rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.common.black,

  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,

    width: '100%',
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',

  minHeight: `${APPBAR_MOBILE} !important`,
  [theme.breakpoints.up('lg')]: {
    minHeight: `${APPBAR_DESKTOP} !important`,
    backgroundColor: theme.palette.common.black,
    width: '100% !important',
    padding: 0,
  },

  [theme.breakpoints.down('lg')]: {
    // paddingLeft: '2rem',
    // paddingRight: '2rem',
    // borderBottom: '0.5px solid #fff',
    padding: 0,
  },
}))

const IconWrapper = styled('div')(({ theme }) => ({
  height: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.up('lg')]: { marginBottom: '7px' },
  // border: '1px solid gold',
}))

export const triggerGAEvent = menu => {
  const EVENT_NAME = 'navbar_click'
  // ----- GA4 event -----
  window.gtag('event', EVENT_NAME, {
    menu,
  })
  // ---------------------
}

const logos = [
  { name: 'google', img: '/static/images/logo/outside/google_logo.png' },
  { name: 'kakao', img: '/static/images/logo/outside/kakao_logo.png' },
  { name: 'naver', img: '/static/images/logo/outside/naver_logo.png' },
  { name: 'draph', img: '/static/images/logo/logo_Avatar2.png' },
]

export default function MainAppBar({ hideLineBanner = false, bgColor = null }) {
  const token = getAccessToken()
  const { pathname } = useLocation()

  const tutorial = useRecoilValue(tutorialAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)

  // 드래그 가능한 영역을 지정해야함.
  const dragDropOpenAble = [
    '/generate/upload',
    '/generate/removebg',
    '/generate/bundle',
    '/fun/fame',
    '/model/mannequin',
  ]
  const portfolioIdPattern = /^\/portfolio\/\d+$/

  const isDragDropOpenAble =
    dragDropOpenAble.includes(pathname) || portfolioIdPattern.test(pathname)

  const dragEnterHandle = e => {
    if (isDragDropOpenAble) {
      e.preventDefault()
      e.stopPropagation()

      setDragDropOpen(true)
    }
  }

  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const rootStylezIndex = () => {
    if (tutorial.step <= 14 || tutorial.step === '14_4' || tutorial.step === 16) return 1100

    if (tutorial.step === '14_4') return 'auto'
  }

  if (!token) {
    return (
      <RootStyle
        sx={{
          zIndex: tutorial.mode ? rootStylezIndex() : isFolded.value ? -1 : 1100,
          // display: isFolded.value && 'none',
          opacity: isFolded.value ? 0 : 1,
          transition: 'opacity 0.3s',
          ...(!isMobile && {
            backgroundImage: `linear-gradient(to bottom, ${theme.palette.draph.blue} 36.507%, ${theme.palette.common.black} 36.507%)`,
          }),
        }}
      >
        <ToolbarStyle
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            ...(bgColor && {
              backgroundColor: `${bgColor} !important`,
              '& .appbar-login-button': {
                backgroundImage: `linear-gradient(${bgColor},${bgColor}),linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%) !important`,
              },
            }),
          }}
        >
          <NonMemberToolbarContent hideLineBanner={hideLineBanner} />
        </ToolbarStyle>
      </RootStyle>
    )
  }

  return (
    <RootStyle
      sx={{
        zIndex: tutorial.mode ? rootStylezIndex() : isFolded.value ? -1 : 1100,
        // display: isFolded.value && 'none',
        opacity: isFolded.value ? 0 : 1,
        transition: 'opacity 0.3s',
        ...(!isMobile && {
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.common.white} 36.507%, ${theme.palette.common.black} 36.507%)`,
        }),
      }}
      onDragEnter={dragEnterHandle}
    >
      <ToolbarStyle
        sx={{
          ...(bgColor && {
            backgroundColor: `${bgColor} !important`,
          }),
        }}
      >
        <ToolbarContent hideLineBanner={hideLineBanner} />
      </ToolbarStyle>
    </RootStyle>
  )
}

function ToolbarContent({ hideLineBanner = false }) {
  const { t, i18n } = useTranslation()

  const MOBILE_MENU = [
    { route: '/user/mypage', title: t('menu.mypage'), GA: 'mypage' },
    { route: '/user/creditInfo', title: t('mypage_menu.credit'), GA: 'mypage' },
    { route: '/user/background', title: t('mypage_menu.background'), GA: 'mypage' },
    { route: '/user/coupon', title: t('mypage_menu.coupon'), GA: 'mypage' },
    { route: '/user/profile', title: t('mypage_menu.user_info_change'), GA: 'mypage' },
  ]

  const [swiper, setSwiper] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [subEventValid, setSubEventValid] = useRecoilState(subEventValidAtom)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const userSubInfo = useRecoilValue(userSubInfoAtom)
  const user = useRecoilValue(userAtom)
  const plans = useRecoilValue(creditPlansAtom)

  const showNoticeBanner = useRecoilValue(noticeBannersAtom).length > 0

  const location = useLocation()
  const isHome = location.pathname === '/home' || location.pathname === '/'

  const theme = useTheme()

  const token = getAccessToken()
  const resetUser = useResetRecoilState(userAtom)
  const navigate = useNavigate()

  const [currency, setCurrency] = useState('krw')

  useEffect(() => {
    const currency = i18n.language === 'ko' ? 'krw' : 'usd'
    setCurrency(currency)
  }, [i18n.language])

  useEffect(() => {
    const subPlans = plans.subscription
    if (subPlans) {
      const v =
        userSubInfo?.subscription_event === SUB_EVENT_ID &&
        subPlans.filter(p => p.ev && p.ev[SUB_EVENT_ID] && !isNaN(p.ev[SUB_EVENT_ID][currency]))
          .length > 0

      setSubEventValid(v)
    }
  }, [plans, currency])

  // 이게 맞을까나...
  // useEffect(() => {
  //   apis.portfolio.getDefaultPortfolio().then(response => {
  //     const menuDefault = _.cloneDeep(appMenuDefault)
  //     menuDefault[0].path = `/portfolio/upload?id=${response.data.id}&pages=1`
  //     setMenu(menuDefault)
  //   })
  // }, [])

  const avatarImg = user.id
    ? logos.find(logo => logo.name === user.login_sns)?.img ||
      logos.find(logo => logo.name === 'draph')?.img
    : undefined

  const logoutUser = () => {
    removeUserCookie()
    resetUser()
    window.location.reload()
    // navigate('/home')
  }

  const handleClick = event => {
    // setAnchorEl(event.currentTarget)
    setShowDropdownMenu(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const snapshot = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        console.log(snapshot)
        console.debug('-----Atom values -----')
        for (const node of snapshot.getNodes_UNSTABLE()) {
          const value = await snapshot.getPromise(node)
          console.debug(node.key, value)
        }
        console.debug('------------------------')
      },
    []
  )

  const tutorialHandle = () => {
    tutorial.mode && tutorial.step === 0 && setTutorial(prev => ({ ...prev, step: 1 }))
  }
  const [hasBorderline, setHasBorderline] = useState(false)
  const [subMenu, setSubMenu] = useRecoilState(subMenuAtom)
  const resetSubMenu = useResetRecoilState(subMenuAtom)

  const { scrollY } = useScroll({ layoutEffect: false })
  const queryParams = new URLSearchParams(location.search)
  const isMobile = useMobileMediaQuery()

  // 서브메뉴 안 닫을 주소

  const { menu, getCurrentMenu, MAINTAIN_MENU, GENERATE_SUB, MODEL_SUB } = useMenu()
  const MAINTAIN_MENU_BOOLEN = MAINTAIN_MENU.some(path => location.pathname.includes(path))
  const threshold = 400

  useEffect(() => {
    if (MAINTAIN_MENU_BOOLEN) {
      if (GENERATE_SUB.some(path => location.pathname.includes(path))) {
        setSubMenu({ open: true, main: 'generate' })
      }
      if (MODEL_SUB.some(path => location.pathname.includes(path))) {
        setSubMenu({ open: true, main: 'model' })
      }
    } else {
      resetSubMenu()
    }
    return () => {
      if (!MAINTAIN_MENU_BOOLEN) {
        resetSubMenu()
      }
    }
  }, [location.pathname])

  useEffect(() => {
    // TODO 모바일에서 표시가 안됨

    scrollY.onChange(() => {
      if (scrollY.get() >= threshold) {
        setHasBorderline(true)
      } else {
        setHasBorderline(false)
      }
    })
  })

  return (
    <>
      <Desktop>
        <Stack
          sx={{ width: '100%' }}
          onMouseLeave={e => {
            if (MAINTAIN_MENU_BOOLEN) {
              if (GENERATE_SUB.some(path => location.pathname.includes(path))) {
                setSubMenu({ open: true, main: 'generate' })
              }
              if (MODEL_SUB.some(path => location.pathname.includes(path))) {
                setSubMenu({ open: true, main: 'model' })
              }
            } else {
              resetSubMenu()
            }
          }}
        >
          {showNoticeBanner && (
            <CenterAlignBox sx={{ backgroundColor: '#FFE6A7' }}>
              <AppbarUpperBox sx={{ width: '100%', background: 'transparent', p: 0 }}>
                <NoticeBanner />
              </AppbarUpperBox>
            </CenterAlignBox>
          )}
          {isHome && !hideLineBanner && (
            <CenterAlignBox sx={{}}>
              <AppbarUpperBox sx={{ width: '100%', background: 'transparent', p: 0 }}>
                <LineBannerSwiper subEventValid={subEventValid} />
              </AppbarUpperBox>
            </CenterAlignBox>
          )}

          <CenterAlignBox
            sx={
              {
                // borderBottom: hasBorderline ? `1px solid ${theme.palette.common.white}` : 0,
              }
            }
          >
            <AppbarDownerBox>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '25rem',
                }}
              >
                <RouterLink
                  to="/home"
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    triggerGAEvent('logo')
                    if (location.pathname === '/home') {
                      // XXX 튜토리얼 실행 테스트 - dev only
                      if (process.env.NODE_ENV === 'development') {
                        // 튜토 OFF
                        // setTutorial(prev => ({ step: 0, mode: true }))
                      } else {
                        window.location.reload()
                      }
                    }
                  }}
                >
                  <Stack direction="row" alignItems="center" sx={{ minWidth: '23rem' }}>
                    {/* <img src="/static/images/logo/logo_image.png" style={{ width: '5rem' }} />
                    <ServiceTitle sx={{ ml: '1.2rem' }} /> */}
                    <img src="/static/images/logo/logo_h_white2.png" />
                  </Stack>
                </RouterLink>
                {/* <TempLayoutDiv
          style={{ width: '6rem', height: '6rem' }} //
          onClick={snapshot}
        >
          아톰
        </TempLayoutDiv> */}
              </Box>
              <CenterMenu MAINTAIN_MENU_BOOLEN={MAINTAIN_MENU_BOOLEN} />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  // justifyContent: 'center',
                  width: '25rem',
                  minWidth: '15rem',
                  position: 'relative',
                }}
                gap="3.6rem"
              >
                {/* <Tutorial step={17}>
                <UserInfoDrawer />
              </Tutorial> */}

                {/* <MainAppBarRouterLink
                  onClick={() => {
                    triggerGAEvent('logout')
                    logoutUser()
                  }}
                  to={location.pathname}
                  style={{ textDecoration: 'none' }}
                >
                  <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <IconWrapper style={{ paddingBottom: '1px' }}>
                      <LogoutIcon />
                    </IconWrapper>
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: '1.4rem',
                        fontWeight: 600,
                        lineHeight: '1.5rem',
                      }}
                    >
                      {t('menu.logout')}
                    </Typography>
                  </Stack>
                </MainAppBarRouterLink> */}

                <MainAppBarRouterLink
                  onClick={() => {}}
                  to="/blog"
                  style={{ textDecoration: 'none' }}
                >
                  <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <IconWrapper style={{ paddingBottom: '1px' }}>
                      <svg
                        width="37"
                        height="31"
                        viewBox="0 0 37 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M36 22.3333C36 23.2763 35.6254 24.1807 34.9586 24.8475C34.2918 25.5143 33.3874 25.8889 32.4444 25.8889H8.11111L1 30V4.55556C1 3.61256 1.3746 2.70819 2.0414 2.0414C2.70819 1.3746 3.61256 1 4.55556 1H32.4444C33.3874 1 34.2918 1.3746 34.9586 2.0414C35.6254 2.70819 36 3.61256 36 4.55556V22.3333Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.70703 17V9.92969H8.62695C10.2139 9.92969 11.0293 10.6523 11.0293 11.7266C11.0293 12.5322 10.4824 13.0889 9.71094 13.25V13.3184C10.5605 13.3574 11.3027 14.002 11.3027 15.0273C11.3027 16.165 10.4189 17 8.85156 17H5.70703ZM7.36719 15.6523H8.46094C9.21777 15.6523 9.56445 15.3447 9.56445 14.8418C9.56445 14.2852 9.1543 13.9287 8.49023 13.9336H7.36719V15.6523ZM7.36719 12.8496H8.35352C8.91992 12.8447 9.33008 12.5469 9.33008 12.0391C9.33008 11.5605 8.96387 11.2578 8.37305 11.2578H7.36719V12.8496Z"
                          fill="white"
                        />
                        <path
                          d="M12.123 17V9.92969H13.7832V15.6426H16.752V17H12.123Z"
                          fill="white"
                        />
                        <path
                          d="M23.9688 13.4648C23.9736 15.7939 22.5039 17.0977 20.6094 17.0977C18.7051 17.0977 17.25 15.7842 17.25 13.4648C17.25 11.1309 18.7051 9.83203 20.6094 9.83203C22.5039 9.83203 23.9736 11.1309 23.9688 13.4648ZM18.9492 13.4648C18.9492 14.8711 19.5791 15.6328 20.6094 15.6328C21.6445 15.6328 22.2744 14.8711 22.2695 13.4648C22.2744 12.0586 21.6445 11.292 20.6094 11.2969C19.5791 11.292 18.9492 12.0586 18.9492 13.4648Z"
                          fill="white"
                        />
                        <path
                          d="M29.6035 12.2344C29.4424 11.6436 28.9639 11.292 28.2656 11.2969C27.2256 11.292 26.5664 12.083 26.5664 13.4551C26.5664 14.8369 27.1963 15.6328 28.2656 15.6328C29.208 15.6328 29.7354 15.1396 29.7598 14.373H28.334V13.1523H31.3613V14.0801C31.3613 15.9648 30.0674 17.0977 28.2559 17.0977C26.2344 17.0977 24.8672 15.7109 24.8672 13.4746C24.8672 11.165 26.3467 9.83203 28.2266 9.83203C29.8574 9.83203 31.1123 10.8232 31.293 12.2344H29.6035Z"
                          fill="white"
                        />
                      </svg>
                    </IconWrapper>
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: '1.4rem',
                        fontWeight: 600,
                        lineHeight: '1.5rem',
                      }}
                    >
                      {t('menu.blog')}
                    </Typography>
                  </Stack>
                </MainAppBarRouterLink>

                <MainAppBarRouterLink
                  onClick={() => {
                    triggerGAEvent('pricing')
                  }}
                  to="/pricing"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  {subEventValid ? (
                    <Swiper
                      onSwiper={setSwiper}
                      slidesPerView={1}
                      loop={true}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay, Pagination, Navigation]}
                      style={{
                        width: '5.2rem',
                      }}
                    >
                      <SwiperSlide
                        style={{
                          display: 'flex',
                          width: '100%',
                          // height: '100%',
                          position: 'relative',
                          justifyContent: 'center',
                        }}
                      >
                        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                          <IconWrapper>
                            <PricingIcon />
                          </IconWrapper>
                          <Typography
                            sx={{
                              color: 'white',
                              fontSize: '1.4rem',
                              fontWeight: 600,
                              lineHeight: '1.5rem',
                            }}
                          >
                            {t('menu.pricing')}
                          </Typography>
                        </Stack>
                      </SwiperSlide>

                      <SwiperSlide
                        style={{
                          display: 'flex',
                          width: '100%',
                          // height: '100%',
                          position: 'relative',
                          justifyContent: 'center',
                        }}
                      >
                        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                          <IconWrapper>
                            <CouponIcon />
                          </IconWrapper>
                          <Typography
                            sx={{
                              color: 'white',
                              fontSize: '1.4rem',
                              fontWeight: 600,
                              lineHeight: '1.5rem',
                              '& span': {
                                color: theme => theme.palette.draph.green,
                              },
                            }}
                          >
                            {t('title.free_for_first_month_nospace_a')}
                            <span>{t('title.free_for_first_month_nospace_b')}</span>
                            {t('title.free_for_first_month_nospace_c')}
                          </Typography>
                        </Stack>
                      </SwiperSlide>
                    </Swiper>
                  ) : (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                      <IconWrapper>
                        <PricingIcon />
                      </IconWrapper>
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '1.4rem',
                          fontWeight: 600,
                          lineHeight: '1.5rem',
                        }}
                      >
                        {t('menu.pricing')}
                      </Typography>
                    </Stack>
                  )}
                </MainAppBarRouterLink>

                <MainAppBarRouterLink
                  onMouseOver={() => {
                    setShowDropdownMenu(true)
                  }}
                  onMouseLeave={() => {
                    setShowDropdownMenu(false)
                  }}
                  onClick={() => {
                    triggerGAEvent('mypage')
                  }}
                  to="/user/mypage"
                  style={{ textDecoration: 'none' }}
                >
                  <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <IconWrapper>
                      <MypageIcon />
                    </IconWrapper>
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: '1.4rem',
                        fontWeight: 600,
                        lineHeight: '1.5rem',
                      }}
                    >
                      {t('menu.mypage')}
                    </Typography>
                  </Stack>
                </MainAppBarRouterLink>

                <Box
                  sx={{
                    zIndex: 9999,
                    position: 'absolute',
                    top: '7.5rem',
                    right: '0rem',
                    // display: showDropdownMenu ? 'block' : 'none',
                    visibility: showDropdownMenu ? 'visible' : 'hidden',
                    transition: 'visibility .2s',
                  }}
                  onMouseOver={() => {
                    setShowDropdownMenu(true)
                  }}
                  onMouseLeave={() => {
                    setShowDropdownMenu(false)
                  }}
                >
                  <CardMenu open={showDropdownMenu} setOpen={setShowDropdownMenu} />
                </Box>
              </Box>
            </AppbarDownerBox>
          </CenterAlignBox>
          <CenterAlignBox>
            <SubMenuAppBar />
          </CenterAlignBox>
        </Stack>
      </Desktop>

      <Mobile>
        <Stack sx={{ width: '100%' }}>
          {showNoticeBanner && (
            <CenterAlignBox sx={{ backgroundColor: '#FFE6A7' }}>
              <AppbarUpperBox sx={{ width: '100%', background: 'transparent', p: 0 }}>
                <NoticeBanner />
              </AppbarUpperBox>
            </CenterAlignBox>
          )}
          {isHome && !hideLineBanner && (
            <CenterAlignBox sx={{ backgroundColor: '#FFE6A7' }}>
              <AppbarUpperBox sx={{ width: '100%', background: 'transparent', p: 0 }}>
                <LineBannerSwiper subEventValid={subEventValid} />
              </AppbarUpperBox>
            </CenterAlignBox>
          )}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: '2rem',
              height: APPBAR_MOBILE,
            }}
          >
            {/* zIndex 막혀서 강제로 비활성 및 배경가리기 */}
            {tutorial.mode && tutorial.step === 14 && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 9,
                }}
              ></Box>
            )}
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '3rem' }}>
            <Tutorial step={0}>
              <MenuDrawer />
            </Tutorial>
          </Box> */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {!tutorial.mode && (
                <RouterLink
                  to="/home"
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    triggerGAEvent('logo')
                    if (location.pathname === '/home') {
                      if (process.env.NODE_ENV === 'development') {
                        // 튜토 OFF
                        // setTutorial(prev => ({ step: 0, mode: true }))
                      } else {
                        window.location.reload()
                      }
                    }
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    <img src="/static/images/logo/logo_h_white2.png" style={{ height: '3.3rem' }} />
                  </Stack>
                </RouterLink>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '1.2rem',
                position: 'relative',
              }}
            >
              {/* <Tutorial step={17}>
              <UserInfoDrawer />
            </Tutorial> */}
              {subEventValid && (
                <Box sx={{ position: 'absolute', left: '-5.2rem' }}>
                  <Swiper
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                      reverseDirection: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    style={{
                      width: '5rem',
                    }}
                  >
                    <SwiperSlide
                      style={{
                        display: 'flex',
                        width: '100%',
                        position: 'relative',
                        justifyContent: 'center',
                      }}
                    ></SwiperSlide>

                    <SwiperSlide
                      style={{
                        display: 'flex',
                        width: '100%',
                        // height: '100%',
                        position: 'relative',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '1.4rem',
                          fontWeight: 600,
                          lineHeight: '1.5rem',
                          '& span': {
                            color: theme => theme.palette.draph.green,
                          },
                        }}
                      >
                        {t('title.free_for_first_month_nospace_a')}
                        <span>{t('title.free_for_first_month_nospace_b')}</span>
                        {t('title.free_for_first_month_nospace_c')}
                      </Typography>
                    </SwiperSlide>
                  </Swiper>
                </Box>
              )}

              <MainAppBarRouterLink to="/pricing" style={{ textDecoration: 'none' }}>
                {/* <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <PricingIcon sx={{ width: '2.4rem', height: '2.2rem' }} />
                </Stack> */}
                {subEventValid ? (
                  <Swiper
                    onSwiper={setSwiper}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    style={{
                      width: '2.4rem',
                    }}
                  >
                    <SwiperSlide
                      style={{
                        display: 'flex',
                        width: '100%',
                        position: 'relative',
                        justifyContent: 'center',
                      }}
                    >
                      <IconWrapper>
                        <PricingIcon sx={{ width: '2.4rem', height: '2.2rem' }} />
                      </IconWrapper>
                    </SwiperSlide>

                    <SwiperSlide
                      style={{
                        display: 'flex',
                        width: '100%',
                        // height: '100%',
                        position: 'relative',
                        justifyContent: 'center',
                      }}
                    >
                      <IconWrapper>
                        <CouponIcon sx={{ width: '2.4rem', height: '2.2rem' }} />
                      </IconWrapper>
                    </SwiperSlide>
                  </Swiper>
                ) : (
                  <IconWrapper>
                    <PricingIcon sx={{ width: '2.4rem', height: '2.2rem' }} />
                  </IconWrapper>
                )}
              </MainAppBarRouterLink>

              <IconButton onClick={handleClick} size="small">
                <MypageIcon sx={{ width: '1.8rem', height: '2.2rem' }} />
              </IconButton>

              <Box
                sx={{
                  zIndex: 9999,
                  position: 'absolute',
                  top: '5rem',

                  // display: showDropdownMenu ? 'block' : 'none',
                  visibility: showDropdownMenu ? 'visible' : 'hidden',
                }}
              >
                <CardMenu open={showDropdownMenu} setOpen={setShowDropdownMenu} />
              </Box>
            </Box>
          </Box>
        </Stack>
        <Backdrop
          sx={{
            zIndex: 1000,
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            background: 'transparent',
          }}
          onClick={() => {
            setShowDropdownMenu(false)
          }}
          open={showDropdownMenu}
        />
      </Mobile>
    </>
  )
}

function NonMemberToolbarContent({ hideLineBanner = false }) {
  const navigate = useNavigate()
  const { navigateWithQuery } = useNavigateForPromotion()

  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const [subMenu, setSubMenu] = useRecoilState(subMenuAtom)
  const resetSubMenu = useResetRecoilState(subMenuAtom)
  const { t, i18n } = useTranslation()

  const location = useLocation()

  const isPromotion = location.pathname.includes('promotion')

  const theme = useTheme()

  const isMobile = useMobileMediaQuery()

  const textButtonStyle = {
    color: 'white',
    fontSize: '2.2rem',
    fontWeight: 600,
    cursor: 'pointer',
  }

  // 서브메뉴 안 닫을 주소

  const { menu, getCurrentMenu, MAINTAIN_MENU, GENERATE_SUB, MODEL_SUB } = useMenu()
  const MAINTAIN_MENU_BOOLEN = MAINTAIN_MENU.some(path => location.pathname.includes(path))

  const threshold = 400

  useEffect(() => {
    if (MAINTAIN_MENU_BOOLEN) {
      if (GENERATE_SUB.some(path => location.pathname.includes(path))) {
        setSubMenu({ open: true, main: 'generate' })
      }
      if (MODEL_SUB.some(path => location.pathname.includes(path))) {
        setSubMenu({ open: true, main: 'model' })
      }
    } else {
      resetSubMenu()
    }
    return () => {
      if (!MAINTAIN_MENU_BOOLEN) {
        resetSubMenu()
      }
    }
  }, [location.pathname])

  return (
    <>
      <Desktop>
        <Stack
          sx={{ width: '100%' }}
          onMouseLeave={e => {
            if (MAINTAIN_MENU_BOOLEN) {
              if (GENERATE_SUB.some(path => location.pathname.includes(path))) {
                setSubMenu({ open: true, main: 'generate' })
              }
              if (MODEL_SUB.some(path => location.pathname.includes(path))) {
                setSubMenu({ open: true, main: 'model' })
              }
            } else {
              resetSubMenu()
            }
          }}
        >
          {!hideLineBanner && (
            <CenterAlignBox sx={{ backgroundColor: theme => theme.palette.draph.blue }}>
              <AppbarUpperBox sx={{ width: '100%', background: 'transparent', p: 0 }}>
                <LineBannerSwiper />
              </AppbarUpperBox>
            </CenterAlignBox>
          )}
          <CenterAlignBox sx={{}}>
            <AppbarDownerBox>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '29.2rem',
                }}
              >
                <RouterLink
                  to="/home"
                  target={isPromotion ? '_blank' : '_self'}
                  style={{ textDecoration: 'none', width: '25rem' }}
                  onClick={() => {
                    triggerGAEvent('logo')
                    if (location.pathname === '/home') {
                      // XXX 튜토리얼 실행 테스트 - dev only
                      if (process.env.NODE_ENV === 'development') {
                        // 튜토 OFF
                        // setTutorial(prev => ({ step: 0, mode: true }))
                      } else {
                        window.location.reload()
                      }
                    }
                  }}
                >
                  <Stack direction="row" alignItems="center" sx={{ minWidth: '23rem' }}>
                    <img src="/static/images/logo/logo_h_white2.png" />
                  </Stack>
                </RouterLink>
              </Box>
              {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}></Box> */}
              <CenterMenu MAINTAIN_MENU_BOOLEN={MAINTAIN_MENU_BOOLEN} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  // width: '25rem',
                  minWidth: '15rem',
                }}
                gap="2rem"
              >
                <GradationOutlineButton
                  className="appbar-login-button"
                  onClick={() => {
                    navigateWithQuery('/login')
                    triggerGAEvent('login')
                  }}
                  sx={{
                    width: isKo(i18n) ? '12rem !important' : '10rem !important',
                  }}
                >
                  {t('login.login')}
                </GradationOutlineButton>
                <GradationButton
                  sx={{ width: '15.2rem', height: '4rem', fontSize: '1.8rem', fontWeight: 700 }}
                  onClick={() => {
                    navigateWithQuery('/join')
                    triggerGAEvent('start_free')
                  }}
                >
                  {t('button.start_free')}
                </GradationButton>
              </Box>
            </AppbarDownerBox>
          </CenterAlignBox>
          <CenterAlignBox>
            <SubMenuAppBar />
          </CenterAlignBox>
        </Stack>
      </Desktop>

      <Mobile>
        <Stack sx={{ width: '100%' }}>
          {!hideLineBanner && (
            <Box sx={{ height: '4rem' }}>
              <LineBannerSwiper />
            </Box>
          )}
          <Box
            sx={{
              height: '5.2rem',
              px: '2rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {!tutorial.mode && (
                <RouterLink
                  id="appbar-logo"
                  to="/home"
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    triggerGAEvent('logo')
                    if (location.pathname === '/home') {
                      if (process.env.NODE_ENV === 'development') {
                        // 튜토 OFF
                        // setTutorial(prev => ({ step: 0, mode: true }))
                      } else {
                        window.location.reload()
                      }
                    }
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    <img src="/static/images/logo/logo_h_white2.png" style={{ height: '3.3rem' }} />
                  </Stack>
                </RouterLink>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '0.8rem',
              }}
            >
              <GradationOutlineButton
                className="appbar-login-button"
                onClick={() => {
                  navigate('/login')
                  triggerGAEvent('login')
                }}
              >
                {t('login.login')}
              </GradationOutlineButton>
              <GradationButton
                sx={{
                  width: '7.6rem !important',
                  height: '2.8rem !important',
                  fontSize: '1.3rem !important',
                  fontWeight: 700,
                }}
                onClick={() => {
                  navigateWithQuery('/join')
                  triggerGAEvent('start_free')
                }}
              >
                {t('menu.Register')}
              </GradationButton>
            </Box>
          </Box>
        </Stack>
      </Mobile>
    </>
  )
}

const StartButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.black,

  '&, &:hover': {
    border: '0.2rem solid',
    borderColor: theme.palette.draph.green,
    borderRadius: '0.5rem',
  },

  width: '14rem',
  fontWeight: 800,
  fontSize: '1.5rem',
  transition: 'box-shadow .5s',

  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    boxShadow: '0 0 1rem rgba(0, 232, 185, 0.5)',
  },

  [theme.breakpoints.down('lg')]: {
    width: '8.4rem',
    fontSize: '1.2rem',
    padding: 0,
    borderWidth: '0.1rem',
  },
}))

const TextLinkButton = styled(Button)(({ theme }) => ({
  fontSize: '1.4rem',
  fontWeight: 400,
  color: theme.palette.common.black,
  textDecoration: 'none !important',
  variant: 'text',
  minWidth: 'auto',
  padding: 0,

  '&:hover': {
    backgroundColor: 'unset',
    fontWeight: 500,
  },
}))

const MainAppBarRouterLink = styled(RouterLink)(({ theme }) => ({
  // width: '5.5rem',

  [theme.breakpoints.down('lg')]: {
    width: 'unset',
  },
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: 'unset',
    fontWeight: 500,
    opacity: 0.8,
  },
}))

const MobileMainAppBarRouterLink = styled(RouterLink)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  fontWeight: 400,
  fontSize: '1.4rem',
}))

function NoticeBanner() {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [swiper, setSwiper] = useState(null)

  const [promotionAnchor, setPromotionAnchor] = useRecoilState(promotionAnchorAtom)
  const noticeBanners = useRecoilValue(noticeBannersAtom)

  const isPromotion = location.pathname.includes('promotion')

  const navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <>
      <Swiper
        slidesPerView={1}
        loop={true}
        // autoplay={{
        //   delay: 5000,
        //   disableOnInteraction: false,
        // }}
        // navigation={{
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // }}
        onSwiper={setSwiper}
        modules={[Autoplay, Pagination, Navigation]}
        onSlideChange={() => {
          setCurrentIndex(swiper.realIndex)
        }}
      >
        {noticeBanners.map((banner, idx) => {
          return (
            <SwiperSlide
              key={idx}
              style={{
                display: 'flex',
                width: '100%',
                // height: '100%',
                position: 'relative',
                justifyContent: 'center',
                backgroundColor: banner.bgColor,
              }}
            >
              <CenterAlignStack
                sx={{
                  position: 'relative',
                  // cursor: 'pointer',
                }}
                onClick={() => {}}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme => banner.textColor ?? theme.palette.common.black,
                    fontSize: { lg: '2rem', xs: '1.4rem' },
                    fontWeight: 700,
                  }}
                >
                  <span>{banner.content[i18n.language] ?? t(`app_notice.${banner.type}`)}</span>

                  {/* <Button
                    onClick={() => {
                      navigate(banner.url)
                      setPromotionAnchor(idx)
                    }}
                    sx={{ minWidth: { xs: 'unset' } }}
                  >
                    <Desktop>
                      <RightArrowIcon color={banner.textColor} />
                    </Desktop>
                    <Mobile>
                      <RightArrowShortIcon color={banner.textColor} />
                    </Mobile>
                  </Button> */}
                </Typography>
                {/* <img
                  style={{ width: '128rem', height: '100%', objectFit: 'cover' }}
                  src={banner.img}
                /> */}
              </CenterAlignStack>
            </SwiperSlide>
          )
        })}
        {/* <div
          title="next"
          className={
            currentIndex === 3
              ? 'swiper-button-next home-icon-gray'
              : 'swiper-button-next home-icon-black'
          }
        ></div>
        <div
          title="prev"
          className={
            currentIndex === 3
              ? 'swiper-button-prev home-icon-gray'
              : 'swiper-button-prev home-icon-black'
          }
        ></div> */}
      </Swiper>
    </>
  )
}

const hoverUnderlineStyle = {
  position: 'relative',
  '&::before': {
    content: "''",
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '0.6rem',
    bottom: 0,
    left: 0,
    backgroundColor: theme => theme.palette.draph.green,
    transform: 'scaleX(0)',
    transition: 'transform 0.3s ease',
  },
  '&:hover::before': {
    transform: 'scaleX(1)',
  },
}
const CenterMenu = ({ MAINTAIN_MENU_BOOLEN }) => {
  const { t, i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const { menu } = useMenu()
  const [subMenu, setSubMenu] = useRecoilState(subMenuAtom)

  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // gap: '2rem',
      }}
    >
      {/* {menu.map((item, idx) => (
        <Box key={item.id} sx={{ ...item.style, cursor: 'pointer', py: '2.35rem', px: '1.5rem' }}>
          <CenterAlignBox sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              fontWeight={600}
              fontSize={isKo(i18n) ? '2.2rem' : '2rem'}
              sx={{
                color: location.pathname.includes(item.id)
                  ? theme.palette.draph.green
                  : theme.palette.common.white,
              }}
            >
              {t(`menu.${item.id}`)}
            </Typography>
          </CenterAlignBox>
        </Box>
      ))} */}
      {menu.map((item, idx) => {
        if (!item.children) {
          return (
            <Box
              key={item.id + idx}
              // to={item.path}
              onClick={() => {
                triggerGAEvent(item.id)
                navigate(item.path)
              }}
              onMouseOver={() => {
                // if (MAINTAIN_MENU_BOOLEN) {
                //   if (GENERATE_SUB.some(path => location.pathname.includes(path))) {
                //     setSubMenu({ open: true, main: 'generate' })
                //   }
                //   if (MODEL_SUB.some(path => location.pathname.includes(path))) {
                //     setSubMenu({ open: true, main: 'model' })
                //   }
                // } else {
                //   setSubMenu({ open: false, main: '' })
                // }
                setSubMenu({ open: false, main: '' })
              }}
              sx={{
                ...item.style,
                cursor: 'pointer',
                p: '2.35rem 1.5rem',

                ...hoverUnderlineStyle,
              }}
            >
              <CenterAlignBox sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {item.id === 'adsV2' && (
                  <CenterAlignBox
                    sx={{
                      mr: '0.6rem',
                      borderRadius: '4px',
                      backgroundColor:
                        location.pathname.split('/')[1] === item.id
                          ? theme.palette.draph.green
                          : theme.palette.common.white,
                      width: '5.6rem',
                      height: '2.6rem',
                      fontSize: '2rem',
                      fontWeight: 600,
                      color: '#161616',
                    }}
                  >
                    Beta
                  </CenterAlignBox>
                )}
                <Typography
                  fontWeight={600}
                  fontSize="2.2rem"
                  sx={{
                    color:
                      location.pathname.split('/')[1] === item.id
                        ? theme.palette.draph.green
                        : theme.palette.common.white,
                  }}
                >
                  {t(`menu.${item.id}`)}
                </Typography>
              </CenterAlignBox>
            </Box>
          )
        } else {
          return (
            <Box
              key={item.id + idx}
              onClick={() => {
                triggerGAEvent(item.id)
                navigate(item.path)
              }}
              onMouseOver={() => {
                setSubMenu({ open: true, main: item.id })
              }}
              sx={{
                ...item.style,
                cursor: 'pointer',
                padding: '2.35rem 1.5rem',
                ...hoverUnderlineStyle,
              }}
            >
              <CenterAlignBox sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  fontWeight={600}
                  fontSize="2.2rem"
                  sx={{
                    color: location.pathname.split('/')[1].includes(item.id)
                      ? theme.palette.draph.green
                      : theme.palette.common.white,
                  }}
                >
                  {t(`menu.${item.id}`)}
                </Typography>
              </CenterAlignBox>
            </Box>
          )
        }
      })}
    </Box>
  )
}

const SubMenuAppBar = () => {
  const theme = useTheme()
  const [subMenu, setSubMenu] = useRecoilState(subMenuAtom)

  return (
    <Collapse
      in={subMenu.open}
      sx={{
        width: '100%',
        opacity: subMenu.open ? 1 : 0,
        transition:
          'opacity cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <CenterAlignStack
        sx={{
          height: APPBAR_SUBMENU,
          backgroundColor: theme.palette.common.white,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SubMenu />
      </CenterAlignStack>
    </Collapse>
  )
}

const subMenuHighlightBackground =
  'linear-gradient(to top, rgba(0, 232, 185, 0.20) 36%, rgba(0, 0, 0, 0) 36%)'
const findObjectById = (menu, subMenu) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].id === subMenu) {
      return menu[i]
    }
  }
  // 해당 ID를 가진 객체를 찾지 못한 경우
  return null
}
const SubMenu = () => {
  const { t, i18n } = useTranslation()
  const { menu } = useMenu()
  const [subMenu, setSubMenu] = useRecoilState(subMenuAtom)

  const location = useLocation()
  const theme = useTheme()

  return (
    <>
      <CenterAlignBox
        onMouseOver={() => {
          setSubMenu({ ...subMenu, open: true })
        }}
        onMouseMove={() => {
          setSubMenu({ ...subMenu, open: true })
        }}
      >
        {findObjectById(menu, subMenu.main)?.children.map((item, idx) => {
          return <SubMenuItem key={item.id} menu={menu} subMenu={subMenu} item={item} idx={idx} />
        })}
      </CenterAlignBox>
    </>
  )
  // <Box
  //   sx={{
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //   }}
  // >
  //   {subMenu.map((item, idx) => (
  //     <StyledLink
  //       key={item.id}
  //       to={item.path}
  //       onClick={() => {
  //         triggerGAEvent(item.id)
  //       }}
  //       style={{ ...item.style }}
  //     >
  //       <CenterAlignBox sx={{ justifyContent: 'center', alignItems: 'center' }}>
  //         {item.id === 'removebg' && (
  //           <Typography
  //             sx={{
  //               color: theme.palette.common.white,
  //               fontSize: '1.1rem',
  //               borderRadius: '2rem',

  //               border: `1px solid ${theme.palette.common.white}`,
  //               p: '0.1rem 0.7rem',
  //               mr: '0.6rem',
  //               fontWeight: 600,
  //             }}
  //           >
  //             {t('menu.free')}
  //           </Typography>
  //         )}
  //         {item.id === 'mannequin' && (
  //           <Typography
  //             sx={{
  //               color: theme.palette.draph.green,
  //               fontSize: '1.1rem',
  //               borderRadius: '2rem',

  //               border: `1px solid ${theme.palette.draph.green}`,
  //               p: '0.1rem 0.7rem',
  //               mr: '0.6rem',
  //               fontWeight: 600,
  //             }}
  //           >
  //             {t('menu.beta')}
  //           </Typography>
  //         )}
  //         <Typography
  //           fontWeight={600}
  //           fontSize={isKo(i18n) ? '2.2rem' : '2rem'}
  //           sx={{
  //             color: location.pathname.includes(item.id)
  //               ? theme.palette.draph.green
  //               : theme.palette.common.white,
  //           }}
  //         >
  //           {t(`menu.${item.id}`)}
  //         </Typography>
  //       </CenterAlignBox>
  //     </StyledLink>
  //   ))}
  // </Box>
}

function SubMenuItem({ menu, subMenu, item, idx }) {
  const { t } = useTranslation()
  const [mouseOn, setMouseOn] = useState(false)

  return (
    <Box key={item.id} sx={{ display: 'flex' }}>
      <StyledLink
        to={item.path}
        onClick={() => {
          triggerGAEvent(item.id)
        }}
        style={{ ...item.style, cursor: 'pointer' }}
        onMouseOver={() => {
          setMouseOn(true)
        }}
        onMouseLeave={() => {
          setMouseOn(false)
        }}
      >
        <CenterAlignBox
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage:
              (item.id === 'face' && location.pathname.includes('face')) || mouseOn
                ? subMenuHighlightBackground
                : location.pathname.includes(item.path.split('?')[0]) || mouseOn
                ? subMenuHighlightBackground
                : 'transparent',
          }}
        >
          {item.id === 'removebg' && (
            //   <Box
            //   sx={{
            //     display: 'flex',
            //     justifyContent: 'center',
            //     alignItems: 'center',
            //     color: theme => theme.palette.common.white,
            //     fontSize: '1.2rem',
            //     borderRadius: '4px',

            //     background: theme => theme.palette.common.black,
            //     p: '0.5rem 1rem',
            //     mr: '0.6rem',
            //     fontWeight: 600,
            //   }}
            // >
            //   {t('menu.free')}
            // </Box>
            <Typography
              sx={{
                color: theme => theme.palette.common.black,
                fontSize: '1.1rem',
                borderRadius: '2rem',

                border: theme => `1px solid ${theme.palette.common.black}`,
                p: '0.1rem 0.7rem',
                mr: '0.6rem',
                fontWeight: 600,
              }}
            >
              {t('menu.free')}
            </Typography>
          )}
          <Typography
            fontWeight={
              (item.id === 'face' && location.pathname.includes('face_')) || mouseOn
                ? 800
                : location.pathname.includes(item.path.split('?')[0])
                ? 800
                : 600
            }
            fontSize="1.8rem"
            sx={{}}
          >
            {t(`menu.${item.id}`, item.title)}
          </Typography>
        </CenterAlignBox>
      </StyledLink>
      {!(findObjectById(menu, subMenu.main)?.children.length - 1 === idx) && (
        <Divider orientation="vertical" flexItem sx={{ m: '0.3rem 2.4rem' }} />
      )}
    </Box>
  )
}

function CardMenu({ open, setOpen }) {
  const { t, i18n } = useTranslation()
  const [credit, setCredit] = useRecoilState(userCreditAtom)
  const [lang, setLang] = useRecoilState(languageAtom)
  const resetUser = useResetRecoilState(userAtom)
  const { pathname } = useLocation()

  const [langExpand, setLangExpand] = useState(false)

  const navigate = useNavigate()
  const logoutUser = () => {
    removeUserCookie()
    resetUser()
    window.location.reload()
    // navigate('/home')
  }

  const dropdownGAEvent = menu => {
    const EVENT_NAME = 'mypage_dropdown'
    // ----- GA4 event -----
    window.gtag('event', EVENT_NAME, {
      menu,
    })
    // ---------------------
  }

  const menuItems = [
    {
      to: '/user/mypage',
      icon: <CoinStackIcon />,
      title: t('mypage_menu.credit_and_subscriptions'),
      menu_name: 'credit',
      endAdornment: (
        <Box
          sx={{
            background: theme => theme.palette.draph.blue,
            color: 'white',
            px: '0.6rem',
            py: '0.2rem',
            borderRadius: '4px',
            fontSize: '1.2rem',
            fontWeight: 700,
            maxWidth: '6.5rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {!isNaN(credit) && numberCommaFormatter(credit)}
        </Box>
      ),
    },

    // {
    //   to: '/user/background',
    //   icon: <ImageIcon />,
    //   title: t('mypage_menu.my_image'),
    //   menu_name: 'my_image',
    // },

    {
      to: '/user/coupon',
      icon: <CouponIcon2 />,
      title: t('mypage_menu.coupon'),
      menu_name: 'coupon',
    },
    {
      to: '/user/profile',
      icon: <MypageIcon2 />,
      title: t('mypage_menu.user_info'),
      menu_name: 'profile',
    },
    {
      to: '/api_main',
      icon: <APIIcon />,
      title: 'API',
      menu_name: 'API',
      isOnlyKorean: true,
    },
    {
      to: null,
      icon: <LanguageIcon />,
      title: t('mypage_menu.language'),
      onClick: e => {
        e.stopPropagation()
        // setLangExpand(x => !x)
        onClickLanguage(i18n.language === 'ko' ? 'en' : 'ko')
      },
      endAdornment: (
        <Stack
          direction="row"
          sx={{
            background: '#EEEEEE',
            px: '0.6rem',
            py: '0.2rem',
            borderRadius: '4px',
            gap: '0.6rem',

            '& .text': {
              fontSize: '1.2rem',
              fontWeight: 700,
              color: '#989898',
              '&.on': {
                color: theme => theme.palette.common.black,
              },
            },
          }}
        >
          <Typography
            className={`text ${i18n?.language === 'ko' && 'on'}`}
            onClick={e => {
              e.stopPropagation()
              // setLangExpand(false)
              onClickLanguage('ko')

              const isBlog = pathname.includes('blog')
              if (isBlog) {
                const updatedUrl = pathname.replace(/\/blog\/[a-z]{2}/, `/blog/ko`)
                navigate(`${updatedUrl}`)
              }
            }}
          >
            KR
          </Typography>

          <Typography
            className={`text ${ENGLISH_LANGUAGES.includes(i18n.language) && 'on'}`}
            onClick={e => {
              e.stopPropagation()
              // setLangExpand(false)
              onClickLanguage('en')

              const isBlog = pathname.includes('blog')
              if (isBlog) {
                const updatedUrl = pathname.replace(/\/blog\/[a-z]{2}/, `/blog/en`)
                navigate(`${updatedUrl}`)
              }
            }}
          >
            ENG
          </Typography>
        </Stack>
      ),
    },
    {
      to: '',
      icon: <LogoutIcon2 />,
      title: t('mypage_menu.logout'),
      endAdornment: <></>,
      onClick: () => {
        dropdownGAEvent('logout')
        logoutUser()
      },
    },
  ]

  useEffect(() => {
    apis.user.getCredit().then(response => {
      setCredit(response.data.credit ?? 0)
    })
  }, [])

  useEffect(() => {
    if (!open) {
      setLangExpand(false)
    }
  }, [open])

  const cardStyle = {
    boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.15)',

    minWidth: '23.4rem',
    borderRadius: '1rem',

    '& .menu-cell': {
      width: 'max-content',
      pr: '1.2rem',
    },

    '& .icon-wrapper': {
      width: '3.2rem',
      height: '3.2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .item': {
      height: '4rem',
      px: '1.2rem',
      cursor: 'pointer',
      '&:hover': { background: '#F2F6FF' },
      '& svg path': { stroke: theme => theme.palette.common.black },
      '& .title': {
        fontSize: '1.4rem',
        fontWeight: 700,
      },
    },
  }

  const onClickLanguage = newLang => {
    dropdownGAEvent(`language_${lang}_${newLang}`)

    setLang(newLang)
    i18n.changeLanguage(newLang)
    window.localStorage.setItem('i18nextLng', newLang)
    setCookie('language', newLang)
  }

  return (
    <>
      <Card sx={cardStyle}>
        <Stack>
          {menuItems.map((item, idx) => {
            if (item.isOnlyKorean && !isKo(i18n)) return null

            return (
              <CenterAlignStack
                direction="row"
                className="item"
                key={idx}
                sx={{ justifyContent: 'space-between' }}
                onClick={e => {
                  if (item.onClick) {
                    item.onClick(e)
                  } else {
                    if (item.menu_name) {
                      dropdownGAEvent(item.menu_name)
                    }
                    navigate(item.to)
                    setOpen(false)
                  }
                }}
              >
                <CenterAlignStack direction="row" className="menu-cell">
                  <Box className="icon-wrapper">{item.icon}</Box>
                  <Typography className="title">{item.title}</Typography>
                </CenterAlignStack>

                {item.endAdornment}
              </CenterAlignStack>
            )
          })}
        </Stack>
      </Card>
    </>
  )
}
