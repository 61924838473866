import {
  BrandIcon,
  CoverLayerIcon,
  DesignIcon,
  IllustrationIcon,
  MainBgIcon,
  ProductFilterIcon,
  TextIcon,
} from 'theme/icon'

export const useAddInfo = () => {
  const SHAPE_LIST_ADDINFO = {
    background: { avatar: <MainBgIcon />, title: '메인 배경' },
    inpaint: { avatar: <ProductFilterIcon />, title: '상품' },
    logo: { avatar: <BrandIcon />, title: '로고' },
    image: {
      avatar: <DesignIcon />,
      title: '이미지 요소',
      children: {
        paste: {
          avatar: <ProductFilterIcon />,
          title: '상품 필터',
        },
        illustration: {
          avatar: <IllustrationIcon />,
          title: '일러스트',
        },
        concept: {
          avatar: <DesignIcon />,
          title: '컨셉 이미지',
        },
        reference: {
          avatar: <DesignIcon />,
          title: '레퍼런스',
        },
        inpaint: {
          avatar: <CoverLayerIcon />,
          title: '커버 레이어',
        },
      },
    },
    text: { avatar: <TextIcon />, title: '텍스트' },
    inpaint_mask: { avatar: <CoverLayerIcon />, title: '커버 레이어' },
  }

  return { SHAPE_LIST_ADDINFO }
}
