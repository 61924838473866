import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import MobileSwipeableDrawer from './MobileSwipeableDrawer'
import { useEffect, useState } from 'react'
import { apis } from 'apis'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export function PromotionPricingDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': {
              width: '38rem',
              borderRadius: '1rem',
            },
          }}
        >
          <DialogContent sx={{ px: '2.2rem' }}>
            <Content />
          </DialogContent>
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <Box sx={{ px: '2rem' }}>
            <Content />
          </Box>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

function Content() {
  const [code, setCode] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const navigate = useNavigate()

  const authPartner = () => {
    apis.partners
      .authCode({
        code: code.replace(/\s/g, ''),
      })
      .then(response => {
        if (response.data?.event_id) {
          navigate('/pricing/promotion', {
            state: {
              event_id: response.data.event_id,
              start_date: response.data.start_date,
              end_date: response.data.end_date,
              code: code.replace(/\s/g, ''),
              // partner,
            },
          })
        } else {
          alert('유효하지 않은 정보입니다.')
        }
      })
  }

  return (
    <>
      <Stack>
        <Typography
          sx={{ fontSize: { lg: '2.4rem', xs: '1.6rem' }, fontWeight: { lg: 700, xs: 800 } }}
        >
          환영합니다!
        </Typography>

        <Box
          sx={{
            width: '100%',
            background: '#F8F8F8',
            px: { lg: '1.8rem', xs: '1.6rem' },
            py: '1.6rem',
            borderRadius: '1rem',
            mt: { lg: '2rem', xs: '2.4rem' },
            mb: { lg: '0.6rem', xs: 0 },
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 400, lineHeight: 1.2 }}>
            코드를 입력하고 프로모션 요금제를 확인하세요.
          </Typography>
        </Box>

        <Divider flexItem sx={{ my: '2.4rem' }} />

        <Stack
          sx={{
            width: '100%',
            '& .row': {
              width: '100%',
              justifyContent: 'space-between',
              mb: { lg: '2rem', xs: '1.9rem' },
              '& .name': {
                color: '#989898',
                fontSize: { lg: '1.6rem', xs: '1.4rem' },
                fontWeight: 500,
              },
              '& .value': {
                fontSize: { lg: '1.6rem', xs: '1.4rem' },
                fontWeight: 500,
              },

              '& .input-value': {
                px: '1.2rem',
                py: '0.4rem',
                width: '18rem',
                '& input': {
                  p: 0,
                  fontSize: { lg: '1.4rem', xs: '1.2rem' },
                  fontWeight: 400,
                },
              },
            },
          }}
        >
          <Stack direction="row" className="row">
            <Typography className="name">코드번호</Typography>
            <OutlinedInput
              className="input-value"
              value={code}
              onChange={e => setCode(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') authPartner()
              }}
            />
          </Stack>
        </Stack>
        <Stack sx={{ width: '100%', display: 'flex', alignItems: 'end' }}>
          <Button
            variant="contained"
            onClick={authPartner}
            sx={{ width: '9.9rem', height: '4rem' }}
          >
            확인
          </Button>
        </Stack>
      </Stack>
    </>
  )
}
