import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { getCurrencyFormatter } from 'utils/common'
import { creditPlansAtom, partnerPlansAtom, userAtom } from '../atoms'
import moment from 'moment'
import { numberCommaFormatter, padZeros } from '../utils/common'
import { apis } from 'apis'
import { addSessionStorageData, SUB_EVENT_ID } from '../pages/Pricing'
import { useNavigate } from 'react-router-dom'

export const generatePaymentUid = () => {
  const d = moment().format('YYYYMMDDHHmmss')
  let uuid = Math.ceil(Math.random() * 100000000)
  uuid = padZeros(uuid, 8)

  return `${d}-${uuid}`
}

export function usePricing() {
  const { t, i18n } = useTranslation()
  const isKo = i18n.language === 'ko'

  const userInfo = useRecoilValue(userAtom)
  const navigate = useNavigate()

  const pricingPlanDict = {
    subscription: t('payment.subscription'),
    payGo: t('payment.payGo'),
    paygo: t('payment.paygo'),
    monthly: t('payment.monthly'),
    bespoke: t('pricing.bespoke'),
  }

  const paymentMethodDict = {
    card: t('payment.card'),
    paypal: t('payment.paypal'),
  }

  const getPlanDisplayName = planId => {
    if (!planId) return
    let name = planId.split('_')[0]
    if (planId.includes('monthly')) {
      name = name.replace('monthly', `${pricingPlanDict.monthly} `)
    } else if (planId.includes('paygo')) {
      name = name.replace('paygo', `${pricingPlanDict.paygo} `)
    } else if (planId.includes('bespoke')) {
      name = t('pricing.bespoke') + ' (' + t(`pricing.${planId}`, '') + ')'
    }
    return name
  }

  const getCurrencySymbol = (currency = isKo ? 'krw' : 'usd') => {
    const c = currency?.toLowerCase() ?? ''
    if (c === 'usd') {
      return '$'
    } else if (c === 'krw') {
      return '₩'
    }

    return ''
  }

  // 플랜에서 가격만
  const getPlanPrice = (plans, planId) => {
    if (!planId) return
    // 간이 예외처리 ㅠㅠ
    if (planId.includes('admin')) {
      const splitPlanId = planId.split('-')
      planId = splitPlanId[0]
    }

    const allPlans = [...plans.subscription, ...plans.payGo]
    const plan = allPlans.find(p => p.id === planId)
    if (!plan) return

    const currency = isKo ? 'krw' : 'usd'
    const currencyFormatter = getCurrencyFormatter(currency)
    const v = currencyFormatter.format(plan.price[currency]).slice(1)

    return v
  }

  // 국내결제 요청 (페이플)
  const requestPay = ({ paymentType, plan, finalPrice, eventId }) => {
    console.log('결제요청시작')
    if (userInfo.master_user_id) {
      alert('마스터 유저가 아닙니다.')
      return
    }

    const orderId = generatePaymentUid()

    const customPaymentInfo = {
      payment_type: paymentType.toLowerCase(),
      payment_cycle: plan.cycle,
      order_id: orderId,
      credit_amount: plan.creditAmount,
      plan_id: plan.id,
      plan_name: getPlanDisplayName(plan.id),
      event_id: eventId,
      plan_normal_price: plan.price.krw,
    }
    addSessionStorageData(customPaymentInfo)

    const paymentTotal = !isNaN(finalPrice) ? finalPrice : plan.price.krw
    const authOnly = paymentType === 'subscription' && paymentTotal === 0

    const b = {
      PCD_PAY_TYPE: 'card',
      PCD_PAY_WORK: paymentType === 'subscription' ? (authOnly ? 'AUTH' : 'PAY') : 'PAY',
      PCD_CARD_VER: paymentType === 'subscription' ? '01' : '02',
      PCD_PAY_GOODS: '드랩 아트 - ' + getPlanDisplayName(plan.id),
      PCD_PAY_TOTAL: paymentTotal,
      PCD_RST_URL: '/paid',
      // PCD_RST_URL: isMobileDevice ? PAYMENT_REDIRECT_ENDPOINT : '/paid',

      PCD_PAY_OID: orderId,
      PCD_PAYER_NO: userInfo.id,
      PCD_PAYER_NAME: userInfo.username,
      PCD_PAYER_HP: userInfo.phone,
      PCD_PAYER_EMAIL: userInfo.email,
      PCD_USER_DEFINE1: authOnly ? orderId : '',
      PCD_USER_DEFINE2: '',

      callbackFunction: res => {
        // 데이터는 웹훅에서 받아서 처리
        if (res.PCD_PAY_RST === 'success') {
          paymentCallback(res, { ...customPaymentInfo })
        }
      },
    }

    apis.payment
      .auth({ ...b, ...customPaymentInfo })
      .then(response => {
        b.PCD_AUTH_KEY = response.data.PCD_AUTH_KEY
        b.PCD_PAY_URL = response.data.PCD_PAY_URL

        // ----- GA4 event -----
        window.gtag('event', 'begin_checkout', {
          item_category: paymentType, // subscription || paygo
          item_id: plan.id,
          item_name: getPlanDisplayName(plan.id),
          price: paymentTotal,
          currency: 'KRW',
        })
        // ---------------------

        window.PaypleCpayAuthCheck(b)
      })
      .catch(() => {
        alert(t('payment.fail'))
      })

    const paymentCallback = (response, paymentInfo) => {
      console.log(response)
      // ----- GA4 event -----
      window.gtag('event', 'purchase', {
        item_category: paymentType, // subscription || paygo
        item_id: plan.id,
        item_name: getPlanDisplayName(plan.id),
        price: paymentTotal,
        currency: 'KRW',
        transaction_id: orderId,
      })
      // ---------------------

      // ----- Google Ads event -----
      window.gtag_report_conversion_purchase(paymentTotal, orderId)
      // ----------------------------

      // ----- Meta(facebook) pixel event -----
      window.fbq('track', 'Purchase', {
        value: paymentTotal,
        currency: 'KRW',
      })
      // --------------------------------------
      // ----- linkedin event -----
      window.lintrk('track', { conversion_id: 17109489 })
      // --------------------------

      const payToTalText =
        paymentInfo.event_id === SUB_EVENT_ID
          ? `${t('after_payment.free_for_first_month')} ${
              paymentInfo.plan_normal_price &&
              '(' +
                t('after_payment.from_next_month') +
                numberCommaFormatter(paymentInfo.plan_normal_price) +
                t('after_payment.currency_txt') +
                ')'
            } `
          : `${numberCommaFormatter(response?.PCD_PAY_TOTAL)} 원`

      navigate('/paid', { state: { paymentResponse: response, paymentInfo } })
    }
  }

  return {
    pricingPlanDict,
    paymentMethodDict,
    getPlanDisplayName,
    getCurrencySymbol,
    getPlanPrice,
    requestPay,
  }
}
