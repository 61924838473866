import { Image, Layer, Stage } from 'react-konva'
import { useImage } from 'react-konva-utils'
import { getS3ImageSrc } from 'utils/common'

const ShapeImage = ({ shape }) => {
  const [image] = useImage(getS3ImageSrc(shape.result_s3_url), 'anonymous')
  return <Image image={image} x={shape.x} y={shape.y} width={shape.width} height={shape.height} />
}

export function ShapesDisplay({ config, width, height }) {
  const { shapes, canvasSize } = config

  return (
    <Stage width={width || canvasSize.width} height={height || canvasSize.height}>
      <Layer>
        {shapes.map(shape => (
          <ShapeImage key={shape.id} shape={shape} />
        ))}
      </Layer>
    </Stage>
  )
}
