import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { styled, useTheme } from '@mui/material/styles'
import {
  Stack,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
  Autocomplete,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { GlobalStyles } from '@mui/system'
import { bannerSelectedFontListsAtom, bannerSelectedLoadingAtom } from 'atoms'
import { useQueries } from '@tanstack/react-query'
import { apis } from 'apis'
import { CustonBannerToggleButton } from 'components/CustomStyle'
import { useInfiniteFont } from 'hooks/useReactQuery'
import { FONT_SIZES } from '../EditCanvas'
import { DownArrowIcon, SelectSideIcon } from 'theme/icon'
import { rgbToHex, toRGBA } from 'utils/common'
import { ChromePicker } from 'react-color'
import { CommonButtonArea } from '../BannerEditorV2'

const CustomStyleSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D9E1EC',
  },

  '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
    borderColor: theme.palette.draph.blue,
  },
  '&:not(.Mui-disabled).Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
    borderColor: theme.palette.draph.blue,
  },
}))

// 상수 분리
const COPY_TYPE_OPTIONS = [
  { value: 'info', label: '정보전달' },
  { value: 'question', label: '궁금증유발' },
  { value: 'review', label: '인기강조' },
  { value: 'price', label: '가격강조' },
]

const TextSettings = ({
  pieceConfig,
  selectedLayer,
  updatePieceHandler,
  retryHandler,
  directConfigUpdate,
}) => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)
  const [selectedLoading, setSelectedLoading] = useRecoilState(bannerSelectedLoadingAtom)
  const [selectedFontLists, setSelectedFontLists] = useRecoilState(bannerSelectedFontListsAtom)

  const [selectedFontId, setSelectedFontId] = useState(selectedLayer.config.text_font)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  // Display 용 (실시간으로 selectedLayer을 변경할 경우, Canvas 를 계속 그리는 문제로 )
  const [displayColor, setDisplayColor] = useState(toRGBA(selectedLayer.config.text_color))

  useEffect(() => {
    setDisplayColor(selectedLayer.config.text_color)
  }, [selectedLayer.config.text_color])
  // --------------------------------------

  const inputRef = useRef(null)

  const handleIconClick = () => {
    setOpen(true) // 아이콘 클릭 시 메뉴 열기
    // 입력 필드에 포커스 주기
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleClick = () => {
    setDisplayColorPicker(p => !p)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleColorChange = newColor => {
    // console.log(newColor.rgb, [
    //   Number(newColor.rgb.r),
    //   Number(newColor.rgb.g),
    //   Number(newColor.rgb.b),
    //   Math.ceil(Number(newColor.rgb.a * 255)),
    // ])
    // directConfigUpdate('text_color', [
    //   Number(newColor.rgb.r),
    //   Number(newColor.rgb.g),
    //   Number(newColor.rgb.b),
    //   Math.ceil(Number(newColor.rgb.a * 255)),
    // ])
    setDisplayColor([
      Number(newColor.rgb.r),
      Number(newColor.rgb.g),
      Number(newColor.rgb.b),
      Math.ceil(Number(newColor.rgb.a * 255)),
    ])
  }

  const handleChangeComplete = newColor => {
    directConfigUpdate('text_color', [
      Number(newColor.rgb.r),
      Number(newColor.rgb.g),
      Number(newColor.rgb.b),
      Math.ceil(Number(newColor.rgb.a * 255)),
    ])
  }

  const fontQueries = useQueries({
    queries: selectedFontLists.map(font => ({
      queryKey: ['font', font],
      queryFn: () => apis.common.getFont(font),
      select: response => response.data,
      enabled: Boolean(font), // font가 있을 때만 쿼리 실행
    })),
  })

  const selectedFontListsData = fontQueries
    .map(query => query.data)
    .filter(Boolean)
    .flat()

  const { data, error, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteFont()

  const fontLists = data ?? []

  const getFontFaces = fonts => {
    if (fonts && fonts.map) {
      return fonts
        .map(font => {
          const format = font.cdn_path.split('.').at(-1)

          return `
            @font-face {
              font-family: '${font.id}';
              src: url('${font.cdn_path}') format('${format}');
              font-weight: normal;
              font-style: normal;
            }
          `
        })
        .join('\n')
    }
  }

  const handleChange = event => {
    directConfigUpdate('text_font', event.target.value)
  }

  // 카피 타입 변경 핸들러
  const handleCopyTypeChange = async copyType => {
    setSelectedLoading(true)
    directConfigUpdate('text_type', copyType.value)

    const { avatar, ...rest } = selectedLayer
    try {
      const body = {
        layer_info: JSON.stringify(rest),
        default_presets: JSON.stringify(pieceConfig.defaultConfig),
        copy_style: copyType.value,
        lang: 'ko',
      }

      const { data: copyData } = await apis.appfront.copyRetry(body)
      directConfigUpdate('text_list', copyData.recommended_copy)
    } finally {
      setSelectedLoading(false)
    }
  }

  const isValidFont = [...(selectedFontListsData || []), ...(fontLists || [])].some(
    font => font.id === selectedLayer.config.text_font
  )

  return (
    <>
      <CommonButtonArea
        retryHandler={retryHandler}
        updatePieceHandler={updatePieceHandler}
        selectedLayer={selectedLayer}
      />
      <Stack
        sx={{
          '& .button-box': {
            display: 'flex',
            width: '100%',
            gap: '1rem',
            flexFlow: 'wrap',
            mt: '1.7rem',
          },
          '& .copy-button': {
            width: 'calc((100% - 2rem) / 3)',
          },

          '& .text-stack': {
            mt: '2.4rem',
          },

          '& .text-style-box': {
            display: 'flex',
            gap: '0.8rem',
            mt: '2.4rem',

            '& .select-box-title': {
              position: 'absolute',
              fontSize: '1.2rem',
              color: '#808080',
              px: '0.4rem',
              backgroundColor: '#fff',
              top: 0,

              transform: 'translate(0, -50%)',
            },
          },
        }}
      >
        {' '}
        <GlobalStyles styles={getFontFaces(data)} />
        <Stack className="copy-stack">
          <Typography className="title">카피라이트 타입</Typography>

          <Box className="button-box">
            {COPY_TYPE_OPTIONS.map(option => {
              return (
                <CustonBannerToggleButton
                  key={option.value}
                  className="copy-button"
                  variant="cantained"
                  selected={selectedLayer.config.text_type === option.value}
                  value={option.value}
                  onClick={() => handleCopyTypeChange(option)}
                  disabled={selectedLoading}
                >
                  {option.label}
                </CustonBannerToggleButton>
              )
            })}
          </Box>
        </Stack>
        <Stack className="text-stack">
          <Typography className="title">텍스트 디자인</Typography>

          <Box className="text-style-box">
            <FontSelect selectedLayer={selectedLayer} directConfigUpdate={directConfigUpdate} />

            <FormControl variant="outlined" sx={{ position: 'relative' }}>
              <Autocomplete
                freeSolo
                value={selectedLayer.config.text_size.toString()}
                onChange={(event, newValue) => {
                  // console.log('newValue', newValue)

                  directConfigUpdate('text_size', Math.round(Number(newValue)))
                }}
                inputValue={selectedLayer.config.text_size.toString()}
                onInputChange={(event, newInputValue) => {
                  directConfigUpdate('text_size', Math.round(Number(newInputValue)))
                }}
                id="controllable-states-demo"
                options={FONT_SIZES}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onKeyDown={event => {
                  // 좌우 화살표는 기본 동작 유지
                  if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                    return
                  }

                  // 위/아래 화살표로 숫자 증감
                  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    event.preventDefault() // 기본 동작 방지
                    const currentValue = Number(selectedLayer.config.text_size)
                    const newValue = event.key === 'ArrowUp' ? currentValue + 1 : currentValue - 1
                    directConfigUpdate('text_size', Math.round(newValue))
                  }
                }}
                sx={{
                  fontSize: '1.6rem',
                  width: '6.8rem',
                  height: '4rem',
                  '& .MuiSvgIcon-root': {
                    // position: 'absolute',
                    // right: '1rem',
                  },

                  '& .MuiOutlinedInput-root': {
                    p: '0 4px 0 1.2rem !important',
                    borderRadius: '0px',
                  },

                  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                    p: '0 0 0 0',
                    fontSize: '1.6rem',
                    flex: 1,
                    height: '4rem',
                  },
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#D9E1EC',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    borderColor: theme.palette.draph.blue,
                  },
                }}
                ListboxProps={{
                  sx: {
                    fontSize: 12, // 폰트 크기 설정
                    '& .MuiAutocomplete-option': {
                      p: '6px',
                    },
                  },
                }}
                filterOptions={(options, { inputValue }) => {
                  return options
                }}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            <InputAdornment position="end" sx={{ ml: 0, mr: '0.8rem' }}>
                              <IconButton
                                onClick={handleIconClick}
                                sx={{ p: '1px' }}
                                disableTouchRipple
                                disableRipple
                              >
                                <DownArrowIcon />
                              </IconButton>
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  )
                }}
              />

              <Typography className="select-box-title" sx={{ left: '0.8rem' }}>
                크기
              </Typography>
            </FormControl>

            <Box
              sx={{
                width: '6rem',
                height: '4rem',
                borderRadius: '0',
                border: '1px solid #D9E1EC',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                cursor: 'pointer',
              }}
              onClick={handleClick}
            >
              <Box
                sx={{
                  borderRadius: '4px',
                  width: '16px',
                  height: '16px',
                  backgroundColor: toRGBA(displayColor),
                  border: '1px solid #303030',
                  mr: '4px',
                }}
              ></Box>
              <DownArrowIcon />
              <Typography className="select-box-title" sx={{ left: '0.8rem', top: '0rem' }}>
                색상
              </Typography>
            </Box>
            {displayColorPicker && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '2',
                  top: '37.5rem',
                  right: '2rem',
                }}
              >
                <Box
                  sx={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                    zIndex: -1,
                  }}
                  onClick={handleClose}
                />
                <ChromePicker
                  color={toRGBA(displayColor)}
                  onChange={handleColorChange}
                  onChangeComplete={handleChangeComplete}
                  disableAlpha={false}
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
    </>
  )
}

export default TextSettings

const FontSelect = ({ selectedLayer, directConfigUpdate }) => {
  const [selectedFontLists, setSelectedFontLists] = useRecoilState(bannerSelectedFontListsAtom)
  const menuRef = useRef(null)

  const fontQueries = useQueries({
    queries: selectedFontLists.map(font => ({
      queryKey: ['font', font],
      queryFn: () => apis.common.getFont(font),
      select: response => response.data,
      enabled: Boolean(font),
    })),
  })

  const selectedFontListsData = fontQueries
    .map(query => query.data)
    .filter(Boolean)
    .flat()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteFont()
  const fontLists = data ?? []

  const handleChange = event => {
    directConfigUpdate('text_font', event.target.value)
  }

  const handleScroll = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target

    // 스크롤이 하단에 도달했는지 확인 (여유값 20px)
    if (scrollHeight - scrollTop <= clientHeight + 20) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }
  }

  const isValidFont = [...(selectedFontListsData || []), ...(fontLists || [])].some(
    font => font.id === selectedLayer.config.text_font
  )

  return (
    <FormControl variant="outlined" sx={{ position: 'relative' }}>
      <CustomStyleSelect
        value={isValidFont ? selectedLayer.config.text_font : ''}
        IconComponent={DownArrowIcon}
        onChange={handleChange}
        sx={{
          width: '20rem',
          height: '4rem',
          '& .MuiSvgIcon-root': {
            position: 'absolute',
          },
        }}
        renderValue={selected => {
          const selectedFont = [...selectedFontListsData, ...fontLists].find(
            font => font.id === selected
          )
          if (!selectedFont) return <Box>폰트를 선택해주세요</Box>
          return (
            <span
              style={{
                fontFamily: `'${selectedFont?.id}'`,
                fontSize: '1.4rem',
              }}
            >
              {selectedFont?.name}
            </span>
          )
        }}
        MenuProps={{
          PaperProps: {
            ref: menuRef,
            onScroll: handleScroll,
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        {selectedFontListsData.length > 0 && (
          <MenuItem disabled sx={{ backgroundColor: 'grey.100' }}>
            <Typography variant="subtitle2">최근 사용한 폰트</Typography>
          </MenuItem>
        )}
        {selectedFontListsData.map(font => (
          <MenuItem
            key={font.id}
            value={font.id}
            sx={{
              fontFamily: `'${font?.id}'`,
              fontSize: '1.4rem',
            }}
          >
            {font.name}
          </MenuItem>
        ))}

        <MenuItem sx={{ borderBottom: 1, borderColor: 'divider' }} />

        <MenuItem disabled sx={{ backgroundColor: 'grey.100' }}>
          <Typography variant="subtitle2">전체 폰트</Typography>
        </MenuItem>

        {fontLists.map(font => (
          <MenuItem
            key={font.id}
            value={font.id}
            sx={{
              fontFamily: `'${font?.id}'`,
              fontSize: '1.4rem',
            }}
          >
            {font.name}
          </MenuItem>
        ))}

        {isFetchingNextPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
            <CircularProgress size={20} />
          </Box>
        )}
      </CustomStyleSelect>

      <Typography className="select-box-title" sx={{ left: '0.8rem' }}>
        폰트
      </Typography>
    </FormControl>
  )
}
