import dataAxios from 'apis/configs/data'

export const dataAPI = {
  uploadTemplate: (body, id) => {
    return dataAxios.post(`/banner_template/upload?banner_id=${id}`, body)
  },

  getTemplate: id => {
    return dataAxios.get(`/banner_template/download/${id}`)
  },

  updateTemplate: id => {
    return dataAxios.put(`/banner_template/update/${id}`)
  },

  deleteTemplate: id => {
    return dataAxios.delete(`/banner_template/delete/${id}`)
  },

  loadAllTemplate: params => {
    return dataAxios.get(`/banner_template/templates/`, { params })
  },

  searchTemplate: (field, value) => {
    return dataAxios.get(`/banner_template/template/search?field=${field}?value=${value}`)
  },

  presignedURL: ext => {
    return dataAxios.post(`/file/generate-presigned-url?ext=${ext}`)
  },
}
