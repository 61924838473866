import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import * as config from 'config'
import { useEffect, useState } from 'react'

import { apis } from 'apis'
import {
  CenterAlignStack,
  CenterAlignBox,
  DialogButton,
  CustomTooltip,
  MobileSwipeableDrawer,
} from 'components'

import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'

import { t } from 'i18next'
import { downloadWarningDialogAtom, PORTFOLIO_CONFIG_DEFAULT, portfolioAtom } from 'atoms'
import { userPortfolioNamesSelector } from 'selector'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { SwipeableDrawer } from '@mui/material'
import useConfirm from 'hooks/useConfirm'
import { useTranslation } from 'react-i18next'
import { iOS } from 'utils/common'
import { English, Korean } from 'hooks/useLanguage'

export const hideDownloadWarningPopup = 'hideDownloadWarningPopup'

export default function DownloadWarningDialog() {
  const [dialog, setDialog] = useRecoilState(downloadWarningDialogAtom)
  const resetDialog = useResetRecoilState(downloadWarningDialogAtom)

  const [againChecked, setAgainChecked] = useState(false)
  const { open, download } = dialog

  useEffect(() => {}, [])

  const handleClose = (e, reason) => {
    resetDialog()
    setAgainChecked(false)
  }

  const handleDownload = () => {
    download()

    if (againChecked) {
      localStorage.setItem(hideDownloadWarningPopup, true)
    }
    handleClose()
  }

  const content = (
    <>
      <CenterAlignStack>
        <Typography
          sx={{
            fontSize: { lg: '1.4rem', xs: '1.4rem' },
            fontWeight: 400,
            textAlign: 'center',
            '& .bold': { fontWeight: 600 },
          }}
        >
          <Korean>
            다운로드 시, 아트워크를 재생성 할 때{' '}
            <Mobile>
              <br />
            </Mobile>
            <span className="bold">크레딧이 차감됩니다.</span>
            <br />
            다운로드 받으시겠어요?
          </Korean>
          <English>
            If you download this image,{' '}
            <Mobile>
              <br />
            </Mobile>
            <span className="bold">credits will be deducted when regenerating this artwork.</span>
            <br />
            Would you like to proceed with the download?
          </English>
        </Typography>

        <Typography
          className="dont-show-again"
          sx={{
            cursor: 'pointer',
            mt: { lg: '1.8rem', xs: '2.4rem' },
            fonstSize: { lg: '1.4rem', xs: '1.4rem' },
            fontWeight: 400,
            color: againChecked ? '#2C4DFF' : '#808080',
            display: 'flex',
            alignItems: 'center',

            '& span': {
              width: '2.5rem',

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
          onClick={() => {
            setAgainChecked(c => !c)
          }}
        >
          <span>
            {againChecked ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16C12.4184 16 16 12.4184 16 8C16 3.5816 12.4184 0 8 0C3.5816 0 0 3.5816 0 8C0 12.4184 3.5816 16 8 16Z"
                  fill="#2C4DFF"
                />
                <path
                  d="M4.60718 8.06377L6.91118 10.3678L11.5192 5.75977"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17C13.4184 17 17 13.4184 17 9C17 4.5816 13.4184 1 9 1C4.5816 1 1 4.5816 1 9C1 13.4184 4.5816 17 9 17Z"
                  stroke="#808080"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.60718 9.06377L7.91118 11.3678L12.5192 6.75977"
                  stroke="#808080"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>
          <Korean>다시 보지 않기</Korean>
          <English>{"Don't show again"}</English>
        </Typography>
      </CenterAlignStack>
    </>
  )

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          sx={{
            '& .MuiDialog-paper': {
              pt: '3.2rem',
              px: 0,

              borderRadius: '1rem',
            },
          }}
        >
          <DialogContent sx={{ p: 0, px: '3.6rem' }}>{content}</DialogContent>

          <DialogActions sx={{ p: 0, mt: '2.6rem' }}>
            <CenterAlignStack
              direction="row"
              sx={{
                width: '100%',

                '& .button': {
                  fontSize: '1.4rem',
                  fontWeight: 600,
                  width: '50%',
                  height: '4.4rem',
                  borderRadius: 0,
                  '&.cancel': { background: '#F8F8F8', color: '#303030' },
                  '&.download': {
                    color: 'white',
                    background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                  },
                },
              }}
            >
              <Button className="button cancel" onClick={handleClose}>
                {t('button.cancel')}
              </Button>
              <Button className="button download" onClick={handleDownload}>
                {t('button.download')}
              </Button>
            </CenterAlignStack>
          </DialogActions>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onClose={handleClose}
          onOpen={() => {
            return open
          }}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignBox sx={{ mt: '2rem' }}>{content}</CenterAlignBox>

          <CenterAlignStack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              pb: '2.8rem',
              mt: '2.8rem',

              '& .button': {
                fontSize: '1.6rem',
                fontWeight: 600,

                height: '4rem',
                borderRadius: 0,
                '&.cancel': { width: '8rem', background: 'transparent', color: '#303030' },
                '&.download': {
                  borderRadius: '4px',
                  width: '20rem',
                  color: 'white',
                  background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                },
              },
            }}
          >
            <Button className="button cancel" onClick={handleClose}>
              {t('button.cancel')}
            </Button>
            <Button className="button download" onClick={handleDownload}>
              {t('button.download')}
            </Button>
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}
