import { MHidden } from 'components/@material-extend'
import {
  Box,
  Typography,
  Checkbox,
  Grid,
  Divider,
  IconButton,
  Stack,
  TextField,
  Pagination,
  Button,
  Select,
  MenuItem,
  ToggleButton,
  Backdrop,
  CircularProgress,
  Skeleton,
  GlobalStyles,
} from '@mui/material'
import {
  CustomTooltip,
  CenterAlignBox,
  CenterAlignStack,
  getGA4EventNameByPortfolioType,
  triggerGA4DownloadEvent,
  RegenerateDialogV2,
  CreateBgForNukkiDialog,
  RegenerateDialog,
  BannerEditor,
  NaverProductButton,
} from 'components'

import { useEffect, useState, useRef, useCallback, useLayoutEffect, useMemo } from 'react'
import { styled, useTheme, alpha, keyframes } from '@mui/material/styles'
import {
  GIFstandardDate,
  MODEL_FACE_CHANGE,
  bannerCopyReprocessing,
  getDownloadPieceFileName,
  getPieceType,
  getQueryParam,
  getS3ImageSrc,
  imageUrlToFile,
  isKo,
  padZeros,
  setWatermark,
  sortPieces,
  utcToLocal,
} from 'utils/common'
import { apis } from 'apis'
import _, { isMap, result } from 'lodash'
import * as config from 'config'

import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import useConfirm from 'hooks/useConfirm'
import { Navigation, Autoplay, Swiper, Manipulation, Controller } from 'swiper'
import { checkedURLforNoCache } from './ArtworkList'
import {
  blockDragDropAtom,
  portfolioDetailAtom,
  portfolioTypeAtom,
  userAtom,
  defaultPortfolioAtom,
  portfolioArtworkAtom,
  artworkRegenCountAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  retryMannequinAtom,
  modelBgRegenDialogAtom,
  modelFaceRegenDialogAtom,
  uploadDialogOpenAtom,
  regenerateUploadFilesAndUrlAtom,
  segmentStepAtom,
  segmentLoadingAtom,
  pleaseLoginDialogAtom,
  modelRenerateDialogAtom,
  downloadWarningDialogAtom,
  userCreditAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { ArtworkWarningIcon, NukkiIcon, RefreshIcon, TrashCanIcon, TrashCanIcon2 } from 'theme/icon'

import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { blogPostsAtom, currentMenuAtom } from '../../atoms'
import { creditPolicyDictSelector } from 'selector'
import { English, Korean } from 'hooks/useLanguage'
import axios from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { DelayInfoForNonSubscriber, DelayInfoForSubscriber } from './ArtworkEtc'
import { LARGE_IMAGE_WIDTH_REM } from './ArtworkRowImageSwiper'
import { useRefineUploadFiles, useUploadHook } from 'hooks/useRefineUploadFiles'
import { getUserType } from 'utils/user'
import { PORTFOLIO_CONFIG_DEFAULT_BACK } from 'config'
import { NHNProductContainedButton } from 'pages/NHNCommerce'
import { Cafe24ProductButton } from 'pages/Cafe24'
import { BarLoader as Loader } from 'react-spinners'
import moment from 'moment'

import { UpscaleConfirmDialog } from './UpscaleConfirmDialog.js'
import {
  canUpscale,
  checkBgremovedPiece,
  getPieceDownloadCondition,
  hasUpscaledImage,
} from 'utils/artwork'
import { hideDownloadWarningPopup } from './DownloadWarningDialog'
import useCreditWarningDialog from 'hooks/useCreditWarningDialog'

export const YesChip = () => {
  return (
    <CenterAlignBox
      sx={{
        alignItems: 'center',
        borderRadius: '6px',
        background: theme => theme.palette.draph.newblue,
        '& span': {
          fontSize: { lg: '1.2rem', fontWeight: 600 },
          color: 'white',
          lineHeight: 1,
        },
        width: '4rem',
      }}
    >
      <span>YES</span>
    </CenterAlignBox>
  )
}
export const NoChip = () => {
  return (
    <CenterAlignBox
      sx={{
        alignItems: 'center',
        borderRadius: '6px',
        background: theme => theme.palette.common.black,
        '& span': {
          fontSize: { lg: '1.2rem', fontWeight: 600 },
          color: 'white',
          lineHeight: 1,
        },
        width: '4rem',
      }}
    >
      <span>NO</span>
    </CenterAlignBox>
  )
}

const ButtonFreeBadge = () => {
  return (
    <Box
      id="button-free-badge"
      sx={{
        transition: 'all 0.1s',
        background: theme => theme.palette.common.black,
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        px: { lg: '0.9rem' },
        py: { lg: '0.1rem' },
        ml: { lg: '0.4rem' },
      }}
    >
      <Typography
        id="free-badge-text"
        sx={{
          color: 'white',
          fontSize: { lg: '1rem', xs: '1rem' },
          transform: { lg: 'scale(1)', xs: 'scale(0.8)' },
          fontWeight: 500,
        }}
      >
        FREE
      </Typography>
    </Box>
  )
}

const ControlButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  fontSize: '2rem',
  fontWeight: 600,

  '&.MuiButton-contained': {
    '&:hover': {
      background: theme.palette.draph.newblue,
    },
  },

  '&.MuiButton-outlined': {
    background: 'white',
    '&:hover': {
      // background: '',
      color: theme.palette.draph.newblue,
      borderColor: theme.palette.draph.newblue,
    },
  },

  [theme.breakpoints.down('lg')]: {},
}))

const ButtonWithStartIcon = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,

  height: '3.2rem',
  borderWidth: '0.1rem',
  borderRadius: '4px',
  fontSize: '1.4rem',
  fontWeight: 600,
  transition: 'all 0.1s',
  color: theme.palette.common.black,
  paddingLeft: '1rem',
  paddingRight: '1rem',

  '&:hover': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.draph.newblue,
    borderWidth: '0.1rem',
    color: theme.palette.draph.newblue,
    '& .MuiButton-startIcon path': {
      fill: theme.palette.draph.newblue,
    },

    '& #button-free-badge': {
      background: theme.palette.draph.newblue,
    },
  },
  '&:disabled': {
    borderColor: '#BBBBBB',
    color: '#BBBBBB !important',
    '& .MuiButton-startIcon path': {
      fill: '#BBBBBB',
    },

    '& #button-free-badge': {
      background: '#BBBBBB',
    },
  },
  '& .MuiButton-startIcon': {
    marginLeft: '0',
    marginRight: '0.4rem',
  },

  [theme.breakpoints.down('lg')]: {
    height: '2.4rem',
    fontSize: '1rem',

    '& .MuiButton-startIcon': {
      marginRight: '0.3rem',
    },
  },
}))

const PinIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3493 5.1637L12.7993 0.613697C11.9667 -0.204566 10.632 -0.204566 9.79934 0.613697L6.96934 3.4037C6.80839 3.56537 6.62273 3.70039 6.41934 3.8037L3.34934 5.3437C2.73248 5.65037 2.30097 6.23524 2.18997 6.91513C2.07897 7.59501 2.30205 8.28676 2.78934 8.7737L5.45934 11.4437L0.21934 16.6837C-0.0731134 16.9765 -0.0731134 17.4509 0.21934 17.7437C0.362186 17.8809 0.551343 17.9594 0.74934 17.9637C0.948185 17.9635 1.13882 17.8844 1.27934 17.7437L6.51934 12.5037L9.18934 15.1737C9.67628 15.661 10.368 15.8841 11.0479 15.7731C11.7278 15.6621 12.3127 15.2306 12.6193 14.6137L14.1593 11.5237C14.2627 11.3203 14.3977 11.1346 14.5593 10.9737L17.3493 8.1837C18.1808 7.34871 18.1808 5.99868 17.3493 5.1637Z"
      fill="#ABABAB"
    />
  </svg>
)

export function ArtworkControl({
  artwork,
  artworkIdx,
  showAdIdx,
  setArtwork,
  selectedPiece,
  currentPiece,
  currentConfig,
  refreshArtworks,
  refreshArtwork,
  processImages,
  canMakeGIFPieces,
  handleOpenGIFDialog,
  redoAnimationStart,
  pieceImageSize,
  handleCreatePortfolioWithConfig,
}) {
  const isNoSO = artwork.name === config.NO_SO_UPLOAD_NAME
  const artworkDone = artwork.status === config.ARTWORK_DONE_STATUS
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)
  const artworkLoading =
    (!artworkDone && !artworkError) || config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)

  const [openBannerEditor, setOpenBannerEditor] = useState(false)
  const [createImageDialog, setCreateImageDialog] = useState(false)

  const [openRegenDialog, setOpenRegenDialog] = useState(false)
  const [openUpscaleConfifmDialog, setOpenUpscaleConfifmDialog] = useState(false)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)
  const [regenCount, setRegenCount] = useRecoilState(artworkRegenCountAtom)
  const [modelBgRegenDialog, setModelBgRegenDialog] = useRecoilState(modelBgRegenDialogAtom)
  const [faceRegenDialog, setFaceRegenDialog] = useRecoilState(modelFaceRegenDialogAtom)
  const [modelRenerateDialog, setModelRenerateDialog] = useRecoilState(modelRenerateDialogAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [user, setUser] = useRecoilState(userAtom)
  const [artworkList, setArtworkList] = useRecoilState(portfolioArtworkAtom)

  const currentMenu = useRecoilValue(currentMenuAtom)
  const creditPolicy = useRecoilValue(creditPolicyDictSelector)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)

  const [retryMannequin, setRetryMannequin] = useRecoilState(retryMannequinAtom)

  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const { showConfirm } = useConfirm()

  const showAdBanner = showAdIdx.includes(artworkIdx)
  const blogPosts = useRecoilValue(blogPostsAtom)?.filter(p => p.category !== 'news')
  const [randomBlogPost, setRandomBlogPost] = useState(
    blogPosts ? blogPosts[Math.floor(Math.random() * blogPosts.length)] : null
  )
  const navigate = useNavigate()

  const isRemoveBgPage =
    portfolioType === config.PORTFOLIO_TYPE_REMOVEBG &&
    location.pathname.includes('/generate/removebg')
  // ------------
  const isUploadPage =
    portfolioType === config.PORTFOLIO_TYPE_UPLOAD && location.pathname.includes('/generate/upload')

  // 유저가 직접 생성한 포트폴리오만 구분
  const isPortfolioPage = portfolioType === config.PORTFOLIO_TYPE_NORMAL
  // && !portfolioDetail.is_default
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const artworkConfig = JSON.parse(artwork?.config)

  // const isFacePiece = isMannequinPage && artworkConfig?.category !== 'mann2man'

  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE
  const isModelBgPage = portfolioType === config.PORTFOLIO_TYPE_MODELBG
  // ------------

  const isNCommerceUser = user.login_sns === 'naver_commerce'
  const isNHNCommerceUser = user.login_sns === 'nhn_commerce'
  const isCafe24CommerceUser = user.login_sns === 'cafe24_commerce'
  const isCommerceUser = isNCommerceUser || isNHNCommerceUser || isCafe24CommerceUser

  const setSegmentStep = useSetRecoilState(segmentStepAtom)
  const segmentLoading = useRecoilValue(segmentLoadingAtom)
  const { editUploadFile } = useUploadHook(regenerateUploadFilesAndUrlAtom)

  const refineUploadFiles = useRefineUploadFiles()

  const onlyOneOutput = isRemoveBgPage || isFacePage

  const { t } = useTranslation()

  const isMobile = useMobileMediaQuery()

  const artworkCopy = useMemo(
    () => bannerCopyReprocessing(JSON.parse(artwork.config).copy_recomm_result),
    [artwork]
  )

  const [pieceDownloaded, setPieceDownloaded] = useState(true) // 누끼 이외의 피스를 다운받은 이력이 있는지 여부

  const [downloadWarningDialog, setDownloadWarningDialog] =
    useRecoilState(downloadWarningDialogAtom)

  useEffect(() => {
    setPieceDownloaded(getPieceDownloadCondition(artwork))
  }, [JSON.stringify(artwork?.pieces)])

  useEffect(() => {
    // console.log(artworkConfig)
  }, [])

  const { showCreditWarningDialog } = useCreditWarningDialog()

  const handleRegenerate = async () => {
    if (pieceDownloaded) {
      const checkCreditType = 'artwork_create_regen_after_download'
      const price = creditPolicy[checkCreditType]

      if (user.use_credit_on.includes(checkCreditType)) {
        const credit = await checkUserCredit()

        if (credit < price) {
          // ----- GA4 event -----
          window.gtag('event', 'not_enough_credit_imp', {
            menu: currentMenu?.id,
          })
          // ---------------------

          showCreditWarningDialog()
          return
        }
      }
    }

    setOpenRegenDialog(true)
    setBlockDragDrop(true)
  }

  const [userCredit, setUserCredit] = useRecoilState(userCreditAtom)

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    setUserCredit(credit)

    return credit
  }

  const openDownloadWarning = () => {
    // 팝업 없이 바로 다운로드 실행시켜주도록 수정하려면, openDownloadWarning 사용하는 곳을 downloadSelected 로 수정

    const selected = selectedPiece ?? currentPiece ? [currentPiece.path] : []
    const bgRemovedOnly = selected.filter(path => !checkBgremovedPiece(path)).length < 1
    const skipPage = config.NO_REGENERATE_TYPES.includes(portfolioType)

    const skipPopup =
      localStorage.getItem(hideDownloadWarningPopup) || bgRemovedOnly || pieceDownloaded || skipPage

    if (skipPopup) {
      downloadSelected()
    } else {
      setDownloadWarningDialog({ open: true, download: downloadSelected })
    }
  }

  const downloadSelected = async () => {
    const pieces = artwork.pieces
    if (user.use_credit_on.includes('artwork_download')) {
      const credit = await checkUserCredit()

      if (credit < creditPolicy.artwork_download) {
        showConfirm({
          alertOnly: true,
          content: <Typography>{t('common.credit_warning')}</Typography>,
        })
        return
      }
    }

    let selected = selectedPiece.map(l => getS3ImageSrc(l))

    if (selected.length < 1 && !onlyOneOutput) {
      if (currentPiece?.path) {
        selected = [getS3ImageSrc(currentPiece.path)]
      } else {
        showConfirm({
          alertOnly: true,
          content: (
            <Typography>
              <Korean>이미지를 선택 후 다운로드해주세요.</Korean>
              <English>No images have been selected.</English>
            </Typography>
          ),
        })
        return
      }
    }

    const artworkConfig = JSON.parse(artwork.config)

    // ----- GA4 event -----
    if (config.DEFAULT_PORTFOLIO_TYPES.includes(portfolioType)) {
      const ev = getGA4EventNameByPortfolioType(portfolioType, 'download')
      triggerGA4DownloadEvent({
        eventName: ev,
        params: { count: selected.length },
        method: 'row',
      })
    } else if (artworkConfig.flag_bg_expansion) {
      triggerGA4DownloadEvent({
        eventName: 'ai_canvas_exp_download',
        params: { count: selected.length },
        method: 'row',
      })
    } else if (!portfolioType) {
      const artworkConfig = JSON.parse(artwork.config)
      triggerGA4DownloadEvent({
        eventName: 'portfolio_download',
        artworkConfig,
        user,
        artwork,
        count: selected.length,
        method: 'row',
      })
    } else {
      const artworkConfig = JSON.parse(artwork.config)
      triggerGA4DownloadEvent({
        artworkConfig,
        user,
        artwork,
        count: selected.length,
        method: 'row',
      })
    }

    // ---------------------

    const promises = []
    const paths = []
    const ax = axios.create()
    const artworkName = artwork.name.replace('.' + _.last(artwork.name.split('.')), '')

    if (selected.length === 1 || onlyOneOutput) {
      const url = checkedURLforNoCache(
        onlyOneOutput ? getS3ImageSrc(artwork.pieces[0].path) : selected[0]
      )

      const img = new Image()
      img.src = url

      const blob = await fetch(url + `?w=${Date.now().toString()}`).then(r => r.blob())

      const ext = _.last(_.first(_.last(url.split('/')).split('?')).split('.'))
      const idx = 1

      const piece = pieces.filter(p => url.includes(p.path))[0]

      const fileName =
        piece?.result_filename?.length > 0
          ? piece.result_filename
          : getDownloadPieceFileName({
              prefix: artworkName,
              idx: idx,
              width: img.width,
              height: img.height,
              ext: ext,
            })

      saveAs(blob, fileName)
      paths.push(onlyOneOutput ? artwork.pieces[0].path : currentPiece?.path)

      checkUserCredit()

      apis.portfolio.updateArtworkDownload(portfolioId, artwork.id, { paths }).then(response => {
        refreshArtwork()
      })
    } else {
      // console.log(selected)
      // console.log(selectedPiece)
      selected.forEach((url, i) => {
        paths.push(selectedPiece[i])
        if (!url) return

        promises.push(
          ax.get(checkedURLforNoCache(url) + `?w=${Date.now().toString()}`, {
            responseType: 'blob',
          })
        )
      })

      const dirName = artworkName

      Promise.all(promises).then(results => {
        processImages(results, dirName, artworkName, pieces)

        checkUserCredit()

        apis.portfolio.updateArtworkDownload(portfolioId, artwork.id, { paths }).then(response => {
          refreshArtwork()
        })
      })
    }
  }

  const deleteSelected = () => {
    let selected = [...selectedPiece]

    if (!selected.length && !onlyOneOutput) {
      if (currentPiece?.path) {
        selected = [currentPiece.path]
      } else {
        showConfirm({
          alertOnly: true,
          content: (
            <Typography>
              <Korean>이미지를 선택 후 삭제하세요.</Korean>
              <English>Please select images to delete.</English>
            </Typography>
          ),
        })
        return
      }
    }
    showConfirm({
      title: t('artworkList.image_delete'),
      content: (
        <p>
          {t('artworkList.image_delete_comment_1')} {onlyOneOutput ? 1 : selected.length}
          {t('artworkList.image_delete_comment_2')}
        </p>
      ),
      onConfirm: () => {
        if (artwork.pieces.length === selected.length || onlyOneOutput) {
          apis.portfolio.deleteArtworks(portfolioId, { artworkIds: [artwork.id] }).then(() => {
            refreshArtworks()
          })
        } else {
          console.log(selected)
          apis.portfolio
            .deletePiece(portfolioId, artwork.id, {
              data: { paths: [...selected] },
            })
            .then(() => {
              refreshArtworks()
            })
        }
        // ----- GA4 event -----
        window.gtag('event', 'image_delete', {
          menu: currentMenu?.id,
          method: 'row',
          count: selectedPiece.length,
        })
        // ---------------------
      },
    })
  }

  const refreshRegenCount = () => {
    apis.portfolio.getArtworkRegenCount().then(response => {
      if (response.data) {
        setRegenCount(response.data)
      }
    })
  }

  const handleModelbgRegenerate = () => {
    setModelRenerateDialog({
      open: true,
      artwork,
      setArtwork,
      refreshArtwork,
      refreshRegenCount,
      pieceImageSize,
      currentPieceId: currentPiece?.id,
    })

    // ----- GA4 event -----
    window.gtag('event', 'modelbg_regenerate_begin', {})
    // ---------------------
  }

  const handleFaceRegenerate = () => {
    const isChanged = moment(artwork.created) - MODEL_FACE_CHANGE >= 0

    if (isChanged) {
      editUploadFile(artwork.uploaded, {
        mannequinMode: true,
        service: 'face',
        keepShowingOn: false,
        artworkId: artwork.id,
      })
      setSegmentStep(0)
      setModelRenerateDialog({
        open: true,
        artwork,
        setArtwork,
        refreshArtwork,
        refreshRegenCount,
        pieceImageSize,
        currentPieceId: currentPiece?.id,
      })
    } else {
      setFaceRegenDialog({
        open: true,
        artwork,
        setArtwork,
        refreshArtwork,
        refreshRegenCount,
      })
    }
  }

  const mannequinRegenrate = async () => {
    // TODO TODO TODO TODO TODO

    editUploadFile(artwork.uploaded, {
      mannequinMode: true,
      service: 'mannequin',
      keepShowingOn: false,
      artworkId: artwork.id,
    })
    setRetryMannequin({ artworkId: artwork.id, config: JSON.parse(artwork.config), isRetry: true })
    setSegmentStep(0)
    setModelRenerateDialog({
      open: true,
      artwork,
      setArtwork,
      refreshArtwork,
      refreshRegenCount,
      pieceImageSize,
      currentPieceId: currentPiece?.id,
    })

    // ----- GA4 event -----
    window.gtag('event', 'mannequin_regenerate_begin', {})
    // ---------------------
  }

  const regenerateArtwork = async ({
    category,
    angle,
    themeAir,
    themeTemplate,
    themeCustom,
    retryType = 'regenerate',
  }) => {
    const checkArtwork = await apis.portfolio.getArtwork(portfolioId, artwork.id)
    if (config.ARTWORK_IN_PROGRESS_STATUS.includes(checkArtwork.data.status)) {
      // 이미 진행중인 상태의 아트워크 - 백엔드로 중복 요청 하지 않고 화면상에서 진행 상태만 업데이트하여 보도록 함
      refreshArtwork()
      refreshRegenCount()
      return
    }

    const feedback = 'regenerate_auto'
    const artworkConfig = JSON.parse(artwork.config)

    const isSimpleConfig =
      !artworkConfig.flag_complex_cmp && !artworkConfig.flag_generate ? 'simple' : 'concept'

    // ----- GA4 event -----
    window.gtag('event', portfolioType ? feedback : 'portfolio_regenerate_auto', {
      theme: isSimpleConfig === 'simple' ? null : themeCustom || themeTemplate,
    })
    // ---------------------

    setArtwork({ ...artwork, status: feedback })

    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('user_type', getUserType(user))
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', portfolioId)
    formData.append('retry_type', retryType ?? 'regenerate')

    const genOptions = {
      ...config.PORTFOLIO_CONFIG_DEFAULT_BACK,
      gen_shadow: artworkConfig.gen_shadow,
      gen_face: artworkConfig.gen_face,
      object_category: category?.length > 0 ? category : 'auto',
      object_sub_category: artworkConfig.object_sub_category ?? 'auto',
      object_boundary: artworkConfig.object_boundary ?? 'none',
      object_angle: angle?.length > 0 ? angle : 'auto',
      flag_generate: artworkConfig.flag_generate,
      flag_complex_cmp: artworkConfig.flag_complex_cmp,
      flag_simple_cmp: artworkConfig.flag_simple_cmp,
      flag_white_cmp: artworkConfig.flag_white_cmp,

      flag_gen_compo: artworkConfig.flag_gen_compo ?? false,
      flag_bg_expansion: artworkConfig.flag_bg_expansion ?? false,
      flag_multiblob_sod: artworkConfig.flag_multiblob_sod ?? false,

      // 별도의 재생성 함수를 사용하는 기능들용 필드들 - 디폴트만 세팅
      flag_human_background: false,
      SO_length_scale: 'auto',
      flag_facemorphing: false,
      facemorphing_race: 'asian',
      facemorphing_gender: 'none',
      // ----------------------------------

      selected_bg_ids: artworkConfig.selected_bg_ids ?? '',

      output_size_w: artworkConfig.output_size_w ?? 0,
      output_size_h: artworkConfig.output_size_h ?? 0,

      theme: artworkConfig.theme,
      theme_air: '',
      theme_template: themeTemplate,
      theme_custom: themeCustom,
      bg_expansion_bbox: artworkConfig.bg_expansion_bbox ?? [],

      output_size_list: artworkConfig.output_size_list ?? [],

      simple_bg_color_list:
        artworkConfig.simple_bg_color_list ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }
    formData.append('gen_options', JSON.stringify(genOptions))

    if (category?.length > 0) formData.append('category', category)
    if (angle?.length > 0) formData.append('angle', angle)

    const scrollTargetId = `a_${artwork.id}`

    // querySelector 에 불가능한 특수문자 앞에  \를 넣어줘야한다고 함.
    const anchorIdTranslation = scrollTargetId.replace(/[#[\].():'"~,]/g, '\\$&')

    const anchor = document.querySelector(`#${anchorIdTranslation}`)

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }

    apis.portfolio
      .updateArtworkFeedback(portfolioId, artwork.id, {
        feedback,
        themeTemplate,
        themeCustom,
        useCreditType: pieceDownloaded ? 'artwork_create_regen_after_download' : null,
      })
      .then(() => {
        refreshArtwork()
        apis.appfront
          .retry(formData)
          .then(() => {
            // refreshArtworks()
            refreshArtwork()
          })
          .catch(error => {
            console.log(error)
            refreshArtwork()
          })
        refreshRegenCount()
      })
  }

  const regenerateArtworkV2 = async (option, image = '', retryType = 'regenerate') => {
    const checkArtwork = await apis.portfolio.getArtwork(portfolioId, artwork.id)
    if (config.ARTWORK_IN_PROGRESS_STATUS.includes(checkArtwork.data.status)) {
      // 이미 진행중인 상태의 아트워크 - 백엔드로 중복 요청 하지 않고 화면상에서 진행 상태만 업데이트하여 보도록 함
      refreshArtwork()
      refreshRegenCount()
      return
    }

    const feedback = 'regenerate_auto'
    const artworkConfig = JSON.parse(artwork.config)

    const isSimpleConfig = !option.flag_complex_cmp && !option.flag_generate ? 'simple' : 'concept'

    // 일반 업로드 시에만 GA 발동
    if (!isRemoveBgPage) {
      // ----- GA4 event -----
      window.gtag('event', portfolioType ? feedback : 'portfolio_regenerate_auto', {
        theme: image.length
          ? 'reference'
          : isSimpleConfig === 'simple'
          ? null
          : option.theme_custom || option.theme_template,
      })
      // ---------------------
    }

    setArtwork({ ...artwork, status: feedback })

    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', portfolioId)
    formData.append('retry_type', retryType ?? 'regenerate')

    if (image.length) {
      formData.append('ref_image', image[0].file ?? '')
    }

    // formData.append('options', JSON.stringify(options))

    const genOptions = {
      // object_category: category?.length > 0 ? category : 'auto',
      // object_angle: angle?.length > 0 ? angle : 'auto',
      // theme_air: '',
      // theme_template: themeTemplate,
      // theme_custom: themeCustom,
      ...PORTFOLIO_CONFIG_DEFAULT_BACK,
      ...option,
      // gen_face 선택 없애면서 기본값으로만 들어감
      gen_face: false,

      has_ref_image: Boolean(image.length),
    }
    formData.append('gen_options', JSON.stringify(genOptions))

    // formData.append('theme_air', '')

    // formData.append('flag_generate', artworkConfig.flag_generate ?? true)
    // formData.append('flag_complex_cmp', artworkConfig.flag_complex_cmp ?? true)
    // formData.append('flag_simple_cmp', artworkConfig.flag_simple_cmp ?? true)
    // formData.append('flag_white_cmp', artworkConfig.flag_white_cmp ?? true)

    // formData.append('output_size_w', artworkConfig.output_size_w)
    // formData.append('output_size_h', artworkConfig.output_size_h)

    // formData.append('output_size_list', artworkConfig.output_size_list ?? [])
    // formData.append(
    //   'simple_bg_color_list',
    //   artworkConfig.simple_bg_color_list ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList
    // )

    const beforeConfig = JSON.parse(artwork.config)
    const afterConfig = JSON.stringify({ ...beforeConfig, ...genOptions })

    apis.portfolio.updateArtwork(portfolioId, artwork.id, { config: afterConfig })

    const scrollTargetId = `a_${artwork.id}`
    // querySelector 에 불가능한 특수문자 앞에  \를 넣어줘야한다고 함.
    const anchorIdTranslation = scrollTargetId.replace(/[#[\].():'"~,]/g, '\\$&')

    const anchor = document.querySelector(`#${anchorIdTranslation}`)

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }

    apis.portfolio
      .updateArtworkFeedback(portfolioId, artwork.id, {
        feedback,
        themeTemplate: option.theme_template,
        themeCustom: option.theme_custom,
        useCreditType: pieceDownloaded ? 'artwork_create_regen_after_download' : null,
      })
      .then(() => {
        refreshArtwork()
        apis.appfront
          .retry(formData)
          .then(() => {
            // refreshArtworks()
            refreshArtwork()
          })
          .catch(error => {
            console.log(error)
            refreshArtwork()
          })
        refreshRegenCount()
      })
  }

  const createFromNukki = () => {
    // ----- GA4 event -----
    window.gtag('event', 'removebg_img_generate_begin', {})
    // ---------------------
    setCreateImageDialog(true)
  }

  const regenerateHandler = () => {
    if (isUploadPage || isPortfolioPage || isRemoveBgPage) return handleRegenerate

    if (isMannequinPage) {
      return mannequinRegenrate
    }

    if (isFacePage) {
      return handleFaceRegenerate
    }
    if (isModelBgPage) {
      return handleModelbgRegenerate
    }
  }

  const handleBannerEdit = () => {
    if (artworkCopy) {
      // ----- GA4 event -----
      window.gtag('event', 'banner_edit_begin', {})
      // ---------------------
      setOpenBannerEditor(true)
    }
  }

  const openUpscaleDialog = () => {
    setOpenUpscaleConfifmDialog(true)
    // ----- GA4 event -----
    window.gtag('event', 'upscale', { method: 'upscale_begin' })
    // ---------------------
  }

  const upscaleCallback = response => {
    setOpenUpscaleConfifmDialog(false)
    refreshArtworks()
  }

  return (
    <>
      <CenterAlignStack sx={{ width: { lg: '40rem' }, opacity: artworkLoading ? 0.6 : 1 }}>
        {/* 아트워크 이름, 휴지통 버튼, 재생성/누끼수정 버튼 */}
        <CenterAlignStack direction="row" sx={{ width: '100%', justifyContent: 'space-between' }}>
          <>
            <Box
              sx={{
                display: 'flex',
                width: { lg: '22rem' },
                textOverflow: 'ellipsis',
                userSelect: 'none',
              }}
            >
              <Typography
                noWrap
                sx={{ width: 'min-content', fontSize: { lg: '1.6rem' }, fontWeight: { lg: 600 } }}
              >
                {isNoSO ? t('banner_config.no_product') : artwork.name}
              </Typography>
            </Box>

            <Stack direction="row" spacing="0.8rem">
              <TrashcanButton handleClick={deleteSelected} disabled={!artworkDone} />
              {isRemoveBgPage ? (
                <EditNukkiButton
                  handleClick={handleRegenerate}
                  disabled={!artworkDone || artworkError}
                />
              ) : artworkConfig.artwork_type === 'banner' ? (
                <EditNukkiButton
                  handleClick={handleBannerEdit}
                  disabled={!artworkDone || artworkError}
                />
              ) : isUploadPage ||
                isPortfolioPage ||
                isMannequinPage ||
                isModelBgPage ||
                isFacePage ? (
                <RegenerateButton
                  handleClick={regenerateHandler()}
                  redoAnimationStart={redoAnimationStart}
                  disabled={!artworkDone || artworkError || segmentLoading}
                  pieceDownloaded={pieceDownloaded}
                />
              ) : (
                <></>
              )}
            </Stack>
          </>
        </CenterAlignStack>

        <Divider
          orientation="horizontal"
          flexItem
          sx={{
            mt: { lg: '1.2rem' },
            mb: { lg: '2rem' },
            background: theme => theme.palette.common.black,
          }}
        />

        {/* 설정 표시 박스 */}
        <Box
          sx={{
            width: '100%',
            py: { lg: '2.8rem' },
            px: { lg: '2.4rem' },
            background: '#FAFAFA',
            '& .item-title': {
              fontSize: { lg: '1.6rem' },
              fontWeight: 600,
              mr: '0.6rem',
            },
            '& .item-value': {
              fontSize: { lg: '1.6rem' },
              fontWeight: 400,
              maxWidth: { lg: '26rem' },

              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            },
            position: 'relative',
          }}
        >
          {/* 현재 아트워크의 설정으로 포트폴리오 만들기 아이콘 */}
          {isUploadPage && artworkDone && (
            <CustomTooltip
              enterDelay={0}
              leaveDelay={100}
              title={<span>{t('artworkList.common_setting_comment')}</span>}
            >
              <Stack
                sx={{
                  background: '#EEEEEE',
                  borderRadius: '4px',
                  p: '9px',
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  cursor: 'pointer',
                  '&:hover': {
                    background: '#D9D9D9',
                    '& svg path': {
                      fill: '#808080',
                    },
                  },
                }}
                onClick={handleCreatePortfolioWithConfig}
              >
                <PinIcon />
              </Stack>
            </CustomTooltip>
          )}
          {artworkConfig.artwork_type === 'banner' ? (
            <BannerConfigDisplayContent
              artworkConfig={artworkConfig}
              pieceImageSize={pieceImageSize}
              currentPiece={currentPiece}
            />
          ) : (
            <ConfigDisplayContent
              artworkConfig={artworkConfig}
              pieceImageSize={pieceImageSize}
              currentPiece={currentPiece}
            />
          )}
        </Box>

        {/* 버튼 (다운로드, (누끼)배경 생성 등) */}
        <Box className="control-button-box" sx={{ width: '100%' }}>
          {isRemoveBgPage ? (
            <RemoveBgControlButtons
              download={openDownloadWarning}
              createFromNukki={createFromNukki}
              disabled={!artworkDone || artworkError}
              loading={artworkLoading}
            />
          ) : isCommerceUser && (isUploadPage || isPortfolioPage) ? (
            <CommerceControlButtons
              artwork={artwork}
              currentPiece={currentPiece}
              download={openDownloadWarning}
              disabled={!artworkDone || artworkError}
            />
          ) : (
            <DefaultControlButtons
              download={openDownloadWarning}
              upscale={openUpscaleDialog}
              displayUpscaleButton={
                isUploadPage &&
                canUpscale(currentPiece) &&
                !hasUpscaledImage(currentPiece, artwork) &&
                pieceImageSize[currentPiece?.id]?.w < 2048 &&
                pieceImageSize[currentPiece?.id]?.h < 2048
              }
              currentPiece={currentPiece}
              canMakeGIFPieces={canMakeGIFPieces}
              handleOpenGIFDialog={handleOpenGIFDialog}
              disabled={!artworkDone || artworkError}
              loading={artworkLoading}
            />
          )}
        </Box>

        {/* 블로그 글, 광고 표시 영역 */}
        {showAdBanner && (
          <>
            <Korean>
              {artworkIdx === 0 ? (
                <BespokeAdBanner />
              ) : randomBlogPost ? (
                <BlogAdBanner blogPost={randomBlogPost} />
              ) : (
                <></>
              )}
            </Korean>
            <English>{randomBlogPost && <BlogAdBanner blogPost={randomBlogPost} />}</English>
          </>
        )}
      </CenterAlignStack>

      {openRegenDialog && isMobile && (
        <RegenerateDialog
          open={openRegenDialog}
          setOpen={setOpenRegenDialog}
          artwork={artwork}
          regenerateArtwork={regenerateArtwork}
          showWarning={currentConfig?.display}
        />
      )}

      {openRegenDialog && !isMobile && (
        <RegenerateDialogV2
          open={openRegenDialog}
          setOpen={setOpenRegenDialog}
          artwork={artwork}
          regenerateArtwork={regenerateArtworkV2}
          // showWarning={currentConfig?.display}
        />
      )}

      {isRemoveBgPage && createImageDialog && (
        <CreateBgForNukkiDialog
          open={createImageDialog}
          setOpen={setCreateImageDialog}
          artwork={artwork}
        />
      )}

      {openBannerEditor && (
        <BannerEditor
          open={openBannerEditor}
          setOpen={setOpenBannerEditor}
          artwork={artwork}
          setArtwork={setArtwork}
          currentPiece={currentPiece}
          copy={artworkCopy}
        />
      )}

      {openUpscaleConfifmDialog && (
        <UpscaleConfirmDialog
          open={openUpscaleConfifmDialog}
          setOpen={setOpenUpscaleConfifmDialog}
          actionParams={{
            image_url: getS3ImageSrc(currentPiece?.path),
            user_id: user.id,
            portfolio_id: portfolioId,
            artwork_id: artwork.id,
          }}
          callback={upscaleCallback}
          currentSize={{
            w: pieceImageSize[currentPiece?.id]?.w,
            h: pieceImageSize[currentPiece?.id]?.h,
          }}
        />
      )}
    </>
  )
}

export function GuestArtworkControl({
  artwork,
  artworkIdx,
  showAdIdx,
  setArtwork,
  selectedPiece,
  currentPiece,
  currentConfig,
  refreshArtworks,
  refreshArtwork,
  processImages,
  canMakeGIFPieces,
  handleOpenGIFDialog,
  redoAnimationStart,
  pieceImageSize,
  handleCreatePortfolioWithConfig,
}) {
  const isNoSO = artwork.name === config.NO_SO_UPLOAD_NAME
  const artworkDone = artwork.status === config.ARTWORK_DONE_STATUS
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)
  const artworkLoading =
    (!artworkDone && !artworkError) || config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)

  const [openBannerEditor, setOpenBannerEditor] = useState(false)
  const [createImageDialog, setCreateImageDialog] = useState(false)

  const [openRegenDialog, setOpenRegenDialog] = useState(false)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)
  const [regenCount, setRegenCount] = useRecoilState(artworkRegenCountAtom)
  const [modelBgRegenDialog, setModelBgRegenDialog] = useRecoilState(modelBgRegenDialogAtom)
  const [faceRegenDialog, setFaceRegenDialog] = useRecoilState(modelFaceRegenDialogAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [user, setUser] = useRecoilState(userAtom)
  const [artworkList, setArtworkList] = useRecoilState(portfolioArtworkAtom)

  const currentMenu = useRecoilValue(currentMenuAtom)
  const creditPolicy = useRecoilValue(creditPolicyDictSelector)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)

  const [retryMannequin, setRetryMannequin] = useRecoilState(retryMannequinAtom)

  const [pleaseLoginDialog, setPleaseLoginDialog] = useRecoilState(pleaseLoginDialogAtom)

  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const { showConfirm } = useConfirm()

  const showAdBanner = showAdIdx.includes(artworkIdx)
  const blogPosts = useRecoilValue(blogPostsAtom)?.filter(p => p.category !== 'news')
  const [randomBlogPost, setRandomBlogPost] = useState(
    blogPosts ? blogPosts[Math.floor(Math.random() * blogPosts.length)] : null
  )
  const navigate = useNavigate()

  const isRemoveBgPage =
    portfolioType === config.PORTFOLIO_TYPE_REMOVEBG &&
    location.pathname.includes('/generate/removebg')
  // ------------
  const isUploadPage =
    portfolioType === config.PORTFOLIO_TYPE_UPLOAD && location.pathname.includes('/generate/upload')

  // 유저가 직접 생성한 포트폴리오만 구분
  const isPortfolioPage = portfolioType === config.PORTFOLIO_TYPE_NORMAL
  // && !portfolioDetail.is_default
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const artworkConfig = JSON.parse(artwork?.config)

  // const isFacePiece = isMannequinPage && artworkConfig?.category !== 'mann2man'

  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE
  const isModelBgPage = portfolioType === config.PORTFOLIO_TYPE_MODELBG
  // ------------

  const isNCommerceUser = user.login_sns === 'naver_commerce'
  const isNHNCommerceUser = user.login_sns === 'nhn_commerce'
  const isCafe24CommerceUser = user.login_sns === 'cafe24_commerce'
  const isCommerceUser = isNCommerceUser || isNHNCommerceUser || isCafe24CommerceUser

  const refineUploadFiles = useRefineUploadFiles()

  const onlyOneOutput = isRemoveBgPage || isFacePage

  const { t } = useTranslation()

  const isMobile = useMobileMediaQuery()

  const artworkCopy = useMemo(
    () => bannerCopyReprocessing(JSON.parse(artwork.config).copy_recomm_result),
    [artwork]
  )

  useEffect(() => {
    // console.log(artworkConfig)
  }, [])

  const handleRegenerate = () => {
    setOpenRegenDialog(true)
    setBlockDragDrop(true)
  }

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const deleteSelected = () => {
    let selected = [...selectedPiece]

    if (!selected.length && !onlyOneOutput) {
      if (currentPiece?.path) {
        selected = [currentPiece.path]
      } else {
        showConfirm({
          alertOnly: true,
          content: (
            <Typography>
              <Korean>이미지를 선택 후 삭제하세요.</Korean>
              <English>Please select images to delete.</English>
            </Typography>
          ),
        })
        return
      }
    }
    showConfirm({
      title: t('artworkList.image_delete'),
      content: (
        <p>
          {t('artworkList.image_delete_comment_1')} {onlyOneOutput ? 1 : selected.length}
          {t('artworkList.image_delete_comment_2')}
        </p>
      ),
      onConfirm: () => {
        if (artwork.pieces.length === selected.length || onlyOneOutput) {
          apis.guest.deleteArtworks({ artwork_ids: [artwork.id] }).then(() => {
            refreshArtworks()
          })
        } else {
          // apis.portfolio
          //   .deletePiece(portfolioId, artwork.id, {
          //     data: { paths: [...selected] },
          //   })
          //   .then(() => {
          //     refreshArtworks()
          //   })
        }

        // ----- GA4 event -----
        // TODO
        // window.gtag('event', 'image_delete', {
        //   menu: currentMenu?.id,
        //   method: 'row',
        //   count: selectedPiece.length,
        // })
        // ---------------------
      },
    })
  }

  const refreshRegenCount = () => {
    apis.portfolio.getArtworkRegenCount().then(response => {
      if (response.data) {
        setRegenCount(response.data)
      }
    })
  }

  const handleModelbgRegenerate = () => {
    // setModelBgRegenDialog({
    //   open: true,
    //   artwork: artwork,
    //   setArtwork: setArtwork,
    //   refreshArtwork: refreshArtwork,
    //   refreshRegenCount: refreshRegenCount,
    // })

    const feedback = 'regenerate_auto'
    const artworkConfig = JSON.parse(artwork.config)

    // ----- GA4 event -----
    window.gtag('event', 'modelbg_regenerate', {})
    // ---------------------

    setArtwork({ ...artwork, status: feedback })

    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('user_type', getUserType(user))
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', artwork.portfolio_id)
    formData.append('retry_type', 'regenerate')

    const genOptions = {
      ...artworkConfig,

      flag_human_background: true,

      // 변경하여 적용할 config
    }
    formData.append('gen_options', JSON.stringify(genOptions))

    apis.portfolio
      .updateArtworkFeedback(artwork.portfolio_id, artwork.id, {
        feedback,
      })
      .then(() => {
        refreshArtwork()
        apis.appfront
          .retry(formData)
          .then(() => {
            refreshArtwork()
          })
          .catch(error => {
            console.log(error)
            refreshArtwork()
          })
        refreshRegenCount()
      })
      .catch(() => {
        alert('오류가 발생하였습니다')
        refreshArtwork()
      })
  }
  const handleFaceRegenerate = () => {
    setFaceRegenDialog({
      open: true,
      artwork: artwork,
      setArtwork: setArtwork,
      refreshArtwork: refreshArtwork,
      refreshRegenCount: refreshRegenCount,
    })
  }

  const setSegmentStep = useSetRecoilState(segmentStepAtom)
  const segmentLoading = useRecoilValue(segmentLoadingAtom)
  const { editUploadFile } = useUploadHook(regenerateUploadFilesAndUrlAtom)

  const mannequinRegenrate = async () => {
    // TODO TODO TODO TODO TODO

    setRetryMannequin({ artworkId: artwork.id, config: JSON.parse(artwork.config), isRetry: true })
    editUploadFile(artwork.uploaded, {
      mannequinMode: true,
      service: 'mannequin',
      artworkId: artwork.id,
    })
    setUploadOpen(true)
    setSegmentStep(0)
  }

  const regenerateArtwork = async ({
    category,
    angle,
    themeAir,
    themeTemplate,
    themeCustom,
    retryType = 'regenerate',
  }) => {
    const checkArtwork = await apis.portfolio.getArtwork(portfolioId, artwork.id)
    if (config.ARTWORK_IN_PROGRESS_STATUS.includes(checkArtwork.data.status)) {
      // 이미 진행중인 상태의 아트워크 - 백엔드로 중복 요청 하지 않고 화면상에서 진행 상태만 업데이트하여 보도록 함
      refreshArtwork()
      refreshRegenCount()
      return
    }

    const feedback = 'regenerate_auto'
    const artworkConfig = JSON.parse(artwork.config)

    const isSimpleConfig =
      !artworkConfig.flag_complex_cmp && !artworkConfig.flag_generate ? 'simple' : 'concept'

    // ----- GA4 event -----
    window.gtag('event', portfolioType ? feedback : 'portfolio_regenerate_auto', {
      theme: isSimpleConfig === 'simple' ? null : themeCustom || themeTemplate,
    })
    // ---------------------

    setArtwork({ ...artwork, status: feedback })

    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('user_type', getUserType(user))
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', portfolioId)
    formData.append('retry_type', retryType ?? 'regenerate')

    const genOptions = {
      ...config.PORTFOLIO_CONFIG_DEFAULT_BACK,
      gen_shadow: artworkConfig.gen_shadow,
      gen_face: artworkConfig.gen_face,
      object_category: category?.length > 0 ? category : 'auto',
      object_sub_category: artworkConfig.object_sub_category ?? 'auto',
      object_boundary: artworkConfig.object_boundary ?? 'none',
      object_angle: angle?.length > 0 ? angle : 'auto',
      flag_generate: artworkConfig.flag_generate,
      flag_complex_cmp: artworkConfig.flag_complex_cmp,
      flag_simple_cmp: artworkConfig.flag_simple_cmp,
      flag_white_cmp: artworkConfig.flag_white_cmp,

      flag_gen_compo: artworkConfig.flag_gen_compo ?? false,
      flag_bg_expansion: artworkConfig.flag_bg_expansion ?? false,
      flag_multiblob_sod: artworkConfig.flag_multiblob_sod ?? false,

      // 별도의 재생성 함수를 사용하는 기능들용 필드들 - 디폴트만 세팅
      flag_human_background: false,
      SO_length_scale: 'auto',
      flag_facemorphing: false,
      facemorphing_race: 'asian',
      facemorphing_gender: 'none',
      // ----------------------------------

      selected_bg_ids: artworkConfig.selected_bg_ids ?? '',

      output_size_w: artworkConfig.output_size_w ?? 0,
      output_size_h: artworkConfig.output_size_h ?? 0,

      theme: artworkConfig.theme,
      theme_air: '',
      theme_template: themeTemplate,
      theme_custom: themeCustom,

      output_size_list: artworkConfig.output_size_list ?? [],

      simple_bg_color_list:
        artworkConfig.simple_bg_color_list ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
    }
    formData.append('gen_options', JSON.stringify(genOptions))

    if (category?.length > 0) formData.append('category', category)
    if (angle?.length > 0) formData.append('angle', angle)

    const scrollTargetId = `a_${artwork.id}`

    // querySelector 에 불가능한 특수문자 앞에  \를 넣어줘야한다고 함.
    const anchorIdTranslation = scrollTargetId.replace(/[#[\].():'"~,]/g, '\\$&')

    const anchor = document.querySelector(`#${anchorIdTranslation}`)

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }

    apis.portfolio
      .updateArtworkFeedback(portfolioId, artwork.id, {
        feedback,
        themeTemplate,
        themeCustom,
      })
      .then(() => {
        refreshArtwork()
        apis.appfront
          .retry(formData)
          .then(() => {
            // refreshArtworks()
            refreshArtwork()
          })
          .catch(error => {
            console.log(error)
            refreshArtwork()
          })
        refreshRegenCount()
      })
  }

  const regenerateArtworkV2 = async (option, image = '', retryType = 'regenerate') => {
    const checkArtwork = await apis.portfolio.getArtwork(portfolioId, artwork.id)
    if (config.ARTWORK_IN_PROGRESS_STATUS.includes(checkArtwork.data.status)) {
      // 이미 진행중인 상태의 아트워크 - 백엔드로 중복 요청 하지 않고 화면상에서 진행 상태만 업데이트하여 보도록 함
      refreshArtwork()
      refreshRegenCount()
      return
    }

    const feedback = 'regenerate_auto'
    const artworkConfig = JSON.parse(artwork.config)

    const isSimpleConfig =
      !artworkConfig.flag_complex_cmp && !artworkConfig.flag_generate ? 'simple' : 'concept'

    // ----- GA4 event -----
    window.gtag('event', portfolioType ? feedback : 'portfolio_regenerate_auto', {
      theme: isSimpleConfig === 'simple' ? null : option.theme_custom || option.theme_template,
    })
    // ---------------------

    setArtwork({ ...artwork, status: feedback })

    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', portfolioId)
    formData.append('retry_type', retryType ?? 'regenerate')
    formData.append('ref_image', image ?? '')

    // formData.append('options', JSON.stringify(options))

    const genOptions = {
      // object_category: category?.length > 0 ? category : 'auto',
      // object_angle: angle?.length > 0 ? angle : 'auto',
      // theme_air: '',
      // theme_template: themeTemplate,
      // theme_custom: themeCustom,
      ...PORTFOLIO_CONFIG_DEFAULT_BACK,
      ...option,
    }
    formData.append('gen_options', JSON.stringify(genOptions))

    // formData.append('theme_air', '')

    // formData.append('flag_generate', artworkConfig.flag_generate ?? true)
    // formData.append('flag_complex_cmp', artworkConfig.flag_complex_cmp ?? true)
    // formData.append('flag_simple_cmp', artworkConfig.flag_simple_cmp ?? true)
    // formData.append('flag_white_cmp', artworkConfig.flag_white_cmp ?? true)

    // formData.append('output_size_w', artworkConfig.output_size_w)
    // formData.append('output_size_h', artworkConfig.output_size_h)

    // formData.append('output_size_list', artworkConfig.output_size_list ?? [])
    // formData.append(
    //   'simple_bg_color_list',
    //   artworkConfig.simple_bg_color_list ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList
    // )

    const scrollTargetId = `a_${artwork.id}`
    // querySelector 에 불가능한 특수문자 앞에  \를 넣어줘야한다고 함.
    const anchorIdTranslation = scrollTargetId.replace(/[#[\].():'"~,]/g, '\\$&')

    const anchor = document.querySelector(`#${anchorIdTranslation}`)

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }

    apis.portfolio
      .updateArtworkFeedback(portfolioId, artwork.id, {
        feedback,
        themeTemplate: option.theme_template,
        themeCustom: option.theme_custom,
      })
      .then(() => {
        refreshArtwork()
        apis.appfront
          .retry(formData)
          .then(() => {
            // refreshArtworks()
            refreshArtwork()
          })
          .catch(error => {
            console.log(error)
            refreshArtwork()
          })
        refreshRegenCount()
      })
  }

  const createFromNukki = () => {
    // ----- GA4 event -----
    window.gtag('event', 'removebg_img_generate_begin', {})
    // ---------------------
    setCreateImageDialog(true)
  }

  const regenerateHandler = () => {
    if (isUploadPage || isPortfolioPage || isRemoveBgPage) return handleRegenerate

    if (isMannequinPage) {
      return mannequinRegenrate
    }

    if (isFacePage) {
      return handleFaceRegenerate
    }
    if (isModelBgPage) {
      return handleModelbgRegenerate
    }
  }

  const handleBannerEdit = () => {
    if (artworkCopy) {
      // ----- GA4 event -----
      window.gtag('event', 'banner_edit_begin', {})
      // ---------------------
      setOpenBannerEditor(true)
    }
  }

  return (
    <>
      <CenterAlignStack sx={{ width: { lg: '40rem' }, opacity: artworkLoading ? 0.6 : 1 }}>
        {/* 아트워크 이름, 휴지통 버튼, 재생성/누끼수정 버튼 */}
        <CenterAlignStack direction="row" sx={{ width: '100%', justifyContent: 'space-between' }}>
          <>
            <Box
              sx={{
                display: 'flex',
                width: { lg: '22rem' },
                textOverflow: 'ellipsis',
                userSelect: 'none',
              }}
            >
              <Typography
                noWrap
                sx={{ width: 'min-content', fontSize: { lg: '1.6rem' }, fontWeight: { lg: 600 } }}
              >
                {isNoSO ? t('banner_config.no_product') : artwork.name}
              </Typography>
            </Box>

            <Stack direction="row" spacing="0.8rem">
              <TrashcanButton handleClick={deleteSelected} disabled={!artworkDone} />
            </Stack>
          </>
        </CenterAlignStack>

        <Divider
          orientation="horizontal"
          flexItem
          sx={{
            mt: { lg: '1.2rem' },
            mb: { lg: '2rem' },
            background: theme => theme.palette.common.black,
          }}
        />

        {/* 설정 표시 박스 */}
        <Box
          sx={{
            width: '100%',
            py: { lg: '2.8rem' },
            px: { lg: '2.4rem' },
            background: '#FAFAFA',
            '& .item-title': {
              fontSize: { lg: '1.6rem' },
              fontWeight: 600,
              mr: '0.6rem',
            },
            '& .item-value': {
              fontSize: { lg: '1.6rem' },
              fontWeight: 400,
              maxWidth: { lg: '26rem' },

              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            },
            position: 'relative',
          }}
        >
          <ConfigDisplayContent
            artworkConfig={artworkConfig}
            pieceImageSize={pieceImageSize}
            currentPiece={currentPiece}
          />
        </Box>

        {/* 버튼 (다운로드) */}
        <Box className="control-button-box" sx={{ width: '100%' }}>
          <Stack
            direction="row"
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              mt: { lg: '2rem' },
            }}
          >
            <ControlButton
              variant="contained"
              sx={{
                width: { lg: '100%' },
                boxShadow: 'none',
                minHeight: { lg: '4.8rem' },
                '& .loader': {
                  '& span span': {
                    backgroundColor: 'white !important',
                  },
                },
              }}
              onClick={() => {
                setPleaseLoginDialog({ open: true, type: 'download' })
                // ----- GA4 event -----
                window.gtag('event', 'non_member_removebg_download', {
                  method: 'row',
                })
                // ---------------------
              }}
              disabled={!artworkDone || artworkError}
            >
              {artworkLoading ? (
                <span className="loader">
                  <Loader width={200} />
                </span>
              ) : (
                t('button.download')
              )}
            </ControlButton>
          </Stack>
        </Box>

        {/* 블로그 글, 광고 표시 영역 */}
        {showAdBanner && randomBlogPost && (
          <Box
            sx={{
              width: { lg: '40rem' },
              height: { lg: '8rem' },
              position: 'relative',
              cursor: 'pointer',
              mt: { lg: '4rem' },
              // mt: 'auto',
              // mb: '8.8rem',
              '& img': {
                zIndex: 1,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                filter: 'brightness(50%)',
              },
            }}
            onClick={() => {
              navigate(
                `/blog/${randomBlogPost.language ?? 'ko'}/${randomBlogPost.category}/${
                  randomBlogPost.id
                }`
              )
            }}
          >
            <Stack
              sx={{
                position: 'absolute',
                // width: '36rem',
                // height: '2.7rem',
                width: '100%',
                height: '100%',
                zIndex: 2,
                alignItems: 'start',
                justifyContent: 'end',
                px: { lg: '2rem' },
                py: { lg: '0.5rem' },
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: '1.2rem' },
                  color: 'white',
                  fontWeight: 600,
                  display: 'flex',
                  '& span': {
                    maxWidth: { lg: '32rem' },
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    WebkitLineClamp: 2,
                  },
                }}
              >
                <span>{t(`blog.${randomBlogPost.category}`, '')}</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '1.8rem' },
                  color: 'white',
                  fontWeight: 600,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  '& span.title': {
                    maxWidth: { lg: '32rem' },
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    WebkitLineClamp: 2,
                  },
                }}
              >
                <span className="title">{randomBlogPost.title}</span>
                <span>&nbsp;&nbsp;{'→'}</span>
              </Typography>
            </Stack>
            <img src={`${getS3ImageSrc(randomBlogPost.cover_image_url)}`} />
          </Box>
        )}
      </CenterAlignStack>
    </>
  )
}

function EditNukkiButton({ handleClick, ...buttonProps }) {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()

  return (
    <>
      <Box>
        <ButtonWithStartIcon
          variant="outlined"
          startIcon={<NukkiIcon />}
          onClick={() => {
            handleClick()
          }}
          sx={{
            '&:hover .MuiButton-startIcon path': {
              stroke: theme => theme.palette.draph.newblue,
            },
            '&:disabled': {
              borderColor: '#BBBBBB',
              '& path': { stroke: '#BBBBBB' },
            },
          }}
          {...buttonProps}
        >
          {t('button.edit')}
          <ButtonFreeBadge />
        </ButtonWithStartIcon>
      </Box>
    </>
  )
}

function TrashcanButton({ handleClick, ...buttonProps }) {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()

  return (
    <>
      <Box>
        <ButtonWithStartIcon
          variant="outlined"
          onClick={() => {
            handleClick()
          }}
          sx={{
            px: '0.5rem',
            minWidth: 'unset',

            '&:hover': {
              backgroundColor: theme => theme.palette.common.white,
              borderColor: theme => theme.palette.common.red,
            },
            '&:disabled': {
              borderColor: '#BBBBBB',
              '& svg': { stroke: '#BBBBBB' },
            },

            '& svg': {
              stroke: theme => theme.palette.common.black,
            },
            '&:hover svg': {
              stroke: theme => theme.palette.common.red,
            },
          }}
          {...buttonProps}
        >
          <TrashCanIcon />
        </ButtonWithStartIcon>
      </Box>
    </>
  )
}

function RegenerateButton({
  handleClick,
  buttonClass = '',
  redoAnimationStart,
  disabled = false,
  pieceDownloaded = false,
}) {
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const { t } = useTranslation()
  const [regenButtonHover, setRegenButtonHover] = useState(false)
  const isMobile = useMobileMediaQuery()

  return (
    <>
      <Box
        sx={{
          animation: redoAnimationStart ? 'tilt-shaking 0.2s linear 25' : '',
          '@keyframes tilt-shaking': {
            '0%': { transform: 'rotate(0deg)', boxShadow: '0 0 2px rgb(77, 128, 255)' },
            '25%': { transform: 'rotate(3deg)' },
            '50%': { transform: 'rotate(0eg)', boxShadow: '0 0 5px rgb(77, 128, 255, 0.8)' },
            '75%': { transform: 'rotate(-3deg)' },
            '100%': { transform: 'rotate(0deg)', boxShadow: '0 0 2px rgb(77, 128, 255)' },
          },
          position: 'relative',
          '&:hover': {
            '& #regenerate-button-free-badge': regenButtonHover
              ? {
                  '& ': {
                    background: theme => theme.palette.draph.newblue,
                  },
                }
              : {},
          },
        }}
      >
        {disabled ? (
          <ButtonWithStartIcon
            variant="outlined"
            startIcon={<RefreshIcon size={isMobile ? 'small' : 'medium'} />}
            className={buttonClass}
            disabled
          >
            {t('button.regenerate')}
            <ButtonFreeBadge />
          </ButtonWithStartIcon>
        ) : (
          <CustomTooltip
            enterDelay={0}
            leaveDelay={100}
            title={
              <div
                onClick={() => {
                  if (
                    portfolioType === config.PORTFOLIO_TYPE_UPLOAD ||
                    portfolioType === config.PORTFOLIO_TYPE_NORMAL
                  ) {
                    // ----- GA4 event -----
                    const eventName = portfolioType
                      ? 'regenerate_begin'
                      : 'portfolio_regenerate_begin'
                    window.gtag('event', eventName, { method: 'tooltip' })
                    // ---------------------
                  }
                  handleClick()
                }}
                style={{ cursor: 'pointer' }}
              >
                {pieceDownloaded ? (
                  <>
                    <Korean>
                      다운로드 받은 내역이 있는 아트워크를 <br />
                      재생성하는 경우, 1 크레딧이 소진됩니다.
                    </Korean>
                    <English>
                      Regenerating an artwork that has already <br />
                      been downloaded will consume 1 credit.
                    </English>
                  </>
                ) : (
                  t('artwork_control.tooltip_comment')
                )}
              </div>
            }
          >
            <ButtonWithStartIcon
              variant="outlined"
              startIcon={<RefreshIcon size={isMobile ? 'small' : 'medium'} />}
              onClick={() => {
                if (
                  portfolioType === config.PORTFOLIO_TYPE_UPLOAD ||
                  portfolioType === config.PORTFOLIO_TYPE_NORMAL
                ) {
                  // ----- GA4 event -----
                  const eventName = portfolioType
                    ? 'regenerate_begin'
                    : 'portfolio_regenerate_begin'
                  window.gtag('event', eventName, { method: 'button' })
                  // ---------------------
                }
                handleClick()
              }}
              className={buttonClass}
              onMouseEnter={() => setRegenButtonHover(true)}
              onMouseLeave={() => setRegenButtonHover(false)}
              sx={{
                '@keyframes tilt-shaking': {
                  '0%': { transform: 'rotate(0deg)' },
                  '25%': { transform: 'rotate(3deg)' },
                  '50%': { transform: 'rotate(0eg)' },
                  '75%': { transform: 'rotate(-3deg)' },
                  '100%': { transform: 'rotate(0deg)' },
                },
                '&.shake': { animation: 'tilt-shaking 0.2s linear 3' },
              }}
            >
              {t('button.regenerate')}
              {!pieceDownloaded && <ButtonFreeBadge />}
            </ButtonWithStartIcon>
          </CustomTooltip>
        )}
      </Box>
    </>
  )
}

// 메뉴별 버튼 (레이아웃이 많이 달라지면 별도로 만들어서 사용) --------------------------------------------------------
function DefaultControlButtons({
  download,
  displayUpscaleButton = false,
  upscale,
  currentPiece,
  canMakeGIFPieces,
  handleOpenGIFDialog,
  loading,
  ...buttonProps
}) {
  const { t } = useTranslation()

  return (
    <>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          mt: { lg: '2rem' },
        }}
        spacing={{ lg: '1.2rem' }}
      >
        <ControlButton
          variant="contained"
          sx={{
            flex: 1,
            // width: { lg: '24.8rem' },
            boxShadow: 'none',
            minHeight: { lg: '4.8rem' },
            '& .loader': {
              '& span span': {
                backgroundColor: 'white !important',
              },
            },
          }}
          onClick={download}
          {...buttonProps}
        >
          {loading ? (
            <span className="loader">
              <Loader width={200} />
            </span>
          ) : (
            t('button.download')
          )}
        </ControlButton>

        {displayUpscaleButton && (
          <ControlButton
            sx={{
              width: { lg: '14rem' },
              color: 'white',
              background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
              transition: 'all 0.5s ease',
              '&:hover': {
                backgroundSize: '200% 200%',
                animation: `gradientAnimation 2s linear infinite`,
              },
            }}
            onClick={upscale}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1H16.9C16.9552 1 17 1.04477 17 1.1V9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M5 5H12.9C12.9552 5 13 5.04477 13 5.1V13"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 9H8.9C8.95523 9 9 9.04477 9 9.1V17"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            &nbsp;
            {t('button.upscale')}
          </ControlButton>
        )}
      </Stack>
    </>
  )
}

function RemoveBgControlButtons({ download, createFromNukki, loading, ...buttonProps }) {
  const { t } = useTranslation()
  return (
    <>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          mt: { lg: '2rem' },
        }}
      >
        <ControlButton
          variant="contained"
          sx={{
            width: { lg: '100%' },
            boxShadow: 'none',
            minHeight: { lg: '4.8rem' },
            '& .loader': {
              '& span span': {
                backgroundColor: 'white !important',
              },
            },
          }}
          onClick={download}
          {...buttonProps}
        >
          {loading ? (
            <span className="loader">
              <Loader width={200} />
            </span>
          ) : (
            t('button.download')
          )}
        </ControlButton>

        {/* 배경 생성 버튼 - 누끼이미지에서 바로 배경 생성 메뉴로 넘기는 기능 */}
        <ControlButton
          variant="outlined"
          sx={{ width: { lg: '14rem' }, minWidth: { lg: '14rem' }, ml: { lg: '1.2rem' } }}
          onClick={createFromNukki}
          {...buttonProps}
        >
          {t('button.upload_from_nukki')}
        </ControlButton>
      </Stack>
    </>
  )
}

function CommerceControlButtons({
  artwork,
  currentPiece,
  download,
  handleOpenGIFDialog,
  ...buttonProps
}) {
  const [nButtonLoading, setNButtonLoading] = useState(false)
  const [nhnButtonLoading, setNHNButtonLoading] = useState(false)
  const [cafe24ButtonLoading, setCafe24ButtonLoading] = useState(false)

  const { t } = useTranslation()
  const { showConfirm } = useConfirm()

  const user = useRecoilValue(userAtom)

  const isNCommerceUser = user.login_sns === 'naver_commerce'
  const isNHNCommerceUser = user.login_sns === 'nhn_commerce'
  const isCafe24CommerceUser = user.login_sns === 'cafe24_commerce'

  const handleNaverProductImage = () => {
    const nChannelProductNo = artwork.id.split('_').length >= 2 ? artwork.id.split('_')[1] : null
    // 9359734030
    if (nChannelProductNo) {
      setNButtonLoading(true)
      apis.naverCommerce.getChannelProductDetail(nChannelProductNo).then(response => {
        const p = response.data

        if (!p.originProduct) {
          showConfirm({
            alertOnly: true,
            content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
          })
          setNButtonLoading(false)
          return
        }

        let productName = p.originProduct?.name

        if (p.smartstoreChannelProduct?.channelProductName?.length > 0) {
          productName = p.smartstoreChannelProduct.channelProductName
        }

        showConfirm({
          content: (
            <Typography>
              스토어 상품{' '}
              <span style={{ fontWeight: 800 }}>
                [{nChannelProductNo} {productName}]
              </span>{' '}
              의 대표이미지가 즉시 교체됩니다.
            </Typography>
          ),
          onConfirm: () => {
            apis.naverCommerce
              .updateProductImage({
                channel_product_no: nChannelProductNo,
                images: [getS3ImageSrc(currentPiece?.path)],
              })
              .then(response => {
                setNButtonLoading(false)

                // ----- GA4 event -----
                window.gtag('event', 'nstore_replace', {
                  menu: productName,
                  output_url: currentPiece?.path,
                })
                // ---------------------

                if (response.data.success) {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>상품 이미지가 교체되었습니다.</Typography>,
                  })
                } else {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>{t('common.error')}</Typography>,
                  })
                }
              })
          },
          onCancel: () => {
            setNButtonLoading(false)
          },
        })
      })
    }
  }

  const handleNHNProductImage = () => {
    const productNo =
      artwork.id.split('shopby_').length >= 2 ? artwork.id.split('shopby_').at(1) : null

    if (productNo) {
      setNHNButtonLoading(true)
      apis.nhnCommerce.getProductDetail(productNo).then(response => {
        const p = response.data

        if (!p?.mallProduct) {
          showConfirm({
            alertOnly: true,
            content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
          })
          setNHNButtonLoading(false)
          return
        }

        const productName = p.mallProduct?.productName

        showConfirm({
          content: (
            <Typography>
              스토어 상품{' '}
              <span style={{ fontWeight: 800 }}>
                [{productNo} {productName}]
              </span>{' '}
              의 대표이미지가 즉시 교체됩니다.
            </Typography>
          ),
          onConfirm: () => {
            apis.nhnCommerce
              .updateProductImage({
                product_no: productNo,
                images: [getS3ImageSrc(currentPiece?.path)],
              })
              .then(response => {
                setNHNButtonLoading(false)

                // ----- GA4 event -----
                window.gtag('event', 'shopby_replace', {
                  menu: productName,
                  output_url: currentPiece?.path,
                })
                // ---------------------
                if (response.data.success) {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>상품 이미지가 교체되었습니다.</Typography>,
                  })
                } else {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>{t('common.error')}</Typography>,
                  })
                }
              })
              .finally(() => {
                setNHNButtonLoading(false)
              })
          },
          onCancel: () => {
            setNHNButtonLoading(false)
          },
        })
      })
    } else {
      showConfirm({
        alertOnly: true,
        content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
      })
    }
  }

  const handleCafe24ProductImage = () => {
    const productNo =
      artwork.id.split('cafe24_').length >= 2 ? artwork.id.split('cafe24_').at(1) : null

    if (productNo) {
      setCafe24ButtonLoading(true)
      apis.cafe24Commerce.getProductDetail(productNo).then(response => {
        const p = response.data.product

        if (!p || !p.product_no) {
          showConfirm({
            alertOnly: true,
            content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
          })
          setCafe24ButtonLoading(false)
          return
        }

        const productName = p.product_name

        showConfirm({
          content: (
            <Typography>
              스토어 상품{' '}
              <span style={{ fontWeight: 800 }}>
                [{productNo} {productName}]
              </span>{' '}
              의 대표이미지가 즉시 교체됩니다.
            </Typography>
          ),
          onConfirm: () => {
            apis.cafe24Commerce
              .updateProductImage({
                product_no: productNo,
                image_s3_url: [getS3ImageSrc(currentPiece?.path)],
              })
              .then(response => {
                setCafe24ButtonLoading(false)

                // ----- GA4 event -----
                window.gtag('event', 'cafe24_replace', {
                  menu: productName,
                  output_url: currentPiece?.path,
                })
                // ---------------------
                if (response.data.success) {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>상품 이미지가 교체되었습니다.</Typography>,
                  })
                } else {
                  showConfirm({
                    alertOnly: true,
                    content: <Typography>{t('common.error')}</Typography>,
                  })
                }
              })
              .finally(() => {
                setCafe24ButtonLoading(false)
              })
          },
          onCancel: () => {
            setCafe24ButtonLoading(false)
          },
        })
      })
    } else {
      showConfirm({
        alertOnly: true,
        content: <Typography>상품 정보를 조회할 수 없습니다.</Typography>,
      })
    }
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          mt: { lg: '2rem' },
        }}
      >
        <ControlButton
          variant="contained"
          sx={{ width: { lg: '100%' }, boxShadow: 'none' }}
          onClick={download}
          {...buttonProps}
        >
          {t('button.download')}
        </ControlButton>

        {isNCommerceUser && (
          <NaverProductButton
            text="대표 이미지 교체"
            type="update"
            onClick={handleNaverProductImage}
            loading={nButtonLoading}
            // sx={{ width: { lg: '19.4rem' } }}
            sx={{ ml: { lg: '1.2rem' } }}
            {...buttonProps}
          />
        )}

        {isNHNCommerceUser && (
          <NHNProductContainedButton
            text="대표 이미지 교체"
            type="update"
            onClick={handleNHNProductImage}
            loading={nhnButtonLoading}
            sx={{ ml: { lg: '1.2rem' } }}
            {...buttonProps}
          />
        )}

        {isCafe24CommerceUser && (
          <Cafe24ProductButton
            text="대표 이미지 교체"
            type="update"
            onClick={handleCafe24ProductImage}
            loading={cafe24ButtonLoading}
            sx={{ ml: { lg: '1.2rem' } }}
            {...buttonProps}
          />
        )}
      </Stack>
    </>
  )
}

export function ArtworkError({ artwork, errorRetry }) {
  const { t } = useTranslation()

  const user = useRecoilValue(userAtom)

  const msg = artwork.err_type
    ? t(`error_message.${artwork.err_type}`)
      ? t([`error_message.${artwork.err_type}`, `error_message.default`])
      : t(`error_message.default`)
    : t(`error_message.default`)

  const msgArr = msg.split('\n')

  const showDelayInfoForSub =
    ['10001', '10003'].includes(artwork.err_type?.toString()) &&
    !(artwork.used_credit < 0) &&
    user.subscription_plan &&
    getUserType(user) === 'subscriber'

  const showDelayInfoForNonSub =
    artwork.err_type?.toString() === '10003' && artwork.used_credit < 0 && !user.subscription_plan

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isBanner = portfolioType === config.PORTFOLIO_TYPE_BANNER
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const isFunFamePage = portfolioType === config.PORTFOLIO_TYPE_FAME
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION
  const isBannerV2 = portfolioType === config.PORTFOLIO_TYPE_BANNERV2
  const renderErrorRetryButton =
    !(isFunFamePage || isBundlePage || isBgExpansionPage || isBanner || isBannerV2) &&
    config.ARTWORK_ERROR_STATUS.includes(artwork.status) &&
    !['10001', '10002', '10003'].includes(artwork?.err_type?.toString())

  return (
    <>
      <CenterAlignStack
        sx={{
          width: { lg: '40rem' },
          height: `${LARGE_IMAGE_WIDTH_REM}rem`,
          justifyContent: 'center',
        }}
      >
        <ArtworkWarningIcon />
        <Box sx={{ fontSize: '1.4rem', fontWeight: 400, textAlign: 'center', mt: '1rem' }}>
          {msgArr.map((s, i) => (
            <span key={i}>
              {s} <br />
            </span>
          ))}
          {
            // 생성 지연 케이스인 경우 (10003), 구독자 여부에 따라 추가 메세지 표시
            showDelayInfoForSub ? (
              <Box sx={{ mt: '2.4rem' }}>
                <DelayInfoForSubscriber />
              </Box>
            ) : showDelayInfoForNonSub ? (
              <Box sx={{ mt: '2.4rem' }}>
                <DelayInfoForNonSubscriber />
              </Box>
            ) : (
              <></>
            )
          }
        </Box>

        {/* 에러 재시도 버튼 */}
        {renderErrorRetryButton && (
          <ControlButton
            variant="outlined"
            onClick={errorRetry}
            sx={{
              width: '100%',

              py: { lg: '0.7rem' },
              mt: { lg: '2.4rem' },
              borderRadius: '0.4rem',
              fontSize: { lg: '2rem' },
              fontWeight: 600,
            }}
          >
            {t('button.retry')}
          </ControlButton>
        )}
      </CenterAlignStack>
    </>
  )
}

function ConfigDisplayContent({ artworkConfig, pieceImageSize, currentPiece }) {
  const { t } = useTranslation()
  const configSimple = !(artworkConfig?.flag_complex_cmp || artworkConfig?.flag_generate)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isRemoveBgPage =
    portfolioType === config.PORTFOLIO_TYPE_REMOVEBG &&
    location.pathname.includes('/generate/removebg')
  const isUploadPage =
    portfolioType === config.PORTFOLIO_TYPE_UPLOAD && location.pathname.includes('/generate/upload')

  const isPortfolioPage = portfolioType === config.PORTFOLIO_TYPE_NORMAL
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE
  const isModelBgPage = portfolioType === config.PORTFOLIO_TYPE_MODELBG

  const defaultConfigList = [
    'theme_template',
    'object_category',
    'flag_white_cmp',
    'size',
    'flag_multiblob_sod',
    'gen_face',
  ]

  const [showConfigList, setShowConfigList] = useState(defaultConfigList)

  useEffect(() => {
    let l = defaultConfigList
    if (isPortfolioPage || isUploadPage) {
      l = defaultConfigList
    } else if (isRemoveBgPage) {
      l = ['size']
    } else if (isBundlePage) {
      l = ['size']
    } else if (isBgExpansionPage) {
      l = ['size']
    } else if (isMannequinPage) {
      l = ['is_male', 'size', 'age', 'prompt']
    } else if (isFacePage) {
      l = ['size', 'facemorphing_gender', 'facemorphing_race', 'is_male', 'model_name']
    } else if (isModelBgPage) {
      l = ['size', 'is_male', 'model_name']
    }

    setShowConfigList(l)
  }, [])

  return (
    <Stack spacing="1rem">
      {/* 배경 테마 */}
      {showConfigList.includes('theme_template') && (
        <Stack direction="row">
          <span className="item-title">{t('upload_config.bg_theme')}: </span>
          <span className="item-value">
            {artworkConfig?.has_ref_image ? (
              t('upload_header.ref_image')
            ) : (
              <>
                {/* {configSimple ? t('upload_config.bg_simple') : t('upload_config.bg_concept')}
                {!configSimple && ' > '} */}

                {!configSimple &&
                  (artworkConfig?.theme_template?.length > 0
                    ? artworkConfig?.theme_template === 'custom'
                      ? artworkConfig.theme_custom
                      : t(`theme_template.${artworkConfig?.theme_template}`)
                    : '-')}
              </>
            )}
          </span>
        </Stack>
      )}

      {/* 상품 카테고리 */}
      {showConfigList.includes('object_category') && (
        <Stack direction="row">
          <span className="item-title">{t('upload_config.product_category')}: </span>
          <span className="item-value">
            {t(`artwork_category.${artworkConfig?.object_category}`)}
          </span>
        </Stack>
      )}

      {/* 배경 제거 이미지 포함 */}
      {showConfigList.includes('flag_white_cmp') && (
        <Stack direction="row">
          <span className="item-title">{t('upload_config.include_nukki')}: </span>
          {artworkConfig.flag_white_cmp ? <YesChip /> : <NoChip />}
        </Stack>
      )}

      {/* 이미지 사이즈 */}
      {showConfigList.includes('size') &&
        pieceImageSize[currentPiece?.id]?.w &&
        pieceImageSize[currentPiece?.id]?.h && (
          <Stack direction="row">
            <span className="item-title">{t('upload_config.image_size')}: </span>
            {t('upload_config.width')} {pieceImageSize[currentPiece.id].w}px *{' '}
            {t('upload_config.height')} {pieceImageSize[currentPiece.id].h}px
          </Stack>
        )}

      {/* 하나의 사물만 감지 */}
      {showConfigList.includes('flag_multiblob_sod') && (
        <Stack direction="row">
          <span className="item-title">{t('upload_config.multi_blob')}: </span>
          {artworkConfig.flag_multiblob_sod ? <NoChip /> : <YesChip />}
        </Stack>
      )}

      {/* 모델 얼굴 변경 */}
      {showConfigList.includes('gen_face') && (
        <Stack direction="row">
          <span className="item-title">{t('upload_config.face_swap')}: </span>
          {artworkConfig.gen_face ? <YesChip /> : <NoChip />}
        </Stack>
      )}

      {/* 마네킹 - 성별 */}
      {showConfigList.includes('is_male') && typeof artworkConfig.is_male === 'boolean' && (
        <Stack direction="row">
          <span className="item-title">{t('mannequin.gender')}: </span>
          {artworkConfig.is_male === true ? t(`button.male`) : t(`button.female`)}
        </Stack>
      )}

      {/* 마네킹 - 연령대 */}
      {isMannequinPage && showConfigList.includes('age') && artworkConfig.age && (
        <Stack direction="row">
          <span className="item-title">{t('mannequin.age')}: </span>
          {t(`button.${artworkConfig.age}`, '')}
        </Stack>
      )}

      {/* 마네킹 - 배경 테마 (직접 입력) */}
      {isMannequinPage && showConfigList.includes('prompt') && artworkConfig.prompt && (
        <Stack direction="row">
          <span className="item-title">{t('upload_config.bg_theme')}: </span>
          <span className="item-value">{artworkConfig.prompt}</span>
        </Stack>
      )}

      {/* 얼굴 변경 - 성별 */}
      {isFacePage &&
        showConfigList.includes('facemorphing_gender') &&
        artworkConfig.facemorphing_gender && (
          <Stack direction="row">
            <span className="item-title">{t('mannequin.gender')}: </span>
            {artworkConfig.facemorphing_gender === 'man' ? t(`button.male`) : t(`button.female`)}
          </Stack>
        )}

      {/* 얼굴 변경 - 모델(인종) */}
      {isFacePage &&
        showConfigList.includes('facemorphing_race') &&
        artworkConfig.facemorphing_gender &&
        artworkConfig.facemorphing_race && (
          <CenterAlignStack direction="row" spacing={'1rem'}>
            <span className="item-title">
              <Korean>모델</Korean>
              <English>Model</English>
            </span>

            <Box sx={{ width: '8rem' }}>
              <img
                src={`/static/images/model_example/${
                  artworkConfig.facemorphing_gender === 'man' ? 'male' : 'female'
                }_${artworkConfig.facemorphing_race}.png`}
              />
            </Box>
          </CenterAlignStack>
        )}

      {/* 얼굴 변경 - 모델 네임 */}
      {isFacePage && showConfigList.includes('model_name') && artworkConfig.model_name && (
        <CenterAlignStack direction="row" spacing={'1rem'}>
          <span className="item-title">
            <Korean>모델: </Korean>
            <English>Model: </English>
          </span>

          {artworkConfig.model_name === 'auto' ? (
            t('theme_template.auto')
          ) : (
            <Box sx={{ width: '8rem' }}>
              <img
                style={{ borderRadius: '1rem' }}
                src={`/static/images/model_example/${artworkConfig.is_male ? 'male' : 'female'}_${
                  artworkConfig.model_name
                }.png`}
              />
            </Box>
          )}
        </CenterAlignStack>
      )}

      {/* 얼굴 변경 - 모델 네임 */}
      {isModelBgPage &&
        showConfigList.includes('model_name') &&
        artworkConfig.model_name &&
        typeof artworkConfig.model_name === 'object' && (
          <CenterAlignStack direction="row" spacing={'1rem'}>
            <span className="item-title">
              <Korean>모델: </Korean>
              <English>Model: </English>
            </span>

            {artworkConfig?.model_name[0] === 'auto'
              ? t('theme_template.auto')
              : artworkConfig?.model_name.map(n => {
                  return (
                    <Box sx={{ width: '8rem' }} key={n}>
                      <img
                        style={{ borderRadius: '1rem' }}
                        src={`/static/images/model_example/${
                          artworkConfig.is_male === true ? 'male' : 'female'
                        }_${n}.png`}
                      />
                    </Box>
                  )
                })}
          </CenterAlignStack>
        )}
    </Stack>
  )
}

function BannerConfigDisplayContent({ artworkConfig, pieceImageSize, currentPiece }) {
  const { t } = useTranslation()

  const defaultConfigList = [
    'size',
    'banner_style',
    'bg_depict',
    // 'brandFeature',
    'human_depict',
    'human_interaction_flag',
    'productFeature',
    // 'product_depict',
  ]

  const [showConfigList, setShowConfigList] = useState(defaultConfigList)

  return (
    <Stack spacing="1rem">
      {/* 배너 타입 (광고 목적 선택) */}
      {showConfigList.includes('banner_style') && artworkConfig.banner_style && (
        <Stack direction="row">
          <span className="item-title">
            <Korean>타입: </Korean>
            <English>Type: </English>
          </span>
          <span className="item-value">
            {artworkConfig.banner_style === 'image_hooking' ? (
              t('banner_config.template_hooking_1')
            ) : artworkConfig.banner_style === 'text_hooking' ? (
              t('banner_config.template_hooking_2')
            ) : (
              <></>
            )}
          </span>
        </Stack>
      )}

      {/* 광고 디자인 묘사 (광고 세부 사항) */}
      {showConfigList.includes('bg_depict') && artworkConfig.bg_depict && (
        <Stack direction="row">
          <span className="item-title">
            <Korean>광고 디자인: </Korean>
            {/* TODO  */}
            <English>Background: </English>
          </span>
          <span className="item-value">{artworkConfig.bg_depict}</span>
        </Stack>
      )}

      {/* 상품 이름 ?? (상품 정보) */}
      {/* config 에 없음 */}

      {/* 상품 컨셉 (상품 정보) */}
      {showConfigList.includes('productFeature') && artworkConfig.productFeature && (
        <Stack direction="row">
          <span className="item-title">
            <Korean>상품 컨셉: </Korean>
            <English>Product Concept: </English>
          </span>
          <span className="item-value">{artworkConfig.productFeature}</span>
        </Stack>
      )}

      {/* AI 모델 묘사 */}
      {showConfigList.includes('human_depict') && artworkConfig.human_depict && (
        <Stack direction="row">
          <span className="item-title">
            <Korean>AI 모델: </Korean>
            <English>AI Model: </English>
          </span>
          <span className="item-value">{artworkConfig.human_depict}</span>
        </Stack>
      )}

      {/* 이미지 사이즈 */}
      {showConfigList.includes('size') &&
        pieceImageSize[currentPiece?.id]?.w &&
        pieceImageSize[currentPiece?.id]?.h && (
          <Stack direction="row">
            <span className="item-title">{t('upload_config.image_size')}: </span>
            {t('upload_config.width')} {pieceImageSize[currentPiece.id].w}px *{' '}
            {t('upload_config.height')} {pieceImageSize[currentPiece.id].h}px
          </Stack>
        )}
    </Stack>
  )
}

function AdBannerBox({ handleClick, children }) {
  return (
    <>
      <Box
        sx={{
          width: {
            lg: '40rem',
          },
          height: { lg: '8rem' },
          position: 'relative',
          cursor: 'pointer',
          mt: { lg: '4rem' },
          overflow: 'hidden',
        }}
        onClick={handleClick}
      >
        {children}
      </Box>
    </>
  )
}

function BlogAdBanner({ blogPost }) {
  const { t } = useTranslation()
  const handleClick = () => {
    window.location.replace(`/blog/${blogPost.category}/${blogPost.id}`)
  }
  return (
    <>
      <AdBannerBox handleClick={handleClick}>
        <Stack
          sx={{
            position: 'absolute',
            // width: '36rem',
            // height: '2.7rem',
            width: '100%',
            height: '100%',
            zIndex: 2,
            alignItems: 'start',
            justifyContent: 'end',
            px: { lg: '2rem' },
            py: { lg: '0.5rem' },
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: '1.2rem' },
              color: 'white',
              fontWeight: 600,
              display: 'flex',
              '& span': {
                maxWidth: { lg: '32rem' },
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                WebkitLineClamp: 2,
              },
            }}
          >
            <span>{t(`blog.${blogPost.category}`, '')}</span>
          </Typography>
          <Typography
            sx={{
              fontSize: { lg: '1.8rem' },
              color: 'white',
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              '& span.title': {
                maxWidth: { lg: '32rem' },
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                WebkitLineClamp: 2,
              },
            }}
          >
            <span className="title">{blogPost.title}</span>
            <span>&nbsp;&nbsp;{'→'}</span>
          </Typography>
        </Stack>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            '& img': {
              zIndex: 1,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            },
          }}
        >
          <img src={`${getS3ImageSrc(blogPost.cover_image_url)}`} />
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: '#000000',
            opacity: 0.2,
          }}
        ></Box>
      </AdBannerBox>
    </>
  )
}

function BespokeAdBanner() {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/overview/bespoke')
  }
  return (
    <AdBannerBox handleClick={handleClick}>
      <GlobalStyles
        styles={`
          @font-face {
            font-family: 'Paperlogy';
            src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-4Regular.woff2') format('woff2');
            font-weight: 400;
            font-style: normal;
          }
          @font-face {
            font-family: 'Paperlogy';
            src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-7Bold.woff2') format('woff2');
            font-weight: 700;
            font-style: normal;
          }
        `}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          // border: '1px solid #ccc',
          backgroundImage: `url('/static/images/overview/bespoke/bespoke_banner_image.png')`,
          backgroundSize: 'auto 110px',
          backgroundPosition: 'right -85px bottom -10px',
          backgroundRepeat: 'no-repeat',
          overflow: 'hidden',
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 68.75%)',
        }}
      />
      <Typography
        sx={{
          fontSize: '1.6rem',
          lineHeight: 1.3,
          fontWeight: 400,
          position: 'absolute',
          left: '18px',
          top: '17px',
          fontFamily: 'Paperlogy', // 커스텀 폰트 적용
          color: '#ffffff', // 텍스트 색상 (이미지에 따라 조정 가능)
          '& span': { fontSize: '2rem', fontWeight: 700 },
        }}
      >
        <Korean>이것보다 고품질 이미지가 필요하다면?</Korean>
        <br />
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Korean>
            대신 제작해 드립니다!&nbsp;&nbsp;
            <svg
              width="53"
              height="12"
              viewBox="0 0 53 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M52.495 6.49497C52.7683 6.22161 52.7683 5.77839 52.495 5.50503L48.0402 1.05025C47.7668 0.776886 47.3236 0.776886 47.0503 1.05025C46.7769 1.32362 46.7769 1.76684 47.0503 2.0402L51.0101 6L47.0503 9.9598C46.7769 10.2332 46.7769 10.6764 47.0503 10.9497C47.3236 11.2231 47.7668 11.2231 48.0402 10.9497L52.495 6.49497ZM0 6.7H52V5.3H0V6.7Z"
                fill="white"
              />
            </svg>
          </Korean>
        </span>
      </Typography>
    </AdBannerBox>
  )
}
