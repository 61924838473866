import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// export default function BlogRedirect() {
//
//   useEffect(() => {
//     window.location.href = '/blog'
//   }, [])

//   return null
// }

export default function BlogRedirect() {
  const navigate = useNavigate()

  useEffect(() => {
    console.log('Blog Redirect ')
    const maxRedirects = 3
    const redirectKey = 'blogRedirectAttempts'

    // 현재 시도 횟수를 sessionStorage에서 가져오기
    let redirectAttempts = parseInt(sessionStorage.getItem(redirectKey) || '0', 10)

    if (redirectAttempts < maxRedirects) {
      // 시도 횟수 증가
      redirectAttempts += 1
      sessionStorage.setItem(redirectKey, redirectAttempts.toString())

      // 2024.09 별도로 구성한 nextjs 블로그로 리다이렉트
      window.location.href = '/blog'
    } else {
      // 무한 리다이렉트(새로고침) 방지
      navigate('/')
      sessionStorage.removeItem(redirectKey)
    }
  }, [navigate])

  return null
}
