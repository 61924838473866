import { yupResolver } from '@hookform/resolvers/yup'
import { Box, OutlinedInput, Stack, Typography, useTheme } from '@mui/material'
import { CenterAlignStack, FormErrorMessage, TextInput } from 'components'
import { Desktop, Mobile, useBreakPoint } from 'hooks/useMediaQuery'
import useConfirm from 'hooks/useConfirm'
import {
  MYPAGE_OUTLET_TOPMARGIN_DESKTOP,
  MYPAGE_PERCENTAGE_WIDHT,
  MYPAGE_SIDE_MARGIN,
} from 'layouts/MypageLayout'
import * as yup from 'yup'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { mobileFooterStyle } from './UserCharge'
import { apis } from 'apis'
import { useSnackbar } from 'notistack'
import { FOOTER_DESKTOP } from 'layouts/main/MainFooter'
import { APPBAR_DESKTOP } from 'layouts/main/MainAppBar'
import { useTranslation } from 'react-i18next'

// TODO 언어설정 필요

export default function Coupon() {
  const { t, i18n } = useTranslation()

  const successMessage = t('coupon.successMessage')

  const UserCouponSchema = yup.object({
    coupon: yup.string().required(t('coupon.enter_coupon')),
  })
  const theme = useTheme()
  const { showConfirm } = useConfirm()

  const [isInputFocused, setIsInputFocused] = useState(false)
  const [placeholder, setPlaceholder] = useState(t('coupon.enter_coupon'))

  const {
    formState: { isDirty, dirtyFields, errors, isSubmitting },
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      coupon: '',
    },
    resolver: yupResolver(UserCouponSchema),
    mode: 'onSubmit',
  })

  const onSubmit = values => {
    const couponText = values.coupon.toUpperCase()

    apis.user.useCoupon({ coupon: couponText }).then(response => {
      if (!response.data.success) {
        showConfirm({
          alertOnly: true,
          content: (
            <Typography>
              {t(`configOrDict.coupon_errorDict.${response.data.error}`) ??
                t(`configOrDict.coupon_errorDict.default`)}
            </Typography>
          ),
        })
        // enqueueSnackbar(errorDict[response.data.error] ?? errorDict.default, {
        //   variant: 'error',
        //   autoHideDuration: 2000,
        // })
      } else {
        setValue('coupon', '')

        // ----- GA4 event -----
        // window.gtag('event', '', {})
        // ---------------------

        // enqueueSnackbar(successMessage, { variant: 'success' })

        showConfirm({
          alertOnly: true,
          content: <Typography>{successMessage}</Typography>,
        })
      }
    })
  }

  const handleFocus = () => {
    setIsInputFocused(true)
    setPlaceholder('')
  }

  const handleBlur = e => {
    if (!e.target.value.length) {
      setIsInputFocused(false)
    }
    setPlaceholder(t('coupon.enter_coupon'))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: {
          lg: `calc(100vh - ${APPBAR_DESKTOP} - ${FOOTER_DESKTOP} - ${MYPAGE_OUTLET_TOPMARGIN_DESKTOP})`,
        },
      }}
    >
      <Desktop>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" style={{ overflowX: 'hidden' }}>
          <CenterAlignStack>
            <Box sx={{ mt: '5.7rem' }}>
              <TextInput
                control={control}
                name="coupon"
                error={!!errors.coupon}
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                sx={{
                  // backgroundColor: 'red',
                  '& input': isInputFocused ? { textTransform: 'uppercase' } : {},
                  '& .MuiOutlinedInput-root': {
                    height: '4.7rem !important',
                  },
                }}
              />
              <LoadingButton
                size="large"
                type="submit"
                sx={{ width: '15.8rem', height: '4.7rem', ml: '1.4rem' }}
                // onClick={handleClick}
                // endIcon={<SendIcon />}
                loading={isSubmitting}
                // loadingPosition="end"
                variant="contained"
              >
                {t('button.coupon_register')}
              </LoadingButton>
              <FormErrorMessage style={{ textAlign: 'start' }} message={errors.coupon?.message} />
            </Box>
          </CenterAlignStack>
        </form>
      </Desktop>

      <Mobile>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          style={{ overflowX: 'hidden', width: '100%' }}
        >
          <CenterAlignStack sx={{ width: '100%' }}>
            <CenterAlignStack>
              <TextInput
                sx={{
                  width: '32rem',

                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#303030',
                      borderWidth: '0.2rem !important',
                    },
                  },
                }}
                control={control}
                name="coupon"
                error={!!errors.coupon}
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {errors.coupon && (
                <FormErrorMessage style={{ textAlign: 'start' }} message={errors.coupon?.message} />
              )}

              <LoadingButton
                size="large"
                type="submit"
                sx={{ width: '32rem', height: '4.8rem', fontSize: '1.8rem', fontWeight: 800 }}
                // onClick={handleClick}
                // endIcon={<SendIcon />}
                loading={isSubmitting}
                // loadingPosition="end"
                variant="contained"
              >
                {t('button.coupon_register')}
              </LoadingButton>
            </CenterAlignStack>
          </CenterAlignStack>
        </form>
      </Mobile>

      <Box
        component="ul"
        sx={{
          mt: '4.4rem',

          height: '22.8rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '& li': {
            fontSize: { lg: '1.6rem', xs: '1.2rem' },
            fontWeight: 400,
            lineHeight: { lg: '3rem' },
            mb: { lg: '0.5rem', xs: '1rem' },
            listStyleType: 'none',
            textIndent: { lg: '1rem' },
            '&::before': {
              content: '"•"',
              marginRight: '0.3rem',
            },
          },
        }}
      >
        <Stack
          sx={{
            // px: { lg: '2rem', xs: '1rem' },
            width: '100%',
            height: '100%',

            backgroundColor: theme.palette.common.lightgray,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: { lg: '78rem', xs: '36rem' },
              ml: { lg: MYPAGE_SIDE_MARGIN, xs: '2.2rem' },
            }}
          >
            <Typography component="li" sx={{ ...mobileFooterStyle }}>
              {t('coupon.notice_1_comment')}
            </Typography>
            <Typography component="li" sx={{ ...mobileFooterStyle }}>
              {t('coupon.notice_2_comment')}
            </Typography>
            <Typography component="li" sx={{ ...mobileFooterStyle }}>
              {t('coupon.notice_3_comment')}
            </Typography>
            <Typography component="li" sx={{ ...mobileFooterStyle }}>
              {t('coupon.notice_4_comment')}
            </Typography>

            <Typography component="li" sx={{ ...mobileFooterStyle, marginBottom: '0 !important' }}>
              {t('coupon.notice_5_comment_a')}
              <Mobile>
                <br />
                &nbsp;&nbsp;
              </Mobile>
              {t('coupon.notice_5_comment_b')}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
