import {
  styled,
  Box,
  Button,
  Card,
  Divider,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Stack,
  Checkbox,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  useTheme,
  DialogActions,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { CenterAlignStack, CenterAlignBox } from './CenterAlignContainer'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { usePricing } from 'hooks/usePricing'
import { fontWeight } from '@mui/system'
import { Fragment, useEffect, useState } from 'react'
import { getDiscountRate, getOriginalPrice, getNextPaidDay } from 'pricing'
import {
  convertPriceSymbolToWord,
  getCurrencyFormatter,
  isKo,
  numberCommaFormatter,
  trasnferDate,
} from 'utils/common'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userAtom } from 'atoms'
import { apis } from 'apis'
import moment from 'moment'
import 'moment-timezone'
import {
  CheckIcon,
  CheckedSquareIcon2,
  CheckedSquareIcon3,
  MiniCheckBox,
  MiniSquare,
  MinusIcon,
  PlusIcon,
  SquareIcon2,
} from 'theme/icon'
import { creditPlansAtom } from '../atoms'
import { useLocation, useNavigate } from 'react-router-dom'
import { SUB_EVENT_ID, addSessionStorageData } from 'pages/Pricing'
import { useTranslation } from 'react-i18next'
import { English, Korean } from 'hooks/useLanguage'
import { checkAppliedSubEvent } from 'pages/CreditAndSubscriptions'
import { CustomRadio1 } from './CustomStyle'
import { generatePaymentUid } from '../hooks/usePricing'

export const SubtitleTypography = styled(Typography)(({ theme }) => ({
  '& svg': {
    marginRight: '0.7rem',
  },
  fontSize: '1.6rem',
  fontWeight: 400,
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    '& svg': {
      marginRight: '0.4rem',
    },
  },
}))

const StyledTable = styled(Table)(({ theme }) => ({
  width: '36rem',
  borderCollapse: 'separate',
  borderSpacing: '0 1rem',
  // minHeight: '32rem',
  '& th': {
    padding: 0,
    height: '4rem',
    borderBottom: 0,
    background: theme.palette.common.black,
    color: '#FFFFFF',
    fontSize: '1.4rem',
    fontWeight: 700,
    textAlign: 'center',
  },
  '& td': {
    padding: 0,
    height: '3.2rem',
    borderBottom: 0,
    lineHeight: 'normal',
    '& p': {
      fontSize: '1.3rem',
    },
  },

  '& tbody .MuiTableRow-root:hover': {
    background: theme.palette.draph.lighterblue,
  },
  '& th:nth-of-type(1),& td:nth-of-type(1)': {
    textAlign: 'center',
    width: '5.2rem',
    // border: '1px solid pink',
  },
  '& th:nth-of-type(2),& td:nth-of-type(2)': {
    textAlign: 'right',
    width: '7rem',
    paddingLeft: '0',
    paddingRight: '3px',
    // border: '1px solid yellow',
  },
  '& th:nth-of-type(3)': {
    // border: '1px solid red',
  },

  '& td:nth-of-type(3)': {
    width: '8.5rem',
    // border: '1px solid red',
  },

  '& td:nth-of-type(4)': {
    width: '17rem',
    // border: '1px solid red',
  },

  '& td:nth-of-type(5)': {
    width: '14rem',
  },
  // '& th:not(th:nth-of-type(1))': {
  //   background: theme.palette.common.black,
  //   color: '#FFFFFF',
  //   fontSize: '1.4rem',
  //   fontWeight: 700,
  //   textAlign: 'center',
  // },

  '& tbody tr td .MuiRadio-root:hover': {
    background: 'transparent',
  },

  [theme.breakpoints.down('lg')]: {
    borderSpacing: '0 0.4rem',
    minWidth: '28.8rem',
    width: 'unset',
    // minHeight: '19.1rem',
    '& th': {
      height: '3.2rem',
    },
    '& td': {
      height: '3.2rem',
      '& p': {
        fontSize: '1rem',
      },
    },

    '& th:nth-of-type(1),& td:nth-of-type(1)': {
      textAlign: 'center',
      width: '3.7rem',
      // border: '1px solid pink',
    },
    '& th:nth-of-type(2),& td:nth-of-type(2)': {
      width: '4rem',
      paddingLeft: '0.3rem',
      paddingRight: '1rem',
    },
    '& td:nth-of-type(3)': {
      width: '4.8rem',
      paddingLeft: '1rem',
    },

    '& td:nth-of-type(4)': {
      width: '10rem',
    },

    '& td:nth-of-type(5)': {
      width: '7.5rem',
    },
    '& th:not(th:nth-of-type(1))': {
      fontSize: '1rem',
    },
  },
}))

export const Header = styled(Typography)(({ theme }) => ({
  fontSize: '1.7rem',

  fontWeight: 700,
  color: theme.palette.common.white,

  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
    fontWeight: 700,
  },
}))

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '2rem',
  padding: '4.1rem 0rem',
  width: '40.8rem',

  [theme.breakpoints.down('lg')]: {
    width: '32rem',
    padding: '3rem 1.6rem 2.4rem 1.6rem',
  },
}))

const FirstSubFreeBadge = () => {
  const { t } = useTranslation()

  return (
    <CenterAlignBox
      sx={{
        alignSelf: 'center',
        width: 'fit-content',
        background: theme => theme.palette.draph.blue,
        px: { lg: '1.2rem', xs: '0.8rem' },
        py: { lg: '0.4rem', xs: '0.3rem' },
        fontSize: { lg: '1.2rem', xs: '1rem' },
        fontWeight: 700,
        color: 'white',
        borderRadius: '2rem',
      }}
    >
      {t('pricing.free_for_first_month')}
    </CenterAlignBox>
  )
}

async function checkSubscriptionEvent() {
  const response = await apis.user.getUserSubscription()
  return response.data.subscription_event
}
async function checkPlanPrice(data) {
  const response = await apis.common.checkPlanValid(data)
  return response.data
}

export function PricingCard({
  name,
  plan,
  requestPay,
  userSubId,
  userSubInfo,
  setOpenNonMemberDialog,
  highlight,
  requestPaypal,
  eventId,
  setOpenChangePaymentMethodDialog = () => {},
  partnerPlans = {},
}) {
  const [currency, setCurrency] = useState('krw')
  const [currencyFormatter, setCurrencyFormatter] = useState(getCurrencyFormatter(currency))
  const [selected, setSelected] = useState({ id: '' })
  const [userSubPlan, setUserSubPlan] = useState(null)
  const [openSubDialog, setOpenSubDialog] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const [quantity, setQuantity] = useState({})

  const DISPLAY_MAX = 5

  const { t, i18n } = useTranslation()

  const navigate = useNavigate()

  const { pricingPlanDict, getPlanDisplayName } = usePricing()

  const publicPlans = useRecoilValue(creditPlansAtom)
  const creditPlans = {
    subscription: [...publicPlans.subscription, ...(partnerPlans?.subscription ?? [])],
    payGo: [...publicPlans.payGo, ...(partnerPlans?.payGo ?? [])],
  }

  const userInfo = useRecoilValue(userAtom)
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const location = useLocation()
  const isSubscriptionMenu = name === 'subscription'
  const isPublicPricingPage = location.pathname === '/pricing'

  const isPaymentFailed = userSubInfo?.payment_status === 'failed'

  const subEventValid =
    eventId &&
    eventId === SUB_EVENT_ID &&
    plan.filter(p => p.ev && p.ev[eventId] && !isNaN(p.ev[eventId][currency])).length > 0

  const [abroadUser, setAbroadUser] = useState(false) // TODO 해외결제 테스트용! 나중에 접속지역 기반 판단

  useEffect(() => {
    const isKo = i18n.language === 'ko'

    setAbroadUser(!isKo)
  }, [i18n.language])

  useEffect(() => {
    if (plan?.length > 0) {
      const dflt = plan.filter(p => p.default)[0]
      if (isSubscriptionMenu && userSubId) {
        const s = plan.filter(p => userSubId === p.id)[0]
        if (s) {
          setUserSubPlan(s)
        } else {
          const ss = creditPlans.subscription.filter(p => userSubId === p.id)[0]
          setUserSubPlan(ss)
        }
        setSelected(s ?? dflt ?? plan[0])
      } else {
        setSelected(dflt ?? plan[0])
      }
    }
  }, [plan, userSubId])

  useEffect(() => {
    const currency = localStorage.getItem('i18nextLng') === 'ko' ? 'krw' : 'usd'
    setCurrency(currency)
  }, [])

  useEffect(() => {
    setCurrencyFormatter(getCurrencyFormatter(currency))
  }, [currency])

  useEffect(() => {
    if (abroadUser) {
      setCurrency('usd')
    } else {
      setCurrency('krw')
    }
  }, [abroadUser])

  useEffect(() => {}, [selected])

  const handleAction = async () => {
    if (!selected.price[currency]) {
      alert(t('payment_error_1'))
      window.location.reload()
    }
    const plans = await checkPlanPrice({
      plan_id: selected.id,
      currency,
      price: selected.price[currency],
    })

    const checkPlan = plans.filter(p => p.plan_type === selected.plan_type && p.id === selected.id)
    let finalPrice = selected.price[currency]

    if (
      !checkPlan ||
      checkPlan.length < 1 ||
      (checkPlan && checkPlan[0].price[currency] !== finalPrice)
    ) {
      alert(t('payment_error_1'))
      window.location.reload()
      return
    }

    // --- 수량은 가격 검사 이후 곱해줌

    if (abroadUser) {
      // TODO 해외결제도 프로모션 금액을 적용하려면 국내결제와 동일하게 처리.. requestPaypal 쪽도 $0 결제에 대한 처리가 필요함
      if (isSubscriptionMenu) {
        if (userSubId) {
          setOpenSubDialog(true)
        } else {
          requestPaypal({
            paymentType: name,
            plan: selected,
            price: finalPrice * (quantity[selected.plan_name] ?? 1),
            currency,
          })
        }
      } else {
        requestPaypal({
          paymentType: name,
          plan: selected,
          price: finalPrice * (quantity[selected.plan_name] ?? 1),
          currency,
        })
      }
    } else {
      if (isSubscriptionMenu) {
        const userEventId = await checkSubscriptionEvent() // 현재 진행중인 이벤트 아이디(SUB_EVENT_ID) 와 매칭되어야함

        const applyEventPrice =
          eventId &&
          eventId === SUB_EVENT_ID &&
          userEventId === SUB_EVENT_ID &&
          selected.ev &&
          selected.ev[SUB_EVENT_ID] &&
          !isNaN(selected.ev[SUB_EVENT_ID][currency])

        if (userEventId === SUB_EVENT_ID) {
          finalPrice = applyEventPrice
            ? selected.ev[SUB_EVENT_ID][currency]
            : selected.price[currency]
          if (applyEventPrice) {
            const p = checkPlan[0]
            const priceValid =
              p.ev &&
              p.ev[SUB_EVENT_ID] &&
              !isNaN(p.ev[SUB_EVENT_ID][currency]) &&
              p.ev[SUB_EVENT_ID][currency] === finalPrice
            if (!priceValid) {
              alert('결제 시도 중 오류가 발생하였습니다.')
              window.location.reload()
              return
            }
          }
        }

        if (userSubId) {
          setOpenSubDialog(true)
        } else {
          requestPay({
            paymentType: name,
            plan: selected,
            finalPrice: finalPrice * (quantity[selected.plan_name] ?? 1),
            currency,
            eventId: applyEventPrice ? SUB_EVENT_ID : null,
          })
        }
      } else {
        requestPay({
          paymentType: name,
          plan: selected,
          finalPrice: finalPrice * (quantity[selected.plan_name] ?? 1),
          currency,
        })
      }
    }
  }

  const HEAD = {
    subscription: [
      <TableCell key="col1">
        <Typography
          sx={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            color: 'inherit',
            width: { lg: '5.9rem', xs: '4.3rem' },
          }}
        >
          {t('pricing.pricingCard.table_col_1')}
        </Typography>
      </TableCell>,
      <TableCell key="col2" colSpan={2}>
        {t('pricing.pricingCard.table_col_2')}
      </TableCell>,
      <TableCell key="col3"> {t('pricing.pricingCard.table_col_3')}</TableCell>,
    ],
    payGo: [
      <TableCell key="col1">
        <Typography
          sx={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            color: 'inherit',
            width: { lg: '5.9rem', xs: '4.3rem' },
          }}
        >
          {t('pricing.pricingCard.table_col_1')}
        </Typography>
      </TableCell>,
      <TableCell key="col2" colSpan={2}>
        {t('pricing.pricingCard.table_col_2_paygo')}
      </TableCell>,
      <TableCell key="col3"> {t('pricing.pricingCard.table_col_3')}</TableCell>,
    ],
    bespoke: [
      <TableCell
        key="col1"
        sx={{ textAlign: 'center !important', width: { xs: '15rem !important' } }}
      >
        <Korean>이미지 타입</Korean>
      </TableCell>,
      <TableCell key="col-q">
        <Korean>수량</Korean>
      </TableCell>,
      <TableCell key="col2" colSpan={1}>
        {t('pricing.pricingCard.table_col_2_paygo')}
      </TableCell>,
    ],
  }

  const getPriceRow = p => {
    if (['bespoke'].includes(name)) {
      return (
        <>
          <TableRow
            key={p.id}
            onClick={() => {
              setSelected(p)
            }}
            sx={{
              cursor: 'pointer',
              '&.MuiTableRow-root': {
                background:
                  selected.id === p.id ? theme => theme.palette.draph.lighterblue : 'unset',
              },
              // '& .MuiTableCell-root': { border: '1px dashed gray !important' },
            }}
          >
            <TableCell>
              <CustomRadio1 checked={selected.id === p.id} sx={{ p: 0, mx: '0.5rem' }} />
            </TableCell>

            <TableCell sx={{ textAlign: 'left !important', px: '0 !important' }}>
              <Typography>{t(`pricing.${p.plan_name}`)}</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'left !important', px: '0 !important' }}>
              <Box sx={{ ml: { lg: '1.2rem', xs: '0.8rem' } }}>
                <QuantityControl
                  q={quantity[p.plan_name]}
                  setQ={val => {
                    setQuantity(q => ({ ...q, [p.plan_name]: val }))
                  }}
                />
              </Box>
            </TableCell>

            {/* <TableCell
              sx={{
                textAlign: 'right',
                width: isSubscriptionMenu ? '14rem' : isMobile ? '6.8rem !important' : '8.5rem',
                pr: { lg: '0.6rem !important', xs: '0.3rem !important' },
              }}
            >
              <Typography sx={{ textDecoration: 'line-through' }}>
                <PrefixMonthly
                  price={currencyFormatter.format(
                    2 * p.price[currency] * (quantity[p.plan_name] ?? 1)
                  )}
                  isSubscription={isSubscriptionMenu}
                />
              </Typography>
            </TableCell> */}

            <TableCell
              sx={{
                textAlign: 'center',
                // textAlign: 'left',
                width: isMobile ? '6.8rem !important' : '8rem !important',
              }}
            >
              <Typography sx={{ color: theme => theme.palette.draph.blue, fontWeight: 700 }}>
                <PrefixMonthly
                  price={currencyFormatter.format(p.price[currency] * (quantity[p.plan_name] ?? 1))}
                  isSubscription={isSubscriptionMenu}
                />
              </Typography>
            </TableCell>
          </TableRow>
        </>
      )
    }

    return (
      <>
        <TableRow
          key={p.id}
          onClick={() => {
            setSelected(p)
          }}
          sx={{
            cursor: 'pointer',
            '&.MuiTableRow-root': {
              background: selected.id === p.id ? theme => theme.palette.draph.lighterblue : 'unset',
            },
          }}
        >
          <TableCell>
            <CustomRadio1 checked={selected.id === p.id} sx={{ p: 0, mx: '0.5rem' }} />
          </TableCell>
          <TableCell sx={{}}>
            <Typography
              sx={
                {
                  // width: { lg: isKo(i18n) ? '5.9rem' : '6.5rem', xs: '4.3rem' }
                }
              }
            >
              {p.creditAmount}{' '}
              {isSubscriptionMenu && <span>&nbsp;{t('pricing.pricingCard.monthly')}</span>}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'right',
              width: isSubscriptionMenu ? '14rem' : isMobile ? '6.8rem !important' : '8.5rem',
              pr: { lg: '0.6rem !important', xs: '0.3rem !important' },
            }}
          >
            <Typography sx={{ textDecoration: 'line-through' }}>
              {subEventValid && p.ev && p.ev[SUB_EVENT_ID] && !isNaN(p.ev[eventId][currency])
                ? currencyFormatter.format(p.price[currency]) // 첫달 0원 프로모션 - 비포 가격을 원래 판매가격으로 표시
                : getOriginalPrice(creditPlans, p.id, currency) !== p?.price[currency] &&
                  currencyFormatter.format(getOriginalPrice(creditPlans, p.id, currency))}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'left',
              width: isSubscriptionMenu
                ? isKo(i18n)
                  ? '19rem'
                  : '7rem'
                : isMobile
                ? '6.8rem !important'
                : '8.5rem',
              pl: { lg: '0.6rem !important', xs: '0.3rem !important' },
            }}
          >
            <Typography sx={{ color: theme => theme.palette.draph.blue, fontWeight: 700 }}>
              {subEventValid &&
              p.ev &&
              p.ev[SUB_EVENT_ID] &&
              !isNaN(p.ev[SUB_EVENT_ID][currency]) ? (
                currencyFormatter.format(p.ev[SUB_EVENT_ID][currency])
              ) : (
                <PrefixMonthly
                  price={currencyFormatter.format(p.price[currency])}
                  isSubscription={isSubscriptionMenu}
                />
              )}
            </Typography>
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            <CenterAlignBox>
              {subEventValid &&
              p.ev &&
              p.ev[SUB_EVENT_ID] &&
              !isNaN(p.ev[SUB_EVENT_ID][currency]) ? (
                <FirstSubFreeBadge />
              ) : (
                getDiscountRate(creditPlans, p.id, currency) > 0 && (
                  <Typography>{getDiscountRate(creditPlans, p.id, currency)} %</Typography>
                )
              )}
            </CenterAlignBox>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      {highlight && (
        <Box
          sx={{
            // width: { lg: '10rem', xs: '5.6rem' },
            height: { lg: '4.4rem', xs: '2.4rem' },
            borderRadius: { lg: '10px', xs: '0.8rem' },
            background: theme => theme.palette.draph.blue,
            color: 'white',
            fontSize: { lg: '2.2rem', xs: '1.2rem' },
            fontWeight: 800,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%)',
            top: { lg: '-2rem', xs: '-1.2rem' },
            zIndex: 99,
            px: { lg: '2.2rem', xs: '1.3rem' },
          }}
        >
          {subEventValid ? 'PROMOTION' : 'BEST'}
        </Box>
      )}
      <StyledCard
        sx={{
          ...(highlight && {
            border: 1,
            borderWidth: { lg: '0.1rem', xs: '0.1rem' },
            borderColor: theme => theme.palette.draph.blue,
            boxShadow: '4px 4px 20px rgba(77, 128, 255, 0.2)',
          }),
        }}
        elevation={9}
      >
        <CenterAlignStack sx={{ width: '100%', justifyContent: 'center' }}>
          {/* 요금제 제목 */}
          <Typography
            component="div"
            fontSize={{ lg: '2.4rem', xs: '1.6rem' }}
            fontWeight={700}
            sx={{
              mb: { lg: '1.2rem', xs: '0.7rem' },
              display: 'flex',
              alignItems: 'center',
              ...(name === 'bespoke' && { cursor: 'pointer' }),
            }}
            onClick={
              name === 'bespoke'
                ? () => {
                    navigate('/overview/bespoke')
                  }
                : () => {}
            }
          >
            {pricingPlanDict[name]}
            {['bespoke'].includes(name) && <NewBadge />}
          </Typography>

          {/* 우선 생성 뱃지 */}
          {isSubscriptionMenu && isPublicPricingPage && (
            <Box
              sx={{
                position: 'absolute',
                right: { lg: '2rem', xs: '1.6rem' },
                top: { lg: '2rem', xs: '1.6rem' },
              }}
            >
              <PriorityBadge />
            </Box>
          )}

          {/* 설명 부분 */}
          <Box sx={{ mb: { lg: '1.7rem', xs: '1rem' } }}>
            <PlanExplanation name={name} subEventValid={subEventValid} />
          </Box>

          <Box>
            <StyledTable>
              <TableHead>
                <TableRow>
                  {/* 라디오버튼용 헤드 */}
                  <TableCell></TableCell>

                  {HEAD[name].map(h => h)}
                </TableRow>
              </TableHead>

              <TableBody>
                {plan?.slice(0, isExpanded ? plan.length : DISPLAY_MAX).map((p, i) => (
                  <Fragment key={i}>{getPriceRow(p)}</Fragment>
                ))}

                {plan?.length > DISPLAY_MAX + 1 && !isExpanded && (
                  <TableRow
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsExpanded(true)
                    }}
                  >
                    <TableCell colSpan={5}>
                      <Typography>
                        <Korean>더보기</Korean>
                        <English>See More</English>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </Box>

          <Button
            sx={{
              mt: { lg: '1.2rem', xs: '0.9rem' },
              // mb: { lg: '1.2rem', xs: '1.1rem' },
              width: { lg: '36rem', xs: '28.8rem' },
              height: { lg: '4.8rem', xs: '3.6rem' },
              backgroundColor: isSubscriptionMenu ? theme.palette.draph.blue : '',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: isSubscriptionMenu ? theme.palette.common.black : '',
              },

              '& p': {
                color: 'white',
                fontSize: { lg: '1.6rem', xs: '1.4rem' },
                fontWeight: { lg: 600, xs: 600 },
              },
            }}
            variant="contained"
            onClick={() => {
              if (userInfo.id) {
                handleAction()
              } else {
                setOpenNonMemberDialog(true)
              }
            }}
            disabled={
              // !termAgreed ||
              (isSubscriptionMenu && isPaymentFailed) ||
              selected.id === userSubId ||
              selected.id === userSubInfo?.next_subscription?.plan_id
            }
          >
            {eventId &&
            selected.ev &&
            selected.ev[SUB_EVENT_ID] &&
            !isNaN(selected.ev[SUB_EVENT_ID][currency]) ? (
              <Typography>{t('pricing.pricingCard.start_free_subscription')}</Typography>
            ) : isSubscriptionMenu && userSubId && selected?.id && userSubPlan?.id ? (
              isPaymentFailed ? (
                <Typography>현재 사용이 불가능합니다.</Typography>
              ) : userSubId.includes(selected?.id) ? (
                <>
                  <Typography>{t('pricing.pricingCard.current_subscription')}</Typography>
                </>
              ) : selected.id === userSubInfo?.next_subscription?.plan_id ? (
                <Typography>{t('pricing.pricingCard.change_subscription')}</Typography>
              ) : selected.creditAmount > userSubPlan.creditAmount ? (
                <Typography>{t('pricing.pricingCard.higher_subscription')}</Typography>
              ) : (
                <Typography>{t('pricing.pricingCard.lower_subscription')}</Typography>
              )
            ) : (
              selected?.price &&
              selected.price[currency] && (
                <>
                  <Korean>
                    {isSubscriptionMenu && <Typography>매달&nbsp;</Typography>}
                    <Typography>
                      {convertPriceSymbolToWord(
                        currencyFormatter.format(
                          selected.price[currency] * (quantity[selected.plan_name] ?? 1)
                        )
                      )}
                    </Typography>
                    <Typography fontSize="1.3rem" sx={{ mx: '0.5rem' }}>
                      (VAT 포함)
                    </Typography>
                    <Typography>결제하기</Typography>
                  </Korean>
                  <English>
                    <Typography>Pay &nbsp;</Typography>
                    <Typography>
                      {currencyFormatter.format(
                        selected.price[currency] * (quantity[selected.plan_name] ?? 1)
                      )}
                    </Typography>
                    <Typography fontSize="1.3rem" sx={{ mx: '0.5rem' }}>
                      (Price incl. VAT)
                    </Typography>
                  </English>
                </>
              )
            )}
          </Button>

          {selected.creditAmount > 0 && (
            <Box
              sx={{
                width: '100%',
                mt: { lg: '2.4rem', xs: '1.6rem' },
                // height: { lg: '4.5rem', xs: '3rem' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                '& p': { fontSize: { lg: '1.3rem', xs: '1.1rem' }, fontWeight: 500 },
                '& span': {
                  fontWeight: 700,
                  color: theme => theme.palette.draph.blue,
                },
              }}
            >
              {isPaymentFailed && isSubscriptionMenu ? (
                <CenterAlignStack
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenChangePaymentMethodDialog(true)
                  }}
                >
                  <Typography
                    sx={{ color: theme.palette.draph.blue, fontWeight: '700 !important' }}
                  >
                    결제 수단을 새로 등록해주세요.
                  </Typography>
                </CenterAlignStack>
              ) : !userSubId?.includes(selected.id) ? (
                <>
                  {isSubscriptionMenu ? (
                    <>
                      <Korean>
                        <CenterAlignStack>
                          {eventId &&
                            selected.ev &&
                            selected.ev[SUB_EVENT_ID] &&
                            !isNaN(selected.ev[SUB_EVENT_ID][currency]) && (
                              <>
                                <Typography>
                                  <span>
                                    {t('pricing.pricingCard.first_month_free_comment_1_a')}
                                  </span>
                                  {t('pricing.pricingCard.first_month_free_comment_1_b')}
                                </Typography>
                              </>
                            )}
                          <Typography sx={{ textAlign: 'center' }}>
                            매월 결제일마다{' '}
                            <span>
                              {selected?.price &&
                                currencyFormatter.format(selected?.price[currency])}
                            </span>{' '}
                            이 결제되고{' '}
                            <Mobile>
                              <br />
                            </Mobile>
                            <span>{selected.creditAmount}크레딧</span>이 자동 충전됩니다.
                          </Typography>
                        </CenterAlignStack>
                      </Korean>
                      <English>
                        <CenterAlignStack>
                          {eventId &&
                            selected.ev &&
                            selected.ev[SUB_EVENT_ID] &&
                            !isNaN(selected.ev[SUB_EVENT_ID][currency]) && (
                              <>
                                <Typography>
                                  <span>After First month for free</span>
                                  {/* {t('pricing.pricingCard.first_month_free_comment_1_b')} */}
                                </Typography>
                              </>
                            )}
                          <Typography sx={{ textAlign: 'center' }}>
                            <span>
                              {selected?.price &&
                                currencyFormatter.format(selected?.price[currency])}
                            </span>{' '}
                            will be charged on monthly due date
                            <br />
                            <span>{selected.creditAmount} Credit(s)</span> will be replenished.
                          </Typography>
                        </CenterAlignStack>
                      </English>
                    </>
                  ) : (
                    <Typography>
                      <span>
                        {selected.creditAmount} {t('credit.charged_credit')}
                      </span>
                      {t('pricing.pricingCard.credit_info')}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography>
                  {t('pricing.pricingCard.current_status_1_a')}
                  <span>
                    {getPlanDisplayName(
                      userSubId?.includes('admin') ? userSubId.slice(0, -6) : userSubId
                    )}
                  </span>
                  {t('pricing.pricingCard.current_status_1_b')}
                </Typography>
              )}
            </Box>
          )}
        </CenterAlignStack>

        {isSubscriptionMenu && (
          <ChangeSubscriptionDialog
            open={openSubDialog}
            setOpen={setOpenSubDialog}
            selectedPlan={selected}
            userSubPlan={userSubPlan}
            currency={currency}
            currencyFormatter={currencyFormatter}
          />
        )}
      </StyledCard>
    </div>
  )
}

function ChangeSubscriptionDialog({
  open,
  setOpen,
  selectedPlan,
  userSubPlan,
  currency,
  currencyFormatter,
}) {
  const userInfo = useRecoilValue(userAtom)
  const [userSubInfo, setUserSubInfo] = useState(null)
  const [userCredit, setUserCredit] = useState(null)
  const [nextPayday, setNextPayday] = useState(null)
  const [change, setChange] = useState() // up or down
  const [isLoading, setIsLoading] = useState(false)
  const [subEventApplied, setSubEventApplied] = useState(false)

  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()

  const { getPlanDisplayName } = usePricing()

  useEffect(() => {
    // console.log('userSubPlan', userSubPlan)
    // console.log('selectedPlan', selectedPlan)
  })

  // selectedPlan.creditAmount < userSubPlan.creditAmount
  useEffect(() => {
    if (selectedPlan && userSubPlan) {
      if (selectedPlan.creditAmount < userSubPlan.creditAmount) {
        setChange('down')
      } else {
        setChange('up')
      }
    } else {
      setChange(null)
    }
  }, [selectedPlan, userSubPlan])

  useEffect(() => {
    if (open) {
      apis.user.getCredit().then(response => {
        setUserCredit(response.data.credit)
      })
      apis.user.getUserSubscription().then(response => {
        const lastPaid = response.data.last_paid

        if (lastPaid) {
          const utcDate = moment.utc(lastPaid).toDate()
          const localNextMonth = moment(utcDate).add(1, 'M').local().format('YYYY-MM-DD')
          setNextPayday(localNextMonth)

          setSubEventApplied(checkAppliedSubEvent(response.data))
        } else {
          setNextPayday('')
        }
        setUserSubInfo(response.data)
      })
    }
  }, [open])
  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeSubscription = () => {
    setIsLoading(true)

    const d = {}
    addSessionStorageData(d)

    apis.user
      .changeSubscription({
        new_plan_id: selectedPlan.id,
        current_subscription_id: userSubInfo.id,
        additional_payment_data: d,
      })
      .then(response => {
        handleClose()

        if (!response.data.success) {
          alert('결제 요청 중 오류가 발생하였습니다.')
          return
        }

        const paidPrice = response.data.payment_response?.PCD_PAY_TOTAL

        // ----- GA4 event -----
        const transactionId = generatePaymentUid()
        window.gtag('event', 'purchase', {
          item_category: 'subscription',
          item_id: response.data.payment_info.plan_id,
          item_name: getPlanDisplayName(response.data.payment_info.plan_id),
          price: paidPrice,
          currency: response.data.payment_info.currency ?? 'KRW',
          transaction_id: transactionId,
        })
        // ---------------------

        // ----- Google Ads event -----
        window.gtag_report_conversion_purchase(
          paidPrice,
          transactionId,
          response.data.payment_info.currency
        )

        // ----------------------------

        // ----- Meta(facebook) pixel event -----
        window.fbq('track', 'Purchase', {
          value: paidPrice,
          currency: response.data.payment_info.currency ?? 'KRW',
        })
        // --------------------------------------
        // ----- linkedin event -----
        window.lintrk('track', { conversion_id: 17109489 })
        // --------------------------

        const pRes =
          response.data.payment_info.currency === 'USD'
            ? response.data.payment_response.response
            : response.data.payment_response

        navigate('/paid', {
          state: {
            paymentResponse: pRes,
            paymentInfo: response.data.payment_info,
            changeSubscription: response.data.change_subscription,
          },
        })
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          borderRadius: '20px',
          boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
          margin: '20px',
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            background: 'black',
            opacity: 0.3,
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 99,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <DialogContent
        sx={{
          width: { lg: '500px', xs: '320px' },
          p: { lg: '37px 0 30px 0', xs: '22px 12px 30px 12px' },
        }}
      >
        <CenterAlignStack>
          <Typography sx={{ fontWeight: 700, fontSize: { lg: '20px' } }}>
            {t('pricing.pricingCard.change_subscription_title_1')}
            <span style={{ color: theme.palette.draph.blue }}>
              {' '}
              {t('pricing.pricingCard.change_subscription_title_2')}
            </span>
            {t('pricing.pricingCard.change_subscription_title_3')}
          </Typography>

          {userSubPlan?.id && selectedPlan?.id && (
            <Table sx={{ mt: { lg: '13px', xs: '8px' }, mb: '2rem' }}>
              <TableBody
                sx={{
                  '& .MuiTableCell-head': {
                    color: theme.palette.common.black,
                    textAlign: 'right',
                    fontSize: { lg: '15px', xs: '11px' },
                    fontWeight: 700,
                  },
                  '& .MuiTableCell-body': { textAlign: 'center' },
                  '& .MuiTableRow-root:nth-of-type(even) .MuiTableCell-body': {
                    backgroundColor: theme.palette.common.lightgray,
                  },
                  '& .MuiTableRow-root:nth-of-type(odd) .MuiTableCell-body': {
                    padding: { lg: '16px', xs: '20px 9px' },
                  },

                  '& .MuiTableCell-root': {
                    borderBottom: 0,
                    padding: { lg: '16px', xs: '5px 7px' },
                    fontSize: { lg: '15px', xs: '11px' },
                    lineHeight: 'normal',
                  },

                  '& .MuiTableRow-root:last-child .MuiTableCell-root ': {
                    padding: { lg: '10px 16px', xs: '7px 9px' },
                  },
                }}
              >
                <TableRow>
                  <TableCell variant="head">
                    {t('pricing.pricingCard.change_subscription_row_1')}
                  </TableCell>
                  <TableCell colSpan={2} sx={{ textAlign: 'center', fontWeight: 700 }}>
                    {userCredit} {t('credit.credit')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    {' '}
                    {t('pricing.pricingCard.change_subscription_row_2_a')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {trasnferDate(moment(nextPayday), 'detail', i18n)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700, color: theme.palette.draph.blue }}>
                    {change === 'down'
                      ? trasnferDate(moment(nextPayday), 'detail', i18n)
                      : t('pricing.pricingCard.change_subscription_row_2_b')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    {' '}
                    {t('pricing.pricingCard.change_subscription_row_3_a')}
                  </TableCell>
                  <TableCell>
                    <CenterAlignStack>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: { lg: '13px', xs: '10px' },
                          lineHeight: { lg: '16px', xs: '12px' },
                        }}
                      >
                        {t('pricing.pricingCard.change_subscription_row_3_b')}
                      </Typography>
                      <Typography sx={{ fontSize: { lg: '18px', xs: '13px' }, fontWeight: 600 }}>
                        {getPlanDisplayName(userSubPlan.id)}
                      </Typography>
                      <Typography
                        sx={{ fontSize: { lg: '12px', xs: '10px' }, textAlign: 'center' }}
                      >
                        {numberCommaFormatter(userSubPlan.creditAmount)}{' '}
                        {t('pricing.pricingCard.change_subscription_row_3_c')}
                        <br />
                        {currencyFormatter.format(userSubPlan.price[currency]).slice(1)}{' '}
                        {t('pricing.pricingCard.change_subscription_row_3_d')}
                      </Typography>
                    </CenterAlignStack>
                  </TableCell>
                  <TableCell>
                    <CenterAlignStack>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: { lg: '13px', xs: '10px' },
                          lineHeight: { lg: '16px', xs: '12px' },
                          color: theme.palette.draph.blue,
                        }}
                      >
                        {t('pricing.pricingCard.change_subscription_row_3_e')}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: '18px', xs: '13px' },
                          fontWeight: 600,
                          color: theme.palette.draph.blue,
                        }}
                      >
                        {getPlanDisplayName(selectedPlan.id)}
                      </Typography>
                      <Typography
                        sx={{ fontSize: { lg: '12px', xs: '10px' }, textAlign: 'center' }}
                      >
                        {numberCommaFormatter(selectedPlan.creditAmount)}{' '}
                        {t('pricing.pricingCard.change_subscription_row_3_c')}
                        <br />
                        {currencyFormatter.format(selectedPlan.price[currency]).slice(1)}{' '}
                        {t('pricing.pricingCard.change_subscription_row_3_d')}
                      </Typography>
                    </CenterAlignStack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" sx={{ lineHeight: { xs: '13px' } }}>
                    {t('pricing.pricingCard.change_subscription_row_4_a')}
                    <br />
                    {t('pricing.pricingCard.change_subscription_row_4_b')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {numberCommaFormatter(userCredit + userSubPlan.creditAmount)}{' '}
                    {t('credit.credit')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {numberCommaFormatter(userCredit + selectedPlan.creditAmount)}{' '}
                    {t('credit.credit')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}

          {subEventApplied && userSubPlan.creditAmount < selectedPlan.creditAmount ? (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: { lg: '1.4rem', xs: '1.2rem' },
                fontWeight: 500,
                color: '#FF2323',
                lineHeight: { lg: 1.2, xs: 1.1 },
                px: { lg: '6rem', xs: '1rem' },
              }}
            >
              {t('pricing.pricingCard.change_event_subscription_notice_a')}
            </Typography>
          ) : (
            <Typography fontSize="1.2rem" fontWeight={400}>
              {t('pricing.pricingCard.change_subscription_notice')}
            </Typography>
          )}
        </CenterAlignStack>
      </DialogContent>

      <DialogActions sx={{ p: 0 }}>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            width: '50%',
            height: { lg: '48px', xs: '40px' },
            fontSize: { lg: '16px', xs: '14px' },
            fontWeight: 600,
            backgroundColor: theme.palette.common.black,
            borderRadius: 0,
            color: theme.palette.common.white,
            '&:hover': {
              color: theme.palette.common.white,
            },
          }}
        >
          {t('button.keep_plan')}
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            handleChangeSubscription()
          }}
          sx={{
            ml: '0 !important',
            width: '50%',
            height: { lg: '48px', xs: '40px' },
            fontSize: { lg: '16px', xs: '14px' },
            fontWeight: 600,
            backgroundColor: theme.palette.common.white,
            borderRadius: 0,
            color: theme.palette.common.black,
            '&:hover': {
              backgroundColor: '#F8F8F8',
              color: theme => theme.palette.common.black,
            },
          }}
        >
          {t('button.change_plan_l')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const PrefixMonthly = ({ price, isSubscription }) => {
  const theme = useTheme()
  const isMobile = useMobileMediaQuery()
  return (
    <>
      <Korean>
        {isSubscription && (
          <span
            style={{
              fontSize: isMobile ? '1rem' : '1.2rem',
              fontWeight: 600,
              backgroundColor: theme.palette.draph.blue,
              color: theme.palette.common.white,
              padding: isMobile ? '0.2rem 0.6rem' : '0.2rem 0.6rem',
              borderRadius: '4px',
              marginRight: '0.4rem',
              lineHeight: 'normal',
            }}
          >
            매달
          </span>
        )}
      </Korean>

      {price}
    </>
  )
}

function PriorityBadge() {
  // 구독 요금제 카드에 붙는 우선생성 딱지
  const { t } = useTranslation()

  return (
    <>
      <CenterAlignBox
        sx={{
          alignItems: 'center',
          background: theme => theme.palette.draph.blue,
          py: { lg: '0.7rem', xs: '0.5rem' },
          px: { lg: '1.1rem', xs: '0.8rem' },
          borderRadius: '0.4rem',
        }}
      >
        <svg
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.83333 0H9.28788L5.95455 4.2H10.5L3.22727 12L4.74242 6.3H0.5L3.83333 0Z"
            fill="#1FFF50"
          />
        </svg>

        <Typography
          sx={{
            color: 'white',
            fontSize: { lg: '1.2rem', xs: '1.1rem' },
            fontWeight: 700,
            ml: '0.2rem',
          }}
        >
          {t('pricing.pricingCard.priority_generation')}
        </Typography>
      </CenterAlignBox>
    </>
  )
}

function NewBadge() {
  const { t } = useTranslation()

  return (
    <>
      <CenterAlignBox
        sx={{
          alignItems: 'center',
          background: theme => theme.palette.draph.blue,
          height: { lg: '2rem', xs: '1.8rem' },
          px: { lg: '0.65rem', xs: '0.5rem' },
          borderRadius: '0.4rem',
          ml: '0.6rem',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontSize: { lg: '1rem', xs: '1rem' },
            fontWeight: 600,
          }}
        >
          NEW
        </Typography>
      </CenterAlignBox>
    </>
  )
}

export function PlanExplanation({ name, subEventValid = false, showPriorityInfo = false }) {
  const { t, i18n } = useTranslation()
  const isSubscriptionMenu = name === 'subscription'
  const isPublicPricingPage = location.pathname === '/pricing'

  const EXP = {
    // 구독 첫달 무료 이벤트 대상에게만 표시되는 안내 문구
    subscriptionEvent: [<>{t('pricing.pricingCard.subscription_comment_event_1')}</>],

    // 우선 생성 대상인 일반 구독 요금제에만 표시되는 안내 문구
    subscriptionPriority: [
      <>
        <Korean>
          <span>가장 빠른 생성 속도</span>로 많은 상품 이미지 제작 가능!
        </Korean>
        <English>
          <span>The fastest way</span> to generate numerous product images
        </English>
      </>,
      <>
        <Korean>
          10분 이상 생성 지연 시, <span> 사용 크레딧 원상복구</span>
        </Korean>
        <English>
          <span>Restore credits</span> if generation delayed for more than 10 minutes
        </English>
      </>,
    ],

    // 구독 공통 안내 문구
    subscription: [
      <>
        <span style={{ fontWeight: isKo(i18n) ? 400 : 700 }}>
          {t('pricing.pricingCard.subscription_comment_2_a')}
        </span>
        <span style={{ fontWeight: isKo(i18n) ? 700 : 400 }}>
          {t('pricing.pricingCard.subscription_comment_2_b')}
        </span>
      </>,
    ],
    payGo: [
      <>{t('pricing.pricingCard.paygo_comment_1')}</>,
      <>
        {t('pricing.pricingCard.paygo_comment_2_a')}{' '}
        <span> {t('pricing.pricingCard.paygo_comment_2_b')}</span>
      </>,
    ],
    bespoke: [
      <>고품질의 이미지 제작 대행이 필요한 경우!</>,
      <>일반 스튜디오 촬영 대비, 50% 이상 비용 절감 효과!</>,
    ],
  }

  // const items = [
  //   ...(isSubscriptionMenu && isPublicPricingPage ? EXP.subscriptionPriority : []),
  //   ...(isSubscriptionMenu ? EXP.subscription : []),
  //   ...(subEventValid ? EXP.subscriptionEvent : []),

  //   ...(name === 'payGo' ? EXP.payGo : []),
  //   ...(name === 'bespoke' ? EXP.bespoke : []),
  // ]

  const items = isSubscriptionMenu
    ? [
        ...(isPublicPricingPage || showPriorityInfo ? EXP.subscriptionPriority : []),
        ...EXP.subscription,
        ...(subEventValid ? EXP.subscriptionEvent : []),
      ]
    : ['payGo', 'bespoke'].includes(name)
    ? EXP[name]
    : []

  return (
    <>
      {items.map((content, idx) => (
        <SubtitleTypography
          className="row"
          key={idx}
          sx={{ fontSize: isKo(i18n) ? '1.4rem' : '1.2rem', '& span': { fontWeight: 700 } }}
        >
          <CheckIcon />
          {content}
        </SubtitleTypography>
      ))}
    </>
  )
}

function QuantityControl({ q, setQ }) {
  const MAX = 100
  const MIN = 1
  useEffect(() => {
    if (!Number.isInteger(q)) {
      setQ(MIN)
    }
  }, [q])

  const add = () => {
    if (Number.isInteger(q) && q < MAX) {
      setQ(q + 1)
    }
  }

  const subtract = () => {
    if (Number.isInteger(q) && q > MIN) {
      setQ(q - 1)
    }
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{
          borderRadius: '4px',
          background: '#E1E1E1',
          width: 'fit-content',

          height: { lg: '2rem', xs: '1.4rem' },
          '& .icon-button': {
            width: { lg: '2rem', xs: '1.4rem' },

            '& .icon': {
              width: { lg: '8px', xs: '6px' },
              height: { lg: '8px', xs: '6px' },
            },
          },
          '& .text': {
            background: 'white',
            textAlign: 'center',
            width: { lg: '2rem', xs: '1.4rem' },
          },
        }}
      >
        <IconButton className="icon-button" onClick={subtract}>
          <MinusIcon className="icon minus" />
        </IconButton>
        <Typography className="text count">{q}</Typography>
        <IconButton className="icon-button" onClick={add}>
          <PlusIcon className="icon plus" />
        </IconButton>
      </Stack>
    </>
  )
}
