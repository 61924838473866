import { configData } from 'config'
import {
  Box,
  Typography,
  Button,
  Stack,
  Select,
  MenuItem,
  Card,
  FormControl,
  SwipeableDrawer,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import {
  ScrollToTop,
  ArtworkList,
  DevEndpointDialog,
  SearchInput,
  ArtworkFilters,
  UploadWrapper,
  CenterAlignStack,
  CenterAlignBox,
  DragDropFullSize,
  EmptyArtworkList,
  PleaseLoginDialog,
  UploadImageDialog,
  DialogButton,
  MobileSwipeableDrawer,
  StyledToggleButtonGroup,
  StyledToggleButton,
  ModelBgConfigComponent,
  UploadHeader,
  GuideComponent,
  ModelFaceConfigComponent,
  ModelRenerateDialog,
  MobileModelUploadOld,
} from 'components'
import { BeforeAfterArrow, SettingIcon } from 'theme/icon'
import { styled, useTheme } from '@mui/material/styles'

import { useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  portfolioDetailAtom,
  portfolioArtworkAtom,
  userAtom,
  portfolioConfigAtom,
  artworkViewConfigAtom,
  userLikedBgAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  tutorialAtom,
  endpointAtom,
  artworkListsIsFoldedAtom,
  defaultPortfolioAtom,
  portfolioAtom,
  autoUploadAtom,
  dragDropOpenAtom,
  uploadDialogOpenAtom,
  portfolioTypeAtom,
  uploadFilesAtom,
  blockDragDropAtom,
  indexAtom,
  selectedMaskImgAtom,
  removeMaskImgAtom,
  segmentLoadingAtom,
  uploadFilesAndUrlAtom,
  maskImgArrayAtom,
} from 'atoms'
import { apis } from 'apis'
import { BarLoader as Loader, PuffLoader } from 'react-spinners'
import {
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
} from 'selector'
import useConfirm from 'hooks/useConfirm'
import _, { debounce } from 'lodash'
import axios from 'axios'
import { getAccessToken } from 'utils/cookie'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { APPBAR_DESKTOP, APPBAR_MOBILE } from 'layouts/main/MainAppBar'
import MainFooter from 'layouts/main/MainFooter'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import * as config from 'config'
import { useMotionValueEvent, useScroll, useTransform } from 'framer-motion'

import { PAGE_HEADER_HEIGHT, ScrollHandler } from './PortfolioDetail'
import { useTranslation } from 'react-i18next'
import { EmptyPageHeader, EmptyUploadHeader, PageHeaderLayout } from './PortfolioUpload'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'
import { triggerGA4UploadEvent } from '../components/portfolio/Header'
import { modelFaceRegenDialogAtom } from '../atoms'

import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { getUserType } from 'utils/user'
import { usePortfolio } from 'hooks/usePortfolio'
import { useResetNpy } from 'hooks/useResetNpy'
import { imgMergeAndGrayScale } from 'components/portfolio/UploadMannequinDialog'
import { blobToRLE } from 'components/fragment/fragmentHelpers'

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    // alignItems: 'center',
  },
}))

export default function ModelFace() {
  const tutorial = useRecoilValue(tutorialAtom)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)

  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'model_face', {})
    // ---------------------

    return () => {
      setBlockDragDrop(false)
    }
  }, [])

  const token = getAccessToken()
  if (!token)
    return (
      <RootStyle>
        <Mobile>
          <EmptyPageHeader />
        </Mobile>

        <Desktop>
          <EmptyUploadHeader videoSrc={config.GUIDE_VIDEOS.face} />
        </Desktop>
        <EmptyArtworkList />
      </RootStyle>
    )

  return (
    <>
      <RootStyle
        style={{
          // overflow: tutorial.step === 15 ? 'hidden' : 'scroll',
          zIndex: tutorial.step === 17 ? '-1' : null,
        }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()

          if (!blockDragDrop) {
            setDragDropOpen(true)
          }
        }}
      >
        <>
          <UploadWrapper>
            {isMobile ? (
              <PageHeader />
            ) : (
              <UploadHeader
                configComponent={<ModelFaceConfigComponent conceptOff={true} ageOff={true} />}
                uploadButtonComponent={<ModelFaceConfigComponent uploadButtonOnly={true} />}
                gudieComponent={<GuideComponent />}
                imageDelay={0.2}
                configDelay={0.3}
                mannquinUpload={true}
                uploadImglimit={1}
                multiple={false}
              />
            )}
          </UploadWrapper>
          <Mobile>
            <Guide />
          </Mobile>

          <ArtworkList upload={true} hideGroupHeader={true} />
        </>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />
      {dragDropOpen && !blockDragDrop && <DragDropFullSize />}
      <ModelFaceRegenDialog />
      <ModelRenerateDialog />
    </>
  )
}

function PageHeader() {
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const [user, setUser] = useRecoilState(userAtom)
  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)
  const { t } = useTranslation()
  const theme = useTheme()

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)

  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetPortfolioConfig = useResetRecoilState(portfolioConfigAtom)

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [uploading, setUploading] = useState(false)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  const maskImgArray = useRecoilValue(maskImgArrayAtom)
  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [index, SetIndex] = useRecoilState(indexAtom)

  const { resetAllState } = useResetNpy()

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)
  const [gender, setGender] = useState('female')
  const [model, setModel] = useState('auto')

  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  useEffect(() => {
    if (uploadOpen) {
      // ----- GA4 event -----
      window.gtag('event', 'model_face_begin', {})
      // ---------------------
    } else {
      setModel('auto')
      setGender('female')
    }
  }, [uploadOpen])

  const handleUpload = async filesObj => {
    // const uploadFiles = await prepareUpload(files, config.PORTFOLIO_TYPE_FACE)
    // if (!uploadFiles) return

    // setUploadButtonDiasbled(true)
    // setUploading(true)
    // setUploadOpen(false)

    // const modelFaceConfig = {
    //   //
    //   flag_facemorphing: true,
    //   facemorphing_race: model,
    //   facemorphing_gender: gender === 'male' ? 'man' : 'woman',

    //   // 나머지 설정 오버라이드용
    //   flag_generate: false,
    //   flag_complex_cmp: false,
    //   flag_simple_cmp: false,
    //   flag_white_cmp: false,
    // }

    // // ----- GA4 event -----
    // window.gtag('event', 'model_face_upload', {
    //   count: uploadFiles.length,
    //   method: `${gender}_${model}`,
    // })
    // // ---------------------

    // const formData = await makeUploadFormData(uploadFiles, modelFaceConfig)

    // console.log('--------- start uploading')

    // await apis.appfront
    //   .upload(formData)
    //   .then(() => {
    //     checkUserCredit()
    //     setUploading(false)
    //     setUploadButtonDiasbled(false)
    //     refreshArtworks()

    //     if ('Notification' in window && Notification.permission === 'default') {
    //       showBrowserNotificationDialog()
    //     }
    //   })
    //   .catch(() => {
    //     setUploading(false)
    //     setUploadButtonDiasbled(false)
    //   })

    const files = filesObj.map(f => f.file)

    setUploading(true)
    setUploadButtonDiasbled(true)
    setUploadOpen(false)

    const mask = await imgMergeAndGrayScale(selectedMaskImg)
    const rleData = await blobToRLE(mask)

    // 여기 다시 짜야함
    const addFormData = {
      binary_mask: mask,
      is_male: gender === 'male',
      model_name: model,

      user_presets: JSON.stringify([rleData]),
      user_binmaps: JSON.stringify(maskImgArray),
    }

    const uploadFiles = await prepareUpload(files, config.PORTFOLIO_TYPE_FACE)
    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)

    // ----- GA4 event -----
    window.gtag('event', 'model_face_upload', {
      count: uploadFiles.length,
      method: `${gender}_${model}`,
    })
    // ---------------------
    const formData = await makeUploadFormData(uploadFiles, {}, addFormData, 'modelFace')

    console.log('--------- start uploading')
    setUploading(true)
    apis.appfront
      .genFace(formData)
      .then(() => {
        checkUserCredit()

        setUploading(false)
        setUploadButtonDiasbled(false)

        files.forEach(image => URL.revokeObjectURL(image.url))
        resetFiles()
        refreshArtworks()

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
      .finally(() => {
        resetAllState()
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  return (
    <>
      <PageHeaderLayout
        headerProps={{
          sx: {
            opacity: isFolded.value ? 0 : 1,
            transition: 'all 0.2s ease',
          },
          zIndex: isFolded.value
            ? -1
            : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
            ? 'auto'
            : null,
        }}
        uploadButtonProps={{
          disabled: uploading || !isOwner || uploadButtonDisabled,
          onClick: () => {
            tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_1' }))
            setUploadOpen(true)
          },
        }}
        uploading={uploading}
        uploadDialog={
          uploadOpen && (
            <MobileModelUploadOld
              title={
                <Typography sx={{ fontWeight: 800, lineHeight: 'normal', mt: '1.2rem' }}>
                  {t('regenerate_dialog.face_title')}
                  <sup
                    style={{
                      color: theme.palette.error.main,
                      fontWeight: 500,
                      fontSize: '1.5rem',
                      lineHeight: '1.5rem',
                      verticalAlign: 'text-top',
                    }}
                  >
                    &#42;
                  </sup>
                </Typography>
              }
              notice={
                <CenterAlignStack
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: { lg: '1.4rem', xs: '1.2rem' },
                      fontWeight: 400,
                      color: '#595959',
                      lineHeight: 'normal',
                    },
                  }}
                >
                  <Desktop>
                    <Typography>{t('upload_dialog.notice_1')}</Typography>
                    <Typography>
                      {t('upload_dialog.model_face_comment_1')}
                      {t('upload_dialog.model_face_comment_2')}
                    </Typography>
                  </Desktop>
                  <Mobile>
                    <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
                      {t('regenerate_dialog.face_notice_1')}
                    </Typography>
                    {/* <Typography>{t('upload_dialog.upload_notice_mobile_1')}</Typography>
                      <Typography>{t('upload_dialog.model_face_comment_1')}</Typography>
                      <Typography>{t('upload_dialog.model_face_comment_2')}</Typography> */}
                  </Mobile>
                </CenterAlignStack>
              }
              mannquinUpload={true}
              segmentService="face"
              gender={gender}
              setGender={setGender}
              model={model}
              setModel={setModel}
              handleUpload={handleUpload}
              uploadButtonTitle={t('button.model_face_upload')}
            />
            // <UploadImageDialog
            //   uploadImage={handleUpload}
            //   uploadButtonTitle={t('button.model_face_upload')}
            //   title={
            //     <Typography sx={{ fontWeight: 800, lineHeight: 'normal', mt: '1.2rem' }}>
            //       {t('regenerate_dialog.face_title')}
            //       <sup
            //         style={{
            //           color: theme.palette.error.main,
            //           fontWeight: 500,
            //           fontSize: '1.5rem',
            //           lineHeight: '1.5rem',
            //           verticalAlign: 'text-top',
            //         }}
            //       >
            //         &#42;
            //       </sup>
            //     </Typography>
            //   }
            //   notice={
            //     <CenterAlignStack
            //       sx={{
            //         '& .MuiTypography-root': {
            //           fontSize: { lg: '1.4rem', xs: '1.2rem' },
            //           fontWeight: 400,
            //           color: '#595959',
            //           lineHeight: 'normal',
            //         },
            //       }}
            //     >
            //       <Desktop>
            //         <Typography>{t('upload_dialog.notice_1')}</Typography>
            //         <Typography>
            //           {t('upload_dialog.model_face_comment_1')}
            //           {t('upload_dialog.model_face_comment_2')}
            //         </Typography>
            //       </Desktop>
            //       <Mobile>
            //         <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
            //           {t('regenerate_dialog.face_notice_1')}
            //         </Typography>
            //         {/* <Typography>{t('upload_dialog.upload_notice_mobile_1')}</Typography>
            //         <Typography>{t('upload_dialog.model_face_comment_1')}</Typography>
            //         <Typography>{t('upload_dialog.model_face_comment_2')}</Typography> */}
            //       </Mobile>
            //     </CenterAlignStack>
            //   }
            //   example={
            //     <ModelFaceUploadOptions
            //       gender={gender}
            //       setGender={setGender}
            //       model={model}
            //       setModel={setModel}
            //     />
            //   }
            // />
          )
        }
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
          />
        }
      />
    </>
  )
}

const ExampleImage = () => (
  <>
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        '& img.before': {
          width: { lg: '160px', xs: '80px' },
        },
        '& img.after': {
          width: { lg: '160px', xs: '80px' },
        },
      }}
      spacing={{ lg: '1.5rem', xs: '0.5rem' }}
    >
      <img src="/static/images/model_example/face_before.png" className="before" />
      <Desktop>
        <BeforeAfterArrow />
      </Desktop>
      <Mobile>
        <BeforeAfterArrow size="small" />
      </Mobile>
      <img src="/static/images/model_example/face_after.png" className="after" />
    </Stack>
  </>
)

const ExampleText = ({ sx }) => {
  const { t } = useTranslation()
  return (
    <Card
      sx={{
        ml: { lg: '3rem !important', xs: '0.7rem' },
        px: { lg: '2.5rem', xs: '1.5rem' },
        '& .MuiTypography-root': {
          fontSize: { lg: '2rem', xs: '1rem' },
          fontWeight: 500,
          textAlign: { xs: 'center' },
        },
        borderRadius: '1rem',
        boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.2)',
        height: { lg: '10rem', xs: '4.4rem' },
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Stack sx={{ height: '100%', justifyContent: 'center', alignItems: 'start' }}>
        <Typography>{t('model_face.comment_1_a')}</Typography>
        <Typography sx={{ fontWeight: '700 !important' }}>{t('model_face.comment_1_b')}</Typography>
      </Stack>
    </Card>
  )
}

const Guide = () => (
  <Stack
    direction="row"
    sx={{ alignItems: 'center', justifyContent: 'center', mt: '1rem', mb: '4rem' }}
  >
    <ExampleImage />
    <ExampleText />
  </Stack>
)

function ModelFaceUploadOptions({ gender, setGender, model, setModel }) {
  const { t, i18n } = useTranslation()
  const isKo = i18n.language === 'ko'

  const toggleButtonStyle = {
    width: { lg: isKo ? '5.6rem' : '8.6rem', xs: isKo ? '5rem' : '8rem' },
    height: { lg: '5rem', xs: '4.4rem ' },
    fontSize: { lg: '1.8rem', xs: '1.6rem ' },
    fontWeight: 700,
    p: 0,
  }

  const raceList = [{ id: 'asian' }, { id: 'black' }, { id: 'caucasian' }, { id: 'hispanic' }]

  return (
    <>
      <CenterAlignStack>
        <CenterAlignStack
          sx={{
            pt: { lg: '2rem', xs: 0 },
            '& .MuiTypography-root': {
              fontSize: { lg: '1.5rem', xs: '1.2rem' },
              fontWeight: { lg: 500, xs: 600 },
            },
          }}
        >
          <Typography>{t('model_face.config_comment_1')}</Typography>
          <Typography>
            {t('model_face.config_comment_2_a')}
            <Desktop>{t('model_face.config_comment_2_b')}</Desktop>
          </Typography>
          <Mobile>
            <Typography>{t('model_face.config_comment_2_b')}</Typography>
          </Mobile>
        </CenterAlignStack>

        <Stack
          direction={{ lg: 'row', xs: 'column' }}
          spacing={{ lg: '2rem', xs: '2rem' }}
          sx={{ py: { lg: '2rem', xs: '1.3rem' }, alignItems: 'center' }}
        >
          <StyledToggleButtonGroup
            value={gender}
            exclusive
            onChange={e => {
              setGender(e.target.value)
            }}
            sx={{ gap: '0 !important' }}
          >
            <StyledToggleButton sx={toggleButtonStyle} value="female">
              {t('button.female')}
            </StyledToggleButton>
            <StyledToggleButton sx={toggleButtonStyle} value="male">
              {t('button.male')}
            </StyledToggleButton>
          </StyledToggleButtonGroup>

          <Stack
            direction="row"
            spacing={{ lg: '1rem', xs: '0.7rem' }}
            sx={{
              '& .item': {
                position: 'relative',
                width: { lg: '10rem', xs: '7.4rem' },
                height: { lg: '10rem', xs: '7.4rem' },
                cursor: 'pointer',
                '& .selected': {
                  border: theme => `3px solid ${theme.palette.draph.blue}`,
                  borderRadius: '1rem',
                },
              },
            }}
          >
            {raceList.map(r => (
              <Box
                key={r.id}
                value={r.id}
                className={`item`}
                onClick={e => {
                  setModel(r.id)
                }}
              >
                <img src={`/static/images/model_example/${gender}_${r.id}.png`} />

                <Box
                  className={`${r.id === model ? 'selected' : ''}`}
                  sx={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      </CenterAlignStack>
    </>
  )
}

export function ModelFaceRegenDialog() {
  const user = useRecoilValue(userAtom)
  const [dialog, setDialog] = useRecoilState(modelFaceRegenDialogAtom)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const [gender, setGender] = useState('female')
  const [model, setModel] = useState('asian')

  const { artwork, setArtwork, refreshArtwork, refreshRegenCount } = dialog

  const resetDialog = useResetRecoilState(modelFaceRegenDialogAtom)

  const { t } = useTranslation()

  const handleClose = () => {
    resetDialog()
  }

  useEffect(() => {
    if (!artwork || !artwork.config) return

    const config = JSON.parse(artwork.config)
    const model = config?.facemorphing_race
    if (model) {
      setModel(model)
    }

    const g = config?.facemorphing_gender
    if (g) {
      setGender(g === 'man' ? 'male' : 'female')
    }
  }, [artwork])

  useEffect(() => {
    if (dialog.open) {
      // ----- GA4 event -----
      window.gtag('event', 'model_face_regenerate_begin', {})
      // ---------------------
    }
  }, [dialog.open])

  const handleRegenerate = () => {
    const feedback = 'regenerate_auto'
    const artworkConfig = JSON.parse(artwork.config)

    // ----- GA4 event -----
    window.gtag('event', 'model_face_regenerate', { method: `${gender}_${model}` })
    // ---------------------

    setArtwork({ ...artwork, status: feedback })

    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('user_type', getUserType(user))
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', artwork.portfolio_id)
    formData.append('retry_type', 'regenerate')

    const genOptions = {
      ...artworkConfig,

      flag_human_background: true,

      // 변경하여 적용할 config
      flag_facemorphing: true,
      facemorphing_race: model,
      facemorphing_gender: gender === 'male' ? 'man' : 'woman',
      //
    }
    formData.append('gen_options', JSON.stringify(genOptions))
    // handleClose()

    apis.portfolio
      .updateArtworkFeedback(artwork.portfolio_id, artwork.id, {
        feedback,
      })
      .then(() => {
        handleClose()
        refreshArtwork()
        apis.appfront
          .retry(formData)
          .then(() => {
            refreshArtwork()
          })
          .catch(error => {
            console.log(error)
            refreshArtwork()
          })
        refreshRegenCount()
      })
      .catch(() => {
        alert('오류가 발생하였습니다')
        refreshArtwork()
      })
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={dialog.open}
          onClose={handleClose}
          sx={{
            '& .MuiDialog-paper': {
              minWidth: '68rem',
              borderRadius: '20px',
            },
          }}
        >
          <DialogContent sx={{}}>
            <CenterAlignStack>
              <Typography sx={{ fontSize: '2rem', fontWeight: 700, py: '1rem' }}>
                {t('regenerate_dialog.regenerate')}
              </Typography>
            </CenterAlignStack>

            <ModelFaceUploadOptions
              gender={gender}
              setGender={setGender}
              model={model}
              setModel={setModel}
            />
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', pt: '1rem', pb: '3rem' }}>
            <DialogButton
              handleClose={handleClose}
              actionText={t('regenerate_dialog.button_regenerate')}
              handleAction={handleRegenerate}
            />
          </DialogActions>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={dialog.open}
          onOpen={() => {
            return dialog.open
          }}
          onClose={handleClose}
        >
          <CenterAlignStack>
            <Typography sx={{ fontSize: '1.6rem', fontWeight: 700, py: '1.6rem' }}>
              {t('regenerate_dialog.regenerate')}
            </Typography>

            <ModelFaceUploadOptions
              gender={gender}
              setGender={setGender}
              model={model}
              setModel={setModel}
            />

            <CenterAlignBox sx={{ pt: '2rem', pb: '3rem' }}>
              <DialogButton
                handleClose={handleClose}
                actionText={t('regenerate_dialog.button_regenerate')}
                handleAction={handleRegenerate}
              />
            </CenterAlignBox>
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}
