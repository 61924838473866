import { English, Korean } from 'hooks/useLanguage'
import { Desktop, Mobile } from 'hooks/useMediaQuery'

export const requestSamples = [
  {
    type: 'bespoke_concept',
    content: <>상품 사진 썸네일 제작 요청</>,
    email: 'happ***@naver.com',
  },
  {
    type: 'bespoke_model',
    content: <>패션 브랜드 운영 중입니다. 제작 요청합니다.</>,
    email: 'wksa***@naver.com',
  },
  {
    type: 'bespoke_model_interaction',
    content: <>인스타용 광고 소재 제작하려고 합니다. 견적 요청드립니다.</>,
    email: 'worl***@naver.com',
  },
  {
    type: 'bespoke_model',
    content: <>신제품 홍보를 위한 모델 사진을 요청드립니다.</>,
    email: 'star***@gmail.com',
  },
  {
    type: 'bespoke_concept',
    content: <>가을 컬렉션을 위한 창의적인 콘셉트 이미지를 제작해주세요.</>,
    email: 'moon***@naver.com',
  },
  {
    type: 'bespoke_model_interaction',
    content: <>제품을 사용 중인 모델의 자연스러운 모습을 원합니다.</>,
    email: 'sky***@gmail.com',
  },
  {
    type: 'bespoke_model',
    content: <>배경에 모델이 포함된 제품 사진을 부탁드립니다.</>,
    email: 'nova***@naver.com',
  },
  {
    type: 'bespoke_concept',
    content: <>여름 테마의 상품 콘셉트 이미지를 만들어주세요.</>,
    email: 'pixel***@gmail.com',
  },
  {
    type: 'bespoke_model_interaction',
    content: <>제품을 활용한 모델의 다양한 포즈 사진을 요청합니다.</>,
    email: 'echo***@naver.com',
  },
  {
    type: 'bespoke_concept',
    content: <>신제품의 독특한 콘셉트 이미지를 제작해주세요.</>,
    email: 'luna***@gmail.com',
  },
  {
    type: 'bespoke_model',
    content: <>모델이 등장하는 배경과 함께 제품 사진을 요청드립니다.</>,
    email: 'vibe***@naver.com',
  },
  {
    type: 'bespoke_model_interaction',
    content: <>제품을 사용하는 모델의 생동감 있는 사진을 부탁드립니다.</>,
    email: 'zen***@gmail.com',
  },
  {
    type: 'bespoke_concept',
    content: <>겨울 시즌에 맞는 제품 콘셉트 이미지를 제작해주세요.</>,
    email: 'flash***@naver.com',
  },
  {
    type: 'bespoke_model',
    content: <>스타일리시한 배경과 함께 제품 사진을 원합니다.</>,
    email: 'blaze***@gmail.com',
  },
  {
    type: 'bespoke_model_interaction',
    content: <>제품을 손에 들고 있는 모델의 멋진 사진을 요청드립니다.</>,
    email: 'ghost***@naver.com',
  },
  {
    type: 'bespoke_concept',
    content: <>봄 컬렉션을 위한 신선한 콘셉트 이미지를 제작해주세요.</>,
    email: 'spark***@gmail.com',
  },
  {
    type: 'bespoke_model',
    content: <>모델과 함께한 제품 사진을 요청드립니다.</>,
    email: 'ghost***@naver.com',
  },
  {
    type: 'bespoke_model_interaction',
    content: <>제품을 활용한 모델의 자연스러운 모습이 담긴 사진을 부탁드립니다.</>,
    email: 'flare***@gmail.com',
  },
  {
    type: 'bespoke_concept',
    content: <>레저용품의 콘셉트 이미지를 제작해주세요.</>,
    email: 'wave***@naver.com',
  },
  {
    type: 'bespoke_model',
    content: <>도심 배경과 함께한 제품 사진을 원합니다.</>,
    email: 'spark***@gmail.com',
  },
  {
    type: 'bespoke_model_interaction',
    content: <>제품을 사용하는 모습을 담은 모델 사진을 요청드립니다.</>,
    email: 'dash***@naver.com',
  },
  {
    type: 'bespoke_concept',
    content: <>럭셔리 제품을 위한 고급스러운 콘셉트 이미지를 만들어주세요.</>,
    email: 'glow***@gmail.com',
  },
  {
    type: 'bespoke_model',
    content: <>자연 배경 속에서 제품을 소개하는 사진을 부탁드립니다.</>,
    email: 'jazz***@naver.com',
  },
  {
    type: 'bespoke_model_interaction',
    content: <>제품과 함께한 모델의 다이나믹한 사진을 요청합니다.</>,
    email: 'bliss***@gmail.com',
  },
]
