import { Group, Circle, Arc } from 'react-konva'
import { useEffect, useState } from 'react'

const LoadingSpinner = ({ props }) => {
  const [rotation, setRotation] = useState(0)

  useEffect(() => {
    const animate = () => {
      setRotation(prev => (prev + 10) % 360)
    }

    const animationId = setInterval(animate, 50)

    return () => {
      clearInterval(animationId)
    }
  }, [])

  return (
    <Group x={props.x} y={props.y} {...props}>
      {/* Background circle */}
      <Circle radius={props.height / 2} fill="#f3f4f6" opacity={0.3} />

      {/* Rotating arc */}
      <Arc
        angle={270}
        rotation={rotation}
        outerRadius={props.height / 2}
        innerRadius={props.height / 2 - 4}
        fill="#4299e1"
      />
    </Group>
  )
}

export default LoadingSpinner
