import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Slide,
  Typography,
  useTheme,
} from '@mui/material'
import {
  brandConfigUpdateAtom,
  brandReadOnlyConfigAtom,
  hasProductAtom,
  loadingSubAtom,
  portfolioDetailAtom,
  unusualPortfolioConfigUpdateAtom,
  uploadDialogOpen2Atom,
  uploadDialogOpenAtom,
  uploadFilesAtom,
  userAtom,
} from 'atoms'
import { PuffLoader } from 'react-spinners'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import SingleSimpleDragDrop from 'components/SingleSimpleDragDrop'
import { Desktop } from 'hooks/useMediaQuery'
import { useRefineUploadFiles } from 'hooks/useRefineUploadFiles'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BANNER_ARTWORK_CONFIG } from 'config'
import { useRecoilState, useRecoilValue } from 'recoil'
import { PlusIcon } from 'theme/icon'
import { DragDropFile } from './UploadImageDialog'
import { BsFillXCircleFill } from 'react-icons/bs'
import { DialogButton } from 'components/DialogButtons'
import { useHandleGetNpy } from './UploadMannequinDialog'
import useBanner from 'hooks/useBanner'

const fontStyles = {
  title: {
    fontSize: '20px',
    fontWeight: 700,
  },
  notice: {
    fontSize: '15px',
    fontWeight: 500,
  },
}

export default function BannerUploadImageDialog({
  dialogTitle,
  dialogNotice,
  title1 = false,
  title2 = false,
  actionText = false,
  sampleNotice = false,
  handleUpload,
  narrowGap,
  contentSx = {},
}) {
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)
  const [uploadOpen2, setUploadOpen2] = useRecoilState(uploadDialogOpen2Atom)
  const [images, setImages] = useRecoilState(uploadFilesAtom)
  const refineUploadFiles = useRefineUploadFiles()
  const user = useRecoilValue(userAtom)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [brandReadOnlyConfig, setBrandReadOnlyConfig] = useRecoilState(brandReadOnlyConfigAtom)
  const [brandConfig, setBrandConfig] = useRecoilState(brandConfigUpdateAtom)
  const [hasProduct, setHasProduct] = useRecoilState(hasProductAtom)
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [uploadingSub, setUploadingSub] = useRecoilState(loadingSubAtom)

  const handleGetNpy = useHandleGetNpy()
  const { logoSaveApi } = useBanner()

  const theme = useTheme()

  useEffect(() => {
    return () => {
      // if (bannerConfig.genType === 'mann2man') return
      // setImages([])
    }
  }, [])

  const setFile = param => file => {
    setBrandConfig(prevState => ({
      ...prevState,
      [param]: file,
    }))
  }

  const handleImageClose = () => {
    setBrandConfig(prev => ({ ...prev, logoImage: [] }))
  }

  const handleClose = () => {
    setUploadOpen(false)
    // setUploadingSub(false)
    setBrandConfig(prev => ({
      logoImage: brandReadOnlyConfig?.logoImage ? [brandReadOnlyConfig.logoImage] : [],
      brandName: brandReadOnlyConfig?.brand_name ?? '',
      brandFeature: brandReadOnlyConfig?.brand_concept ?? '',
      logoS3path: brandReadOnlyConfig?.s3_url ?? '',
    }))
  }

  const { t, i18n } = useTranslation()
  return (
    <Desktop>
      <Dialog
        open={uploadOpen}
        sx={{
          overflowX: 'hidden',
          '& .MuiDialog-paper': {
            minWidth: '492px',
            borderRadius: '10px',
          },
        }}
        onClose={handleClose}
      >
        <DialogContent sx={{ p: '39px 30px 31px 30px', ...contentSx }}>
          {sampleNotice ?? <></>}
          <CenterAlignStack>
            <Typography>{dialogTitle ?? ''}</Typography>
            <Typography>{dialogNotice ?? ''}</Typography>
          </CenterAlignStack>
          <CenterAlignStack>
            <CenterAlignBox sx={{ width: '100%' }}>
              <CenterAlignStack sx={{ width: '50%', gap: narrowGap ? '8px' : '16px' }}>
                <Typography
                  sx={{ ...fontStyles.title, ...(hasProduct ? {} : { color: '#9E9E9E' }) }}
                >
                  {title1 ?? t('banner_config.upload_title_1')}
                  {hasProduct && (
                    <sup
                      style={{
                        color: theme.palette.error.main,
                        fontWeight: 500,
                        fontSize: '1.8rem',
                        lineHeight: '2.1rem',
                        verticalAlign: 'text-top',
                      }}
                    >
                      &#42;
                    </sup>
                  )}
                </Typography>
                {hasProduct ? (
                  <SingleSimpleDragDrop
                    file={images}
                    setFile={setImages}
                    width="144px"
                    height="144px"
                    isUpload={true}
                  />
                ) : (
                  <CenterAlignBox
                    sx={{
                      alignItems: 'center',
                      width: '14.4rem',
                      height: '14.4rem',
                      borderRadius: '10px',
                      backgroundColor: '#ABABAB',
                    }}
                  >
                    <Typography sx={{ fontSize: '2rem', color: '#fff', fontWeight: 700 }}>
                      {t('banner_config.no_product')}
                    </Typography>
                  </CenterAlignBox>
                )}
              </CenterAlignStack>
              <Divider orientation="vertical" flexItem sx={{ height: '144px', alignSelf: 'end' }} />
              <CenterAlignStack sx={{ width: '50%', gap: narrowGap ? '8px' : '16px' }}>
                <Typography sx={{ ...fontStyles.title }}>
                  {' '}
                  {title2 ?? t('banner_config.upload_title_2')}
                </Typography>
                <SingleSimpleDragDrop
                  file={brandConfig.logoImage}
                  setFile={setFile('logoImage')}
                  width="144px"
                  height="144px"
                />
                {/* {Boolean(brandConfig.logoImage.length) && (
                  <IconButton
                    onClick={handleImageClose}
                    sx={{
                      position: 'absolute',
                      top: '6.7rem',
                      right: '3rem',
                      fontSize: '2rem ',
                    }}
                  >
                    <BsFillXCircleFill
                      style={{
                        background: 'white',
                        borderRadius: '50px',
                        width: '2.4rem',
                        height: '2.4rem',
                        padding: '1px',
                      }}
                    />
                  </IconButton>
                )} */}
              </CenterAlignStack>
            </CenterAlignBox>
            <Typography sx={{ ...fontStyles.notice, mt: '20px', mb: '16px' }}>
              {t('banner_config.upload_notice')}
            </Typography>
            <DialogButton
              actionText={
                uploadingSub ? <PuffLoader size={35} /> : t('banner_config.upload_button')
              }
              handleClose={handleClose}
              handleAction={async () => {
                if (bannerConfig.genType === 'mann2man') {
                  await logoSaveApi()
                  // setUploadingSub(false)

                  setUploadOpen(false)
                  setUploadOpen2(true)

                  handleGetNpy({ image: images[0] })
                  // setFile(images[0])
                } else {
                  const logo = await logoSaveApi()
                  // setUploadingSub(false)
                  // console.log(brandConfig, URL.createObjectURL(logo.logo_image[0]))

                  const option = {
                    ...BANNER_ARTWORK_CONFIG,
                    logoS3path: logo.logoS3path,
                    humanDepict: bannerConfig.humanDepict,
                    bgDepict: bannerConfig.bgDepict,
                    brandFeature: brandConfig.brandFeature,
                    productFeature: bannerConfig.productFeature,
                  }
                  handleUpload(images, option, { logo_image: logo.logo_image[0] })
                }
              }}
              actionprops={
                hasProduct
                  ? { disabled: !images.length || uploadingSub }
                  : { disabled: uploadingSub }
              }
            />
          </CenterAlignStack>
        </DialogContent>
      </Dialog>
    </Desktop>
  )
}
