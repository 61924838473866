import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom'
// material
import { styled, useTheme } from '@mui/material/styles'

import { CenterAlignBox, CenterAlignStack, TempLayoutDiv } from 'components'
import { Box, Card, Divider, Stack, Typography } from '@mui/material'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { userCreditInfoAtom } from 'atoms'
import { APPBAR_DESKTOP } from './main/MainAppBar'
import { useTranslation } from 'react-i18next'
import { isKo } from 'utils/common'
import { Korean } from 'hooks/useLanguage'
import { FOOTER_DESKTOP } from './main/MainFooter'
import { BsJustify } from 'react-icons/bs'

// ----------------------------------------------------------------------

export const MypagesMenu = styled(Card)(({ theme }) => ({
  // position: 'fixed',
  position: 'sticky',
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',

  height: `calc(100vh - ${APPBAR_DESKTOP})`,
  // height: `calc(100vh - ${FOOTER_DESKTOP} - ${APPBAR_DESKTOP})`,

  boxShadow: 'none',
  border: '1px solid #D9D9D9',
  top: `calc(${APPBAR_DESKTOP} + 3.6rem)`,
  borderRadius: '1rem',

  padding: '9px',
}))

export const MypagesRouterLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 400,
  fontSize: '1.4rem',

  background: '#F8F8F8',
  height: '4.4rem',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '1.6rem',
  '&.bg-transparent': {
    background: 'transparent',
  },
  color: theme.palette.common.black,

  '&:active, &:visited': { color: 'inherit' },

  '&.selected': {
    color: theme.palette.draph.blue,

    '& .icon.stroke path': {
      stroke: theme.palette.draph.blue,
    },
    '& .icon.fill path': {
      fill: theme.palette.draph.blue,
    },
  },
  '& .icon': {
    width: '2.4rem',
    height: '2.4rem',

    marginRight: '1.6rem',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const titleStyle = {
  fontWeight: 500,
  fontSize: '1.4rem',

  lineHeight: '1.67rem',
  color: '#808080',
  height: '3.7rem',
  display: 'flex',
  alignItems: 'center',
  pl: '1.6rem',
}

const dividerStyle = {
  my: '1.6rem',
  border: '0.5px solid #BBBBBB',
}

// ----------------------------------------------------------------------

export default function MypagesSideBar() {
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [userCreditInfo, setUserCreditInfo] = useRecoilState(userCreditInfoAtom)

  return (
    <>
      <Desktop>
        <MypagesMenu>
          <Stack sx={{ height: '100%' }}>
            <Typography sx={titleStyle}>
              {t('mypage_menu.credit_and_subscriptions_short')}
            </Typography>
            {/* <Typography sx={{ mb: '1.6rem', ...titleStyle }}>{t('mypage_menu.credit')}</Typography> */}

            <MypagesRouterLink
              to="/user/subscriptions"
              className={pathname === '/user/subscriptions' && 'selected'}
              onClick={() => {}}
            >
              <Box className="icon stroke">
                <DashboardIcon />
              </Box>
              {t('mypage_menu.subscription')}
            </MypagesRouterLink>

            <MypagesRouterLink
              to="/user/creditInfo"
              className={pathname === '/user/creditInfo' && userCreditInfo === 0 && 'selected'}
              onClick={() => {
                setUserCreditInfo(0)
              }}
            >
              <Box className="icon fill">
                <CreditCardIcon />
              </Box>
              {t('mypage_menu.charged_credit')}
            </MypagesRouterLink>
            <MypagesRouterLink
              to="/user/creditInfo"
              className={pathname === '/user/creditInfo' && userCreditInfo === 1 && 'selected'}
              onClick={() => {
                setUserCreditInfo(1)
              }}
            >
              <Box className="icon stroke">
                <CoinIcon />
              </Box>
              {t('mypage_menu.credit_history')}
            </MypagesRouterLink>

            <Korean>
              <MypagesRouterLink
                to="/user/bespoke_history"
                className={pathname === '/user/bespoke_history' && 'selected'}
              >
                <Box className="icon stroke">
                  <HandshakeIcon />
                </Box>
                {t('mypage_menu.bespoke_history')}
              </MypagesRouterLink>
            </Korean>

            <Divider flexItem sx={{ ...dividerStyle }} />

            <MypagesRouterLink
              to="/user/coupon"
              className={`bg-transparent ${pathname === '/user/coupon' && 'selected'}`}
            >
              <Box className="icon stroke">
                <CouponIcon />
              </Box>
              {t('mypage_menu.coupon')}
            </MypagesRouterLink>

            <MypagesRouterLink
              to="/user/profile"
              className={`bg-transparent ${pathname === '/user/profile' && 'selected'}`}
            >
              <Box className="icon fill">
                <SettingIcon />
              </Box>
              {t('mypage_menu.user_info_change')}
            </MypagesRouterLink>
          </Stack>
        </MypagesMenu>
      </Desktop>
    </>
  )
}

const DashboardIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 1H2C1.44772 1 1 1.44772 1 2V9C1 9.55229 1.44772 10 2 10H7C7.55228 10 8 9.55229 8 9V2C8 1.44772 7.55228 1 7 1Z"
      stroke="#303030"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 1H13C12.4477 1 12 1.44772 12 2V5C12 5.55228 12.4477 6 13 6H18C18.5523 6 19 5.55228 19 5V2C19 1.44772 18.5523 1 18 1Z"
      stroke="#303030"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 10H13C12.4477 10 12 10.4477 12 11V18C12 18.5523 12.4477 19 13 19H18C18.5523 19 19 18.5523 19 18V11C19 10.4477 18.5523 10 18 10Z"
      stroke="#303030"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 14H2C1.44772 14 1 14.4477 1 15V18C1 18.5523 1.44772 19 2 19H7C7.55228 19 8 18.5523 8 18V15C8 14.4477 7.55228 14 7 14Z"
      stroke="#303030"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const CreditCardIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 5C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17V7C2 5.89543 2.89543 5 4 5H20ZM4 15V17H20V15H4ZM4 13H20V7H4V13Z"
      fill="#303030"
    />
  </svg>
)

const CoinIcon = () => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 3.36834C11 4.67634 8.76142 5.73668 6 5.73668C3.23858 5.73668 1 4.67634 1 3.36834M11 3.36834C11 2.06034 8.76142 1 6 1C3.23858 1 1 2.06034 1 3.36834M11 3.36834V6.68409M1 3.36834V6.68409M11 6.68409C11 7.99209 8.76142 9.05243 6 9.05243C3.23858 9.05243 1 7.99209 1 6.68409M11 6.68409V9.99936M1 6.68409V9.99936M11 9.99936C11 11.3074 8.76142 12.3677 6 12.3677C3.23858 12.3677 1 11.3074 1 9.99936M11 9.99936V13.3147M11 9.99936C11 11.3074 13.2386 12.3672 16 12.3672C18.7614 12.3672 21 11.3069 21 9.99888M11 9.99936C11 8.69136 13.2386 7.63054 16 7.63054C18.7614 7.63054 21 8.69088 21 9.99888M1 9.99936V13.3147M11 13.3147C11 14.6227 8.76142 15.683 6 15.683C3.23858 15.683 1 14.6227 1 13.3147M11 13.3147V16.6317M11 13.3147C11 14.6227 13.2386 15.683 16 15.683C18.7614 15.683 21 14.6227 21 13.3147M1 13.3147V16.6317C1 17.9397 3.23858 19 6 19C8.76142 19 11 17.9397 11 16.6317M11 16.6317C11 17.9397 13.2386 19 16 19C18.7614 19 21 17.9397 21 16.6317V13.3147M21 9.99888V13.3147"
      stroke="#303030"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const CouponIcon = () => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 5H8.01M14 5L11 8L8 11M14 11H14.01M1 5C1.79565 5 2.55871 5.31607 3.12132 5.87868C3.68393 6.44129 4 7.20435 4 8C4 8.79565 3.68393 9.55871 3.12132 10.1213C2.55871 10.6839 1.79565 11 1 11V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15H19C19.5304 15 20.0391 14.7893 20.4142 14.4142C20.7893 14.0391 21 13.5304 21 13V11C20.2044 11 19.4413 10.6839 18.8787 10.1213C18.3161 9.55871 18 8.79565 18 8C18 7.20435 18.3161 6.44129 18.8787 5.87868C19.4413 5.31607 20.2044 5 21 5V3C21 2.46957 20.7893 1.96086 20.4142 1.58579C20.0391 1.21071 19.5304 1 19 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V5Z"
      stroke="#303030"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const SettingIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 0L14 3L18 3.5L20 6.5L18 10L20 13.5L18 16.5L14 17L11.5 20H8.5L6 17L2 16.5L0 13.5L2 10L0 6.5L2 3.5L6 3L8.5 0H11.5ZM10.562 1.999H9.437L7.0312 4.88666L3.156 5.37L2.35 6.581L4.3035 10L2.35 13.418L3.156 14.629L7.0312 15.1133L9.436 17.999H10.563L12.9688 15.1133L16.843 14.629L17.649 13.418L15.6965 10L17.649 6.581L16.843 5.37L12.9688 4.88666L10.562 1.999ZM10 6C12.2091 6 14 7.79086 14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10C6 7.79086 7.79086 6 10 6ZM10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8Z"
      fill="#303030"
    />
  </svg>
)

const HandshakeIcon = () => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.05 14.6338L11.95 16.51C12.1371 16.6948 12.3593 16.8414 12.6038 16.9414C12.8483 17.0414 13.1104 17.0929 13.375 17.0929C13.6396 17.0929 13.9017 17.0414 14.1462 16.9414C14.3907 16.8414 14.6129 16.6948 14.8 16.51C14.9871 16.3252 15.1356 16.1058 15.2369 15.8644C15.3381 15.623 15.3903 15.3642 15.3903 15.1029C15.3903 14.8415 15.3381 14.5828 15.2369 14.3413C15.1356 14.0999 14.9871 13.8805 14.8 13.6957M12.9 11.8195L15.275 14.1648C15.6529 14.538 16.1655 14.7476 16.7 14.7476C17.2345 14.7476 17.7471 14.538 18.125 14.1648C18.5029 13.7916 18.7153 13.2854 18.7153 12.7576C18.7153 12.2298 18.5029 11.7237 18.125 11.3505L14.439 7.71066C13.9046 7.18364 13.1803 6.88762 12.425 6.88762C11.6697 6.88762 10.9454 7.18364 10.411 7.71066L9.575 8.53619C9.19707 8.90939 8.68448 9.11905 8.15 9.11905C7.61552 9.11905 7.10293 8.90939 6.725 8.53619C6.34707 8.16299 6.13475 7.65683 6.13475 7.12904C6.13475 6.60126 6.34707 6.0951 6.725 5.7219L9.3945 3.08585C10.2611 2.23232 11.3913 1.68861 12.6061 1.54078C13.8209 1.39296 15.051 1.64947 16.1015 2.26971L16.548 2.53238C16.9525 2.77346 17.4334 2.85707 17.897 2.7669L19.55 2.43857M19.55 1.50047L20.5 11.8195H18.6M2.45 1.50047L1.5 11.8195L7.675 17.9171C8.05293 18.2903 8.56552 18.5 9.1 18.5C9.63448 18.5 10.1471 18.2903 10.525 17.9171C10.9029 17.5439 11.1153 17.0378 11.1153 16.51C11.1153 15.9822 10.9029 15.4761 10.525 15.1029M2.45 2.43857H10.05"
      stroke="#303030"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
