import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import {
  Link,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Divider,
  SwipeableDrawer,
} from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import {
  CenterAlignBox,
  CenterAlignStack,
  FormTextField,
  GoogleLogin,
  KakaoLogin,
  NaverLogin,
  StyledFormTextField,
  FormErrorMessage,
  RegisterBox,
  RegisterLabel,
  TextInput,
  FormCheckbox,
  AgreeDialog,
} from 'components'
import { apis } from 'apis'
import { AiFillEye as OpenedEyeIcon, AiFillEyeInvisible as ClosedEyeIcon } from 'react-icons/ai'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useRecoilState } from 'recoil'
import { tutorialAtom, userAtom } from 'atoms'
import { styled, useTheme } from '@mui/material/styles'
import {
  AgreeCheckedSquareIcon,
  AgreeSquareIcon,
  CheckedSquareIcon,
  CheckedSquareIcon2,
  SearchIcon,
  SquareIcon,
  SquareIcon2,
} from 'theme/icon'
import { motion } from 'framer-motion'
import { RiThermometerFill } from 'react-icons/ri'
import { MHidden } from 'components/@material-extend'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useSnackbar } from 'notistack'
import useConfirm from 'hooks/useConfirm'
import moment from 'moment'
import { iOS } from 'utils/common'
import { promotionInfo, promotionInfoKeys } from './promotionEvents/DomeggookEvent'
import { useNavigateForPromotion, useNavigateWithQuery } from 'hooks/useNavigateWithQuery'
import { useTranslation } from 'react-i18next'
import { LoginAddinfoDialog } from './Login'
import { Korean } from 'hooks/useLanguage'

const StyledCheckbox = styled(FormControlLabel)(({ theme }) => ({
  paddingLeft: '3px',

  '& .MuiCheckbox-root': {
    color: 'blue',
  },

  '& .MuiTypography-root': {
    fontSize: '12px',
    fontWeight: 400,
    // color: theme.palette.common.black,
    color: 'black',
  },
}))

const buttonStyle = {
  width: { lg: '38.4rem', xs: '30rem' },
  height: { lg: '6rem', xs: '4.9rem' },
  fontSize: { lg: '2rem', xs: '1.5rem' },
}

export const buttonMobileStyle = {
  width: '320px',
  height: '48px',
  mt: '23px',
  fontSize: '18px',
  fontWeight: '800',
}

const TextButtonStyle = {
  fontSize: '12px',
  fontWeight: 400,
  color: 'black',
  cursor: 'pointer',
}

export default function Join() {
  const initUsername = localStorage.getItem('rememberMe')
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const loginSchema = yup.object().shape({
    username: yup.string().required(t('register.id_error_1')),
    password: yup
      .string()
      .min(2, t('register.password_error_4'))
      .required(t('register.password_error_4')),
  })

  const [open, setOpen] = useState(false)
  const [socialLogin, setSocialLogin] = useState({
    open: false,
    socialName: null,
    data: {},
    agreeInfo: null,
  })

  const [loading, setLoading] = useState(false)
  const [remember, setRemember] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const [user, setUser] = useRecoilState(userAtom)
  const promotionId = '4'

  const location = useLocation()
  const { navigateEvent, navigateCheckpromotion } = useNavigateForPromotion()
  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    if (localStorage.getItem('promotionQuery')) return

    for (const key of promotionInfoKeys) {
      if (promotionInfo[key].query === location.search) {
        localStorage.setItem('promotionQuery', promotionInfo[key].query)
        break
      }
    }

    // ----- GA4 event -----
    window.gtag('event', 'sign_up_begin', {})
    // ---------------------
  }, [])

  const {
    formState: { isDirty, dirtyFields, errors },
    control,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit', // validation will trigger on submit
    defaultValues: {
      username: initUsername ?? '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  })

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (initUsername) {
      setRemember(true)
    }
  }, [])

  const onSubmit = values => {
    setLoading(true)
    apis.user
      .login(values)
      .then(response => {
        const d = response.data

        if (remember) {
          localStorage.setItem('rememberMe', values.username)
        } else {
          localStorage.removeItem('rememberMe')
        }
        const accessToken = d.access_token
        const refreshToken = d.refresh_token
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)

        // ----- GA4 event -----
        window.gtag('event', 'login', {
          method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
        })
        // ---------------------

        navigateEvent({ replace: true })

        if (!localStorage.getItem('promotionQuery')) {
          apis.user.checkEvent(promotionId).then(response => {
            const isApply = response.data.applied
            // 튜토 OFF
            // setTutorial(prev => ({ step: 0, mode: !isApply }))
          })
        }
      })
      .catch(e => {
        console.log(e)
        if (e.response.status === 401) {
          alert(t('login.login_error_1'))
        } else {
          alert(t('common.error'))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <motion.div
        initial={{ x: 10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <CenterAlignStack sx={{ pt: '80px' }}>
          <Typography
            sx={{ fontSize: { lg: '2.4rem', xs: '2rem' }, lineHeight: 1, fontWeight: 400 }}
          >
            {t('join.join_today')}
          </Typography>

          <CenterAlignStack sx={{ pt: '0.4rem' }}>
            <Typography
              sx={{
                fontSize: { lg: '3rem', xs: '2.4rem' },
                fontWeight: 800,

                background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',

                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
              }}
            >
              {t('join.join_comment_1')}
            </Typography>

            <Button
              size="large"
              variant="contained"
              sx={{ ...buttonStyle, mt: '4rem' }}
              onClick={() => {
                navigate('/register')
                // ----- GA4 event -----
                window.gtag('event', 'sign_up_method', { method: 'general' })
                // ---------------------
              }}
            >
              {t('login.email_sign_up')}
            </Button>

            <Divider flexItem sx={{ borderColor: '#D9D9D9', my: '4rem' }} />

            <Typography sx={{ fontSize: { lg: '2rem', xs: '1.6rem' }, fontWeight: 700 }}>
              {t('login.SNS_sign_up')}
            </Typography>

            <CenterAlignBox sx={{ py: '2.5rem' }} gap="30px">
              <GoogleLogin
                setOpen={setOpen}
                socialLogin={socialLogin}
                setSocialLogin={setSocialLogin}
              />

              <Korean>
                <NaverLogin
                  setOpen={setOpen}
                  socialLogin={socialLogin}
                  setSocialLogin={setSocialLogin}
                />
                <KakaoLogin
                  setOpen={setOpen}
                  socialLogin={socialLogin}
                  setSocialLogin={setSocialLogin}
                />
              </Korean>
            </CenterAlignBox>
          </CenterAlignStack>

          <CenterAlignStack direction="row" sx={{ pb: '1.5rem' }}>
            <Typography
              sx={{
                fontSize: { lg: '1.6rem', xs: '1.4rem' },
                fontWeight: 500,
                mr: '1rem',
              }}
            >
              {t('join.join_comment_2')}
            </Typography>
            <Typography
              component={'span'}
              sx={{
                cursor: 'pointer',
                fontWeight: 800,
                fontSize: { lg: '1.6rem', xs: '1.4rem' },
                color: theme => theme.palette.draph.newblue,
              }}
              onClick={() => {
                navigate('/login')
                // ----- GA4 event -----
                window.gtag('event', 'sign_up_already', {})
                // ---------------------
              }}
            >
              {t('join.login')}
            </Typography>
          </CenterAlignStack>

          <Korean>
            <img
              src={
                isMobile
                  ? '/static/images/event/short_event_banner_mo.png'
                  : '/static/images/event/short_event_banner_pc.png'
              }
              style={{ width: isMobile ? '32rem' : '38.4rem', cursor: 'pointer' }}
              onClick={() => {
                navigate('/event/ko/normal/sns_review_event')
              }}
            />
          </Korean>
        </CenterAlignStack>
      </motion.div>
      <LoginAddinfoDialog
        open={open}
        setOpen={setOpen}
        socialLogin={socialLogin}
        setSocialLogin={setSocialLogin}
      />
    </>
  )
}

function DividerIcon(props) {
  return (
    <svg
      width="1"
      height="8"
      viewBox="0 0 1 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line x1="0.25" y1="1.09278e-08" x2="0.25" y2="8" stroke="#808080" strokeWidth="0.5" />
    </svg>
  )
}
