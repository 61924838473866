import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Box,
  DialogActions,
  Stack,
  SwipeableDrawer,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { apis } from 'apis'
import { CenterAlignStack, CenterAlignBox } from 'components/CenterAlignContainer'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { FadeLoader as Loader } from 'react-spinners'
import { DialogButton } from 'components/DialogButtons'
import {
  SearchInput,
  CustomPagination,
  UploadImageThumb,
  BadExampleWarning,
  processInGroups,
} from 'components'

import { LoadingButton } from '@mui/lab'

import {
  BackButton,
  BackIcon,
  MobileArtworkGrayCloseIcon,
  SearchIcon,
  SelectedIcon,
  UndoIcon,
} from 'theme/icon'
import { useRecoilValue } from 'recoil'
import { portfolioConfigAtom } from 'atoms'
import { iOS, imageToFile } from 'utils/common'
import Register from 'pages/Register'
import Login from 'pages/Login'
import { CommerceRegisterForm, CommerceLogin } from 'pages'
import moment from 'moment'
import { useNavigateForPromotion } from 'hooks/useNavigateWithQuery'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { English, Korean } from 'hooks/useLanguage'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const SAVE_KEY_PREFIX = 'cafe24_'
const SNS_NAME_CAFE24 = 'cafe24_commerce'

const getParam = name => {
  const url = new URL(window.location.href)
  return url.searchParams.get(name)
}

// key color #235AF2
export function Cafe24Commerce() {
  const { t } = useTranslation()
  const [showRegisterForm, setShowRegisterForm] = useState(false)

  const { navigateEvent } = useNavigateForPromotion()
  const navigate = useNavigate()

  const saveKeys = ['mall_id', 'refresh_token', 'mall_user_id']

  const saveInfo = info => {
    saveKeys.forEach(k => {
      sessionStorage.setItem(`${SAVE_KEY_PREFIX}${k}`, info[k])
    })
  }

  // cafe24 앱스토어를 통해 유입된 유저의 경우 그 세션 이내에서 결제 발생시 cafe24에 결제를 제공해주어야 하는데,
  // 이때 세션에 보관했던 정보를 활용해야 하므로 세션스토리지에 보관했던 정보를 reset 하지 않도록 함
  // const resetInfo = () => {
  //   saveKeys.forEach(k => {
  //     sessionStorage.removeItem(`${SAVE_KEY_PREFIX}${k}`)
  //   })
  // }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const oAuthCode = queryParams.get('code')
    const mallId = queryParams.get('state')

    if (oAuthCode) {
      apis.cafe24Commerce
        .checkUser({
          mall_id: mallId,
          code: oAuthCode,
        })
        .then(response => {
          if (!response.data.success) {
            let msg = response.data.message ?? '오류가 발생하였습니다.'
            if (response.data.error === 'invalid_grant') {
              msg = '인증 정보가 유효하지 않습니다.'
            }
            alert(msg)
          } else {
            // 가입/로그인시 제출할 항목을 보관
            saveInfo(response.data)

            if (response.data.registered) {
              setShowRegisterForm(false)
            } else {
              window.onbeforeunload = e => {
                return ''
              }
              setShowRegisterForm(true)
            }
          }
        })
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  const onSubmitRegister = values => {
    const code = getParam('code')
    if (!code) {
      alert('잘못된 접근입니다.')
      return
    }

    const submitValues = { ...values }

    saveKeys.forEach(k => {
      const v = sessionStorage.getItem(`${SAVE_KEY_PREFIX}${k}`)
      submitValues[k] = v
    })

    apis.cafe24Commerce.createUser(submitValues).then(response => {
      const d = response.data

      const accessToken = d.access_token
      const refreshToken = d.refresh_token
      setAccessToken(accessToken)
      setRefreshToken(refreshToken)

      // ----- GA4 event -----
      window.gtag('event', 'sign_up', {
        method: SNS_NAME_CAFE24,
        date: moment().local().format('YYYY-MM-DD'),
      })
      window.gtag_report_conversion_register()
      // ---------------------

      // ----- Meta(facebook) pixel event -----
      window.fbq('track', 'CompleteRegistration')
      // --------------------------------------
      // ----- linkedin event -----
      window.lintrk('track', { conversion_id: 17109481 })
      // --------------------------

      navigateEvent({ replace: true })
    })
  }

  const onSubmitLogin = values => {
    const submitValues = { ...values }

    saveKeys.forEach(k => {
      const v = sessionStorage.getItem(`${SAVE_KEY_PREFIX}${k}`)
      submitValues[k] = v
    })
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')

    apis.cafe24Commerce
      .login({ ...submitValues, code })
      .then(response => {
        if (response.data.access_token) {
          // 정상 로그인 처리
          const d = response.data

          const accessToken = d.access_token
          const refreshToken = d.refresh_token
          setAccessToken(accessToken)
          setRefreshToken(refreshToken)

          // ----- GA4 event -----
          window.gtag('event', 'login', {
            method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
          })
          // ---------------------

          navigate('/generate/upload')
        } else {
          alert(response.data.message)
        }
      })
      .catch(e => {
        if (e.response?.status === 401) {
          alert(t('login.login_error_1'))
        } else {
          alert(t('common.error'))
        }
      })
  }

  const loginTitleComponent = (
    <>
      <CenterAlignStack>
        <CenterAlignStack sx={{ mb: '2rem' }} direction="row" spacing={'1.6rem'}>
          <img src="/static/images/logo/logo_h_black.png" style={{ height: '2.5rem' }} />

          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.07994 7.41573L0.210379 0H2.88219L7.53156 5.9176L12.223 0H14.7896L8.89902 7.35955L15 15H12.3703L7.48948 8.85768L2.58766 15H0L6.07994 7.41573Z"
              fill="#303030"
            />
          </svg>

          <img src="/static/images/etc/cafe24_logo_color.png" style={{ height: '1.5rem' }} />
        </CenterAlignStack>

        <Typography sx={{ fontSize: '2.4rem', fontWeight: 700, mb: '0.2rem' }}>
          <Korean>카페24 연동 로그인</Korean>
          <English>카페24 연동 로그인</English>
        </Typography>
        <Typography
          sx={{
            fontSize: '1.4rem',
            fontWeight: 400,
            lineHeight: 1,
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            setShowRegisterForm(true)
          }}
        >
          <Korean>신규 회원 가입</Korean>
          <English>신규 회원 가입</English>
        </Typography>
      </CenterAlignStack>
    </>
  )

  return (
    <>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme => theme.palette.common.black,
          height: '8rem',
        }}
      >
        <img src="/static/images/logo/logo_h_white.png" style={{ height: '4.9rem' }} />
      </Stack>

      {showRegisterForm ? (
        <CommerceRegisterForm
          titleAreaComponent={registerTitleComponent}
          onSubmit={onSubmitRegister}
          sx={{
            '& .submit-button:hover': {
              background: '#235AF2',
            },
          }}
          showLogin={() => {
            setShowRegisterForm(false)
          }}
        />
      ) : (
        <CommerceLogin titleAreaComponent={loginTitleComponent} onSubmit={onSubmitLogin} />
      )}
    </>
  )
}

const registerTitleComponent = (
  <>
    <CenterAlignStack sx={{ mb: '2rem' }} direction="row" spacing={{ lg: '1.6rem', xs: '0.8rem' }}>
      <Box
        sx={{
          // width: { lg: '12.5rem', xs: '10.3rem' },
          height: { lg: '2.5rem', xs: '2.1rem' },
        }}
      >
        <img
          src="/static/images/logo/logo_h_black.png"
          style={{
            // width: '100%',
            // height: 'auto',
            height: '100%',
            width: 'auto',
            objectFit: 'cover',
          }}
        />
      </Box>

      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.07994 7.41573L0.210379 0H2.88219L7.53156 5.9176L12.223 0H14.7896L8.89902 7.35955L15 15H12.3703L7.48948 8.85768L2.58766 15H0L6.07994 7.41573Z"
          fill="#303030"
        />
      </svg>

      <Box sx={{ height: { lg: '1.5rem', xs: '1.3rem' } }}>
        <img
          src="/static/images/etc/cafe24_logo_color.png"
          style={{
            height: '100%',
            width: 'auto',
            objectFit: 'cover',
          }}
        />
      </Box>
    </CenterAlignStack>
  </>
)
