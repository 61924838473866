import { Box, Card, Typography, styled, useTheme } from '@mui/material'
import { DataGrid, gridColumnVisibilityModelSelector } from '@mui/x-data-grid'
import { apis } from 'apis'
import { userAtom, userCreditAtom, userCreditInfoAtom, userSubscriptionIdAtom } from 'atoms'
import { CenterAlignBox, CenterAlignStack, CustomPagination } from 'components'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { MYPAGE_PERCENTAGE_WIDHT, MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { isKo, numberCommaFormatter, trasnferDate } from 'utils/common'
import { CellTypography, HeaderName, footerStyle, mobileFooterStyle } from './UserCharge'
import { useTranslation } from 'react-i18next'
import { usePricing } from 'hooks/usePricing'

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.black,
  border: 0,
  width: '88.4rem',
  minHeight: '41rem',
  paddingBottom: '3rem',
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100% !important',
  },

  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
    // margin: '0 1.3rem',

    '&.MuiDataGrid-cell:last-child': {
      width: '0 !important',
      margin: '0 !important',
      padding: '0 !important',
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    borderBottom: '0.1rem solid',
    backgroundColor: theme.palette.common.black,
    borderRadius: 0,
    justifyContent: 'center',

    '& .MuiDataGrid-columnHeader': {
      '&:focus-within': { outline: 'none !important' },
    },
  },

  '& .MuiDataGrid-row': {
    // padding: '0 7.6rem',
    width: '100% !important',
    minHeight: '5.8rem !important',
    justifyContent: 'center',

    borderBottom: '0.05rem solid #8F8F8F',

    '&:hover': {
      background: 'transparent',
    },

    '&.Mui-selected': {
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
  },

  '& .MuiDataGrid-cell': {
    borderBottom: 0,

    '&:focus-within': { outline: 'none !important' },
    '&:last-child': {
      // width: '24.6rem !important',
      // minWidth: '24.6rem !important',
      // maxWidth: '24.6rem !important',
    },
  },

  '& .MuiDataGrid-columnSeparator': { display: 'none' },

  [theme.breakpoints.down('lg')]: {
    '& .MuiDataGrid-columnHeaders': {
      minHeight: '4rem !important',
      maxHeight: '4rem !important',
      lineHeight: '4rem !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '4rem !important', // '& .MuiDataGrid-columnHeaders' 높이와 맞춰야함
    },
    '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
      margin: '0rem',
      padding: 0,
    },
  },
}))

const StyledDataGridMobile = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.black,
  border: 0,
  width: '100%',
  minHeight: '14.1rem',
  paddingBottom: '3rem',
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100% !important',
  },

  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
    // margin: '0 1.3rem',

    '&.MuiDataGrid-cell:last-child': {
      width: '0 !important',
      margin: '0 !important',
      padding: '0 !important',
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    borderBottom: '0.1rem solid',
    backgroundColor: theme.palette.common.black,
    borderRadius: 0,
    justifyContent: 'center',

    '& .MuiDataGrid-columnHeader': {
      '&:focus-within': { outline: 'none !important' },
    },
  },

  '& .MuiDataGrid-row': {
    // padding: '0 7.6rem',
    width: '100% !important',
    minHeight: '5.6rem !important',
    justifyContent: 'center',
    borderBottom: '0.05rem solid #8F8F8F',

    '&:hover': {
      background: 'transparent',
    },

    '&.Mui-selected': {
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
  },

  '& .MuiDataGrid-cell': {
    borderBottom: 0,

    '&:focus-within': { outline: 'none !important' },
    '&:last-child': {
      // width: '24.6rem !important',
      // minWidth: '24.6rem !important',
      // maxWidth: '24.6rem !important',
    },
  },

  '& .MuiDataGrid-columnSeparator': { display: 'none' },

  [theme.breakpoints.down('lg')]: {
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '5.6rem !important', // '& .MuiDataGrid-columnHeaders' 높이와 맞춰야함
    },
    '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
      margin: '0rem',
      padding: 0,
    },
  },
}))

export default function UserCredit({ currentSubscription }) {
  const postsPerPage = 10
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const { getPlanDisplayName } = usePricing()

  const [page, setPage] = useState(1)
  const [visiblePosts, setVisiblePosts] = useState([])
  const [creditInfo, setCreditInfo] = useState(null)
  const [todaySummary, setTodaySummary] = useState([
    {
      id: 1,
      title: t('credit.current_credit'),
      value: '-',
    },
    {
      id: 2,
      title: t('credit.today_used_credit'),
      value: '-',
    },
    {
      id: 3,
      title: t('credit.today_charged_credit'),
      value: '-',
    },
  ])
  const [userCredit, setUserCredit] = useRecoilState(userCreditAtom)
  const userSubId = useRecoilValue(userSubscriptionIdAtom)

  const now = moment().format('YYYY-MM-DD')

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    if (creditInfo) {
      const startIndex = (page - 1) * postsPerPage
      const endIndex = startIndex + postsPerPage
      setVisiblePosts(creditInfo.slice(startIndex, endIndex))
    }
  }, [page, creditInfo])

  useEffect(() => {
    apis.user.getCredit().then(response => {
      setUserCredit(response.data.credit)
    })

    const m = moment().utcOffset()
    apis.user.getCreditSummary(m / 60).then(response => {
      const summary = response.data
      if (summary[0].date === now) {
        const s = summary.shift()
        setTodaySummary([
          {
            id: 1,
            title: t('credit.current_credit'),
            value: s.balance,
          },
          {
            id: 2,
            title: t('credit.today_used_credit'),
            value: Math.abs(s.used),
          },
          {
            id: 3,
            title: t('credit.today_charged_credit'),
            value: s.added ?? '0',
          },
        ])
      } else {
        setTodaySummary([
          {
            id: 1,
            title: t('credit.current_credit'),
            value: numberCommaFormatter(userCredit),
          },
          {
            id: 2,
            title: t('credit.today_used_credit'),
            value: 0,
          },
          {
            id: 3,
            title: t('credit.today_charged_credit'),
            value: 0,
          },
        ])
      }

      setCreditInfo(summary)
    })
  }, [])

  const columns = [
    {
      field: 'date',
      flex: 1.5,
      headerName: t('credit.date'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        // 서버에서 타임존 고려하여 리턴된 데이터이므로 변환할 필요 없음

        return (
          <CellTypography>
            <Desktop>{trasnferDate(params.value, 'num', i18n)}</Desktop>
            <Mobile>
              {params.value.split('-')[0].slice(2, 4)}.{params.value.split('-')[1]}.
              {params.value.split('-')[2]}
            </Mobile>
          </CellTypography>
        )
      },
    },
    {
      field: 'added',
      headerName: t('credit.added'),
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => <CellTypography>{params.value}</CellTypography>,
    },
    {
      field: 'used',
      headerName: t('credit.used'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      flex: 1,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => <CellTypography>{params.value}</CellTypography>,
    },
    {
      field: 'expired',
      headerName: t('credit.expired'),
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => <CellTypography>{params.value}</CellTypography>,
    },
    {
      field: 'balance',
      headerName: t('credit.balance'),
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => (
        <CellTypography sx={{ fontWeight: 800, fontSize: '1.6rem' }}>{params.value}</CellTypography>
      ),
    },
  ]

  return (
    <>
      <Desktop>
        <CenterAlignStack>
          <CenterAlignStack sx={{ width: '88.4rem', mb: '2.6rem' }}>
            <Typography sx={{ fontWeight: 700, fontSize: '2.6rem', mt: '5.7rem' }}>
              {t('credit.today_credit')}
            </Typography>

            <CenterAlignBox
              sx={{ width: '100%', display: 'flex', mt: '3.3rem', mb: '7.6rem' }}
              gap="2.4rem"
            >
              {todaySummary.map((info, idx) => {
                return (
                  <Card
                    key={info.id}
                    sx={{
                      width: '20rem',
                      height: '14rem',
                      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
                      borderRadius: '16px ',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: isKo(i18n) ? '2.8rem' : '2.4rem',
                        fontWeight: 600,
                        color: theme.palette.draph.blue,
                      }}
                    >
                      {info.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 800,
                        fontSize: '4rem',
                        color:
                          info.value !== 0 ? theme.palette.common.black : theme.palette.common.gray,
                      }}
                    >
                      {info.value}
                    </Typography>
                  </Card>
                )
              })}
            </CenterAlignBox>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: '1.6rem' }}>
                {t('credit.my_credit')}
                <span
                  style={{ fontWeight: 800, fontSize: '2rem', color: theme.palette.draph.blue }}
                >
                  {userCredit ? numberCommaFormatter(userCredit) : 0} {t('credit.credit')}
                </span>
              </Typography>
              {currentSubscription?.id && (
                <Box sx={{ border: '2px solid #4D80FF', borderRadius: '20px' }}>
                  <Typography
                    sx={{
                      margin: '0.5rem 1.8rem',
                      fontWeight: 700,
                      fontSize: '1.4rem',
                    }}
                  >
                    {t('mypage.subscription')}:{' '}
                    {getPlanDisplayName(currentSubscription?.plan_id, i18n.language)} (
                    {trasnferDate(moment.utc(currentSubscription?.last_paid).toDate(), 'num', i18n)}
                    ~ )
                  </Typography>
                </Box>
              )}
            </Box>
          </CenterAlignStack>

          <StyledDataGrid
            disableColumnMenu
            getRowHeight={() => 'auto'}
            rows={visiblePosts ?? []}
            components={{
              NoRowsOverlay: () => (
                <>
                  <CenterAlignBox sx={{ py: '3rem' }}>{t('credit.empty_history')}</CenterAlignBox>
                </>
              ),
            }}
            keepNonExistentRowsSelected
            columns={columns}
            pageSize={postsPerPage}
            // rowsPerPageOptions={[5, 10, 15]}
            autoHeight
            hideFooter
            initialState={{
              sorting: {},
            }}
          />

          <CustomPagination
            count={Math.ceil((creditInfo?.length ?? 0) / postsPerPage)}
            page={page}
            onChange={(e, value) => {
              setPage(value)
            }}
          />
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack sx={{ width: '100%' }}>
          <CenterAlignStack sx={{ width: '100%', px: '1rem' }}>
            <Typography sx={{ fontWeight: 700, fontSize: '1.4rem', mt: '3.9rem' }}>
              {t('credit.today_credit')}
            </Typography>

            <CenterAlignBox
              sx={{ width: '100%', display: 'flex', mt: '2rem', mb: '2rem' }}
              gap="2.4rem"
            >
              {todaySummary.map((info, idx) => {
                return (
                  <Card
                    key={info.title}
                    sx={{
                      width: '26.7%',
                      minWidth: '9.5rem',
                      height: '8rem',
                      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
                      borderRadius: '16px ',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '1.4rem',
                        fontWeight: 600,
                        color: theme.palette.draph.blue,
                      }}
                    >
                      {info.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 800,
                        fontSize: '2.4rem',
                        color:
                          info.value === 0 ? theme.palette.common.gray : theme.palette.common.black,
                      }}
                    >
                      {info.value}
                    </Typography>
                  </Card>
                )
              })}
            </CenterAlignBox>

            <CenterAlignStack sx={{ mb: '2.8rem' }}>
              <Typography sx={{ fontSize: ' 1.2rem', fontWeight: 500, mt: '3.4rem' }}>
                {t('credit.my_credit')}

                <span
                  style={{
                    fontWeight: 800,
                    fontSize: '2rem',
                    color: theme.palette.draph.blue,
                    marginLeft: '1rem',
                  }}
                >
                  {userCredit ? numberCommaFormatter(userCredit) : 0} {t('credit.credit')}
                </span>
              </Typography>
              {currentSubscription?.id && (
                <Box
                  sx={{
                    border: '2px solid #4D80FF',
                    borderRadius: '20px',
                    mt: '1rem',
                  }}
                >
                  <Typography
                    sx={{
                      margin: '0.4rem 1.3rem',
                      fontWeight: 700,
                      fontSize: '1.2rem',
                    }}
                  >
                    {t('mypage.subscription')}:{' '}
                    {getPlanDisplayName(currentSubscription?.plan_id, i18n.language)} (
                    {trasnferDate(moment.utc(currentSubscription?.last_paid).toDate(), 'num', i18n)}
                    ~ )
                  </Typography>
                </Box>
              )}
            </CenterAlignStack>
          </CenterAlignStack>
          <StyledDataGridMobile
            disableColumnMenu
            getRowHeight={() => 'auto'}
            rows={visiblePosts ?? []}
            components={{
              NoRowsOverlay: () => (
                <>
                  <CenterAlignBox sx={{ py: '3rem' }}>{t('credit.empty_history')}</CenterAlignBox>
                </>
              ),
            }}
            keepNonExistentRowsSelected
            columns={columns}
            pageSize={postsPerPage}
            // rowsPerPageOptions={[5, 10, 15]}
            autoHeight
            hideFooter
            initialState={{
              sorting: {},
            }}
          />

          <CustomPagination
            count={Math.ceil((creditInfo?.length ?? 0) / postsPerPage)}
            page={page}
            onChange={(e, value) => {
              setPage(value)
            }}
          />
        </CenterAlignStack>
      </Mobile>
    </>
  )
}
