import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { AiOutlinePushpin } from 'react-icons/ai'
import { FiEdit3, FiSearch } from 'react-icons/fi'
import { PuffLoader, SyncLoader } from 'react-spinners'
import { CloseIcon, SearchIcon, StarOutlinedIcon } from 'theme/icon'
import { BackgroundCard } from './background/BackgroundItem'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'

import { CardTitleTypography } from 'pages/CreditAndSubscriptions'
import { useTranslation } from 'react-i18next'
const imageBoxStyle = {
  position: 'relative',
  width: '100%',
  height: '32rem',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}
const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}
const idxTypoStyle = {
  paddingLeft: '1rem',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '18px',
  paddingBottom: '1rem',
}

export function BackgroundSkeleton() {
  const theme = useTheme()
  return (
    <Grid item>
      <Stack sx={{ width: '24rem' }}>
        <BackgroundCard width="100%" height="100%" sx={{ height: '31.6rem', borderRadius: '10px' }}>
          <CardHeader
            sx={{ py: '0rem', pr: '0.2rem', pl: '1.2rem' }}
            avatar={<Avatar sx={{ width: '2rem', height: '2rem' }} />}
            action={
              <CenterAlignBox gap="0.4rem">
                <IconButton size="large" disabled sx={{ p: 0, width: '2.5rem', height: '2.5rem' }}>
                  <StarOutlinedIcon
                    sx={{
                      width: '2.2rem',
                      height: '2.2rem',
                      fill: 'none',
                      stroke: theme.palette.common.black,
                      strokeWidth: '1.8',
                    }}
                  />
                </IconButton>
                <IconButton size="large" disabled sx={{ p: 0, width: '2.5rem', height: '2.5rem' }}>
                  <AiOutlinePushpin
                    style={{ width: '15rem', height: '15rem' }}
                    color={theme.palette.common.black}
                  />
                </IconButton>

                <IconButton size="large" disabled sx={{ p: 0, width: '2.5rem', height: '2.5rem' }}>
                  <CloseIcon style={{ width: '1.5rem', height: '1.5rem' }} />
                </IconButton>
              </CenterAlignBox>
            }
          />
          <CardMedia component="div">
            <Skeleton
              sx={{ height: '18rem', width: '24rem', transform: 'scale(1, 1)', borderRadius: 0 }}
            />
          </CardMedia>

          <IconButton
            sx={{
              position: 'absolute',
              color: '#ffffff90',
              p: 0.4,
              borderRadius: 'unset',

              right: '1rem',
              top: '28rem',
            }}
            disabled
          >
            {/* <FiSearch style={{ width: '1.75rem', height: '1.75rem', color: 'c9c9c9' }} /> */}
          </IconButton>

          <CardContent sx={{ p: '0.8rem 1.2rem 1rem 1.2rem' }}>
            <Box
              width="100%"
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Typography variant="h5" noWrap sx={{ width: '20%', fontSize: '1.7rem' }}>
                  <Skeleton sx={{}} />
                </Typography>
              </Box>

              <FiEdit3 />
            </Box>
            <Box width="100%">
              <Skeleton sx={{ fontSize: '12px', lineHeight: '14px', borderRadius: 0 }} />
              <Skeleton sx={{ fontSize: '12px', lineHeight: '14px', borderRadius: 0 }} />
              <Skeleton sx={{ fontSize: '12px', lineHeight: '14px', borderRadius: 0 }} />
            </Box>
          </CardContent>
        </BackgroundCard>
        {/* <Box width="100%" height="100%" sx={{ p: 2, border: '1px dashed gray' }}>
          <Box width="100%" sx={imageBoxStyle}>
            <Skeleton animation="wave" variant="rectangular" sx={imageStyle} />
            <SyncLoader
              color="rgb(150, 150, 150, 0.4)"
              margin={4}
              size={9}
              speedMultiplier={0.4}
              cssOverride={{ position: 'absolute', top: '50%' }}
            />
          </Box>
          <Box
            width="100%"
            sx={{ py: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <Box
              sx={{
                width: '90%',
              }}
            >
              <Typography variant="h4">
                <Skeleton animation="wave" />
              </Typography>
            </Box>
          </Box>
          <Box width="100%">
            <Typography variant="body">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Typography>
          </Box>
        </Box> */}
      </Stack>
    </Grid>
  )
}

export function MypageSkeleton() {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  return (
    <>
      <Card sx={{ width: { lg: '44.3rem', xs: '32rem' }, height: 'fit-content' }}>
        <CenterAlignStack>
          <CardTitleTypography>{t('mypage.my_subscription')}</CardTitleTypography>
          <CardContent sx={{ height: '22rem', padding: 0 }}>
            <CenterAlignStack
              sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}
            >
              <PuffLoader />
            </CenterAlignStack>
          </CardContent>
        </CenterAlignStack>
      </Card>

      <Card sx={{ width: { lg: '44.3rem', xs: '32rem' }, height: 'fit-content' }}>
        <CenterAlignStack>
          <CardTitleTypography>{t('mypage.my_credit')}</CardTitleTypography>
          <CardContent sx={{ height: '22rem', padding: 0 }}>
            <CenterAlignStack
              sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}
            >
              <PuffLoader />
            </CenterAlignStack>
          </CardContent>
        </CenterAlignStack>
      </Card>
    </>
  )
}

export function ArtworkSkeleton() {}
