import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { CenterAlignBox } from 'components/CenterAlignContainer'
import {
  getS3ImageSrc,
  isDev,
  isKo,
  translatePromptEnToKo,
  translatePromptKoToEn,
} from 'utils/common'
import * as config from 'config'
import { apis } from 'apis'
import { useRecoilState } from 'recoil'
import { userAtom } from 'atoms'
import axios from 'axios'
import _ from 'lodash'
import { checkedURLforNoCache } from 'utils/artwork'
import JSZip from 'jszip'
import { CreateButton } from 'components/CustomStyle'
import { processImages } from './ArtworkList'
import { RIGHT_CLICK_ALLOW_USER } from 'App'
import { useDeleteArtworks } from 'hooks/useReactQuery'
import { triggerGA4UploadEventManualConfig } from './Header'
import { useTranslation } from 'react-i18next'

export default function BannerArtworkControl({
  setOpen,
  setEditorDialogArtwork,
  artwork,
  portfolioId,
}) {
  const theme = useTheme()
  const { i18n, t } = useTranslation()

  const [user, setUser] = useRecoilState(userAtom)

  const artworkDone = config.ARTWORK_DONE_STATUS === artwork.status
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)
  const artworkLoading =
    (!artworkDone && !artworkError) || config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)

  const artworkConfig = JSON.parse(artwork.config)

  const { mutate: deleteArtworks, isPending: isDeleting } = useDeleteArtworks()

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const allDownload = () => {
    const promises = []
    const paths = []
    const ax = axios.create()
    const artworkName = artwork.name.replace('.' + _.last(artwork.name.split('.')), '')
    const pieces = artwork.pieces
    const piecePathArray = pieces.map(p => p.path)

    piecePathArray.forEach((path, i) => {
      paths.push(path)
      if (!path) return

      promises.push(
        ax.get(checkedURLforNoCache(getS3ImageSrc(path)) + `?w=${Date.now().toString()}`, {
          responseType: 'blob',
        })
      )
    })

    const zip = new JSZip()
    const dirName = artworkName
    zip.folder(dirName)

    Promise.all(promises).then(results => {
      processImages(results, dirName, artworkName, pieces)

      checkUserCredit()

      apis.portfolio.updateArtworkDownload(portfolioId, artwork.id, { paths }).then(response => {})
    })

    const eventParams = {
      method: 'row',
      count: paths.length,
      menu: 'v2',
    }

    triggerGA4UploadEventManualConfig(eventParams, 'banner_download')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        '& .artwork-prompt': {
          display: 'flex',

          backgroundColor: '#fff',
          p: '1.2rem',
          borderRadius: '10px',
          gap: '0.8rem',
          width: '80.8rem',
          minHeight: '8.6rem',

          '& .prompt-title': {
            background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
            color: theme.palette.common.white,
            p: '0.5rem 1.3rem',
            borderRadius: '4px',
            fontWeight: 500,
            fontSize: '1.2rem',
            width: '6.8rem',
            height: 'fit-content',
          },

          '& .prompt-box': {
            flex: 1,
          },
        },
        '& .button-box': {
          ml: '2rem',
          justifyContent: 'space-between',

          '& .MuiButtonBase-root': {
            height: '4.8rem',
            borderRadius: '4px',
            fontSize: '2rem',
            fontWeight: 700,
          },

          '& .delete-button': {
            fontSize: '1.5rem',
            height: '3rem',
          },
        },
      }}
    >
      <Box className="artwork-prompt">
        <CenterAlignBox className="prompt-title">{t('banner_v2.prompt')}</CenterAlignBox>
        <Stack className="prompt-box">
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500, lineBreak: 'anywhere' }}>
            {isKo(i18n)
              ? translatePromptEnToKo(artworkConfig.promptObj)
              : translatePromptKoToEn(artworkConfig.promptObj)}
          </Typography>
        </Stack>
      </Box>
      <Stack className="button-box">
        <Box sx={{ display: 'flex', gap: '1.6rem' }}>
          {/* <Box sx={{ width: '14rem' }}></Box> */}
          <Button
            sx={{
              width: '14rem',
            }}
            variant="contained"
            onClick={() => {
              setOpen(true)
              setEditorDialogArtwork(artwork)
            }}
            disabled={artworkLoading || artwork.pieces?.length === 0}
          >
            {t('button.edit')}
          </Button>

          <CreateButton
            sx={{
              width: '20rem',
            }}
            disabled={artworkLoading || artwork.pieces?.length === 0}
            onClick={allDownload}
          >
            {t('button.download')}
          </CreateButton>
        </Box>

        <Box
          sx={{
            mt: '1.4rem',
            fontWeight: 500,
            fontSize: '1.4rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative',
          }}
        >
          {(isDev || RIGHT_CLICK_ALLOW_USER.includes(user.id)) && (
            <Button
              className="delete-button"
              sx={{
                width: '10rem',
                height: '4rem',
                fontSize: '1.2rem',
                position: 'absolute',
                left: 0,
              }}
              variant="contained"
              onClick={() => deleteArtworks({ portfolioId: portfolioId, artworkIds: [artwork.id] })}
            >
              삭제
            </Button>
          )}
          Draph ads &nbsp;
          <Box
            sx={{
              backgroundColor: '#303030',
              color: 'white',
              p: '0.5rem 1.2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              fontSize: '1.2rem',
            }}
          >
            powered by Draph art
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
