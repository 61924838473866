import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apis } from 'apis'
import { useMemo, useState } from 'react'
import * as config from 'config'
import { isKo, notify, sortPieces } from 'utils/common'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { bannerPagesAtom, bannerTemplateParamsAtom } from 'atoms'
import t2iTemplate from 'apis/data/t2iTemplate.json'

export const useCacheManager = () => {
  const queryClient = useQueryClient()

  return {
    // 특정 쿼리 캐시 제거
    removeTemplateCache: (key = '', filters = {}) => {
      queryClient.removeQueries([key, filters])
    },

    // 모든 템플릿 관련 캐시 제거
    removeAllTemplateCache: (key = '') => {
      queryClient.removeQueries([key])
    },

    // 모든 캐시 제거
    clearAllCache: () => {
      queryClient.clear()
    },

    // 특정 쿼리 수동 리페치
    refetchTemplates: (key = '', filters = {}) => {
      queryClient.refetchQueries([key, filters])
    },
  }
}

// Templates
export const useTemplates = () => {
  // const [currentCursorIndex, setCurrentCursorIndex] = useRecoilState(bannerPagesAtom)
  const [templateSearchParams, setTemplateSearchParams] = useRecoilState(bannerTemplateParamsAtom)
  const { category, subcategory, initialPageSize, subsequentPageSize } = templateSearchParams

  const fetchTemplates = async ({ pageParam = null }) => {
    const params = {
      limit: pageParam === null ? initialPageSize ?? 10 : subsequentPageSize ?? 10,
      last_evaluated_key: pageParam,
      category,
      subcategory,
    }

    const response = await apis.data.loadAllTemplate(params)
    return {
      templates: response.data.templates,
      nextCursor: response.data.last_evaluated_key?.banner_id ?? null,
    }
  }

  const { data, isLoading, isError, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['templates', { category, subcategory, initialPageSize, subsequentPageSize }],
    queryFn: fetchTemplates,
    getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
    enabled: Boolean((!category && !subcategory) || (category && subcategory)),
  })

  const loadMore = () => {
    fetchNextPage()
    // setCurrentCursorIndex(prev => prev + 1)
  }

  return {
    templates: data?.pages.flatMap(page => page.templates) ?? [],
    isLoading,
    isError,
    loadMore,
    hasNextPage,
  }
}

export const useSelectedTemplateData = selectedIds => {
  const currentPage = useRecoilValue(bannerPagesAtom)

  const [templateSearchParams, setTemplateSearchParams] = useRecoilState(bannerTemplateParamsAtom)
  const [currentCursorIndex, setCurrentCursorIndex] = useRecoilState(bannerPagesAtom)

  const { category, subcategory, initialPageSize, subsequentPageSize } = templateSearchParams

  const { templates, isLoading, isError } = useTemplatesPagination({
    queryKey: ['templates', { category, subcategory, currentCursorIndex }],
  })

  const selectedTemplateData = useMemo(() => {
    if (!templates || selectedIds.length === 0) return null
    const selectedTemplate = templates.find(t => selectedIds.includes(t.banner_id))

    // 하..... t2i fake Template(1730163609626) 통과
    return selectedTemplate
      ? JSON.parse(selectedTemplate.template.data)
      : selectedIds.includes('1730163609626')
      ? JSON.parse(t2iTemplate.template.data)
      : null
  }, [templates, selectedIds])

  const isLogo = useMemo(() => {
    if (!selectedTemplateData) return false
    const shapes = selectedTemplateData.shapes
    return shapes.some(s => s.config.type === 'logo')
  }, [selectedTemplateData])

  const isInpaint = useMemo(() => {
    if (!selectedTemplateData) return false
    const shapes = selectedTemplateData.shapes
    return shapes.some(
      s => s.config.type === 'background' && s.config.background_type === 'inpaint'
    )
  }, [selectedTemplateData])

  return { selectedTemplateData, isInpaint, isLogo, isLoading, isError }
}

// Artwork

export const useArtworkQuery = (options = { portfolioId: null }) => {
  const getArtworks = useQuery({
    queryKey: ['artworks'],
    queryFn: () =>
      apis.portfolio.getAllPortfolioArtwork(options.portfolioId).then(r => {
        return r.data
      }),
    ...options,
  })

  // const getArtwork = useQuery({
  //   queryKey: ['artwork'],
  //   queryFn: () =>
  //     apis.portfolio.getArtwork(options.portfolioId, options.artworkId).then(r => {
  //       console.log('artwork', r.data)
  //       return r.data
  //     }),
  //   ...options,
  // })

  return { getArtworks }
}
// 아트워크 삭제
export function useDeleteArtworks() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: data => {
      return apis.portfolio.deleteArtworks(data.portfolioId, { artworkIds: data.artworkIds })
    }, // Assume this API endpoint exists
    onSuccess: () => {
      // Invalidate and refetch the artworks query
      queryClient.invalidateQueries({ queryKey: ['artworks'] })
    },
  })
}

// isFetchStatus 이게 true 일 때 이제 실행되도록 아니면 row 컴포넌트에서 모두 실행
export const useArtworkPolling = (portfolioId, artworkId, shouldPoll) => {
  const { t, i18n } = useTranslation()

  const notifyArtworkDone = artworkName => {
    const title = t('artwork_list.message.artwork_generate_done')
    const body = isKo
      ? `${artworkName ?? '-'}${t('artwork_list.message.check_artwork_results')}`
      : `Check out the results for ${artworkName ?? '-'} now!
    `
    const options = {
      body,
      icon: isKo
        ? 'https://d1p0kjf7jiqoy5.cloudfront.net/static/logo_image_ko.png'
        : 'https://d1p0kjf7jiqoy5.cloudfront.net/static/logo_image_en.png',
    }

    if ('Notification' in window && Notification.permission === 'granted') {
      return new Notification(title, options)
    }
  }

  const queryClient = useQueryClient()
  const [modPieceError, setModPieceError] = useState(false)

  const { data, error, refetch } = useQuery({
    queryKey: ['individualArtwork', portfolioId, artworkId],
    queryFn: () => apis.portfolio.getArtwork(portfolioId, artworkId),
    enabled: !!portfolioId && !!artworkId && shouldPoll,
    refetchInterval: query => {
      const data = query.state.data
      if (!data) return 2500
      const status = data.data.status?.toLowerCase()
      if (config.ARTWORK_DONE_STATUS === status || config.ARTWORK_ERROR_STATUS.includes(status)) {
        return false // 폴링 중지
      }
      return 2500 // 2.5초마다 폴링
    },
    onSuccess: data => {
      if (config.ARTWORK_DONE_STATUS === data.status?.toLowerCase()) {
        notifyArtworkDone(data.name)
        if (config.ARTWORK_MODPIECE_ERROR_STATUS === data.feedback_status) {
          setModPieceError(true)
        }
      } else if (config.ARTWORK_ERROR_STATUS.includes(data.status?.toLowerCase())) {
        notify(`오류 - ${data.name}`)
        // queryClient.invalidateQueries({ queryKey: ['artworks'] }) // 전체 artworks 리스트 갱신
      }
    },
    select: response => ({
      ...response.data,
      // pieces: sortPieces(response.data.pieces),
    }),
  })

  return { individualArtwork: data, error, modPieceError, refetch }
}

// pagination React-Query
export const useTemplatesPagination = (option = {}) => {
  const [cursors, setCursors] = useState([null])
  const [currentCursorIndex, setCurrentCursorIndex] = useRecoilState(bannerPagesAtom)

  const [templateSearchParams, setTemplateSearchParams] = useRecoilState(bannerTemplateParamsAtom)
  const { category, subcategory, initialPageSize, subsequentPageSize, enabled } =
    templateSearchParams

  const isFirstPage = currentCursorIndex === 0

  const fetchTemplates = async () => {
    // 현재 첫 페이지인지 여부를 확인

    const params = {
      // 첫 페이지면 initialPageSize, 아니면 subsequentPageSize 사용
      limit: 12,
      last_evaluated_key: cursors[currentCursorIndex]
        ? JSON.stringify(cursors[currentCursorIndex])
        : null,
      category,
      subcategory,
    }

    const response = await apis.data.loadAllTemplate(params)

    return {
      templates: response.data.templates,
      nextCursor: response.data.last_evaluated_key ?? null,
    }
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: ['templates', { category, subcategory, currentCursorIndex }],
    queryFn: fetchTemplates,
    enabled,
    ...option,
  })

  const goToNextPage = () => {
    if (data?.nextCursor) {
      setCursors(prev => [...prev.slice(0, currentCursorIndex + 1), data.nextCursor])
      setCurrentCursorIndex(prev => prev + 1)
    }
  }

  const goToPreviousPage = () => {
    if (currentCursorIndex > 0) {
      setCurrentCursorIndex(prev => prev - 1)
    }
  }

  return {
    templates: data?.templates ?? [],
    isLoading,
    isError,
    goToNextPage,
    goToPreviousPage,
    hasPreviousPage: currentCursorIndex > 0,
    hasNextPage: !!data?.nextCursor,
    isFirstPage,
  }
}

// export const useSelectedTemplateData = selectedIds => {
//   const currentPage = useRecoilValue(bannerPagesAtom)
//   const { templates, isLoading, isError } = useTemplates({
//     queryKey: ['templates', currentPage],
//   })

//   const selectedTemplateData = useMemo(() => {
//     if (!templates || selectedIds.length === 0) return null
//     const selectedTemplate = templates.find(t => selectedIds.includes(t.banner_id))

//     // 하..... t2i fake Template(1730163609626) 통과
//     return selectedTemplate
//       ? JSON.parse(selectedTemplate.template.data)
//       : selectedIds.includes('1730163609626')
//       ? JSON.parse(t2iTemplate.template.data)
//       : null
//   }, [templates, selectedIds])

//   const isLogo = useMemo(() => {
//     if (!selectedTemplateData) return false
//     const shapes = selectedTemplateData.shapes
//     return shapes.some(s => s.config.type === 'logo')
//   }, [selectedTemplateData])

//   const isInpaint = useMemo(() => {
//     if (!selectedTemplateData) return false
//     const shapes = selectedTemplateData.shapes
//     return shapes.some(
//       s => s.config.type === 'background' && s.config.background_type === 'inpaint'
//     )
//   }, [selectedTemplateData])

//   return { selectedTemplateData, isInpaint, isLogo, isLoading, isError }
// }
