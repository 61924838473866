import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'

const AIIcon = ({ color = '#303030' }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: { lg: '6.4rem', xs: '4.6rem' },
        height: { lg: '2.8rem', xs: '2rem' },
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '1rem',
        color: theme.palette.common.white,
        fontWeight: 700,
        fontSize: { lg: '1.6rem', xs: '1.4rem' },
      }}
    >
      AI
    </Box>
  )
}

export const configData = {
  ML_URL: process.env.REACT_APP_ML_URL,
  DB_URL: process.env.REACT_APP_DB_URL,
  STATIC_URL: process.env.REACT_APP_STATIC_URL,
  MAILER_URL: process.env.REACT_APP_MAILER_URL,
  DATA_URL: process.env.REACT_APP_DATA_URL,
  // MAILER_URL: 'http://localhost:4000',
  IMGGEN_URL: process.env.REACT_APP_IMGGEN_URL,
  COMMON_URL: process.env.REACT_APP_ML_COMMON_URL,
  COMMERCE_URL: process.env.REACT_APP_COMMERCE_URL,
}

export const GUEST_USERNAME = 'draph_guest_user_n5gIUpDOhPVrqZu2g0cbwX3y0CFIqPqR'

export const PAYMENT_REDIRECT_ENDPOINT = `${configData.DB_URL}/payment/paid_direct`

export const USER_GRADE_DICT = {
  welcome: '일반',
  basic: '베이직',
  pro: '프로',
  enterprise: '엔터프라이즈',
  betatester: '베타테스터',
  premium: '프리미엄',
  admin: '관리자',
}
export const PORTFOLIO_CATEGORIES = [
  {
    id: 'auto',
    name: '자동',
    children: [
      { id: 'auto', name: '자동', example: '/static/images/subCategory/picto/autoGif.gif' },
    ],
  },
  {
    id: 'person',
    name: '모델',
    children: [
      { id: 'auto', name: '자동', example: <AIIcon /> },
      { id: 'whole', name: '전신', example: '/static/images/subCategory/human/whole.jpeg' },
      { id: 'torso', name: '상반신', example: '/static/images/subCategory/human/torso.jpeg' },
      { id: 'lower', name: '하반신', example: '/static/images/subCategory/human/lower.jpeg' },
      { id: 'body', name: '몸통', example: '/static/images/subCategory/human/body.jpeg' },
      { id: 'detail', name: '상세', example: '/static/images/subCategory/human/detail.jpeg' },
    ],
  },
  {
    id: 'clothes',
    name: '의류',
    children: [
      { id: 'auto', name: '자동', example: <AIIcon /> },
      { id: 'clothing', name: '옷', example: '/static/images/subCategory/clothes/clothing.jpeg' },
      { id: 'etc', name: '잡화/기타', example: '/static/images/subCategory/clothes/etc.jpeg' },
      { id: 'detail', name: '상세', example: '/static/images/subCategory/clothes/detail.jpeg' },
    ],
  },
  {
    id: 'food',
    name: '식품',
    children: [
      { id: 'auto', name: '자동', example: <AIIcon /> },
      { id: 'plate', name: '접시,그릇', example: '/static/images/subCategory/food/plate.jpeg' },
      { id: 'box', name: '포장', example: '/static/images/subCategory/food/box.jpeg' },
      { id: 'raw', name: '식재료', example: '/static/images/subCategory/food/raw.jpeg' },
    ],
  },
  {
    id: 'cosmetics',
    name: '화장품',
    children: [
      { id: 'auto', name: '자동', example: '/static/images/subCategory/picto/cosmetics.png' },
    ],
  },
  {
    id: 'car',
    name: '자동차',
    children: [{ id: 'auto', name: '자동', example: '/static/images/subCategory/picto/car.png' }],
  },
  // {
  //   id: 'misc',
  //   name: '생활잡화',
  //   children: [{ id: 'auto', name: '자동', example: '/static/images/subCategory/picto/car.png' }],
  // },
]

export const MUSER_SUBCATEGORIES = {
  // 쿠팡이츠
  1233: {
    food: [
      // { id: 'auto', name: '자동', example: '/static/images/subCategory/picto/food.png' },
      // { id: 'plate', name: '접시,그릇', example: '/static/images/subCategory/food/plate.jpeg' },
      // { id: 'box', name: '포장', example: '/static/images/subCategory/food/box.jpeg' },
      // { id: 'raw', name: '식재료', example: '/static/images/subCategory/food/raw.jpeg' },
      { id: 'western', name: '양식' },
      { id: 'oriental', name: '한식/중식' },
      { id: 'japanese', name: '일식' },
      { id: 'dessert', name: '디저트/카페' },
    ],
  },
}

export const PORTFOLIO_CATEGORY_DICT = {
  auto: '자동',
  person: '모델',
  clothes: '의류',
  food: '식품',
  cosmetics: '화장품',
  car: '자동차',
  others: '기타',
  common: '공용',

  whole: '전신',
  torso: '상반신',
  body: '몸통',
  lower: '하반신',
  detail: '상세',
  clothing: '옷',
  etc: '잡화/기타',
  plate: '접시,그릇',
  box: '포장',
  raw: '식재료',

  western: '양식',
  oriental: '한식/중식',
  japanese: '일식',
  dessert: '디저트/카페',
}

export const OBJECT_BOUNDARY = [
  { id: 'none', name: '자동' },
  { id: 'strict', name: '엄격' },
  { id: 'normal', name: '적당히' },
  { id: 'natural', name: '자연스럽게' },
]

export const OBJECT_ANGLE = [
  { id: 'auto', name: '자동' },
  { id: 'perpen', name: '수직' },
  { id: 'floor', name: '측면' },
]

export const ARTWORK_REFRESH_STATUS = 'need_refresh'
export const ARTWORK_DONE_STATUS = 'done'
export const ARTWORK_ERROR_STATUS = [
  'error',
  'failed',
  'Failed',
  'Delayed',
  'delayed',
  'Failed_piece',
]
export const ARTWORK_ADDPIECE_STATUS = 'add_piece'
export const ARTWORK_ADDPIECE_ERROR_STATUS = 'add_piece_error'
export const ARTWORK_MODPIECE_STATUS = 'mod_piece'
export const ARTWORK_MODPIECE_ERROR_STATUS = 'mod_piece_error'
export const ARTWORK_MODPIECE_DONE_STATUS = 'mod_piece_complete'
export const ARTWORK_REGEN_STATUS = [
  'regenerate_auto',
  'regenerate',
  ARTWORK_ADDPIECE_STATUS,
  ARTWORK_MODPIECE_STATUS,
  'retry',
]
export const ARTWORK_REGEN_AUTO_COMPLETE = 'regenerate_auto_complete'
export const ARTWORK_IN_PROGRESS_STATUS = [
  '',
  'face',
  'sr',
  'sod',
  'sod_tagging',
  'composite',
  'assessment',
  'luxify',
  'shadow',
  'retry',
  'uploaded',
  'analysis',
  'prep',
  'gen',
  'gen_start',
  'retry_start',
]

const getProgress = stage => {
  const totalStage = 3
  // return `${stage} / ${totalStage}`
  return (stage / (totalStage + 1)) * 100
}

export const ARTWORK_STATUS_DICT = {
  uploaded: { text: 'uploaded', progress: getProgress(1), progressIdx: 1 },
  analysis: { text: 'analysis', progress: getProgress(2), progressIdx: 2 },
  prep: { text: 'prep', progress: getProgress(3), progressIdx: 3 },
  gen: { text: 'gen', progress: getProgress(3), progressIdx: 3 },
  gen_start: { text: 'gen_start', progress: getProgress(3), progressIdx: 3 },

  // face: { text: '가상 모델 생성', progress: getProgress(1), progressIdx: 1, next: 'sr' },
  // sr: {
  //   text: '사진을 더 선명하게',
  //   progress: getProgress(2),
  //   progressIdx: 2,
  //   prev: 'face',
  //   next: 'sod',
  // },
  // sod: {
  //   text: '상품 추출',
  //   progress: getProgress(3),
  //   progressIdx: 3,
  //   prev: 'sr',
  //   next: 'sod_tagging',
  // },
  // sod_tagging: {
  //   text: '상품 분석',
  //   progress: getProgress(4),
  //   progressIdx: 4,
  //   prev: 'sod',
  //   next: 'composite',
  // },
  // composite: {
  //   text: '상품 사진 합성',
  //   progress: getProgress(5),
  //   progressIdx: 5,
  //   prev: 'sod_tagging',
  //   next: 'assessment',
  // },
  // assessment: {
  //   text: 'AI 사진 심사',
  //   progress: getProgress(6),
  //   progressIdx: 6,
  //   prev: 'composite',
  //   next: 'luxify',
  // },
  // luxify: {
  //   text: '새로운 조명 생성',
  //   progress: getProgress(7),
  //   progressIdx: 7,
  //   prev: 'assessment',
  //   next: 'shadow',
  // },
  // shadow: { text: '그림자 생성', progress: getProgress(8), progressIdx: 8, prev: 'luxify' },
  // 'whole_tagging': '사진 분석',
  //
  done: { text: 'done' },
  error: { text: 'error' },
  Failed: { text: 'Failed' },
  failed: { text: 'failed' },
  retry: { text: 'retry' },
  regenerate_auto: { text: 'regenerate_auto' },
  regenerate: { text: 'regenerate' },
  need_refresh: { text: 'need_refresh' },
}

export const ARTWORK_REGEN_REQ_MAX = 5
export const ARTWORK_ADDPIECE_PREFIX = '_addpiecetemp'

export const GA4_EVENT_CATEGORY = {
  portfolio: 'portfolio',
}

// export const PAGE_TITLE = {
//   terms: '이용약관',
//   privacy: '개인정보 처리방침',
//   marketing: '생성물 활용 동의',
//   Advertisement: '광고 동의',
//   login: '로그인',
//   findID: '아이디 찾기',
//   findPW: '비밀번호 찾기',
//   resetPW: '비밀번호 초기화',
//   Register: '회원가입',
//   Board: '공지사항',
//   guide: '드랩 가이드',
//   FAQ: 'FAQ',
//   inquiry: '문의',
//   user: '유저 정보',
//   home: '드랩아트',
//   portfolio: '포트폴리오',
//   background: '배경관리',
//   paid: '결제완료',
//   pricing: '요금제',
//   admin: '관리자페이지',
//   'generate/upload': '이미지 생성',
//   'generate/removebg': '누끼따기',
//   'generate/mannequin': '마네킹 → 모델',
//   'generate/bundle': '대량(번들) 상품',
//   'fun/fame': 'FUN&AI(유명인)',

//   // 하위를 나중에 확인함으로
//   promotion: '프로모션',
//   'promotion/domeggook_event': '드랩아트X도매꾹 이벤트',
//   'promotion/oddoffice_event': '드랩아트X이상한사무실 이벤트',
//   default: '드랩아트',
// }

export const PORTFOLIO_TYPE_NORMAL = null // 유저가 생성하여 사용하는 일반 포트폴리오
export const PORTFOLIO_TYPE_REMOVEBG = 'removebg' // 누끼생성 메뉴 전용 포트폴리오
export const PORTFOLIO_TYPE_UPLOAD = 'upload' // 이미지생성 메뉴 전용 포트폴리오
export const PORTFOLIO_TYPE_MANNEQUIN = 'mannequin' // 마네킹(마네킹 to 사람) 메뉴 전용 포트폴리오
export const PORTFOLIO_TYPE_MODELBG = 'modelbg' // 모델이 있는 배경
export const PORTFOLIO_TYPE_FACE = 'face' // 모델 얼굴 변경
export const PORTFOLIO_TYPE_FAME = 'fame' // fun - 유명인 합성 메뉴 전용 포트폴리오
export const PORTFOLIO_TYPE_BUNDLE = 'bundle' // bundle - 번들전용 포트폴리오
export const PORTFOLIO_TYPE_BGEXPANSION = 'bg_expansion' // 배경 확장(캔버스 확장) 전용 포트폴리오
export const PORTFOLIO_TYPE_BANNER = 'banner' // 광고용 배너 전용 포트폴리오
export const PORTFOLIO_TYPE_BANNERV2 = 'banner2' // 광고용 2배너 전용 포트폴리오

export const DEFAULT_PORTFOLIO_TYPES = [
  PORTFOLIO_TYPE_REMOVEBG,
  PORTFOLIO_TYPE_MANNEQUIN,
  PORTFOLIO_TYPE_MODELBG,
  PORTFOLIO_TYPE_FACE,
  PORTFOLIO_TYPE_FAME,
  PORTFOLIO_TYPE_BUNDLE,
  PORTFOLIO_TYPE_BGEXPANSION,
  PORTFOLIO_TYPE_BANNER,
]

export const UNUSUAL_PORTFOLIO_TYPES = [PORTFOLIO_TYPE_BANNER, PORTFOLIO_TYPE_BANNERV2]

export const NO_REGENERATE_TYPES = [
  PORTFOLIO_TYPE_BANNER,
  PORTFOLIO_TYPE_BANNERV2,
  PORTFOLIO_TYPE_BUNDLE,
  PORTFOLIO_TYPE_BGEXPANSION,
]

export const DEFAULT_PORTFOLIO_NAME_DICT = {
  upload: '미분류',
  removebg: '누끼',
  mannequin: '마네킹',
  fun_fame: 'FUN&AI',
  fame: 'FUN&AI',
  bundle: '번들',
  bg_expansion: '캔버스확장',
  face: '모델얼굴변경',
}

export const UNUSUAL_PORTFOLIO_NAME_DICT = {
  banner: '배너',
}

export const BG_THEME_TEMPLATES = [
  { id: 'auto', type: 'concept' },

  { id: 'sky', sample: { default: 'default.png' }, type: 'concept' },
  { id: 'with_flower', sample: { default: 'default.png' }, type: 'concept' },
  { id: 'minimalism', sample: { default: 'default.png' }, type: 'simple' },
  { id: 'bubble', sample: { default: 'default.png' }, type: 'concept' },
  { id: 'half', sample: { default: 'default.png' }, type: 'concept' },
  { id: 'with_plant', sample: { default: 'default.png' }, type: 'concept' },
  { id: 'simple', sample: { default: 'default.png' }, type: 'concept' },
  { id: 'ocean_view', sample: { default: 'default.png' }, type: 'concept' },
  { id: 'kitchen', sample: { default: 'default.png' }, type: 'concept' },
  { id: 'table', sample: { default: 'default.png' }, type: 'concept' },

  { id: 'outdoor', sample: { default: 'default.png' }, type: 'concept' }, // 평온한 숲속  // 구: 교외 (시골, 숲, 정원)
  { id: 'summer', sample: { default: 'default.png' }, type: 'concept' }, // 호텔 수영장
  { id: 'living_room', sample: { default: 'default.png' }, type: 'concept' }, // 거실
  { id: 'winter', sample: { default: 'default.png' }, type: 'concept' }, // 새하얀 겨울냄새
  { id: 'indoor', sample: { default: 'default.png' }, type: 'concept' }, // 분위기 좋은 카페 // 구: 카페, 주방, 바
  { id: 'office', sample: { default: 'default.png' }, type: 'concept' }, // 잔잔한 오피스
  { id: 'studio', sample: { default: 'default.png', person: 'person.png' }, type: 'simple' }, // 컨셉 스튜디오
  { id: 'simple_studio1', sample: { default: 'default.png' }, type: 'simple' }, // 스튜디오1
  { id: 'simple_studio2', sample: { default: 'default.png' }, type: 'simple' }, // 스튜디오2
  { id: 'food_tile', sample: { default: 'default.png' }, type: 'concept' },
  { id: 'einsmedia_marble', sample: { default: 'default.png', type: 'concept' } }, // 스튜디오
  { id: 'einsmedia_dryer', sample: { default: 'default.png', type: 'concept' } }, // 스튜디오

  // ------
  // { id: 'sand_wave', sample: { default: 'default.png' } },
  // { id: 'with_flower2', sample: { default: 'default.png' } },
  // { id: 'naturalism', sample: { default: 'default.png' } },
  // { id: 'glass_flow', sample: { default: 'default.png' } },
  // { id: 'display', sample: { default: 'default.png' } },
  // { id: 'stone', sample: { default: 'default.png' } },
  // { id: 'woodwave', sample: { default: 'default.png' } },
  // { id: 'bathroom', sample: { default: 'default.png' } },
  // { id: 'table2', sample: { default: 'default.png' } },
  // { id: 'kor_floor', sample: { default: 'default.png' } },
  // { id: 'white_interior', sample: { default: 'default.png' } },
  // { id: 'shadow_play', sample: { default: 'default.png' } },
  // ------

  // { id: 'city', sample: { default: 'default.png', person: 'person.png' } }, // 도시 (도심, 골목, 공원)
  // { id: 'landmark', sample: { default: 'default.png' } }, // 명소
  // { id: 'spring', sample: { default: 'default.png' } },
  // { id: 'fall', sample: { default: 'default.png' } },

  { id: 'custom' }, // 직접입력

  // { id: 'season_christmas', sample: { default: 'default.png', person: 'person.png' } },
  // { id: 'season_new_year', sample: { default: 'default.png', person: 'person.png' } },

  // { id: 'dummy1' },
  // { id: 'dummy2' },
  // { id: 'dummy3' },
  // { id: 'dummy4' },
  // { id: 'dummy5' },
  // { id: 'dummy6' },
  // { id: 'dummy7' },
  // { id: 'dummy8' },
  // { id: 'dummy9' },
]
export const BG_THEME_ORDER = {
  default: [
    'auto',
    'bubble',
    'studio',
    'minimalism',
    'half',
    'sky',
    'simple',
    'with_flower',
    'sand_wave',
    'ocean_view',
    'with_flower2',
    'naturalism',
    'glass_flow',
    'display',
    'with_plant',
    'table',
    'kitchen',
    'stone',
    'woodwave',
    'bathroom',
    'table2',
    'kor_floor',
    'white_interior',
    'shadow_play',
    'outdoor',
    'summer',
    'living_room',
    'indoor',
    'winter',
    'office',
    'simple_studio1',
    'simple_studio2',
    'food_tile',
    'einsmedia_marble',
    'einsmedia_dryer',

    'custom',

    // 'spring',
    // 'city',
    // 'landmark',
    // 'living_room',
    // 'fall',
  ],
  nukkiUsage: [
    'summer',
    'city',
    'outdoor',
    'studio',
    'landmark',
    'indoor',
    'office',
    'living_room',
  ],
  // clothes: [
  //   'spring',
  //   'summer',
  //   'studio',
  //   'living_room',
  //   'office',
  //   'city',
  //   'outdoor',
  //   'indoor',
  //   'fall',
  //   'winter',
  //   'landmark',
  // ],
  // food: [
  //   'spring',
  //   'summer',
  //   'indoor',
  //   'outdoor',
  //   'studio',
  //   'living_room',
  //   'fall',
  //   'winter',
  //   'office',
  //   'city',
  //   'landmark',
  // ],
  // cosmetics: [
  //   'spring',
  //   'summer',
  //   'studio',
  //   'living_room',
  //   'office',
  //   'outdoor',
  //   'indoor',
  //   'fall',
  //   'winter',
  //   'city',
  //   'landmark',
  // ],
  // car: [
  //   'spring',
  //   'summer',
  //   'city',
  //   'outdoor',
  //   'landmark',
  //   'fall',
  //   'winter',
  //   'studio',
  //   'living_room',
  //   'office',
  //   'indoor',
  // ],
}

export const ARTWORK_ERROR_CODE = {
  101: 'upload image error',
  201: 'retry error',
  301: 'bg generate error',
  401: 'gen_compo error',
  402: 'analysis pipeline connection error',
  403: 'sod error',
  404: 'out of memory error',
  405: 'other issues in analysis pipeline',
  501: 'prep pipeline connection error',
  502: 'elastic search api call error',
  601: 'gen_agg error',
  701: 'nukki task error',
  801: 'imggen task error',
  901: 'composite task error',
}

export const PORTFOLIO_CONFIG_DEFAULT_BACK = {
  gen_shadow: true,
  gen_face: true,
  object_category: 'auto',
  object_sub_category: 'auto',
  object_boundary: 'none',
  object_angle: 'auto',
  model_gender: 'auto',
  flag_generate: true,
  flag_complex_cmp: true,
  flag_simple_cmp: true, // 단색
  flag_white_cmp: true, // 누끼
  output_size_w: 0,
  output_size_h: 0, // 0 means auto
  flag_gen_compo: false,
  flag_bg_expansion: false,
  flag_multiblob_sod: false,
  selected_bg_ids: '',

  flag_human_background: false,
  SO_length_scale: 'auto',

  flag_facemorphing: false,
  facemorphing_race: 'asian',
  facemorphing_gender: 'none',

  theme: '',
  theme_background: '',
  theme_template: 'auto',
  theme_custom: '',

  output_size_list: [],
  bg_expansion_bbox: [],
  simple_bg_color_list: [
    [255, 255, 255],
    [242, 242, 244],
  ],
}

export const BANNER_CONFIG_DEFAULT = {
  // image: '',
  // logoImage: '',
  productName: '',
  productFeature: '',
  productCategory: '',
  brandName: '',
  brandFeature: '',
  bannerMainText1: '',
  bannerMainTextIdx: 0,
  bannerMainText2: '',
  bannerSubText1: '',
  bannerSubTextIdx: 0,
  bannerSubText2: '',
  outputW: 1024,
  outputH: 1024,
  humanDepict: '',
  humanInteractionFlag: false,
  mann2manImageS3UrlList: JSON.stringify([]),
  genType: 'full',
  geodaiMax: false,
  productDepict: '',
  bgDepict: '',
  bannerStyle: 'image_hooking',
  copyStyle: 'question',
  prevPrice: '',
  postPrice: '',
  // mann2manImage: '',
  // binaryMask: '',
  // selectedExternalSize: null,
}

export const BANNER2_CONFIG_DEFAULT = {
  product_name: '',
  product_feature: '',
  brand_name: '',
  brand_feature: '',
}

// BANNER 용
export const BRAND_CONFIG_DEFAULT = {
  logoImage: [],
  brandName: '',
  brandFeature: '',
  logoS3path: '',
}

// font

export const FONT_DICT = {
  'pretendard_subtitle.otf': 'Pretendard Medium',
  'pretendard_title.otf': 'Pretendard SemiBold',
  'gmarketsans_subtitle.ttf': 'gmarket Sans',
  'gmarketsans_title.ttf': 'gmarket Sans Bold',
  'scdream_title.otf': 'scdream Bold',
  'scdream_subtitle.otf': 'scdream Regular',
  'jalnan_title.ttf': 'jalnan',
  'chosun_title.ttf': 'chosun',
  'heynovember_title.ttf': 'heynovember',
  'didot_title.otf': 'didot',

  'Pretendard Thin': 'Pretendard Thin',
  'Pretendard ExtraLight': 'Pretendard ExtraLight',
  'Pretendard Light': 'Pretendard Light',
  'Pretendard Regular': 'Pretendard Regular',
  'Pretendard Medium': 'Pretendard Medium',
  'Pretendard SemiBold': 'Pretendard SemiBold',
  'Pretendard Bold': 'Pretendard Bold',
  'Pretendard ExtraBold': 'Pretendard ExtraBold',
  'Pretendard Black': 'Pretendard Black',

  chosun: 'chosun',
  jalnan: 'jalnan',
  'gmarket Sans': 'gmarket Sans',
  'gmarket Sans Bold': 'gmarket Sans Bold',
  'scdream Regular': 'scdream Regular',
  'scdream Bold': 'scdream Bold',
  heynovember: 'heynovember',
  didot: 'didot',

  'font_bukk.ttf': 'bukk',
  'font_partialsans.otf': 'partialsans',
  'font_eulyoo1945.otf': 'eulyoo1945',
  'font_hambaknoon.ttf': 'hambaknoon',
  'font_onemobilepop.otf': 'onemobilepop',
  'font_onguelryutung.ttf': 'onguelryutung',
  'font_RixInooAriDuri.otf': 'RixInooAriDuri',
  'font_vitrocore.otf': 'vitrocore',
  'font_yes24myeongjo.ttf': 'yes24myeongjo',

  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
}

export const FONT_INFO = {
  'Pretendard Thin': { fontFamily: 'Pretendard Thin', label: '프리텐다드 Thin' },
  'Pretendard ExtraLight': { fontFamily: 'Pretendard ExtraLight', label: '프리텐다드 ExtraLight' },
  'Pretendard Light': { fontFamily: 'Pretendard Light', label: '프리텐다드 Light' },
  'Pretendard Regular': { fontFamily: 'Pretendard Regular', label: '프리텐다드 Regular' },
  'Pretendard Medium': { fontFamily: 'Pretendard Medium', label: '프리텐다드 Medium' },
  'Pretendard SemiBold': { fontFamily: 'Pretendard SemiBold', label: '프리텐다드 SemiBold' },
  'Pretendard Bold': { fontFamily: 'Pretendard Bold', label: '프리텐다드 Bold' },
  'Pretendard ExtraBold': { fontFamily: 'Pretendard ExtraBold', label: '프리텐다드 ExtraBold' },
  'Pretendard Black': { fontFamily: 'Pretendard Black', label: '프리텐다드 Black' },

  'gmarket Sans': { fontFamily: 'gmarket Sans', label: 'G마켓 산스 Medium' },
  'gmarket Sans Bold': { fontFamily: 'gmarket Sans Bold', label: 'G마켓 산스 Bold' },
  'scdream Regular': { fontFamily: 'scdream Regular', label: '에스코어 드림 4' },
  'scdream Bold': { fontFamily: 'scdream Bold', label: '에스코어 드림 7' },
  chosun: { fontFamily: 'chosun', label: '조선일보명조' },
  jalnan: { fontFamily: 'jalnan', label: '여기어때 잘난체' },
  heynovember: { fontFamily: 'heynovember', label: 'heynovember' },
  didot: { fontFamily: 'didot', label: 'didot' },

  bukk: { fontFamily: 'bukk', label: '부크크' },
  partialsans: { fontFamily: 'partialsans', label: '파셜산스' },
  eulyoo1945: { fontFamily: 'eulyoo1945', label: '을유 1945' },
  hambaknoon: { fontFamily: 'hambaknoon', label: '함박눈' },
  onemobilepop: { fontFamily: 'onemobilepop', label: 'ONE 모바일 POP' },
  onguelryutung: { fontFamily: 'onguelryutung', label: '온글잎 류뚱체' },
  RixInooAriDuri: { fontFamily: 'RixInooAriDuri', label: 'Rix이누아리두리' },
  vitrocore: { fontFamily: 'vitrocore', label: '비트로 코어' },
  yes24myeongjo: { fontFamily: 'yes24myeongjo', label: '예스 명조' },
}

export const NO_SO_UPLOAD_NAME = 'no_SO_no__SO_no___SO'

export const BANNER_ARTWORK_CONFIG = {
  logoS3path: null,
  humanDepict: '',
  bgDepict: '',
}

export const LANGUAGES = ['ko', 'en'] // 안정적으로 번역, 이미지리소스 등이 모두 마련된 경우 여기로 ..
export const ENGLISH_LANGUAGES = ['en', 'ja'] // 일부만 번역되어 베이스는 영어로 제공되어야하는 경우 여기에만

export const IMP_CODE = 'imp68713786' // 포트원 가맹점 코드
export const IMP_PG_ACCOUNT_ID = process.env.REACT_APP_IMP_PG_ACCOUNT_ID
export const IMP_NOTICE_URL = `${configData.DB_URL}/payment/imp_paid` // 포트원 웹훅 url

export const GUIDE_IMAGES = {
  upload: {
    original: '/static/images/배경생성_2.gif',
    draphed: ['/static/images/0-1.gif', '/static/images/0-1.gif', '/static/images/0-1.gif'],
  },

  removebg: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/0-1.gif',
      '/static/images/showcase/showcase5.png',
    ],
  },

  bundle: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/0-1.gif',
      '/static/images/showcase/showcase5.png',
    ],
  },

  bg_expansion: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  modelbg: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  face: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  mannequin: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  banner: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  default: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },
}

// 가로형
export const GUIDE_VIDEOS = {
  upload: {
    ko: '/static/video/guide/upload_ko.mp4',
    en: '/static/video/guide/upload_en.mp4',
    ja: '/static/video/guide/upload_en.mp4',
  },
  removebg: {
    ko: '/static/video/guide/removebg_ko.mp4',
    en: '/static/video/guide/removebg_ko.mp4',
    ja: '/static/video/guide/removebg_ko.mp4',
  },
  bundle: {
    ko: '/static/video/guide/bundle_ko.mp4',
    en: '/static/video/guide/bundle_en.mp4',
    ja: '/static/video/guide/bundle_en.mp4',
  },
  bg_expansion: {
    ko: '/static/video/guide/bg_expansion_ko.mp4',
    en: '/static/video/guide/bg_expansion_ko.mp4',
    ja: '/static/video/guide/bg_expansion_ko.mp4',
  },
  modelbg: {
    ko: '/static/video/guide/modelbg_ko.mp4',
    en: '/static/video/guide/modelbg_en.mp4',
    ja: '/static/video/guide/modelbg_en.mp4',
  },
  mannequin: {
    ko: '/static/video/guide/mannequin_ko.mp4',
    en: '/static/video/guide/mannequin_en.mp4',
    ja: '/static/video/guide/mannequin_en.mp4',
  },
  face: {
    ko: '/static/video/guide/face_ko.mp4',
    en: '/static/video/guide/face_en.mp4',
    ja: '/static/video/guide/face_en.mp4',
  },
  face_blur: {
    ko: '/static/video/guide/face_blur_ko.mp4',
    en: '/static/video/guide/face_blur_en.mp4',
    ja: '/static/video/guide/face_blur_en.mp4',
  },
  banner: {
    ko: '/static/video/guide/banner_ko.mp4',
    en: '/static/video/guide/banner_en.mp4',
    ja: '/static/video/guide/banner_en.mp4',
  },

  default: {
    ko: '/static/video/guide/upload_ko.mp4',
    en: '/static/video/guide/upload_en.mp4',
    ja: '/static/video/guide/upload_en.mp4',
  },
}

export const MODEL = {
  male: [{ id: 'achs' }, { id: 'gion' }, { id: 'tya' }],
  female: [{ id: 'ega' }, { id: 'bunk' }, { id: 'pons' }],
}

export const PROMPT_SELECT_IMAGE = [
  'background',
  'style',
  'lightning',
  'camera',
  'film',
  'lens',
  'angle',
  'filter',
]

export const PROMPT_BUTTON = {
  common: [
    { label: '상품 종류', value: 'product', required: true },
    { label: '톤', value: 'tone' },
    { label: '스타일', value: 'style' },
    { label: '조명', value: 'lightning' },
    { label: '카메라 종류', value: 'camera' },
    { label: '필름 타입', value: 'film' },
    { label: '각도', value: 'angle' },
    { label: '필터', value: 'filter' },
    // { label: '배경', value: 'background' },
    // { label: '렌즈', value: 'lens' },
  ],
  model: [
    { label: '모델 성별', value: 'gender', required: true },
    { label: '모델 국적', value: 'nation' },
  ],
}

export const PROMPT_OPTIONS = {
  product: [
    { label: '화장품', value: 'cosmetic' },
    { label: '소형 가전', value: 'small appliances' },
    { label: '대형 가전', value: 'large appliances' },
    { label: '생활 잡화', value: 'grocery' },
    { label: '의류', value: 'clothes' },
    { label: '식품', value: 'food' },
    { label: '자동차', value: 'car' },
    { label: '직접입력', value: 'custom' },
  ],
  // background: [
  //   {
  //     label: '화이트 인피니티 스튜디오',
  //     value: 'White Infinity Studio',
  //     src: '',
  //     body: 'dd',
  //     suggest: '',
  //   },
  //   {
  //     label: '소프트 베이지 스튜디오',
  //     value: 'Soft Light Beige Studio',
  //     src: '',
  //     body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //   },
  //   {
  //     label: '그래디언트 스튜디오 배경',
  //     value: ' Gradient Studio Background ',
  //     src: '',
  //     body: 'ddsdfasdfsdf',
  //   },
  //   { label: '반사되는 스튜디오 바닥', value: 'Reflective Floor Studio ', src: '' },
  //   { label: '현대적인 도심 풍경', value: 'Modern Cityscape', src: '' },
  //   { label: '럭셔리 테라스', value: 'Luxury Terrace', src: '' },
  //   { label: '해변 로케이션', value: 'Beachfront Location', src: '' },
  //   { label: '럭셔리 가든', value: 'Luxury Garden', src: '' },
  //   { label: '고급 부티크', value: 'High-End Boutique', src: '' },
  //   { label: '미니멀 아파트', value: 'Minimalist Apartment', src: '' },
  //   { label: '카페 배경', value: 'Cafe Setting', src: '' },
  //   { label: '직접입력', value: 'custom', src: '' },
  // ],
  tone: [
    { label: '파란색', value: 'blue' },
    { label: '빨간색', value: 'red' },
    { label: '노란색', value: 'yellow' },
    { label: '베이지', value: 'beige' },
    { label: '직접입력', value: 'custom' },
  ],

  style: [
    {
      label: '추상 사진',
      value: 'Abstract',
      src: '/static/images/bannerV2/style/style_1.png',
      bodyKo: '색상과 질감을 활용하여 제품의 독창적이고 예술적인 면을 강조하는 이미지.',
      suggestKo: '고급 문구류, 예술 작품, 고급 가전제품',
    },
    {
      label: '아날로그 사진',
      value: 'Analogue',
      src: '/static/images/bannerV2/style/style_2.png',
      bodyKo: '빈티지한 필름 질감과 색상 퇴색을 통해 클래식하면서도 따뜻한 느낌을 주는 사진.',
      suggestKo: '빈티지 스타일 시계, 클래식 가구, 필름 카메라',
    },
    {
      label: '뷰티 사진',
      value: 'Beauty',
      src: '/static/images/bannerV2/style/style_3.png',
      bodyKo:
        '스튜디오에서 촬영된 제품의 고급스러운 느낌을 살려, 텍스처와 빛 반사를 강조하는 이미지.',
      suggestKo: '고급 주얼리, 전자기기, 프리미엄 주방용품',
    },
    {
      label: '캔디드 사진',
      value: 'Candid',
      src: '/static/images/bannerV2/style/style_4.png',
      bodyKo:
        '자연스러운 일상 속에서 제품이 어떻게 활용되는지를 보여주는 사진으로, 편안하고 친근한 분위기를 연출.',
      suggestKo: '커피 머그, 개인용 전자기기, 캐주얼한 홈데코 아이템',
    },
    {
      label: '다큐멘터리 사진',
      value: 'Documentary',
      src: '/static/images/bannerV2/style/style_5.png',
      bodyKo: '현실적이고 진솔한 표현을 통해 제품의 가치와 환경적 의미를 부각하는 사진.',
      suggestKo: '친환경 제품, 재사용 가능한 가정용품, 공정 무역 커피 또는 티',
    },
    {
      label: '글래머 사진',
      value: 'Glamour',
      src: '/static/images/bannerV2/style/style_6.png',
      bodyKo: '제품을 세련되고 매혹적인 분위기로 연출하여, 제품의 고급스러움과 매력을 부각.',
      suggestKo: '프리미엄 가방, 명품 액세서리, 고급 와인이나 술병',
    },
    {
      label: '하이 패션 사진',
      value: 'High Fashion',
      src: '/static/images/bannerV2/style/style_7.png',
      bodyKo: '고급 패션 스타일의 독창적인 연출로 제품을 예술적으로 표현.',
      suggestKo: '디자이너 가구, 패셔너블한 전자기기, 현대적인 인테리어 소품',
    },
    {
      label: '즉석 사진',
      value: 'Instant',
      src: '/static/images/bannerV2/style/style_8.png',
      bodyKo: '폴라로이드 스타일의 레트로한 느낌으로, 친숙하고 자연스러운 이미지를 표현합니다.',
      suggestKo: '빈티지 장난감, 노트북 커버, 레트로 스타일 소품',
    },
    {
      label: '라이프스타일 사진',
      value: 'Lifestyle',
      src: '/static/images/bannerV2/style/style_9.png',
      bodyKo: '일상 속에서 자연스럽게 제품을 사용하는 모습으로, 실용적이고 친숙한 느낌을 줍니다.',
      suggestKo: '식기류, 홈 오피스 용품, 라이프스타일 가전',
    },
    {
      label: '스트리트 패션 사진',
      value: 'Street Fashion',
      src: '/static/images/bannerV2/style/style_10.png',
      bodyKo: '도시적이고 세련된 느낌으로 제품을 표현하여 스타일리시한 분위기를 강조합니다.',
      suggestKo: '아웃도어 기어, 스트릿웨어 액세서리, 개성 있는 개인용 전자기기',
    },
    {
      label: '초현실주의 사진',
      value: 'Surrealist',
      src: '/static/images/bannerV2/style/style_11.png',
      bodyKo: '비현실적이고 환상적인 요소를 통해 제품을 창의적이고 독특하게 연출합니다',
      suggestKo: '예술 작품, 실험적인 디자인 제품, 독특한 홈데코 아이템',
    },
    // { label: '모더니스트 사진', value: 'Modernist', src: '', bodyKo: ' ', suggest: '' },
    // { label: '픽토리얼리스트 사진', value: 'Pictorialist', src: '', bodyKo: ' ', suggest: '' },
    // { label: '폴라로이드 사진', value: 'Polaroid', src: '', bodyKo: ' ', suggest: '' },
    // { label: '핀홀 사진', value: 'Pinhole', src: '', bodyKo: ' ', suggest: '' },
    // { label: '대형 포맷 사진', value: 'Large Format', src: '', bodyKo: ' ', suggest: '' },
    // { label: '틴타입 사진', value: 'Tintype', src: '', bodyKo: ' ', suggest: '' },
  ],

  lightning: [
    {
      label: '반사광',
      value: 'Bounce Lighting',
      src: '/static/images/bannerV2/lightning/lightning_1.png',
      bodyKo:
        '주변 벽이나 천장에서 반사된 부드러운 빛을 사용하여 제품에 자연스럽고 은은한 느낌을 줍니다.',
    },
    {
      label: '촛불',
      value: 'Candlelight',
      src: '/static/images/bannerV2/lightning/lightning_2.png',
      bodyKo: '따뜻하고 부드러운 촛불빛으로 제품에 로맨틱하고 친근한 느낌을 더합니다.',
    },
    {
      label: '키아로스쿠로',
      value: 'Chiaroscuro',
      src: '/static/images/bannerV2/lightning/lightning_3.png',
      bodyKo: '밝은 하이라이트와 강한 그림자를 대비시켜 드라마틱하고 예술적인 분위기를 연출합니다.',
    },
    {
      label: '시네마틱 조명',
      value: 'Cinematic Lighting',
      src: '/static/images/bannerV2/lightning/lightning_4.png',
      bodyKo:
        '영화적인 효과의 조명으로 제품에 깊이와 무드를 더해 시선을 사로잡는 이미지를 만듭니다.',
    },
    {
      label: '부드러운 확산광 ',
      value: 'Soft Diffused Lighting',
      src: '/static/images/bannerV2/lightning/lightning_5.png',
      bodyKo: '큰 확산광으로 부드럽고 고른 조명을 만들어 제품의 자연스러운 질감을 살립니다.',
    },
    {
      label: '엣지 라이트',
      value: 'Edge Lighting',
      src: '/static/images/bannerV2/lightning/lightning_6.png',
      bodyKo: '제품의 실루엣을 부각시키는 엣지 라이트로 고급스럽고 세련된 이미지를 연출합니다.',
    },
    {
      label: '고든 아워',
      value: 'Golden Hour',
      src: '/static/images/bannerV2/lightning/lightning_7.png',
      bodyKo: '해가 뜨거나 질 때의 따뜻하고 부드러운 빛으로 제품에 로맨틱한 분위기를 더합니다.',
    },
    {
      label: '자연광',
      value: 'Natural Lighting',
      src: '/static/images/bannerV2/lightning/lightning_8.png',
      bodyKo: '자연스러운 햇빛으로 제품을 밝히며, 친근하고 진솔한 느낌을 강조합니다.',
    },
    {
      label: '오버캐스트 조명',
      value: 'Overcast Lighting',
      src: '/static/images/bannerV2/lightning/lightning_9.png',
      bodyKo: '흐린 날의 은은하고 균일한 빛으로 부드럽고 자연스러운 제품 이미지를 연출합니다.',
    },

    // { label: '환경광', value: 'Ambient Lighting', src: '', bodyKo: '' },
    // { label: '주광', value: 'Key Lighting', src: '', bodyKo: '' },
    // { label: '보조광', value: 'Fill Lighting', src: '', bodyKo: '' },
    // { label: '역광', value: 'Backlighting', src: '', bodyKo: '' },
    // { label: '림 라이트', value: 'Rim Lighting', src: '', bodyKo: '' },
    // { label: '강한 빛', value: 'Hard Lighting', src: '', bodyKo: '' },
    // { label: '부드러운 빛', value: 'Soft Lighting', src: '', bodyKo: '' },
    // { label: '로우 키 조명', value: 'Low-Key Lighting', src: '', bodyKo: '' },
    // { label: '하이 키 조명', value: 'High-Key Lighting', src: '', bodyKo: '' },
    // { label: '컬러 조명', value: 'Colored Lighting', src: '', bodyKo: '' },
    // { label: '실제 조명', value: 'Practical Lighting', src: '', bodyKo: '' },
    // { label: '동기 부여 조명', value: 'Motivated Lighting', src: '', bodyKo: '' },
    // { label: '자연광 스튜디오', value: 'Soft Light from Large Window', src: '', bodyKo: '' },
  ],

  angle: [
    {
      label: '위에서',
      value: 'High Angle',
      src: '/static/images/bannerV2/angle/angle_1.png',
      bodyKo:
        '제품을 내려다보는 시점에서 촬영하여, 제품의 상단 및 패키지 디자인을 강조하고, 정리된 느낌을 줍니다.',
    },
    {
      label: '아이 레벨',
      value: 'Eye Level',
      src: '/static/images/bannerV2/angle/angle_2.png',
      bodyKo: '제품과 같은 눈높이에서 자연스럽게 촬영하여 친근하고 직관적인 느낌을 전달합니다.',
    },
    {
      label: '사선 각도',
      value: 'Diagonal Angle',
      src: '/static/images/bannerV2/angle/angle_3.png',
      bodyKo:
        '카메라를 사선으로 기울여 제품의 입체감을 부각하며, 제품의 형태와 디자인을 강조하는 독특한 구도입니다.',
    },
    {
      label: '측면 각도',
      value: 'Side Angle',
      src: '/static/images/bannerV2/angle/angle_4.png',
      bodyKo:
        '제품을 측면에서 촬영하여, 옆모습을 강조하고 디자인의 세부 요소를 부각시키는 구도입니다.',
    },
    {
      label: '45도 각도',
      value: '45-Degree Angle',
      src: '/static/images/bannerV2/angle/angle_5.png',
      bodyKo:
        '카메라를 제품 앞쪽에서 45도로 기울여 촬영하여, 제품의 앞면과 측면을 동시에 보여주는 구도로 입체감을 줍니다.',
    },
  ],

  camera: [
    {
      label: 'Aaton LTR 54',
      value: 'Aaton LTR 54',
      src: '/static/images/bannerV2/camera/camera_1.png',
      bodyKo:
        'S16 필름 카메라로 자연스러운 화면 비네팅과 필름 질감을 활용하여 제품에 빈티지한 느낌을 더합니다.',
    },
    {
      label: 'ARRI ALEXA 65',
      value: 'ARRI ALEXA 65',
      src: '/static/images/bannerV2/camera/camera_2.png',
      bodyKo:
        '65mm 디지털 시네마 카메라로 고해상도와 넓은 다이내믹 레인지가 특징이며, 제품의 섬세한 디테일을 표현하는 데 적합합니다.',
    },
    {
      label: 'Bolex H16',
      value: 'Bolex H16',
      src: '/static/images/bannerV2/camera/camera_3.png',
      bodyKo:
        '16mm 필름 카메라로 빈티지한 필름 질감과 자연스러운 비네팅을 활용하여 따뜻하고 클래식한 분위기를 연출합니다.',
    },
    {
      label: 'RED Digital Cinema Camera',
      value: 'RED Digital Cinema Camera',
      src: '/static/images/bannerV2/camera/camera_4.png',
      bodyKo:
        '고해상도의 디지털 카메라로 선명하고 다이내믹한 이미지를 촬영하며, 제품의 강렬한 컬러와 세부 표현에 적합합니다.h',
    },
    {
      label: 'Canon EOS 5D',
      value: 'Canon EOS 5D',
      src: '/static/images/bannerV2/camera/camera_5.png',
      bodyKo:
        '큰 센서로 얕은 심도와 매끄러운 배경 보케 효과를 연출하며, 제품이 부드럽게 강조됩니다.',
    },
    {
      label: 'Fujifilm X-T4',
      value: 'Fujifilm X-T4',
      src: '/static/images/bannerV2/camera/camera_6.png',
      bodyKo:
        '후지필름 특유의 색감과 필름 시뮬레이션 모드로 풍부한 색상과 자연스러운 톤을 표현합니다.',
    },
    {
      label: 'Hasselblad X1D II',
      value: 'Hasselblad X1D II',
      src: '/static/images/bannerV2/camera/camera_7.png',
      bodyKo:
        '중형 포맷 카메라로 고해상도의 디테일과 부드러운 색조 전환이 특징이며, 제품에 고급스러운 느낌을 부여합니다.',
    },
    {
      label: 'Sony A7III',
      value: 'Sony A7III',
      src: '/static/images/bannerV2/camera/camera_8.png',
      bodyKo: '고급 자동 초점과 다양한 촬영 모드로 자연스러운 질감과 선명한 디테일을 표현합니다.',
    },
    {
      label: 'Leica M3',
      value: 'Leica M3',
      src: '/static/images/bannerV2/camera/camera_9.png',
      bodyKo: '클래식한 라이카 이미지의 고유한 톤과 디테일로 제품에 품격과 깊이를 더합니다.',
    },
    // { label: 'GoPro Hero', value: 'GoPro Hero', src: '', bodyKo: '' },
    // { label: 'Lumix GH5', value: 'Lumix GH5', src: '', bodyKo: '' },
    // { label: 'Pentax 645Z', value: 'Pentax 645Z', src: '', bodyKo: '' },
    // { label: 'Leica T', value: 'Leica T', src: '', bodyKo: '' },
    // { label: 'Diana F+', value: 'Diana F+', src: '', bodyKo: '' },
    // { label: 'Hasselblad 500CM', value: 'Hasselblad 500CM', src: '', bodyKo: '' },
    // { label: 'Holga 120N', value: 'Holga 120N', src: '', bodyKo: '' },
    // { label: 'Kodak Brownie', value: 'Kodak Brownie', src: '', bodyKo: '' },
    // { label: 'Kodak Funsaver', value: 'Kodak Funsaver', src: '', bodyKo: '' },
    // { label: 'Rolleiflex', value: 'Rolleiflex', src: '', bodyKo: '' },
    // { label: 'Polaroid SX-70', value: 'Polaroid SX-70', src: '', bodyKo: '' },
  ],

  film: [
    {
      label: '아그파 비스타',
      value: 'Agfa Vista',
      src: '/static/images/bannerV2/film/film_1.png',
      bodyKo: '강렬하고 과장된 색감을 자랑하는 필름으로, 제품에 생동감과 활기를 더해줍니다.',
    },
    {
      label: '시네스틸 800T',
      value: 'Cinestill 800T',
      src: '/static/images/bannerV2/film/film_2.png',
      bodyKo:
        '저조도 환경에서도 영화 같은 색감과 할레이션 효과를 제공하여, 제품에 고급스럽고 신비로운 느낌을 부여합니다.',
    },
    {
      label: '엑타 100',
      value: 'Ektar 100',
      src: '/static/images/bannerV2/film/film_3.png',
      bodyKo:
        '선명하고 깨끗한 색감이 특징으로, 제품을 진하고 풍부하게 표현하여 선명한 디테일을 강조합니다.',
    },
    {
      label: '일포드 HP5 플러스',
      value: 'Ilford HP5 Plus',
      src: '/static/images/bannerV2/film/film_4.png',
      bodyKo:
        '중간 정도의 필름 그레인과 흑백 톤이 어우러져 고전적이면서도 세련된 이미지를 연출합니다.',
    },
    {
      label: '코닥 비전3',
      value: 'Kodak Vision3',
      src: '/static/images/bannerV2/film/film_5.png',
      bodyKo: '풍부한 컬러 표현력과 부드러운 그레인이 특징으로, 제품에 영화적인 품격을 더해줍니다.',
    },
    {
      label: '로모크롬 컬러 필름',
      value: 'Lomochrome Color Film',
      src: '/static/images/bannerV2/film/film_6.png',
      bodyKo: '예측 불가능한 컬러 변형과 독특한 톤으로 제품에 창의적이고 자유로운 느낌을 더합니다.',
    },
    {
      label: '코닥 포트라 160',
      value: 'Kodak Portra 160',
      src: '/static/images/bannerV2/film/film_7.png',
      bodyKo: '부드러운 색감과 자연스러운 피부 톤을 제공하여, 제품을 따뜻하고 편안하게 표현합니다.',
    },
    {
      label: '코닥 트라이-X 400',
      value: 'Kodak Tri-X 400',
      src: '/static/images/bannerV2/film/film_8.png',
      bodyKo:
        '깊고 풍부한 블랙과 중간 정도의 그레인을 가진 필름으로, 제품에 강렬하고 클래식한 느낌을 부여합니다.',
    },
    {
      label: '벨비아 100',
      value: 'Velvia 100',
      src: '/static/images/bannerV2/film/film_9.png',
      bodyKo: '색채의 강렬함과 뛰어난 디테일 표현이 특징으로, 제품을 생동감 있게 연출합니다.',
    },
    // { label: '코닥 비전3 IMAX', value: 'Kodak Vision3 IMAX', src: '', bodyKo: '' },
    // { label: '필름 그레인', value: 'Film Grain', src: '', bodyKo: '' },
    // { label: '후지컬러 프로', value: 'Fujicolor Pro', src: '', bodyKo: '' },
  ],

  filter: [
    {
      label: '흑백',
      value: 'Black and White',
      src: '/static/images/bannerV2/filter/filter_1.png',
      bodyKo:
        '색상을 제거해 제품의 형태, 질감, 명암을 강조하여 클래식하고 세련된 이미지를 만듭니다.',
    },
    {
      label: '컬러 필터',
      value: 'Color Filter',
      src: '/static/images/bannerV2/filter/filter_2.png',
      bodyKo: '특정 색감을 강조하는 필터로, 제품에 독특한 분위기와 시각적 개성을 더해줍니다.',
    },
    {
      label: '보케',
      value: 'Bokeh',
      src: '/static/images/bannerV2/filter/filter_3.png',
      bodyKo: '제품 뒤쪽 배경을 부드럽게 흐리게 하여, 제품이 돋보이도록 연출하는 효과입니다.',
    },
    {
      label: '그런지 필터',
      value: 'Grunge Filter',
      src: '/static/images/bannerV2/filter/filter_4.png',
      bodyKo: '거친 질감과 색바램 효과를 추가하여 빈티지하고 날것의 느낌을 강조합니다.',
    },
    {
      label: '드리미 헤이즈',
      value: 'Dreamy Haze',
      src: '/static/images/bannerV2/filter/filter_5.png',
      bodyKo: ' 부드러운 흐림과 헤이즈 효과를 통해 몽환적이고 로맨틱한 분위기를 연출합니다.',
    },
    {
      label: '글리치 스타일',
      value: 'Glitch Style',
      src: '/static/images/bannerV2/filter/filter_6.png',
      bodyKo: '왜곡과 색상 이동 효과를 추가하여, 현대적이고 실험적인 이미지를 만듭니다.',
    },
    {
      label: '렌즈 플레어',
      value: 'Lens Flare',
      src: '/static/images/bannerV2/filter/filter_7.png',
      bodyKo: '빛이 렌즈에 닿아 생기는 밝은 플레어로, 제품에 밝고 강렬한 느낌을 더해줍니다.',
    },
    {
      label: '세피아 톤',
      value: 'Sepia Tone',
      src: '/static/images/bannerV2/filter/filter_8.png',
      bodyKo: '오래된 사진과 같은 따뜻한 갈색 톤을 사용하여 빈티지한 느낌을 강조합니다',
    },
    {
      label: '소프트 포커스',
      value: 'Soft Focus',
      src: '/static/images/bannerV2/filter/filter_9.png',
      bodyKo: '제품의 윤곽을 부드럽게 흐리게 하여, 따뜻하고 감성적인 이미지를 만듭니다.',
    },
    {
      label: '비네팅',
      value: 'Vignette',
      src: '/static/images/bannerV2/filter/filter_10.png',
      bodyKo: '화면 가장자리를 어둡게 하여 중앙의 제품에 집중을 유도하는 효과입니다.',
    },
  ],

  lens: [
    { label: '50mm 단초점 렌즈', value: '50mm Prime Lens', src: '' },
    { label: '8mm 어안 렌즈', value: '8mm Fisheye Lens', src: '' },
    { label: '50mm f/1.1 Lens', value: '50mm f/1.1 Lens', src: '' },
    { label: '24-70mm 줌 렌즈', value: '24-70mm Zoom Lens', src: '' },
    { label: '16mm 광각 렌즈', value: '16mm Wide-Angle Lens', src: '' },
    { label: '200mm 망원 렌즈', value: '200mm Telephoto Lens', src: '' },
    { label: '어안 렌즈', value: 'Fisheye Lens', src: '' },
    { label: '아나모픽 렌즈', value: 'Anamorphic Lens', src: '' },
    { label: '85mm 인물 렌즈', value: '85mm Portrait Lens', src: '' },
    { label: '필름 카메라 느낌', value: 'Film Camera Look', src: '' },
    { label: '35mm 렌즈', value: '35mm Lens', src: '' },
    { label: '매크로 렌즈', value: 'Macro Lens', src: '' },
    { label: '틸트-쉬프트 렌즈', value: 'Tilt-Shift Lens', src: '' },
    { label: '소프트 포커스 렌즈', value: 'Soft Focus Lens', src: '' },
    { label: '135mm 망원 렌즈', value: '135mm Telephoto Lens', src: '' },
    { label: '기본 번들 렌즈', value: 'Kit Lens', src: '' },
    { label: '초광각 렌즈', value: 'Ultra-Wide Lens', src: '' },
    { label: '시네마 렌즈', value: 'Cinema Lens', src: '' },
  ],
  gender: [
    { label: '남자', value: 'man' },
    { label: '여자', value: 'woman' },
  ],
  nation: [
    { label: '한국인', value: 'Korean' },
    { label: '백인', value: 'Caucasian' },
    { label: '아시아인', value: 'Asian' },
    { label: '아프리카계 또는 흑인', value: 'African' },
    { label: '히스패닉 / 라티노', value: 'Hispanic / Latino' },
    { label: '중동계', value: 'Middle Eastern' },
    { label: '인도 및 남아시아인', value: 'Indian / South Asian ' },
    { label: '혼혈 및 다문화', value: 'Mixed Race / Ethnically Ambiguous' },
  ],
}

export const PROMPT_VALUE = {
  product: ['cosemetic', 'clothes', 'car', 'custom'],
  background: ['cosemetic', 'clothes', 'car', 'custom'],
}

export const SHAPE_LIST_ADDINFO = {
  background: { avatar: '/static/images/test_sample/a3.webp', title: '메인 배경' },
  inpaint: { avatar: '/static/images/test_sample/a4.jpeg', title: '상품' },
  logo: { avatar: '/static/images/test_sample/a3.webp', title: '로고' },
  image: {
    avatar: '/static/images/test_sample/a5.png',
    title: '이미지 요소',
    children: {
      paste: {
        avatar: '/static/images/test_sample/a4.jpeg',
        title: '상품',
      },
      illustration: {
        avatar: '/static/images/test_sample/a4.jpeg',
        title: '일러스트',
      },
      concept: {
        avatar: '/static/images/test_sample/a4.jpeg',
        title: '컨셉 이미지',
      },
      reference: {
        avatar: '/static/images/test_sample/a4.jpeg',
        title: '레퍼런스',
      },
      inpaint: {
        avatar: '/static/images/test_sample/a4.jpeg',
        title: '커버 레이어',
      },
    },
  },
  text: { avatar: '/static/images/test_sample/a4.jpeg', title: '텍스트' },
  inpaint_mask: { avatar: '/static/images/test_sample/a4.jpeg', title: '커버 레이어' },
}

export const ORDER_LIMIT_SHAPE_TYPES = ['background']

export const BANNERV2_CONFIG_DEFAULT = {
  background: {
    type: 'background',
    background_type: 'mono',
    bg_color: '#000000',
    bg_blur_intensity: 0,

    // inpaint 특성
    bg_inpaint_image_url: '',
    bg_inpaint_prompt: '',
    bg_inpaint_image_bbox: [],
    bg_inpaint_background_image_url: '',
    bg_inpaint_background_image_bbox: [],
    bg_inpaint_background_mask_bbox: [],
    // concept 특성
    bg_concept_prompt: '',
    // reference 특성
    bg_reference_image_url: '',
    bg_reference_image_bbox: [],
  },
  text: {
    type: 'text',
    text_type: 'info',
    text_price_prev_price: '',
    text_price_post_price: '',
    text_line_height: '1.35',
    text_custom: '',
    text_role: 'title',
    linebreak: 'false',
    text_font: 'auto',
    text_size: 50,
    text_list: ['문구를 수정해주세요.'],
    text_horizontal_align: 'center',
    text_vertical_align: 'center',
    text_color: [255, 255, 255, 255],
    text_bg_color: [0, 0, 0, 0],
  },

  image: {
    type: 'image',
    has_main_image: false,
    image_type: 'illustration',
    image_human_prompt: '',
    image_concept_prompt: '',
    image_reference_image: '',
    image_frame_form: 'nukki',
    image_frame_beyond: false,
    image_blur_intensity: 0,
    image_gradation_direction: 'None',

    image_inpaint_img_url: '',
    image_inpaint_bbox: [],
    image_inpaint_prompt: '',
  },
  logo: {
    type: 'logo',
    logo_image_url: '',
  },
  inpaint: { type: 'inpaint', bg_inpaint_image_url: '' },
  inpaint_mask: { type: 'inpaint_mask' },
}
