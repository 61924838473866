import { Box } from '@mui/material'
import { FeatureIntroduction, HowToUse } from 'components'
import { English, Korean } from 'hooks/useLanguage'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { LandingCommonLayout } from 'layouts'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  banner,
  bgGeneration,
  faceGeneration,
  bgRemoval,
  faceChange,
  bundle,
  bgExpansion,
  mannequinToModel,
  modelGeneration,
} from './features'

const FEATURE_LIST = [
  banner,
  bgGeneration,
  faceGeneration,
  bgRemoval,
  faceChange,
  bundle,
  bgExpansion,
  mannequinToModel,
  modelGeneration,
]

export default function Overview() {
  const navigate = useNavigate()
  const { featureId } = useParams()
  const feature = FEATURE_LIST.filter(f => f.id === featureId)[0]
  const { t } = useTranslation()

  if (!FEATURE_LIST.map(f => f.id).includes(featureId) || !feature) {
    window.location.href = '/'
    return
  }

  return (
    <>
      <LandingCommonLayout>
        {/* <Box sx={{ width: '100vw', height: '700px', background: 'gray' }}></Box> */}
        {/* 상단 메인이미지 */}
        <FeatureIntroduction
          name={feature.menuNameKey ? t(`menu.${feature.menuNameKey}`) : null}
          title={feature.title}
          sx={{
            '& .action-button': {
              '&:hover': {
                '& path': {
                  fill: 'url(#paint0_linear_11435_2771)',
                },
              },
            },
          }}
          image={feature.main_image}
          video={feature.video}
          buttonText={
            <>
              <Korean>지금 바로 사용하기</Korean>
              <English>Get started</English>
            </>
          }
          handleClickButton={() => {
            navigate(feature.path)
          }}
        />

        {/* 활용법 */}
        <Box sx={{ mt: { lg: '8.4rem', xs: '6rem' } }}>
          <HowToUse items={feature.howToUse} />
        </Box>
      </LandingCommonLayout>
    </>
  )
}

export const LayoutTail = ({ bgColor = '#202020', tailColor = 'black', radius = '6', sx = {} }) => (
  <Box
    sx={{
      display: 'flex',
      width: { lg: '48.2rem', xs: '36rem' },
      height: { lg: '7.6rem', xs: '4rem' },
      background: tailColor,
      '& span': {
        height: '100%',
        width: '50%',

        '&:nth-of-type(1)': {
          background: bgColor,
          borderRadius: `0 ${radius}rem 0 0`,
        },
        '&:nth-of-type(2)': {
          background: bgColor,
          borderRadius: `${radius}rem 0 0 0`,
        },
      },
      ...sx,
    }}
  >
    <Box component="span"></Box>
    <Box component="span"></Box>
  </Box>
)
