import { Box, InputAdornment, Stack, Typography } from '@mui/material'
import { autoScalingModeAtom, portfolioConfigAtom } from 'atoms'
import { FormErrorMessage } from 'components/FormInput'
import { SmallTextField } from 'components'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { isKo } from 'utils/common'

// const sizeMin = 50
const sizeMax = 2000

export default function OutputField({
  config,
  setConfig,
  setMinSizeWarning,
  setMaxSizeWarning,
  setModified = () => {},
  index,
  list,
  openMarket,
  required = false,
  sizeMinWidth = 50,
  sizeMinHeight = 50,
  sx = {},
}) {
  const { t, i18n } = useTranslation()

  const [placeholderWidth, setPlaceholderWidth] = useState(required ? null : t('outputField.auto'))
  const [placeholderHeight, setPlaceholderHeight] = useState(
    required ? null : t('outputField.auto')
  )

  const [autoScalingMode, setAutoScalingMode] = useRecoilState(autoScalingModeAtom)

  const [error, setError] = useState({ w: false, h: false })

  const handleOutput = (name, index, e) => {
    const { value } = e.target
    const currentIdxConfigWidthValue = config.outputSizeList[index].w
    const currentIdxConfigHeightValue = config.outputSizeList[index].h

    setAutoScalingMode(true)

    if (/^[0-9]*$/.test(value)) {
      if (value.length > 0 && name === 'w' && !currentIdxConfigHeightValue) {
        setError(e => ({ ...e, h: true }))
        setPlaceholderHeight('')
      }

      if (value.length > 0 && name === 'h' && !currentIdxConfigWidthValue) {
        setError(e => ({ ...e, w: true }))
        setPlaceholderWidth('')
      }

      if (value.length < 1) {
        setConfig(prevState => ({
          ...prevState,
          outputSizeList: prevState.outputSizeList.map((item, i) =>
            i === index ? { ...item, [name]: null } : item
          ),
        }))
      } else {
        let size = value
        if (size.length === 4 && Number(size) > sizeMax) {
          size = size.slice(0, -1)
          setMaxSizeWarning(true)
        }
        setConfig(prevState => ({
          ...prevState,
          outputSizeList: prevState.outputSizeList.map((item, i) =>
            i === index ? { ...item, [name]: size } : item
          ),
        }))
      }

      if (name === 'w' && currentIdxConfigHeightValue && value.length) {
        setError({ w: false, h: false })
      }
      if (name === 'h' && currentIdxConfigWidthValue && value.length) {
        setError({ w: false, h: false })
      }

      setModified(true)
    }
  }

  const handleBlur = (name, index, e) => {
    const { value } = e.target

    if (value.trim() === '') {
      setError(e => ({ ...e, [name]: true }))
    } else {
      setError(e => ({ ...e, [name]: false }))
    }

    if (value.length < 1) {
      setMinSizeWarning(false)
      return setConfig(prevState => ({
        ...prevState,
        outputSizeList: prevState.outputSizeList.map((item, i) =>
          i === index ? { ...item, [name]: null } : item
        ),
      }))
    }
    let size = Number(value)

    if (name === 'w') {
      if (size < sizeMinWidth) {
        size = sizeMinWidth
        setMinSizeWarning(true)
      } else {
        setMinSizeWarning(false)
      }
    } else {
      if (size < sizeMinHeight) {
        size = sizeMinHeight
        setMinSizeWarning(true)
      } else {
        setMinSizeWarning(false)
      }
    }

    setConfig(prevState => ({
      ...prevState,
      outputSizeList: prevState.outputSizeList.map((item, i) =>
        i === index ? { ...item, [name]: size } : item
      ),
    }))

    setMaxSizeWarning(false)
  }

  useEffect(() => {
    if (index === 0 && !config.outputSizeList[index].w && !config.outputSizeList[index].h) {
      setPlaceholderHeight(required ? null : t('outputField.auto'))
      setPlaceholderWidth(required ? null : t('outputField.auto'))
      setError({ w: false, h: false })
    }
  }, [config.outputSizeList])

  return (
    <Stack>
      <Box>
        <SmallTextField
          onBlur={e => {
            handleBlur('w', index, e)
          }}
          onFocus={() => setPlaceholderWidth('')}
          placeholder={index === 0 ? placeholderWidth : ''}
          inputProps={{ inputMode: 'decimal', pattern: '[0-9]*', maxLength: 4 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography>{t('upload_config.width')}</Typography>
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">px</InputAdornment>,
          }}
          value={list.w || ''}
          onChange={e => {
            handleOutput('w', index, e)
          }}
          sx={{
            width: { lg: '16.1rem ', xs: isKo(i18n) ? '12rem' : '12.6rem' },
            mr: { lg: '1rem', xs: '1rem' },
            backgroundColor: theme => theme.palette.common.white,
            '& input': {
              height: '1.7rem',
            },
            ...sx,
          }}
          error={error.w}
          disabled={openMarket && index === 0}
        />
        <SmallTextField
          onBlur={e => {
            handleBlur('h', index, e)
          }}
          onFocus={() => setPlaceholderHeight('')}
          placeholder={index === 0 ? placeholderHeight : ''}
          inputProps={{ inputMode: 'decimal', pattern: '[0-9]*', maxLength: 4 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{t('upload_config.height')}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Typography>px</Typography>
              </InputAdornment>
            ),
          }}
          value={list.h || ''}
          onChange={e => {
            handleOutput('h', index, e)
          }}
          sx={{
            width: { lg: '16.1rem ', xs: isKo(i18n) ? '12rem' : '12.6rem' },
            backgroundColor: theme => theme.palette.common.white,
            '& input': {
              height: '1.7rem',
            },
            ...sx,
          }}
          error={error.h}
          disabled={openMarket && index === 0}
        />
      </Box>
    </Stack>
  )
}
