import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import { blogPostsAtom, blogTabAtom, languageAtom } from 'atoms'
import { CenterAlignBox, CenterAlignStack, ScrollToTop, SearchInput } from 'components'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { LandingCommonLayout } from 'layouts'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { SearchIcon } from 'theme/icon'
import { getS3ImageSrc, padZeros, removeTags } from 'utils/common'
import { styled as styledMUI } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Swiper, Autoplay, Navigation, Pagination } from 'swiper'
import EffectPanorama from 'swiper-js-plugins/panorama-slider/dist/effect-panorama.esm.js'
import 'swiper-js-plugins/panorama-slider/dist/effect-panorama.min.css'
import { getStaticResourceLanguage } from 'hooks/useLanguage'

const StyledButton = styledMUI(Button)(({ theme }) => ({
  transition: 'all 0.2s',

  // backgroundColor: 'pink',
  background: 'linear-gradient(90deg, #2C4DFF, #8D00FC, #2C4DFF)',
  backgroundSize: '200% 200%',
  color: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',

  borderRadius: '3rem',

  '&.Mui-disabled': {
    background: '#BBBBBB',

    '#tag': {
      color: '#BBBBBB',
    },
  },

  '& .button-children-wrapper': {
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.2s',
  },

  '&:hover': {
    // animation: 'gradientAnimation 4s ease infinite',
    background: 'white',

    '& .text': {
      background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
      backgroundClip: 'text',
      color: 'transparent',
    },
  },
}))

export function ActionButton({ children, ...props }) {
  return (
    <StyledButton className="action-button" {...props}>
      <div className="button-children-wrapper text">{children}</div>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          opacity: 1,
          background: 'white',
          position: 'absolute',
          zIndex: -1,
        }}
      ></Box>
    </StyledButton>
  )
}

export function FeatureIntroduction({ title, image, video, buttonText, handleClickButton, sx }) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const wrapperStyle = {
    position: 'relative',
    width: { lg: '128rem', xs: '32rem' },
    height: { lg: '60rem' },

    background: 'transparent',

    '& .title-box': {
      position: 'absolute',
      // border: '1px dashed green',
      left: { lg: '4rem', xs: '2rem' },
      top: { lg: '4rem', xs: '2rem' },
      '& .text': {
        color: 'white',
        fontWeight: 400,
        lineHeight: { lg: '5.2rem', xs: '2.8rem' },
        fontSize: { lg: '3rem', xs: '1.6rem' },
        '& .lg-big': {
          fontSize: { lg: '4rem' },
        },
        '& .big': {
          fontSize: { lg: '4rem', xs: '2.2rem' },
        },
        '& .lg-bold': {
          fontWeight: { lg: 800 },
        },
        '& .bold': {
          fontWeight: 800,
        },
      },
    },
    '& .image-box': {
      position: { lg: 'absolute', xs: 'unset' },
      height: { lg: '100%', xs: '36rem' },
      borderRadius: '3rem',
      overflow: 'hidden',

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },

      '& video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '& .button-box': {
      position: { lg: 'absolute', xs: 'unset' },
      mt: { xs: '3.2rem' },
      left: { lg: '50%' },
      bottom: { lg: '2rem' },
      transform: { lg: 'translate(-50%)' },
    },
  }

  return (
    <>
      <CenterAlignStack
        sx={{
          ...sx,
        }}
      >
        {/* 이미지, 타이틀, 버튼 영역 박스 */}
        <Box sx={wrapperStyle}>
          <Box className="image-box" sx={{ width: '100%', height: '100%', position: 'relative' }}>
            {/* <img src={image ?? '/static/sample/dummy.png'} /> */}
            <Desktop>
              {video ? (
                <video preload="metadata" loop autoPlay muted key={video[i18n.language]}>
                  <source src={video[i18n.language]} type="video/mp4" />
                </video>
              ) : (
                // <video src={video[i18n.language]} loop autoPlay muted>
                //   Your browser does not support the video tag.
                // </video>
                <img src={image} />
              )}
            </Desktop>
            <Mobile>
              {video ? (
                <video
                  src={video[i18n.language]?.replace('/video/overview/', '/video/overview/square/')}
                  loop
                  autoPlay
                  muted
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={image} />
              )}
            </Mobile>

            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,

                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 70%)',
              }}
            />
          </Box>
          <Box className="title-box">
            <Typography className="text">{title}</Typography>
          </Box>
          {/* <Box className="button-box">
            <ActionButton
              sx={{
                width: { lg: '40rem', xs: '32rem' },
                height: { lg: '6rem', xs: '4rem' },
                fontSize: { lg: '2.4rem', xs: '1.6rem' },
                fontWeight: 800,
              }}
              onClick={handleClickButton}
            >
              {buttonText}
            </ActionButton>
          </Box> */}
        </Box>

        <Box className="button-box" sx={{ mt: '3.2rem' }}>
          <ActionButton
            sx={{
              width: { lg: '40rem', xs: '32rem' },
              height: { lg: '6rem', xs: '4rem' },
              fontSize: { lg: '2.4rem', xs: '1.6rem' },
              fontWeight: 800,
            }}
            onClick={handleClickButton}
          >
            {buttonText}
          </ActionButton>
        </Box>
      </CenterAlignStack>
    </>
  )
}

export function HowToUse({ items }) {
  const { i18n } = useTranslation()
  return (
    <>
      <CenterAlignStack
        sx={{
          width: { lg: '88rem', xs: '32rem' },
          color: 'white',

          '& .item-wrapper': {
            width: '100%',

            '& .text-box': {
              // border: '1px dashed gold',
              alignItems: { lg: 'center', xs: 'center' },

              width: { lg: '50%', xs: '32rem' },
              height: { lg: '26.4rem', xs: '9.6rem' },
              position: 'relative',

              '& .index': {
                lineHeight: 1.15,
                fontSize: { lg: '16rem', xs: '8rem' },
                fontWeight: 400,
                color: '#FFFFFF',
                opacity: 0.12,
                fontStyle: 'italic',
                position: 'absolute',
              },
              '& .content': {
                // border: '1px dashed pink',
                color: '#FFFFFF',
                lineHeight: 1.4,
                fontSize: { lg: '2rem', xs: '1.6rem' },
                fontWeight: 400,
                '& .bold': {
                  fontWeight: 600,
                },
              },
            },

            '&:nth-of-type(odd)': {
              '& .text-box': {
                justifyContent: { lg: 'center', xs: 'end' },
              },
              '& .text-box .index': {
                left: 0,
                top: 0,
              },
              '& .text-box .content': {
                textAlign: 'right',
              },
            },
            '&:nth-of-type(even)': {
              '& .text-box': {
                justifyContent: { lg: 'center', xs: 'start' },
              },
              flexDirection: { lg: 'row-reverse', xs: 'column' },
              '& .text-box .index': {
                right: 0,
                top: 0,
              },
            },

            '& .image-box': {
              width: { lg: '50%', xs: '32rem' },
              height: { lg: '26.4rem', xs: '19.2rem' },
              '& img': {
                width: '100%',
                height: '100%',
                borderRadius: '2rem',
                objectFit: 'cover',
              },
            },
          },
        }}
        spacing={{ lg: '4rem', xs: '3.2rem' }}
      >
        {items.map((item, idx) => (
          <Stack
            key={idx}
            className="item-wrapper"
            sx={{ flexDirection: { lg: 'row', xs: 'column' } }}
          >
            <CenterAlignBox className="text-box">
              <Typography className="index">{padZeros(idx + 1, 2)}</Typography>
              <Typography className="content">{item.text}</Typography>
            </CenterAlignBox>
            <Box className="image-box">
              <img src={item.image[`${getStaticResourceLanguage(i18n.language)}`]} />
            </Box>
          </Stack>
        ))}
      </CenterAlignStack>
    </>
  )
}

export function TextItemList({ showIndex = true, itemTitle, items, sx }) {
  return (
    <>
      <Stack
        spacing={{ lg: '2.4rem', xs: '1.2rem' }}
        sx={{
          '& .text': {
            color: 'white',
          },

          '& .item-title': {
            background: 'white',
            color: 'black',
            fontSize: { lg: '2rem', xs: '1.2rem' },
            fontWeight: 600,
            lineHeight: 1.2,
            borderRadius: '1rem',
            p: { lg: '0.8rem 1.8rem', xs: '0.7rem 1.15rem' },
            mr: { lg: '1.8rem', xs: '1rem' },
          },
          '& .item-content': {
            fontSize: { lg: '2.2rem', xs: '1.2rem' },
            fontWeight: 400,
            lineHeight: 1.2,
          },
          ...sx,
        }}
      >
        {items.map((item, idx) => (
          <CenterAlignStack direction="row" key={idx}>
            <Typography className="text item-title">
              {itemTitle} {showIndex && idx + 1}
            </Typography>
            <Typography className="text item-content">{item.content}</Typography>
          </CenterAlignStack>
        ))}
      </Stack>
    </>
  )
}

export function ButtonTabs({ tabs, selectedTab, setSelectedTab, sx, ...props }) {
  useEffect(() => {}, [])

  const changeTab = tab => {
    setSelectedTab(tab)
  }
  return (
    <>
      <CenterAlignStack
        sx={{
          '& .tab-button': {
            fontSize: { lg: '2rem' },
            fontWeight: 600,
            background: '#BBBBBB',
            color: theme => theme.palette.common.black,
            '&:hover, &.selected': {
              color: 'white',
              background: theme => theme.palette.draph.newblue,
            },

            '&.selected': {},
          },

          ...sx,
        }}
        {...props}
      >
        {/*  TODO 우선 탭 없이 가고 탭별로 들어갈 항목이 충분히 마련되면 디벨롭 예정 */}
        {/* <Stack direction="row" spacing={{ lg: '1.6rem' }}>
          {tabs.map(t => (
            <Button
              key={t.id}
              variant="contained"
              className={`tab-button ${selectedTab.id === t.id && 'selected'}`}
              onClick={() => {
                changeTab(t)
              }}
            >
              {t.text}
            </Button>
          ))}
        </Stack> */}

        <Box className="content" sx={{}}>
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
            key={selectedTab.id}
          >
            {selectedTab.content}
          </motion.div>
        </Box>
      </CenterAlignStack>
    </>
  )
}

export function ImageTextCard({
  img,
  video,
  videoThumbTime = 0,
  title,
  content,
  to,
  sx,
  ...props
}) {
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const navigate = useNavigate()
  const videoRef = useRef(null)

  const go = () => {
    navigate(to)
  }

  const handleMouseOver = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0
      videoRef.current.play()
    }
  }
  const handleMouseOut = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      videoRef.current.currentTime = videoThumbTime
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoThumbTime
    }
  }, [videoRef])

  return (
    <Box
      className="card"
      onClick={go}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      sx={{
        cursor: to ? 'pointer' : 'default',
        width: '32rem',
        height: '36rem',
        borderRadius: '2rem',
        overflow: 'hidden',
        position: 'relative',

        '&:hover': {
          '& .fg-image': {
            height: '100%',
            transition: 'height 1s',
            '& video': {
              transform: 'scale(1)',
            },
          },
        },

        '& .bg-image': {
          zIndex: -1,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
        '& .cover-layer': {
          borderRadius: '2rem',
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          background: '#FFFFFFD9',
          backdropFilter: 'blur(30px)',
          '& .text-area': {
            py: '3.5rem',
            pl: '3.2rem',
          },
        },
        '& .fg-image': {
          transition: 'height 1s',
          width: '100%',
          height: '22.4rem',
          borderRadius: '2rem',
          overflow: 'hidden',
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
          '& video': {
            borderRadius: '2rem',
            transform: 'scale(1)',
            // transform: 'scale(1.15)',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        },

        '& .text.title': {
          fontSize: '2rem',
          fontWeight: 800,
          lineHeight: 1.2,
        },
        '& .text.content': {
          mt: '0.8rem',
          fontSize: '1.4rem',
          fontWeight: 400,
          lineHeight: 1.2,
        },
        ...sx,
      }}
      {...props}
    >
      {/* 바탕에 깔리는 이미지 (글자부분 뒤로 살짝 비쳐보이는 이미지) */}
      <video src={video} className="bg-image" muted></video>

      <Box className="cover-layer">
        {/* 위쪽에 보이는 이미지 */}
        <Box className="fg-image">
          <video preload="auto" src={video} loop muted ref={videoRef}>
            Your browser does not support the video tag.
          </video>
        </Box>

        <Stack className="text-area">
          <Typography className="text title">{title}</Typography>
          <Typography className="text content">{content}</Typography>
        </Stack>
      </Box>
    </Box>
  )
}

export const Arrow = ({ sx, ...props }) => (
  <Box component="div" sx={{ display: 'flex', ml: { lg: '1rem', xs: '0.8rem' }, ...sx }} {...props}>
    <Desktop>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.465 0.244655C11.0074 -0.111207 10.3456 -0.0788559 9.92504 0.341709L9.82799 0.451617C9.47213 0.90929 9.50448 1.57106 9.92504 1.99162L13.766 5.83333H1.41667C0.772334 5.83333 0.25 6.35567 0.25 7C0.25 7.64433 0.772334 8.16667 1.41667 8.16667H13.766L9.92504 12.0084L9.82799 12.1183C9.47213 12.576 9.50448 13.2377 9.92504 13.6583C10.3807 14.1139 11.1193 14.1139 11.575 13.6583L17.4083 7.82496L17.5053 7.71505C17.8612 7.25738 17.8289 6.59561 17.4083 6.17504L11.575 0.341709L11.465 0.244655Z"
          fill="white"
          // fill="url(#paint0_linear_11435_2771)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_11435_2771"
            x1="0.25"
            y1="7"
            x2="17.75"
            y2="7"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2C4DFF" />
            <stop offset="1" stopColor="#8D00FC" />
          </linearGradient>
        </defs>
      </svg>
    </Desktop>
    <Mobile>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2536 0.823783C9.83519 0.498423 9.23014 0.528001 8.84562 0.912517L8.75689 1.013C8.43153 1.43145 8.46111 2.0365 8.84562 2.42101L12.3573 5.9333H1.06667C0.477563 5.9333 0 6.41086 0 6.99997C0 7.58907 0.477563 8.06664 1.06667 8.06664H12.3575L8.84562 11.5792L8.75689 11.6797C8.43153 12.0981 8.46111 12.7032 8.84562 13.0877C9.26218 13.5042 9.93756 13.5042 10.3541 13.0877L15.6874 7.75434L15.7762 7.65386C16.1015 7.23541 16.072 6.63037 15.6874 6.24585L10.3541 0.912517L10.2536 0.823783Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_11435_2771"
            x1="0.25"
            y1="7"
            x2="17.75"
            y2="7"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2C4DFF" />
            <stop offset="1" stopColor="#8D00FC" />
          </linearGradient>
        </defs>
      </svg>
    </Mobile>
  </Box>
)

export function ImageCarouselSwiper({ images, onClickSlide = () => {}, sx }) {
  const uuid = Math.ceil(Math.random() * 1000)
  const isMobile = useMobileMediaQuery()
  const [swiper, setSwiper] = useState()

  useEffect(() => {
    const sw = new Swiper(`.panorama-slider-${uuid} #swiper-container`, {
      spaceBetween: 20,
      // pass Panorama module
      modules: [EffectPanorama, Autoplay],
      // enable "panorama" effect
      effect: 'panorama',
      slidesPerView: 1.5,
      loop: true,
      loopAdditionalSlides: 1,
      centeredSlides: true,
      grabCursor: true,
      autoplay: { delay: 1, disableOnInteraction: false },
      speed: 6000,
      // pagination: {
      //   el: '.swiper-pagination',
      //   dynamicBullets: true,
      //   dynamicMainBullets: 3,
      // },
      // panorama effect parameters
      panoramaEffect: {
        depth: 150,
        rotate: 45,
      },
      breakpoints: {
        480: {
          slidesPerView: 2,
          panoramaEffect: {
            rotate: 35,
            depth: 150,
          },
        },
        640: {
          slidesPerView: 3,
          panoramaEffect: {
            rotate: 30,
            depth: 150,
          },
        },
        1024: {
          slidesPerView: 4,
          panoramaEffect: {
            rotate: 30,
            depth: 200,
          },
        },
        1200: {
          slidesPerView: 4,
          panoramaEffect: {
            rotate: 20,
            depth: 500,
            // rotate: 25,
            // depth: 450,
          },
        },
      },
    })

    setSwiper(sw)
  }, [])

  useEffect(() => {
    if (swiper) {
      swiper.update()
      swiper.autoplay.start()
    }
  }, [images])
  return (
    <>
      <Box
        className="wrapper"
        sx={{
          overflow: 'hidden',
          height: { lg: 'calc(100vw / 3 - 10rem)', xs: 'calc(100vw / 1.5 - 2rem)' },
          ...sx,
        }}
      >
        <CenterAlignStack
          sx={{
            width: '100%',
            height: { lg: 'calc(100vw / 3)', xs: 'calc(100vw / 1.5)' },
            // height: { lg: '45rem' }
          }}
        >
          <Box
            className={`panorama-slider-${uuid}`}
            sx={{
              width: { lg: '140%', xs: '100%' },
              height: '100%',
              // border: '1px dashed gold',
              // background: 'pink',

              overflow: 'hidden',
              position: 'relative',

              '& .swiper-wrapper': {
                position: 'relative',
                height: '100%',
              },

              '& .swiper-slide': {
                // height: '60rem',
                height: '100%',

                '& .slide-image': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                },
              },
            }}
          >
            <Box id={`swiper-container`} sx={{ height: '100%' }}>
              <Box component="div" className="swiper-wrapper" sx={{}}>
                {images.map((path, i) => (
                  <div
                    className="swiper-slide"
                    key={i}
                    onClick={() => {
                      onClickSlide(path)
                    }}
                  >
                    <img className="slide-image" src={path} />
                  </div>
                ))}
              </Box>
            </Box>
          </Box>
        </CenterAlignStack>
      </Box>
    </>
  )
}
