//   {
//     image: '/static/images/banner/concept.png',
//     style: {
//       width: '14rem',
//       height: '14rem',
//       cursor: 'pointer',
//       borderRadius: '1rem',
//       border:
//         bannerConfig.genType === 'no_SO'
//           ? `3px solid ${theme.palette.draph.blue}`
//           : `3px solid rgba(0,0,0,0)`,
//       transition: 'border 0.3s',
//       boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.1)',
//     },
//     config: 'no_SO',
//     sourceStyle: {
//       alignItems: 'center',
//       width: '14rem',
//       height: '2.8rem',
//       position: 'absolute',
//       borderBottomLeftRadius: '1rem',
//       borderBottomRightRadius: '1rem',
//       bottom: 0,
//       backgroundColor:
//         bannerConfig.genType === 'no_SO' ? theme.palette.draph.blue : theme.palette.common.white,
//       color:
//         bannerConfig.genType === 'no_SO'
//           ? theme.palette.common.white
//           : theme.palette.common.black,
//       transition: 'all 0.3s',
//       fontSize: '1.3rem',
//       lineHeight: 1,
//       fontWeight: 400,
//     },
//     source: 'AI 컨셉 이미지',
//   },

import { Box, Typography, useTheme } from '@mui/material'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { images } from 'test_config'

export default function OutlinedImage({
  imageSrc,
  imageStyleAdd,
  valueKey,
  value,
  boxStyleAdd,
  source,
  sourceStyleAdd,
  config,
  setConfig,
  borderWidth = '3',
  isDisabled = false,
  clickAdd = () => {},
}) {
  const theme = useTheme()

  const handleClick = value => () => {
    if (isDisabled) return
    clickAdd()

    if (!setConfig) return
    if (typeof config === 'object') {
      setConfig(prev => ({ ...prev, [valueKey]: value }))
    } else {
      setConfig(value)
    }
  }

  const diviergingPoint = typeof config === 'object' ? config[valueKey] === value : config === value

  const boxStyle = {
    width: '12rem',
    height: '13.2rem',
    cursor: isDisabled ? 'auto' : 'pointer',
    borderRadius: '1rem',
    border: diviergingPoint
      ? `${borderWidth}px solid ${theme.palette.draph.newBlue}`
      : `${borderWidth}px solid rgba(0,0,0,0)`,
    transition: 'border 0.3s',
    // boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.1)',
    ...boxStyleAdd,
  }
  const sourceStyle = {
    cursor: 'pointer',
    alignItems: 'center',
    width: '12rem',
    height: '2.8rem',
    // position: 'absolute',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    // bottom: 0,
    backgroundColor: diviergingPoint ? theme.palette.draph.newBlue : theme.palette.common.black,
    color: theme.palette.common.white,
    transition: 'all 0.3s',
    fontSize: '1.3rem',
    lineHeight: 1,
    fontWeight: 700,
    ...sourceStyleAdd,
  }

  return (
    <>
      <CenterAlignStack
        sx={{
          width: '7.2rem',
          height: '12rem',
          position: 'relative',
          border: diviergingPoint
            ? `${1}px solid ${theme.palette.draph.newBlue}`
            : `${1}px solid #D9D9D9`,

          borderRadius: '5px',
          overflow: 'hidden',

          '&:hover': {
            border: `1px solid ${theme.palette.draph.newBlue}`,
            '& .icon': {
              visibility: 'visible',
            },
            '& .dim': {
              visibility: 'visible',
            },
            '& .label': {
              color: diviergingPoint ? 'white' : theme.palette.draph.newBlue,
            },
          },

          '& .label': {
            mt: '0.6rem',
            zIndex: 4,
            color: diviergingPoint ? 'white' : '#989898',
            fontSize: '1.4rem',
            lineHeight: '1.8rem',
            fontWeight: diviergingPoint ? 600 : 500,
          },

          '& .selected-color': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',

            display: diviergingPoint ? 'flex' : 'none',
            background: theme.palette.draph.newBlue,
            zIndex: 3,

            '& .icon': {
              background: '#FFFFFF',
              width: '2.4rem',
              height: '2.4rem',
              borderRadius: '50%',
              alignItems: 'center',
            },
          },
        }}
      >
        <Box className="selected-color">
          <CenterAlignBox className="icon">
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.90234 5.09662L5.35834 8.55262L12.2703 1.64062"
                stroke={theme.palette.draph.newBlue}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </CenterAlignBox>
        </Box>
        <Box
          sx={{
            mt: '0.6rem',
            width: '6rem',
            height: '8.4rem',
            borderRadius: '3px',
            overflow: 'hidden',
            position: 'relative',
            '& .dim': {
              visibility: 'hidden',
              width: '100%',
              height: '100%',
              position: 'absolute',
            },
            '& .icon': {
              visibility: 'hidden',
              background: '#FFFFFF80',
              width: '2.4rem',
              height: '2.4rem',
              borderRadius: '50%',
              alignItems: 'center',

              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            },
            '& img': {
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            },
          }}
          onClick={handleClick(value)}
        >
          <Box className="dim" sx={{ background: '#00000066' }} />
          <CenterAlignBox className="icon">
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.90234 5.09662L5.35834 8.55262L12.2703 1.64062"
                stroke="white"
                strokeOpacity="0.7"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </CenterAlignBox>
          {imageSrc && <img src={imageSrc} />}
        </Box>

        <Typography className="label">{source}</Typography>
      </CenterAlignStack>

      {/*     
      <Box sx={{ position: 'relative' }}>
        <img
          src={imageSrc}
          style={{
            width: '12rem',
            height: '10.4rem',
            // height: '14rem',
            cursor: 'pointer',
            borderRadius: '1rem 1rem 0 0',
            objectFit: 'cover',

            ...imageStyleAdd,
          }}
        />
        <Box
          style={boxStyle}
          sx={{ position: 'absolute', top: 0 }}
          onClick={handleClick(value)}
        ></Box>
        <CenterAlignBox sx={sourceStyle} onClick={handleClick(value)}>
          {source}
        </CenterAlignBox>
      </Box> */}
    </>
  )
}
