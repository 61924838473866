import { Box, Divider, OutlinedInput, Stack, Typography, useTheme } from '@mui/material'
import { apis } from 'apis'
import {
  defaultConfigAtom,
  loadingAtom,
  portfolioDetailAtom,
  unusualPortfolioConfigUpdateAtom,
} from 'atoms'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { CreateButton, CustomOutlinedInput } from 'components/CustomStyle'
import { English, Korean } from 'hooks/useLanguage'
import { fontSize } from 'pages/test/Toolbar'
import { useTranslation } from 'react-i18next'
import { BarLoader } from 'react-spinners'
import { useRecoilState, useRecoilValue } from 'recoil'
import { CircleCheckIcon, UploadIcon, UploadIcon2 } from 'theme/icon'
import { isKo } from 'utils/common'

export default function BannerUserInfo({ uploadBannerV2, selectedIds }) {
  const theme = useTheme()
  const { i18n } = useTranslation()
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [isUploadLoading, setIsUploadLoading] = useRecoilState(loadingAtom)

  // const [bannerConfig, setBannerConfig] = useRecoilState(defaultConfigAtom)

  const updateConfig = (name, value) => {
    setBannerConfig(p => {
      return { ...p, [name]: value }
    })
  }

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        p: { lg: '8rem 0 12.8rem 0' },
        width: '100%',

        '& .info-box': {
          width: 'inherit',
        },

        '& .textarea': {
          gap: '4rem',
          flex: 1,
        },

        '& .info-common': {
          fontSize: '2rem',
          fontWeight: 500,
          color: theme.palette.common.white,
          gap: '1.6rem',
        },

        '& .select-option': {
          bgcolor: '#303030',
          borderRadius: '10px',
          width: 'auto',
          height: 'auto',
          color: 'white',
          p: '0.6rem 1.6rem',
          flex: 1,

          '&.multi': {
            p: '1rem 1.6rem',
          },
          '& input': {
            textAlign: 'start',
            fontSize: '2rem',
            fontWeight: 400,
            '&::placeholder': {
              color: '#ABABAB',
            },
          },

          '& textarea': {
            textAlign: 'start',
            fontSize: '2rem',
            fontWeight: 400,
            '&::placeholder': {
              color: '#ABABAB',
            },
          },

          '&.MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              borderColor: 'none',
            },
            '&.Mui-focused fieldset': { border: '1px solid #fff' },
            '&.Mui-focused.Mui-error fieldset': {
              borderColor: 'red',
              borderWidth: '0.1rem',
            },
          },
        },
      }}
    >
      <CenterAlignBox className="info-box">
        <Stack className="textarea">
          <Stack className="info-common">
            <Korean>브랜드 이름은</Korean>
            <English>The brand name is</English>
            <Box sx={{ display: 'flex', gap: '1.6rem', alignItems: 'center' }}>
              <CustomOutlinedInput
                className="select-option"
                value={bannerConfig.brand_name}
                onChange={e => {
                  updateConfig('brand_name', e.target.value)
                }}
                placeholder={isKo(i18n) ? '드랩' : 'Draph'}
              />{' '}
              <Korean>입니다.</Korean>
            </Box>
          </Stack>

          <Stack className="info-common">
            <Korean>브랜드 특징은</Korean>
            <English>The brand feature is</English>
            <Box sx={{ display: 'flex', gap: '1.6rem', alignItems: 'center' }}>
              <CustomOutlinedInput
                className="select-option multi"
                value={bannerConfig.brand_feature}
                onChange={e => {
                  updateConfig('brand_feature', e.target.value)
                }}
                multiline // 추가
                minRows={4}
                placeholder={isKo(i18n) ? '편리해요' : 'convenient'}
              />{' '}
            </Box>
          </Stack>
        </Stack>
        <Divider
          flexItem
          orientation="verical"
          sx={{ border: '0.5px solid #D9D9D9', mx: '4.4rem' }}
        />
        <Stack className="textarea">
          <Stack className="info-common">
            <Korean>제품에 이름은</Korean>
            <English>The product name is</English>
            <Box sx={{ display: 'flex', gap: '1.6rem', alignItems: 'center' }}>
              <CustomOutlinedInput
                className="select-option"
                value={bannerConfig.product_name}
                onChange={e => {
                  updateConfig('product_name', e.target.value)
                }}
                placeholder={isKo(i18n) ? '드랩 아트' : 'Draph Art'}
              />{' '}
              <Korean>입니다.</Korean>
            </Box>
          </Stack>

          <Stack className="info-common">
            <Korean>제품 특징은</Korean>
            <English>The product feature is</English>
            <Box sx={{ display: 'flex', gap: '1.6rem', alignItems: 'center' }}>
              <CustomOutlinedInput
                className="select-option multi"
                value={bannerConfig.product_feature}
                onChange={e => {
                  updateConfig('product_feature', e.target.value)
                }}
                placeholder={isKo(i18n) ? '편리해요' : 'convenient'}
                multiline // 추가
                minRows={4}
                maxRows={6}
              />{' '}
            </Box>
          </Stack>
        </Stack>
      </CenterAlignBox>

      <CreateButton
        sx={{ mt: '8rem', borderRadius: '30px', height: '6.8rem' }}
        disabled={
          !(
            bannerConfig.product_name &&
            bannerConfig.product_feature &&
            bannerConfig.brand_name &&
            bannerConfig.brand_feature
          ) || isUploadLoading
        }
        onClick={() => {
          setIsUploadLoading(true)
          const body = { config: bannerConfig }

          apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
            setPortfolioDetail({ ...portfolioDetail, ...response.data })
          })

          uploadBannerV2()
        }}
      >
        {isUploadLoading ? (
          <BarLoader width={150} />
        ) : (
          <Typography
            className="content"
            sx={{
              fontWeight: 700,
              fontSize: '2.4rem',
              color: theme.palette.common.white,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <UploadIcon
              sx={{ marginRight: '0.5rem', width: '2rem', height: '2rem' }}
              strokeWidth="3"
            />
            {isKo(i18n) ? 'AI 생성하기' : 'Generate'}
          </Typography>
        )}
      </CreateButton>
    </Stack>
  )
}
