import crudAxios from './configs/crud'
import * as cookie from 'utils/cookie'

const getGuestSessionInfo = () => {
  const h = {}

  const sessionToken = cookie.getGuestSession()

  if (sessionToken) {
    h.guest_session = sessionToken
  }

  // const guestAccessToken = cookie.getGuestAccess()

  // if (guestAccessToken) {
  //   h[cookie.guestAccessToken] = guestAccessToken
  // }
  return h
}

export const userAPI = {
  // UserInfo
  getUser: () => {
    return crudAxios.get('/user')
  },
  getCreditPolicy: () => {
    return crudAxios.get('/user/credit_policy')
  },
  updateUser: body => {
    return crudAxios.put('/user', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    })
  },
  createUser: body => {
    return crudAxios.post('/user', { ...body, ...getGuestSessionInfo() })
  },
  // Token
  login: body => {
    return crudAxios.post('/user/login', { ...body, ...getGuestSessionInfo() })
  },
  getRefreshToken: () => {
    return crudAxios.get('/user/refresh_token')
  },
  saveRefreshToken: body => {
    return crudAxios.post('/user/refresh_token', body)
  },
  getCategory: () => {
    return crudAxios.get('/user/product_category')
  },
  invite: body => {
    return crudAxios.post('/user/invite', body)
  },
  getCredit: () => {
    return crudAxios.get('/user/credit')
  },
  getCreditHistory: filter => {
    let url = `/user/credit_history`
    if (filter) url += `?filter=${filter}`
    return crudAxios.get(url)
  },
  getCreditSummary: timeDelta => {
    const url = `/user/credit_summary?d=${timeDelta}`
    return crudAxios.get(url)
  },
  getBespokeHistory: filter => {
    let url = `/user/bespoke_history`
    if (filter) url += `?filter=${filter}`
    return crudAxios.get(url)
  },
  sendServiceFeedback: body => {
    return crudAxios.post('/user/service_feedback', body)
  },
  getUserPreference: () => {
    return crudAxios.get('/user/preference')
  },
  updateUserPreference: body => {
    return crudAxios.put('/user/preference', body)
  },
  findUser: email => {
    return crudAxios.get(`/user/username?email=${email}`)
  },
  postResetPasswordInfo: body => {
    return crudAxios.post('/user/pw_reset_info', body)
  },
  updatePassword: body => {
    return crudAxios.put('/user/password', body)
  },
  checkDuplicetUser: body => {
    return crudAxios.post('/user/check', body)
  },
  getUserSubscription: () => {
    return crudAxios.get(`/user/subscription`)
  },
  promotionTest: () => {
    return crudAxios.post(`/payment/promotion_test`, { promotion_id: 'test', credit_amount: 2 })
  },

  useCoupon: body => {
    return crudAxios.post(`/user/coupon`, body)
  },
  getRefundInfo: id => {
    return crudAxios.get(`/user/refund_info?id=${id}`)
  },
  changeSubscription: body => {
    return crudAxios.put(`/user/subscription`, body)
  },
  cancelChangeSubscription: body => {
    return crudAxios.post(`/user/subscription/cancel_change`, body)
  },
  snsLogin: body => {
    return crudAxios.post(`/user/login_sns`, { ...body, ...getGuestSessionInfo() })
  },
  createSnsUser: body => {
    return crudAxios.post(`/user/sns`, { ...body, ...getGuestSessionInfo() })
  },
  cancelSubscription: body => {
    return crudAxios.post(`/user/subscription/cancel`, body)
  },
  sendCancelSubscriptionFeedback: body => {
    return crudAxios.post(`/user/subscription/cancel_feedback`, body)
  },
  reSubscribe: body => {
    return crudAxios.post(`/user/subscription/re_subscribe`, body)
  },
  applyEvent: (id, body) => {
    return crudAxios.post(`/user/apply_event/${id}`, body)
  },
  checkEvent: id => {
    return crudAxios.get(`/user/check_event/${id}`)
  },
  finishTutorial: body => {
    return crudAxios.post(`/user/finish_tutorial`, body)
  },
  leaveDraph: () => {
    return crudAxios.put(`/user/leave`)
  },
  cancelLeaveDraph: body => {
    return crudAxios.post(`/user/cancel_leave`, body)
  },
  checkPassword: body => {
    return crudAxios.post(`/user/check_pw`, body)
  },
  getUserStat: () => {
    return crudAxios.get(`/user/stat`)
  },
  getPresignedUrl: () => {
    return crudAxios.get(`/user/upload_zip_url`)
  },
  getValidPromotion: eventId => {
    return crudAxios.get(`/user/valid_promotion?event_id=${eventId}`)
  },

  getUserLogoImage: () => {
    return crudAxios.get(`/user/logo`)
  },

  postUserLogoImage: body => {
    return crudAxios.post(`/user/logo`, body)
  },

  checkDuplicateEmail: body => {
    return crudAxios.post(`/user/check_email`, body)
  },
  linkAccount: body => {
    return crudAxios.post(`/user/link_account`, body)
  },
  alert: body => {
    return crudAxios.post(`/user/alert`, body)
  },

  // API Token
  getAPIToken: () => {
    return crudAxios.get(`/user/api_token`)
  },
  addAPIToken: body => {
    return crudAxios.post(`/user/api_token`, body)
  },
  updateAPIToken: (token, body) => {
    return crudAxios.put(`/user/api_token/${token}`, body)
  },
  deleteAPIToken: token => {
    return crudAxios.delete(`/user/api_token/${token}`)
  },
}
