import {
  Button,
  Typography,
  Stack,
  Box,
  Grid,
  TextField,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  Collapse,
  Divider,
} from '@mui/material'
import { apis } from 'apis'
import crudAxios from 'apis/configs/crud'
import { creditPlansAtom, languageAtom, userAtom } from 'atoms'
import axios from 'axios'
import {
  CenterAlignStack,
  NaverProductButton,
  LanguageSelect,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from 'components'
import { generatePaymentUid } from 'pages/Pricing'
import { getNextPaidDay } from 'pricing'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import moment from 'moment'
import { getS3ImageSrc, numberCommaFormatter } from 'utils/common'
import { useNavigate } from 'react-router-dom'
import { NaverCommerceRegister } from 'pages/NaverCommerceRegister'
import { BlogSample } from './BlogSample'
import { LineBannerSwiper } from '../../components/main/LineBannerSwiper'
import {
  AlignBoxCenterIcon,
  AlignBoxLeftIcon,
  AlignBoxRightIcon,
  AlignContentCenterIcon,
  AlignContentLeftIcon,
  AlignContentRightIcon,
  ArrowUpInCircleIcon,
  DropdownArrowIcon,
  CoinStackIcon,
  CouponIcon2,
  ImageIcon,
  LanguageIcon,
  LogoutIcon2,
  MypageIcon,
  MypageIcon2,
} from 'theme/icon'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import { AnimatedCardStack, AnimatedCardStack2, ExampleComponent, SelectFont } from './Ga0e'

const tagUrl = 'http://100.109.2.26:6902/tag_emb/?stnc='
const IMG_BASE64 = 'data:image/png;base64,'

const body = {
  // artwork_id: '',
  user_id: 2,
  category: 'food',
  sub_category: 'plate',
  angle: 'perpen',
  so_size: 'small',
  output_w: 500,
  output_h: 1500,
}

function TestPageee() {
  useEffect(() => {}, [])
  return (
    <>
      <ExampleComponent />

      <CenterAlignStack sx={{ p: '2rem' }}>
        <h1>ga0e의 테스트페이지...</h1>
        <br />
        <Divider flexItem />
        <SelectFont />
      </CenterAlignStack>
    </>
  )
}

export default TestPageee
