import {
  FormControl,
  Button,
  Select,
  MenuItem,
  Box,
  Divider,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from '@mui/material'

import { English, Korean } from 'hooks/useLanguage'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CenterAlignStack, GradationButton, TypoSpaceBtn } from '../../components'
import { DropdownArrowIcon } from 'theme/icon'
import { getCurrencyFormatter, numberCommaFormatter, padZeros } from 'utils/common'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { FreeMode, Autoplay } from 'swiper'

import { requestSamples } from './bespoke'
import { LayoutTail } from './Overview'
import { useRecoilValue } from 'recoil'
import { creditPlansAtom } from 'atoms'
import { t } from 'i18next'

SwiperCore.use([FreeMode])

const SAMPLE_RESULTS = [...Array(9)].map((_, index) => {
  return {
    img: `/static/images/overview/bespoke/simple_model_sample/1_${index + 1}.png`,
  }
})

const SAMPLE_RESULTS2 = [...Array(9)].map((_, index) => {
  return {
    img: `/static/images/overview/bespoke/sample2/${index + 1}.png`,
  }
})

const SAMPLE_RESULTS_INTERACTION = [...Array(9)].map((_, index) => {
  return {
    img: `/static/images/overview/bespoke/sample3/${index + 1}.png`,
  }
})

const getDiscountPrice = (original, final) => {
  if (!isNaN(original) && !isNaN(final)) {
    const d = original - final
    const dr = (d / original) * 100
    return { price: d, rate: Math.trunc(dr) }
  }
  return { price: 0, rate: 0 }
}

export default function BespokeService() {
  const isMobile = useMobileMediaQuery()

  return (
    <>
      <CenterAlignStack sx={{ width: { xs: '100%' }, pt: '6.4rem', pb: '17.2rem' }}>
        <CenterAlignStack sx={{ width: { lg: '100rem', xs: '100%' } }}>
          {/* 타입별 가격 카드 */}
          <PricingCard />

          {/* 예시 요청 목록 */}
          <CenterAlignStack
            sx={{ width: '100vw', mt: { lg: '10rem', xs: '4rem' }, position: 'relative' }}
          >
            <SampleRequests />
          </CenterAlignStack>

          {/* 파란 배경 소개글 */}
          <CenterAlignStack
            sx={{
              width: '100vw',
              mt: { lg: '14rem', xs: '6.7rem' },
              background: '#3C53D2',
              py: { lg: '10.3rem', xs: '6.8rem' },
            }}
          >
            <Stack
              direction={isMobile ? 'column' : 'row'}
              sx={{
                width: { lg: '100rem', xs: '32rem' },
                justifyContent: 'space-between',
                position: 'relative',
              }}
            >
              <Box className="intro" sx={{ '& .text': { color: 'white' } }}>
                <Typography
                  className="text"
                  sx={{
                    fontSize: { lg: '3.4rem', xs: '2.4rem' },
                    fontWeight: 700,
                    textAlign: { lg: 'left', xs: 'center' },
                    lineHeight: { lg: 'unset', xs: '3.2rem' },
                  }}
                >
                  안녕하세요. <br /> 생성형 AI서비스 드랩아트를{' '}
                  <Mobile>
                    <br />
                  </Mobile>
                  운영 중인 드랩입니다.
                </Typography>

                <Box
                  sx={{
                    width: { lg: '30rem', xs: '100%' },
                    position: { lg: 'absolute' },
                    right: 0,
                    bottom: 0,
                  }}
                >
                  <img
                    src={`/static/images/overview/bespoke/bespoke_image_sample_1${
                      isMobile ? '_mobile' : ''
                    }.png`}
                  />
                </Box>

                <Typography
                  className="text"
                  sx={{
                    mt: { lg: '3.6rem', xs: '2.8rem' },
                    fontSize: { lg: '1.8rem', xs: '1.4rem' },
                    fontWeight: 400,
                    lineHeight: 1.3,
                  }}
                >
                  유저가 직접 생성하는 서비스인 드랩아트를 운영해보니,
                  <br />
                  가장 크게 느낀점이 있었습니다.
                  <br /> <br />
                  실제 현업에서 바로 사용할 수 있을 정도의 퀄리티를 생산하기 위해서는{' '}
                  <Desktop>
                    <br />
                  </Desktop>
                  AI생성모델을 깊은 수준까지 활용할 수 있어야 한다는 것.
                  <br /> <br />
                  초보자도 사용할 수 있을 정도로 간편한 버전의 생성 모델을
                  <br />
                  드랩아트를 통해 유저에게 제공하고 있지만,
                  <br />
                  <b>한편으로는 훨씬 고품질의 이미지를 필요로 하는 고객이 있다는 것.</b>
                  <br /> <br />
                  드랩아트 스튜디오는{' '}
                  <Desktop>
                    <br />
                  </Desktop>
                  이러한 고품질 이미지에 대한 니즈를 충족하기 위해 시작되었습니다.
                </Typography>
              </Box>
            </Stack>
          </CenterAlignStack>

          {/* 생성 예시 바둑판 1 */}
          <CenterAlignStack sx={{ width: '100%', py: { lg: '12rem', xs: '8rem' } }}>
            <Box
              sx={{
                width: { lg: '100%', xs: '32rem' },
                borderRadius: '3rem',
                height: { lg: '36.2rem', xs: '19.4rem' },
                overflow: 'hidden',
                position: 'relative',
                '& img': {
                  height: '100%',
                  objectFit: 'cover',
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  boxShadow: '0px 481px 4px 0px #0000009C inset',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '& .text': { color: 'white', textAlign: 'center' },
                }}
              >
                <Stack>
                  <Typography
                    className="text"
                    sx={{
                      fontSize: { lg: '3.4rem', xs: '1.8rem' },
                      fontWeight: 700,
                      mb: { lg: '2rem', xs: '0.8rem' },
                    }}
                  >
                    고품질의 이미지를 생성하고 싶으신가요?
                  </Typography>
                  <Typography
                    className="text"
                    sx={{
                      fontSize: { lg: '2rem', xs: '1.3rem' },
                      fontWeight: 400,
                      lineHeight: 1.3,
                    }}
                  >
                    업무에 바로 활용가능할 정도의 고품질 이미지는 <br /> 커머스와 생성모델에 대한
                    이해없이는 만들어 지지 않습니다.
                  </Typography>
                </Stack>
              </Box>

              {/* <img src="/static/images/overview/bespoke/image_grid_1.png" /> */}
              <Box
                sx={{
                  width: { lg: '100rem', xs: '100%' },

                  display: 'inline-grid', // 부모 박스의 너비를 자식에 맞춤
                  gridTemplateColumns: { lg: 'repeat(5, 195.2px)', xs: 'repeat(3, 106.6px)' }, // 열 설정
                  gap: { lg: '8px', xs: '4px' }, // 간격 설정
                  borderRadius: '3rem',
                  overflow: 'hidden',

                  '& .box': {
                    width: { lg: '195.2px', xs: '106.6px' },
                    height: { lg: '115.3px', xs: '62.9px' },

                    '& img': {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    },
                  },
                }}
              >
                {[...Array(15)].map((_, idx) => (
                  <Box className="box" key={idx}>
                    <img
                      src={`/static/images/overview/bespoke/bg_grid/${idx + 1}.png`}
                      loading="lazy"
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </CenterAlignStack>

          <CenterAlignStack
            sx={{ width: '100vw', background: 'white', pb: { lg: '12rem', xs: '8rem' } }}
          >
            <LayoutTail
              bgColor="white"
              tailColor="#161616"
              radius={30}
              sx={{ height: { lg: '4.1rem', xs: '2.5rem' } }}
            />
            <Stack sx={{ width: { lg: '100rem', xs: '32rem' }, pt: { lg: '8rem', xs: '5.5rem' } }}>
              <Typography
                sx={{
                  color: '#161616',
                  fontSize: { lg: '2.8rem', xs: '2.4rem' },
                  lineHeight: { lg: 1.1, xs: 1.4 },
                  fontWeight: 700,
                  mb: { lg: '1.6rem', xs: '2rem' },
                }}
              >
                누가 어떻게 생성하냐에 따라{' '}
                <Mobile>
                  <br />
                </Mobile>
                결과물은 천차만별
              </Typography>
              <Typography
                sx={{
                  color: '#161616',
                  fontSize: { lg: '2rem', xs: '1.3rem' },
                  lineHeight: { lg: '2.8rem', xs: '1.8rem' },
                  fontWeight: 400,
                  '& span': {
                    color: '#2C4DFF',
                    fontWeight: 600,
                  },
                }}
              >
                일반적인 AI모델 제작자는 브랜딩에 대한 이해 없이 누구나 사용 가능한 AI 생성 툴로
                제작합니다. <br />{' '}
                <Mobile>
                  <br />
                </Mobile>
                드랩 스튜디오는 자체적으로 드랩아트를 운영하고 있으며, <br />
                <span>서울대, 카이스트 박사들로 구성된 수준 높은 개발자</span>들과{' '}
                <span>커머스업 실무진이 모든 과정을 핸들링</span>하는 전문 스튜디오입니다.
              </Typography>
            </Stack>

            <ImageGridCol3 items={SAMPLE_RESULTS} />
            <Box
              sx={{
                mt: '2rem',
                background: '#EEEEEE',
                px: { lg: '2.8rem' },
                py: { lg: '1rem', xs: '0.9rem' },
                width: { lg: 'unset', xs: '32rem' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '3rem',
              }}
            >
              <Typography
                sx={{ color: '#505050', fontSize: { lg: '1.6rem', xs: '1.2rem' }, fontWeight: 500 }}
              >
                위 이미지는 모두 드랩 스튜디오 디자이너가 제작하였습니다.
              </Typography>
            </Box>
          </CenterAlignStack>

          {/* 생성 예시 바둑판 2 */}
          <CenterAlignStack
            sx={{
              width: '100%',
              pt: '8rem',
              pb: { lg: '10rem', xs: '8rem' },
              '& .text': {
                color: 'white',
                '&.title': {
                  fontSize: { lg: '2.8rem', xs: '2.4rem' },
                  fontWeight: 700,
                  mb: { lg: '1.6rem', xs: '1.2rem' },
                },
                '&.subtitle': { fontSize: { lg: '2rem', xs: '1.3rem' }, fontWeight: 400 },
              },
            }}
          >
            <Stack
              sx={{
                width: { lg: '100rem', xs: '32rem' },
              }}
            >
              <Typography className="text title">
                단순 상품 사진부터
                <Mobile>
                  <br />
                </Mobile>{' '}
                모델 화보 컷까지
              </Typography>
              <Typography className="text subtitle">
                드랩 스튜디오는 현재 가장 고품질의 AI 이미지를 제공하고 있습니다.{' '}
                <Desktop>
                  <br />
                </Desktop>
                제품 사진, AI 모델까지 한번에 제작이 가능합니다.
              </Typography>
            </Stack>

            <ImageGridCol3 items={SAMPLE_RESULTS2} />

            <Divider
              flexItem
              sx={{
                borderColor: { lg: 'white', xs: 'transparent' },
                borderTopWidth: 0,
                borderBottomWidth: '0.6px',
                mt: '4rem',
                mb: { lg: '6rem', xs: '4rem' },
              }}
            />

            {/* 생성 예시 바둑판 3 */}
            <Stack
              sx={{
                width: { lg: '100rem', xs: '32rem' },
              }}
            >
              <Typography className="text title">
                제품과 상호작용하는 모델도 생성 가능합니다.
              </Typography>
              <Typography className="text subtitle">
                제품을 자연스럽게 들고 있는 AI 모델을 만드는 기술은 쉽게 진행하기 어렵습니다. <br />
                드랩 스튜디오만이 가지는 가장 핵심적인 장점입니다.
              </Typography>
            </Stack>

            <ImageGridCol3 items={SAMPLE_RESULTS_INTERACTION} />
          </CenterAlignStack>

          {/* 작업 진행 과정 안내 */}
          <CenterAlignStack
            sx={{
              width: '100vw',
              py: { lg: '12rem', xs: '8rem' },
              background: '#141C48',
              '& .text': {
                color: 'white',
                '&.title': {
                  fontSize: { lg: '2.8rem', xs: '2.4rem' },
                  fontWeight: 700,
                  mb: { lg: '1.6rem', xs: '1.2rem' },
                },
                '&.subtitle': { fontSize: { lg: '2rem', xs: '1.3rem' }, fontWeight: 400 },
              },
            }}
          >
            <Stack
              sx={{
                width: { lg: '100rem', xs: '32rem' },
              }}
            >
              <Typography className="text title">
                더 고품질의 이미지를{' '}
                <Mobile>
                  <br />
                </Mobile>{' '}
                생성하기 위해
              </Typography>
              <Typography className="text subtitle">
                자체 스튜디오에서 제품을 직접 촬영하여 고품질의 누끼 이미지를 제작합니다.
              </Typography>

              <Box
                sx={{
                  mt: { lg: '4.8rem', xs: '2.4rem' },
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap', // 너비를 넘치면 다음 줄로 넘어가게 함
                  gap: { lg: '16px', xs: '8px' }, // 박스 간의 간격

                  '& .image-row': {
                    width: '100%',
                    justifyContent: 'space-between',
                    '& .image-box': {
                      height: { lg: '39.6rem', xs: '18.2rem' },

                      overflow: 'hidden', // 넘치는 부분 숨김

                      '& img': {
                        height: '100%',
                        width: { lg: 'auto', xs: '100%' },
                        objectFit: 'cover',
                      },
                    },
                    position: 'relative',
                  },

                  '& .image-text': {
                    right: { lg: '20px', xs: '8px' },
                    bottom: { lg: '20px', xs: '7px' },
                    position: 'absolute',
                    textAlign: 'right',
                    color: 'white',
                    lineHeight: { lg: 1.4, xs: 1.15 },
                    '& span': {
                      fontSize: { lg: '2rem', xs: '1.2rem' },
                      background: '#00000099',
                      px: '10px',

                      '&:first-of-type': {
                        borderTopLeftRadius: '1rem',
                        borderTopRightRadius: '1rem',
                        pt: '4px',
                      },
                      '&:last-of-type': {
                        borderTopLeftRadius: '1rem',
                        borderBottomLeftRadius: '1rem',
                        borderBottomRightRadius: '1rem',
                        py: '4px',
                      },
                    },
                  },
                }}
              >
                <Stack className="image-row" direction="row">
                  <Box
                    className="image-box"
                    sx={{
                      width: { lg: 'auto', xs: '13.4rem' },
                    }}
                  >
                    <img src="/static/images/overview/bespoke/studio_image_1.png" />
                  </Box>
                  <Box
                    className="image-box"
                    sx={{
                      width: { lg: 'auto', xs: '17.8rem' },
                      '& img': {
                        objectPosition: { lg: 'unset !important', xs: '-135px top !important' },
                      },
                    }}
                  >
                    <img src="/static/images/overview/bespoke/studio_image_2.png" />
                  </Box>
                  <Typography className="image-text">
                    <span>3D모델링 촬영 장비</span>
                    <br />
                    <span>(4K 카메라88대, Reality Capture, Smart Shooter 3 등)</span>
                  </Typography>
                </Stack>
                <Stack className="image-row" direction="row">
                  <Box
                    className="image-box"
                    sx={{
                      height: { lg: '30.2rem !important', xs: '13.2rem !important' },
                      width: { lg: 'auto', xs: '18.6rem' },
                    }}
                  >
                    <img src="/static/images/overview/bespoke/studio_image_3.png" />
                  </Box>
                  <Box
                    className="image-box"
                    sx={{
                      height: { lg: '30.2rem !important', xs: '13.2rem !important' },
                      width: { lg: 'auto', xs: '12.6rem' },
                    }}
                  >
                    <img src="/static/images/overview/bespoke/studio_image_4.png" />
                  </Box>
                  <Typography className="image-text">
                    <span>다목적용 촬영 장비</span>
                    <br />
                    <span>(SONY 미러리스알파7 S2 카메라, 조명, 크로마장비)</span>
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </CenterAlignStack>

          <CenterAlignStack
            sx={{
              width: '100%',
              py: { lg: '12rem', xs: '8rem' },
            }}
          >
            <Typography
              sx={{
                width: '100%',
                textAlign: { lg: 'left', xs: 'center' },
                color: 'white',
                fontSize: { lg: '2.8rem', xs: '2rem' },
                fontWeight: 600,
                mb: { lg: '4rem', xs: '3.3rem' },
              }}
            >
              작업은 어떻게 진행되나요?
            </Typography>

            <Stack
              direction={isMobile ? 'column' : 'row'}
              sx={{
                width: { lg: '100%', xs: '32rem' },

                justifyContent: 'center',
                alignItems: 'center',

                '& svg': {
                  transform: isMobile ? 'rotate(90deg)' : '',
                },
                '& .progress': {
                  flexDirection: { lg: 'column', xs: 'row' },
                  width: { lg: '30rem', xs: '100%' },
                  height: { lg: 'unset', xs: '6.4rem' },
                  borderRadius: '2rem',
                  overflow: 'hidden',
                  '& .title': {
                    background: '#3A58FF',
                    height: { lg: '6rem', xs: '100%' },
                    width: { lg: '100%', xs: '10.8rem' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: { lg: '2.4rem', xs: '1.6rem' },
                    fontWeight: 600,
                  },
                  '& .content': {
                    px: { lg: '2.6rem', xs: '1.6rem' },
                    flex: { lg: 'unset', xs: 1 },
                    textAlign: 'center',
                    background: '#333333',
                    height: { lg: '12rem', xs: '100%' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: { lg: '2rem', xs: '1.4rem' },
                    fontWeight: 500,
                  },
                },
              }}
              spacing="2rem"
            >
              <Stack className="progress">
                <Typography className="title">상담 및 결제</Typography>
                <Typography className="content">
                  원하는 컨셉, 배경, 종류, 모델 등 자세한 상담을 통한 작업 착수
                </Typography>
              </Stack>
              <svg
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 7L0 14V0L10 7Z" fill="white" />
              </svg>

              <Stack className="progress">
                <Typography className="title">시안 송부</Typography>
                <Typography className="content">
                  구매한 상품에 따라 <br />
                  시안 2~3개 송부 및 확인
                </Typography>
              </Stack>
              <svg
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 7L0 14V0L10 7Z" fill="white" />
              </svg>

              <Stack className="progress">
                <Typography className="title">최종안 송부</Typography>
                <Typography className="content">
                  시안의 방향성을 토대로
                  <br /> 최종안 작업
                </Typography>
              </Stack>
            </Stack>
          </CenterAlignStack>

          <CenterAlignStack
            sx={{
              width: '100vw',
              background: '#202020',
              pb: { lg: '12rem', xs: '8rem' },
              '& .image-box': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                '& .image': {
                  width: { lg: '28rem', xs: '9.4rem' },
                  height: { lg: '40rem', xs: '13.6rem' },
                  background: '#D9D9D9',
                  borderRadius: { lg: '3rem', xs: '0.6rem' },
                  overflow: 'hidden',

                  '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    overflow: 'hidden',
                  },
                },
                '& .label': {
                  display: 'inline-flex',
                  background: '#404040',
                  borderRadius: '3rem',
                  px: { lg: '2.8rem', xs: '1.2rem' },
                  py: { lg: '1rem', xs: '0.5rem' },
                  color: 'white',
                  fontSize: { lg: '1.6rem', xs: '1.2rem' },
                  fontWeight: 500,

                  alignSelf: 'center', // 부모 flex 박스에서 가운데 정렬
                  mt: { lg: '2.8rem', xs: '0.8rem' },

                  '&.result': {
                    background: 'white',

                    '& span': {
                      display: 'inline-block',
                      background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                      backgroundClip: 'text',
                      textFillColor: 'transparent',
                      fontSize: { lg: '1.6rem', xs: '1.2rem' },
                      fontWeight: 700,
                    },
                  },
                },
              },

              '& .icon-box': {
                mx: { lg: '2rem', xs: '0.5rem' },
                height: { lg: '40rem', xs: '13.6rem' },
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <LayoutTail
              bgColor="#202020"
              tailColor="#161616"
              radius={30}
              sx={{ height: { lg: '4.1rem', xs: 0 } }}
            />

            <Stack direction="row" sx={{ pt: '8rem' }}>
              <Box className="image-box">
                <Box className="image">
                  <img src="/static/images/overview/bespoke/example/product_1.png" />
                </Box>
                <Box className="label">제품 누끼 전달</Box>
              </Box>
              <Box className="icon-box">
                <PlusIcon />
              </Box>
              <Box className="image-box">
                <Box className="image">
                  <img src="/static/images/overview/bespoke/example/model_1.png" />
                </Box>
                <Box className="label">모델 느낌 설명</Box>
              </Box>

              <Box className="icon-box">
                <EqualIcon />
              </Box>
              <Box className="image-box">
                <Box className="image">
                  <img src="/static/images/overview/bespoke/example/model_with_product_1.png" />
                </Box>
                <Box className="label result">
                  <span>모델 화보컷</span>
                </Box>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ pt: { lg: '6.8rem', xs: '3.2rem' } }}>
              <Box className="image-box">
                <Box className="image">
                  <img src="/static/images/overview/bespoke/example/product_2.png" />
                </Box>
                <Box className="label">제품 누끼 전달</Box>
              </Box>
              <Box className="icon-box">
                <PlusIcon />
              </Box>
              <Box className="image-box">
                <Box className="image">
                  <img src="/static/images/overview/bespoke/example/model_2.png" />
                </Box>
                <Box className="label">모델 느낌 설명</Box>
              </Box>

              <Box className="icon-box">
                <EqualIcon />
              </Box>
              <Box className="image-box">
                <Box className="image">
                  <img src="/static/images/overview/bespoke/example/model_with_product_2.png" />
                </Box>
                <Box className="label result">
                  <span>모델 화보컷</span>
                </Box>
              </Box>
            </Stack>

            <Stack direction="row" sx={{ pt: { lg: '6.8rem', xs: '3.2rem' } }}>
              <Box className="image-box" sx={{}}>
                <Box
                  className="image"
                  sx={{ width: { lg: '45.6rem !important', xs: '14.8rem !important' } }}
                >
                  <img src="/static/images/overview/bespoke/example/product_3.png" />
                </Box>
                <Box className="label">제품 누끼 전달</Box>
              </Box>
              <Box className="icon-box">
                <ArrowIcon />
              </Box>
              <Box className="image-box">
                <Box
                  className="image"
                  sx={{ width: { lg: '45.6rem !important', xs: '14.8rem !important' } }}
                >
                  <img src="/static/images/overview/bespoke/example/model_with_product_3.png" />
                </Box>
                <Box className="label result">
                  <span>모델 배치컷</span>
                </Box>
              </Box>
            </Stack>
          </CenterAlignStack>

          {/* 비용 절감 */}
          <CenterAlignStack
            sx={{
              width: { lg: '100rem', xs: '32rem' },

              py: { lg: '12rem', xs: '8rem' },
              '& .text': { color: 'white' },
            }}
          >
            <Typography
              className="text"
              sx={{ fontSize: { lg: '2.8rem', xs: '2.4rem' }, fontWeight: 600 }}
            >
              이런 분들께 추천드립니다.
            </Typography>

            <Box
              sx={{
                display: 'inline-grid', // 부모 박스의 너비를 자식에 맞춤
                gridTemplateColumns: {
                  lg: 'repeat(3, 28rem)', // 3열 설정
                  xs: 'repeat(2, 15.2rem)', // 2열 설정
                },
                gap: { lg: '2.4rem', xs: '1.6rem' }, // 간격 설정
                mt: { lg: '4rem', xs: '3.2rem' },
                '& .box': {
                  width: { lg: '28rem', xs: '15.2rem' },
                  height: { lg: '14rem', xs: '11.8rem' },
                  background: '#2B2B2B',
                  borderRadius: { lg: '3rem', xs: '1rem' },
                  pt: { lg: '2.2rem', xs: '1.8rem' },
                  pl: { lg: '4rem', xs: '1.6rem' },

                  '& .text': {
                    color: 'white',
                    fontSize: { lg: '2rem', xs: '1.4rem' },
                    lineHeight: 1.3,
                    fontWeight: 500,
                    '&.idx': {
                      fontSize: { lg: '2.4rem', xs: '1.8rem' },
                      mb: '0.7rem',
                      fontWeight: 700,
                    },
                  },
                },
              }}
            >
              {[
                {
                  content: (
                    <>
                      모델이 필요한데 <br /> 비용/시간이 없는 분
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      합리적 비용으로{' '}
                      <Mobile>
                        <br />
                      </Mobile>
                      고품질{' '}
                      <Desktop>
                        <br />
                      </Desktop>
                      이미지를{' '}
                      <Mobile>
                        <br />
                      </Mobile>
                      원하시는 분
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      제품 홍보 효과 UP! <br />
                      호감형 모델이{' '}
                      <Mobile>
                        <br />
                      </Mobile>
                      필요한 분
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      스튜디오 촬영{' '}
                      <Desktop>
                        <br />
                      </Desktop>
                      비용{' '}
                      <Mobile>
                        <br />
                      </Mobile>
                      부담이 크신 분
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      비현실적인 이미지 <br />
                      제작이 필요한 분
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      해외/야외 촬영이
                      <br />
                      부담되는 분
                    </>
                  ),
                },
              ].map((item, idx) => (
                <Box className="box" key={idx}>
                  <Typography className="text idx">{padZeros(idx + 1, 2)}</Typography>
                  <Typography className="text">{item.content}</Typography>
                </Box>
              ))}
            </Box>

            <Box sx={{ mt: { lg: '12rem', xs: '8rem' } }}>
              <Typography
                className="text"
                sx={{
                  textAlign: 'center',
                  fontSize: { lg: '2.8rem', xs: '2rem' },
                  fontWeight: 400,
                  '& span': { fontWeight: 700, fontSize: { lg: '2.8rem', xs: '2.4rem' } },
                }}
              >
                확실하게 말씀드릴 수 있습니다.
                <br />
                <span>
                  최소 100만원 이상의{' '}
                  <Mobile>
                    <br />
                  </Mobile>
                  비용을 아낄 수 있습니다.
                </span>
              </Typography>
            </Box>

            <Box
              sx={{
                mt: '3.6rem',
                width: { lg: '88.8rem', xs: '32rem' },
                background: '#2B2B2B',
                borderRadius: { lg: '3rem', xs: '1rem' },
                px: { lg: '7.6rem', xs: 0 },
                py: { lg: '4.7rem', xs: '3.6rem' },
                display: 'flex',
                flexDirection: { lg: 'row', xs: 'column' },
                alignItems: 'stretch',
              }}
            >
              <CenterAlignStack
                sx={{
                  pb: { lg: 0, xs: '3.6rem' },
                  '& .text': {
                    px: { lg: '2.8rem', xs: 0 },
                    py: { lg: '0.6rem', xs: 0 },
                    color: 'white',
                    fontSize: { lg: '1.6rem', xs: '1.2rem' },
                    fontWeight: 600,
                    mb: '0.8rem',
                  },
                }}
              >
                <CenterAlignStack sx={{ flexDirection: { lg: 'column', xs: 'row' } }}>
                  <Box
                    sx={{
                      width: { lg: '216px', xs: '128px' },
                      height: { lg: '156px', xs: '93px' },
                      mb: { lg: '3rem', xs: 0 },
                      mr: { lg: 0, xs: '2.4rem' },
                      '& img': { width: '100%', height: '100%', objectFit: 'cover' },
                    }}
                  >
                    <img src="/static/images/overview/bespoke/image1.png" />
                  </Box>
                  <Stack
                    sx={{
                      display: 'flex',
                      alignItems: { lg: 'center', xs: 'left' },
                      width: { lg: 'unset', xs: '10rem' },
                    }}
                  >
                    <Typography className="text">높은 비용의 모델</Typography>
                    <Typography className="text">전문 포토그래퍼 섭외</Typography>
                    <Typography className="text">촬영 스튜디오 발품</Typography>
                    <Typography className="text">계약 준비</Typography>
                    <Typography className="text">소품 준비</Typography>
                  </Stack>
                </CenterAlignStack>

                <Typography
                  sx={{
                    color: 'white',
                    mt: '2rem',
                    fontSize: { lg: '1.8rem', xs: '1.6rem' },
                    fontWeight: 500,
                    '& span': {
                      fontSize: { lg: '2rem', xs: '1.6rem' },
                      fontWeight: 800,
                      color: '#161616',
                      background: '#FFA7A7',
                      borderRadius: '1rem',
                      px: '1.4rem',
                      py: '0.6rem',
                    },
                  }}
                >
                  기타 비용 합쳐서&nbsp;&nbsp;
                  <span>최소 수백만원</span>
                </Typography>
              </CenterAlignStack>

              <CenterAlignStack sx={{ flex: 1, justifyContent: 'center' }}>
                <Desktop>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="20" cy="20" r="20" fill="white" />
                    <path
                      d="M30 20L15 28.6603L15 11.3397L30 20Z"
                      fill="url(#paint0_linear_14486_944)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_14486_944"
                        x1="20"
                        y1="10"
                        x2="20"
                        y2="30"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#2C4DFF" />
                        <stop offset="1" stopColor="#8D00FC" />
                      </linearGradient>
                    </defs>
                  </svg>
                </Desktop>

                <Mobile>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="14" cy="14" r="14" transform="rotate(90 14 14)" fill="white" />
                    <path
                      d="M14 21L7.93782 10.5L20.0622 10.5L14 21Z"
                      fill="url(#paint0_linear_15030_936)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_15030_936"
                        x1="21"
                        y1="14"
                        x2="7"
                        y2="14"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#2C4DFF" />
                        <stop offset="1" stopColor="#8D00FC" />
                      </linearGradient>
                    </defs>
                  </svg>
                </Mobile>
              </CenterAlignStack>

              <CenterAlignStack
                sx={{
                  '& .text': {
                    px: { lg: '2.8rem', xs: 0 },
                    py: { lg: '0.6rem', xs: 0 },
                    color: 'white',
                    fontSize: { lg: '1.6rem', xs: '1.2rem' },
                    fontWeight: 600,
                    mb: '0.8rem',
                  },
                }}
              >
                <CenterAlignStack
                  sx={{ flexDirection: { lg: 'column', xs: 'row' }, mt: { lg: 0, xs: '2.8rem' } }}
                >
                  <Box
                    sx={{
                      width: { lg: '216px', xs: '128px' },
                      height: { lg: '156px', xs: '93px' },
                      mb: { lg: '3rem', xs: 0 },
                      mr: { lg: 0, xs: '2.4rem' },
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',

                      '& img': {
                        width: { lg: '60px', xs: '48px' },
                        height: { lg: '60px', xs: '48px' },
                      },
                    }}
                  >
                    <img src="/static/images/overview/bespoke/image2.png" />
                  </Box>
                  <Stack
                    sx={{
                      display: 'flex',
                      alignItems: { lg: 'center', xs: 'left' },
                      width: { lg: 'unset', xs: '10rem' },
                    }}
                  >
                    <Typography className="text">모델 비용 X</Typography>
                    <Typography className="text">촬영 X</Typography>
                    <Typography className="text">스튜디오 X</Typography>
                    <Typography className="text">계약 X</Typography>
                    <Typography className="text">소품 준비 X</Typography>
                  </Stack>
                </CenterAlignStack>

                <Typography
                  sx={{
                    color: 'white',
                    mt: '2rem',
                    fontSize: { lg: '1.8rem', xs: '1.6rem' },
                    fontWeight: 500,
                    '& span': {
                      fontSize: { lg: '2rem', xs: '1.6rem' },
                      fontWeight: 800,
                      color: '#161616',
                      background: '#00E8B9',
                      borderRadius: '1rem',
                      px: '1.4rem',
                      py: '0.6rem',
                    },
                  }}
                >
                  ALL-IN-ONE으로 &nbsp;&nbsp;
                  <span>100,000원</span>
                </Typography>
              </CenterAlignStack>
            </Box>
          </CenterAlignStack>

          {/* 타입별 가격 카드 (최상단과 동일) */}
          <CenterAlignStack
            sx={{ width: '100vw', background: '#202020', pt: '8rem', pb: '9.2rem' }}
          >
            <Typography
              sx={{
                color: 'white',
                textAlign: 'center',
                fontSize: { lg: '2.8rem', xs: '2rem' },
                fontWeight: { lg: 600, xs: 400 },
                mb: '4rem',
                '& span': {
                  fontSize: { lg: '2.8rem', xs: '2.4rem' },
                  fontWeight: { lg: 600, xs: 700 },
                },
              }}
            >
              지금 드랩 스튜디오를 이용하시면 <br />
              <span>
                선착순 100분에게{' '}
                <Mobile>
                  <br />
                </Mobile>
                50% 할인 혜택을 드립니다.
              </span>
            </Typography>
            <Box sx={{ width: { lg: '100rem', xs: '100%' } }}>
              <PricingCard bgColor="#202020" />
            </Box>
          </CenterAlignStack>

          {/* 자주 묻는 질문 */}
          <FAQ />

          {/* 주의사항 */}
          <Box
            sx={{
              width: { lg: '100%', xs: '32rem' },
              borderRadius: '1rem',
              background: '#202020',
              p: { lg: '4rem', xs: '2rem' },
              mt: { lg: '8rem', xs: '5.9rem' },
              '& .text': { color: '#C3C3C3' },
            }}
          >
            <Typography
              className="text"
              sx={{ fonatSize: { lg: '2rem', xs: '1.6rem' }, lineHeight: 1, fontWeight: 800 }}
            >
              주의사항
            </Typography>
            <Divider
              sx={{
                borderColor: '#C3C3C3',
                borderTopWidth: 0,
                borderBottomWidth: '0.5px',
                my: { lg: '2rem', xs: '1.6rem' },
              }}
            />

            <Box
              sx={{
                pl: '0.8rem',
                '& .li': {
                  position: 'relative',
                  paddingLeft: { lg: '1.5rem', xs: '0.8rem' }, // 불렛과 텍스트 간의 간격
                  marginBottom: '0.75rem', // 리스트 아이템 간의 간격
                  '&::before': {
                    content: '"•"', // 불렛 포인트
                    position: 'absolute',
                    left: { lg: 0, xs: '-4px' },
                    top: { lg: '3px', xs: '-2px' }, // 불렛을 2px 위로 이동
                    color: '#c3c3c3', // 불렛 색상
                    fontSize: '1.3rem', // 불렛 크기 조정 (필요 시)
                  },
                },
              }}
            >
              {[
                '상황에 따라 사전 공지 없이 할인이 조기 마감되거나 연장될 수 있습니다.',
                '딥페이크, 일반 또는 연예인 합성 등 비윤리적인 작업은 진행하지 않습니다.',
                '선정적이거나 신체 노출이 과도한 작업은 진행하지 않습니다.',
              ].map((item, idx) => (
                <Box className="li" key={idx}>
                  <Typography
                    className="text"
                    sx={{
                      lineHeight: { lg: 1.5, xs: 1.2 },
                      fontSize: { lg: '1.6rem', xs: '1.2rem' },
                      fontWeight: 400,
                    }}
                  >
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          {/* 환불규정 */}
          <Box
            sx={{
              width: { lg: '100%', xs: '32rem' },
              background: '#202020',
              p: { lg: '4rem', xs: '2rem' },
              mt: { lg: '8rem', xs: '5.9rem' },
              borderRadius: '1rem',
              '& .text': { color: '#C3C3C3' },
            }}
          >
            <Typography className="text" sx={{ fonatSize: '2rem', fontWeight: 800 }}>
              환불규정
            </Typography>
            <Divider
              sx={{
                borderColor: '#C3C3C3',
                borderTopWidth: 0,
                borderBottomWidth: '0.5px',
                my: { lg: '2rem', xs: '1.6rem' },
              }}
            />

            <Box
              sx={{
                pl: '0.8rem',
                '& .li': {
                  position: 'relative',
                  paddingLeft: { lg: '1.5rem', xs: '0.8rem' }, // 불렛과 텍스트 간의 간격
                  marginBottom: '0.75rem', // 리스트 아이템 간의 간격
                  '&::before': {
                    content: '"•"', // 불렛 포인트
                    position: 'absolute',
                    left: { lg: 0, xs: '-4px' },
                    top: { lg: '3px', xs: '-2px' }, // 불렛을 2px 위로 이동
                    color: '#c3c3c3', // 불렛 색상
                    fontSize: '1.3rem', // 불렛 크기 조정 (필요 시)
                  },
                },
              }}
            >
              {[
                '작업이 진행된 이후에는 환불이 되지 않습니다.',
                '환불을 원하시는 경우, 채팅을 통해 상담 후 진행 가능합니다.',
                '원하는 방향으로 제작 불가능한 경우, 디자이너를 통한 환불이 진행될 수 있습니다.',
                '최종 결과물의 퀄리티로 인한 환불은 불가능합니다.',
              ].map((item, idx) => (
                <Box className="li" key={idx}>
                  <Typography
                    className="text"
                    sx={{
                      lineHeight: { lg: 1.5, xs: 1.2 },
                      fontSize: { lg: '1.6rem', xs: '1.2rem' },
                      fontWeight: 400,
                    }}
                  >
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </CenterAlignStack>
      </CenterAlignStack>
    </>
  )
}

const getOriginalPrice = p => 2 * p
function PricingCard({ bgColor = '#161616' }) {
  const plans = useRecoilValue(creditPlansAtom)
  const pricing = plans.bespoke

  const [selectType, setSelectType] = useState(pricing[0])
  const [selectOpen, setSelectOpen] = useState(false)
  const [currency, setCurrency] = useState('krw')
  const { i18n } = useTranslation()

  const currencyFormatter = getCurrencyFormatter(currency)
  const isMobile = useMobileMediaQuery()

  const buttonStyle = {
    height: { lg: '5.6rem', xs: '4rem' },
    width: { lg: '18.4rem', xs: '12.4rem' },
    fontSize: { lg: '2rem', xs: '1.6rem' },
    fontWeight: 800,
  }

  useEffect(() => {
    if (i18n.language === 'en') {
      setCurrency('usd')
    } else {
      setCurrency('krw')
    }
  }, [i18n.language])

  const handleChange = event => {
    const p = pricing.filter(x => x.id === event.target.value)[0]
    if (p) {
      setSelectType(p)
    }
  }

  return (
    <Box
      sx={{
        width: { lg: '100%', xs: '32rem' },
        margin: 'auto',

        display: { lg: 'flex', xs: 'block' },
        position: 'relative',
        overflow: 'hidden',
        '& .box': {
          py: { lg: '5.4rem' },
          backgroundColor: '#303030',
          borderRadius: '1.1rem',
        },
      }}
    >
      {/* 왼쪽(모바일은 상단) 박스 */}
      <Box
        className="box"
        sx={{
          pl: { lg: '5.2rem', xs: '3rem' },
          pt: { lg: 0, xs: '3.25rem' },
          pb: { lg: 0, xs: '3.8rem' },
          width: { lg: '46.6rem', xs: '100%' },
          borderBottomLeftRadius: { lg: '1.1rem !important', xs: '0 !important' },
          borderBottomRightRadius: { lg: '1.1rem !important', xs: '0 !important' },
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontSize: { lg: '2.8rem', xs: '2rem' },
            fontWeight: 800,
            mb: { lg: '2.4rem', xs: '1.25rem' },
          }}
        >
          AI 상품 썸네일, AI 모델 이미지
          <br /> 고품질 제작
        </Typography>

        <FormControl
          variant="outlined"
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },

            '.MuiInputBase-root': {
              borderTopLeftRadius: '2.6rem',
              borderTopRightRadius: '2.6rem',
              borderBottomLeftRadius: selectOpen ? 0 : '2.6rem',
              borderBottomRightRadius: selectOpen ? 0 : '2.6rem',
              transition: 'all 0.1s ease-in-out',
              // width: '26rem',
              width: '26rem',
              height: 'auto',
            },
            '& .MuiSelect-select': {
              fontSize: { lg: '2rem', xs: '1.6rem' },
              fontWeight: 700,
              color: '#161616',
              height: { lg: '4.8rem !important', xs: '4rem !important' },
              display: 'flex',
              alignItems: 'center',
              py: 0,
              pl: '2.8rem',
              pr: '1rem !important',
              justifyContent: 'center',
            },
          }}
        >
          <Select
            value={selectType.id}
            onChange={handleChange}
            open={selectOpen}
            onClose={() => {
              setSelectOpen(false)
            }}
            onOpen={() => {
              setSelectOpen(true)
            }}
            displayEmpty
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .MuiList-root': { py: 0 },
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: '2.6rem',
                  borderBottomLeftRadius: '2.6rem',
                },
              },
            }}
            IconComponent={() => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '2.4rem',
                  height: '2.4rem',
                  mr: '1.6rem',
                  cursor: 'pointer',
                  transform: selectOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.5s ease-in-out',
                }}
                onClick={() => {
                  setSelectOpen(o => !o)
                }}
              >
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.292893 0.292893C-0.0675907 0.653377 -0.0953203 1.22061 0.209705 1.6129L0.292893 1.70711L5.29289 6.70711C5.65338 7.06759 6.22061 7.09532 6.6129 6.7903L6.70711 6.70711L11.7071 1.70711C12.0976 1.31658 12.0976 0.683418 11.7071 0.292893C11.3466 -0.0675907 10.7794 -0.0953203 10.3871 0.209705L10.2929 0.292893L6 4.585L1.70711 0.292893C1.34662 -0.0675907 0.779392 -0.0953203 0.387101 0.209705L0.292893 0.292893Z"
                    fill="#161616"
                  />
                </svg>
              </Box>
            )}
          >
            {pricing.map(item => (
              <MenuItem
                key={item.id}
                value={item.id}
                className="thumb-type-menu-item"
                sx={{
                  height: '4.8rem',
                  fontSize: { lg: '2rem', xs: '1.6rem' },
                  fontWeight: 500,
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#BABABA',
                  '&:hover': { background: '#BABABA' },
                  '&.Mui-selected': {
                    background: '#BABABA !important',
                    '&:hover': { background: '#BABABA' },
                  },
                }}
              >
                <span> {t(`pricing.${item.id}`)}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* 세로 점선 및 움푹 들어간 부분 */}
      <Box
        className="line-wrapper"
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'stretch',
          backgroundColor: '#303030',
          width: { lg: '10px', xs: '100%' },
          height: { lg: 'unset', xs: '10px' },
          justifyContent: 'center',
        }}
      >
        {/* 움푹 들어간 부분 위 (모바일:왼쪽) */}
        <Box
          sx={{
            width: '10px',
            height: '13px',
            backgroundColor: bgColor,
            borderBottomLeftRadius: '3px',
            borderBottomRightRadius: '3px',
            position: 'absolute',
            top: { lg: 0, xs: '-60%' },
            left: { lg: '50%', xs: 0 },
            transform: { lg: 'translateX(-50%)', xs: 'rotate(-90deg)' },
            zIndex: 1,
          }}
        />

        {/* 세로 점선 */}
        <Desktop>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderImage:
                'repeating-linear-gradient(to bottom, #989898 0, #989898 6px, transparent 6px, transparent 12px) 1',
            }}
          />
        </Desktop>
        <Mobile>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              border: 'none',
              height: '1px',
              width: '100%',
              backgroundSize: '12px 1px',
              backgroundImage:
                'repeating-linear-gradient(to right, #989898 0, #989898 6px, transparent 6px, transparent 12px)',
            }}
          />
        </Mobile>

        {/* 움푹 들어간 부분 아래 (모바일:오른쪽) */}
        <Box
          sx={{
            width: '10px',
            height: '13px',
            backgroundColor: bgColor,
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
            position: 'absolute',

            top: { lg: 'unset', xs: '-60%' },
            bottom: { lg: 0, xs: '' },
            left: { lg: '50%', xs: 'unset' },
            right: { lg: 'unset', xs: 0 },
            transform: { lg: 'translateX(-50%)', xs: 'rotate(-90deg)' },
            zIndex: 1,
          }}
        />
      </Box>

      {/* 오른쪽 박스 */}
      <Box
        className="box"
        sx={{
          flex: 1,
          pl: { lg: '6.5rem', xs: '3rem' },
          pt: { lg: 0, xs: '3.2rem' },
          borderTopLeftRadius: { lg: '1.1rem !important', xs: '0 !important' },
          borderTopRightRadius: { lg: '1.1rem !important', xs: '0 !important' },
        }}
      >
        {/* 가격 정보 */}
        <Stack
          sx={{
            width: { lg: '40rem', xs: '26rem' },
            px: '0.6rem',
            pb: { lg: 0, xs: '3.15rem' },
            '& .price-text': {
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',

              '& .text': {
                color: '#BBBBBB',
                fontSize: { lg: '2rem', xs: '1.6rem' },
                '&.field': { fontWeight: 600 },
                '&.value': { fontWeight: 700 },
              },
            },
          }}
        >
          <Stack className="price-text" sx={{ mb: '1.2rem' }}>
            <Typography className="field text">정가</Typography>
            <Typography className="value text" sx={{ textDecoration: 'line-through' }}>
              {/* 할인 전 가격 = 할인 후 가격의 2배로 고정되어있음 */}
              {selectType.price[currency]
                ? numberCommaFormatter(getOriginalPrice(selectType.price[currency]))
                : '-'}
              원
            </Typography>
          </Stack>

          <Stack className="price-text">
            <Typography className="field text">할인 금액</Typography>
            <Typography
              className="value text"
              sx={{ '& .rate': { color: '#00F99F', mr: '1.2rem' } }}
            >
              <span className="rate">
                (-
                {
                  getDiscountPrice(
                    getOriginalPrice(selectType.price[currency]),
                    selectType.price[currency]
                  ).rate
                }
                %)
              </span>
              <span>
                {numberCommaFormatter(
                  getDiscountPrice(
                    getOriginalPrice(selectType.price[currency]),
                    selectType.price[currency]
                  ).price
                )}
                원
              </span>
            </Typography>
          </Stack>

          <Divider flexItem sx={{ borderColor: '#989898', my: '2.8rem' }} />

          <Stack className="price-text">
            <Typography className="field text">현재 판매가</Typography>
            <Typography className="value text" sx={{}}>
              {selectType.price[currency] ? numberCommaFormatter(selectType.price[currency]) : '-'}
              원
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: '100%',
              color: '#A2A2A2',
              alignItems: 'end',
              mt: '0.8rem',
              fontSize: { lg: '1.6rem', xs: '1.4rem' },
              fontWeight: 600,
            }}
          >
            * {t(`pricing.${selectType.id}`)} 제작 시
          </Stack>

          <Stack
            direction="row"
            sx={{ width: '100%', mt: { lg: '5.6rem', xs: '2.4rem' } }}
            spacing={{ lg: '2rem', xs: '1.2rem' }}
          >
            <Button
              variant="contained"
              sx={{
                flex: { lg: 1, xs: 'unset' },
                borderRadius: '0.4rem',
                background: 'white',
                '&:hover': {
                  background: 'white',
                  filter: 'drop-shadow(0px 0px 10px rgba(175, 80, 255, 0.3))',
                },
                '& span': {
                  fontSize: { lg: '2rem', xs: '1.6rem' },
                  fontWeight: 800,
                  background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  color: 'transparent',
                  // 폴백 색상
                  '@supports not (-webkit-background-clip: text)': {
                    color: '#2C4DFF', // 그라데이션 대신 기본 색상 사용
                  },
                },

                ...buttonStyle,
              }}
              onClick={() => {
                window.open('http://draph.channel.io/')
                window.gtag_report_conversion_bespoke_consulting()
                // ----- GA4 event -----
                window.gtag('event', 'famous_person_upload', {})
                // ---------------------
              }}
            >
              <span>상담하기</span>
            </Button>

            <GradationButton
              sx={{
                flex: { lg: 1, xs: 'unset' },
                borderRadius: '0.4rem',
                color: 'white',
                ...buttonStyle,
              }}
              onClick={() => {
                window.open(`${window.location.origin}/pricing`, '_blank').focus()
                window.gtag_report_conversion_bespoke_purchase()
                // ----- GA4 event -----
                window.gtag('event', 'famous_person_download', {})
                // ---------------------
              }}
            >
              결제하기
            </GradationButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

function SampleRequests() {
  const isMobile = useMobileMediaQuery()
  const outerWidth = 1000
  const outerHeight = isMobile ? 232 : 308
  const itemHeight = isMobile ? 40 : 52
  const itemCount = 20

  return (
    <Box
      sx={{
        position: 'relative',
        width: { lg: '100rem', xs: '32rem' },
        height: `${outerHeight}px`,
        overflow: 'hidden',
        borderRadius: '1rem',

        '& .swiper-wrapper': {
          transitionTimingFunction: ' linear !important',
        },
      }}
    >
      {/* 상단 그라데이션 */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '116px',
          background: 'linear-gradient(360deg, rgba(22, 22, 22, 0) 5.62%, #161616 100%)',
          pointerEvents: 'none',
          zIndex: 3,
        }}
      />
      {/* 하단 그라데이션 */}
      <Box
        sx={{
          position: 'absolute',
          bottom: '0',
          width: '100%',
          height: '116px',
          background: 'linear-gradient(360deg, #161616 5.62%, rgba(22, 22, 22, 0) 100%)',
          pointerEvents: 'none',
          zIndex: 3,
        }}
      />
      {/* Swiper 컨테이너 */}
      <Swiper
        direction="vertical"
        slidesPerView="auto"
        spaceBetween={12}
        modules={[Autoplay]}
        loop={true}
        autoplay={{
          delay: 0, // 자동 재생 딜레이를 0으로 설정하여 즉시 다음 슬라이드로 이동
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
        speed={3000} // 슬라이드 전환 속도 (조절 가능)
        allowTouchMove={false} // 사용자 터치로 슬라이드 이동 방지
        style={{ height: '100%', width: '100%', background: 'transparent' }}
      >
        {requestSamples.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              height: `${itemHeight}px`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '1rem',
              background: '#2D2D2D',
              boxSizing: 'border-box',
            }}
          >
            <CenterAlignStack
              direction="row"
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: '1.2rem',

                '& .text': {
                  color: 'white',

                  '&.content': {
                    fontSize: { lg: '1.8rem', xs: '1.4rem' },
                    fontWeight: 500,
                    flex: { xs: 1 },

                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  },
                  '&.email': {
                    fontSize: { lg: '1.5rem', xs: '1.2rem' },
                  },
                },
              }}
            >
              <CenterAlignStack direction="row" sx={{ width: '100%' }}>
                <Box
                  sx={{
                    px: { lg: '2.8rem', xs: '1.2rem' },
                    py: '0.6rem',
                    borderRadius: '1rem',
                    background: '#4A4A4A',
                    mr: '1.2rem',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: { lg: '1.6rem', xs: '1.2rem' },
                      fontWeight: 600,
                    }}
                  >
                    {item.type.includes('model') ? <>AI 모델</> : t(`pricing.${item.type}`)}
                  </Typography>
                </Box>
                <Typography className="text content">{item.content}</Typography>
              </CenterAlignStack>

              <Desktop>
                <Typography className="text email">{item.email}</Typography>
              </Desktop>
            </CenterAlignStack>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

function ImageGridCol3({ items }) {
  return (
    <Box
      sx={{
        width: { lg: '100rem', xs: '32rem' },

        display: 'inline-grid', // 부모 박스의 너비를 자식에 맞춤
        gridTemplateColumns: { lg: 'repeat(3, 32rem)', xs: 'repeat(3, 10.4rem)' }, // 3열 설정
        gap: { lg: '2rem', xs: '0.4rem' }, // 간격 설정

        mt: { lg: '4rem', xs: '2.4rem' },
        '& .box': {
          width: { lg: '32rem', xs: '10.4rem' },
          height: { lg: '18.4rem', xs: '6rem' },
          background: '#D9D9D9',
          borderRadius: { lg: '3rem', xs: '0.6rem' },
          overflow: 'hidden',

          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        },
      }}
    >
      {items.map((item, idx) => (
        <Box className="box" key={idx}>
          <img src={item.img} loading="lazy" />
        </Box>
      ))}
    </Box>
  )
}

const PlusIcon = () => {
  const isMobile = useMobileMediaQuery()
  return isMobile ? (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99998 2.08331C5.2301 2.08331 5.41665 2.26986 5.41665 2.49998V4.5829L7.49998 4.58331C7.7301 4.58331 7.91665 4.76986 7.91665 4.99998C7.91665 5.2301 7.7301 5.41665 7.49998 5.41665L5.41665 5.41623V7.49998C5.41665 7.7301 5.2301 7.91665 4.99998 7.91665C4.76986 7.91665 4.58331 7.7301 4.58331 7.49998V5.41623L2.49998 5.41665C2.26986 5.41665 2.08331 5.2301 2.08331 4.99998C2.08331 4.76986 2.26986 4.58331 2.49998 4.58331L4.58331 4.5829V2.49998C4.58331 2.26986 4.76986 2.08331 4.99998 2.08331Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.33337C20.9205 8.33337 21.6667 9.07957 21.6667 10V18.3317L30 18.3334C30.9205 18.3334 31.6667 19.0796 31.6667 20C31.6667 20.9205 30.9205 21.6667 30 21.6667L21.6667 21.665V30C21.6667 30.9205 20.9205 31.6667 20 31.6667C19.0795 31.6667 18.3333 30.9205 18.3333 30V21.665L10 21.6667C9.07954 21.6667 8.33334 20.9205 8.33334 20C8.33334 19.0796 9.07954 18.3334 10 18.3334L18.3333 18.3317V10C18.3333 9.07957 19.0795 8.33337 20 8.33337Z"
        fill="white"
      />
    </svg>
  )
}

const EqualIcon = () => {
  const isMobile = useMobileMediaQuery()

  return isMobile ? (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50004 3.33331C7.73016 3.33331 7.91671 3.51986 7.91671 3.74998C7.91671 3.9801 7.73016 4.16665 7.50004 4.16665H2.50004C2.26992 4.16665 2.08337 3.9801 2.08337 3.74998C2.08337 3.51986 2.26992 3.33331 2.50004 3.33331L7.50004 3.33331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50004 5.83331C7.73016 5.83331 7.91671 6.01986 7.91671 6.24998C7.91671 6.4801 7.73016 6.66665 7.50004 6.66665H2.50004C2.26992 6.66665 2.08337 6.4801 2.08337 6.24998C2.08337 6.01986 2.26992 5.83331 2.50004 5.83331H7.50004Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 13.3334C30.9205 13.3334 31.6667 14.0796 31.6667 15C31.6667 15.9205 30.9205 16.6667 30 16.6667H10C9.07952 16.6667 8.33333 15.9205 8.33333 15C8.33333 14.0796 9.07952 13.3334 10 13.3334L30 13.3334Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 23.3334C30.9205 23.3334 31.6667 24.0796 31.6667 25C31.6667 25.9205 30.9205 26.6667 30 26.6667H10C9.07952 26.6667 8.33333 25.9205 8.33333 25C8.33333 24.0796 9.07952 23.3334 10 23.3334H30Z"
        fill="white"
      />
    </svg>
  )
}

const ArrowIcon = () => {
  const isMobile = useMobileMediaQuery()
  return isMobile ? (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.672 2.58738C5.50855 2.46028 5.2722 2.47184 5.122 2.62204L5.08734 2.66129C4.96024 2.82475 4.9718 3.06109 5.122 3.21129L6.49379 4.58333H2.08329C1.85317 4.58333 1.66663 4.76988 1.66663 5C1.66663 5.23012 1.85317 5.41667 2.08329 5.41667H6.49379L5.122 6.78871L5.08734 6.82796C4.96024 6.99141 4.9718 7.22776 5.122 7.37796C5.28472 7.54068 5.54854 7.54068 5.71125 7.37796L7.79459 5.29463L7.82925 5.25537C7.95634 5.09192 7.94479 4.85557 7.79459 4.70537L5.71125 2.62204L5.672 2.58738Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6882 10.3495C22.0344 9.84113 21.089 9.88735 20.4882 10.4882L20.3495 10.6452C19.8411 11.299 19.8874 12.2444 20.4882 12.8452L25.9753 18.3333H8.33334C7.41286 18.3333 6.66667 19.0795 6.66667 20C6.66667 20.9205 7.41286 21.6667 8.33334 21.6667H25.9753L20.4882 27.1548L20.3495 27.3118C19.8411 27.9657 19.8874 28.911 20.4882 29.5118C21.139 30.1627 22.1943 30.1627 22.8452 29.5118L31.1785 21.1785L31.3172 21.0215C31.8255 20.3677 31.7793 19.4223 31.1785 18.8215L22.8452 10.4882L22.6882 10.3495Z"
        fill="white"
      />
    </svg>
  )
}
function FAQ() {
  const items = [
    {
      title: <>제품 콘셉트 이미지가 무엇인가요?</>,
      content: <>모델 없이 제품만으로 구성된 사진, 상품 컨셉 사진입니다.</>,
    },
    {
      title: <>모델 + 상품 공간 배치샷이 무엇인가요?</>,
      content: (
        <>모델 옆 적절한 위치에 제품이 있는 사진입니다. 장소를 다양하게 지정할 수 있습니다.</>
      ),
    },
    {
      title: <>제품을 들고 있는 모델샷이 무엇인가요?</>,
      content: <>모델이 제품을 들고 있는 이미지 입니다.</>,
    },
    {
      title: <>상품을 보내면 어떤 점이 좋나요?</>,
      content: (
        <>
          기본적으로 가지고 계신 이미지로 작업을 진행하지만 <br />
          훨씬 더 고 퀄리티의 이미지를 필요로 하신다면, <br />
          제품을 보내주시면 드랩이 촬영부터 제작까지 전담합니다.
        </>
      ),
    },
    {
      title: <>어떤 이미지를 보내드리면 좋을까요?</>,
      content: (
        <>
          최대한 고화질의 이미지를 보내주시고, 여러 각도에서 찍은 이미지를 보내주시면 제작이
          용이합니다.
        </>
      ),
    },
    {
      title: <>비용은 어떻게 되나요?</>,
      content: (
        <>
          현재 [제품 콘셉트 이미지]의 경우 최종 1장 납품당 30,000원입니다. <br />
          [모델 + 상품 공간 배치샷]은 50,000원이며,
          <br />
          [제품을 들고 있는 모델샷]은 100,000원입니다. (VAT 포함)
        </>
      ),
    },
    {
      title: <>제작기간은 어떻게 되나요?</>,
      content: <>전반적으로 근무일 기준 약 3-5일이 소요됩니다.</>,
    },
  ]
  return (
    <>
      <CenterAlignStack sx={{ width: '100%', pt: { lg: '10rem', xs: '8rem' } }}>
        <Typography
          sx={{
            fontSize: { lg: '2.8rem', xs: '2rem' },
            color: 'white',
            fontWeight: 600,
            mb: { lg: '4rem', xs: '2.8rem' },
          }}
        >
          자주 묻는 질문
        </Typography>
        <CenterAlignStack sx={{ width: { lg: '100%', xs: '32rem' } }} spacing={'1.3rem'}>
          {items.map((item, idx) => (
            <FAQItem key={idx} sx={{ width: '100%' }} item={item} />
          ))}
        </CenterAlignStack>
      </CenterAlignStack>
    </>
  )
}

function FAQItem({ item, ...props }) {
  const isMobile = useMobileMediaQuery()
  const [expanded, setExpanded] = useState(false)
  const handleChange = () => {
    setExpanded(x => !x)
  }

  return (
    <Box sx={{ borderRadius: '1rem', overflow: 'hidden' }} {...props}>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        sx={{
          background: '#303030',
          '& .text': {
            color: 'white',
            '&.title': {
              fontSize: { lg: '1.6rem', xs: '1.4rem' },
              fontWeight: 600,
            },
            '&.content': { fontSize: { lg: '1.6rem', xs: '1.2rem' }, fontWeight: 400 },
          },
        }}
      >
        <AccordionSummary
          sx={{
            minHeight: { lg: '5.2rem', xs: '4rem' },
            '&.Mui-expanded': {
              height: { lg: '5.2rem', xs: '4rem' },
              minHeight: { lg: '5.2rem', xs: '4rem' },
            },
            px: { lg: '2.8rem', xs: '2rem' },
            '& .MuiAccordionSummary-content': {
              margin: '0 0',
            },
          }}
          expandIcon={
            isMobile ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.24408 13.0893C4.94367 12.7889 4.92057 12.3162 5.17475 11.9893L5.24408 11.9107L9.41074 7.74408C9.71115 7.44367 10.1838 7.42057 10.5107 7.67475L10.5893 7.74408L14.7559 11.9107C15.0814 12.2362 15.0814 12.7638 14.7559 13.0893C14.4555 13.3897 13.9828 13.4128 13.6559 13.1586L13.5774 13.0893L10 9.5125L6.42259 13.0893C6.12219 13.3897 5.64949 13.4128 5.32258 13.1586L5.24408 13.0893Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.39052 12.3905C7.90988 12.8712 7.87291 13.6275 8.27961 14.1505L8.39052 14.2761L15.0572 20.9428C15.5378 21.4235 16.2941 21.4604 16.8172 21.0537L16.9428 20.9428L23.6095 14.2761C24.1302 13.7554 24.1302 12.9112 23.6095 12.3905C23.1288 11.9099 22.3725 11.8729 21.8495 12.2796L21.7239 12.3905L16 18.1133L10.2761 12.3905C9.7955 11.9099 9.03919 11.8729 8.51613 12.2796L8.39052 12.3905Z"
                  fill="white"
                />
              </svg>
            )
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className="text title">{item?.title}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            background: '#505050',
            px: { lg: '2.8rem', xs: '2rem' },
            py: { lg: '5.2rem', xs: '2.8rem' },
            borderBottomLeftRadius: '1rem',
            borderBottomRightRadius: '1rem',
          }}
        >
          <Typography className="text content">{item?.content}</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
