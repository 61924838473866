import { apis } from 'apis'
import moment from 'moment'
import { getCurrencyFormatter } from 'utils/common'

export const DEFAULT_CURRENCY = 'krw'

export const getDiscountRate = (plans, planId = 'paygo1', currency = 'krw') => {
  const priceOfOne = plans.payGo.filter(p => p.id === 'paygo1')[0].price[currency]

  const allPlans = Object.values(plans).flat()

  const plan = allPlans.filter(p => p.id === planId)[0]

  if (!plan) return 0
  const planPricePerOne = plan.price[currency] / plan.creditAmount

  const d = ((priceOfOne - planPricePerOne) / priceOfOne) * 100
  return Math.round(d)
}

export const getOriginalPrice = (plans, planId = 'paygo1', currency = 'krw') => {
  const priceOfOne = plans.payGo.filter(p => p.id === 'paygo1')[0].price[currency]
  const allPlans = Object.values(plans).flat()
  const plan = allPlans.filter(p => p.id === planId)[0]

  return plan ? priceOfOne * plan.creditAmount : priceOfOne
}

// 플랜에서 가격만

export const getSubscriptionPrice = (currency, price) => {
  const currencyFormatter = getCurrencyFormatter(currency)
  const v = currencyFormatter.format(price).slice(1)
  return v
}

export const getPlanPrice = (plans, planId) => {
  if (!planId) return
  // 간이 예외처리 ㅠㅠ
  if (planId.includes('admin')) {
    const splitPlanId = planId.split('-')
    planId = splitPlanId[0]
  }

  const allPlans = [...plans.subscription, ...plans.payGo]
  const plan = allPlans.find(p => p.id === planId)
  if (!plan) return

  const currency = Object.keys(plan.price)[0]
  const currencyFormatter = getCurrencyFormatter(currency)
  const v = currencyFormatter.format(plan.price[currency]).slice(1)
  // console.log('dd', currencyFormatter.format(plan.price[currency]))
  return v
}

export const getNextPaidDay = lastPaid => {
  if (!lastPaid) return ''
  const utcDate = moment.utc(lastPaid).toDate()
  const localNextPaidDay = moment(utcDate).add(1, 'M').local().format('YYYY-MM-DD')

  return localNextPaidDay
}

export const getCreditAmount = (plans, planId) => {
  if (!planId) return

  // 간이 예외처리 ㅠㅠ
  if (planId.includes('admin')) {
    const splitPlanId = planId.split('-')
    planId = splitPlanId[0]
  }
  const allPlans = [...plans.subscription, ...plans.payGo]
  const plan = allPlans.find(p => p.id === planId)
  if (!plan) return

  return plan.creditAmount
}
