import {
  Box,
  Button,
  Collapse,
  Dialog,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  artworkPageAtom,
  autoUploadAtom,
  bannerMajorPageAtom,
  configDialogOpenAtom,
  customTemplateAtom,
  dragDropOpenAtom,
  f1Atom,
  f2Atom,
  f3Atom,
  indexAtom,
  isAnchorAtom,
  keepShowingConfigAtom,
  mannequinLoadingAtom,
  maskImgArrayAtom,
  portfolioArtworkAtom,
  portfolioConfigAtom,
  portfolioTypeAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  selectedMaskImgAtom,
  toggleToPrevAtom,
  unusualPortfolioConfigUpdateAtom,
  uploadDialogOpenAtom,
  uploadFilesAndUrlAtom,
  uploadFilesAtom,
  uploadImgHeightAtom,
  uploadImgWidthAtom,
  uploadLoadingAtom,
  userAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  isSimpleAtom,
  modelRenerateDialogAtom,
} from 'atoms'
import {
  useCheckImage,
  useCheckImageForGuest,
  useRefineUploadFiles,
} from 'hooks/useRefineUploadFiles'
import useConfirm from 'hooks/useConfirm'
import {
  BulbIcon,
  CloseBlackIcon,
  ImageIcon,
  ImageIcon2,
  PlusIcon,
  PlusMobileIcon,
  SelectSideIcon,
  SettingIcon,
} from 'theme/icon'
import { allowedTypes } from 'utils/common'
import { file } from 'jszip'
import { BlueSwitch, OpacityMotionDiv } from './CustomStyle'
import {
  PORTFOLIO_TYPE_BANNER,
  PORTFOLIO_TYPE_UPLOAD,
  PORTFOLIO_TYPE_FACE,
  PORTFOLIO_TYPE_BGEXPANSION,
} from 'config'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'
import { getAccessToken, getGuestSession } from 'utils/cookie'
import { useResetNpy } from 'hooks/useResetNpy'
import { apis } from 'apis'
import { useApiHandler } from 'hooks/useApiHandler'
import { useLocation } from 'react-router-dom'
import { RIGHT_CLICK_ALLOW_USER } from 'App'
import { optionSwitchStyle } from './portfolio/PortfolioConfig'
import { CustomTooltip, TwoImageSlider } from 'components'
import { English, Korean } from 'hooks/useLanguage'
import { fontSize } from 'pages/test/Toolbar'

const normalCommerceButtonStyle = {
  width: '18.8rem',
  height: '9.2rem',
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: 'inherit',
  minHeight: 'auto',
  '& .MuiButtonBase-root': { padding: { lg: '1.2rem 1.6rem', xs: '0' } },
  '& .MuiTabs-scroller': { display: 'flex', justifyContent: 'center' },
  '& .MuiTabs-indicator': { display: 'none' },
  '& .MuiTabs-flexContainer': { width: 'inherit', justifyContent: 'flex-start' },

  [theme.breakpoints.down('lg')]: {},
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  height: '5.2rem',
  width: '24rem',
  borderTopRightRadius: '1rem',
  borderTopLeftRadius: '1rem',
  color: theme.palette.common.black,
  backgroundColor: '#D9D9D9',
  fontSize: '2rem',
  fontWeight: 700,
  minWidth: 'auto',
  minHeight: 'auto',
  maxWidth: 'none',

  '&.Mui-selected': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.draph.newBlue,
  },

  [theme.breakpoints.down('lg')]: {
    height: '3.2rem',
    lineHeight: 'normal',
    borderTopRightRadius: '1rem',
    borderTopLeftRadius: '1rem',
    fontSize: '1.2rem',
  },
}))

export function UploadHeader({
  gudieComponent,
  configComponent,
  configComponentSecond,
  uploadButtonComponent,
  noConfigComponentMode = false, // config 가 없고 버튼만 있는 경우
  multiple = true,
  configWidth,
  uploadImglimit = 20,
  configDelay = 0,
  imageDelay = 0,
  addTabsArray,
  commerceButton,
  isModel,
  mannquinUpload = false,
  blockInteraction = false,
}) {
  const theme = useTheme()
  const { showPleaseLoginDialog, addClickInterceptor } = usePleaseLoginDialog()
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()

  const [tabPage, setTabPage] = useState(0)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const user = useRecoilValue(userAtom)
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const [page, setPage] = useRecoilState(artworkPageAtom)
  const [isAnchor, setIsAnchor] = useRecoilState(isAnchorAtom)
  const [keepShowingConfig, setKeepShowingConfig] = useRecoilState(keepShowingConfigAtom)
  const [prev, setPrev] = useRecoilState(toggleToPrevAtom)

  const removeBgImageFile = useRecoilValue(autoUploadAtom)

  const setConfigDialog = useSetRecoilState(configDialogOpenAtom)

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)
  const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)
  const resetMannequinLoading = useResetRecoilState(mannequinLoadingAtom)
  const resetSegmentLoading = useResetRecoilState(segmentLoadingAtom)
  const resetSegmentStep = useResetRecoilState(segmentStepAtom)
  const resetMaskImgArray = useResetRecoilState(maskImgArrayAtom)
  const resetUploadImgHeight = useResetRecoilState(uploadImgHeightAtom)
  const resetUploadImgWidth = useResetRecoilState(uploadImgWidthAtom)
  const resetIndex = useResetRecoilState(indexAtom)
  const resetF1 = useResetRecoilState(f1Atom)
  const resetF2 = useResetRecoilState(f2Atom)
  const resetF3 = useResetRecoilState(f3Atom)
  const resetUploadLoading = useResetRecoilState(uploadLoadingAtom)
  const resetDragDropOpen = useResetRecoilState(dragDropOpenAtom)
  const resetRemoveBgImageFile = useResetRecoilState(autoUploadAtom)

  const { abortApi } = useApiHandler({ apiKey: 'getNpyMaps', apis: apis.appfront })

  const reset = () => {
    resetFiles()
    resetSelectedMaskImg()
    resetMannequinLoading()
    resetSegmentLoading()
    resetSegmentStep()
    resetMaskImgArray()
    resetUploadImgWidth()
    resetUploadImgHeight()
    resetIndex()
    resetF1()
    resetF2()
    resetF3()
    resetUploadLoading()
    resetDragDropOpen()
    removeBgImageFile.auto && resetRemoveBgImageFile()
    abortApi()
  }

  const isUploadPage = portfolioType === PORTFOLIO_TYPE_UPLOAD

  const tabsHandler = (e, newValue) => {
    setTabPage(newValue)
  }

  const filesRef = useRef(files)

  // Update the ref whenever files changes
  useEffect(() => {
    filesRef.current = files
  }, [files])

  useEffect(() => {
    const s = localStorage.getItem('draph-prev')
    setPrev(s === 'true')

    return () => {
      // console.log('revoke_1')
      // filesRef.current.forEach(image => URL.revokeObjectURL(image.url))
      // reset()
      setKeepShowingConfig(false)
    }
  }, [])

  useEffect(() => {
    filesRef.current.forEach(image => URL.revokeObjectURL(image.url))
    reset()
  }, [portfolioType])

  useEffect(() => {
    if (blockInteraction) {
      const target = document.querySelector('#config-panel-wrapper')

      if (target) addClickInterceptor(target)
    }
  }, [])

  const tabsArrayDefault = [
    {
      default: t('upload_header.upload_tab'),
      mobile: t('upload_header.upload_tab'),
      component: isModel ? (
        <ModelUploadPart
          configComponent={configComponent}
          configComponentSecond={configComponentSecond}
          noConfigComponentMode={noConfigComponentMode}
          multiple={multiple}
          uploadImglimit={uploadImglimit}
          configDelay={configDelay}
          imageDelay={imageDelay}
          configWidth={configWidth}
        />
      ) : (
        <UploadPart
          configComponent={configComponent}
          noConfigComponentMode={noConfigComponentMode}
          multiple={multiple}
          uploadImglimit={uploadImglimit}
          configDelay={configDelay}
          imageDelay={imageDelay}
          configWidth={configWidth}
          commerceButton={commerceButton}
          mannquinUpload={mannquinUpload}
        />
      ),
    },
    {
      default: t('upload_header.guide_tab'),
      mobile: t('upload_header.guide_tab'),
      component: (
        <GuidePart uploadButtonComponent={uploadButtonComponent} gudieComponent={gudieComponent} />
      ),
    },
  ]

  const getFinalTabsArray = (tabsArray, addTabsArray) => {
    if (typeof addTabsArray !== 'undefined' && addTabsArray.length > 0) {
      return [
        ...tabsArray.slice(0, tabsArray.length - 1),
        ...addTabsArray,
        tabsArray[tabsArray.length - 1],
      ]
    }
    return tabsArray.filter(item => item !== null)
  }

  const tabsArray = getFinalTabsArray(tabsArrayDefault, addTabsArray)

  useEffect(() => {
    if (page === 1 && files.length === 0 && isAnchor) {
      const anchor = document.querySelector(`#scroll-anchor`)

      setTimeout(() => {
        setIsAnchor(false)
        anchor.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }, 500)
    }
  }, [files])

  return (
    <CenterAlignBox
      sx={{
        background: theme.palette.common.lightgray,
        p: '6rem 0 6.8rem 0',
        position: 'relative',
      }}
    >
      {RIGHT_CLICK_ALLOW_USER.includes(user.id) && (
        <Box sx={{ position: 'absolute', top: '1.8rem' }}>
          <Stack direction="row" sx={{}}>
            <Typography fontSize={'1.6rem'} fontWeight={700} sx={{ mr: '1rem' }}>
              🚨드랩 전용 미리하기🚨{' '}
            </Typography>
            <BlueSwitch
              sx={optionSwitchStyle}
              checked={prev}
              onChange={(e, c) => {
                setPrev(c)
                localStorage.setItem('draph-prev', c)
              }}
            />
          </Stack>
        </Box>
      )}

      <Stack sx={{ position: 'relative', alignItems: 'center' }}>
        <CenterAlignBox sx={{ width: '100%' }}>
          <StyledTabs
            value={tabPage}
            onChange={tabsHandler}
            centered={true}
            TabIndicatorProps={{
              style: { height: '0.3rem' },
            }}
            sx={{}}
          >
            {tabsArray.map((t, i) => (
              <StyledTab
                key={t.default}
                label={isMobile ? t.mobile : t.default}
                sx={{ width: `${100 / tabsArray.length}%` }}
              />
            ))}
          </StyledTabs>
          {isUploadPage && (
            <Button
              onClick={() => {
                setConfigDialog(true)
              }}
              sx={{
                width: '5.6rem',
                p: 0,
                borderRadius: '10px 10px 0 0',
                backgroundColor: '#EEEEEE',
              }}
            >
              <SettingIcon color="#808080" sx={{ width: '2rem', height: '2.2rem' }} size="medium" />
            </Button>
          )}
        </CenterAlignBox>

        <Box id="config-panel-wrapper" sx={{ zIndex: 1 }}>
          {tabsArray[tabPage] && tabsArray[tabPage].component}
        </Box>

        {multiple && (
          <CenterAlignBox
            sx={{
              p: '1.2rem 1.6rem',
              background: '#EEEEEE',
              borderRadius: '3rem',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              mt: '2.4rem',
              gap: '0.8rem',
              // mb: '2.8rem',

              '& .notice': {
                fontSize: '1.4rem',
                fontWeight: 600,
              },
            }}
          >
            <BulbIcon />

            <Korean>
              <Typography className="notice">최대 20장까지 한 번에 등록 가능합니다.</Typography>
            </Korean>
            <English>
              <Typography className="notice">
                You can register up to 20 images at a time.
              </Typography>
            </English>
          </CenterAlignBox>
        )}
      </Stack>
    </CenterAlignBox>
  )
}

const UploadPart = ({
  uploadImglimit,
  formStyle = {},
  multiple,
  isBulkUpload = false,
  configComponent,
  noConfigComponentMode,
  configDelay,
  imageDelay,
  configWidth,
  commerceButton,
  mannquinUpload,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)

  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [keepShowingConfig, setKeepShowingConfig] = useRecoilState(keepShowingConfigAtom)
  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)

  const [showAll, setShowAll] = useState(false)

  const { resetAllState } = useResetNpy()

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)
  const { abortApi } = useApiHandler({ apiKey: 'getNpyMaps', apis: apis.appfront })

  const handleShowAll = () => {
    setShowAll(true)
  }

  const handleShowFive = () => {
    setShowAll(false)
  }

  const filesToShow = showAll ? files : files.slice(0, 5)

  // drag state
  // ref
  const inputRef = useRef(null)

  const isMobile = useMobileMediaQuery()

  const getWidth = () => {
    if (configWidth) return configWidth

    if (keepShowingConfig) {
      return files.length > 5 ? '97.7rem' : '85.2rem'
    }

    if (!files.length) return '48rem'

    return files.length > 5 ? '97.7rem' : '85.2rem'
  }

  // useEffect(() => {
  //   console.log('files', files)
  // })

  useEffect(() => {
    return () => {
      if (inputRef && inputRef.current) inputRef.current.value = null
    }
  }, [])

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragDropOpen(true)
    } else if (e.type === 'dragleave') {
      setDragDropOpen(false)
    }
  }

  const reset = () => {
    files.forEach(image => URL.revokeObjectURL(image.url))
    resetFiles()
    resetAllState()
    abortApi()
  }

  const deleteCallback = () => {
    // console.log('revoke_2')
    reset()
  }

  // 여기부터 커머스 시이작 !
  const user = useRecoilValue(userAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const location = useLocation()
  const isFacePage = location.pathname.includes('/model/face')
  const isRemoveBgPage = location.pathname.includes('/generate/removebg')

  const isBannerPage = portfolioType === PORTFOLIO_TYPE_BANNER
  const isSegment = portfolioType === PORTFOLIO_TYPE_FACE
  const isNotShowConfig = portfolioType === PORTFOLIO_TYPE_BGEXPANSION
  const isNCommerceUser = user.login_sns === 'naver_commerce'
  const isNHNCommerceUser = user.login_sns === 'nhn_commerce'
  const isCafe24CommerceUser = user.login_sns === 'cafe24_commerce'
  const isCommerceUser = isNCommerceUser || isNHNCommerceUser || isCafe24CommerceUser

  const bannerConfig = useRecoilValue(unusualPortfolioConfigUpdateAtom)
  const isNoSo = isBannerPage && bannerConfig.genType === 'no_SO'

  return (
    <CenterAlignStack sx={{ position: 'relative' }}>
      <CenterAlignStack
        sx={{
          p: files.length ? '4.4rem 4rem' : '3.1rem 3.8rem 2.6rem 3.8rem',
          backgroundColor: theme.palette.common.white,
          borderBottomLeftRadius:
            noConfigComponentMode && (files.length || keepShowingConfig) ? '0px' : '10px',
          borderBottomRightRadius:
            noConfigComponentMode && (files.length || keepShowingConfig) ? '0px' : '10px',
          borderTopRightRadius: files.length ? '10px' : 0,

          boxShadow: ' 4px 4px 20px 0px #0000001A',
          alignItems: files.length && !isNoSo ? 'flex-start' : 'center',
          width: getWidth(),
          transition: 'width 0.5s ease-in-out',
          zIndex: 1,
        }}
      >
        {isNoSo ? (
          <OpacityMotionDiv style={{ display: 'flex' }}>
            <Stack>
              {' '}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#BBBBBB',
                  width: '40.4rem',
                  height: '9.2rem',
                  transition: 'background-color 0.3s ease',
                  borderRadius: '10px',
                }}
              >
                <PlusIcon
                  color={'#6D6D6D'}
                  style={{ width: '2.4rem', height: '2.4rem' }}
                  strokeWidth="5"
                />
              </div>
              <Typography sx={{ mt: '1.2rem', fontWeight: 600, textAlign: 'center' }}>
                {t('upload_header.no_product')}
              </Typography>
            </Stack>
          </OpacityMotionDiv>
        ) : (
          <>
            {files.length === 0 && (
              <OpacityMotionDiv style={{ display: 'flex' }}>
                <Stack>
                  <UploadButton
                    formStyle={{ ...(isCommerceUser && normalCommerceButtonStyle), ...formStyle }}
                    isBulkUpload={isBulkUpload}
                    multiple={multiple}
                    files={files}
                    setFiles={setFiles}
                    mannquinUpload={mannquinUpload}
                    segmentService={isSegment ? 'face' : ''}
                    uploadImglimit={uploadImglimit}
                    deleteCallback={isSegment ? deleteCallback : () => {}}
                    keepShowingOn={!(isSegment || isNotShowConfig)}
                  />
                  <Typography sx={{ mt: '1.2rem', fontWeight: 600, textAlign: 'center' }}>
                    {isRemoveBgPage
                      ? t('upload_header.notice_3')
                      : isFacePage
                      ? t('upload_header.notice_2')
                      : t('upload_header.notice_1')}
                  </Typography>
                </Stack>

                {isCommerceUser && (
                  <>
                    <Divider flexItem orientation="vertical" sx={{ mx: '2.2rem' }} />
                    {commerceButton}
                  </>
                )}
              </OpacityMotionDiv>
            )}
            {files.length > 0 && (
              <OpacityMotionDiv
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  gap: '1.2rem',
                  width: '100%',
                }}
              >
                <Stack
                  sx={{
                    height: '12rem',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mr: '2rem',
                  }}
                >
                  <UploadButton
                    formStyle={formStyle}
                    isBulkUpload={isBulkUpload}
                    multiple={multiple}
                    files={files}
                    setFiles={setFiles}
                    uploadImglimit={uploadImglimit}
                    disabled={files.length >= uploadImglimit || uploading}
                  />
                  <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>
                    {t('upload_header.image')}
                  </Typography>
                </Stack>

                <OpacityMotionDiv
                  style={{
                    gap: '1.2rem',
                    width: '66rem',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                  delay={imageDelay}
                >
                  {filesToShow.map((f, i) => {
                    return (
                      <ImageThumb
                        key={f.url}
                        url={f.url}
                        idx={i}
                        imageDelay={imageDelay}
                        files={files}
                        setFiles={setFiles}
                      />
                    )
                  })}
                </OpacityMotionDiv>

                {files.length > 5 &&
                  (showAll ? (
                    <CenterAlignBox sx={{ height: '12rem', alignItems: 'center' }}>
                      {' '}
                      <Button
                        sx={{
                          background: '#EEEEEE',
                          width: '10rem',
                          height: '3.4rem',
                          minWidth: 'auto',
                          color: theme.palette.common.black,
                          p: 0,
                          transform: 'rotate(180deg)',
                        }}
                        onClick={handleShowFive}
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      >
                        <SelectSideIcon sx={{ width: '1rem', heigth: '0.6rem' }} />
                      </Button>
                    </CenterAlignBox>
                  ) : (
                    files.length > 5 && (
                      <OpacityMotionDiv
                        style={{
                          width: '10rem',
                          height: '12rem',
                          alignItems: 'center',
                          gap: '1.6rem',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        // delay={0.3}
                      >
                        <Typography>···</Typography>
                        <Button
                          sx={{
                            background: '#EEEEEE',
                            width: '6.3rem',
                            height: '3.4rem',
                            minWidth: 'auto',
                            color: theme.palette.common.black,
                            p: 0,
                          }}
                          onClick={handleShowAll}
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                        >
                          + {files.length - 5} &nbsp;
                          <SelectSideIcon sx={{ width: '1rem', heigth: '0.6rem' }} />
                        </Button>
                      </OpacityMotionDiv>
                    )
                  ))}
              </OpacityMotionDiv>
            )}
          </>
        )}
      </CenterAlignStack>

      <Box
        sx={{
          backgroundColor: 'white',
          // border: '1px solid red',
          // width: '100%',
          transform: noConfigComponentMode ? 'translateY(0rem)' : 'translateY(-0.8rem)',
          // display: files.length ? 'flex' : 'none',
          zIndex: noConfigComponentMode ? 1 : 'auto',
          width: getWidth(),

          transition: 'width 0.5s ease-in-out',
        }}
      >
        {(keepShowingConfig || Boolean(files.length)) && (
          <OpacityMotionDiv delay={configDelay}>{configComponent}</OpacityMotionDiv>
        )}
      </Box>
    </CenterAlignStack>
  )
}

const ModelUploadPart = ({
  uploadImglimit,
  formStyle = {},
  multiple,
  isBulkUpload = false,
  configComponent,
  configComponentSecond,
  noConfigComponentMode,
  configDelay,
  imageDelay,
  configWidth,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const [dialog, setDialog] = useRecoilState(modelRenerateDialogAtom)
  const [isMannequin, setIsMannquin] = useState(false)
  const [keepShowingConfig, setKeepShowingConfig] = useRecoilState(keepShowingConfigAtom)

  const [f1, setF1] = useRecoilState(f1Atom)
  const [f2, setF2] = useRecoilState(f2Atom)

  const [showAll, setShowAll] = useState(false)

  const { resetAllState } = useResetNpy()

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)
  const { abortApi } = useApiHandler({ apiKey: 'getNpyMaps', apis: apis.appfront })

  useEffect(() => {
    const filesArray = [f1, f2]
    const validFiles = filesArray.filter(files => files.length)

    if (files.length) return

    if (validFiles.length === 1) {
      setFiles(validFiles[0])
    }

    // else if (validFiles.length === 0) {
    //   setFiles([]) // 빈 배열로 설정하여 파일이 없음을 나타냅니다.
    // }

    // if (f1.length || f2.length) {
    //   setIsMannquin(true)
    // } else {
    //   setIsMannquin(false)
    // }
  }, [f1, f2])

  useEffect(() => {
    if (!files.length) {
      setF1([])
      setF2([])
      // setF3([])
    }
  }, [files])

  const reset = () => {
    files.forEach(image => URL.revokeObjectURL(image.url))
    resetFiles()
    resetAllState()
    abortApi()
  }

  const deleteCallback = () => {
    // console.log('revoke_2')
    reset()
  }

  const contentStyle = {
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    width: '100%',
    height: '2.4rem',

    color: theme.palette.common.white,
    borderRadius: '0 0 10px 10px',
    fontSize: '1.2rem',
    fontWeight: 600,
  }

  // drag state
  // ref
  const inputRef = useRef(null)

  const getWidth = () => {
    if (!files.length) return '48rem'

    if (files.length && configWidth) return configWidth

    return files.length > 5 ? '97.7rem' : '85.2rem'
  }

  useEffect(() => {
    return () => {
      if (inputRef && inputRef.current) inputRef.current.value = null
    }
  }, [])

  return (
    <CenterAlignStack>
      <CenterAlignStack
        sx={{
          p: files.length ? '4.4rem 4rem' : '3.1rem 3.8rem 2.6rem 3.8rem',
          backgroundColor: theme.palette.common.white,
          borderBottomLeftRadius: noConfigComponentMode && files.length ? '0px' : '10px',
          borderBottomRightRadius: noConfigComponentMode && files.length ? '0px' : '10px',
          borderTopRightRadius: files.length ? '10px' : 0,

          boxShadow: ' 4px 4px 20px 0px #0000001A',
          alignItems: 'center',
          width: getWidth(),
          transition: 'width 0.5s ease-in-out',
          zIndex: 1,
        }}
      >
        <CenterAlignBox sx={{ gap: '3.2rem' }}>
          <CustomTooltip
            componentsSx={{
              '& .MuiTooltip-arrow': {
                color: 'rgba(0,0,0, 1)',
              },

              background: 'rgba(0,0,0, 1)',
            }}
            title={
              <TwoImageSlider
                image1={'/static/images/mann_result.png'}
                image2={'/static/images/mann_ori.png'}
                duration={4}
                size={{ width: '19.2rem', heightL: '13.8rem' }}
              />
            }
          >
            <Box>
              <UploadButton
                formStyle={{ width: '18rem', height: '12rem' }}
                isBulkUpload={isBulkUpload}
                multiple={false}
                files={f1}
                setFiles={setF1}
                uploadImglimit={uploadImglimit}
                thumbnailMode={true}
                mannquinUpload={true}
                disabled={Boolean(f2.length)}
                deleteCallback={deleteCallback}
                segmentService="mannequin"
                underContent={
                  <CenterAlignBox
                    sx={{
                      ...contentStyle,
                      backgroundColor: !f2.length
                        ? f1.length
                          ? theme.palette.draph.newBlue
                          : theme.palette.common.black
                        : '#6D6D6D',
                    }}
                  >
                    {t('upload_header.mannequin')}
                  </CenterAlignBox>
                }
              />
            </Box>
          </CustomTooltip>

          <CustomTooltip
            componentsSx={{
              '& .MuiTooltip-arrow': {
                color: 'rgba(0,0,0, 1)',
              },

              background: 'rgba(0,0,0, 1)',
            }}
            title={
              <TwoImageSlider
                image1={'/static/images/normal_result.png'}
                image2={'/static/images/normal_ori.png'}
                duration={4}
                size={{ width: '19.2rem', heightL: '13.8rem' }}
                lowestOpacity={0.6}
              />
            }
          >
            <Box>
              <UploadButton
                formStyle={{ width: '18rem', height: '12rem' }}
                isBulkUpload={isBulkUpload}
                multiple={false}
                files={f2}
                setFiles={setF2}
                uploadImglimit={uploadImglimit}
                thumbnailMode={true}
                mannquinUpload={true}
                segmentService="mannequin"
                disabled={Boolean(f1.length)}
                deleteCallback={deleteCallback}
                underContent={
                  <CenterAlignBox
                    sx={{
                      ...contentStyle,
                      backgroundColor: !f1.length
                        ? f2.length
                          ? theme.palette.draph.newBlue
                          : theme.palette.common.black
                        : '#6D6D6D',
                    }}
                  >
                    {t('upload_header.normal')}
                  </CenterAlignBox>
                }
              />
            </Box>
          </CustomTooltip>
          {/* <UploadButton
            formStyle={{ width: '12rem', height: '12rem' }}
            isBulkUpload={isBulkUpload}
            multiple={false}
            files={f3}
            setFiles={setF3}
            uploadImglimit={uploadImglimit}
            thumbnailMode={true}
            mannquinUpload={true}
            disabled={Boolean(f1.length || f2.length)}
            deleteCallback={deleteCallback}
            underContent={
              <CenterAlignBox
                sx={{
                  ...contentStyle,
                  backgroundColor: !(f1.length || f2.length)
                    ? f3.length
                      ? theme.palette.draph.newBlue
                      : theme.palette.common.black
                    : '#6D6D6D',
                }}
              >
                {t('upload_header.normal')}
              </CenterAlignBox>
            }
          /> */}

          {/* <Box sx={{ position: 'relative' }}>
            <UploadButton
              formStyle={{ width: '12rem', height: '12rem' }}
              isBulkUpload={isBulkUpload}
              multiple={true}
              files={f3}
              setFiles={setF3}
              uploadImglimit={20}
              thumbnailMode={true}
              mannquinUpload={false}
              disabled={Boolean(f1.length || f2.length)}
              deleteCallback={deleteCallback}
              hasDeleteButton={!(f3.length > 1)}
              underContent={
                <CenterAlignBox
                  sx={{
                    ...contentStyle,
                    backgroundColor: !(f1.length || f2.length)
                      ? f3.length
                        ? theme.palette.draph.newBlue
                        : theme.palette.common.black
                      : '#6D6D6D',
                  }}
                >
                  일반인
                </CenterAlignBox>
              }
            />
            {f3.length > 1 && (
              <MultiUploadEditDialog top="0.6rem" right="0.6rem" files={f3} setFiles={setF3} />
            )}
          </Box> */}
        </CenterAlignBox>
      </CenterAlignStack>
      <Box
        sx={{
          backgroundColor: 'white',
          // border: '1px solid red',
          // width: '100%',
          transform: noConfigComponentMode ? 'translateY(0rem)' : 'translateY(-0.8rem)',
          // display: files.length ? 'flex' : 'none',s
          width: getWidth(),
          transition: 'width 0.5s ease-in-out',
        }}
      >
        {Boolean(files.length) && !dialog.open && (
          <OpacityMotionDiv delay={configDelay}>
            {/* {isMannequin ? configComponent : configComponentSecond} */}
            {configComponent}
          </OpacityMotionDiv>
        )}
      </Box>
    </CenterAlignStack>
  )
}

export const UploadButton = ({
  formStyle = {},
  plusSx = {},
  labelFormStyle = {},
  isBulkUpload,
  multiple,
  files,
  setFiles,
  uploadImglimit,
  thumbnailPlusComponent,
  thumbnailMode = false,
  underContent,
  mannquinUpload = false,
  segmentService = 'mannequin',
  disabled = false,
  hasDeleteButton = true,
  keepShowingOn = true,
  deleteCallback = () => {},
}) => {
  const isMobile = useMobileMediaQuery()
  const uuid = Math.ceil(Math.random() * 1000000)
  const inputRef = useRef(null)
  const theme = useTheme()

  const segmentLoading = useRecoilValue(segmentLoadingAtom)
  const { showConfirm } = useConfirm()

  const token = getAccessToken()
  const guestSessionToken = getGuestSession()

  const checkImage =
    !token && guestSessionToken
      ? useCheckImageForGuest(uploadImglimit)
      : useCheckImage(uploadImglimit)

  const hasFiles = Boolean(files.length)
  const [dragActive, setDragActive] = useState(false)
  const [uploadFiles, setUplodFiles] = useRecoilState(uploadFilesAndUrlAtom)

  const hasFileStyle = {
    background: theme.palette.common.black,
    width: '9.2rem',
    height: '9.2rem',
  }

  const underContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginBottom: '2rem',
  }

  const disabledStyle = {
    background: '#BBBBBB',
    // width: '9.2rem',
    // height: '9.2rem',
    cursor: 'auto',
  }

  const handleDelete = url => e => {
    e.preventDefault()
    const deletedItem = files.find(item => item.url === url)
    URL.revokeObjectURL(deletedItem.url)
    setFiles(items => items.filter(item => item.url !== url))

    deleteCallback()
  }

  const handleChange = async function (e) {
    e.preventDefault()

    if (e.target.files) {
      if (!isBulkUpload) {
        const uploadFiles = Array.prototype.slice.call(e.target.files)

        checkImage({
          uploadFiles,
          files,
          setFiles,
          mannequinMode: mannquinUpload,
          keepShowingOn,
          segmentService,
        })
      } else {
        const uploadFiles = Array.prototype.slice.call(e.target.files, 0, 1)

        const allowedTypes = ['application/zip', 'application/x-zip-compressed']
        const zipfiles = uploadFiles.filter(f => allowedTypes.includes(f.type)).slice(0, 1)
        const otherfiles = uploadFiles.filter(f => !allowedTypes.includes(f.type))

        if (otherfiles.length) {
          showConfirm({ content: '확장자(.zip)을 확인해주세요 !', alertOnly: true })
        }

        if (zipfiles.length) {
          setFiles(zipfiles)
        }
      }
      e.target.value = ''
    }
  }

  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0] && !uploadFiles.length) {
      // handleFiles(e.dataTransfer.files)

      const uploadFiles = Array.prototype.slice.call(e.dataTransfer.files, 0, 1)

      checkImage({
        uploadFiles,
        files,
        setFiles,
        mannequinMode: mannquinUpload,
        keepShowingOn,
        segmentService,
      })
    }
  }

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  return (
    <>
      {!thumbnailMode ? (
        <form
          className="form-file-upload"
          id={`form-file-upload-${uuid}`}
          // onDragEnter={handleDrag}
          onSubmit={e => e.preventDefault()}
          style={{
            ...formStyle,
          }}
        >
          <input
            ref={inputRef}
            type="file"
            className="input-file-upload"
            id={`input-file-upload-${uuid}`}
            accept={isBulkUpload ? '' : allowedTypes.join(', ')}
            multiple={multiple}
            onChange={handleChange}
            disabled={(!multiple && hasFiles) || disabled}
          />
          <label
            id="label-file-upload"
            htmlFor={`input-file-upload-${uuid}`}
            // htmlFor={token ? `input-file-upload-${uuid}` : ''}
            style={{
              width: '40.4rem',
              height: '9.2rem',
              transition: 'background-color 0.3s ease',
              ...(hasFiles && hasFileStyle),
              ...(((!multiple && hasFiles) || disabled) && disabledStyle),
              ...labelFormStyle,
              ...formStyle,
            }}
          >
            <div>
              <PlusIcon
                color={hasFiles ? theme.palette.common.white : '#6D6D6D'}
                style={{ width: '2.4rem', height: '2.4rem', ...plusSx }}
                strokeWidth="5"
              />
            </div>
          </label>
        </form>
      ) : !hasFiles ? (
        <form
          className="form-file-upload"
          id={`form-file-upload-${uuid}`}
          // onDragEnter={handleDrag}
          onSubmit={e => e.preventDefault()}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          style={{
            ...formStyle,
          }}
        >
          <input
            ref={inputRef}
            type="file"
            className="input-file-upload"
            id={`input-file-upload-${uuid}`}
            accept={isBulkUpload ? '' : allowedTypes.join(', ')}
            multiple={multiple}
            onChange={handleChange}
            disabled={disabled}
          />
          <label
            id="label-file-upload"
            htmlFor={`input-file-upload-${uuid}`}
            className={dragActive ? 'drag-active' : ''}
            style={{
              width: '40.4rem',
              height: '9.2rem',
              transition: 'background-color 0.3s ease',
              ...labelFormStyle,
              ...(hasFiles && hasFileStyle),
              ...(disabled && disabledStyle),
              ...formStyle,
            }}
          >
            {thumbnailPlusComponent ?? (
              <div style={{ ...(underContent && underContentStyle) }}>
                <PlusIcon
                  color={hasFiles ? theme.palette.common.white : '#6D6D6D'}
                  strokeWidth="5"
                  style={{ ...plusSx }}
                />
                {underContent}
              </div>
            )}
          </label>
        </form>
      ) : (
        <Stack sx={{ position: 'relative' }}>
          <img
            style={{ objectFit: 'cover', borderRadius: '10px', maxWidth: 'none', ...formStyle }}
            src={files[0]?.url}
          />
          {underContent}
          {hasDeleteButton && (
            <IconButton
              onClick={handleDelete(files[0].url)}
              sx={{
                position: 'absolute',
                top: '-1.4rem',
                right: '-1.5rem',
              }}
            >
              <CloseBlackIcon />
            </IconButton>
          )}
        </Stack>
      )}
    </>
  )
}

export const RefImageUploadButton = ({
  formStyle = {},
  plusSx = {},
  labelFormStyle = {},
  isBulkUpload,
  multiple,
  files,
  setFiles,
  uploadImglimit,
  thumbnailPlusComponent,
  thumbnailMode = false,
  underContent,
  mannquinUpload = false,
  segmentService = 'mannequin',
  disabled = false,
  hasDeleteButton = true,
  keepShowingOn = true,
  deleteCallback = () => {},
}) => {
  const isMobile = useMobileMediaQuery()
  const uuid = Math.ceil(Math.random() * 1000000)
  const inputRef = useRef(null)
  const theme = useTheme()

  const segmentLoading = useRecoilValue(segmentLoadingAtom)
  const { showConfirm } = useConfirm()

  const token = getAccessToken()
  const guestSessionToken = getGuestSession()

  const checkImage =
    !token && guestSessionToken
      ? useCheckImageForGuest(uploadImglimit)
      : useCheckImage(uploadImglimit)

  const hasFiles = Boolean(files.length)
  const [dragActive, setDragActive] = useState(false)
  const [uploadFiles, setUplodFiles] = useRecoilState(uploadFilesAndUrlAtom)

  const hasFileStyle = {
    background: theme.palette.common.black,
    width: '9.2rem',
    height: '9.2rem',
  }

  const underContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginBottom: '2rem',
  }

  const disabledStyle = {
    background: '#BBBBBB',
    // width: '9.2rem',
    // height: '9.2rem',
    cursor: 'auto',
  }

  const handleDelete = url => e => {
    e.preventDefault()
    const deletedItem = files.find(item => item.url === url)
    URL.revokeObjectURL(deletedItem.url)
    setFiles(items => items.filter(item => item.url !== url))

    deleteCallback()
  }

  const handleChange = async function (e) {
    e.preventDefault()

    if (e.target.files) {
      if (!isBulkUpload) {
        const uploadFiles = Array.prototype.slice.call(e.target.files)

        checkImage({
          uploadFiles,
          files,
          setFiles,
          mannequinMode: mannquinUpload,
          keepShowingOn,
          segmentService,
        })
      } else {
        const uploadFiles = Array.prototype.slice.call(e.target.files, 0, 1)

        const allowedTypes = ['application/zip', 'application/x-zip-compressed']
        const zipfiles = uploadFiles.filter(f => allowedTypes.includes(f.type)).slice(0, 1)
        const otherfiles = uploadFiles.filter(f => !allowedTypes.includes(f.type))

        if (otherfiles.length) {
          showConfirm({ content: '확장자(.zip)을 확인해주세요 !', alertOnly: true })
        }

        if (zipfiles.length) {
          setFiles(zipfiles)
        }
      }
      e.target.value = ''
    }
  }

  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0] && !uploadFiles.length) {
      // handleFiles(e.dataTransfer.files)

      const uploadFiles = Array.prototype.slice.call(e.dataTransfer.files, 0, 1)

      checkImage({
        uploadFiles,
        files,
        setFiles,
        mannequinMode: mannquinUpload,
        keepShowingOn,
        segmentService,
      })
    }
  }

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  return (
    <>
      <CenterAlignStack
        direction="row"
        sx={{
          background: '#f8f8f8',
          width: '30.8rem',
          height: '12rem',
          borderRadius: '1rem',
          px: '1.6rem',
          py: '1.4rem',
        }}
      >
        {!hasFiles ? (
          <form
            className="form-file-upload"
            id={`form-file-upload-${uuid}`}
            // onDragEnter={handleDrag}
            onSubmit={e => e.preventDefault()}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            style={{
              ...formStyle,
            }}
          >
            <input
              ref={inputRef}
              type="file"
              className="input-file-upload"
              id={`input-file-upload-${uuid}`}
              accept={isBulkUpload ? '' : allowedTypes.join(', ')}
              multiple={multiple}
              onChange={handleChange}
              disabled={disabled}
            />
            <label
              id="label-file-upload"
              htmlFor={`input-file-upload-${uuid}`}
              className={dragActive ? 'drag-active' : ''}
              style={{
                transition: 'background-color 0.3s ease',

                background: '#D9D9D9',

                ...labelFormStyle,
                ...(hasFiles && hasFileStyle),
                ...(disabled && disabledStyle),
                ...formStyle,
              }}
            >
              <ImageIcon2 />
            </label>
          </form>
        ) : (
          <Stack sx={{ position: 'relative' }}>
            <img
              style={{ objectFit: 'cover', borderRadius: '10px', maxWidth: 'none', ...formStyle }}
              src={files[0]?.url}
            />
            <IconButton
              onClick={handleDelete(files[0].url)}
              sx={{
                position: 'absolute',
                top: '-1.4rem',
                right: '-1.5rem',
              }}
            >
              <CloseBlackIcon />
            </IconButton>
          </Stack>
        )}

        <CenterAlignStack
          sx={{
            ml: '2rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              px: '2.3rem',
              py: '0.6rem',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              background: theme => theme.palette.common.black,
              borderRadius: '3rem',
              fontSize: '1.4rem',
              fontWeight: 600,
              '& svg': {
                ml: '4px',
              },
              cursor: 'pointer',
            }}
            onClick={() => {
              inputRef?.current?.click()
            }}
          >
            <Korean>참고 이미지 업로드</Korean>
            <English>Upload Reference</English>
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.375 7.61719C9.69552 7.61719 9.95969 7.86699 9.9958 8.18881L10 8.26427V10.8526C10 11.1845 9.75873 11.458 9.44789 11.4953L9.375 11.4997H0.625C0.304478 11.4997 0.040308 11.2499 0.00420483 10.9281L0 10.8526V8.26427C0 7.9069 0.279822 7.61719 0.625 7.61719C0.945522 7.61719 1.20969 7.86699 1.2458 8.18881L1.25 8.26427V10.2055H8.75V8.26427C8.75 7.93242 8.99127 7.65892 9.30211 7.62154L9.375 7.61719ZM5.00129 8.91212C5.34647 8.91212 5.62629 8.62241 5.62629 8.26503L5.62692 2.71045L7.68435 4.84007C7.90965 5.07334 8.26417 5.09128 8.50935 4.8939L8.56823 4.84007C8.79354 4.60681 8.81087 4.23976 8.62023 3.98592L8.56823 3.92496L5.44323 0.689527C5.21793 0.456263 4.86341 0.43832 4.61823 0.635697L4.55935 0.689527L1.43435 3.92496C1.19027 4.17766 1.19027 4.58737 1.43435 4.84007C1.65965 5.07334 2.01417 5.09128 2.25935 4.8939L2.31823 4.84007L4.37692 2.70915L4.37629 8.26503C4.37629 8.62241 4.65611 8.91212 5.00129 8.91212Z"
                fill="white"
              />
            </svg>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '1.2rem',
              fontWeight: 400,
              mt: '8px',
            }}
          >
            <Korean>
              참고 이미지가 있다면 <br /> 업로드해주세요!
            </Korean>
            <English>
              Please upload <br /> a reference image
            </English>
          </Box>
        </CenterAlignStack>
      </CenterAlignStack>
    </>
  )
}

const ImageThumb = ({ url, idx, imageDelay, files, setFiles }) => {
  // const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const [portfolioType, setPortfolioType] = useRecoilState(portfolioTypeAtom)
  const [majorPage, setMajorPage] = useRecoilState(bannerMajorPageAtom)

  const { abortApi } = useApiHandler({ apiKey: 'getNpyMaps', apis: apis.appfront })
  const { resetAllState } = useResetNpy()

  const user = useRecoilValue(userAtom)
  const isNCommerceUser = user.login_sns === 'naver_commerce'
  const isBannerPage = portfolioType === PORTFOLIO_TYPE_BANNER
  const isSegment = portfolioType === PORTFOLIO_TYPE_FACE

  const handleClick = () => {
    if (isNCommerceUser && files.length === 1) return

    const deletedItem = files.find(item => item.url === url)
    URL.revokeObjectURL(deletedItem.url)
    setFiles(items => items.filter(item => item.url !== url))

    if (isBannerPage) {
      setMajorPage(0)
      resetAllState()
      abortApi()
    }

    if (isSegment) {
      resetAllState()
      abortApi()
    }
  }

  return (
    <OpacityMotionDiv
      style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}
      delay={imageDelay}
    >
      <img
        style={{
          width: '12rem',
          height: '12rem',
          objectFit: 'cover',
          borderRadius: '10px',
          maxWidth: 'none',
        }}
        src={url}
      />

      <IconButton
        onClick={handleClick}
        sx={{
          position: 'absolute',
          top: '-1.4rem',
          right: '-1.5rem',
        }}
      >
        <CloseBlackIcon />
      </IconButton>
    </OpacityMotionDiv>
  )
}

const GuidePart = ({
  uploadImglimit = 20,
  formStyle = {},
  multiple = true,
  isBulkUpload = false,
  uploadButtonComponent,
  gudieComponent,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)

  // drag state
  // ref
  const inputRef = useRef(null)

  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    return () => {
      if (inputRef && inputRef.current) inputRef.current.value = null
    }
  }, [])

  return (
    <OpacityMotionDiv style={{ display: 'flex', flexDirection: 'column' }}>
      <CenterAlignStack>{gudieComponent}</CenterAlignStack>
      {uploadButtonComponent}
    </OpacityMotionDiv>
  )
}

const MultiUploadEditDialog = ({ files, setFiles, top, right }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          position: 'absolute',
          background: theme.palette.draph.blue,
          color: theme.palette.common.white,
          borderRadius: '4px',
          zIndex: 1,
          p: '0.4rem 0.55rem',
          fontWeight: 700,
          fontSize: '1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          cursor: 'pointer',
          transition: 'background 0.3s',
          top,
          right,

          '&:hover': { background: '#5571fc' },
        }}
      >
        + {files.length - 1}
        <SvgIcon
          sx={{ width: '1.3rem', height: '1.2rem', fill: 'none', ml: '0.3rem' }}
          viewBox="0 0 13 12"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.71639 3.78361C8.9612 4.02843 8.9612 4.42535 8.71639 4.67017L7.387 5.99956L8.71639 7.32983C8.9612 7.57465 8.9612 7.97157 8.71639 8.21639C8.47157 8.4612 8.07465 8.4612 7.82983 8.21639L6.50044 6.88611L5.17017 8.21639C4.92535 8.4612 4.52843 8.4612 4.28361 8.21639C4.0388 7.97157 4.0388 7.57465 4.28361 7.32983L5.61389 5.99956L4.28361 4.67017C4.0388 4.42535 4.0388 4.02843 4.28361 3.78361C4.52843 3.5388 4.92535 3.5388 5.17017 3.78361L6.50044 5.113L7.82983 3.78361C8.07465 3.5388 8.47157 3.5388 8.71639 3.78361Z"
            fill="#2C4DFF"
          />
        </SvgIcon>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        // disableScrollLock={true}
        sx={{
          overflowX: 'hidden',
          '& .MuiDialog-paper': {
            minWidth: '72rem',
            borderRadius: '10px',
            p: '2.8rem 3.6rem',
          },
        }}
      >
        <CenterAlignStack>
          <Typography sx={{ fontWeight: 700, fontSize: '2rem' }}>
            {t('upload_header.image_selected')}
          </Typography>

          <Box sx={{ width: '64.8rem', gap: '1.2rem', display: 'flex', m: '2rem 0 2.4rem 0' }}>
            {files.map((f, i) => {
              return (
                <ImageThumb
                  key={f.url}
                  url={f.url}
                  idx={i}
                  imageDelay={0}
                  files={files}
                  setFiles={setFiles}
                />
              )
            })}
          </Box>

          <Button
            variant="contained"
            sx={{ width: '20rem', height: '4rem', borderRadius: '4px', fontWeight: 600 }}
            onClick={handleClose}
          >
            확인
          </Button>
        </CenterAlignStack>
      </Dialog>
    </>
  )
}
