import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  OutlinedInput,
  Slide,
  Stack,
  Typography,
  styled,
  useTheme,
  Box,
  Divider,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  keyframes,
} from '@mui/material'
import { apis } from 'apis'
import { subEventValidAtom, userAtom, userCreditAtom, userCreditInfoAtom } from 'atoms'
import {
  CenterAlignBox,
  CenterAlignStack,
  CheckPasswordDialog,
  DialogButton,
  LeaveNoticeDialog,
  MobileSwipeableDrawer,
  ShowUserState,
  SpanDraphBlue,
} from 'components'
import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { MYPAGE_PERCENTAGE_WIDHT, MYPAGE_SIDE_MARGIN, pageTitleStyle } from 'layouts/MypageLayout'
import { getCreditAmount, getNextPaidDay } from 'pricing'
import { forwardRef, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { motion } from 'framer-motion'
import moment from 'moment'
import { CircleWarningIcon } from 'theme/icon'
import { creditPlansAtom, userSubInfoAtom } from '../atoms'
import { MypageSkeleton } from 'components/Skeleton'
import { CircleLoader, PuffLoader, RingLoader, RotateLoader } from 'react-spinners'
import { usePricing } from 'hooks/usePricing'
import { useTranslation } from 'react-i18next'
import { getOrdinalSuffix, isKo, nullZero, numberCommaFormatter, trasnferDate } from 'utils/common'
import { t } from 'i18next'
import i18n from 'i18n'
import { English, Korean } from 'hooks/useLanguage'
import { SUB_EVENT_ID } from './Pricing'
import { getCurrencyFormatter, utcToLocal } from '../utils/common'
import { LeaveDialog, SelectReasonDialog } from 'components/ShowUserState'
import { generatePaymentUid } from '../hooks/usePricing'
import { getSubscriptionPrice } from '../pricing'
import { PlanExplanation } from 'components/PricingCard'

const bboing = keyframes`
 0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  /* 30% {
    -webkit-transform: scale3d(1.07, 0.85, 1);
            transform: scale3d(1.07, 0.85, 1);
  }
  40% {
    -webkit-transform: scale3d(0.85, 1.07, 1);
            transform: scale3d(0.85, 1.07, 1);
  } */
  50% {
    -webkit-transform: scale3d(1.04, 0.95, 1);
            transform: scale3d(1.04, 0.95, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
`

const subStyle = { fontWeight: 600, fontSize: '1.6rem' }

const TextButton = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.common.black,
  textDecorationLine: 'underline',
  fontSize: '1.8rem',
  fontWeight: 500,

  '&:hover': {
    backgroundColor: 'transparent',
  },

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.4rem',
  },
}))

const MainButton = styled(Button)(({ theme }) => ({
  width: '18rem',
  height: '4rem',

  fontSize: '1.6rem',
  fontWeight: 600,
  borderRadius: '4px',

  [theme.breakpoints.down('lg')]: {},
}))

export const CardTitleTypography = styled(Typography)(({ theme }) => ({
  width: '100%',
  height: '7.6rem',
  backgroundColor: theme.palette.draph.blue,
  fontSize: '2.6rem',
  fontWeight: 700,
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('lg')]: {
    height: '4.8rem',
    fontSize: '2rem',
    fontWeight: 700,
  },
}))

// const CardContent = styled(CenterAlignStack)(({ theme }) => ({
//   margin: '3.7rem 0 3.4rem 0',

//   [theme.breakpoints.down('lg')]: {
//     margin: '2.8rem 0 2rem 0',
//   },
// }))

export const checkAppliedSubEvent = currentSubscription => {
  if (currentSubscription.last_paid && currentSubscription.start_date) {
    const a = moment(currentSubscription.last_paid)
    const b = moment(currentSubscription.start_date)

    const diff = moment.duration(a.diff(b)).asMonths()

    if (diff < 1 && currentSubscription.applied_event === SUB_EVENT_ID) {
      return true
    }
  }
  return false
}

const AppliedEventBadge = ({ sx }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box
      sx={{
        border: `2px solid ${theme.palette.draph.blue}`,
        borderRadius: '2rem',
        px: { lg: '1.5rem', xs: '1rem' },
        py: { lg: '0.4rem', xs: '0.3rem' },
        ...sx,
      }}
    >
      <Typography sx={{ fontSize: { lg: '2rem' }, fontWeight: 700 }}>
        {t('mypage.first_month_free_badge_a')}
        <span style={{ color: theme.palette.draph.blue }}>
          {t('mypage.first_month_free_badge_b')}
        </span>
        {t('mypage.first_month_free_badge_c')}
      </Typography>
    </Box>
  )
}

export default function CreditAndSubscriptions() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const options = [
    { id: 999, label: t('select_reason_dialog.reasons_8') },
    { id: 2, label: t('select_reason_dialog.reasons_2') },
    { id: 3, label: t('select_reason_dialog.reasons_3') },
    { id: 4, label: t('select_reason_dialog.reasons_4') },
    { id: 5, label: t('select_reason_dialog.reasons_5') },
    { id: 6, label: t('select_reason_dialog.reasons_6') },
    { id: 7, label: t('select_reason_dialog.reasons_7') },
    { id: 1, label: t('select_reason_dialog.reasons_1') },
  ]

  const [currentSubscription, setCurrentSubscription] = useRecoilState(userSubInfoAtom)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [openCheckPasswardDialog, setOpenCheckPasswordDialog] = useState(false)
  const [openLeaveNoticeDialog, setOpenLeaveNoticeDialog] = useState(false)
  const [openSelectReasonDialog, setOpenSelectReasonDialog] = useState(false)
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false)

  const [selectedOption, setSelectedOption] = useState('')
  const [etcText, setEtcText] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [showAppliedEventBadge, setShowAppliedEventBadge] = useState(false)

  const [userCredit, setUserCredit] = useRecoilState(userCreditAtom)
  const creditPlans = useRecoilValue(creditPlansAtom)
  const user = useRecoilValue(userAtom)

  const refreshSubscription = () => {
    apis.user.getUserSubscription().then(response => {
      setIsLoading(false)
      setCurrentSubscription(response.data)
    })
  }

  useEffect(() => {
    apis.user.getCredit().then(response => {
      // console.log(response.data.credit)
      setUserCredit(response.data.credit ?? 0)
    })
  }, [])

  const theme = useTheme()

  useEffect(() => {
    setShowAppliedEventBadge(checkAppliedSubEvent(currentSubscription))
  }, [currentSubscription])

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ width: '101.2rem', mb: '5.3rem' }}>
          <Stack direction="row" sx={{ width: '100%', alignItems: 'center', mb: '1.6rem' }}>
            <Typography sx={{ ...subStyle, textAlign: 'left' }}>
              {t('mypage.welcome_a')}
              <span style={{ color: theme.palette.draph.blue }}>{user.username}</span>
              {t('mypage.welcome_b')}
            </Typography>
            {showAppliedEventBadge && <AppliedEventBadge sx={{ ml: { lg: '2rem' } }} />}
          </Stack>
          <CenterAlignBox
            gap="2rem"
            // 배너 빠지면 여기로 !
            // sx={{ mb: currentSubscription?.plan_id ? '21.3rem' : 'unset' }}
            sx={{ mb: '7.9rem', width: '100%' }}
          >
            {isLoading ? (
              // TODO TODO 업데이트
              <MypageSkeleton />
            ) : (
              <>
                <Stack sx={{ flex: 1 }} gap="2rem">
                  <MypageSubscriptionCard
                    setCancelDialogOpen={setCancelDialogOpen}
                    currentSubscription={currentSubscription}
                    user={user}
                  />
                  <MypageSavedCard />
                </Stack>
                <Stack sx={{ flex: 1 }} gap="2rem">
                  <MypageCreditCard userCredit={userCredit} />
                  <MypageCreditHistoryCard />
                </Stack>
              </>
            )}
          </CenterAlignBox>
          <Korean>
            <img
              src={'/static/images/event/long_event_banner_pc.png'}
              style={{ width: '91.8rem', cursor: 'pointer' }}
              onClick={() => {
                navigate('/event/ko/normal/sns_review_event')
              }}
            />
          </Korean>
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack sx={{ width: '100%', mb: '8rem' }}>
          <CenterAlignStack sx={{ width: '32rem' }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '1.6rem',
                textAlign: 'left',
                width: '100%',
                mt: '1.8rem',
                mb: '0.7rem',
              }}
            >
              {t('mypage.welcome_a')}
              <span style={{ color: theme.palette.draph.blue }}>{user.username}</span>
              {t('mypage.welcome_b')}
            </Typography>
            {showAppliedEventBadge && (
              <AppliedEventBadge sx={{ ml: { lg: '2rem' }, mb: { xs: '1.5rem' } }} />
            )}
            <CenterAlignStack gap="2rem" sx={{ mt: '1.5rem', width: '32rem' }}>
              <MypageSubscriptionCard
                setCancelDialogOpen={setCancelDialogOpen}
                currentSubscription={currentSubscription}
                user={user}
              />
              <MypageSavedCard />
              <MypageCreditCard userCredit={userCredit} />
              <MypageCreditHistoryCard />
            </CenterAlignStack>
            <Korean>
              <img
                src={'/static/images/event/short_event_banner_mo.png'}
                style={{ width: '32rem', cursor: 'pointer', margin: '4rem 0 0 0' }}
                onClick={() => {
                  navigate('/event/ko/normal/sns_review_event')
                }}
              />
            </Korean>
          </CenterAlignStack>
        </CenterAlignStack>
      </Mobile>

      {
        <ShowUserState
          refreshSubscription={refreshSubscription}
          currentSubscription={currentSubscription}
          open={cancelDialogOpen}
          setOpen={setCancelDialogOpen}
          openNextDialog={openCheckPasswardDialog}
          setOpenNextDialog={setOpenCheckPasswordDialog}
          setOpenNextDialogforSNS={setOpenLeaveNoticeDialog}
          userCredit={userCredit}
          type="unsubscribe"
        />
      }

      {openCheckPasswardDialog && (
        <CheckPasswordDialog
          open={openCheckPasswardDialog}
          setOpen={setOpenCheckPasswordDialog}
          openNextDialog={openLeaveNoticeDialog}
          setOpenNextDialog={setOpenLeaveNoticeDialog}
          type="unsubscribe" // GA4용.. 이 페이지(마이페이지)에서 비밀번호 입력하는 경우가 구독취소밖에 없어서 이렇게 처리! 변경사항 있으면 수정해야합니다
        />
      )}

      <LeaveNoticeDialog
        open={openLeaveNoticeDialog}
        setOpen={setOpenLeaveNoticeDialog}
        openNextDialog={openSelectReasonDialog}
        setOpenNextDialog={setOpenSelectReasonDialog}
        currentUser={user}
        currentSubscription={currentSubscription}
        userCredit={userCredit}
        type="unsubscribe"
      />

      {
        <SelectReasonDialog
          options={options}
          open={openSelectReasonDialog}
          setOpen={setOpenSelectReasonDialog}
          openNextDialog={openLeaveDialog}
          setOpenNextDialog={setOpenLeaveDialog}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          etcText={etcText}
          setEtcText={setEtcText}
          refreshSubscription={refreshSubscription}
          currentSubscription={currentSubscription}
          type="unsubscribe"
        />
      }

      {openLeaveDialog && (
        <LeaveDialog
          options={options}
          open={openLeaveDialog}
          setOpen={setOpenLeaveDialog}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          etcText={etcText}
          setEtcText={setEtcText}
          currentSubscription={currentSubscription}
          type="unsubscribe"
        />
      )}
    </>
  )
}

const MypageSubscriptionCard = ({ currentSubscription, setCancelDialogOpen }) => {
  const planId = currentSubscription?.plan_id
  const lastPaid = currentSubscription?.last_paid
  const creditPlans = useRecoilValue(creditPlansAtom)
  const user = useRecoilValue(userAtom)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()

  const [buttonLoading, setButtonLoading] = useState(false)

  const { getPlanDisplayName, getPlanPrice, getCurrencySymbol } = usePricing()

  const [openChangePaymentMethodDialog, setOpenChangePaymentMethodDialog] = useState(false)

  const subEventValid = useRecoilValue(subEventValidAtom)

  const theme = useTheme()

  const setting = planId
    ? currentSubscription.end_date && !currentSubscription.next_subscription?.id
      ? 'cancel'
      : 'planId'
    : ''

  const nextSubscription = currentSubscription?.next_subscription

  const isPaymentFailed = currentSubscription?.payment_status === 'failed'

  const cancelSubscriptionHandle = () => {
    // ----- GA4 event -----
    window.gtag('event', 'unsubscribe_begin', {})
    // ---------------------
    setCancelDialogOpen(true)
  }

  const cancelUpdateSubscription = () => {
    setButtonLoading(true)
    apis.user
      .cancelChangeSubscription({
        current_subscription_id: currentSubscription.id,
        next_subscription_id: currentSubscription.next_subscription.id,
      })
      .then(response => {
        if (response.data.success) {
          window.location.reload()
        }
      })
  }

  const reSubscribe = () => {
    apis.user.reSubscribe({ current_subscription_id: currentSubscription.id }).then(response => {
      if (response.data.success) {
        window.location.reload()
      }
    })
  }

  const handleChangePaymentMethod = () => {
    // ----- GA4 event -----
    window.gtag('event', 'payment_change_begin', {})
    // ---------------------

    const orderId = generatePaymentUid()

    const requestData = {
      PCD_PAY_TYPE: 'card',
      PCD_PAY_WORK: 'AUTH',
      PCD_CARD_VER: '01',

      PCD_PAY_OID: orderId,
      PCD_PAYER_NO: user.id,
      PCD_PAYER_NAME: user.username,
      PCD_PAYER_HP: user.phone,
      PCD_PAYER_EMAIL: user.email,
      PCD_USER_DEFINE1: orderId,
      PCD_USER_DEFINE2: '',

      callbackFunction: res => {
        if (res.PCD_PAY_RST === 'success') {
          // ----- GA4 event -----
          window.gtag('event', 'payment_change_complete', {})
          // ---------------------
          alert(res.PCD_PAY_MSG)
          window.location.reload()
        }
      },

      // ----
      payment_type: 'subscription_change_payment_method',
      order_id: orderId,
    }

    apis.payment
      .auth({ ...requestData })
      .then(response => {
        requestData.PCD_AUTH_KEY = response.data.PCD_AUTH_KEY
        requestData.PCD_PAY_URL = response.data.PCD_PAY_URL

        window.PaypleCpayAuthCheck(requestData)
      })
      .catch(() => {
        alert(t('payment.fail'))
      })
  }

  const selectCard = () => {
    // if (planId) {
    if (setting === 'planId') {
      return (
        <Stack>
          {currentSubscription.next_subscription?.id ? (
            // 예정된 다음 구독이 있는 경우 (구독 변경 예정)
            <>
              <Stack
                direction="row"
                sx={{
                  width: '100%',

                  alignItems: 'center',

                  '& .wrapper': {},
                  '& svg': { mx: { lg: '2.4rem', xs: '2rem' } },
                  '& .plan-name': {
                    fontSize: { lg: '2.4rem', xs: '1.8rem' },
                    lineHeight: { lg: '2.8rem', xs: '2.4rem' },
                    fontWeight: 700,
                    mt: { lg: '6px', xs: '4px' },
                    mb: { lg: '8px', xs: '6px' },
                  },
                  '& .title': {
                    fontSize: { lg: '1.6rem', xs: '1.2rem' },
                    fontWeight: 600,
                  },

                  '& .price': {
                    fontSize: { lg: '1.6rem', xs: '1.4rem' },
                    fontWeight: 600,
                    color: 'white',
                    background: '#303030',
                    width: 'fit-content',
                    borderRadius: '4px',
                    px: '1rem',
                    py: '0.5rem',
                  },
                }}
              >
                <Stack className="wrapper">
                  <Typography className="title">{t('mypage.subscription_status_1')}</Typography>
                  <Typography className="plan-name" sx={{}}>
                    {getPlanDisplayName(planId)?.includes('admin')
                      ? getPlanDisplayName(planId).replace('-admin', '')
                      : // ? getPlanDisplayName(planId).replace('-admin', ' (관리자)')
                        getPlanDisplayName(planId)}
                  </Typography>

                  <Typography className="price">
                    {(currentSubscription?.charge_currency &&
                      currentSubscription?.charge_price &&
                      getSubscriptionPrice(
                        currentSubscription.charge_currency,
                        currentSubscription.charge_price
                      )) ??
                      getPlanPrice(creditPlans, planId)}
                    {t('mypage.monthly_price')}
                  </Typography>
                </Stack>

                <Desktop>
                  <svg
                    width="61"
                    height="16"
                    viewBox="0 0 61 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M60.7071 8.70711C61.0976 8.31658 61.0976 7.68342 60.7071 7.29289L54.3432 0.928932C53.9526 0.538408 53.3195 0.538408 52.9289 0.928932C52.5384 1.31946 52.5384 1.95262 52.9289 2.34315L58.5858 8L52.9289 13.6569C52.5384 14.0474 52.5384 14.6805 52.9289 15.0711C53.3195 15.4616 53.9526 15.4616 54.3432 15.0711L60.7071 8.70711ZM0 9H60V7H0V9Z"
                      fill="#303030"
                    />
                  </svg>
                </Desktop>
                <Mobile>
                  <svg
                    width="49"
                    height="16"
                    viewBox="0 0 49 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M48.7071 8.70711C49.0976 8.31658 49.0976 7.68342 48.7071 7.29289L42.3431 0.928932C41.9526 0.538408 41.3195 0.538408 40.9289 0.928932C40.5384 1.31946 40.5384 1.95262 40.9289 2.34315L46.5858 8L40.9289 13.6569C40.5384 14.0474 40.5384 14.6805 40.9289 15.0711C41.3195 15.4616 41.9526 15.4616 42.3431 15.0711L48.7071 8.70711ZM0 9H48V7H0V9Z"
                      fill="#303030"
                    />
                  </svg>
                </Mobile>

                <Stack className="wrapper">
                  <Typography className="title">{t('mypage.planned_subscription')}</Typography>
                  <Typography
                    className="plan-name"
                    sx={{
                      color: theme.palette.draph.blue,
                    }}
                  >
                    {getPlanDisplayName(nextSubscription.plan_id)}
                  </Typography>
                  <Typography
                    className="price"
                    sx={{ background: theme => `${theme.palette.draph.blue} !important` }}
                  >
                    {(nextSubscription?.charge_currency &&
                      nextSubscription?.charge_price &&
                      getSubscriptionPrice(
                        nextSubscription.charge_currency,
                        nextSubscription.charge_price
                      )) ??
                      getPlanPrice(creditPlans, nextSubscription.plan_id)}
                    {t('mypage.monthly_price')}
                  </Typography>
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: { lg: '3.2rem', xs: '2rem' },

                  fontWeight: 700,
                  color: isPaymentFailed ? theme.palette.common.black : theme.palette.draph.blue,
                }}
              >
                {getPlanDisplayName(planId)?.includes('admin')
                  ? getPlanDisplayName(planId).replace('-admin', ' (관리자)')
                  : getPlanDisplayName(planId)}
              </Typography>
            </>
          )}

          <Stack
            className="content-box"
            sx={{
              width: '100%',
              '& .row': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: '2rem',
              },
              '& .text': {
                fontSize: '1.6rem',
                fontWeight: 400,
                '&.field': {},
                '&.value': { fontWeight: 600 },
              },
              py: '1.6rem',
              px: { lg: '2.4rem', xs: '1.6rem' },
              mt: '2rem',
            }}
            spacing={{ lg: '2rem', xs: '1.2rem' }}
          >
            {' '}
            {currentSubscription.payment_status === 'failed' && (
              <>
                <CenterAlignStack
                  direction={isMobile ? 'column' : 'row'}
                  sx={{
                    cursor: 'pointer',
                    mt: '0.6rem',
                    animation: `${bboing} 1s ease 0s 2 normal both running`,
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    setOpenChangePaymentMethodDialog(true)
                  }}
                >
                  <CircleWarningIcon
                    sx={{
                      mr: isKo(i18n) ? '0.4rem' : '1.4rem',
                      width: { xs: '1.4rem' },
                      height: { xs: '1.4rem' },
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#FF2323',
                      fontSize: { lg: '1.6rem', xs: '1.6rem' },
                      fontWeight: 600,
                      textAlign: 'center',
                      mt: isMobile ? '0.3rem' : 0,
                      mb: isMobile ? '1rem' : 0,
                    }}
                  >
                    <Korean>
                      {currentSubscription.payment_method_update_exp &&
                        utcToLocal(
                          currentSubscription.payment_method_update_exp,
                          'YYYY/MM/DD HH:mm'
                        ) + t('mypage.change_payment_method_notice_1')}{' '}
                      {isMobile && <br />}
                      {t('mypage.change_payment_method_notice_2')}
                    </Korean>
                    <English>
                      {currentSubscription.payment_method_update_exp &&
                        'Please change your payment method'}
                      <br />
                      {currentSubscription.payment_method_update_exp &&
                        'by ' +
                          utcToLocal(
                            currentSubscription.payment_method_update_exp,
                            'YYYY/MM/DD HH:mm'
                          )}
                    </English>
                  </Typography>
                </CenterAlignStack>
              </>
            )}
            {currentSubscription.next_subscription?.id ? (
              <CenterAlignBox sx={{ width: '100%' }}>
                <Typography
                  sx={{
                    fontSize: '1.6rem',
                    fontWeight: 600,
                    textAlign: 'center',
                    width: { lg: 'unset', xs: '16rem' },
                  }}
                >
                  {t('mypage.chagne_plan_comment')}
                </Typography>
              </CenterAlignBox>
            ) : (
              <></>
            )}
            <Stack className="row">
              {currentSubscription.next_subscription?.id ? (
                <>
                  <Typography className="text field">{t('mypage.next_paid_day')}</Typography>
                  <Typography className="text value">
                    {trasnferDate(getNextPaidDay(lastPaid), 'detail', i18n)}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className="text field">{t('credit.paid_date')}</Typography>
                  <Typography className="text value">
                    <Korean>매달 {currentSubscription.monthly_charge_date}일</Korean>
                    <English>
                      Every {getOrdinalSuffix(currentSubscription.monthly_charge_date)} of the Month
                    </English>
                  </Typography>
                </>
              )}
            </Stack>
            <Stack className="row">
              <Typography className="text field">{t('credit.payment_amount')}</Typography>
              <Typography className="text value">
                {getSubscriptionPrice(
                  currentSubscription.charge_currency,
                  currentSubscription.charge_price
                )}
              </Typography>
            </Stack>
            <Stack className="row">
              <Typography className="text field">{t('credit.charge_credit')}</Typography>
              <Typography className="text value">
                {currentSubscription.charge_credit_amount}
              </Typography>
            </Stack>
          </Stack>

          <CenterAlignStack
            sx={{
              mt: '2rem',
              flexDirection: { lg: 'row-reverse', xs: 'column' },
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <CenterAlignStack
              direction="row"
              spacing={{
                lg: '2.8rem',
                xs: '1.6rem',
              }}
              sx={{ width: { lg: 'unset', xs: '100%' }, justifyContent: { xs: 'right' } }}
            >
              <TextButton
                sx={{
                  fontSize: '1.6rem',
                  fontWeight: 400,
                  textDecoration: 'none',
                }}
                onClick={cancelSubscriptionHandle}
              >
                {t('button.cancel_subscription')}
              </TextButton>

              {currentSubscription.next_subscription?.id ? (
                <MainButton
                  variant="contained"
                  size="large"
                  onClick={cancelUpdateSubscription}
                  disabled={buttonLoading}
                >
                  {buttonLoading ? (
                    <Box sx={{ '& span': { width: '40px !important', height: '40px !important' } }}>
                      <PuffLoader />
                    </Box>
                  ) : (
                    t('button.cancel_plan')
                  )}
                </MainButton>
              ) : (
                <MainButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    navigate('/pricing')
                  }}
                  disabled={isPaymentFailed}
                >
                  {t('button.change_plan')}
                </MainButton>
              )}
            </CenterAlignStack>
            <Box
              sx={{
                mt: { lg: 0, xs: '1rem' },
                width: { lg: 'unset', xs: '100%' },

                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <Korean>
                {/* 우선 국내결제만 지원 */}
                <TextButton onClick={handleChangePaymentMethod} sx={{ fontSize: '1.4rem' }}>
                  {t('mypage.change_payment_method')}
                </TextButton>
              </Korean>
            </Box>
          </CenterAlignStack>
        </Stack>
      )
    } else if (setting === 'cancel') {
      // 구독 취소 예정인 상태
      return (
        <Stack spacing="2rem">
          <Typography
            sx={{
              fontSize: { lg: '3.2rem', xs: '2rem' },
              lineHeight: { lg: '4rem', xs: '2.4rem' },
              fontWeight: 700,
              color: theme.palette.common.black,
            }}
          >
            <Korean>혜택을 놓치지 마세요!</Korean>
            <English>Don’t miss out on the benefits!</English>
          </Typography>

          <Stack
            className="content-box"
            sx={{
              py: '1.6rem',
              px: { lg: '2.4rem', xs: '1.6rem' },
              mt: '2rem',
              '& .row': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
              '& .text': {
                fontSize: '1.6rem',
                fontWeight: 400,
                '&.field': {},
                '&.value': { fontWeight: 600 },
              },
            }}
            spacing="2rem"
          >
            <Stack className="row">
              <Typography className="text field">{t('title.pricing')}</Typography>
              <Typography className="text value">{getPlanDisplayName(planId)}</Typography>
            </Stack>
            <Stack className="row">
              <Typography className="text field">{t('credit.expire_date')}</Typography>
              <Typography className="text value">
                {trasnferDate(currentSubscription.end_date, 'detail', i18n)}
              </Typography>
            </Stack>
            <Stack className="row">
              <Korean>
                <Typography className="text field">
                  ・월정액으로 충전한 크레딧은 만료일 이후 모두 사라집니다.
                </Typography>
              </Korean>
              <English>
                <Typography className="text field" sx={{ fontSize: '1.3rem !important' }}>
                  ・Subscription credits will be expired after the expiration date.
                </Typography>
              </English>
            </Stack>
          </Stack>

          {/* ---- */}

          {/* <Typography
            sx={{
              fontSize: { lg: '2.6rem', xs: '1.9rem' },
              lineHeight: { lg: '3.7rem', xs: '2.3rem' },
              fontWeight: 600,
            }}
          >
            {(currentSubscription?.charge_currency &&
              currentSubscription?.charge_price &&
              getSubscriptionPrice(
                currentSubscription.charge_currency,
                currentSubscription.charge_price
              )) ??
              getPlanPrice(creditPlans, planId)}{' '}
            {t('mypage.monthly_price')}
          </Typography> */}

          <MainButton variant="contained" size="large" onClick={reSubscribe} sx={{ width: '100%' }}>
            {t('button.retry_subscription')}
          </MainButton>
        </Stack>
      )
    } else {
      return (
        <Stack sx={{ width: '100%' }} spacing="2rem">
          <Typography
            className="gradient-text"
            sx={{
              fontSize: { lg: '3.2rem', xs: '2rem' },
              fontWeight: 700,
            }}
          >
            <Korean>지금 바로 구독해보세요!</Korean>
            <English>Subscribe Now!</English>
          </Typography>
          <Stack className="content-box" sx={{ p: '1.6rem' }}>
            <Stack
              spacing="6px"
              sx={{
                '& .row': {
                  // display: 'flex',
                  alignItems: 'center',
                  '& svg': { display: 'none' },
                  '&::before': {
                    content: '"•"',
                    marginRight: '0.3rem',
                    fontSize: '10px',
                    verticalAlign: 'text-top',
                  },
                },
              }}
            >
              <PlanExplanation
                name="subscription"
                showPriorityInfo={true}
                subEventValid={subEventValid}
              />
            </Stack>
          </Stack>

          <Desktop>
            <Divider sx={{ my: { lg: '2rem' } }} />
          </Desktop>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <MainButton
              variant="contained"
              size="large"
              sx={{ width: '100%' }}
              onClick={() => {
                navigate('/pricing')
              }}
            >
              <Korean>요금제 보러가기</Korean>
              <English>View Subscription Plans</English>
            </MainButton>
          </Box>
        </Stack>
      )
    }
  }

  return (
    <>
      <MypageCard
        title={
          <>
            <Korean>내 구독</Korean>
            <English>My Subscription</English>
          </>
        }
        highlightLabel={currentSubscription?.id && setting !== 'cancel'}
        label={
          currentSubscription?.payment_status === 'failed'
            ? 'Payment Failure'
            : setting === 'cancel'
            ? 'Canceling'
            : currentSubscription?.id
            ? 'Activated'
            : null
        }
        failedLabel={currentSubscription?.id && currentSubscription?.payment_status === 'failed'}
      >
        {selectCard()}
      </MypageCard>

      <SubscriptionPaymtneMethodDialog
        // open={true}
        open={openChangePaymentMethodDialog}
        setOpen={setOpenChangePaymentMethodDialog}
        subInfo={currentSubscription}
        handleChangePaymentMethod={handleChangePaymentMethod}
      />
    </>
  )
}

function MypageCard({
  title,
  titleLink = null,
  label,
  highlightLabel = false,
  failedLabel,
  children,
}) {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        width: '100%',
        background: 'white',
        border: failedLabel ? '1px solid #FF2323' : '1px solid #d9d9d9',
        borderRadius: '1rem',
        py: { lg: '3.2rem', xs: '2.4rem' },
        px: { lg: '3.4rem', xs: '2rem' },

        '& .content-box': {
          width: '100%',
          background: '#F8F8F8',
          borderRadius: '1rem',
        },
      }}
    >
      <Box
        gap={{ lg: '8px', xs: '4px' }}
        sx={{
          display: 'flex',
          mb: { lg: '1.6rem', xs: '1.4rem' },
          alignItems: 'center',
          '& .text': {
            '&.title': {
              fontWeight: 700,
              fontSize: { lg: '2rem', xs: '1.6rem' },
              lineHeight: { lg: '2.4rem', xs: '2rem' },

              cursor: titleLink ? 'pointer' : 'unset',
            },
            '&.label': {
              background: theme =>
                failedLabel
                  ? 'transparent'
                  : highlightLabel
                  ? theme.palette.draph.blue
                  : theme.palette.common.black,
              color: failedLabel ? '#FF2323' : 'white',
              border: failedLabel ? '1px solid #FF2323' : 'inherit',
              borderRadius: '4px',
              height: '2.4rem',
              alignItems: 'center',
              display: 'flex',
              // py: '0.5rem',
              px: '1rem',

              fontWeight: 600,
              fontSize: { lg: '1.2rem', xs: '1.2rem' },
              lineHeight: { lg: '1.43rem', xs: '1.43rem' },
            },
          },
        }}
      >
        <Typography
          className="text title"
          onClick={
            titleLink
              ? () => {
                  navigate(titleLink)
                }
              : () => {}
          }
        >
          {title}
        </Typography>
        {label && <Typography className="text label">{label}</Typography>}
      </Box>
      {children}
    </Box>
  )
}

const MypageCreditCard = ({ userCredit }) => {
  const navigate = useNavigate()

  const selectCard = () => {
    if (userCredit !== 0) {
      return (
        <MypageCard title={t('mypage.my_credit')} label="Credit">
          <Typography
            sx={{
              fontSize: { lg: '3.2rem', xs: '2rem' },
              lineHeight: { lg: '3.8rem', xs: '2.4rem' },
              fontWeight: 700,

              '& span': {
                fontSize: { lg: '2.4rem', xs: '1.8rem' },
                lineHeight: { lg: '2.86rem', xs: '2rem' },
                fontWeight: 500,
                ml: '4px',
              },
            }}
          >
            {numberCommaFormatter(userCredit)}
            <span>credits</span>
          </Typography>
          <Divider sx={{ my: '2rem' }} />

          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
            <MainButton
              variant="contained"
              onClick={() => {
                navigate('/pricing')
              }}
              sx={{ width: { lg: '18rem', xs: '100%' } }}
            >
              {t('button.charge_credit')}
            </MainButton>
          </Box>
        </MypageCard>
      )
    } else {
      return (
        <MypageCard title={t('mypage.my_credit')} label="Credit">
          <Typography
            sx={{
              fontSize: { lg: '3.2rem', xs: '2.6rem' },
              lineHeight: { lg: '3.8rem', xs: '3.1rem' },
              fontWeight: 700,

              '& span': {
                fontSize: { lg: '2.4rem', xs: '2.6rem' },
                lineHeight: { lg: '2.86rem', xs: '3.1rem' },
                fontWeight: 500,
                ml: '4px',
              },
            }}
          >
            {numberCommaFormatter(userCredit)}
            <span>credit</span>
          </Typography>

          <Typography
            sx={{
              fontSize: '1.6rem',
              fontWeight: 400,
              mt: { lg: '1rem' },
            }}
          >
            {t('mypage.no_credit_comment_1')}
            <br />
            <span style={{ fontWeight: 800 }}>
              {t('mypage.no_credit_comment_2_a')}
              {t('mypage.no_credit_comment_2_b')}
            </span>
          </Typography>
          <Divider sx={{ my: { lg: '2rem' } }} />

          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
            <MainButton
              variant="contained"
              onClick={() => {
                navigate('/pricing')
              }}
            >
              {t('button.charge_credit')}
            </MainButton>
          </Box>
        </MypageCard>
      )
    }
  }

  return (
    <>
      <Desktop>{selectCard()}</Desktop>
      <Mobile>{selectCard()}</Mobile>
    </>
  )
}

function MypageCreditHistoryCard() {
  const HISTORY_MAX = 5
  const [creditHistory, setCreditHistory] = useState([])
  const [userCreditInfo, setUserCreditInfo] = useRecoilState(userCreditInfoAtom)
  useEffect(() => {
    setUserCreditInfo(1)
  }, [])

  const typeLabelDict = {
    added: (
      <>
        <Korean>크레딧 충전</Korean>
        <English>Charged</English>
      </>
    ),
    used: (
      <>
        <Korean>크레딧 사용</Korean>
        <English>Used</English>
      </>
    ),
    expired: (
      <>
        <Korean>크레딧 만료</Korean>
        <English>Expired</English>
      </>
    ),
  }

  useEffect(() => {
    const m = moment().utcOffset()

    apis.user.getCreditSummary(m / 60).then(response => {
      const summary = response.data

      const result = []
      summary.some(entry => {
        if (entry.added !== null)
          result.push({ date: entry.date, type: 'added', value: entry.added })
        if (result.length >= HISTORY_MAX) return true
        if (entry.used !== null) result.push({ date: entry.date, type: 'used', value: entry.used })
        if (result.length >= HISTORY_MAX) return true
        if (entry.expired !== null)
          result.push({ date: entry.date, type: 'expired', value: entry.expired })
        return result.length >= HISTORY_MAX
      })

      setCreditHistory(result)
    })
  }, [])

  return (
    <>
      <MypageCard
        title={t('mypage_menu.credit_history')}
        label="History"
        titleLink="/user/creditInfo"
      >
        <Stack
          className="content-box"
          sx={{
            p: { lg: '2.4rem', xs: '1.6rem' },
            '& .row': {
              justifyContent: 'space-between',
              '& .date': { fontSize: '1.6rem', fontWeight: 600, lineHeight: '2rem' },
              '& .type': { fontSize: '1.2rem', fontWeight: 400, lineHeight: '1.6rem' },
              '& .value': { fontSize: '1.6rem', fontWeight: 600 },
            },
          }}
          spacing="2rem"
        >
          {creditHistory.map((d, i) => (
            <Stack direction="row" key={i} sx={{ alignItems: 'center' }} className="row">
              <Box sx={{ alignItems: 'end' }}>
                <Typography className="date">{d.date}</Typography>
                <Typography className="type">{typeLabelDict[d.type]}</Typography>
              </Box>
              <Typography className="value">
                {d.value > 0 && '+'}
                {d.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </MypageCard>
    </>
  )
}

function MypageSavedCard() {
  const { i18n } = useTranslation()
  const user = useRecoilValue(userAtom)
  const [userStat, setUserStat] = useState(null)

  useEffect(() => {
    apis.user.getUserStat().then(r => {
      const stat = r.data

      setUserStat(stat)
    })
  }, [])

  return (
    <>
      <MypageCard
        title={
          <>
            <Korean>내가 아낀 비용</Korean>
            <English>Your Savings</English>
          </>
        }
        label="Saved"
      >
        <Typography
          sx={{
            fontSize: { lg: '3.2rem', xs: '2rem' },
            lineHeight: { lg: '4rem', xs: '2.8rem' },

            fontWeight: 700,
            '& .blue': { color: theme => theme.palette.draph.blue },
          }}
        >
          <Korean>
            현재까지{' '}
            <span className="blue">
              총 {numberCommaFormatter(nullZero(userStat?.piece[0]?.piece) * 60000)}원
            </span>
            의 비용을 아꼈습니다.
          </Korean>
          <English>
            You have saved a total of{' '}
            <span className="blue">
              ${numberCommaFormatter(nullZero(userStat?.piece[0]?.piece) * 43)}{' '}
            </span>
            so far.
          </English>
        </Typography>

        <Stack
          className="content-box"
          sx={{
            mt: '2rem',
            py: { lg: '1.6rem', xs: '1.6rem' },
            px: { lg: '2.4rem', xs: '1.6rem' },
            '& .row': {
              justifyContent: 'space-between',
              fontSize: '1.6rem',
              '& .field': { fontWeight: 400 },
              '& .value': { fontWeight: 600 },
            },
          }}
          spacing="2rem"
        >
          <Stack direction="row" className="row">
            <Typography className="field">
              <Korean>드랩 가입일</Korean>
              <English>Date of Registration</English>
            </Typography>
            <Typography className="value">{trasnferDate(user?.created, 'detail', i18n)}</Typography>
          </Stack>
          <Stack direction="row" className="row">
            <Typography className="field">
              <Korean>생성 이미지 수</Korean>
              <English>Number of Images Created</English>
            </Typography>
            <Typography className="value">
              {numberCommaFormatter(nullZero(userStat?.piece[0]?.piece))} <Korean>장</Korean>
            </Typography>
          </Stack>
        </Stack>
      </MypageCard>
    </>
  )
}

export function SubscriptionPaymtneMethodDialog({
  open,
  setOpen,
  subInfo,
  handleChangePaymentMethod,
}) {
  // 구독 정기 결제가 실패하여 구독정보 카드에 '결제수단을 변경해달라'는 경고문구 표시된 경우
  // 그 문구를 클릭하면 열리는 다이얼로그

  const { t, i18n } = useTranslation()

  const [creditExpDate, setCreditExpDate] = useState()

  useEffect(() => {
    if (subInfo.sub_credit_expires) {
      const utcDate = moment.utc(subInfo.sub_credit_expires).toDate()
      const oneDayLater = moment(utcDate).add(1, 'days')

      const localDateString = oneDayLater.local().format('YYYY/MM/DD')

      setCreditExpDate(localDateString)
    }
  }, [subInfo])

  const handleClick = () => {
    setOpen(false)
    handleChangePaymentMethod()
  }

  const remainingDays =
    moment(subInfo?.payment_method_update_exp)
      .startOf('day')
      .diff(moment().startOf('day'), 'days') + 1

  // utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
        >
          <DialogContent sx={{ p: '3.2rem 2.4rem 2.8rem 2.4rem' }}>
            <CenterAlignStack>
              <CenterAlignStack
                sx={{
                  '& .text': {
                    fontSize: '2rem',

                    fontWeight: 700,
                    lineHeight: 1.2,
                    textAlign: 'center',
                    '& .blue': { color: theme => theme.palette.draph.blue },
                  },
                  mb: '2rem',
                }}
              >
                <Korean>
                  <Typography className="text">
                    월정액 자동 결제가 되지 않았습니다.
                    <br />
                    <span className="blue">결제 수단을 변경</span>해주세요.
                  </Typography>
                </Korean>

                <English>
                  <Typography className="text">
                    There was an issue with your payment method <br />
                    so the automatic payment did not go through.
                    <br />
                    <span className="blue">Please change your payment method.</span>
                  </Typography>
                </English>
              </CenterAlignStack>

              <CenterAlignStack
                sx={{
                  '& .text': {
                    lineHeight: 'normal',
                    // textAlign: 'center',
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    '& .blue': { color: theme => theme.palette.draph.blue },
                  },
                  background: '#F8F8F8',

                  p: '1.6rem',
                  mb: '2rem',
                  borderRadius: '10px',
                  width: '40rem',
                }}
              >
                <Korean>
                  <Typography className="text">
                    등록된 결제 수단에 문제가 발생하여 자동 결제가 되지 않아, 크레딧이 임시로
                    소멸조치 되었습니다.{' '}
                    {/* {subInfo.temp_expired_credit_sum && (
                      <>
                        이에 월정액으로 충전된 크레딧이 임시로 소멸 조치되었습니다.
                        <br />
                      </>
                    )} */}
                    {subInfo.payment_method_update_exp && (
                      <>
                        <span className="blue">
                          {utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')}
                        </span>{' '}
                        까지 결제 수단을 변경해주세요.
                      </>
                    )}
                    <br />
                    <br />
                    <ul style={{ marginLeft: '2.5rem', fontWeight: 600 }}>
                      <li>결제 수단 변경 시 : 크레딧 복구 + 구독 자동 연장</li>
                      <li>결제 수단 미변경 시 : 크레딧 소멸 + 구독 취소</li>
                    </ul>
                    {/* {subInfo.temp_expired_credit_sum ? (
                      <>
                        결제 수단을 변경하시면 드랩아트 월정액을 계속 구독하실 수 있으며, 임시로
                        소멸된 크레딧도 모두 복구됩니다. <br />
                        <br />
                        단, 기한 내에 결제 수단을 변경하지 않는 경우 월정액 구독이 자동 취소되며,
                        임시로 소멸 조치된 크레딧 역시 복구되지 않습니다.
                      </>
                    ) : (
                      <>
                        결제 수단을 변경하시면 드랩아트 월정액을 계속 구독하실 수 있습니다.
                        <br />
                        <br />
                        만약 기한 내에 결제 수단을 변경하지 않는 경우 월정액 구독이 자동 취소되며,
                        구독이 취소되면 월정액 구독으로 충전된 크레딧도 모두 사라집니다.
                      </>
                    )} */}
                  </Typography>
                </Korean>
                <English>
                  <Typography className="text">
                    There was an issue with your payment method so the automatic payment did not go
                    through.{' '}
                    {subInfo.temp_expired_credit_sum && (
                      <>
                        Hence your charged credits are now temporarily expired.
                        <br />
                      </>
                    )}
                    If you update your payment method{' '}
                    {subInfo.payment_method_update_exp && (
                      <>
                        by{' '}
                        <span className="blue">
                          {utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')}
                        </span>{' '}
                      </>
                    )}
                    {subInfo.temp_expired_credit_sum ? (
                      <>
                        your subscription will continue and the expired credits will be restored.{' '}
                        <br />
                        <br />
                        However, if the payment method update is not proceeded within the given
                        period, your monthly subscription will be canceled automatically and the
                        expired credits will not be restored.
                      </>
                    ) : (
                      <>
                        your subscription will continue.
                        <br />
                        <br />
                        However, if the payment method update is not proceeded within the given
                        period, your monthly subscription will be canceled automatically and the
                        credits charged from your subscription will also expire.
                      </>
                    )}
                  </Typography>
                </English>
              </CenterAlignStack>

              {(subInfo.temp_expired_credit_sum || subInfo.subscription_credit) && (
                <CenterAlignStack
                  sx={{
                    '& .text': {
                      lineHeight: 1.3,
                      textAlign: 'center',
                      fontSize: '1.8rem',
                      fontWeight: 500,
                      '&.large': {
                        fontSize: '2.4rem',
                      },
                      '&.bold': {
                        color: '#FF2323',
                        fontWeight: 700,
                      },
                      '& span': {
                        color: '#FF2323',
                        fontWeight: 700,
                      },
                    },
                    mb: '2rem',
                  }}
                >
                  <Korean>
                    {/* {subInfo.temp_expired_credit_sum ? (
                      <Typography className="text">임시 소멸 조치된 크레딧</Typography>
                    ) : subInfo.subscription_credit ? (
                      <Typography className="text">소멸 예정 크레딧</Typography>
                    ) : (
                      <></>
                    )}

                    <Typography className="text large bold">
                      {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} 크레딧
                    </Typography> */}
                    <Typography className="text">
                      앞으로 <span className="bold">{remainingDays}일 후</span>에{' '}
                      <span className="bold">모든 크레딧이 영구 소멸</span>됩니다.
                    </Typography>
                    <Typography className="text large bold" sx={{ mt: '2.3rem' }}>
                      {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} 크레딧
                    </Typography>
                  </Korean>
                  <English>
                    {subInfo.temp_expired_credit_sum ? (
                      <Typography className="text">Temporarily expired</Typography>
                    ) : subInfo.subscription_credit ? (
                      <Typography className="text">Expected to expire</Typography>
                    ) : (
                      <></>
                    )}

                    <Typography className="text large bold">
                      {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} Credits
                    </Typography>
                  </English>
                </CenterAlignStack>
              )}

              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  height: '5rem',
                  fontSize: '1.6rem',
                  fontWeight: 600,
                  borderRadius: '4px',
                }}
                onClick={handleClick}
              >
                {t('mypage.change_payment_method_dialog_button')}
              </Button>
            </CenterAlignStack>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {}}
          onClose={() => {
            setOpen(false)
          }}
        >
          <CenterAlignStack sx={{ pb: '2.2rem' }}>
            <CenterAlignStack
              sx={{
                width: '32rem',
                alignItems: 'center',
                '& .text': {
                  // textAlign: 'center',
                  fontSize: '1.6rem',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  width: 'inherit',
                  '& .blue': { color: theme => theme.palette.draph.blue },
                },
                mt: '1rem',
                mb: '2rem',
              }}
            >
              <Korean>
                <Typography className="text">
                  월정액 자동 결제가 되지 않았습니다.
                  <br />
                  <span className="blue">결제 수단을 변경</span>해주세요.
                </Typography>
              </Korean>

              <English>
                <Typography className="text">
                  There was an issue with your payment method so the automatic payment did not go
                  through.
                  <br />
                  <span className="blue">Please change your payment method.</span>
                </Typography>
              </English>
            </CenterAlignStack>

            <CenterAlignStack
              sx={{
                '& .text': {
                  lineHeight: 'normal',
                  // textAlign: 'center',
                  fontSize: '1.4rem',
                  fontWeight: 400,
                  '& .blue': { color: theme => theme.palette.draph.blue },
                  '& .bold': { fontWeight: 600 },
                },

                background: '#F8F8F8',
                p: '1.6rem 1.2rem',
                mb: '2rem',
                borderRadius: '10px',
                width: '32rem',
              }}
            >
              <Korean>
                <Typography className="text">
                  등록된 결제 수단에 문제가 발생하여 자동 결제가 되지 않아, 크레딧이 임시로 소멸조치
                  되었습니다.{' '}
                  {/* {subInfo.temp_expired_credit_sum && (
                      <>
                        이에 월정액으로 충전된 크레딧이 임시로 소멸 조치되었습니다.
                        <br />
                      </>
                    )} */}
                  {subInfo.payment_method_update_exp && (
                    <>
                      <span className="blue">
                        {utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')}
                      </span>{' '}
                      까지 결제 수단을 변경해주세요.
                    </>
                  )}
                  <br />
                  <br />
                  <span className="bold">결제 수단 변경 시 : 크레딧 복구 + 구독 자동 연장</span>
                  <br />
                  <span className="bold">결제 수단 미변경 시 : 크레딧 소멸 + 구독 취소</span>
                  {/* {subInfo.temp_expired_credit_sum ? (
                      <>
                        결제 수단을 변경하시면 드랩아트 월정액을 계속 구독하실 수 있으며, 임시로
                        소멸된 크레딧도 모두 복구됩니다. <br />
                        <br />
                        단, 기한 내에 결제 수단을 변경하지 않는 경우 월정액 구독이 자동 취소되며,
                        임시로 소멸 조치된 크레딧 역시 복구되지 않습니다.
                      </>
                    ) : (
                      <>
                        결제 수단을 변경하시면 드랩아트 월정액을 계속 구독하실 수 있습니다.
                        <br />
                        <br />
                        만약 기한 내에 결제 수단을 변경하지 않는 경우 월정액 구독이 자동 취소되며,
                        구독이 취소되면 월정액 구독으로 충전된 크레딧도 모두 사라집니다.
                      </>
                    )} */}
                </Typography>
              </Korean>
              <English>
                <Typography className="text">
                  There was an issue with your payment method so the automatic payment did not go
                  through.{' '}
                  {subInfo.temp_expired_credit_sum && (
                    <>
                      Hence your charged credits are now temporarily expired.
                      <br />
                    </>
                  )}
                  If you update your payment method{' '}
                  {subInfo.payment_method_update_exp && (
                    <>
                      by{' '}
                      <span className="blue">
                        {utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')}
                      </span>{' '}
                    </>
                  )}
                  {subInfo.temp_expired_credit_sum ? (
                    <>
                      your subscription will continue and the expired credits will be restored.{' '}
                      <br />
                      <br />
                      However, if the payment method update is not proceeded within the given
                      period, your monthly subscription will be canceled automatically and the
                      expired credits will not be restored.
                    </>
                  ) : (
                    <>
                      your subscription will continue.
                      <br />
                      <br />
                      However, if the payment method update is not proceeded within the given
                      period, your monthly subscription will be canceled automatically and the
                      credits charged from your subscription will also expire.
                    </>
                  )}
                </Typography>
              </English>
            </CenterAlignStack>

            {(subInfo.temp_expired_credit_sum || subInfo.subscription_credit) && (
              <CenterAlignStack
                sx={{
                  '& .text': {
                    lineHeight: 1.3,
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    fontWeight: 500,
                    '&.large': {
                      fontSize: '2.4rem',
                    },
                    '&.bold': {
                      color: '#FF2323',
                      fontWeight: 700,
                    },
                    '& span': {
                      color: '#FF2323',
                      fontWeight: 700,
                    },
                  },
                }}
              >
                <Korean>
                  {/* {subInfo.temp_expired_credit_sum ? (
                    <Typography className="text">임시 소멸 조치된 크레딧</Typography>
                  ) : subInfo.subscription_credit ? (
                    <Typography className="text">소멸 예정 크레딧</Typography>
                  ) : (
                    <></>
                  )}

                  <Typography className="text large bold">
                    {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} 크레딧
                  </Typography> */}
                  <Typography className="text">
                    앞으로 <span className="bold">{remainingDays}일 후</span>에{' '}
                    <span className="bold">모든 크레딧이 영구 소멸</span>됩니다.
                  </Typography>
                  <Typography className="text large bold" sx={{ mt: '1.6rem', mb: '3.2rem' }}>
                    {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} 크레딧
                  </Typography>
                </Korean>
                <English>
                  {subInfo.temp_expired_credit_sum ? (
                    <Typography className="text">Temporarily expired</Typography>
                  ) : subInfo.subscription_credit ? (
                    <Typography className="text">Expected to expire</Typography>
                  ) : (
                    <></>
                  )}

                  <Typography className="text large bold">
                    {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} Credits
                  </Typography>
                </English>
              </CenterAlignStack>
            )}

            <Button
              variant="contained"
              sx={{
                width: '32rem',
                height: '4rem',
                fontSize: '1.6rem',
                fontWeight: 600,
                borderRadius: '4px',
                mb: '0.7rem',
              }}
              onClick={handleClick}
            >
              {t('mypage.change_payment_method_dialog_button')}
            </Button>
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}
