import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import {
  CenterAlignBox,
  CenterAlignStack,
  CustomOutlinedInput,
  DialogButton,
  FragmentImages,
  ShadowButton,
  SpanDraphBlue,
  processInGroups,
  triggerGA4UploadEventManualConfig,
} from 'components'
import { useEffect, useState, useRef, forwardRef } from 'react'
import { PlusIcon, RefreshIcon, UndoArrowIcon, UndoIcon } from 'theme/icon'
import './uploadimage.css'

import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  portfolioArtworkAtom,
  portfolioDetailAtom,
  userAtom,
  selectedMaskImgAtom,
  maskImgArrayAtom,
  mannequinLoadingAtom,
  uploadFilesAtom,
  dragDropOpenAtom,
  uploadDialogOpenAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  retryMannequinAtom,
  uploadDialogOpen2Atom,
  brandConfigUpdateAtom,
  unusualPortfolioConfigUpdateAtom,
  artworkPageAtom,
  uploadFilesAndUrlAtom,
  regenerateUploadFilesAndUrlAtom,
  maskImgAtom,
} from 'atoms'

import { Divider, Grid, OutlinedInput, Slide, SwipeableDrawer, useTheme } from '@mui/material'

import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile, useBreakPoint, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { allowedTypes, iOS, isKo, resizeImage } from 'utils/common'
import * as config from 'config'
import { FadeLoader, PuffLoader } from 'react-spinners'
import { apis } from 'apis'
import _ from 'lodash'

import { useCheckImage, useRefineUploadFiles } from 'hooks/useRefineUploadFiles'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { StyledToggleButton } from './BannerConfig'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { usePortfolio } from 'hooks/usePortfolio'
import { getUserType } from 'utils/user'
import { useApiHandler } from 'hooks/useApiHandler'
import axios from 'axios'
import { decodeMask2RLE, imageData2DToImage } from 'components/fragment/fragmentHelpers'

const getImagePromise = src => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve([img.width, img.height])
    img.onerror = reject
    img.src = src
  })
}

const fontStyles = {
  actionTitle: {
    fontSize: '2rem',
    fontWeight: 800,
    lineHeight: 'normal',
  },
  actionSubtitle: {
    fontSize: { lg: '1.6rem', xs: '1.5rem' },
    fontWeight: 700,
    lineHeight: 'normal',
  },
}

const uploadImglimit = 20

// export default function UploadMannequinDialog({
//   firstNotice = null,
//   secondNotice = null,
//   promptInput = true,
//   genderSelect = true,
//   usage = 'default',
//   useUploadOpen2 = false,
// }) {
//   const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()
//   const portfolioDetail = useRecoilValue(portfolioDetailAtom)
//   const retryMannequin = useRecoilValue(retryMannequinAtom)
//   const resetDragDropOpen = useResetRecoilState(dragDropOpenAtom)
//   const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)
//   const resetMannequinLoading = useResetRecoilState(mannequinLoadingAtom)
//   const resetSegmentLoading = useResetRecoilState(segmentLoadingAtom)
//   const resetSegmentStep = useResetRecoilState(segmentStepAtom)
//   const resetRetryMannequin = useResetRecoilState(retryMannequinAtom)
//   const resetFiles = useResetRecoilState(regenerateUploadFilesAndUrlAtom)
//   const resetUploadFiles = useResetRecoilState(uploadFilesAndUrlAtom)

//   const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)
//   const [uploadOpen2, setUploadOpen2] = useRecoilState(uploadDialogOpen2Atom)

//   const [files, setFiles] = useRecoilState(regenerateUploadFilesAndUrlAtom)
//   const [dragActive, setDragActive] = useRecoilState(dragDropOpenAtom)

//   const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
//   const [segmentStep, setSegmentStep] = useRecoilState(segmentStepAtom)
//   const [loading, setLoading] = useRecoilState(mannequinLoadingAtom)
//   const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
//   const [brandConfig, setBrandConfig] = useRecoilState(brandConfigUpdateAtom)
//   const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
//   const [page, setPage] = useRecoilState(artworkPageAtom)

//   const { showConfirm } = useConfirm()
//   const user = useRecoilValue(userAtom)
//   const uploadFiles = useRecoilValue(uploadFilesAndUrlAtom)

//   const [concept, setConcept] = useState('')
//   const [gender, setGender] = useState('female')
//   const [age, setAge] = useState('adult')

//   const { t } = useTranslation()
//   const isMobile = useMobileMediaQuery()

//   const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

//   const portfolioArtworklimit = portfolioDetail.is_default ? Infinity : 200

//   const filesRef = useRef(files)

//   useEffect(() => {
//     filesRef.current = files
//   }, [files])

//   useEffect(() => {
//     return () => {
//       reset()
//     }
//   }, [])

//   const reset = () => {
//     // console.log('revoke_11')
//     filesRef.current.forEach(image => URL.revokeObjectURL(image.url))
//     resetFiles()
//     uploadFiles.forEach(image => URL.revokeObjectURL(image.url))
//     resetUploadFiles()

//     resetDragDropOpen()
//     resetSelectedMaskImg()
//     resetMannequinLoading()
//     resetSegmentLoading()
//     resetSegmentStep()
//     resetRetryMannequin()
//     setGender('female')
//   }

//   const handleClose = () => {
//     useUploadOpen2 ? setUploadOpen2(false) : setUploadOpen(false)
//   }

//   const handleDrag = function (e) {
//     e.preventDefault()
//     e.stopPropagation()

//     if (e.type === 'dragenter' || e.type === 'dragover') {
//       setDragActive(true)
//     } else if (e.type === 'dragleave') {
//       setDragActive(false)
//     }
//   }

//   const handleAction = () => {
//     switch (usage) {
//       case 'banner':
//         return async () => {
//           setLoading(true)
//           const mask = await imgMergeAndGrayScale(selectedMaskImg)

//           // const genOption = {
//           //   ...config.MANN2MAN_GENOPTION_DEFAULT,
//           //   mann2man_gender: gender,
//           //   mann2man_age: age, // 여기 변경 !
//           // }

//           const filesArray = files.map(f => f.file)

//           const mannInfo = {
//             mann2man_image: filesArray[0],
//             binary_mask: mask,
//             mann2man_is_male: gender === 'male',
//             mann2man_age: age,
//             human_depict: concept,
//             gen_type: 'mann2man',
//             bgDepict: '',
//           }
//           const option = {
//             ...config.BANNER_ARTWORK_CONFIG,
//             logoS3path: brandConfig.logoS3path,
//             humanDepict: concept,
//             bgDepict: bannerConfig.bgDepict,
//             brandFeature: brandConfig.brandFeature,
//             productFeature: bannerConfig.productFeature,
//           }

//           const formData = await makeUploadFormData(filesArray, option, mannInfo, 'banner')

//           // ----- GA4 event -----
//           const eventParams = {
//             output_size_width: bannerConfig?.outputW,
//             output_size_height: bannerConfig?.outputH,
//             config_object_category: bannerConfig?.productCategory ? 'yes' : 'no',
//             config_object_sub_category:
//               bannerConfig?.genType === 'full'
//                 ? 'no'
//                 : bannerConfig?.genType === 'mann2man'
//                 ? 'wearing'
//                 : bannerConfig?.genType === 'side'
//                 ? 'model'
//                 : '',

//             menu: gender,
//             method: age,

//             count: 1,
//           }

//           window.gtag('event', 'banner_upload', eventParams)
//           // ---------------------

//           apis.appfront
//             .makeBanner(formData)
//             .then(() => {
//               handleClose()
//               checkUserCredit()
//               refreshArtworks()

//               if (page === 1) {
//                 const anchor = document.querySelector(`#scroll-anchor`)

//                 if (anchor) {
//                   anchor.scrollIntoView({
//                     block: 'start',
//                     behavior: 'smooth',
//                   })
//                 }
//               }
//             })
//             .catch(() => {
//               showConfirm({
//                 content: t('upload_dialog.warning'),
//                 alertOnly: true,
//               })
//             })
//             .finally(() => {
//               setLoading(false)
//             })
//         }

//       case 'default':
//         return async () => {
//           setLoading(true)
//           const feedback = 'regenerate_auto'

//           const mask = await imgMergeAndGrayScale(selectedMaskImg)

//           const formData = new FormData()

//           if (!retryMannequin.isRetry) {
//             const ready = await prepareUpload([{}], null, null, null, true)
//             if (!ready.success) return
//           }
//           const config = retryMannequin.config

//           formData.append('image', files[0].file)
//           formData.append('binary_mask', mask)
//           formData.append('user_id', user.id)
//           formData.append('username', user.username)
//           formData.append('user_type', getUserType(user))
//           formData.append('gen_type', retryMannequin.isRetry ? 'retry' : 'initial')
//           formData.append('is_male', gender === 'male')
//           formData.append('mann2man_type', config.mann2man_type ?? 'mann')

//           formData.append('portfolio_id', portfolioDetail.id)
//           formData.append('artwork_id', retryMannequin.isRetry ? retryMannequin.artworkId : '')
//           formData.append('prompt', concept)
//           formData.append('age', age)

//           // ----- GA4 event -----
//           triggerGA4UploadEventManualConfig(
//             {
//               image_type: config?.mann2man_type === 'human' ? '일반 이미지' : '마네킹',
//               menu: gender === 'male' ? '남' : '여', // 성별
//               method: age === 'baby' ? '아기' : age === 'child' ? '어린이' : '성인', // 연령대
//               theme: concept.length > 0, // 배경 테마가 공란인지(false) 입력값있는지(true)
//             },
//             retryMannequin.isRetry ? 'mannequin_regenerate' : 'mannequin_complete'
//           )
//           // ---------------------

//           try {
//             if (retryMannequin.isRetry) {
//               // Update artwork feedback

//               await apis.portfolio.updateArtworkFeedback(
//                 portfolioDetail.id,
//                 retryMannequin.artworkId,
//                 { feedback }
//               )

//               // Get man-to-man data
//               await apis.appfront.getMann2man(formData)

//               if ('Notification' in window && Notification.permission === 'default') {
//                 showBrowserNotificationDialog()
//               }

//               // Create a new config object
//               const beforeConfig = retryMannequin.config
//               const afterConfig = JSON.stringify({
//                 ...beforeConfig,
//                 age: age,
//                 is_male: gender === 'male',
//                 prompt: concept,
//               })

//               // Update artwork with new config
//               const response = await apis.portfolio.updateArtwork(
//                 portfolioDetail.id,
//                 retryMannequin.artworkId,
//                 {
//                   config: afterConfig,
//                 }
//               )

//               // Refresh artworks and close the modal
//               refreshArtworks()
//               handleClose()
//             } else {
//               await apis.appfront.getMann2man(formData)

//               if ('Notification' in window && Notification.permission === 'default') {
//                 showBrowserNotificationDialog()
//               }

//               handleClose()
//               refreshArtworks()
//               reset()
//             }
//           } catch (error) {
//             showConfirm({
//               content: t('upload_dialog.warning'),
//               alertOnly: true,
//             })
//           } finally {
//             setLoading(false)
//           }
//         }
//     }
//   }

//   return (
//     <>
//       <Desktop>
//         <Dialog
//           open={useUploadOpen2 ? uploadOpen2 : uploadOpen}
//           onClose={segmentLoading ? () => {} : handleClose}
//           // disableScrollLock={true}
//           sx={{
//             overflowX: 'hidden',
//             '& .MuiDialog-paper': {
//               minWidth: '68rem',
//               minHeight: '37.5rem',
//               maxWidth: 'none',
//               maxHeight: '77.4rem',

//               borderRadius: '10px',
//               p: segmentStep === 1 ? '2.5rem 0rem 2.6rem 0rem' : '2.8rem 4rem 2.8rem 4rem',
//               width: segmentStep === 1 ? '88rem' : 'auto',
//             },
//           }}
//         >
//           <CenterAlignStack sx={{}} onDragEnter={handleDrag}>
//             <Stack sx={{ mb: '1.8rem', justifyItems: 'center', alignItems: 'center' }}>
//               {segmentStep !== 1 &&
//                 (firstNotice ?? (
//                   <CenterAlignStack>
//                     {retryMannequin.isRetry ? (
//                       <>
//                         <Typography fontSize="2rem" fontWeight={700}>
//                           {t('mannequin.retry_title')}
//                         </Typography>
//                         <Typography
//                           fontSize="1.5rem"
//                           fontWeight={400}
//                           sx={{ textAlign: 'center', lineHeight: 'normal' }}
//                         >
//                           {t('mannequin.retry_comment')}
//                         </Typography>
//                       </>
//                     ) : (
//                       <>
//                         <Typography fontSize="2rem" fontWeight={700}>
//                           {t('button.upload_image')}
//                         </Typography>
//                         <Typography
//                           fontSize="1.5rem"
//                           fontWeight={400}
//                           sx={{ textAlign: 'center', lineHeight: 'normal' }}
//                         >
//                           {t('mannequin.step_0_comment_1')}
//                           <br />
//                           {t('mannequin.step_0_comment_2')}
//                         </Typography>
//                       </>
//                     )}
//                   </CenterAlignStack>
//                 ))}
//               {segmentStep === 1 &&
//                 (secondNotice ?? (
//                   <CenterAlignStack>
//                     <Typography fontSize="2.2rem" fontWeight={700}>
//                       {t('mannequin.step_1_title')}
//                     </Typography>
//                     <Typography fontSize="1.4rem" fontWeight={400}>
//                       {/* {t('mannequin.step_1_comment_a')}
//                     <span style={{ fontWeight: 400 }}> {t('mannequin.step_1_comment_b')}</span> */}
//                       {t('mannequin.step_1_comment')}
//                     </Typography>
//                   </CenterAlignStack>
//                 ))}
//             </Stack>

//             <CenterAlignStack
//               sx={{
//                 flexDirection: segmentStep === 1 ? 'row' : 'column',
//                 alignItems: segmentStep === 1 ? 'start' : 'center',
//                 outline: segmentStep === 1 ? '1px solid #AFAFAF' : '',
//                 width: segmentStep === 1 ? '100%' : 'auto',
//               }}
//             >
//               <CenterAlignStack sx={{ width: '100%' }}>
//                 <MannequinContents
//                   files={files}
//                   setFiles={setFiles}
//                   width="60rem"
//                   height="14.4rem"
//                   dragActive={dragActive}
//                   handleClose={handleClose}
//                   setDragActive={setDragActive}
//                   portfolioArtworklimit={portfolioArtworklimit}
//                   segmentLoading={segmentLoading}
//                   setSegmentLoading={setSegmentLoading}
//                   usage={usage}
//                 />
//               </CenterAlignStack>

//               <MannequinActions
//                 handleClose={handleClose}
//                 files={files}
//                 segmentLoading={segmentLoading}
//                 setSegmentLoading={setSegmentLoading}
//                 gender={gender}
//                 setGender={setGender}
//                 age={age}
//                 setAge={setAge}
//                 concept={concept}
//                 setConcept={setConcept}
//                 reset={reset}
//                 promptInput={promptInput}
//                 genderSelect={genderSelect}
//                 usage={usage}
//               />
//             </CenterAlignStack>
//             {segmentStep === 1 && (
//               <CenterAlignBox sx={{ mt: '2.4rem' }}>
//                 <DialogButton
//                   handleClose={handleClose}
//                   handleAction={handleAction()}
//                   actionText={
//                     loading ? (
//                       <PuffLoader size={35} />
//                     ) : usage === 'banner' ? (
//                       t('banner_config.upload_button')
//                     ) : (
//                       t('button.done')
//                     )
//                   }
//                   actionprops={{
//                     disabled:
//                       !selectedMaskImg.length || loading || (usage === 'banner' && !concept),
//                   }}
//                 />
//               </CenterAlignBox>
//             )}
//           </CenterAlignStack>
//           {/* <Button
//             variant="contained"
//             sx={{
//               width: { lg: '10rem', xs: isBreakPoint ? '10rem' : '20rem' },
//               height: { lg: '5rem', xs: '4.8rem' },
//               fontSize: { lg: '1.8rem', xs: '1.6rem' },
//               fontWeight: 700,
//               borderRadius: '10px',
//               mt: isBreakPoint ? '0rem' : '1.35rem',
//             }}
//             onClick={handleAction()}
//             disabled={!selectedMaskImg.length || loading || (usage === 'banner' && !concept)}
//           >
//             {loading ? <PuffLoader size={35} /> : t('button.done')}
//           </Button> */}
//         </Dialog>
//       </Desktop>

//       <Mobile>
//         <SwipeableDrawer
//           open={uploadOpen}
//           onOpen={() => {
//             return uploadOpen
//           }}
//           // id="image-swiper-dialog"
//           onClose={segmentLoading ? () => {} : handleClose}
//           anchor="bottom"
//           sx={{
//             '& .MuiBackdrop-root': {
//               background: 'rgba(0, 0, 0, 0.5) ',
//             },
//             '& .MuiDrawer-paperAnchorBottom': {
//               borderTopLeftRadius: '25px',
//               borderTopRightRadius: '25px',
//             },
//             '& .MuiDrawer-paper': {
//               pb: '3rem',
//               px: '2rem',
//             },
//           }}
//           disableDiscovery={iOS}
//           disableSwipeToOpen={true}
//         >
//           <CenterAlignStack>
//             <CenterAlignBox
//               sx={{
//                 width: '4rem',
//                 height: '0.4rem',
//                 backgroundColor: '#BCBCBC',
//                 borderRadius: '20px',
//                 mt: '1.2rem',
//                 mb: '2.8rem',
//               }}
//             ></CenterAlignBox>
//           </CenterAlignStack>

//           <CenterAlignStack sx={{}} onDragEnter={handleDrag}>
//             <Stack
//               sx={{
//                 mb: '1.8rem',
//                 justifyItems: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               {segmentStep !== 1 && (
//                 <CenterAlignStack>
//                   {retryMannequin.isRetry ? (
//                     <>
//                       <Typography
//                         sx={{
//                           fontSize: { lg: '2.2rem', xs: '1.6rem' },
//                           fontWeight: { lg: 700, xs: 800 },
//                         }}
//                       >
//                         {t('mannequin.retry_title')}
//                       </Typography>
//                       <Typography
//                         fontSize="1.2rem"
//                         fontWeight={400}
//                         sx={{ textAlign: 'center', lineHeight: 'normal', mt: '0.3rem' }}
//                       >
//                         {t('mannequin.retry_comment')}
//                       </Typography>
//                     </>
//                   ) : (
//                     <>
//                       <Typography
//                         sx={{
//                           fontSize: { lg: '2.2rem', xs: '1.6rem' },
//                           fontWeight: { lg: 700, xs: 800 },
//                         }}
//                       >
//                         {t('button.upload_image')}
//                       </Typography>
//                       <Typography
//                         fontSize="1.1rem"
//                         fontWeight={400}
//                         sx={{ textAlign: 'center', lineHeight: 'normal', mt: '0.3rem' }}
//                       >
//                         {t('mannequin.step_0_comment_1')}
//                         <br />
//                         {t('mannequin.step_0_comment_2')}
//                       </Typography>
//                     </>
//                   )}
//                 </CenterAlignStack>
//               )}

//               {segmentStep === 1 && (
//                 <CenterAlignStack>
//                   <Typography
//                     sx={{
//                       fontSize: { lg: '2.2rem', xs: '1.4rem' },
//                       fontWeight: { lg: 700, xs: 800 },
//                       lineHeight: 'normal',
//                     }}
//                   >
//                     {t('mannequin.step_1_title')}
//                   </Typography>
//                   <Typography
//                     fontSize="1.2rem"
//                     fontWeight={400}
//                     sx={{ textAlign: 'center', lineHeight: 'normal', mt: '0.3rem' }}
//                   >
//                     {/* {t('mannequin.step_1_comment_a')}
//                     <span style={{ fontWeight: 400 }}> {t('mannequin.step_1_comment_b')}</span> */}
//                     {t('mannequin.step_1_comment')}
//                   </Typography>
//                 </CenterAlignStack>
//               )}
//             </Stack>

//             <CenterAlignStack sx={{ width: '100%' }}>
//               <MannequinContents
//                 files={files}
//                 setFiles={setFiles}
//                 width="32rem"
//                 height="30rem"
//                 dragActive={dragActive}
//                 handleClose={handleClose}
//                 setDragActive={setDragActive}
//                 portfolioArtworklimit={portfolioArtworklimit}
//                 segmentLoading={segmentLoading}
//                 setSegmentLoading={setSegmentLoading}
//                 usage={usage}
//               />
//             </CenterAlignStack>

//             <MannequinActions
//               handleClose={handleClose}
//               files={files}
//               segmentLoading={segmentLoading}
//               setSegmentLoading={setSegmentLoading}
//               gender={gender}
//               setGender={setGender}
//               age={age}
//               setAge={setAge}
//               concept={concept}
//               setConcept={setConcept}
//               reset={reset}
//               promptInput={promptInput}
//               genderSelect={genderSelect}
//               usage={usage}
//             />
//           </CenterAlignStack>

//           {segmentStep === 1 && (
//             <CenterAlignBox sx={{ mt: '2.4rem' }}>
//               <Button
//                 variant="contained"
//                 onClick={handleAction()}
//                 disabled={!selectedMaskImg.length || loading || (usage === 'banner' && !concept)}
//                 sx={{ width: '20rem', height: '4.8rem', fontSize: '1.8rem', fontWeight: 800 }}
//               >
//                 {loading ? (
//                   <PuffLoader size={35} />
//                 ) : usage === 'banner' ? (
//                   t('banner_config.upload_button')
//                 ) : (
//                   t('button.done')
//                 )}
//               </Button>
//             </CenterAlignBox>
//           )}
//         </SwipeableDrawer>
//       </Mobile>
//     </>
//   )
// }

// let realWidth
// let realHeight

// // drag drop file component
// export function MannequinContents({
//   files,
//   setFiles,
//   dragActive,
//   setDragActive,
//   portfolioArtworklimit,
//   segmentLoading,
//   setSegmentLoading,
//   handleClose,
//   usage,
//   ...props
// }) {
//   // drag state
//   // ref
//   const inputRef = useRef(null)
//   const isMobile = useMobileMediaQuery()
//   const { prepareUpload } = usePortfolio()
//   const imgBoxMaxWidth = isMobile ? 320 : 540
//   const imgBoxMaxHeight = isMobile ? 300 : 510
//   const imgBoxMinHeight = isMobile ? 300 : 420

//   const portfolioArtwork = useRecoilValue(portfolioArtworkAtom)
//   const [maskImgArray, setMaskImgArray] = useRecoilState(maskImgArrayAtom)
//   const [segmentStep, setSegmentStep] = useRecoilState(segmentStepAtom)
//   const [retryMannequin, setRetryMannequin] = useRecoilState(retryMannequinAtom)
//   const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)

//   const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)
//   const checkImage = useCheckImage()

//   const user = useRecoilValue(userAtom)

//   const { showConfirm } = useConfirm()
//   const { t } = useTranslation()

//   const uuid = Math.ceil(Math.random() * 1000)

//   const [uploadImgHeight, setUploadImgHeight] = useState(0)
//   const [uploadImgWidth, setUploadImgWidth] = useState(0)
//   const [scale, setScale] = useState()

//   const handleReset = () => {
//     resetSelectedMaskImg()
//   }
//   const handleUndo = () => {
//     const copy = [...selectedMaskImg]
//     copy.pop()
//     setSelectedMaskImg(copy)
//   }

//   const theme = useTheme()

//   const loadHandler = e => {
//     realWidth = e.target.naturalWidth
//     realHeight = e.target.naturalHeight

//     const widthScale = imgBoxMaxWidth / realWidth
//     const heightScale = imgBoxMaxHeight / realHeight //  높이 / 너비 너비 비율

//     if (widthScale >= heightScale) {
//       setUploadImgWidth(`${(realWidth * heightScale) / 10}rem`)
//       setUploadImgHeight(`${(realHeight * heightScale) / 10}rem`)
//       setScale(heightScale)
//     }

//     if (widthScale < heightScale) {
//       setUploadImgWidth(`${(realWidth * widthScale) / 10}rem`)
//       setUploadImgHeight(`${(realHeight * widthScale) / 10}rem`)
//       setScale(widthScale)
//     }
//   }

//   useEffect(() => {
//     return () => {
//       if (inputRef && inputRef.current) inputRef.current.value = null
//     }
//   }, [])

//   // handle drag events
//   const handleDrag = function (e) {
//     e.preventDefault()
//     e.stopPropagation()

//     if (e.type === 'dragenter' || e.type === 'dragover') {
//       setDragActive(true)
//     } else if (e.type === 'dragleave') {
//       setDragActive(false)
//     }
//   }
//   const IMAGE_RATIO = 3

//   const handleChange = async function (e) {
//     e.preventDefault()
//     if (e.target.files) {
//       const uploadFiles = Array.prototype.slice.call(e.target.files).splice(0, 1)
//       const ready = await prepareUpload(
//         uploadFiles,
//         null,
//         null,
//         null,
//         config.PORTFOLIO_TYPE_MANNEQUIN
//       )

//       if (!ready.success) return

//       // ----- GA4 event -----
//       window.gtag('event', 'mannequin_upload', {})
//       // ---------------------
//       checkImage(uploadFiles, files, setFiles, true, false, 'mannequin')
//     }
//   }

//   return (
//     <>
//       {files.length === 0 && (
//         <form
//           className="form-file-upload"
//           id={`form-file-upload-${uuid}`}
//           onDragEnter={handleDrag}
//           onSubmit={e => e.preventDefault()}
//           style={{
//             width: props.width ?? '12.8rem',
//             height: props.height ?? '14.4rem',
//           }}
//         >
//           <input
//             ref={inputRef}
//             type="file"
//             className="input-file-upload"
//             id={`input-file-upload-${uuid}`}
//             accept={allowedTypes.join(', ')}
//             onChange={handleChange}
//           />
//           <label
//             id="label-file-upload"
//             htmlFor={`input-file-upload-${uuid}`}
//             className={dragActive ? 'drag-active' : ''}
//           >
//             <div>
//               <PlusIcon />
//             </div>
//           </label>

//           {/* {dragActive && (
//             <div
//               id="drag-file-element"
//               onDragEnter={handleDrag}
//               onDragLeave={handleDrag}
//               onDragOver={handleDrag}
//               // onDrop={handleDrop}
//             ></div>
//           )} */}
//         </form>
//       )}

//       {files.length !== 0 && segmentStep !== 1 && (
//         <CenterAlignStack>
//           <img
//             src={files[0].url}
//             style={{ width: uploadImgWidth, height: uploadImgHeight }}
//             onLoad={loadHandler}
//           />
//         </CenterAlignStack>
//       )}

//       {segmentLoading && (
//         <CenterAlignBox
//           sx={{
//             position: 'absolute',
//             alignItems: 'center',
//             backgroundColor: 'rgba(0, 0, 0, 0.50)',
//             width: uploadImgWidth,
//             height: uploadImgHeight,
//           }}
//         >
//           <FadeLoader
//             color={theme.palette.common.white}
//             height={25}
//             margin={16}
//             radius={9}
//             width={6}
//           />
//         </CenterAlignBox>
//       )}

//       {segmentStep === 1 && (
//         <Stack sx={{ outline: { lg: '1px solid #AFAFAF', xs: '' }, width: '100%' }}>
//           <CenterAlignBox sx={{ display: 'flex', alignItems: 'center' }}>
//             <Box
//               sx={{
//                 display: 'flex',
//                 ...(isMobile && {
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
//                   borderRadius: '10px',
//                   mb: '1.6rem',
//                 }),
//               }}
//             >
//               <Button
//                 onClick={handleReset}
//                 variant="text"
//                 sx={{
//                   width: { lg: 'auto', xs: '9.2rem' },
//                   height: { lg: '4rem', xs: '4.4rem' },
//                   transition: 'all 0.3s',
//                   color: theme.palette.common.black,
//                   fontSize: { lg: '1.6rem', xs: '1.6rem' },
//                   ml: { lg: '3rem', xs: 0 },

//                   borderRadius: '10px',
//                   '&:hover': {
//                     color: theme.palette.draph.blue,
//                     '& .MuiButton-startIcon path': {
//                       fill: theme.palette.draph.blue,
//                     },
//                   },
//                   '& .MuiButton-startIcon path': {
//                     transition: 'fill 0.3s',
//                   },
//                   '& .MuiButton-startIcon': {
//                     transform: 'rotate(166deg)',
//                   },
//                 }}
//                 startIcon={
//                   <RefreshIcon
//                     size={'medium'}
//                     style={{
//                       width: isMobile ? '1.4rem' : '1.4rem',
//                       height: isMobile ? '1.4rem' : '1.4rem',
//                     }}
//                   />
//                 }
//               >
//                 {t('button.reset')}
//               </Button>
//               <Divider
//                 orientation="vertical"
//                 flexItem
//                 sx={{
//                   height: '20px',
//                   alignSelf: 'center',
//                   borderColor: theme.palette.common.black,
//                 }}
//               />
//               <Button
//                 onClick={handleUndo}
//                 variant="text"
//                 sx={{
//                   width: { lg: 'auto', xs: '11.2rem' },
//                   height: { lg: '4rem', xs: '4.4rem' },
//                   transition: 'all 0.3s',
//                   color: theme.palette.common.black,
//                   fontSize: { lg: '1.6rem', xs: '1.6rem' },
//                   borderRadius: '10px',
//                   // borderRight: '10px',
//                   '&:hover': {
//                     color: theme.palette.draph.blue,
//                     '& .MuiButton-startIcon path': {
//                       stroke: theme.palette.draph.blue,
//                     },
//                   },
//                   '& .MuiButton-startIcon path': {
//                     strokeWidth: 2.5,
//                     transition: 'all 0.3s',
//                   },
//                 }}
//                 startIcon={<UndoArrowIcon style={{ width: '1.4rem', height: '1.4rem' }} />}
//               >
//                 {t('button.undo')}
//               </Button>
//             </Box>
//           </CenterAlignBox>
//           <CenterAlignStack
//             sx={{
//               minHeight: { lg: '51rem', xs: 'auto' },
//               justifyContent: 'center',
//               backgroundColor: '#6D6D6D',
//             }}
//           >
//             <FragmentImages
//               files={files[0]}
//               uploadImgHeight={uploadImgHeight}
//               uploadImgWidth={uploadImgWidth}
//             />
//           </CenterAlignStack>
//         </Stack>
//       )}
//     </>
//   )
// }

// // https://www.codemzy.com/blog/react-drag-drop-file-upload

// const MannequinActions = ({
//   handleClose,
//   gender,
//   setGender,
//   age,
//   setAge,
//   concept,
//   setConcept,
//   usage,
// }) => {
//   const theme = useTheme()
//   const isMobile = useMobileMediaQuery()
//   const isBreakPoint = useBreakPoint()

//   const { t, i18n } = useTranslation()
//   const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
//   const [segmentStep, setSegmentStep] = useRecoilState(segmentStepAtom)
//   const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
//   const [retryMannequin, setRetryMannequin] = useRecoilState(retryMannequinAtom)

//   const portfolioDetail = useRecoilValue(portfolioDetailAtom)
//   const [loading, setLoading] = useRecoilState(mannequinLoadingAtom)

//   const { showConfirm } = useConfirm()
//   const user = useRecoilValue(userAtom)

//   const ex0 =
//     usage === 'banner' ? t('banner_config.mann2man_prompt_ex') : t('mannequin.bg_theme_ex')

//   const onFocus = () => {
//     setPlaceholder('')
//   }
//   const onBlur = () => {
//     setPlaceholder(ex0)
//   }

//   const onChange = e => {
//     setConcept(e.target.value)
//   }

//   const [placeholder, setPlaceholder] = useState(ex0)

//   const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)

//   useEffect(() => {
//     if (retryMannequin.config) {
//       const config = retryMannequin.config

//       setGender(config.is_male ? 'male' : 'female')
//       setAge(config.age ?? 'adult')
//       setConcept(config.prompt ?? '')
//     }
//   }, [retryMannequin])

//   const genderList = [
//     { label: t('button.female'), value: 'female' },
//     { label: t('button.male'), value: 'male' },
//   ]

//   const ageList = [
//     { label: t('button.baby'), value: 'baby' },
//     { label: t('button.child'), value: 'child' },
//     { label: t('button.adult'), value: 'adult' },
//   ]

//   return (
//     <>
//       {segmentStep === 0 && (
//         <CenterAlignBox sx={{ justifyContent: 'center', p: '0', mt: '3.2rem' }}>
//           <ShadowButton
//             sx={{
//               fontSize: '2rem',
//               width: '18.4rem',
//             }}
//             onClick={handleClose}
//             disabled={segmentLoading}
//           >
//             {t('button.cancel')}
//           </ShadowButton>
//           {/* <DialogButton
//             actionText="업로드"
//             handleClose={handleClose}
//             handleAction={handleUpload}
//             actionprops={{ disabled: files.length < 1 || segmentLoading }}
//           /> */}
//         </CenterAlignBox>
//       )}
//       {segmentStep === 1 && (
//         <Stack
//           sx={{
//             alignItems: 'start',
//             minWidth: { lg: '34rem', xs: 'auto' },
//             p: { lg: '2rem 2.2rem', xs: 0 },
//           }}
//         >
//           <CenterAlignStack
//             className="model"
//             sx={{
//               width: '100%',
//               position: 'relative',
//               alignItems: 'start',
//               gap: { lg: '1.6rem', xs: '2rem' },
//               flexDirection: { lg: 'column', xs: 'row' },
//               mt: { lg: 0, xs: '1.8rem' },
//             }}
//           >
//             <Desktop>
//               <Stack sx={{ width: '100%', position: 'relative', alignItems: 'start' }}>
//                 <Typography
//                   sx={{
//                     ...fontStyles.actionTitle,
//                     '&:after': {
//                       right: '0',
//                       // transform: 'translateX(50%)',
//                       content: '""',
//                       position: 'absolute',
//                       top: '50%',
//                       width: 'calc(100% - 8rem)', // 여백을 고려하여 너비 계산
//                       height: '1px',
//                       backgroundColor: 'black',
//                     },
//                   }}
//                 >
//                   {t('mannequin.model_config')}
//                 </Typography>
//               </Stack>
//             </Desktop>

//             <Stack
//               sx={{
//                 gap: { lg: '0.8rem', xs: '0.6rem' },
//                 justifyContent: { lg: 'start', xs: 'center' },
//               }}
//             >
//               <Typography
//                 sx={{
//                   ...fontStyles.actionSubtitle,
//                   ...(usage === 'banner' && { fontSize: '1.4rem' }),
//                   textAlign: { lg: 'start', xs: 'center' },
//                 }}
//               >
//                 {t('mannequin.gender')}
//               </Typography>
//               <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
//                 {genderList.map((g, i) => {
//                   return (
//                     <StyledToggleButton
//                       key={g.value}
//                       value={g.value}
//                       selected={gender === g.value}
//                       onChange={e => {
//                         setGender(e.target.value)
//                       }}
//                       sx={{
//                         minWidth: { lg: '8rem', xs: '5.2rem' },
//                         height: '3.2rem',
//                         fontSize: '1.4rem',

//                         border: `2px solid`,
//                         borderColor: `transparent`,
//                         fontWeight: 600,
//                         color: theme.palette.common.black,

//                         '&.Mui-selected': {
//                           boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
//                           color: theme.palette.draph.blue,
//                           background: 'none',
//                           borderColor: theme.palette.draph.blue,
//                           outline: 'none',
//                           fontWeight: 800,
//                         },
//                         ...(isMobile && { borderRadius: '10px', px: '1rem' }),
//                       }}
//                     >
//                       {g.label}
//                     </StyledToggleButton>
//                   )
//                 })}
//               </Box>
//             </Stack>

//             <Stack sx={{ gap: '0.8rem' }}>
//               <Typography
//                 sx={{
//                   ...fontStyles.actionSubtitle,
//                   ...(usage === 'banner' && { fontSize: '1.4rem' }),
//                   textAlign: { lg: 'start', xs: 'center' },
//                 }}
//               >
//                 {t('mannequin.age')}
//               </Typography>
//               <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
//                 {ageList.map((a, i) => {
//                   return (
//                     <StyledToggleButton
//                       key={a.value}
//                       value={a.value}
//                       selected={age === a.value}
//                       onChange={e => {
//                         setAge(e.target.value)
//                       }}
//                       sx={{
//                         minWidth: { lg: '8rem', xs: '5.2rem' },
//                         height: '3.2rem',
//                         fontSize: '1.4rem',

//                         border: `2px solid`,
//                         borderColor: `transparent`,
//                         fontWeight: 600,
//                         color: theme.palette.common.black,

//                         '&.Mui-selected': {
//                           boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
//                           color: theme.palette.draph.blue,
//                           background: 'none',
//                           borderColor: theme.palette.draph.blue,
//                           outline: 'none',
//                           fontWeight: 800,
//                         },
//                         ...(isMobile && { borderRadius: '10px', px: '1rem' }),
//                       }}
//                     >
//                       {a.label}
//                     </StyledToggleButton>
//                   )
//                 })}
//               </Box>
//             </Stack>
//           </CenterAlignStack>

//           <CenterAlignStack
//             className="theme"
//             sx={{
//               width: '100%',
//               position: 'relative',
//               alignItems: 'start',
//               gap: '1.6rem',
//               mt: { lg: '3.6rem', xs: '2rem' },
//             }}
//           >
//             <Desktop>
//               <Stack sx={{ width: '100%', position: 'relative', alignItems: 'start' }}>
//                 <Typography
//                   sx={{
//                     ...fontStyles.actionTitle,
//                     '&:after': {
//                       right: '0',
//                       // transform: 'translateX(50%)',
//                       content: '""',
//                       position: 'absolute',
//                       top: '50%',
//                       width: 'calc(100% - 8rem)', // 여백을 고려하여 너비 계산
//                       height: '1px',
//                       backgroundColor: 'black',
//                     },
//                   }}
//                 >
//                   {usage === 'banner'
//                     ? t('mannequin.model_depict')
//                     : t('mannequin.background_config')}
//                 </Typography>
//               </Stack>
//             </Desktop>

//             <Stack sx={{ gap: '0.8rem' }}>
//               <Typography
//                 sx={{
//                   ...fontStyles.actionSubtitle,
//                   textAlign: { lg: 'start', xs: 'center' },
//                 }}
//               >
//                 {usage === 'banner' ? (
//                   <span style={{ fontSize: '1.4rem' }}>
//                     {t('banner_config.mann2man_prompt')}
//                     <sup style={{ color: '#FF615C' }}>&#42;</sup>
//                   </span>
//                 ) : (
//                   <>
//                     <SpanDraphBlue> {t('mannequin.step_1_prompt_comment_a')}</SpanDraphBlue>
//                     {t('mannequin.step_1_prompt_comment_b')}
//                     <SpanDraphBlue> {t('mannequin.step_1_prompt_comment_c')}</SpanDraphBlue>
//                   </>
//                 )}
//               </Typography>

//               <CustomOutlinedInput
//                 value={concept}
//                 placeholder={placeholder}
//                 onChange={onChange}
//                 onFocus={onFocus}
//                 onBlur={onBlur}
//                 type="text"
//                 multiline={!isMobile}
//                 inputProps={isMobile ? { maxLength: 1000 } : {}}
//                 minRows={2}
//                 maxRows={2}
//                 sx={{
//                   height: { lg: '6.5rem', xs: '3.6rem' },
//                   width: { lg: '29.6rem', xs: '32rem' },
//                   textAlign: 'center',
//                 }}
//               />
//               {/* <OutlinedInput multiline></OutlinedInput> */}
//             </Stack>
//           </CenterAlignStack>
//         </Stack>
//       )}
//     </>
//   )
// }

const uploadMaxSize = 1024

export const imgResizeAndBinary = async uploadFiles => {
  let imgBinary

  const promises = uploadFiles.map(f => {
    const func = async () => {
      try {
        const image = new Image()
        const objectUrl = window.URL.createObjectURL(f)

        let smallImgFile = null

        image.src = objectUrl

        await image.decode()

        if (image.width > uploadMaxSize || image.height > uploadMaxSize) {
          console.log(f.name, '..resizing image')
          const smallerImage = await resizeImage(f, uploadMaxSize)
          // console.log('smallerImage', smallerImage)
          smallerImage.lastModified = new Date()
          smallerImage.name = f.name
          smallImgFile = new File([smallerImage], f.name)
          imgBinary = smallImgFile
        } else {
          imgBinary = f
        }

        URL.revokeObjectURL(objectUrl)
      } catch (e) {
        console.log(e, 'ERROR ', f.name)
      }
    }

    return func
  })

  await processInGroups(promises)

  return imgBinary
}

const loadImage = src => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = () => resolve(img)
    img.onerror = err => reject(err)
  })
}

// const imgMergeAndGrayScale = async imgArray => {
//   const { Image } = require('image-js')

//   const canvas = document.createElement('canvas')
//   const ctx = canvas.getContext('2d')

//   const firstImage = await loadImage(imgArray[0])
//   canvas.width = firstImage.width
//   canvas.height = firstImage.height

//   imgArray.forEach(async imageData => {
//     const img = await loadImage(imageData)
//     console.log(img)
//     ctx.drawImage(img, 0, 0)
//   })

//   const mergeData = ctx.getImageData(0, 0, canvas.width, canvas.height)
//   console.log('mergeData', mergeData)
//   const originalImg = new Image(canvas.width, canvas.height, mergeData.data)
//   // const greyedImg = originalImg.grey()

//   return originalImg.toDataURL()
// }

export async function imgMergeAndGrayScale(imgArray) {
  const { Image } = require('image-js')

  // 모든 이미지 로딩을 기다리기 위한 Promise 배열
  const imagePromises = imgArray.map(loadImage)
  try {
    // 이미지 배열을 비동기로 로딩
    const images = await Promise.all(imagePromises)

    // 이미지의 너비와 높이를 가져옴
    const width = images[0].width
    const height = images[0].height

    // Canvas 엘리먼트 생성
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    // Canvas 2D 컨텍스트를 얻음
    const ctx = canvas.getContext('2d')

    // 이미지를 Canvas에 그림
    images.forEach(image => {
      ctx.drawImage(image, 0, 0, width, height)
    })

    const selectData = ctx.getImageData(0, 0, width, height)
    const originalImg = new Image(width, height, selectData.data)

    const greyedImg = originalImg.grey()
    const mask = greyedImg.mask()

    const maskToBlob = await mask.toBlob(blob => {
      const file = new File([blob], `crop.jpg`, { type: 'image/png' })
      return file
    }, 'image/png')

    // const result = mask.toDataURL('image/png')

    // combinedImageSrc를 반환
    return maskToBlob
  } catch (error) {
    console.error('이미지 로딩 중 오류 발생:', error)
    throw error // 오류를 다시 던지거나 처리할 수 있습니다.
  }
}

export const useHandleGetNpy = () => {
  const { showConfirm } = useConfirm()
  const user = useRecoilValue(userAtom)

  const [segmentStep, setSegmentStep] = useRecoilState(segmentStepAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [maskImgArray, setMaskImgArray] = useRecoilState(maskImgArrayAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)
  const { callApi: getNpyMaps } = useApiHandler({
    apiKey: 'getNpyMaps',
    apis: apis.appfront,
  })

  const { callApi: getEditNpyMaps } = useApiHandler({
    apiKey: 'getEditNpyMaps',
    apis: apis.appfront,
  })

  console.log()

  const setSelectedMaskImg = useSetRecoilState(selectedMaskImgAtom)

  const handleGetNpy = async ({
    image,
    service = 'mannequin',
    isRegen = false,
    artworkId = null,
  }) => {
    setSegmentLoading(true)
    const formData = new FormData()

    formData.append('user_id', user.id)
    formData.append('img', image)
    formData.append('service', service)

    const formDataRegen = new FormData()
    formDataRegen.append('artwork_id', artworkId)

    try {
      let r
      if (isRegen) {
        r = await getEditNpyMaps(formDataRegen)
        if (r.data.code === 141) {
          r = await getNpyMaps(formData)
        }
      } else {
        r = await getNpyMaps(formData)
      }

      // const r = await axios.post('http://100.111.252.150:4567/get_bin_maps', formData)

      setSegmentLoading(false)
      setSegmentStep(1)
      const rawBinmaps = JSON.parse(r.data.binmaps)

      console.log('rawBinmaps', rawBinmaps)
      if (r.data.preset) {
        const presetBinmaps = JSON.parse(r.data.preset)[0]

        if (JSON.parse(r.data.preset).length !== 0) {
          setSelectedMaskImg([
            imageData2DToImage(decodeMask2RLE(presetBinmaps.counts, presetBinmaps.size)).src,
          ])
        }
      }

      setMaskImgArray(rawBinmaps)
    } catch (err) {
      console.log(err)
      if (err.name === 'CanceledError') return
      setUploadOpen(false)
      showConfirm({
        alertOnly: true,
        content: '이미지를 인식하는데 오류가 발생하였습니다.',
      })
    } finally {
      setSegmentLoading(false)
    }

    // apis.appfront
    //   .getNpyMaps(formData)
    //   .then(r => {
    //     setSegmentLoading(false)
    //     setSegmentStep(1)
    //     const rawBinmaps = JSON.parse(r.data.binmaps)

    //     setMaskImgArray(rawBinmaps)
    //   })
    //   .catch(() => {
    //     setUploadOpen(false)
    //     showConfirm({
    //       alertOnly: true,
    //       content: '이미지를 인식하는데 오류가 발생하였습니다.',
    //     })
    //   })
    //   .finally(() => {
    //     setSegmentLoading(false)
    //   })
  }

  return handleGetNpy
}
