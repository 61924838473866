export const getUserType = user => {
  // 백엔드에 업로드 api 호출시 실어보내는 유저 정보 (BE에서 우선순위 큐 배정에 사용됨)
  // user 구독자 여부 확인
  // 추후 다른 타입 추가 (엔터프라이즈 등)
  if (user.subscription_plan?.includes('w100maker')) {
    return 'w100maker'
  } else if (user.subscription_plan?.includes('priority_basic')) {
    return 'priority_basic'
  } else if (user.subscription_plan || user.grade === 'admin') {
    // 최초 생성 10분 지연 시 크레딧 환불처리를 해주는 유효 구독자 (FE에서는 크레딧 환불 문구 표시와 연관됨)
    return 'subscriber'
  }

  return null
}
