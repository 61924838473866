import { useState } from 'react'
// material
import { alpha, styled } from '@mui/material/styles'
import { Box, Stack, AppBar, Toolbar, Typography, Divider } from '@mui/material'
import { motion } from 'framer-motion'
import { AgreeDialog, LanguageSelect } from 'components'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { APPBAR_MOBILE } from './MainAppBar'
import { Link } from 'react-router-dom'
import { getAccessToken } from 'utils/cookie'

import { isDev, isKo } from 'utils/common'
import { useTranslation } from 'react-i18next'
import { Korean } from 'hooks/useLanguage'
import { ENGLISH_LANGUAGES } from 'config'
// ----------------------------------------------------------------------

export const FOOTER_MOBILE = '34rem'
export const FOOTER_DESKTOP = '20.4rem'

const DraphInfoTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '1.6rem',
  fontWeight: 800,
  mb: '0.6rem',
  lineHeight: '2.5rem',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    mb: '0.8rem',
  },
}))

const FooterLink = ({ text, link, title = false, name }) => {
  return (
    <Link
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'inherit', textDecoration: 'none' }}
      onClick={() => {
        if (name) {
          // ----- GA4 event -----
          window.gtag('event', 'footer_click', { menu: name })
          // ---------------------
        }
      }}
    >
      {title ? <DraphInfoTitle>{text}</DraphInfoTitle> : <DraphInfoText>{text}</DraphInfoText>}
    </Link>
  )
}

const DraphInfoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '1.2rem',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
    lineHeight: '1.8rem',
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  background: '#505050',
  height: FOOTER_MOBILE,
  minHeight: FOOTER_MOBILE,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'end',
  justifySelf: 'end',
  paddingLeft: '0rem',
  paddingRight: '0rem',

  [theme.breakpoints.up('lg')]: {
    height: FOOTER_DESKTOP,
    minHeight: FOOTER_DESKTOP,
    paddingLeft: '3.6rem',
    paddingRight: '3.6rem',
  },

  // [theme.breakpoints.down('lg')]: {
  //   marginBottom: APPBAR_MOBILE,
  // },
}))

export default function MainFooter() {
  const isMobile = useMobileMediaQuery()
  const token = getAccessToken()

  return (
    <ToolbarStyle sx={{ mb: isMobile && token ? APPBAR_MOBILE : 0 }}>
      <DraphInfo />
    </ToolbarStyle>
  )
}

function DraphInfo() {
  const isMobile = useMobileMediaQuery()
  const [termOpen, setTermOpen] = useState(false)
  const [privacyOpen, setPrivacyOpen] = useState(false)
  const { t, i18n } = useTranslation()

  return (
    <>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            width: { lg: '120.5rem', xs: '36rem' },
            flexDirection: { lg: 'row', xs: 'column' },
            justifyContent: { lg: isKo(i18n) ? 'space-between' : 'flex-end' },
            p: { lg: 0, xs: '0 1.8rem 0 1.6rem;' },
          }}
        >
          <Korean>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: { lg: '', xs: 'flex-start' },
                alignItems: { lg: 'flex-start', xs: 'flex-start' },
                width: { lg: 'auto', xs: '100%' },
              }}
            >
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: { lg: 'center', xs: 'space-between' },
                  alignItems: { lg: 'center', xs: 'flex-start' },
                  width: { xs: 'inherit' },
                }}
              >
                <Stack
                  sx={{
                    mr: { lg: '3rem', xs: '1rem' },
                    px: { lg: 0, xs: '0rem' },
                  }}
                >
                  <DraphInfoText>
                    {t('footer.company_name') + ' | '}
                    {t('footer.ceo_name')}
                    {ENGLISH_LANGUAGES.includes(i18n.language) ? '' : ' | '}
                    {ENGLISH_LANGUAGES.includes(i18n.language) ? <br /> : ''}
                    {t('footer.business_number')}
                    {/* 주식회사 드랩 | 대표자: 이주완 | 사업자등록번호: 541-88-02453 */}
                  </DraphInfoText>
                  <DraphInfoText>
                    {t('footer.company_address')}
                    {ENGLISH_LANGUAGES.includes(i18n.language) ? '' : ' | '}
                    {ENGLISH_LANGUAGES.includes(i18n.language) ? <br /> : ''}
                    {t('footer.company_phone_number') + ' | '}
                    {t('footer.company_email')}
                  </DraphInfoText>

                  <DraphInfoText>{t('footer.online_market_business_number')}</DraphInfoText>
                  <DraphInfoText
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      window.open(
                        'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5418802453',
                        '_blank'
                      )
                    }}
                  >
                    {t('footer.online_market_verification')}
                  </DraphInfoText>
                </Stack>

                <KBAuthMark />
              </Stack>

              <Desktop>
                <Divider flexItem sx={{ borderColor: 'white', my: '1.8rem' }} />
                <DraphInfoText>ⓒ Draph Inc. All rights reserved.</DraphInfoText>
              </Desktop>
            </Box>
          </Korean>

          <Box
            sx={{
              display: 'flex',
              width: { lg: '54rem', xs: '33rem' },
              justifyContent: 'space-between',
              pt: { lg: 0, xs: '1.8rem' },
            }}
          >
            <Stack sx={{ width: { lg: isKo(i18n) ? '8.8rem' : '9.8rem', xs: 'auto' } }}>
              <DraphInfoTitle>COMPANY</DraphInfoTitle>

              <FooterLink
                link="https://www.draph.ai/"
                text={t('footer.about_us')}
                name="draph_inc"
              />
              <FooterLink
                link="https://www.draph.ai/careers"
                text={t('footer.employment')}
                name="careers"
              />
              <FooterLink link="/board" text={t('footer.service_center')} name="cs" />
              {/* <FooterLink link="https://www.draph.ai/careers" text="블로그" /> */}
              <AgreeDialog
                category="Terms"
                openButton={
                  <DraphInfoText
                    onClick={() => {
                      // ----- GA4 event -----
                      window.gtag('event', 'footer_click', { menu: 'terms_of_use' })
                      // ---------------------
                    }}
                  >
                    {t('footer.terms')}
                  </DraphInfoText>
                }
              />

              <AgreeDialog
                category="Privacy"
                openButton={
                  <DraphInfoText
                    fontWeight={700}
                    onClick={() => {
                      // ----- GA4 event -----
                      window.gtag('event', 'footer_click', { menu: 'privacy_policy' })
                      // ---------------------
                    }}
                  >
                    {t('footer.privacy')}
                  </DraphInfoText>
                }
              />
            </Stack>

            <Stack sx={{ width: '7rem' }}>
              <DraphInfoTitle>SNS</DraphInfoTitle>
              {/* {i18n.language === 'ko' && (
                <FooterLink
                  link="https://blog.naver.com/draph_ai"
                  text={t('footer.naver_blog')}
                  name="blog"
                />
              )} */}
              <FooterLink link={`/blog/${i18n.language}`} text={t('menu.blog')} name="blog" />
              <FooterLink
                link="https://www.linkedin.com/company/draph-inc/"
                text={t('footer.linkedin')}
                name="linkedin"
              />
              <FooterLink
                link="https://www.instagram.com/draph_inc/"
                text={t('footer.instagram')}
                name="instagram"
              />
              <FooterLink link="https://www.youtube.com/@draph_ai" text={t('footer.youtube')} />
              {/* {isKo(i18n) && (
                <>
                  <FooterLink
                    link="https://www.pinterest.co.kr/draphinc/"
                    text={t('footer.pinterest')}
                  />
                  <FooterLink
                    link="https://www.facebook.com/draph.inc/"
                    text={t('footer.facebook')}
                  />
              
                </>
              )} */}
            </Stack>
            <Stack>
              <DraphInfoTitle>PRICING</DraphInfoTitle>
              <FooterLink link="/pricing" text={t('footer.pricing')} name="pricing" />
              <FooterLink link="/api_info" text={t('footer.api_info')} name="api" />
            </Stack>
            <Stack>
              <DraphInfoTitle>LANGUAGE</DraphInfoTitle>
              {/* <DraphInfoTitle>{t('footer.language')}</DraphInfoTitle> */}
              <LanguageSelect />
            </Stack>
          </Box>
        </Box>
        {!(isKo(i18n) && !isMobile) && (
          <>
            <Divider
              flexItem
              sx={{
                borderColor: 'white',
                m: isMobile ? '2.5rem 0 1rem 0' : '2rem 0 0.4rem 0',
                width: isMobile ? '100%' : '54rem',
                alignSelf: isKo(i18n) ? 'stretch' : 'end',
              }}
            />
            <DraphInfoText sx={{ textAlign: 'end', mr: isMobile ? '1.8rem' : 0 }}>
              ⓒ Draph Inc. All rights reserved.
            </DraphInfoText>
          </>
        )}
      </Stack>
    </>
  )
}

const CustomLi = styled(motion.li)`
  width: 100%;
`

const CustomUl = styled(motion.ul)`
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
`
const CustomDiv = styled(motion.div)`
  width: 100%;
`

const Banner = () => {
  return (
    <CustomDiv>
      <CustomUl>
        <CustomLi>알고 계셨나요? ^^ 그런걸 올리시면 안됩니다.</CustomLi>
      </CustomUl>
    </CustomDiv>
  )
}
const DidYouKnow = () => {
  return (
    <section style={{ width: '100%', overflow: 'hidden' }}>
      <motion.article
        style={{ display: 'flex', width: '200%' }}
        initial={{ x: 0 }}
        animate={{ x: '-50%' }}
        transition={{ ease: 'linear', duration: 10, repeat: Infinity }}
      >
        <Banner />
        <Banner />
      </motion.article>
    </section>
  )
}

function IconBox({ children, link, ...props }) {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        lineHeight: 1,
      }}
      onClick={() => {
        window.open(link, '_blank').focus()
      }}
    >
      {children}
    </Box>
  )
}

const BlogIcon = props => (
  <IconBox {...props} link="https://blog.naver.com/draph_ai">
    <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0708 0H4.91849C2.20773 0 0 2.21705 0 4.91849V16.8235C0 19.5342 2.21705 21.742 4.91849 21.742H11.6814L12.8458 23.7541L13.9916 25.7382C14.1966 26.1015 14.7182 26.1015 14.9325 25.7382L16.0783 23.7541L17.2427 21.742H24.0801C26.7909 21.742 28.9986 19.5249 28.9986 16.8235V4.92781C28.9986 2.21705 26.7816 0 24.0708 0Z"
        fill="white"
      />
      <path
        d="M15.8391 8.71875C14.3579 8.71875 13.1562 9.9018 13.1562 11.3643C13.1562 12.8268 14.3579 14.0099 15.8391 14.0099C17.3202 14.0099 18.5219 12.8268 18.5219 11.3643C18.5219 9.9018 17.3295 8.71875 15.8391 8.71875ZM15.8391 12.6312C15.1311 12.6312 14.5535 12.063 14.5535 11.3643C14.5535 10.6657 15.1311 10.0974 15.8391 10.0974C16.547 10.0974 17.1246 10.6657 17.1246 11.3643C17.1246 12.063 16.5563 12.6312 15.8391 12.6312Z"
        fill="#505050"
      />
      <path
        d="M7.3517 8.71642C6.77415 8.71642 6.23386 8.93999 5.81467 9.32192V7.13281H4.32422V13.8864H5.81467V13.4114C6.23386 13.784 6.76483 14.0169 7.3517 14.0169C8.68379 14.0169 9.76437 12.8338 9.76437 11.3713C9.76437 9.90879 8.69311 8.71642 7.3517 8.71642ZM7.05361 12.6848C6.504 12.6848 6.02892 12.3587 5.82399 11.8836C5.75878 11.7253 5.7122 11.5483 5.7122 11.362C5.7122 11.1757 5.74946 10.9987 5.82399 10.8403C6.02892 10.3652 6.504 10.0392 7.05361 10.0392C7.79883 10.0392 8.39501 10.6261 8.39501 11.362C8.39501 12.0979 7.79883 12.6848 7.05361 12.6848Z"
        fill="#505050"
      />
      <path
        d="M23.1691 8.82903V9.24822C22.7499 8.92218 22.2282 8.72656 21.6693 8.72656C20.2999 8.72656 19.1914 9.87235 19.1914 11.279C19.1914 12.6856 20.2999 13.8314 21.6693 13.8314C22.2375 13.8314 22.7592 13.6357 23.1691 13.3097V14.0177C23.1691 14.0177 23.02 14.7163 21.9767 14.7163V16.0298C21.9767 16.0298 23.1038 16.1602 24.1099 15.1541C24.1099 15.1541 24.5757 14.6604 24.6781 14.0084V8.82903H23.1691ZM21.9487 12.5738C21.1942 12.5738 20.5887 11.9869 20.5887 11.2696C20.5887 10.5524 21.2035 9.9655 21.9487 9.9655C22.4797 9.9655 22.9455 10.2636 23.1691 10.6921C23.2622 10.8691 23.3088 11.0647 23.3088 11.2696C23.3088 11.4746 23.2529 11.6795 23.1691 11.8472C22.9455 12.285 22.4797 12.5738 21.9487 12.5738Z"
        fill="#505050"
      />
      <path
        d="M9.93359 7.00529V8.35601C9.93359 8.35601 10.6229 8.44916 10.7533 9.11987V13.8893H12.2904V9.11987V9.08261C12.1972 8.4119 11.7967 7.90888 11.7967 7.90888C10.921 6.87488 9.93359 7.00529 9.93359 7.00529Z"
        fill="#505050"
      />
    </svg>
  </IconBox>
)

const LinkedInIcon = props => (
  <IconBox {...props} link="https://www.linkedin.com/company/draph-inc/">
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.89648 21.8745H0.361328V7.27002H4.89648V21.8745ZM2.62646 5.27783C1.17627 5.27783 0 4.07666 0 2.62646C1.03799e-08 1.92988 0.276716 1.26183 0.769274 0.769274C1.26183 0.276716 1.92988 0 2.62646 0C3.32305 0 3.9911 0.276716 4.48366 0.769274C4.97621 1.26183 5.25293 1.92988 5.25293 2.62646C5.25293 4.07666 4.07617 5.27783 2.62646 5.27783ZM21.8701 21.8745H17.3447V14.7651C17.3447 13.0708 17.3105 10.8979 14.9868 10.8979C12.6289 10.8979 12.2676 12.7388 12.2676 14.6431V21.8745H7.73731V7.27002H12.0869V9.26221H12.1504C12.7559 8.11475 14.2349 6.90381 16.4414 6.90381C21.0312 6.90381 21.875 9.92627 21.875 13.8521V21.8745H21.8701Z"
        fill="white"
      />
    </svg>
  </IconBox>
)

const InstagramIcon = props => (
  <IconBox {...props} link="https://www.instagram.com/draph_inc/">
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.875 1H7.875C4.56129 1 1.875 3.68629 1.875 7V19C1.875 22.3137 4.56129 25 7.875 25H19.875C23.1887 25 25.875 22.3137 25.875 19V7C25.875 3.68629 23.1887 1 19.875 1Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.677 12.2444C18.8251 13.2431 18.6545 14.263 18.1895 15.1592C17.7245 16.0553 16.9888 16.7821 16.0869 17.236C15.1851 17.6899 14.1631 17.8479 13.1663 17.6875C12.1695 17.5271 11.2487 17.0565 10.5348 16.3426C9.82089 15.6286 9.35027 14.7078 9.18987 13.711C9.02948 12.7142 9.18747 11.6922 9.64138 10.7904C10.0953 9.88859 10.822 9.15285 11.7182 8.68785C12.6143 8.22285 13.6343 8.05227 14.633 8.20036C15.6517 8.35142 16.5948 8.82612 17.323 9.55433C18.0512 10.2825 18.5259 11.2257 18.677 12.2444Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4766 6.39844H20.4886"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconBox>
)

const PinterestIcon = props => (
  <IconBox {...props} link="https://www.pinterest.co.kr/draphinc/">
    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8359 0C5.82617 0 0.875 3.33984 0.875 8.74512C0.875 12.1826 2.80859 14.1357 3.98047 14.1357C4.46387 14.1357 4.74219 12.7881 4.74219 12.4072C4.74219 11.9531 3.58496 10.9863 3.58496 9.09668C3.58496 5.1709 6.57324 2.3877 10.4404 2.3877C13.7656 2.3877 16.2266 4.27734 16.2266 7.74902C16.2266 10.3418 15.1865 15.2051 11.8174 15.2051C10.6016 15.2051 9.56152 14.3262 9.56152 13.0664C9.56152 11.2207 10.8506 9.43359 10.8506 7.5293C10.8506 4.29688 6.26562 4.88281 6.26562 8.78906C6.26562 9.60938 6.36816 10.5176 6.73438 11.2646C6.06055 14.165 4.68359 18.4863 4.68359 21.4746C4.68359 22.3975 4.81543 23.3057 4.90332 24.2285C5.06934 24.4141 4.98633 24.3945 5.24023 24.3018C7.70117 20.9326 7.61328 20.2734 8.72656 15.8643C9.32715 17.0068 10.8799 17.6221 12.1104 17.6221C17.2959 17.6221 19.625 12.5684 19.625 8.0127C19.625 3.16406 15.4355 0 10.8359 0Z"
        fill="white"
      />
    </svg>
  </IconBox>
)

const FacebookIcon = props => (
  <IconBox {...props} link="https://www.facebook.com/draph.inc/">
    <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1372 14.0625L13.8315 9.53809H9.49023V6.60205C9.49023 5.36426 10.0967 4.15771 12.041 4.15771H14.0146V0.305664C14.0146 0.305664 12.2236 0 10.5112 0C6.93604 0 4.59912 2.16699 4.59912 6.08984V9.53809H0.625V14.0625H4.59912V25H9.49023V14.0625H13.1372Z"
        fill="white"
      />
    </svg>
  </IconBox>
)
const YoutubeIcon = props => (
  <IconBox {...props} link="https://www.youtube.com/@draph_ai">
    <svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.4305 3.08042C27.1085 1.8679 26.1597 0.912955 24.955 0.588881C22.7715 0 14.0156 0 14.0156 0C14.0156 0 5.25982 0 3.07621 0.588881C1.87153 0.913007 0.922736 1.8679 0.600712 3.08042C0.015625 5.27819 0.015625 9.86363 0.015625 9.86363C0.015625 9.86363 0.015625 14.4491 0.600712 16.6468C0.922736 17.8594 1.87153 18.7745 3.07621 19.0986C5.25982 19.6875 14.0156 19.6875 14.0156 19.6875C14.0156 19.6875 22.7714 19.6875 24.955 19.0986C26.1597 18.7745 27.1085 17.8594 27.4305 16.6468C28.0156 14.4491 28.0156 9.86363 28.0156 9.86363C28.0156 9.86363 28.0156 5.27819 27.4305 3.08042ZM11.152 14.0269V5.7004L18.4701 9.86373L11.152 14.0269Z"
        fill="white"
      />
    </svg>
  </IconBox>
)

const KBAuthMark = props => {
  const isMobile = useMobileMediaQuery()
  const onPopKBAuthMark = () => {
    window.open(
      '',
      'KB_AUTHMARK',
      'height=604, width=648, status=yes, toolbar=no, menubar=no, location=no'
    )
    document.KB_AUTHMARK_FORM.action = 'https://okbfex.kbstar.com/quics'
    document.KB_AUTHMARK_FORM.target = 'KB_AUTHMARK'
    document.KB_AUTHMARK_FORM.submit()
  }

  return (
    <div {...props}>
      <form name="KB_AUTHMARK_FORM" method="get">
        <input type="hidden" name="page" value="C021590" />
        <input type="hidden" name="cc" value="b034066:b035526" />
        <input type="hidden" name="mHValue" value="f281ea4aaf6c5a081cdd7c3e2129b1ae" />
      </form>
      <a href="#" onClick={onPopKBAuthMark}>
        <img
          src="/static/images/logo/outside/KB_mark.png"
          border="0"
          alt="Popup Image"
          style={
            isMobile
              ? { width: '3.2rem', height: '3.2rem', objectFit: 'cover' }
              : { width: '4.8rem', height: '4.8rem', objectFit: 'cover' }
          }
        />
      </a>
    </div>
  )
}

// const InstagramIcon = props => (
//   <Box {...props}></Box>
// )
