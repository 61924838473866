import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import {
  unusualPortfolioConfigUpdateAtom,
  portfolioDetailAtom,
  bannerSizeRatioAtom,
  userAtom,
  uploadDialogOpenAtom,
  hasProductAtom,
  isnoSOHumanDepictAtom,
  loadingAtom,
  uploadDialogOpen2Atom,
  selectedMaskImgAtom,
  removeMaskImgAtom,
  segmentLoadingAtom,
  indexAtom,
  uploadFilesAndUrlAtom,
  bannerMannInfoAtom,
  bannerMajorPageAtom,
  maskImgArrayAtom,
} from 'atoms'
import { Segment, TwoImageSlider } from 'components'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import {
  CreateButton,
  CustomOutlinedInput,
  OpacityMotionDiv,
  ShadowButton,
  SmallTextField,
  SpanDraphBlue,
} from 'components/CustomStyle'

import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  BannerSizeHorizontal,
  BannerSizeSquare,
  BannerSizeVertical,
  CheckedSquareIcon3,
  EraserIcon,
  NextIcon,
  SelectSideIcon,
  SquareIcon2,
} from 'theme/icon'
import { BarLoader as Loader } from 'react-spinners'
import { isKo } from 'utils/common'
import useBanner from 'hooks/useBanner'
import { RIGHT_CLICK_ALLOW_USER } from 'App'
import { English, getStaticResourceLanguage, Korean } from 'hooks/useLanguage'
import { set } from 'lodash'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import useConfirm from 'hooks/useConfirm'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useFragment } from 'hooks/useFragement'
import { usePortfolio } from 'hooks/usePortfolio'
import { imgMergeAndGrayScale, useHandleGetNpy } from './UploadMannequinDialog'
import { UploadButton } from 'components/UploadHeader'
import { useApiHandler } from 'hooks/useApiHandler'
import { useResetNpy } from 'hooks/useResetNpy'
import { blobToRLE } from 'components/fragment/fragmentHelpers'

export const roundButtonStyle = {
  borderRadius: '3rem',
  border: 0,
  fontSize: '1.2rem',

  boxShadow: ' 2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
  fontWeight: 600,
  '&.Mui-disabled': {
    border: 'none',
    '& svg path': {
      stroke: '#919EAB',
    },
  },
  '&:hover': {
    boxShadow: ' 2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
    background: 'unset',
    color: theme => theme.palette.draph.blue,
    '& svg path': {
      stroke: theme => theme.palette.draph.blue,
      transition: 'stroke 0.3s',
    },
  },
  '& svg path': {
    transition: 'stroke 0.3s',
  },
  '& svg': {
    mr: '0.5rem',
  },
}

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  fontSize: '1.3rem',
  fontWeight: 700,
  px: '1.6rem',
  width: 'auto',

  height: '3.2rem',
  borderRadius: '20px !important',
  outline: `2px solid`,
  outlineColor: `transparent`,
  borderColor: 'transparent',
  color: theme.palette.common.black,

  '&.Mui-selected': {
    boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
    color: theme.palette.draph.newBlue,
    background: 'none',
    outlineColor: `${theme.palette.draph.newBlue}`,
    '& rect': {
      fill: 'rgba(77, 128, 255, 0.7)',
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover': {
      color: theme.palette.draph.newBlue,
      boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
      background: 'none',
    },
  },

  '&:hover': {
    backgroundColor: theme.palette.draph.newBlue,
    color: theme.palette.common.white,
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
  },
  boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
  transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  gap: '0.4rem',
}))

const bannerStyle = {
  bannerConfigContainer: { width: '118.4rem', p: '3.6rem 0 4rem 0' },

  title: {
    fontSize: '2rem',
    fontWeight: 700,
  },

  subtitle: {
    fontSize: '1.3rem',
    color: '#505050',
    textAlign: 'center',
    lineHeight: 'normal',
  },

  productInfoTitle: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 'normal',
  },

  productInfoInput: {
    width: '33rem',
    height: '4rem',
    '& input': { textAlign: 'start', fontSize: '1.4rem' },
  },

  buttonStyle: {
    width: '20rem',
    height: '5rem',
    borderRadius: '10px',
    fontWeight: 800,
    fontSize: '2rem',
  },

  templateSubtitle: {
    fontWeight: 600,
    fontSize: '1.6rem',
    mb: '1.6rem',
  },
}

const bannerStyle2 = {
  title: {
    fontSize: '1.6rem',
    fontWeight: 800,
  },

  subtitle: {
    fontSize: '1.3rem',
    color: '#505050',
    textAlign: 'center',
    lineHeight: 'normal',
  },

  productInfoTitle: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 'normal',
  },
}

export function BannerInfo({ value, setValue }) {
  const { t, i18n } = useTranslation()
  const [bannerSizeRatio, setBannerSizeRatio] = useRecoilState(bannerSizeRatioAtom)
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)

  const hasProductGenType = ['full', 'mann2man', 'side']
  const [hasProduct, setHasProduct] = useRecoilState(hasProductAtom)
  const [isnoSOHumanDepict, setIsnoSoHumanDepict] = useRecoilState(isnoSOHumanDepictAtom)

  const disabledButton = !(
    bannerConfig.productName &&
    bannerConfig.productFeature &&
    bannerConfig.productCategory &&
    bannerConfig.outputW &&
    bannerConfig.outputH
  )
  const theme = useTheme()

  const [error, setError] = useState({
    productName: false,
    productFeature: false,
    productCategory: false,
  })

  useEffect(() => {
    if (bannerConfig.genType) {
      if (hasProductGenType.includes(bannerConfig.genType)) {
        setHasProduct(true)
      } else {
        setHasProduct(false)
      }

      if (bannerConfig.humanDepict) {
        setIsnoSoHumanDepict(true)
      } else {
        setIsnoSoHumanDepict(false)
      }
    }
  }, [bannerConfig.genType, bannerConfig.humanDepict])

  const productInfoInputs = [
    {
      id: 'productName',
      title: t('banner_config.info_input_1'),
      placeholder: t('banner_config.info_input_1_ex'),
    },
    {
      id: 'productFeature',
      title: t('banner_config.info_input_2'),
      placeholder: t('banner_config.info_input_2_ex'),
    },
    {
      id: 'productCategory',
      title: t('banner_config.info_input_3'),
      placeholder: t('banner_config.info_input_3_ex'),
    },
  ]

  // useEffect(() => {
  //   console.log(error)
  // }, [error])

  return (
    <CenterAlignStack sx={bannerStyle.bannerConfigContainer}>
      <CenterAlignStack sx={{ width: '100%' }}>
        <Typography sx={bannerStyle.title}>
          {t('banner_config.info_title')}
          <sup style={{ color: theme.palette.error.main }}>&#42;</sup>
        </Typography>
        <Typography sx={bannerStyle.subtitle}>{t('banner_config.info_subtitle')}</Typography>

        <Stack sx={{ gap: '1.6rem', mt: '1.6rem' }}>
          {productInfoInputs.map(p => {
            return (
              <Stack sx={{ gap: '0.8rem' }} key={p.id}>
                <Typography sx={bannerStyle.productInfoTitle}>{p.title}</Typography>
                <CustomOutlinedInput
                  value={bannerConfig[p.id]}
                  placeholder={p.placeholder}
                  onChange={e => {
                    if (error[p.id] && e.target.value) {
                      setError(prev => ({ ...prev, [p.id]: false }))
                    }
                    setBannerConfig(prev => ({ ...prev, [p.id]: e.target.value }))
                  }}
                  sx={bannerStyle.productInfoInput}
                  onBlur={e => {
                    if (!e.target.value) {
                      setError(prev => ({ ...prev, [p.id]: true }))
                    }
                  }}
                  error={error[p.id]}
                />
              </Stack>
            )
          })}
        </Stack>
        <BannerSize />
        <Button
          variant="contained"
          sx={bannerStyle.buttonStyle}
          disabled={disabledButton}
          onClick={() => {
            setValue(1)
            const ratio = bannerConfig.outputW / bannerConfig.outputH
            setBannerSizeRatio(ratio)
          }}
        >
          {t('button.next')}
        </Button>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}

const sizeMax = 3000
const sizeMin = 512

export function BannerSize() {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [error, setError] = useState({ w: false, h: false })

  const handleOutput = (name, e) => {
    const { value } = e.target
    const currentConfigWidthValue = bannerConfig.outputW
    const currentConfigHeightValue = bannerConfig.outputH

    if (/^[0-9]*$/.test(value)) {
      if (value.length > 0 && name === 'outputW' && !currentConfigHeightValue) {
        setError(e => ({ ...e, h: true }))
      }

      if (value.length > 0 && name === 'outputH' && !currentConfigWidthValue) {
        setError(e => ({ ...e, w: true }))
      }

      if (value.length < 1) {
        setBannerConfig(prev => ({
          ...prev,
          [name]: null,
        }))
      } else {
        setBannerConfig(prev => ({
          ...prev,
          [name]: value,
          // selectedExternalSize: null,
        }))
      }

      if (name === 'outputW' && currentConfigHeightValue && value.length) {
        setError({ w: false, h: false })
      }
      if (name === 'outputH' && currentConfigWidthValue && value.length) {
        setError({ w: false, h: false })
      }
    }
  }

  const handleBlur = (name, e) => {
    const { value } = e.target

    if (value.trim() === '') {
      setError(e => ({ ...e, [name]: true }))
    } else {
      setError(e => ({ ...e, [name]: false }))
    }

    if (value.length < 1) {
      setBannerConfig(prev => ({
        ...prev,
        [name]: null,
      }))
    }

    let size = Number(value)
    if (size < sizeMin) {
      size = sizeMin
    }
    if (size > sizeMax) {
      size = sizeMax
    }

    setBannerConfig(prev => ({
      ...prev,
      [name]: size,
    }))
  }

  return (
    <>
      <CenterAlignStack sx={{}}>
        <CenterAlignStack>
          <Typography sx={bannerStyle2.title}>
            {t('banner_config.size_title')}
            <sup style={{ color: theme.palette.error.main }}>&#42;</sup>
          </Typography>
          <Typography sx={bannerStyle2.subtitle}>
            {t('banner_config.size_subtitle_1')}
            <br />
            {t('banner_config.size_subtitle_2')}
          </Typography>
        </CenterAlignStack>

        <CenterAlignBox sx={{ my: '2.4rem' }}>
          <SmallTextField
            inputProps={{ inputMode: 'decimal', pattern: '[0-9]*', maxLength: 4 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ color: '#808080' }}>{t('upload_config.width')}</Typography>
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">px</InputAdornment>,
            }}
            value={bannerConfig.outputW}
            onChange={e => {
              handleOutput('outputW', e)
            }}
            onBlur={e => {
              handleBlur('outputW', e)
            }}
            sx={{
              width: { lg: '18rem ', xs: isKo(i18n) ? '12rem' : '12.6rem' },
              mr: { lg: '1rem', xs: '1rem' },
              backgroundColor: theme => theme.palette.common.white,
              '& input': {
                height: '1.7rem',
              },
            }}
          />
          <SmallTextField
            inputProps={{ inputMode: 'decimal', pattern: '[0-9]*', maxLength: 4 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ color: '#808080' }}>{t('upload_config.height')}</Typography>
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">px</InputAdornment>,
            }}
            value={bannerConfig.outputH}
            onChange={e => {
              handleOutput('outputH', e)
            }}
            onBlur={e => {
              handleBlur('outputH', e)
            }}
            sx={{
              width: { lg: '18rem ', xs: isKo(i18n) ? '12rem' : '12.6rem' },
              mr: { lg: '0rem', xs: '0rem' },
              backgroundColor: theme => theme.palette.common.white,
              '& input': {
                height: '1.7rem',
              },
            }}
          />
        </CenterAlignBox>
        <ExternalPreferredSize />
      </CenterAlignStack>
    </>
  )
}

export const BannerTemplate = () => {
  const { t, i18n } = useTranslation()

  const isProduct = [
    {
      value: false,
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_2'),
    },
    {
      value: true,
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_1'),
    },
  ]

  const model = [
    {
      value: 'full',
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_3'),
    },
    {
      value: 'mann2man',
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_4'),
    },
    {
      value: 'side',
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_5'),
    },
  ]

  const noSOHumanDepict = [
    {
      value: false,
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_3'),
    },
    {
      value: true,
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_5'),
    },
  ]

  const theme = useTheme()

  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [bannerSizeRatio, setBannerSizeRatio] = useRecoilState(bannerSizeRatioAtom)

  const user = useRecoilValue(userAtom)
  const [uploading, setUploading] = useRecoilState(loadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [open, setOpen] = useState(false)
  const [hasProduct, setHasProduct] = useRecoilState(hasProductAtom)
  const [isnoSOHumanDepict, setIsnoSoHumanDepict] = useRecoilState(isnoSOHumanDepictAtom)

  const { disabledButton } = useBanner()

  const calHeight = () => {
    const isLongHeight = ['side'].includes(bannerConfig.genType) || isnoSOHumanDepict

    if (bannerConfig.genType === 'mann2man') return { height: '33.9rem' }
    if (isLongHeight) return { height: '43.7rem' }
    if (!isLongHeight) return { height: '39.9rem' }
  }

  const addClickHandlerModel = value => () => {
    setBannerConfig(prev => ({ ...prev, genType: value }))
    if (value === 'full' || value === 'mann2man') {
      setBannerConfig(prev => ({ ...prev, humanDepict: '', bgDepict: '' }))
    }
  }

  const addClickHandlerProduct = value => () => {
    setHasProduct(value)
    if (value && bannerConfig.genType === 'no_SO') {
      setBannerConfig(prev => ({ ...prev, genType: 'full', humanDepict: '', bgDepict: '' }))
      setIsnoSoHumanDepict(false)
    }

    if (!value && bannerConfig.genType !== 'no_SO') {
      setBannerConfig(prev => ({ ...prev, genType: 'no_SO', humanDepict: '', bgDepict: '' }))
      setIsnoSoHumanDepict(false)
    }
  }

  const addClickHandlernoSOHumanDepict = value => () => {
    setIsnoSoHumanDepict(value)
    if (!value) {
      setBannerConfig(prev => ({ ...prev, humanDepict: '', bgDepict: '' }))
    }
  }

  // useEffect(() => {
  //   console.log('bannerConfig', bannerConfig.genType)
  //   console.log('hasProduct', hasProduct)
  //   console.log('isnoSOHumanDepict', isnoSOHumanDepict)
  //   console.log('--------------------------------------')
  // })

  const imageEx = {
    square: {
      style: { width: '25.4rem', height: '25.4rem', borderRadius: '10px' },
      styleMann2man: { width: '25.4rem', height: '25.4rem' },
      imageStyle: {
        borderRadius: '10px',
      },
      full: '/static/images/banner/square_full',
      mann2man: [
        '/static/images/banner/square_mann2man_1',
        '/static/images/banner/square_mann2man_2',
      ],
      side: '/static/images/banner/square_side',
      noSOnoHuman: '/static/images/banner/square_noSO_off',
      noSOyesHuman: '/static/images/banner/square_noSO_on',
      FOOH: '/static/images/banner/square_FOOH',
    },
    horizontal: {
      style: { width: '45.7rem', height: '24rem', borderRadius: '10px' },
      styleMann2man: { height: '24rem' },
      imageStyle: {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        height: '24rem',
      },
      full: '/static/images/banner/horizontal_full',
      mann2man: [
        '/static/images/banner/horizontal_mann2man_1.png',
        '/static/images/banner/horizontal_mann2man_2.png',
        '/static/images/banner/horizontal_mann2man_text',
      ],
      side: '/static/images/banner/horizontal_side',
      noSOnoHuman: '/static/images/banner/horizontal_noSO_off',
      noSOyesHuman: '/static/images/banner/horizontal_noSO_on',
      FOOH: '/static/images/banner/horizontal_FOOH',
    },
    vertical: {
      style: { width: '22rem', height: '27.6rem', borderRadius: '10px' },
      styleMann2man: { width: '22rem', height: '19rem' },
      imageStyle: { borderTopRightRadius: '10px', borderTopLeftRadius: '10px', height: '19rem' },
      full: '/static/images/banner/vertical_full',
      mann2man: [
        '/static/images/banner/vertical_mann2man_1.png',
        '/static/images/banner/vertical_mann2man_2.png',
        '/static/images/banner/vertical_mann2man_text',
      ],
      side: '/static/images/banner/vertical_side',
      noSOnoHuman: '/static/images/banner/vertical_noSO_off',
      noSOyesHuman: '/static/images/banner/vertical_noSO_on',
      FOOH: '/static/images/banner/vertical_FOOH',
    },
  }

  const imageSort = ratio => {
    if (ratio === null) return imageEx.square

    if (ratio < 1.09 && ratio > 0.91) {
      return imageEx.square
    }
    if (ratio >= 1.09) {
      return imageEx.horizontal
    }
    if (ratio <= 0.91) {
      return imageEx.vertical
    }
  }

  return (
    <CenterAlignStack sx={bannerStyle.bannerConfigContainer}>
      <CenterAlignBox sx={{ width: 'inherit', mb: '3.6rem' }}>
        <OpacityMotionDiv
          initialProps={{ y: -15 }}
          animateProps={{ y: 0 }}
          style={{
            width: '50%',
            transition: 'height 0.3s',
            ...calHeight(),
          }}
        >
          <CenterAlignStack>
            <Typography sx={{ ...bannerStyle.title }}>
              {t('banner_config.template_title_1')}
            </Typography>
            <Typography sx={bannerStyle.subtitle}>
              {t('banner_config.template_subtitle_3')}
            </Typography>
            <CenterAlignStack>
              <Typography sx={{ ...bannerStyle.templateSubtitle, mt: '3.2rem' }}>
                {t('banner_config.template_subtitle_1')}
              </Typography>
              <CenterAlignBox sx={{ gap: '2.8rem' }}>
                {isProduct.map((p, i) => {
                  return (
                    <StyledToggleButton
                      key={p.value}
                      value={p.value}
                      selected={hasProduct === p.value}
                      onChange={addClickHandlerProduct(p.value)}
                      sx={{
                        minWidth: '12rem',
                        height: '3.4rem',
                        fontSize: '1.4rem',

                        border: `2px solid`,
                        borderColor: `transparent`,
                        fontWeight: 600,
                        color: theme.palette.common.black,

                        '&.Mui-selected': {
                          boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                          color: theme.palette.draph.blue,
                          background: 'none',
                          borderColor: theme.palette.draph.blue,
                          outline: 'none',
                          fontWeight: 800,
                        },
                      }}
                    >
                      {p.source}
                    </StyledToggleButton>
                  )
                })}
              </CenterAlignBox>

              <Typography sx={{ ...bannerStyle.templateSubtitle, mt: '2.8rem' }}>
                {t('banner_config.template_subtitle_2')}
              </Typography>
              {hasProduct && (
                <OpacityMotionDiv initialProps={{ y: -15 }} animateProps={{ y: 0 }}>
                  <CenterAlignBox sx={{ gap: '2.8rem' }}>
                    {model.map((p, i) => {
                      return (
                        <StyledToggleButton
                          key={p.value}
                          value={p.value}
                          selected={bannerConfig.genType === p.value}
                          onChange={addClickHandlerModel(p.value)}
                          sx={{
                            minWidth: '12rem',
                            height: '3.4rem',
                            fontSize: isKo(i18n) ? '1.4rem' : '1.3rem',
                            border: `2px solid`,
                            borderColor: `transparent`,
                            fontWeight: 600,
                            color: theme.palette.common.black,
                            p: 0,
                            '&.Mui-selected': {
                              boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                              color: theme.palette.draph.blue,
                              background: 'none',
                              borderColor: theme.palette.draph.blue,
                              outline: 'none',
                              fontWeight: 800,
                            },
                          }}
                        >
                          {p.source}
                        </StyledToggleButton>
                      )
                    })}
                  </CenterAlignBox>
                </OpacityMotionDiv>
              )}
              {!hasProduct && (
                <OpacityMotionDiv initialProps={{ y: -15 }} animateProps={{ y: 0 }}>
                  <CenterAlignBox sx={{ gap: '2.8rem' }}>
                    {noSOHumanDepict.map((p, i) => {
                      return (
                        <StyledToggleButton
                          key={p.value}
                          value={p.value}
                          selected={isnoSOHumanDepict === p.value}
                          onChange={addClickHandlernoSOHumanDepict(p.value)}
                          sx={{
                            minWidth: '12rem',
                            height: '3.4rem',
                            fontSize: '1.4rem',
                            border: `2px solid`,
                            borderColor: `transparent`,
                            fontWeight: 600,
                            color: theme.palette.common.black,
                            '&.Mui-selected': {
                              boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                              color: theme.palette.draph.blue,
                              background: 'none',
                              borderColor: theme.palette.draph.blue,
                              outline: 'none',
                              fontWeight: 800,
                            },
                          }}
                        >
                          {p.source}
                        </StyledToggleButton>
                      )
                    })}
                  </CenterAlignBox>
                </OpacityMotionDiv>
              )}

              {<BannerPrompt hasProduct={hasProduct} isnoSOHumanDepict={isnoSOHumanDepict} />}
            </CenterAlignStack>
          </CenterAlignStack>
        </OpacityMotionDiv>

        <Divider flexItem orientation="vertical" />
        <CenterAlignStack sx={{ width: '50%' }}>
          <CenterAlignStack>
            <Typography sx={{ ...bannerStyle.title }}>
              {t('banner_config.template_title_2')}
            </Typography>
            <Typography sx={bannerStyle.subtitle}>
              {t('banner_config.template_subtitle_4')}
            </Typography>

            <Stack sx={{ mt: '2.4rem' }}>
              {bannerConfig.genType !== 'no_SO' ? (
                bannerConfig.genType !== 'mann2man' ? (
                  bannerConfig.genType !== 'full' ? (
                    <img
                      style={{ ...imageSort(bannerSizeRatio).style }}
                      src={`${imageSort(bannerSizeRatio)[bannerConfig.genType]}_${
                        i18n.language
                      }.png`}
                    />
                  ) : (
                    <img
                      style={{ ...imageSort(bannerSizeRatio).style }}
                      src={
                        bannerConfig.geodaiMax
                          ? `${imageSort(bannerSizeRatio).FOOH}_${getStaticResourceLanguage(
                              i18n.language
                            )}.png`
                          : `${
                              imageSort(bannerSizeRatio)[bannerConfig.genType]
                            }_${getStaticResourceLanguage(i18n.language)}.png`
                      }
                    />
                  )
                ) : (
                  <Stack sx={{ flexDirection: bannerSizeRatio <= 0.91 ? 'column' : 'row' }}>
                    {bannerSizeRatio >= 1.09 && (
                      <img
                        style={{
                          height: imageSort(bannerSizeRatio).style.height,
                          borderBottomLeftRadius: '10px',
                          borderTopLeftRadius: '10px',
                        }}
                        src={`${imageSort(bannerSizeRatio)[bannerConfig.genType][2]}_${
                          i18n.language
                        }.png`}
                      />
                    )}
                    <TwoImageSlider
                      duration={5.5}
                      size={{ ...imageSort(bannerSizeRatio).styleMann2man }}
                      image1={
                        bannerSizeRatio > 0.91 && bannerSizeRatio < 1.09
                          ? `${imageSort(bannerSizeRatio)[bannerConfig.genType][0]}_${
                              i18n.language
                            }.png`
                          : `${imageSort(bannerSizeRatio)[bannerConfig.genType][0]}`
                      }
                      image2={
                        bannerSizeRatio > 0.91 && bannerSizeRatio < 1.09
                          ? `${imageSort(bannerSizeRatio)[bannerConfig.genType][1]}_${
                              i18n.language
                            }.png`
                          : `${imageSort(bannerSizeRatio)[bannerConfig.genType][1]}`
                      }
                      image1Style={{ ...imageSort(bannerSizeRatio).imageStyle }}
                      image2Style={{ ...imageSort(bannerSizeRatio).imageStyle }}
                    />
                    {bannerSizeRatio <= 0.91 && (
                      <img
                        style={{
                          width: imageSort(bannerSizeRatio).style.width,
                          borderBottomLeftRadius: '10px',
                          borderBottomRightRadius: '10px',
                        }}
                        src={`${imageSort(bannerSizeRatio)[bannerConfig.genType][2]}_${
                          i18n.language
                        }.png`}
                      />
                    )}
                  </Stack>
                )
              ) : isnoSOHumanDepict ? (
                <img
                  style={{ ...imageSort(bannerSizeRatio).style }}
                  src={`${imageSort(bannerSizeRatio).noSOyesHuman}_${getStaticResourceLanguage(
                    i18n.language
                  )}.png`}
                />
              ) : (
                <img
                  style={{ ...imageSort(bannerSizeRatio).style }}
                  src={`${imageSort(bannerSizeRatio).noSOnoHuman}_${getStaticResourceLanguage(
                    i18n.language
                  )}.png`}
                />
              )}
            </Stack>
          </CenterAlignStack>

          {bannerConfig.genType === 'full' && (
            <CenterAlignStack>
              <FormControlLabel
                sx={{ m: '1.1rem 1.6rem 0 0', '& svg': { width: '1.6rem', height: '1.6rem' } }}
                control={
                  <Checkbox
                    sx={{ p: '0.5rem' }}
                    onClick={() =>
                      setBannerConfig(prev => ({
                        ...prev,
                        geodaiMax: !bannerConfig.geodaiMax,
                      }))
                    }
                    checked={bannerConfig.geodaiMax}
                  />
                }
                label={
                  <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
                    {t('banner_config.geodai_title')}
                  </Typography>
                }
                labelPlacement="end"
              />
              <Typography sx={{ fontSize: '1.3rem' }}>
                {t('banner_config.geodai_subtitle')}
              </Typography>
            </CenterAlignStack>
          )}
        </CenterAlignStack>
      </CenterAlignBox>

      <Button
        variant="contained"
        component="span"
        sx={{
          width: { lg: '26rem', xs: '18rem' },
          height: { lg: '6rem', xs: '4.2rem' },
          fontSize: { lg: '2rem', xs: '1.5rem' },
          fontWeight: 800,
          borderWidth: { lg: '0.2rem', xs: '0.1rem' },
        }}
        onClick={() => {
          const body = { config: bannerConfig }

          // ----- GA4 event -----
          window.gtag('event', 'banner_begin', { menu: 'setting' })
          // ---------------------

          apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
            setPortfolioDetail({ ...portfolioDetail, ...response.data })
          })
          setUploadOpen(true)
        }}
        disabled={disabledButton || uploading}
      >
        {!uploading && t('button.upload_image')}
        {uploading && <Loader />}
      </Button>
      {/* <BannerUploadImageDialog
        title1={t('banner_config.upload_title_1')}
        title2={t('banner_config.upload_title_2')}
      /> */}
      {/* {RIGHT_CLICK_ALLOW_USER && (
        <CenterAlignBox
          sx={{
            gap: '8px',
            mt: '15px',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              const body = { config: bannerConfig }
              console.log(body.config)
              apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
                setPortfolioDetail({ ...portfolioDetail, ...response.data })
              })
            }}
          >
            업데이트
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              const body = { config: {} }
              apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
                setPortfolioDetail({ ...portfolioDetail, ...response.data })
              })
            }}
          >
            초기화
          </Button>
        </CenterAlignBox>
      )} */}
    </CenterAlignStack>
  )
}

const ExternalPreferredSize = () => {
  const theme = useTheme()

  const externalValue = [
    {
      id: 'google',
      img: '/static/images/logo/outside/google_logo_1.png',
      imgStyle: { width: '3rem' },
      size: [
        {
          id: 'googleSquare',
          width: 1200,
          height: 1200,
          title: t('banner_config.size_1'),
          icon: <BannerSizeSquare />,
        },
        {
          id: 'googleHorizontal',
          width: 1200,
          height: 628,
          title: t('banner_config.size_2'),
          icon: <BannerSizeHorizontal />,
        },
        {
          id: 'googleVertical',
          width: 960,
          height: 1200,
          title: t('banner_config.size_3'),
          icon: <BannerSizeVertical />,
        },
      ],
    },
    {
      id: 'meta',
      img: '/static/images/logo/outside/meta_logo.png',
      imgStyle: { width: '3.6rem', height: '2.4rem' },
      size: [
        {
          id: 'metaSquare',
          width: 1080,
          height: 1080,
          title: t('banner_config.size_1'),
          icon: <BannerSizeSquare />,
        },
        {
          id: 'metaHorizontal',
          width: 1200,
          height: 628,
          title: t('banner_config.size_2'),
          icon: <BannerSizeHorizontal />,
        },
        {
          id: 'metaVertical',
          width: 1080,
          height: 1920,
          title: t('banner_config.size_4'),
          icon: <BannerSizeVertical />,
        },
      ],
    },
  ]

  const [size, setSize] = useState('')
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)

  // useEffect(() => {}, [bannerConfig.outputH, bannerConfig.outputW])

  return (
    <CenterAlignStack sx={{ gap: '1.6rem' }}>
      {externalValue.map(v => {
        return (
          <CenterAlignBox key={v.id} sx={{ gap: '1.2rem', alignItems: 'center' }}>
            <Box
              sx={{
                width: '2.6rem',
                height: '1.9rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={v.img} style={v.imgStyle} />
            </Box>

            {v.size.map(s => {
              return (
                <StyledToggleButton
                  sx={{ ...(s.id === 'googleVertical' && { minWidth: '10.2rem' }) }}
                  key={s.id}
                  value={s.id}
                  // selected={bannerConfig.selectedExternalSize === s.id}
                  onChange={e => {
                    // ----- GA4 event -----
                    window.gtag('event', 'banner_ad_size', {
                      menu: s.id,
                    })
                    // ---------------------

                    if (size === s.id) {
                      setBannerConfig(prev => ({
                        ...prev,
                        outputW: 1024,
                        outputH: 1024,
                        // selectedExternalSize: null,
                      }))
                    } else {
                      setBannerConfig(prev => ({
                        ...prev,
                        outputW: s.width,
                        outputH: s.height,
                        // selectedExternalSize: s.id,
                      }))
                    }
                  }}
                >
                  {s.icon}
                  {s.title}
                </StyledToggleButton>
              )
            })}
          </CenterAlignBox>
        )
      })}
    </CenterAlignStack>
  )
}

const BannerPrompt = ({ hasProduct, isnoSOHumanDepict }) => {
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  switch (true) {
    case bannerConfig.genType === 'full':
      return (
        <>
          <OpacityMotionDiv initialProps={{ y: -3 }} animateProps={{ y: 0 }}>
            <CenterAlignStack sx={{ mt: '3.6rem' }}>
              <Typography sx={{ ...bannerStyle.templateSubtitle, mb: 0 }}>
                {t('banner_config.model_subtitle')}
              </Typography>
              <Typography sx={bannerStyle.subtitle}>
                {t('banner_config.model_sub_subtitle')}
              </Typography>
              <CenterAlignStack sx={{ alignItems: 'center', mt: '1.2rem', gap: '0.8rem' }}>
                {/* <CenterAlignBox sx={{ alignItems: 'center', width: '100%', justifyContent: 'end' }}>
                  <Typography
                    sx={{
                      ...bannerStyle.templateSubtitle,
                      mb: 0,
                      mr: '1.2rem',
                      minWidth: '5.92rem',
                      textAlign: 'end',
                    }}
                  >
                    {t('banner_config.product_status')}
                  </Typography>
                  <CustomOutlinedInput
                    value={bannerConfig.productDepict}
                    placeholder={t('banner_config.product_status_input_ex_1')}
                    onChange={e => {
                      setBannerConfig(prev => ({
                        ...prev,
                        productDepict: e.target.value,
                      }))
                    }}
                    sx={{
                      ...bannerStyle.productInfoInput,
                      width: '32.8rem',
                    }}
                  />
                </CenterAlignBox> */}
                {/* <CenterAlignBox sx={{ alignItems: 'center', width: '100%', justifyContent: 'end' }}>
                  <Typography
                    sx={{
                      ...bannerStyle.templateSubtitle,
                      mb: 0,
                      mr: '1.2rem',
                      minWidth: '5.92rem',
                      textAlign: 'end',
                    }}
                  >
                    {t('banner_config.product_background')}
                  </Typography>
                  <CustomOutlinedInput
                    value={bannerConfig.bgDepict}
                    placeholder={t('banner_config.product_background_input_ex_1')}
                    onChange={e => {
                      setBannerConfig(prev => ({
                        ...prev,
                        bgDepict: e.target.value,
                      }))
                    }}
                    sx={{
                      ...bannerStyle.productInfoInput,

                      width: '32.8rem',
                    }}
                  />


                </CenterAlignBox> */}

                <CustomOutlinedInput
                  value={bannerConfig.bgDepict}
                  placeholder={t('banner_config.product_background_input_ex_1')}
                  onChange={e => {
                    setBannerConfig(prev => ({ ...prev, bgDepict: e.target.value }))
                  }}
                  sx={{
                    ...bannerStyle.productInfoInput,
                    // mt: '1.2rem',
                    '& input': {
                      textAlign: 'center',
                      fontSize: '1.4rem',
                    },
                  }}
                />
              </CenterAlignStack>
            </CenterAlignStack>
          </OpacityMotionDiv>
        </>
      )

    case bannerConfig.genType === 'mann2man':
      break
    case bannerConfig.genType === 'side':
      return (
        <>
          <OpacityMotionDiv initialProps={{ y: -3 }} animateProps={{ y: 0 }}>
            <CenterAlignStack sx={{ mt: '3.6rem' }}>
              <Typography sx={{ ...bannerStyle.templateSubtitle, mb: 0 }}>
                {t('banner_config.model_subtitle')}
              </Typography>
              <Typography sx={bannerStyle.subtitle}>
                <Korean>
                  배경 <span style={{ textDecoration: 'underline' }}>미입력</span> 시, AI 추천
                  배경이 적용됩니다.
                </Korean>
                <English>
                  <span style={{ textDecoration: 'underline' }}>When left blank</span>, AI
                  recommended background will be applied.
                </English>
              </Typography>
              <CenterAlignStack sx={{ alignItems: 'center', mt: '1.2rem', gap: '0.8rem' }}>
                <CenterAlignBox sx={{ alignItems: 'center', width: '100%', justifyContent: 'end' }}>
                  <Typography
                    sx={{
                      ...bannerStyle.templateSubtitle,
                      mb: 0,
                      mr: isKo(i18n) ? '1.2rem' : '0.7rem',
                      fontSize: isKo(i18n) ? '1.6rem' : '1.4rem',
                      minWidth: '5.92rem',
                      textAlign: 'end',
                    }}
                  >
                    {t('banner_config.model_title')}
                    <sup
                      style={{
                        color: theme.palette.error.main,
                        fontWeight: 500,
                        fontSize: '1.8rem',
                        lineHeight: '2.1rem',
                        verticalAlign: 'text-top',
                      }}
                    >
                      &#42;
                    </sup>
                  </Typography>
                  <CustomOutlinedInput
                    value={bannerConfig.humanDepict}
                    placeholder={t('banner_config.model_input_ex_1')}
                    onChange={e => {
                      setBannerConfig(prev => ({
                        ...prev,
                        humanDepict: e.target.value,
                      }))
                    }}
                    sx={{
                      ...bannerStyle.productInfoInput,
                      width: isKo(i18n) ? '32.8rem' : '26.8rem',
                    }}
                  />
                </CenterAlignBox>
                <CenterAlignBox sx={{ alignItems: 'center', width: '100%', justifyContent: 'end' }}>
                  <Typography
                    sx={{
                      ...bannerStyle.templateSubtitle,
                      mb: 0,
                      mr: isKo(i18n) ? '1.2rem' : '0.7rem',
                      fontSize: isKo(i18n) ? '1.6rem' : '1.4rem',

                      minWidth: '5.92rem',
                      textAlign: 'end',
                    }}
                  >
                    {t('banner_config.product_background')}
                  </Typography>
                  <CustomOutlinedInput
                    value={bannerConfig.bgDepict}
                    placeholder={t('banner_config.product_background_input_ex_2')}
                    onChange={e => {
                      setBannerConfig(prev => ({
                        ...prev,
                        bgDepict: e.target.value,
                      }))
                    }}
                    sx={{
                      ...bannerStyle.productInfoInput,

                      width: isKo(i18n) ? '32.8rem' : '26.8rem',
                    }}
                  />
                </CenterAlignBox>
              </CenterAlignStack>
            </CenterAlignStack>
          </OpacityMotionDiv>
        </>
      )

    case bannerConfig.genType === 'no_SO' && !isnoSOHumanDepict:
      return (
        <>
          <OpacityMotionDiv initialProps={{ y: -3 }} animateProps={{ y: 0 }}>
            <CenterAlignStack sx={{ mt: '3.6rem' }}>
              <Typography sx={{ ...bannerStyle.templateSubtitle, mb: 0 }}>
                {t('banner_config.model_subtitle')}
              </Typography>
              <Typography sx={bannerStyle.subtitle}>
                {t('banner_config.model_sub_subtitle')}
              </Typography>
              <CustomOutlinedInput
                value={bannerConfig.bgDepict}
                placeholder={t('banner_config.product_background_input_ex_3')}
                onChange={e => {
                  setBannerConfig(prev => ({ ...prev, bgDepict: e.target.value }))
                }}
                sx={{
                  ...bannerStyle.productInfoInput,
                  mt: '1.2rem',
                  '& input': {
                    textAlign: 'center',
                    fontSize: '1.4rem',
                  },
                }}
              />
            </CenterAlignStack>
          </OpacityMotionDiv>
        </>
      )
    case bannerConfig.genType === 'no_SO' && isnoSOHumanDepict:
      return (
        <>
          <OpacityMotionDiv initialProps={{ y: -3 }} animateProps={{ y: 0 }}>
            <CenterAlignStack sx={{ mt: '3.6rem' }}>
              <Typography sx={{ ...bannerStyle.templateSubtitle, mb: 0 }}>
                {t('banner_config.model_subtitle')}
              </Typography>
              <Typography sx={bannerStyle.subtitle}>
                <Korean>
                  배경 <span style={{ textDecoration: 'underline' }}>미입력</span> 시, AI 추천
                  배경이 적용됩니다.
                </Korean>

                <English>
                  <span style={{ textDecoration: 'underline' }}>When left blank</span>, AI
                  recommended background will be applied.
                </English>
              </Typography>
              <CenterAlignStack sx={{ alignItems: 'center', mt: '1.2rem', gap: '0.8rem' }}>
                <CenterAlignBox sx={{ alignItems: 'center', width: '100%', justifyContent: 'end' }}>
                  <Typography
                    sx={{
                      ...bannerStyle.templateSubtitle,
                      mb: 0,
                      mr: isKo(i18n) ? '1.2rem' : '0.7rem',
                      fontSize: isKo(i18n) ? '1.6rem' : '1.4rem',
                      minWidth: '5.92rem',
                      textAlign: 'end',
                    }}
                  >
                    {t('banner_config.model_title')}
                    <sup
                      style={{
                        color: theme.palette.error.main,
                        fontWeight: 500,
                        fontSize: '1.8rem',
                        lineHeight: '2.1rem',
                        verticalAlign: 'text-top',
                      }}
                    >
                      &#42;
                    </sup>
                  </Typography>
                  <CustomOutlinedInput
                    value={bannerConfig.humanDepict}
                    placeholder={t('banner_config.model_input_ex_2')}
                    onChange={e => {
                      setBannerConfig(prev => ({
                        ...prev,
                        humanDepict: e.target.value,
                      }))
                    }}
                    sx={{
                      ...bannerStyle.productInfoInput,
                      width: isKo(i18n) ? '32.8rem' : '26.8rem',
                    }}
                  />
                </CenterAlignBox>
                <CenterAlignBox sx={{ alignItems: 'center', width: '100%', justifyContent: 'end' }}>
                  <Typography
                    sx={{
                      ...bannerStyle.templateSubtitle,
                      mb: 0,
                      mr: isKo(i18n) ? '1.2rem' : '0.7rem',
                      fontSize: isKo(i18n) ? '1.6rem' : '1.4rem',
                      minWidth: '5.92rem',
                      textAlign: 'end',
                    }}
                  >
                    {t('banner_config.product_background')}
                  </Typography>
                  <CustomOutlinedInput
                    value={bannerConfig.bgDepict}
                    placeholder={t('banner_config.product_status_input_ex_2')}
                    onChange={e => {
                      setBannerConfig(prev => ({
                        ...prev,
                        bgDepict: e.target.value,
                      }))
                    }}
                    sx={{
                      ...bannerStyle.productInfoInput,

                      width: isKo(i18n) ? '32.8rem' : '26.8rem',
                    }}
                  />
                </CenterAlignBox>
              </CenterAlignStack>
            </CenterAlignStack>
          </OpacityMotionDiv>
        </>
      )

    default:
      break
  }
}

// {
// humanDepictAllowedGenType ? (
// <>
//   <Typography sx={{ ...bannerStyle.templateSubtitle }}>
//     {t('banner_config.model_subtitle')}
//   </Typography>
//   <CustomOutlinedInput
//     value={bannerConfig.humanDepict}
//     placeholder={t('banner_config.product_status_input_ex')}
//     onChange={e => {
//       setBannerConfig(prev => ({ ...prev, humanDepict: e.target.value }))
//     }}
//     sx={{
//       ...bannerStyle.productInfoInput,

//       '& input': {
//         textAlign: 'center',
//         fontSize: '1.4rem',
//       },
//     }}
//   />
// </>
//   ) : (
//     <>
//       <Typography sx={{ ...bannerStyle.templateSubtitle, mb: 0 }}>
//         {t('banner_config.model_subtitle')}
//       </Typography>
//       <Typography sx={bannerStyle.subtitle}>{t('banner_config.model_sub_subtitle')}</Typography>
//       <CenterAlignStack sx={{ alignItems: 'center', mt: '1.2rem', gap: '0.8rem' }}>
//         <CenterAlignBox sx={{ alignItems: 'center' }}>
//           <Typography
//             sx={{
//               ...bannerStyle.templateSubtitle,
//               mb: 0,
//               mr: '1.2rem',
//               minWidth: '5.92rem',
//               textAlign: 'end',
//             }}
//           >
//             {t('banner_config.product_status')}
//           </Typography>
//           <CustomOutlinedInput
//             value={bannerConfig.productDepict}
//             placeholder={t('banner_config.product_status_input_ex')}
//             onChange={e => {
//               setBannerConfig(prev => ({
//                 ...prev,
//                 productDepict: e.target.value,
//               }))
//             }}
//             sx={{
//               ...bannerStyle.productInfoInput,
//               width: '32.8rem',
//             }}
//           />
//         </CenterAlignBox>
//         <CenterAlignBox sx={{ alignItems: 'center' }}>
//           <Typography
//             sx={{
//               ...bannerStyle.templateSubtitle,
//               mb: 0,
//               mr: '1.2rem',
//               minWidth: '5.92rem',
//               textAlign: 'end',
//             }}
//           >
//             {t('banner_config.product_background')}
//           </Typography>
//           <CustomOutlinedInput
//             value={bannerConfig.bgDepict}
//             placeholder={t('banner_config.product_background_input_ex')}
//             onChange={e => {
//               setBannerConfig(prev => ({
//                 ...prev,
//                 bgDepict: e.target.value,
//               }))
//             }}
//             sx={{
//               ...bannerStyle.productInfoInput,

//               width: '32.8rem',
//             }}
//           />
//         </CenterAlignBox>
//       </CenterAlignStack>
//     </>
//   )
// }

export const BannerConfigPage = ({ uploadButton }) => {
  const [majorPage, setMajorPage] = useRecoilState(bannerMajorPageAtom)
  const [uploadOpen2, setUploadOpen2] = useRecoilState(uploadDialogOpen2Atom)
  const [minorPage, setMinorPage] = useState(0)

  const [concept, setConcept] = useState('')
  const [gender, setGender] = useState('female')
  const [age, setAge] = useState('adult')

  useEffect(() => {
    return () => {
      setMajorPage(0)
    }
  }, [])

  switch (majorPage) {
    case 0:
      return (
        <>
          <BannerTemplate2 minorPage={minorPage} setMinorPage={setMinorPage} />
          {/* {uploadOpen2 && (
            <UploadMannequinDialog
              firstNotice={
                <CenterAlignStack sx={{ gap: '4px' }}>
                  <Typography fontSize="2.2rem" fontWeight={700} sx={{ lineHeight: 'normal' }}>
                    {t('banner_config.upload_title_2_dialog_title')}
                  </Typography>
                  <Typography
                    fontSize="1.5rem"
                    fontWeight={400}
                    sx={{ textAlign: 'center', lineHeight: 'normal' }}
                  >
                    {t('banner_config.upload_title_2_dialog_notice_1')} <br />
                    {t('banner_config.upload_title_2_dialog_notice_2')}
                  </Typography>
                </CenterAlignStack>
              }
              usage={'banner'}
              useUploadOpen2={true}
            />
          )} */}
        </>
      )

    case 1:
      return (
        <BannerMannequin
          setMajorPage={setMajorPage}
          setMinorPage={setMinorPage}
          concept={concept}
          setConcept={setConcept}
          age={age}
          setAge={setAge}
          gender={gender}
          setGender={setGender}
        />
      )

    case 2:
      return <BannerInfo2 uploadButton={uploadButton} setMinorPage={setMinorPage} />
  }
}

export const BannerTemplate2 = ({ minorPage, setMinorPage }) => {
  const [majorPage, setMajorPage] = useRecoilState(bannerMajorPageAtom)

  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const hookingArray = [
    {
      value: 'image_hooking',
      source: t('banner_config.template_hooking_1'),
    },
    {
      value: 'text_hooking',
      source: t('banner_config.template_hooking_2'),
    },
  ]

  const isProduct = [
    {
      value: false,
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_2'),
    },
    {
      value: true,
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_1'),
    },
  ]

  const model = [
    {
      value: 'full',
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_3'),
    },
    {
      value: 'mann2man',
      imageSrc: '/static/images/banner/full.png',
      source: isKo(i18n) ? (
        t('banner_config.template_select_4')
      ) : (
        <>
          AI Model Wearing <br /> The Product
        </>
      ),
    },
    {
      value: 'side',
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_5'),
    },
  ]

  const noSOHumanDepict = [
    {
      value: false,
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_3'),
    },
    {
      value: true,
      imageSrc: '/static/images/banner/full.png',
      source: t('banner_config.template_select_5'),
    },
  ]

  // value 다 따로 해야함
  const copyStyle = [
    { value: 'price', source: t('banner_config.copy_1') },
    { value: 'info', source: t('banner_config.copy_2') },
    { value: 'question', source: t('banner_config.copy_3') },
    { value: 'review', source: t('banner_config.copy_4') },
    // { value: 'testimony', source: t('banner_config.copy_5') },
    // { value: 'pronoun', source: t('banner_config.copy_6') },
    // { value: 'review', source: t('banner_config.copy_7') },
  ]

  const [uploadOpen2, setUploadOpen2] = useRecoilState(uploadDialogOpen2Atom)

  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const hasProductGenType = ['full', 'mann2man', 'side']

  const handleGetNpy = useHandleGetNpy()
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)

  useEffect(() => {
    if (bannerConfig.genType) {
      if (hasProductGenType.includes(bannerConfig.genType)) {
        setHasProduct(true)
      } else {
        setHasProduct(false)
      }

      if (bannerConfig.humanDepict) {
        setIsnoSoHumanDepict(true)
      } else {
        setIsnoSoHumanDepict(false)
      }
    }
  }, [bannerConfig.genType])
  const hookingHandler = value => () => {
    setBannerConfig(prev => ({ ...prev, bannerStyle: value }))

    if (value === 'image_hooking') {
      setBannerConfig(prev => ({ ...prev, copyStyle: 'question' }))
    }

    if (value === 'text_hooking') {
      setBannerConfig(prev => ({ ...prev, copyStyle: 'price' }))
    }
  }

  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [maskImgArray, setMaskImgArray] = useRecoilState(maskImgArrayAtom)

  const nextHandler = () => {
    if (minorPage === 0) {
      setMinorPage(p => {
        return 1
      })
    }

    if (minorPage === 1) {
      if (bannerConfig.genType === 'mann2man') {
        setMajorPage(p => {
          return p + 1
        })

        // 세그먼트가 도는 중이거나 이미 끝나서 마스크가 있는 경우는 다시하지 않음
        if (!segmentLoading && !maskImgArray) {
          handleGetNpy({ image: files[0].file, service: 'mannequin' })
        }
      } else {
        setMajorPage(p => {
          return p + 2
        })
      }
    }
  }

  const prevHandler = () => {
    if (minorPage === 1 && majorPage === 0) {
      setMinorPage(p => {
        return 0
      })
    }

    // if (majorPage === 0) {
    // }

    // if (minorPage === 1) {
    //   if (bannerConfig.genType === 'mann2man') {
    //     setMajorPage(p => {
    //       return p + 1
    //     })
    //     handleGetNpy(files[0].file)
    //   } else {
    //     setMajorPage(p => {
    //       return p + 2
    //     })
    //   }
    // }
  }

  const { abortApi } = useApiHandler({ apiKey: 'getNpyMaps', apis: apis.appfront })
  const { resetAllState } = useResetNpy()

  const [hasProduct, setHasProduct] = useRecoilState(hasProductAtom)
  const [isnoSOHumanDepict, setIsnoSoHumanDepict] = useRecoilState(isnoSOHumanDepictAtom)

  const calHeight = () => {
    const isLongHeight = ['side'].includes(bannerConfig.genType) || isnoSOHumanDepict

    if (bannerConfig.genType === 'mann2man') return { height: '33.9rem' }
    if (isLongHeight) return { height: '43.7rem' }
    if (!isLongHeight) return { height: '39.9rem' }
  }

  const addClickHandlerModel = value => () => {
    setBannerConfig(prev => ({ ...prev, genType: value }))
    if (value === 'full' || value === 'mann2man') {
      setBannerConfig(prev => ({ ...prev, humanDepict: '', bgDepict: '' }))
    }
  }

  const addClickHandlerProduct = value => () => {
    setHasProduct(value)
    if (value && bannerConfig.genType === 'no_SO') {
      setBannerConfig(prev => ({ ...prev, genType: 'full', humanDepict: '', bgDepict: '' }))
      setIsnoSoHumanDepict(false)
    }

    if (!value && bannerConfig.genType !== 'no_SO') {
      // setFiles([])
      setBannerConfig(prev => ({ ...prev, genType: 'no_SO', humanDepict: '', bgDepict: '' }))
      setIsnoSoHumanDepict(false)
    }
  }

  const addClickHandlernoSOHumanDepict = value => () => {
    setIsnoSoHumanDepict(value)
    if (!value) {
      setBannerConfig(prev => ({ ...prev, humanDepict: '', bgDepict: '' }))
    }
  }

  useEffect(() => {
    if (bannerConfig.genType !== 'mann2man') {
      resetAllState()
      abortApi()
    }
  }, [bannerConfig.genType, hasProduct, isnoSOHumanDepict])

  const disabledButton =
    minorPage === 1 &&
    ((bannerConfig.genType === 'side' && !bannerConfig.humanDepict) ||
      (bannerConfig.genType === 'no_SO' && isnoSOHumanDepict && !bannerConfig.humanDepict) ||
      (bannerConfig.genType === 'mann2man' && !files.length) ||
      (bannerConfig.copyStyle === 'price' && !bannerConfig.prevPrice && !bannerConfig.postPrice))

  return (
    <>
      <CenterAlignStack sx={{ width: 'inherit' }}>
        <CenterAlignBox sx={{ mb: '4.4rem' }}>
          <CenterAlignStack sx={{ width: '37.8rem' }}>
            <CenterAlignStack>
              <Typography sx={{ fontWeight: 800, lineHeight: 'normal', mb: '0.2rem' }}>
                {t('banner_config.detail_title')}
              </Typography>
              <Typography sx={{ ...bannerStyle.templateSubtitle, mt: '3.2rem' }}>
                {t('banner_config.template_subtitle_1')}
              </Typography>
              <CenterAlignBox sx={{ gap: '1.2rem' }}>
                {isProduct.map((p, i) => {
                  return (
                    <StyledToggleButton
                      key={p.value}
                      value={p.value}
                      selected={hasProduct === p.value}
                      onChange={addClickHandlerProduct(p.value)}
                      sx={{
                        minWidth: '12rem',
                        fontSize: isKo(i18n) ? '1.4rem' : '1.3rem',
                        p: 0,
                        border: `2px solid`,
                        borderColor: `transparent`,
                        fontWeight: 600,
                        color: theme.palette.common.black,

                        '&.Mui-selected': {
                          boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                          color: theme.palette.draph.newBlue,
                          background: 'none',
                          borderColor: theme.palette.draph.newBlue,
                          outline: 'none',
                          fontWeight: 800,
                        },
                      }}
                    >
                      {p.source}
                    </StyledToggleButton>
                  )
                })}
              </CenterAlignBox>

              <Typography sx={{ ...bannerStyle.templateSubtitle, mt: '2.8rem' }}>
                {t('banner_config.template_subtitle_2')}
              </Typography>
              {hasProduct && (
                <OpacityMotionDiv initialProps={{ y: -15 }} animateProps={{ y: 0 }}>
                  <CenterAlignBox sx={{ gap: '1.2rem' }}>
                    {model.map((p, i) => {
                      return (
                        <StyledToggleButton
                          key={p.value}
                          value={p.value}
                          selected={bannerConfig.genType === p.value}
                          onChange={addClickHandlerModel(p.value)}
                          sx={{
                            minWidth: '10rem',
                            height: isKo(i18n) ? '3.4rem' : 'auto',
                            fontSize: '1.4rem',
                            border: `2px solid`,
                            borderColor: `transparent`,
                            fontWeight: 600,
                            color: theme.palette.common.black,
                            p: isKo(i18n) ? '0 1.8rem' : '0.3rem 1.3rem',
                            '&.Mui-selected': {
                              boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                              color: theme.palette.draph.newBlue,
                              background: 'none',
                              borderColor: theme.palette.draph.newBlue,
                              outline: 'none',
                              fontWeight: 800,
                            },
                          }}
                        >
                          {p.source}
                        </StyledToggleButton>
                      )
                    })}
                  </CenterAlignBox>
                </OpacityMotionDiv>
              )}
              {!hasProduct && (
                <OpacityMotionDiv initialProps={{ y: -15 }} animateProps={{ y: 0 }}>
                  <CenterAlignBox sx={{ gap: '1.2rem' }}>
                    {noSOHumanDepict.map((p, i) => {
                      return (
                        <StyledToggleButton
                          key={p.value}
                          value={p.value}
                          selected={isnoSOHumanDepict === p.value}
                          onChange={addClickHandlernoSOHumanDepict(p.value)}
                          sx={{
                            minWidth: '12rem',
                            height: '3.4rem',
                            fontSize: isKo(i18n) ? '1.4rem' : '1.3rem',
                            border: `2px solid`,
                            borderColor: `transparent`,
                            fontWeight: 600,
                            color: theme.palette.common.black,

                            '&.Mui-selected': {
                              boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                              color: theme.palette.draph.newBlue,
                              background: 'none',
                              borderColor: theme.palette.draph.newBlue,
                              outline: 'none',
                              fontWeight: 800,
                            },
                          }}
                        >
                          {p.source}
                        </StyledToggleButton>
                      )
                    })}
                  </CenterAlignBox>
                </OpacityMotionDiv>
              )}

              {<BannerPrompt hasProduct={hasProduct} isnoSOHumanDepict={isnoSOHumanDepict} />}
            </CenterAlignStack>
          </CenterAlignStack>

          {/* <CenterAlignStack sx={{ width: '32rem' }}>
            <Typography sx={{ fontWeight: 800, mb: '2rem' }}>
              {t('banner_config.hooking_title')}
            </Typography>
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 600, mb: '1.3rem' }}>
              {t('banner_config.hooking_subtitle')}
            </Typography>

            <CenterAlignBox sx={{ gap: '1.2rem' }}>
              {hookingArray.map((p, i) => {
                return (
                  <StyledToggleButton
                    key={p.value}
                    value={p.value}
                    selected={bannerConfig.bannerStyle === p.value}
                    onChange={hookingHandler(p.value)}
                    sx={{
                      minWidth: '12rem',
                      height: '3.4rem',
                      fontSize: isKo(i18n) ? '1.4rem' : '1.3rem',

                      border: `2px solid`,
                      borderColor: `transparent`,
                      fontWeight: 600,
                      color: theme.palette.common.black,

                      '&.Mui-selected': {
                        boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                        color: theme.palette.draph.blue,
                        background: 'none',
                        borderColor: theme.palette.draph.newBlue,
                        outline: 'none',
                        fontWeight: 800,
                      },
                    }}
                  >
                    {p.source}
                  </StyledToggleButton>
                )
              })}
            </CenterAlignBox>

            {bannerConfig.bannerStyle === 'text_hooking' && (
              <OpacityMotionDiv
                style={{
                  padding: '1.3rem 1.1rem',
                  border: '1px solid #B6CBFF',
                  borderRadius: '10px',
                  width: '32rem',
                  gap: '1.1rem',
                  display: 'flex',
                  flexFlow: 'wrap',
                  justifyContent: 'center',
                  marginTop: '2rem',
                  zIndex: 1,
                  backgroundColor: theme.palette.common.white,
                  boxShadow:
                    bannerConfig.copyStyle === 'price'
                      ? '0px 4px 10px 0px rgba(0, 0, 0, 0.03)'
                      : '',
                }}
              >
                {copyStyle.map((v, i) => {
                  return (
                    <FormControlLabel
                      key={v.value}
                      sx={{ m: 0, '& .MuiFormControlLabel-label': { fontSize: '1.2rem' } }}
                      control={
                        <Checkbox
                          size="small"
                          value={v.value}
                          checked={v.value === bannerConfig.copyStyle}
                          icon={<SquareIcon2 style={{ width: '1.4rem', height: '1.4rem' }} />}
                          checkedIcon={
                            <CheckedSquareIcon3 sx={{ width: '1.4rem', height: '1.4rem' }} />
                          }
                          onChange={e => {
                            setBannerConfig(p => ({ ...p, copyStyle: e.target.value }))
                          }}
                          sx={{
                            p: '0 0.4rem 0 0',
                          }}
                        />
                      }
                      label={v.source}
                    />
                  )
                })}
              </OpacityMotionDiv>
            )}

            {bannerConfig.copyStyle === 'price' && (
              <OpacityMotionDiv
                initialProps={{ y: -30 }}
                animateProps={{ y: -8 }}
                style={{
                  display: 'flex',
                  backgroundColor: '#F2F6FF',
                  padding: '2.4rem 0 1.6rem 0',
                  justifyContent: 'center',
                  width: '32rem',
                  flexDirection: 'column',
                  borderRadius: '0 0 10px 10px',
                  gap: '0.8rem',
                }}
              >
                <CenterAlignBox sx={{ gap: '1.2rem', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '1.4rem' }}>
                    {' '}
                    {t('banner_config.prev_price')}
                  </Typography>
                  <CustomOutlinedInput
                    inputProps={{
                      style: {},
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    value={bannerConfig.prevPrice}
                    placeholder={t('banner_config.price_ex')}
                    onChange={e => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault()
                      }
                      setBannerConfig(prev => ({ ...prev, prevPrice: e.target.value }))
                    }}
                    sx={{
                      backgroundColor: theme.palette.common.white,
                      width: '23.6rem',
                      height: '2.8rem',
                      '& input': { textAlign: 'start', fontSize: '1.2rem' },

                      '&.MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderWidth: '0.1rem',
                          borderColor: bannerConfig.prevPrice
                            ? theme.palette.common.black
                            : '#D9E1EC',
                        },
                      },
                    }}
                  />
                </CenterAlignBox>
                <CenterAlignBox sx={{ gap: '1.2rem', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '1.4rem' }}>
                    {' '}
                    {t('banner_config.next_price')}
                  </Typography>{' '}
                  <CustomOutlinedInput
                    value={bannerConfig.postPrice}
                    inputProps={{
                      style: {},
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    placeholder={t('banner_config.price_ex')}
                    onChange={e => {
                      setBannerConfig(prev => ({ ...prev, postPrice: e.target.value }))
                    }}
                    sx={{
                      backgroundColor: theme.palette.common.white,
                      width: '23.6rem',
                      height: '2.8rem',
                      '& input': { textAlign: 'start', fontSize: '1.2rem' },

                      '&.MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderWidth: '0.1rem',
                          borderColor: bannerConfig.postPrice
                            ? theme.palette.common.black
                            : '#D9E1EC',
                        },
                      },
                    }}
                  />
                </CenterAlignBox>
              </OpacityMotionDiv>
            )}
          </CenterAlignStack> */}

          <Divider flexItem orientation="vertical" sx={{ mx: '4rem' }} />

          {minorPage === 0 ? (
            <CenterAlignStack sx={{ width: '32rem' }}>
              <Typography sx={{ fontWeight: 800, lineHeight: 'normal', mb: '0.2rem' }}>
                {t('banner_config.example_title')}
              </Typography>
              <Typography
                sx={{ fontWeight: 400, fontSize: '1.3rem', mb: '1.6rem', color: '#505050' }}
              >
                {t('banner_config.example_subtitle')}
              </Typography>
              <ImageOverlay
                topImageSrc={`/static/images/banner/square_noSO_off_${getStaticResourceLanguage(
                  i18n.language
                )}.png`}
                bottomImageSrc={`/static/images/banner/square_noSO_on_${getStaticResourceLanguage(
                  i18n.language
                )}.png`}
              />
            </CenterAlignStack>
          ) : (
            <CenterAlignStack sx={{ width: '32rem' }}>
              <Typography sx={{ fontWeight: 800, mb: '2rem' }}>
                {t('banner_config.hooking_title')}
              </Typography>
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 600, mb: '1.3rem' }}>
                {t('banner_config.hooking_subtitle')}
              </Typography>

              <CenterAlignBox sx={{ gap: '1.2rem' }}>
                {hookingArray.map((p, i) => {
                  return (
                    <StyledToggleButton
                      key={p.value}
                      value={p.value}
                      selected={bannerConfig.bannerStyle === p.value}
                      onChange={hookingHandler(p.value)}
                      sx={{
                        minWidth: '12rem',
                        height: '3.4rem',
                        fontSize: isKo(i18n) ? '1.4rem' : '1.3rem',

                        border: `2px solid`,
                        borderColor: `transparent`,
                        fontWeight: 600,
                        color: theme.palette.common.black,

                        '&.Mui-selected': {
                          boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                          color: theme.palette.draph.blue,
                          background: 'none',
                          borderColor: theme.palette.draph.newBlue,
                          outline: 'none',
                          fontWeight: 800,
                        },
                      }}
                    >
                      {p.source}
                    </StyledToggleButton>
                  )
                })}
              </CenterAlignBox>

              {bannerConfig.bannerStyle === 'text_hooking' && (
                <OpacityMotionDiv
                  style={{
                    padding: '1.3rem 1.1rem',
                    border: '1px solid #B6CBFF',
                    borderRadius: '10px',
                    width: '32rem',
                    gap: '1.1rem',
                    display: 'flex',
                    flexFlow: 'wrap',
                    justifyContent: 'center',
                    marginTop: '2rem',
                    zIndex: 1,
                    backgroundColor: theme.palette.common.white,
                    boxShadow:
                      bannerConfig.copyStyle === 'price'
                        ? '0px 4px 10px 0px rgba(0, 0, 0, 0.03)'
                        : '',
                  }}
                >
                  {copyStyle.map((v, i) => {
                    return (
                      <FormControlLabel
                        key={v.value}
                        sx={{ m: 0, '& .MuiFormControlLabel-label': { fontSize: '1.2rem' } }}
                        control={
                          <Checkbox
                            size="small"
                            value={v.value}
                            checked={v.value === bannerConfig.copyStyle}
                            icon={<SquareIcon2 style={{ width: '1.4rem', height: '1.4rem' }} />}
                            checkedIcon={
                              <CheckedSquareIcon3 sx={{ width: '1.4rem', height: '1.4rem' }} />
                            }
                            onChange={e => {
                              setBannerConfig(p => ({ ...p, copyStyle: e.target.value }))
                            }}
                            sx={{
                              p: '0 0.4rem 0 0',
                            }}
                          />
                        }
                        label={v.source}
                      />
                    )
                  })}
                </OpacityMotionDiv>
              )}

              {bannerConfig.copyStyle === 'price' && (
                <OpacityMotionDiv
                  initialProps={{ y: -30 }}
                  animateProps={{ y: -8 }}
                  style={{
                    display: 'flex',
                    backgroundColor: '#F2F6FF',
                    padding: '2.4rem 0 1.6rem 0',
                    justifyContent: 'center',
                    width: '32rem',
                    flexDirection: 'column',
                    borderRadius: '0 0 10px 10px',
                    gap: '0.8rem',
                  }}
                >
                  <CenterAlignBox sx={{ gap: '1.2rem', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '1.4rem' }}>
                      {' '}
                      {t('banner_config.prev_price')}
                    </Typography>
                    <CustomOutlinedInput
                      inputProps={{
                        style: {},
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      value={bannerConfig.prevPrice}
                      placeholder={t('banner_config.price_ex')}
                      onChange={e => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault()
                        }
                        setBannerConfig(prev => ({ ...prev, prevPrice: e.target.value }))
                      }}
                      sx={{
                        backgroundColor: theme.palette.common.white,
                        width: '23.6rem',
                        height: '2.8rem',
                        '& input': { textAlign: 'start', fontSize: '1.2rem' },

                        '&.MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderWidth: '0.1rem',
                            borderColor: bannerConfig.prevPrice
                              ? theme.palette.common.black
                              : '#D9E1EC',
                          },
                        },
                      }}
                    />
                  </CenterAlignBox>
                  <CenterAlignBox sx={{ gap: '1.2rem', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '1.4rem' }}>
                      {' '}
                      {t('banner_config.next_price')}
                    </Typography>{' '}
                    <CustomOutlinedInput
                      value={bannerConfig.postPrice}
                      inputProps={{
                        style: {},
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      placeholder={t('banner_config.price_ex')}
                      onChange={e => {
                        setBannerConfig(prev => ({ ...prev, postPrice: e.target.value }))
                      }}
                      sx={{
                        backgroundColor: theme.palette.common.white,
                        width: '23.6rem',
                        height: '2.8rem',
                        '& input': { textAlign: 'start', fontSize: '1.2rem' },

                        '&.MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderWidth: '0.1rem',
                            borderColor: bannerConfig.postPrice
                              ? theme.palette.common.black
                              : '#D9E1EC',
                          },
                        },
                      }}
                    />
                  </CenterAlignBox>
                </OpacityMotionDiv>
              )}
            </CenterAlignStack>
            // <CenterAlignStack sx={{ width: '37.8rem' }}>
            //   <CenterAlignStack>
            //     <Typography sx={{ fontWeight: 800, lineHeight: 'normal', mb: '0.2rem' }}>
            //       {t('banner_config.detail_title')}
            //     </Typography>
            //     <Typography sx={{ ...bannerStyle.templateSubtitle, mt: '3.2rem' }}>
            //       {t('banner_config.template_subtitle_1')}
            //     </Typography>
            //     <CenterAlignBox sx={{ gap: '1.2rem' }}>
            //       {isProduct.map((p, i) => {
            //         return (
            //           <StyledToggleButton
            //             key={p.value}
            //             value={p.value}
            //             selected={hasProduct === p.value}
            //             onChange={addClickHandlerProduct(p.value)}
            //             sx={{
            //               minWidth: '12rem',
            //               fontSize: isKo(i18n) ? '1.4rem' : '1.3rem',
            //               p: 0,
            //               border: `2px solid`,
            //               borderColor: `transparent`,
            //               fontWeight: 600,
            //               color: theme.palette.common.black,

            //               '&.Mui-selected': {
            //                 boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
            //                 color: theme.palette.draph.newBlue,
            //                 background: 'none',
            //                 borderColor: theme.palette.draph.newBlue,
            //                 outline: 'none',
            //                 fontWeight: 800,
            //               },
            //             }}
            //           >
            //             {p.source}
            //           </StyledToggleButton>
            //         )
            //       })}
            //     </CenterAlignBox>

            //     <Typography sx={{ ...bannerStyle.templateSubtitle, mt: '2.8rem' }}>
            //       {t('banner_config.template_subtitle_2')}
            //     </Typography>
            //     {hasProduct && (
            //       <OpacityMotionDiv initialProps={{ y: -15 }} animateProps={{ y: 0 }}>
            //         <CenterAlignBox sx={{ gap: '1.2rem' }}>
            //           {model.map((p, i) => {
            //             return (
            //               <StyledToggleButton
            //                 key={p.value}
            //                 value={p.value}
            //                 selected={bannerConfig.genType === p.value}
            //                 onChange={addClickHandlerModel(p.value)}
            //                 sx={{
            //                   minWidth: '10rem',
            //                   height: isKo(i18n) ? '3.4rem' : 'auto',
            //                   fontSize: '1.4rem',
            //                   border: `2px solid`,
            //                   borderColor: `transparent`,
            //                   fontWeight: 600,
            //                   color: theme.palette.common.black,
            //                   p: isKo(i18n) ? '0 1.8rem' : '0.3rem 1.3rem',
            //                   '&.Mui-selected': {
            //                     boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
            //                     color: theme.palette.draph.newBlue,
            //                     background: 'none',
            //                     borderColor: theme.palette.draph.newBlue,
            //                     outline: 'none',
            //                     fontWeight: 800,
            //                   },
            //                 }}
            //               >
            //                 {p.source}
            //               </StyledToggleButton>
            //             )
            //           })}
            //         </CenterAlignBox>
            //       </OpacityMotionDiv>
            //     )}
            //     {!hasProduct && (
            //       <OpacityMotionDiv initialProps={{ y: -15 }} animateProps={{ y: 0 }}>
            //         <CenterAlignBox sx={{ gap: '1.2rem' }}>
            //           {noSOHumanDepict.map((p, i) => {
            //             return (
            //               <StyledToggleButton
            //                 key={p.value}
            //                 value={p.value}
            //                 selected={isnoSOHumanDepict === p.value}
            //                 onChange={addClickHandlernoSOHumanDepict(p.value)}
            //                 sx={{
            //                   minWidth: '12rem',
            //                   height: '3.4rem',
            //                   fontSize: isKo(i18n) ? '1.4rem' : '1.3rem',
            //                   border: `2px solid`,
            //                   borderColor: `transparent`,
            //                   fontWeight: 600,
            //                   color: theme.palette.common.black,

            //                   '&.Mui-selected': {
            //                     boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
            //                     color: theme.palette.draph.newBlue,
            //                     background: 'none',
            //                     borderColor: theme.palette.draph.newBlue,
            //                     outline: 'none',
            //                     fontWeight: 800,
            //                   },
            //                 }}
            //               >
            //                 {p.source}
            //               </StyledToggleButton>
            //             )
            //           })}
            //         </CenterAlignBox>
            //       </OpacityMotionDiv>
            //     )}

            //     {<BannerPrompt hasProduct={hasProduct} isnoSOHumanDepict={isnoSOHumanDepict} />}
            //   </CenterAlignStack>
            // </CenterAlignStack>
          )}
        </CenterAlignBox>

        <Box sx={{ display: 'flex', width: '100%' }}>
          <CreateButton
            onClick={prevHandler}
            disabled={minorPage === 0}
            sx={{
              borderRadius: '0 0 0 10px',

              background: '#BBBBBB',
              '&.Mui-disabled': {
                background: '#BBBBBB',
              },
              '&:hover': {
                background: '#BBBBBB',
              },
              transition: 'transform 0.3s ease-in-out', // transition 추가
              '&:hover .content': {
                transform: 'scale(1.1)', // hover 시 글자와 아이콘 크기 증가
              },
            }}
          >
            <Box
              className="content"
              sx={{
                display: 'flex',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out', // transition 추가
              }}
            >
              {' '}
              <NextIcon sx={{ transform: 'rotate(180deg)' }} /> &nbsp;&nbsp;
              {t('banner_config.prev')}
            </Box>
          </CreateButton>
          <CreateButton
            onClick={nextHandler}
            disabled={disabledButton}
            sx={{ borderRadius: '0 0 10px 0' }}
          >
            <Box
              className="content"
              sx={{
                display: 'flex',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out', // transition 추가
              }}
            >
              {' '}
              {t('banner_config.next')}&nbsp;&nbsp;
              <NextIcon />
            </Box>
          </CreateButton>
        </Box>
      </CenterAlignStack>
    </>
  )
}

const ImageOverlay = ({ topImageSrc, bottomImageSrc }) => {
  return (
    <Box sx={{ width: '29.2rem', position: 'relative', height: '25rem' }}>
      <img
        src={topImageSrc}
        style={{ width: '16.6rem', height: '16.6rem', objectFit: 'cover', borderRadius: '10px' }}
      />
      <img
        src={bottomImageSrc}
        style={{
          width: '16.6rem',
          height: '16.6rem',
          objectFit: 'cover',
          position: 'absolute',
          bottom: 0,
          right: 0,
          zIndex: 1,
          boxShadow: '-4px -4px 10px 0px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
        }}
      />
    </Box>
  )
}
export function BannerInfo2({ uploadButton, setMinorPage }) {
  const { t, i18n } = useTranslation()
  const [bannerSizeRatio, setBannerSizeRatio] = useRecoilState(bannerSizeRatioAtom)

  const hasProductGenType = ['full', 'mann2man', 'side']
  const [hasProduct, setHasProduct] = useRecoilState(hasProductAtom)
  const [isnoSOHumanDepict, setIsnoSoHumanDepict] = useRecoilState(isnoSOHumanDepictAtom)
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [majorPage, setMajorPage] = useRecoilState(bannerMajorPageAtom)

  const theme = useTheme()

  const [error, setError] = useState({
    productName: false,
    productFeature: false,
    productCategory: false,
  })

  useEffect(() => {
    if (bannerConfig.genType) {
      if (hasProductGenType.includes(bannerConfig.genType)) {
        setHasProduct(true)
      } else {
        setHasProduct(false)
      }

      if (bannerConfig.humanDepict) {
        setIsnoSoHumanDepict(true)
      } else {
        setIsnoSoHumanDepict(false)
      }
    }
  }, [bannerConfig.genType, bannerConfig.humanDepict])

  const productInfoInputs = [
    {
      id: 'productName',
      title: t('banner_config.info_input_1'),
      placeholder: t('banner_config.info_input_1_ex'),
    },
    {
      id: 'productFeature',
      title: t('banner_config.info_input_2'),
      placeholder: t('banner_config.info_input_2_ex'),
    },
    {
      id: 'productCategory',
      title: t('banner_config.info_input_3'),
      placeholder: t('banner_config.info_input_3_ex'),
    },
  ]

  // useEffect(() => {
  //   console.log(error)
  // }, [error])

  const prevHandler = () => {
    if (bannerConfig.genType === 'mann2man') {
      setMajorPage(1)
    } else {
      setMajorPage(0)
    }
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <CenterAlignBox sx={{ mb: '4.4rem' }}>
        <CenterAlignStack sx={{}}>
          <Typography sx={bannerStyle2.title}>
            {t('banner_config.info_title')}
            <sup style={{ color: theme.palette.error.main }}>&#42;</sup>
          </Typography>
          <Typography sx={bannerStyle2.subtitle}>{t('banner_config.info_subtitle')}</Typography>

          <Stack sx={{ gap: '1.6rem', mt: '1.6rem' }}>
            {productInfoInputs.map(p => {
              return (
                <Stack sx={{ gap: '0.8rem' }} key={p.id}>
                  <Typography sx={bannerStyle2.productInfoTitle}>{p.title}</Typography>
                  <CustomOutlinedInput
                    value={bannerConfig[p.id]}
                    placeholder={p.placeholder}
                    onChange={e => {
                      if (error[p.id] && e.target.value) {
                        setError(prev => ({ ...prev, [p.id]: false }))
                      }
                      setBannerConfig(prev => ({ ...prev, [p.id]: e.target.value }))
                    }}
                    sx={{
                      width: '32rem',
                      height: '3.6rem',
                      '& input': {
                        fontSize: '1.4rem',
                      },
                      '&.MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderWidth: '0.1rem',
                          borderColor: bannerConfig[p.id] ? theme.palette.common.black : '#D9E1EC',
                        },
                      },
                      ...bannerStyle.productInfoInput,
                    }}
                    onBlur={e => {
                      if (!e.target.value) {
                        setError(prev => ({ ...prev, [p.id]: true }))
                      }
                    }}
                    error={error[p.id]}
                  />
                </Stack>
              )
            })}
          </Stack>
        </CenterAlignStack>

        <Divider orientation="vertical" sx={{ mx: isKo(i18n) ? '4rem' : '1.5rem' }} />
        <BannerSize />
      </CenterAlignBox>

      <Box sx={{ width: '100%', display: 'flex' }}>
        <CreateButton
          onClick={prevHandler}
          sx={{
            borderRadius: '0 0 0 10px',

            background: '#BBBBBB',
            '&.Mui-disabled': {
              background: '#BBBBBB',
            },
            '&:hover': {
              background: '#BBBBBB',
            },
            transition: 'transform 0.3s ease-in-out', // transition 추가
            '&:hover .content': {
              transform: 'scale(1.1)', // hover 시 글자와 아이콘 크기 증가
            },
          }}
        >
          <Box
            className="content"
            sx={{
              display: 'flex',
              alignItems: 'center',
              transition: 'transform 0.3s ease-in-out', // transition 추가
            }}
          >
            {' '}
            <NextIcon sx={{ transform: 'rotate(180deg)' }} /> &nbsp;&nbsp;
            {t('banner_config.prev')}
          </Box>
        </CreateButton>
        {uploadButton}
      </Box>
    </Stack>
  )
}

const BannerMannequin = ({
  setMajorPage,
  setMinorPage,
  concept,
  setConcept,
  gender,
  setGender,
  age,
  setAge,
}) => {
  const theme = useTheme()
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const isMobile = useMobileMediaQuery()

  const { handleRedo, handleReset, handleUndo } = useFragment()

  const maskImgArray = useRecoilValue(maskImgArrayAtom)
  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [index, SetIndex] = useRecoilState(indexAtom)
  const [bannerMannInfo, setBannerMannInfo] = useRecoilState(bannerMannInfoAtom)

  const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)
  const resetRemoveMaskImg = useResetRecoilState(removeMaskImgAtom)
  const resetSegmentLoading = useResetRecoilState(segmentLoadingAtom)
  const resetIndex = useResetRecoilState(indexAtom)
  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const [uploading, setUploading] = useState(false)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)

  const [race, setRace] = useState('asian')

  const genderList = [
    { label: t('button.female'), value: 'female' },
    { label: t('button.male'), value: 'male' },
  ]

  const ageList = [
    { label: t('button.baby'), value: 'baby' },
    { label: t('button.child'), value: 'child' },
    { label: t('button.adult'), value: 'adult' },
  ]

  const [isFocused, setIsFocused] = useState(false)

  const onFocus = () => {
    setIsFocused(true)
  }
  const onBlur = () => {
    setIsFocused(false)
  }

  const onChange = e => {
    setConcept(e.target.value)
  }

  const raceList = [{ id: 'asian' }, { id: 'black' }, { id: 'caucasian' }, { id: 'hispanic' }]

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()
  const { showConfirm } = useConfirm()

  const {
    refreshArtworks,
    checkUserCredit,
    prepareUpload,
    makeUploadFormData,
    updatePortfolioDetail,
  } = usePortfolio()

  const nextHandler = async () => {
    const mask = await imgMergeAndGrayScale(selectedMaskImg)
    const rleData = await blobToRLE(mask)

    const mannInfo = {
      mann2man_image: files[0].file,
      binary_mask: mask,
      mann2man_is_male: gender === 'male',
      mann2man_age: age,
      user_presets: JSON.stringify([rleData]),
      user_binmaps: JSON.stringify(maskImgArray),
      human_depict: concept,
      gen_type: 'mann2man',
      bg_Depict: '',
    }

    setBannerMannInfo(p => ({ ...p, ...mannInfo }))
    setMajorPage(2)
  }

  const prevHandler = () => {
    setMajorPage(0)
    setMinorPage(1)
  }

  const disabledButton = !selectedMaskImg.length || !concept.length

  return (
    <Stack sx={{ width: 'inherit' }}>
      <Box>
        <Box
          sx={{
            p: '0 4rem 4.4rem 4rem',
            display: 'flex',
            width: '100%',
          }}
        >
          <CenterAlignStack sx={{ mr: '4rem' }}>
            <Stack sx={{ alignItems: 'center', gap: '0.2rem' }}>
              <Typography sx={{ fontWeight: 800, lineHeight: 'normal' }}>
                {t('upload_header.model_bg_title')}{' '}
                <sup
                  style={{
                    color: theme.palette.error.main,
                    fontWeight: 500,
                    fontSize: '1.5rem',
                    lineHeight: '1.5rem',
                    verticalAlign: 'text-top',
                  }}
                >
                  &#42;
                </sup>
              </Typography>
              <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
                {t('upload_header.model_notice_1')}
              </Typography>
            </Stack>
            <CenterAlignStack
              className="model"
              sx={{
                width: '100%',
                position: 'relative',
                alignItems: 'start',
                gap: { lg: '3.3rem', xs: '2rem' },
                flexDirection: { lg: 'column', xs: 'row' },
                mt: { lg: '2.8rem', xs: '1.8rem' },
              }}
            >
              <Stack
                sx={{
                  gap: { lg: '0.8rem', xs: '0.6rem' },
                  justifyContent: { lg: 'start', xs: 'center' },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    textAlign: { lg: 'start', xs: 'center' },
                  }}
                >
                  {t('mannequin.gender')}
                </Typography>
                <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
                  {genderList.map((g, i) => {
                    return (
                      <StyledToggleButton
                        key={g.value}
                        value={g.value}
                        selected={gender === g.value}
                        onChange={e => {
                          setGender(e.target.value)
                        }}
                        sx={{
                          minWidth: { lg: '8rem', xs: '5.2rem' },
                          height: '3.2rem',
                          fontSize: '1.4rem',

                          border: `2px solid`,
                          borderColor: `transparent`,
                          fontWeight: 600,
                          color: theme.palette.common.black,

                          '&.Mui-selected': {
                            boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                            color: theme.palette.draph.blue,
                            background: 'none',
                            borderColor: theme.palette.draph.blue,
                            outline: 'none',
                            fontWeight: 800,
                          },
                          ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                        }}
                      >
                        {g.label}
                      </StyledToggleButton>
                    )
                  })}
                </Box>
              </Stack>

              <Stack sx={{ gap: '0.8rem' }}>
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    textAlign: { lg: 'start', xs: 'center' },
                  }}
                >
                  {t('mannequin.age')}
                </Typography>
                <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
                  {ageList.map((a, i) => {
                    return (
                      <StyledToggleButton
                        key={a.value}
                        value={a.value}
                        selected={age === a.value}
                        onChange={e => {
                          setAge(e.target.value)
                        }}
                        sx={{
                          minWidth: { lg: '8rem', xs: '5.2rem' },
                          height: '3.2rem',
                          fontSize: '1.4rem',

                          border: `2px solid`,
                          borderColor: `transparent`,
                          fontWeight: 600,
                          color: theme.palette.common.black,

                          '&.Mui-selected': {
                            boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                            color: theme.palette.draph.blue,
                            background: 'none',
                            borderColor: theme.palette.draph.blue,
                            outline: 'none',
                            fontWeight: 800,
                          },
                          ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                        }}
                      >
                        {a.label}
                      </StyledToggleButton>
                    )
                  })}
                </Box>
              </Stack>
            </CenterAlignStack>
            <CenterAlignStack
              className="theme"
              sx={{
                width: '100%',
                position: 'relative',
                alignItems: 'start',
                gap: '1.6rem',
                mt: { lg: '3.6rem', xs: '2rem' },
              }}
            >
              <Stack sx={{ gap: '0.8rem', width: '100%' }}>
                <Stack sx={{ gap: '0.2rem' }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '1.4rem',
                      lineHeight: 'normal',
                      textAlign: { lg: 'start', xs: 'center' },
                    }}
                  >
                    {t('mannequin.model_depict')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1.4rem',
                      lineHeight: '1.2',
                      textAlign: { lg: 'start', xs: 'center' },
                    }}
                  >
                    <Korean>
                      <SpanDraphBlue style={{ fontWeight: 600 }}>
                        옷(상품)을 착용한 AI모델
                      </SpanDraphBlue>
                      의 모습을 묘사해 주세요.
                    </Korean>
                  </Typography>
                </Stack>

                <CustomOutlinedInput
                  value={concept}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  type="text"
                  inputProps={{ maxLength: 1000 }}
                  multiline
                  minRows={6}
                  startAdornment={
                    <div style={{ position: 'absolute', top: '2.2rem', left: '1.2rem' }}>
                      {!isFocused && !concept.length && (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              backgroundColor: theme.palette.common.black,
                              color: theme.palette.common.white,
                              p: '0.3rem 0.6rem',
                              fontSize: '1.2rem',
                              fontWeight: 600,
                              borderRadius: '6px',
                              lineHeight: 'normal',
                            }}
                          >
                            {t('upload_header.custom_template')}
                          </Box>
                        </InputAdornment>
                      )}
                    </div>
                  }
                  sx={{
                    height: 'auto',
                    lineHeight: '1.6',
                    width: '34.8rem',
                    p: '1.05rem 1.4rem',
                    '&.MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderWidth: '0.1rem',
                        borderColor: '#000000',
                        borderRadius: '0px',
                      },
                    },
                  }}
                />
              </Stack>
            </CenterAlignStack>
          </CenterAlignStack>
          <CenterAlignStack sx={{}}>
            <Stack sx={{ alignItems: 'center', gap: '0.2rem' }}>
              <Typography sx={{ fontWeight: 800, lineHeight: 'normal' }}>
                {t('mannequin.step_1_title')}{' '}
                <sup
                  style={{
                    color: theme.palette.error.main,
                    fontWeight: 500,
                    fontSize: '1.5rem',
                    lineHeight: '1.5rem',
                    verticalAlign: 'text-top',
                  }}
                >
                  &#42;
                </sup>
              </Typography>
              <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
                {t('mannequin.step_1_comment')}
              </Typography>
            </Stack>
            <CenterAlignStack
              sx={{
                width: '40rem',
                height: '34.4rem',
                backgroundColor: '#D9D9D9',
                justifyContent: 'center',
                mt: '1.8rem',
              }}
            >
              <Segment widthLimit={400} heightLimit={344} />
            </CenterAlignStack>
            <CenterAlignBox sx={{ gap: '1.2rem', mt: '1.6rem' }}>
              <Button
                variant="outlined"
                sx={{ ...roundButtonStyle, width: '9.2rem', height: '2.8rem' }}
                onClick={handleReset}
                disabled={segmentLoading}
              >
                <EraserIcon />
                {t('regenerate_dialog.bg_edit_reset')}
              </Button>

              <Button
                variant="outlined"
                sx={{ ...roundButtonStyle, width: '9.2rem', height: '2.8rem' }}
                onClick={handleUndo}
                disabled={index === -1 || segmentLoading}
              >
                <svg
                  width="1rem"
                  height="1rem"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 11L1 6L6 1"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 6H11.5C12.2223 6 12.9375 6.14226 13.6048 6.41866C14.272 6.69506 14.8784 7.10019 15.3891 7.61091C15.8998 8.12163 16.3049 8.72795 16.5813 9.39524C16.8577 10.0625 17 10.7777 17 11.5C17 12.2223 16.8577 12.9375 16.5813 13.6048C16.3049 14.272 15.8998 14.8784 15.3891 15.3891C14.8784 15.8998 14.272 16.3049 13.6048 16.5813C12.9375 16.8577 12.2223 17 11.5 17H8"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('regenerate_dialog.bg_edit_undo')}
              </Button>
              <Button
                variant="outlined"
                sx={{ ...roundButtonStyle, width: '9.2rem', height: '2.8rem' }}
                onClick={handleRedo}
                disabled={removeMaskImg.length === 0 || segmentLoading}
              >
                <svg
                  width="1rem"
                  height="1rem"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 11L17 6L12 1"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 6H6.5C5.04131 6 3.64236 6.57946 2.61091 7.61091C1.57946 8.64236 1 10.0413 1 11.5C1 12.2223 1.14226 12.9375 1.41866 13.6048C1.69506 14.272 2.10019 14.8784 2.61091 15.3891C3.64236 16.4205 5.04131 17 6.5 17H10"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('regenerate_dialog.bg_edit_redo')}
              </Button>
            </CenterAlignBox>
          </CenterAlignStack>
        </Box>

        <Box sx={{ display: 'flex', width: '100%' }}>
          <CreateButton
            onClick={prevHandler}
            sx={{
              borderRadius: '0 0 0 10px',

              background: '#BBBBBB',
              '&.Mui-disabled': {
                background: '#BBBBBB',
              },
              '&:hover': {
                background: '#BBBBBB',
              },
              transition: 'transform 0.3s ease-in-out', // transition 추가
              '&:hover .content': {
                transform: 'scale(1.1)', // hover 시 글자와 아이콘 크기 증가
              },
            }}
          >
            <Box
              className="content"
              sx={{
                display: 'flex',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out', // transition 추가
              }}
            >
              {' '}
              <NextIcon sx={{ transform: 'rotate(180deg)' }} /> &nbsp;&nbsp;
              {t('banner_config.prev')}
            </Box>
          </CreateButton>
          <CreateButton
            onClick={nextHandler}
            disabled={disabledButton}
            sx={{ borderRadius: '0 0 10px 0' }}
          >
            <Box
              className="content"
              sx={{
                display: 'flex',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out', // transition 추가
              }}
            >
              {' '}
              {t('banner_config.next')}&nbsp;&nbsp;
              <NextIcon />
            </Box>
          </CreateButton>
        </Box>
      </Box>
    </Stack>
  )
}
