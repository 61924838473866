import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom'
// material
import { styled, useTheme } from '@mui/material/styles'

import { CenterAlignBox, CenterAlignStack, TempLayoutDiv } from 'components'
import { Box, Card, Divider, Stack, Typography } from '@mui/material'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { userCreditInfoAtom } from 'atoms'
import { APPBAR_DESKTOP } from './main/MainAppBar'
import { useTranslation } from 'react-i18next'
import { isKo } from 'utils/common'
import { Korean } from 'hooks/useLanguage'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: 0,
  height: 0,
  position: 'relative',
}))

export const MypagesMenu = styled(Card)(({ theme }) => ({
  position: 'sticky',
  display: 'flex',
  flexDirection: 'column',
  width: '20rem',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',

  top: '17.78rem',
  left: '0rem',
}))
export const MypagesRouterLink = styled(RouterLink)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  fontWeight: 500,
  fontSize: '2rem',

  '&:hover': {
    backgroundColor: 'unset',
    opacity: 0.8,
  },
}))

const titleStyle = {
  fontWeight: 700,
  fontSize: '2.4rem',
  lineHeight: '2.9rem',
}

const dividerStyle = {
  my: '1.8rem',
  border: '0.5px solid #808080',
}

// ----------------------------------------------------------------------

export default function MypagesSideBar() {
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [userCreditInfo, setUserCreditInfo] = useRecoilState(userCreditInfoAtom)

  const styledList = { paddingBottom: '2rem', fontWeight: 500, fontSize: '18px' }

  return (
    <>
      <Desktop>
        <MypagesMenu sx={{ height: 'fit-content', minWidth: '20rem' }}>
          <Stack sx={{ margin: '4.4rem 3.2rem', height: '100%' }}>
            <Typography sx={{ mb: '1.6rem', ...titleStyle }}>
              {t('mypage_menu.credit_and_subscriptions_short')}
            </Typography>
            {/* <Typography sx={{ mb: '1.6rem', ...titleStyle }}>{t('mypage_menu.credit')}</Typography> */}

            <MypagesRouterLink
              to="/user/subscriptions"
              onClick={() => {}}
              sx={{
                fontSize: isKo(i18n) ? '2rem' : '1.68rem',
                mb: '0.5rem',
                color:
                  pathname === '/user/subscriptions' && userCreditInfo === 0
                    ? theme.palette.draph.blue
                    : theme.palette.common.black,
              }}
            >
              - {t('mypage_menu.subscription')}
            </MypagesRouterLink>
            <MypagesRouterLink
              to="/user/creditInfo"
              onClick={() => {
                setUserCreditInfo(0)
              }}
              sx={{
                fontSize: isKo(i18n) ? '2rem' : '1.68rem',
                mb: '0.5rem',
                color:
                  pathname === '/user/creditInfo' && userCreditInfo === 0
                    ? theme.palette.draph.blue
                    : theme.palette.common.black,
              }}
            >
              - {t('mypage_menu.charged_credit')}
            </MypagesRouterLink>
            <MypagesRouterLink
              to="/user/creditInfo"
              onClick={() => {
                setUserCreditInfo(1)
              }}
              sx={{
                fontSize: isKo(i18n) ? '2rem' : '1.68rem',
                mb: '0.5rem',
                color:
                  pathname === '/user/creditInfo' && userCreditInfo === 1
                    ? theme.palette.draph.blue
                    : theme.palette.common.black,
              }}
            >
              - {t('mypage_menu.credit_history')}
            </MypagesRouterLink>

            <Korean>
              <MypagesRouterLink
                to="/user/bespoke_history"
                sx={{
                  fontSize: isKo(i18n) ? '2rem' : '1.68rem',
                  mb: 0,
                  color:
                    pathname === '/user/bespoke_history'
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                }}
              >
                - {t('mypage_menu.bespoke_history')}
              </MypagesRouterLink>
            </Korean>

            {/* 
            <MypagesRouterLink
              to="/user/mypage"
              style={{ marginBottom: 0, ...titleStyle }}
              sx={{
                color:
                  pathname === '/user/mypage'
                    ? theme.palette.draph.blue
                    : theme.palette.common.black,
              }}
            >
              {t('mypage_menu.mypage')}
            </MypagesRouterLink> */}
            {/* 
            <Divider flexItem sx={{ ...dividerStyle }} />
            <Typography sx={{ mb: '1.6rem', ...titleStyle }}>
              {t('mypage_menu.my_image')}
            </Typography> */}

            {/* <MypagesRouterLink
              to="/user/background"
              sx={{
                fontSize: isKo(i18n) ? '2rem' : '1.68rem',
                mb: '0.5rem',
                color:
                  pathname === '/user/background'
                    ? theme.palette.draph.blue
                    : theme.palette.common.black,
              }}
            >
              - {t('mypage_menu.background')}
            </MypagesRouterLink> */}

            <Divider flexItem sx={{ ...dividerStyle }} />

            <MypagesRouterLink
              to="/user/coupon"
              style={{ marginBottom: 0, ...titleStyle }}
              sx={{
                color:
                  pathname === '/user/coupon'
                    ? theme.palette.draph.blue
                    : theme.palette.common.black,
              }}
            >
              {t('mypage_menu.coupon')}
            </MypagesRouterLink>

            <Divider flexItem sx={{ ...dividerStyle }} />

            <MypagesRouterLink
              to="/user/profile"
              sx={{
                ...titleStyle,
                color:
                  pathname === '/user/profile'
                    ? theme.palette.draph.blue
                    : theme.palette.common.black,
              }}
            >
              {t('mypage_menu.user_info_change')}
            </MypagesRouterLink>
          </Stack>
        </MypagesMenu>
      </Desktop>
      {/* <Mobile>
        <CenterAlignBox sx={{ width: '100%', mt: '3.8rem', gap: '5.3rem' }}>
          <Typography sx={{ ...styledList, fontWeight: pathname === '/board' ? 800 : 500 }}>
            <RouterLink to="/board" style={{ color: 'inherit', textDecoration: 'none' }}>
              공지사항
            </RouterLink>
          </Typography>
          <Typography sx={{ ...styledList, fontWeight: pathname === '/board' ? 800 : 500 }}>
            <RouterLink to="/board" style={{ color: 'inherit', textDecoration: 'none' }}>
              드랩 가이드
            </RouterLink>
          </Typography>
          <Typography sx={{ ...styledList, fontWeight: pathname === '/FAQ' ? 800 : 500 }}>
            <RouterLink to="/FAQ" style={{ color: 'inherit', textDecoration: 'none' }}>
              FAQ
            </RouterLink>
          </Typography>
        </CenterAlignBox>
        <Divider sx={{ mt: '0.4rem', backgroundColor: 'black' }} />
      </Mobile> */}
    </>
  )
}

// <CenterAlignStack sx={{ display: 'flex', py: '14.6rem' }}>
// <ul>
//   <li style={{ ...styledList, fontWeight: pathname === '/board' ? 800 : 500 }}>
//     <RouterLink to="/board" style={{ color: 'inherit', textDecoration: 'none' }}>
//       공지사항
//     </RouterLink>
//   </li>
//   {/* 드랩가이드는 아직 개설 전 */}
//   <li style={{ ...styledList, fontWeight: pathname === '/board' ? 800 : 500 }}>
//     <RouterLink to="/board" style={{ color: 'inherit', textDecoration: 'none' }}>
//       드랩 가이드
//     </RouterLink>
//   </li>
//   <li style={{ ...styledList, fontWeight: pathname === '/FAQ' ? 800 : 500 }}>
//     <RouterLink to="/FAQ" style={{ color: 'inherit', textDecoration: 'none' }}>
//       FAQ
//     </RouterLink>
//   </li>
// </ul>
// </CenterAlignStack>
