// Convert the onnx model mask prediction to ImageData

export function imageData2DToImage(input, mode = 'add') {
  if (!input) return null

  // const [r, g, b] = [0, 232, 185] // the masks's blue color
  const [r, g, b] = mode === 'add' ? [0, 232, 185] : [255, 35, 35] // the masks's blue color
  const width = input[0].length
  const height = input.length

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  canvas.width = width
  canvas.height = height

  const imageData4Channel = context.createImageData(width, height)
  const data = imageData4Channel.data

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4
      const value = input[y][x]

      // 투명도 설정
      data[index] = r // Red 채널
      data[index + 1] = g // Green 채널
      data[index + 2] = b // Blue 채널
      data[index + 3] = value === 1 ? 255 : 0 // Alpha 채널
      // data[index + 3] = 255 // Alpha 채널
    }
  }

  context.putImageData(imageData4Channel, 0, 0)

  const newImage = new Image()
  newImage.src = canvas.toDataURL()

  return newImage
}

// Helper function for handling image scaling needed for SAM
const handleImageScale = image => {
  // Input images to SAM must be resized so the longest side is 1024
  const LONG_SIDE_LENGTH = 1024
  const w = image.naturalWidth
  const h = image.naturalHeight
  const samScale = LONG_SIDE_LENGTH / Math.max(h, w)
  return { height: h, width: w, samScale }
}

function compareImages(imageData1, imageData2) {
  return new Promise((resolve, reject) => {
    const img1 = new Image()
    img1.src = imageData1
    const img2 = new Image()
    img2.src = imageData2

    img1.onload = () => {
      img2.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img1.width
        canvas.height = img1.height
        const ctx = canvas.getContext('2d')
        ctx.willReadFrequently = true

        // 두 이미지를 캔버스에 그립니다.
        ctx.drawImage(img1, 0, 0, img1.width, img1.height)
        const data1 = ctx.getImageData(0, 0, img1.width, img1.height).data

        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img2, 0, 0, img2.width, img2.height)
        const data2 = ctx.getImageData(0, 0, img2.width, img2.height).data

        // 이미지 유사도를 계산 (평균 제곱 오차)
        let mse = 0
        let overlapPixels = 0

        for (let i = 0; i < data1.length; i += 4) {
          const diffR = data1[i] - data2[i]
          const diffG = data1[i + 1] - data2[i + 1]
          const diffB = data1[i + 2] - data2[i + 2]
          mse += diffR * diffR + diffG * diffG + diffB * diffB

          if (data1[i] !== 0 || data1[i + 1] !== 0 || data1[i + 2] !== 0) {
            overlapPixels++
          }
        }
        mse /= img1.width * img1.height

        // 유사도 계산 (낮을수록 유사)
        const similarity = 1 - mse / 255

        const overlapPercentage = (overlapPixels / (img1.width * img1.height)) * 100
        const overlapInfo = overlapPercentage

        resolve({ similarity, overlapInfo })

        // resolve(similarity)
      }
    }
  })
}

function compareImagesForNukki(imageData1, imageData2) {
  return new Promise((resolve, reject) => {
    const img1 = new Image()
    img1.src = imageData1
    const img2 = new Image()
    img2.src = imageData2

    img1.onload = () => {
      img2.onload = () => {
        const { Image } = require('image-js')

        const canvas = document.createElement('canvas')
        canvas.width = img1.width
        canvas.height = img1.height
        const ctx = canvas.getContext('2d')
        ctx.willReadFrequently = true

        // 두 이미지를 캔버스에 그립니다.
        ctx.drawImage(img1, 0, 0, img1.width, img1.height)
        const data1 = ctx.getImageData(0, 0, img1.width, img1.height).data
        dataMask(data1)
        const data1Image = new Image(img1.width, img1.height, data1)
        const greyedImg1 = data1Image.grey({ algorithm: 'maximum' })

        ctx.clearRect(0, 0, canvas.width, canvas.height)

        ctx.drawImage(img2, 0, 0, img2.width, img2.height)
        const data2 = ctx.getImageData(0, 0, img2.width, img2.height).data
        dataMask(data2)
        const data2Image = new Image(img1.width, img1.height, data2)
        const greyedImg2 = data2Image.grey({ algorithm: 'maximum' })

        // console.log(data1Image.toDataURL())
        // console.log(greyedImg2)

        // console.log(data1Image.toDataURL())
        // console.log(data2Image.toDataURL())
        // console.log(greyedImg1.toDataURL())
        // console.log(greyedImg2.toDataURL())

        // console.log(mask1.toDataURL(), mask2.toDataURL())
        // console.log(mask1.getIntersection(mask2))
        // console.log(greyedImg1.getSimilarity(greyedImg2))
        // console.log(greyedImg1.getSimilarity(greyedImg2))
        const isSimilarity = Math.floor(greyedImg1.getSimilarity(greyedImg2) * 10) / 10
        // 이미지 유사도를 계산 (평균 제곱 오차)

        resolve({ isSimilarity })
      }
    }
  })
}

function selectedMaskImageByposition(inputArray, h, w) {
  let result
  for (const object of inputArray) {
    // const decodeImageResult = decodeCocoRLE(object.size, object.counts)
    // const decodeImageResult = decodeMask2RLE(object.counts, object.size[1], object.size[0])
    const searchPositionBoolen = searchPositionValue(object.counts, object.size, h, w)
    if (searchPositionBoolen) {
      result = decodeMask2RLE(object.counts, object.size)
      break
    }
  }

  if (result) {
    return result
  } else {
    return null
  }
}
export function decodeMask2RLE(mask2RLE, [height, width]) {
  // 2차원 행렬 초기화
  const parts = mask2RLE

  const matrix = Array.from({ length: height }, () => Array(width).fill(0))
  let pixelPosition = 0
  // Mask2RLE 데이터 파싱

  for (let i = 0; i < parts.length; i++) {
    const length = parseInt(parts[i])

    // 시작 위치를 x, y 좌표로 변환
    let x = pixelPosition % height
    let y = Math.floor(pixelPosition / height)

    // 해당 영역 채우기

    for (let j = 0; j < length; j++) {
      if (i % 2 === 1) {
        matrix[x][y] = 1
        x++
        if (x === height) {
          x = 0
          y++
        }
      } else {
        matrix[x][y] = 0
        x++

        if (x === height) {
          x = 0
          y++
        }
      }
    }
    pixelPosition += length
  }

  return matrix
}

function searchPositionValue(counts, [imgH, imgW], searchH, searchW) {
  const searchPosition = (searchW - 1) * imgH + searchH // 0 시작 ~
  let accCounts = 0
  let index = 0

  while (searchPosition > accCounts) {
    accCounts += counts[index]
    index += 1
  }

  if (index % 2 === 0) {
    return true
  } else {
    return false
  }
}

export { handleImageScale, compareImages, selectedMaskImageByposition, compareImagesForNukki }

// Use a Canvas element to produce an image from ImageData
function imageDataToImage(imageData) {
  if (!imageData) return null

  const canvas = imageDataToCanvas(imageData)
  const image = new Image()
  image.src = canvas.toDataURL()
  return image
}

// Canvas elements can be created from ImageData
function imageDataToCanvas(imageData) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  canvas.width = imageData.width
  canvas.height = imageData.height
  ctx?.putImageData(imageData, 0, 0)
  return canvas
}

// Convert the onnx model mask output to an HTMLImageElement
export function onnxMaskToImage(input, width, height) {
  return imageDataToImage(arrayToImageData(input, width, height))
}

function arrayToImageData(input, width, height) {
  if (!input) return null
  const [r, g, b, a] = [0, 232, 185, 255] // the masks's blue color
  const arr = new Uint8ClampedArray(4 * width * height).fill(0)
  for (let i = 0; i < input.length; i++) {
    // Threshold the onnx model mask prediction at 0.0
    // This is equivalent to thresholding the mask using predictor.model.mask_threshold
    // in python
    if (input[i] > 1.0) {
      arr[4 * i + 0] = r
      arr[4 * i + 1] = g
      arr[4 * i + 2] = b
      arr[4 * i + 3] = a
    }
  }
  return new ImageData(arr, height, width)
}

const dataMask = data => {
  for (let i = 0; i < data.length; i += 4) {
    const alpha = data[i + 3]

    if (alpha !== 0) {
      data[i] = 255 // Red
      data[i + 1] = 255 // Green
      data[i + 2] = 255 // Blue
      // 알파값이 특정 값인 경우 흰색으로 변경
    }
  }

  return data
}
