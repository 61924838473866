import React, { useEffect, useMemo, useState } from 'react'
import {
  TableRow,
  TableCell,
  Checkbox,
  Box,
  Typography,
  Button,
  useTheme,
  Divider,
  Stack,
  Skeleton,
} from '@mui/material'
import { getS3ImageSrc, translatePromptEnToKo } from 'utils/common'
import * as config from 'config'
import { useParams } from 'react-router-dom'
import { defaultPortfolioAtom, portfolioTypeAtom, userAtom } from 'atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useArtworkPolling } from 'hooks/useReactQuery'
import { ShapesDisplay } from './ShapesDisplay'
import BannerEditorV2 from './BannerEditorV2'
import moment from 'moment'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { fontSize } from 'pages/test/Toolbar'
import { CreateButton } from 'components/CustomStyle'
import { PuffLoader } from 'react-spinners'
import { ArtworkError } from './ArtworkRowControl'
import JSZip from 'jszip'
import { apis } from 'apis'
import axios from 'axios'
import _ from 'lodash'
import { checkedURLforNoCache } from 'utils/artwork'
import { processImages } from './ArtworkList'
import BannerArtworkControl from './BannerArtworkControl'
import { motion } from 'framer-motion'
import { English, Korean } from 'hooks/useLanguage'

const BannerArtworkRow = ({
  initArtwork,
  isChecked,
  onCheckboxChange,
  index,
  idx,
  showDialog,
  pieceImageSize,
  setPieceImageSize,
  setOpen,
  editorDialogArtwork,
  setEditorDialogArtwork,

  setOpenSingleDialog,
  setUploadImage,
}) => {
  const theme = useTheme()
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const [artwork, setArtwork] = useState(initArtwork)
  const [user, setUser] = useRecoilState(userAtom)

  const shouldPoll = useMemo(
    () => config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status),
    [artwork.status]
  )

  const { individualArtwork, error, modPieceError } = useArtworkPolling(
    portfolioId,
    initArtwork.id,
    shouldPoll
  )

  useEffect(() => {
    if (individualArtwork) {
      setArtwork(individualArtwork)
    }
  }, [individualArtwork, individualArtwork?.status, individualArtwork?.pieces])

  const onLoadPieceImage = (e, piece) => {
    setPieceImageSize(s => {
      const size = {
        ...s,
      }
      size[piece.id] = { w: e.target.naturalWidth, h: e.target.naturalHeight }
      return size
    })
  }

  const inpaintURL = useMemo(() => {
    const config = JSON.parse(artwork.config)

    const url = Array.isArray(config.shapes)
      ? config.shapes.find(s => s.config.type === 'background').config.bg_inpaint_image_url
      : ''
    return url
  }, [artwork])

  const artworkConfig = JSON.parse(artwork.config)
  const canvasSize = JSON.parse(artwork?.config)?.canvasSize

  const handleImageClick = () => {
    setOpenSingleDialog(true)
    setUploadImage(inpaintURL)
  }

  const artworkDone = config.ARTWORK_DONE_STATUS === artwork.status
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)
  const artworkLoading =
    (!artworkDone && !artworkError) || config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const allDownload = () => {
    const promises = []
    const paths = []
    const ax = axios.create()
    const artworkName = artwork.name.replace('.' + _.last(artwork.name.split('.')), '')
    const pieces = artwork.pieces
    const piecePathArray = pieces.map(p => p.path)

    piecePathArray.forEach((path, i) => {
      paths.push(path)
      if (!path) return

      promises.push(
        ax.get(checkedURLforNoCache(getS3ImageSrc(path)) + `?w=${Date.now().toString()}`, {
          responseType: 'blob',
        })
      )
    })

    const zip = new JSZip()
    const dirName = artworkName
    zip.folder(dirName)

    Promise.all(promises).then(results => {
      processImages(results, dirName, artworkName, pieces)

      checkUserCredit()

      apis.portfolio.updateArtworkDownload(portfolioId, artwork.id, { paths }).then(response => {})
    })
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }} // 아래에서 시작 (100px 아래)
      whileInView={{ opacity: 1, y: 0 }} // 원래 위치로 이동
      viewport={{ once: true, margin: '-100px' }}
      transition={{
        duration: 1, // 애니메이션 지속 시간
        ease: 'easeOut', // 자연스러운 감속 효과
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          '& .artwork-row': {
            // minHeight: '51.4rem',
            backgroundColor: '#eeeeee',
            borderRadius: '10px',
            mt: '2rem',
            mb: '6.4rem',
            p: '2.4rem',
          },

          '& .bar': {
            mt: '0.7rem',
            position: 'relative',
          },
          '& .date': {
            fontSize: '2.4rem',
            fontWeight: 800,
            lineHeight: 'normal',
            mb: '0.4rem',
          },

          '& .info': {
            fontSize: '1.4rem',
            fontWeight: 400,
            lineHeight: 'normal',
          },
          '& .artwork-name': {
            fontSize: '1.6rem',
            fontWeight: 700,
          },
          '& .artwork-img': {
            display: 'flex',
            gap: '1.5rem',
            backgroundColor: '#fff',
            p: '2.6rem 2.5rem',
            my: '2rem',
            borderRadius: '10px',
          },
        }}
      >
        <Stack className="bar">
          <Box
            sx={{
              width: '1.6rem',
              height: '1.6rem',
              backgroundColor: theme.palette.common.black,
              borderRadius: '50%',
            }}
          ></Box>
          <Box
            sx={{
              width: '0.8rem',
              flex: 1,
              borderRight: `1px solid ${theme.palette.common.black}`,
            }}
          ></Box>

          <Box
            sx={{
              bottom: '-0.7rem',
              position: 'absolute',
              width: '0.8rem',
              height: '0.7rem',
              flex: 1,
              borderRight: `1px solid ${theme.palette.common.black}`,
            }}
          ></Box>
        </Stack>
        <Stack sx={{ ml: '3.2rem', width: '123.2rem' }}>
          <Typography className="date">{moment(artwork.created).format('YYYY.MM.DD')}</Typography>
          <Typography className="info">
            <Korean>
              {moment(artwork.created).format('YYYY년 MM월 DD일')}에 생성한 이미지입니다. 이미지
              사이즈는 {canvasSize?.width}px * {canvasSize?.height}px
              {inpaintURL ? (
                <>
                  이며, 상품 사진은{' '}
                  <span
                    style={{
                      fontWeight: 700,
                      textDecoration: 'solid underline 1.5px',
                      cursor: 'pointer',
                    }}
                    onClick={handleImageClick}
                  >
                    이 이미지
                  </span>
                  를 활용하였습니다.
                </>
              ) : (
                ' 입니다'
              )}
            </Korean>
            <English>
              This image was created on {moment(artwork.created).format('ll')}. The image size is{' '}
              {canvasSize?.width}px * {canvasSize?.height}px{' '}
              {inpaintURL && (
                <>
                  and is used for{' '}
                  <span
                    style={{
                      fontWeight: 700,
                      textDecoration: 'solid underline 1.5px',
                      cursor: 'pointer',
                    }}
                    onClick={handleImageClick}
                  >
                    product photos
                  </span>
                  .
                </>
              )}
            </English>
          </Typography>

          <Stack className="artwork-row">
            <Typography className="artwork-name">{artwork.name}</Typography>
            <Box className="artwork-img" sx={{}}>
              {artworkDone || artwork.pieces?.length > 0 ? (
                <>
                  {artwork?.pieces.map((p, idx) => {
                    return (
                      <div
                        key={p.path}
                        onClick={() => {
                          if (artworkLoading) return
                          showDialog(artwork, idx, { pieceImageSize })
                        }}
                      >
                        <Box
                          component="img"
                          src={getS3ImageSrc(p.path)}
                          style={{
                            width: '20rem',
                            opacity: artworkLoading ? 0.6 : 1,
                            // height: '20rem',
                            objectFit: 'contain',
                            cursor: artworkLoading ? 'auto' : 'pointer',
                          }}
                          onLoad={e => {
                            onLoadPieceImage(e, p)
                          }}
                        />
                      </div>
                    )
                  })}
                  {artworkLoading && (
                    <Skeleton width="20rem" height="20rem" sx={{ transform: 'none' }} />
                  )}
                </>
              ) : artworkError ? (
                <CenterAlignStack sx={{ width: '100%' }}>
                  <ArtworkError artwork={artwork} />
                </CenterAlignStack>
              ) : (
                <CenterAlignBox sx={{ gap: '1.6rem' }}>
                  {Array(3)
                    .fill(0)
                    .map((s, i) => {
                      return (
                        <Skeleton key={i} width="20rem" height="20rem" sx={{ transform: 'none' }} />
                      )
                    })}
                </CenterAlignBox>
              )}
            </Box>

            <BannerArtworkControl
              artwork={artwork}
              setOpen={setOpen}
              setEditorDialogArtwork={setEditorDialogArtwork}
              portfolioId={portfolioId}
            />
            {/* <Box sx={{ display: 'flex' }}>
            <Box className="artwork-prompt">
              <CenterAlignBox className="prompt-title">프롬프트</CenterAlignBox>
              <Stack className="prompt-box">
                <Typography sx={{ fontSize: '1.4rem', fontWeight: 500, lineBreak: 'anywhere' }}>
                  {translatePromptEnToKo(artworkConfig.promptObj)}
                </Typography>
              </Stack>
            </Box>
            <Stack className="button-box">
              <Box sx={{ display: 'flex', gap: '1.6rem' }}>
                <Button
                  sx={{
                    width: '14rem',
                    height: '4.8rem',
                    borderRadius: '4px',
                    fontSize: '2rem',
                    fontWeight: 700,
                  }}
                  variant="contained"
                  onClick={() => {
                    setOpen(true)
                    setEditorDialogArtwork(artwork)
                  }}
                  disabled={artworkLoading || artwork.pieces?.length === 0}
                >
                  수정
                </Button>

                <CreateButton
                  sx={{
                    width: '20rem',
                    height: '4.8rem',
                    borderRadius: '4px',
                    fontSize: '2rem',
                    fontWeight: 700,
                  }}
                  disabled={artworkLoading || artwork.pieces?.length === 0}
                  onClick={allDownload}
                >
                  다운로드
                </CreateButton>
              </Box>
              <Box
                sx={{
                  mt: '1.4rem',
                  fontWeight: 500,
                  fontSize: '1.4rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                Draph ads &nbsp;
                <Box
                  sx={{
                    backgroundColor: '#303030',
                    color: 'white',
                    p: '0.5rem 1.2rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '4px',
                    fontSize: '1.2rem',
                  }}
                >
                  powered by Draph art
                </Box>
              </Box>
            </Stack>
          </Box> */}
          </Stack>
        </Stack>
      </Box>
    </motion.div>

    // <TableRow>
    //   <TableCell padding="checkbox">
    //     <Checkbox checked={isChecked} onChange={onCheckboxChange} />
    //   </TableCell>
    //   <TableCell>
    //     <Box sx={{ width: '42rem', gap: '2rem', display: 'flex', flexFlow: 'wrap' }}>
    //       {artwork?.pieces.map((p, idx) => {
    //         return (
    //           <div
    //             key={p.path}
    //             onClick={() => {
    //               showDialog(artwork, idx, { pieceImageSize })
    //             }}
    //           >
    //             <Box
    //               component="img"
    //               src={getS3ImageSrc(p.path)}
    //               style={{
    //                 width: '20rem',
    //                 height: '20rem',
    //                 objectFit: 'contain',
    //                 cursor: 'pointer',
    //               }}
    //               onLoad={e => {
    //                 onLoadPieceImage(e, p)
    //               }}
    //             />
    //           </div>
    //         )
    //       })}
    //     </Box>
    //   </TableCell>
    //   <TableCell>
    //     <Typography variant="body1">아트워크 이름: {artwork.name}</Typography>
    //     <Typography variant="body2">ID: {artwork.id}</Typography>
    //     <Typography variant="body2">순서: {idx}</Typography>
    //   </TableCell>
    //   <TableCell>
    //     <Button
    //       sx={{
    //         width: '14rem',
    //         height: '4.8rem',
    //         borderRadius: '4px',
    //         fontSize: '2rem',
    //         fontWeight: 700,
    //       }}
    //       variant="contained"
    //       onClick={() => {
    //         setOpen(true)
    //         setEditorDialogArtwork(artwork)
    //       }}
    //     >
    //       수정
    //     </Button>
    //   </TableCell>
    // </TableRow>
  )
}

export default BannerArtworkRow
