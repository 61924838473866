import { Box, Typography, Stack, Card } from '@mui/material'
import {
  ScrollToTop,
  ArtworkList,
  UploadImageDialog,
  ArtworkFilters,
  UploadWrapper,
  DragDropFullSize,
  EmptyArtworkList,
  CenterAlignStack,
  OutputField,
  SmallTextField,
  CenterAlignBox,
  UploadHeader,
  BundleConfigComponent,
  GuideComponent,
  BgExpansionConfigComponent,
} from 'components'
import { styled, useTheme } from '@mui/material/styles'

import { useEffect, useState } from 'react'

import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  portfolioDetailAtom,
  userAtom,
  portfolioConfigAtom,
  artworkViewConfigAtom,
  userLikedBgAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  tutorialAtom,
  endpointAtom,
  artworkListsIsFoldedAtom,
  defaultPortfolioAtom,
  uploadDialogOpenAtom,
  dragDropOpenAtom,
  blockDragDropAtom,
} from 'atoms'
import { apis } from 'apis'
import { isPortfolioOwnerSelector } from 'selector'
import { getAccessToken } from 'utils/cookie'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import * as config from 'config'

import { PAGE_HEADER_HEIGHT, ScrollHandler } from './PortfolioDetail'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { EmptyPageHeader, EmptyUploadHeader, PageHeaderLayout } from './PortfolioUpload'

import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { BeforeAfterArrow } from 'theme/icon'
import {
  triggerGA4UploadEvent,
  triggerGA4UploadEventManualConfig,
} from '../components/portfolio/Header'
import { portfolioUploadConfigSelector } from '../selector'
import { usePortfolio } from 'hooks/usePortfolio'
import { getStaticResourceLanguage } from 'hooks/useLanguage'

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    // alignItems: 'center',
  },
}))

const uploadImglimit = 20

export default function BgExpansion() {
  const tutorial = useRecoilValue(tutorialAtom)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)

  const token = getAccessToken()

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'ai_canvas_exp', {})
    // ---------------------
    return () => {
      setBlockDragDrop(false)
    }
  }, [])

  if (!token)
    return (
      <RootStyle>
        <Mobile>
          <EmptyPageHeader />
        </Mobile>

        <Desktop>
          <EmptyUploadHeader videoSrc={config.GUIDE_VIDEOS.bg_expansion} />
        </Desktop>
        <EmptyArtworkList />
      </RootStyle>
    )

  return (
    <>
      {/* <Wrapper> */}
      <RootStyle
        style={{
          // overflow: tutorial.step === 15 ? 'hidden' : 'scroll',
          zIndex: tutorial.step === 17 ? '-1' : null,
        }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()

          if (!blockDragDrop) {
            setDragDropOpen(true)
          }
        }}
      >
        <>
          <UploadWrapper>
            <Desktop>
              <UploadHeader
                configComponent={<BgExpansionConfigComponent />}
                uploadButtonComponent={<BgExpansionConfigComponent uploadButtonOnly={true} />}
                gudieComponent={<GuideComponent />}
                /* 하단 2개가 들어가면 1개 모드로 변경  */
                multiple={false}
                uploadImglimit={1}
                configWidth="48rem"
              />
            </Desktop>
          </UploadWrapper>

          <Mobile>
            <EmptyArtworkList message={<MobileExample />} sx={{ pt: '3rem' }} />
          </Mobile>
          <ArtworkList upload={true} hideGroupHeader={true} />
        </>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />

      {dragDropOpen && !blockDragDrop && <DragDropFullSize uploadImgLimit={1} />}
      {/* </Wrapper> */}
    </>
  )
}

function PageHeader() {
  const { t } = useTranslation()
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()

  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const [user, setUser] = useRecoilState(userAtom)
  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetPortfolioConfig = useResetRecoilState(portfolioConfigAtom)
  const resetUploadDialogOpen = useResetRecoilState(uploadDialogOpenAtom)

  const [uploading, setUploading] = useState(false)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const outputSizeValid =
    portfolioConfig.outputSizeList[0] &&
    portfolioConfig.outputSizeList[0].w &&
    portfolioConfig.outputSizeList[0].h &&
    true

  useEffect(() => {
    return () => {
      resetPortfolioDetail()
      resetViewConfig()
      resetPortfolioConfig()
      resetUploadDialogOpen()
    }
  }, [])

  useEffect(() => {
    if (uploadOpen) {
      // ----- GA4 event -----
      window.gtag('event', 'ai_canvas_exp_begin', {})
      // ---------------------
    }
  }, [uploadOpen])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter])

  const handleUpload = async files => {
    const uploadFiles = await prepareUpload(files)
    if (!uploadFiles) return

    setUploadButtonDiasbled(true)
    setUploading(true)
    setUploadOpen(false)

    const bgExpansionConfig = {
      flag_gen_compo: false,
      flag_bg_expansion: true,
      flag_generate: true,
      output_size_list: portfolioConfig.outputSizeList.map(o => ({ output_w: o.w, output_h: o.h })),
    }

    // ----- GA4 event -----

    triggerGA4UploadEventManualConfig(
      {
        output_size_w: bgExpansionConfig.output_size_list[0].output_w,
        output_size_h: bgExpansionConfig.output_size_list[0].output_h,
        count: uploadFiles.length,
      },
      'ai_canvas_exp_upload'
    )
    // ---------------------

    const formData = await makeUploadFormData(uploadFiles, bgExpansionConfig)

    console.log('--------- start uploading')

    await apis.appfront
      .upload(formData)
      .then(() => {
        checkUserCredit()
        setUploading(false)
        setUploadButtonDiasbled(false)
        refreshArtworks()

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  return (
    <>
      <PageHeaderLayout
        headerProps={{
          sx: {
            opacity: isFolded.value ? 0 : 1,
            transition: 'all 0.2s ease',
          },
          zIndex: isFolded.value
            ? -1
            : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
            ? 'auto'
            : null,
        }}
        uploadButtonProps={{
          disabled: uploading || !isOwner || uploadButtonDisabled,
          onClick: () => {
            tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_1' }))
            setUploadOpen(true)
          },
        }}
        uploading={uploading}
        uploadDialog={
          uploadOpen && (
            <UploadImageDialog
              uploadImage={handleUpload}
              uploadButtonTitle={t('button.bg_expansion_upload')}
              notice={
                <CenterAlignStack
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: { lg: '1.4rem', xs: '1.2rem' },
                      fontWeight: 400,
                      color: '#595959',
                      lineHeight: 'normal',
                    },
                  }}
                >
                  <Desktop>
                    <Typography>{t('bg_expansion.upload_notice_1')}</Typography>
                    <Typography>{t('bg_expansion.upload_notice_2')}</Typography>
                  </Desktop>
                  <Mobile>
                    <Typography>{t('bg_expansion.upload_notice_1_mobile')}</Typography>
                    <Typography>{t('bg_expansion.upload_notice_2_mobile_a')}</Typography>
                    <Typography>{t('bg_expansion.upload_notice_2_mobile_b')}</Typography>
                  </Mobile>
                </CenterAlignStack>
              }
              example={<UploadInput />}
              actionDisabledCondition={!outputSizeValid}
            />
          )
        }
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
          />
        }
      ></PageHeaderLayout>
    </>
  )
}

const ExampleImage = () => (
  <>
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        '& .image': {
          height: { lg: '14.8rem', xs: '7.2rem' },
        },
      }}
      spacing={{ lg: '1.5rem', xs: '0.6rem' }}
    >
      <img className="image before" src="/static/images/bg_expansion_before.png" />
      <Desktop>
        <BeforeAfterArrow />
      </Desktop>
      <Mobile>
        <BeforeAfterArrow size="small" />
      </Mobile>
      <img className="image after" src="/static/images/bg_expansion_after.png" />
    </Stack>
  </>
)

const ExampleText = ({ sx }) => {
  const { t } = useTranslation()

  return (
    <Card
      sx={{
        borderRadius: '1rem',
        ml: { lg: '4rem !important', xs: '1rem' },
        px: { lg: '2.5rem', xs: '0.5rem' },
        '& .MuiTypography-root': {
          fontSize: { lg: '2rem', xs: '1rem' },
          fontWeight: 500,
          textAlign: { xs: 'center' },
          lineHeight: { lg: 'unset', xs: '1.5rem' },
        },
        boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.2)',
        height: { lg: '9.2rem', xs: '4.4rem' },
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Stack sx={{ height: '100%', justifyContent: 'center', alignItems: 'start' }}>
        <Typography>{t('bg_expansion.example_text_1')}</Typography>
        <Typography sx={{ '& span': { fontWeight: 700 } }}>
          {t('bg_expansion.example_text_2_a')}
          <span>{t('bg_expansion.example_text_2_b')}</span>
          {t('bg_expansion.example_text_2_c')}
        </Typography>
        <Typography>{t('bg_expansion.example_text_3')}</Typography>
      </Stack>
    </Card>
  )
}

const Guide = () => (
  <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center', mt: '1rem' }}>
    <ExampleImage />
    <ExampleText />
  </Stack>
)

function UploadInput() {
  const [minSizeWarning, setMinSizeWarning] = useState(false)
  const [maxSizeWarning, setMaxSizeWarnig] = useState(false)
  const [modified, setModified] = useState(false)

  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  useEffect(() => {
    if (portfolioConfig.outputSizeList.length < 1) {
      setPortfolioConfig(prev => {
        return { ...prev, outputSizeList: [{ w: null, h: null }] }
      })
    }
  }, [portfolioConfig])

  return (
    <>
      <CenterAlignStack sx={{ py: '2rem' }}>
        <Typography sx={{ fontSize: { lg: '1.8rem', xs: '1.6rem' }, fontWeight: 700, mb: '1rem' }}>
          {t('upload_config.image_size')}
          <sup
            style={{
              color: theme.palette.error.main,
              fontWeight: 500,
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              verticalAlign: 'text-top',
              marginLeft: '0.2rem',
            }}
          >
            &#42;
          </sup>
        </Typography>

        {portfolioConfig.outputSizeList.length > 0 && (
          <OutputField
            config={portfolioConfig}
            setConfig={setPortfolioConfig}
            setMinSizeWarning={setMinSizeWarning}
            setMaxSizeWarning={setMaxSizeWarnig}
            index={0}
            list={portfolioConfig.outputSizeList[0]}
            openMarket={false}
            required={true}
            modified={modified}
            setModified={setModified}
          />
        )}
      </CenterAlignStack>
    </>
  )
}

const MobileExample = () => {
  const { t, i18n } = useTranslation()
  return (
    <CenterAlignStack>
      <Typography>{t('banner_config.only_PC')}</Typography>
      <Guide />
    </CenterAlignStack>
  )
}
