import {
  Box,
  Typography,
  styled,
  useTheme,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Slide,
  CircularProgress,
} from '@mui/material'
import { apis } from 'apis'
import { userAtom, userCreditAtom, userSubscriptionIdAtom } from 'atoms'
import { CenterAlignBox, CenterAlignStack, CustomPagination } from 'components'
import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { MYPAGE_PERCENTAGE_WIDHT, MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import moment from 'moment'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
  getCurrencyFormatter,
  isKo,
  numberCommaFormatter,
  trasnferDate,
  utcToLocal,
} from 'utils/common'
import { useTranslation } from 'react-i18next'
import { usePricing } from 'hooks/usePricing'
import { English, Korean } from 'hooks/useLanguage'
import { CellTypography, HeaderName, StyledDataGrid, StyledDataGridMobile } from './UserCharge'

export function BespokeRequestHistory() {
  const postsPerPage = 10
  const theme = useTheme()

  const { getPlanDisplayName } = usePricing()
  const { t, i18n } = useTranslation()

  const [page, setPage] = useState(1)
  const [userCredit, setUserCredit] = useRecoilState(userCreditAtom)
  const [visiblePosts, setVisiblePosts] = useState([])
  const [history, setHistory] = useState(null)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null)

  const isMobile = useMobileMediaQuery()

  const columns = [
    {
      field: 'date',
      flex: 1.5,
      headerName: t('credit.paid_date'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        return params.value ? (
          <CellTypography className={`${params.row.payment_canceled ? 'canceled' : ''}`}>
            <Desktop>{trasnferDate(moment.utc(params.value).toDate(), 'dot', i18n)}</Desktop>
            <Mobile>{trasnferDate(moment.utc(params.value).toDate(), 'dotM', i18n)}</Mobile>
          </CellTypography>
        ) : (
          <></>
        )
      },
    },
    {
      field: 'type',
      headerName: t('credit.charged_details'),
      align: 'center',
      headerAlign: 'center',
      flex: 1.5,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        const creditTypeArr = params.value.split('_')
        let value = params.value
        if (['paygo', 'subscription'].includes(creditTypeArr[0])) {
          value = getPlanDisplayName(creditTypeArr[1], i18n.language)
        } else if (creditTypeArr[0].includes('coupon')) {
          value = isKo(i18n) ? params.row.note : 'coupon'
        } else if (creditTypeArr[0].includes('admin')) {
          value = isKo(i18n) ? params.row.note : 'admin'
        }
        return (
          <CellTypography className={`${params.row.payment_canceled ? 'canceled' : ''}`}>
            {t(`pricing.${value}`)}
          </CellTypography>
        )
      },
    },

    {
      field: 'paid',
      headerName: t('credit.charged_paid'),
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        const currency = params.row.currency ?? 'krw'
        const currencyFormatter = getCurrencyFormatter(currency)
        const v = currencyFormatter.format(params.value)

        const withComma = numberCommaFormatter(params.value)

        return (
          <CellTypography className={`${params.row.payment_canceled ? 'canceled' : ''}`}>
            <English>{!isNaN(params.value) ? v : '-'}</English>
            <Korean>{!isNaN(params.value) ? withComma : '-'} 원</Korean>
          </CellTypography>
        )
      },
    },
    {
      field: 'status',
      headerName: t('bespoke_history.status'),
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        return (
          <CellTypography>{params.value && t(`bespoke_history.${params.value}`)}</CellTypography>
        )
      },
    },
  ]

  const refreshData = () => {
    apis.user.getBespokeHistory().then(response => {
      setHistory(response.data)
    })
  }

  useEffect(() => {
    refreshData()
  }, [])

  useEffect(() => {
    if (history) {
      const startIndex = (page - 1) * postsPerPage
      const endIndex = startIndex + postsPerPage
      setVisiblePosts(history.slice(startIndex, endIndex))
    }
  }, [history, page])

  return (
    <>
      <CenterAlignStack>
        <CenterAlignStack
          sx={{ width: { lg: '88.4rem', xs: '32rem' }, mb: isKo(i18n) ? '2.6rem' : '1.5rem' }}
        >
          <Typography sx={{ fontWeight: 800, fontSize: '3rem' }}>
            {t('mypage_menu.bespoke_history')}
          </Typography>
        </CenterAlignStack>
        <Desktop>
          <CenterAlignStack sx={{ width: MYPAGE_PERCENTAGE_WIDHT, pl: MYPAGE_SIDE_MARGIN }}>
            <StyledDataGrid
              disableColumnMenu
              getRowHeight={() => 'auto'}
              rows={visiblePosts ?? []}
              components={{
                NoRowsOverlay: () => (
                  <>
                    <CenterAlignBox sx={{ py: '3rem' }}>{t('credit.empty_history')}</CenterAlignBox>
                  </>
                ),
              }}
              keepNonExistentRowsSelected
              columns={columns}
              pageSize={postsPerPage}
              // rowsPerPageOptions={[5, 10, 15]}
              autoHeight
              hideFooter
              initialState={{
                sorting: {},
              }}
            />

            <CustomPagination
              count={Math.ceil((history?.length ?? 0) / postsPerPage)}
              page={page}
              onChange={(e, value) => {
                setPage(value)
              }}
            />
          </CenterAlignStack>
        </Desktop>

        <Mobile>
          <CenterAlignStack sx={{ width: '32rem' }}>
            <StyledDataGridMobile
              disableColumnMenu
              getRowHeight={() => 'auto'}
              rows={visiblePosts ?? []}
              components={{
                NoRowsOverlay: () => (
                  <>
                    <CenterAlignBox sx={{ py: '3rem' }}>{t('credit.empty_history')}</CenterAlignBox>
                  </>
                ),
              }}
              keepNonExistentRowsSelected
              columns={columns}
              pageSize={postsPerPage}
              // rowsPerPageOptions={[5, 10, 15]}
              autoHeight
              hideFooter
              initialState={{
                sorting: {},
              }}
            />

            <CustomPagination
              count={Math.ceil((history?.length ?? 0) / postsPerPage)}
              page={page}
              onChange={(e, value) => {
                setPage(value)
              }}
            />
          </CenterAlignStack>
        </Mobile>
      </CenterAlignStack>
    </>
  )
}
