import { Box, Button, Collapse, IconButton, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import {
  ArtworkFilters,
  ArtworkList,
  BannerConfigComponent,
  BulkUploadComponent,
  CenterAlignBox,
  CenterAlignStack,
  CommerceButtonComponent,
  DevEndpointDialog,
  DragDropFullSize,
  EmptyArtworkList,
  GuideComponent,
  NaverProductButton,
  NaverProductListDialog,
  PortfolioUploadConfigComponent,
  ScrollToTop,
  UploadConfigDialog,
  UploadHeader,
  UploadImageDialog,
  UploadWrapper,
  triggerGA4UploadEvent,
} from 'components'
import { DropdownArrowIcon, SettingIcon } from 'theme/icon'

import { apis } from 'apis'
import {
  PORTFOLIO_CONFIG_DEFAULT,
  artworkListsIsFoldedAtom,
  artworkViewConfigAtom,
  autoUploadAtom,
  blockDragDropAtom,
  defaultPortfolioAtom,
  dragDropOpenAtom,
  endpointAtom,
  portfolioConfigAtom,
  portfolioDetailAtom,
  refImageAtom,
  tutorialAtom,
  uploadDialogOpenAtom,
  userAtom,
  userLikedBgAtom,
  welcomeDialogAtom,
} from 'atoms'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import {
  OutputConfig,
  PortfolioCategory,
  PortfolioConcept,
  UploadConfig,
} from 'components/portfolio/PortfolioConfigDialog'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BarLoader as Loader } from 'react-spinners'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { isPortfolioOwnerSelector, portfolioUploadConfigSelector } from 'selector'
import { Tutorial } from 'theme/Tutorial'
import { getAccessToken } from 'utils/cookie'
import { PortfolioConfigTabs } from '../components/portfolio/PortfolioConfig'
import { PAGE_HEADER_HEIGHT, ScrollHandler } from './PortfolioDetail'
import { useLocation, useNavigate } from 'react-router-dom'
import { NHNProductButton, NHNProductContainedButton, NHNProductListDialog } from './NHNCommerce'
import { Cafe24ProductButton, Cafe24ProductListDialog } from './Cafe24'
import { usePortfolio } from 'hooks/usePortfolio'
import { ArtworkListTemp } from '../components'
import { GUIDE_VIDEOS } from 'config'

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {},
}))

const PageHeaderStyle = styled(Box)(({ theme }) => ({
  top: 0,
  minHeight: 'auto',
  justifyContent: 'center',
  alignItems: 'end',

  bordercolor: theme.palette.common.black,
  background: theme.palette.common.white,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '3.5rem',
    minHeight: PAGE_HEADER_HEIGHT,

    paddingBottom: '3.8rem',
    display: 'flex',
    flexDirection: 'row',
  },
}))

const uploadImglimit = 20

export default function PortfolioUpload() {
  const tutorial = useRecoilValue(tutorialAtom)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)

  const [welcomeDialog, setWelcomeDialog] = useRecoilState(welcomeDialogAtom)
  const user = useRecoilValue(userAtom)

  const { state } = useLocation()
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()

  const { isNewUser } = state ?? {}

  const addTabsArray = [
    {
      default: t('bulk_upload'),
      mobile: t('bulk_upload'),
      component: <BulkUploadComponent />,
    },
  ]

  useEffect(() => {
    return () => {
      setBlockDragDrop(false)
    }
  }, [])

  useEffect(() => {
    if (isNewUser) {
      setWelcomeDialog({ open: true })

      // ----- GA4 event -----
      window.gtag('event', 'welcome_imp', {})
      // ---------------------
    }
  }, [isNewUser])

  const token = getAccessToken()
  if (!token)
    return (
      <RootStyle sx={{ pb: '15rem' }}>
        <Mobile>
          <EmptyPageHeader />
          <ConfigAccordionGroup blockInteraction={true} />
        </Mobile>

        <Desktop>
          <EmptyUploadHeader videoSrc={GUIDE_VIDEOS.upload} />
        </Desktop>
        <EmptyArtworkList />
      </RootStyle>
    )

  return (
    <>
      {/* <Wrapper> */}
      <RootStyle
        style={{
          // overflow: tutorial.step === 15 ? 'hidden' : 'scroll',
          zIndex: tutorial.step === 17 ? '-1' : null,
        }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()

          if (!blockDragDrop) {
            setDragDropOpen(true)
          }
        }}
      >
        {/* 모바일 되면 정리 필수 + 예외 고객 들은 PageHeader로 */}
        <>
          <UploadWrapper>
            {/* 삭제 할 것 */}
            {/* <PageHeader />
            <ConfigAccordionGroup /> */}
            <Desktop>
              <UploadHeader
                configComponent={<PortfolioUploadConfigComponent />}
                uploadButtonComponent={<PortfolioUploadConfigComponent uploadButtonOnly={true} />}
                gudieComponent={<GuideComponent />}
                configDelay={0.5}
                imageDelay={0.3}
                addTabsArray={user.grade === 'enterpriseB' && addTabsArray}
                commerceButton={<CommerceButtonComponent />}
              />
            </Desktop>
          </UploadWrapper>

          <Mobile>
            <PageHeader />
            <ConfigAccordionGroup />
          </Mobile>

          <ArtworkList upload={true} />
        </>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />
      {dragDropOpen && !blockDragDrop && <DragDropFullSize />}
      <UploadConfigDialog />
      {/* </Wrapper> */}
    </>
  )
}

function PageHeader() {
  const { t } = useTranslation()
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)
  const [user, setUser] = useRecoilState(userAtom)
  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)
  const [refImage, setRefImage] = useRecoilState(refImageAtom)

  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetPortfolioConfig = useResetRecoilState(portfolioConfigAtom)
  const resetUploadDialogOpen = useResetRecoilState(uploadDialogOpenAtom)
  const resetRefImage = useResetRecoilState(refImageAtom)

  const [uploading, setUploading] = useState(false)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [openEndpointDialog, setOpenEndpointDialog] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  const [modified, setModified] = useState(false)
  const [removeBgImageFile, setRemoveBgImageFile] = useRecoilState(autoUploadAtom)
  const resetRemoveBgImageFile = useResetRecoilState(autoUploadAtom)
  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)

  const isNCommerceUser = user.login_sns === 'naver_commerce'
  const isNHNCommerceUser = user.login_sns === 'nhn_commerce'
  const isCafe24CommerceUser = user.login_sns === 'cafe24_commerce'
  const isCommerceUser = isNCommerceUser || isNHNCommerceUser || isCafe24CommerceUser
  const [nProductDialogOpen, setNProductDialogOpen] = useState(false)
  const [nhnProductDialogOpen, setNhnProductDialogOpen] = useState(false)
  const [cafe24ProductDialogOpen, setCafe24ProductDialogOpen] = useState(false)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const isMobile = useMobileMediaQuery()

  // 모바일에서 refImage 가 있으면 업로드할 때 기본값 추가
  useEffect(() => {
    if (refImage.length) {
      setPortfolioConfig({
        ...portfolioConfig,
        theme_template: 'custom',
        theme_custom: '',
        bgComplex: true,
        bgGenerate: true,
        bgSimple: false,
      })
    }
  }, [refImage])

  useEffect(() => {
    if (removeBgImageFile.auto && !modified && !uploading && defaultPortfolio.id) {
      const defaultOption = {
        flag_generate: true,
        flag_complex_cmp: true,
        flag_simple_cmp: false,
        flag_white_cmp: false,

        output_size_w: 0,
        output_size_h: 0,

        selected_bg_ids: '',

        object_category: 'auto',
        object_sub_category: 'auto',

        flag_gen_compo: portfolioConfig.flagGenCompo,
        flag_bg_expansion: portfolioConfig.flagBgExpansion,
        flag_multiblob_sod: false,

        output_size_list: [],

        //
        theme_template: removeBgImageFile.theme_template ?? 'auto',
        theme_custom: '',
      }

      handleUpload(removeBgImageFile.file, 'upload', defaultOption)
      setModified(true)
    }
  }, [])

  useEffect(() => {
    // 포트폴리오 페이지 진입시 로컬스토리지에서 엔드포인트 정보 가져와 atom에 세팅

    // setPortfolioDetail(p => {
    //   return { ...p, config: { ...p.config, ...c } }
    // })

    apis.portfolio
      .getDefaultPortfolio()
      .then(response => {
        const d = response.data
        const c = removeBgImageFile.auto ? {} : d.config
        setPortfolioDetail({
          id: d.id,
          name: d.name,
          theme: d.theme,
          stat: d.stat,
          user_id: d.user_id,
          config: { ...PORTFOLIO_CONFIG_DEFAULT, name: d.name, ...c },
          is_default: d.is_default,
          // 가입시 생성되는 최초의 포트폴리오 대응
        })
      })
      .catch(error => {
        console.log(error.response.status)
      })

    apis.background.getLikedBackgrounds().then(response => {
      setUserLikedBg(response.data)
    })

    return () => {
      // resetPortfolioDetail()
      resetViewConfig()
      resetPortfolioConfig()
      resetUploadDialogOpen()
      resetRefImage()
    }
  }, [removeBgImageFile.auto])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter])

  const handleUpload = async (files, eventName = 'upload', addGenOption = {}) => {
    const uploadFiles = await prepareUpload(files)
    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)

    // ----- GA4 event -----
    triggerGA4UploadEvent(
      { ...uploadConfig, has_ref_image: Boolean(refImage.length) },
      user,
      uploadFiles.length,
      eventName
    )
    // ---------------------
    const mergeOption = { ...addGenOption, has_ref_image: Boolean(refImage.length) }

    const addFormData = refImage.length
      ? {
          ref_image: refImage[0]?.file ?? '',
        }
      : {}

    const formData = await makeUploadFormData(uploadFiles, mergeOption, addFormData)
    setUploadOpen(false)

    console.log('--------- start uploading')
    setUploading(true)
    apis.appfront
      .upload(formData)
      .then(() => {
        checkUserCredit()
        setUploading(false)
        setUploadButtonDiasbled(false)
        refreshArtworks()
        setModified(false)
        removeBgImageFile.auto && resetRemoveBgImageFile()

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  return (
    <Mobile>
      <PageHeaderLayout
        headerProps={{
          sx: {
            opacity: isFolded.value ? 0 : 1,
            transition: 'all 0.2s ease',
          },
          zIndex: isFolded.value
            ? -1
            : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
            ? 'auto'
            : null,
        }}
        buttonSx={{ width: { lg: isCommerceUser ? '22rem' : '26rem', xs: '18rem' } }}
        uploadButtonProps={{
          disabled: uploading || !isOwner || uploadButtonDisabled,
          onClick: () => {
            tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_1' }))
            setUploadOpen(true)

            const editedConfigForbody = { ...portfolioConfig }
            let configOutputlist = [...editedConfigForbody.outputSizeList] ?? []

            if (!portfolioConfig.outputWidth || !portfolioConfig.outputHeight) {
              editedConfigForbody.outputHeight = null
              editedConfigForbody.outputWidth = null
            }

            if (configOutputlist.length !== 0) {
              configOutputlist = configOutputlist.filter(o => o.w !== null && o.h !== null)
            }

            if (configOutputlist.length > 1) {
              editedConfigForbody.simpleBgColorList = [[255, 255, 255]]
            } else {
              editedConfigForbody.simpleBgColorList = PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList
            }

            editedConfigForbody.outputSizeList = configOutputlist

            const body = {
              new_name: user.username,
              theme: portfolioConfig.theme,
              config: { ...editedConfigForbody },
            }

            apis.portfolio.updatePortfolio(defaultPortfolio.id, body).then(response => {
              setPortfolioDetail({ ...portfolioDetail, ...response.data })
            })

            // ----- GA4 event -----
            window.gtag('event', 'upload_begin', {})
            // ---------------------
          },
        }}
        uploading={uploading}
        uploadDialog={
          uploadOpen && (
            <UploadImageDialog
              uploadImage={handleUpload}
              uploadButtonTitle={t('button.original_upload')}
              bulkUploadAble={user.grade === 'enterpriseB'}
            />
          )
        }
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
          />
        }
        secondButton={
          isCafe24CommerceUser ? (
            <>
              <Cafe24ProductButton
                sx={{ ml: { lg: '2rem', xs: '0.8rem' } }}
                text={isMobile ? '상품' : '상품 연동'}
                onClick={() => {
                  setCafe24ProductDialogOpen(true)
                }}
              />
              <Cafe24ProductListDialog
                open={cafe24ProductDialogOpen}
                setOpen={setCafe24ProductDialogOpen}
                uploadImage={handleUpload}
                uploading={uploading}
                setUploading={setUploading}
              />
            </>
          ) : isNCommerceUser ? (
            <>
              <NaverProductButton
                sx={{ ml: { lg: '2rem', xs: '0.8rem' } }}
                onClick={() => {
                  setNProductDialogOpen(true)
                }}
                text="상품 불러오기"
              />
              <NaverProductListDialog
                open={nProductDialogOpen}
                setOpen={setNProductDialogOpen}
                uploadImage={handleUpload}
                uploading={uploading}
                setUploading={setUploading}
              />
            </>
          ) : isNHNCommerceUser ? (
            <>
              <NHNProductContainedButton
                sx={{ ml: { lg: '2rem', xs: '0.8rem' } }}
                onClick={() => {
                  setNhnProductDialogOpen(true)
                }}
                text="상품 불러오기"
              />
              <NHNProductListDialog
                open={nhnProductDialogOpen}
                setOpen={setNhnProductDialogOpen}
                uploadImage={handleUpload}
                uploading={uploading}
                setUploading={setUploading}
              />
            </>
          ) : (
            <></>
          )
        }
      >
        <DevEndpointDialog open={openEndpointDialog} setOpen={setOpenEndpointDialog} />
      </PageHeaderLayout>
      {/* <Desktop>
        {process.env.NODE_ENV === 'development' && (
          <CenterAlignBox sx={{ width: '100%', position: 'relative' }}>
            <IconButton
              onClick={() => {
                setOpenEndpointDialog(true)
              }}
              sx={{ position: 'absolute', bottom: '1rem' }}
            >
              <SettingIcon color={Object.keys(endpoint).length > 0 ? 'red' : null} />
            </IconButton>
          </CenterAlignBox>
        )}
      </Desktop> */}
    </Mobile>
  )
}

export function ConfigAccordionGroup({ blockInteraction = false }) {
  const [value, setValue] = useState(0)
  const [isExpanded, setIsExpanded] = useState(true)
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const { t } = useTranslation()

  const tabs = [
    { default: t('upload_config.bg_theme'), mobile: t('upload_config.bg_theme_s') },
    { default: t('upload_config.product_category'), mobile: t('upload_config.product_category_s') },
    { default: t('upload_config.advanced_setting'), mobile: t('upload_config.advanced_setting') },
    // { default: t('upload_config.output_setting'), mobile: t('upload_config.output_setting_s') },
    // { default: t('upload_config.advanced_setting'), mobile: t('upload_config.advanced_setting_s') },
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (!isExpanded) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 250)
    }
  }, [isExpanded])

  return (
    <CenterAlignStack>
      <CenterAlignStack sx={{ width: { lg: '118.4rem', xs: '32rem' } }}>
        <PortfolioConfigTabs value={value} onChange={handleChange} tabs={tabs} />

        <Collapse in={isExpanded} sx={{ width: '100%' }}>
          <ConfigPanel value={value} blockInteraction={blockInteraction} />
        </Collapse>

        <Button
          sx={{
            width: { lg: '118.4rem', xs: '32rem' },
            height: { lg: '5.2rem', xs: '3.2rem' },
            backgroundColor: '#eeeeee',
            borderTopLeftRadius: '0rem',
            borderTopRightRadius: '0rem',
            borderBottomLeftRadius: '1rem',
            borderBottomRightRadius: '1rem',
            mb: { lg: '4rem', xs: '3.2rem' },
            transition: 'all 1s',
            '& svg': {
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(360deg)',
              transition: 'all 0.4s',
            },
          }}
          onClick={() => {
            setIsExpanded(i => !i)
          }}
        >
          <DropdownArrowIcon
            color={theme.palette.common.black}
            style={isMobile ? {} : { width: '3.2rem', height: '1.6rem' }}
          />
        </Button>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}

export function ConfigPanel({ value, blockInteraction }) {
  const [outputConfigModified, setOutputConfigModified] = useState(false)
  const { addClickInterceptor } = usePleaseLoginDialog()

  useEffect(() => {
    if (blockInteraction) {
      const target = document.querySelector('#config-panel-wrapper')
      if (target) addClickInterceptor(target)
    }
  }, [value])

  switch (value) {
    case 0:
      return (
        <Box id="config-panel-wrapper" sx={{ width: '100%' }}>
          <PortfolioConcept isUploadPortfolio={true} />
        </Box>
      )
    case 1:
      return <PortfolioCategory isUploadPortfolio={true} />

    case 2:
      return (
        <Box id="config-panel-wrapper" sx={{ width: '100%' }}>
          <OutputConfig
            isUploadPortfolio={true}
            modified={outputConfigModified}
            setModified={setOutputConfigModified}
          />
        </Box>
      )
    case 3:
      return (
        <Box id="config-panel-wrapper" sx={{ width: '100%' }}>
          <UploadConfig isUploadPortfolio={true} />
        </Box>
      )
  }
}

export function PageHeaderLayout({
  headerProps,
  uploadButtonProps,
  uploading,
  uploadDialog,
  artworkFilter,
  secondButton,
  buttonSx = {},
  children,
  comment = null,
}) {
  const { t } = useTranslation()

  return (
    <>
      <PageHeaderStyle {...headerProps}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { lg: 'flex-start', md: 'center', sm: 'center', xs: 'center' },
            alignItems: 'center',
            width: { lg: '35rem', xs: '100%' },
            px: 1,
          }}
        >
          <>
            <Box
              sx={{
                display: 'flex',
                width: { lg: '28rem', xs: '25.5rem' },
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                alignItems: 'center',
                minHeight: { lg: '3.8rem', xs: 0 },
              }}
            ></Box>
          </>
        </Box>

        <Box
          sx={{
            width: { lg: '50rem', xs: '100%' },
            mt: { lg: 0, xs: '2.5rem' },
            mb: { lg: 0, xs: '2.2rem' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <>
            <Tutorial step="14_4">
              <Tutorial step={14}>
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    width: { lg: '26rem', xs: '18rem' },
                    height: { lg: '6rem', xs: '4.2rem' },
                    fontSize: { lg: '2rem', xs: '1.5rem' },
                    fontWeight: 800,
                    borderWidth: { lg: '0.2rem', xs: '0.1rem' },
                    ...buttonSx,
                  }}
                  {...uploadButtonProps}
                >
                  {!uploading && t('button.upload_image')}
                  {uploading && <Loader />}
                </Button>
                {secondButton}
              </Tutorial>
            </Tutorial>
            {uploadDialog}
          </>
        </Box>
        <Desktop>{artworkFilter}</Desktop>
        {children}
      </PageHeaderStyle>
      {comment && <CenterAlignBox>{comment}</CenterAlignBox>}
    </>
  )
}

export function EmptyPageHeader() {
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  const { showPleaseLoginDialog, addClickInterceptor } = usePleaseLoginDialog()

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    const targets = document.querySelectorAll('.artwork-filter-component')
    targets.forEach(t => {
      addClickInterceptor(t)
    })
  }, [])

  return (
    <>
      <PageHeaderLayout
        uploadButtonProps={{
          onClick: () => {
            showPleaseLoginDialog()
          },
        }}
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
            disabled={true}
          />
        }
        comment={
          <Typography
            sx={{
              color: theme => theme.palette.draph.blue,
              fontSize: { lg: '1.6rem', xs: '1.3rem' },
              cursor: 'pointer',
              fontWeight: 700,
              position: 'relative',
              top: { lg: '-2.6rem', xs: '-1.5rem' },
              animation: 'fadeIn 1s infinite alternate',
            }}
            onClick={() => {
              // ----- GA4 event -----
              window.gtag('event', 'non_member_sign_up_now', {})
              // ---------------------
              navigate('/login')
            }}
          >
            {t('upload_header_comment')}
          </Typography>
        }
      />
    </>
  )
}

export function EmptyUploadHeader({ videoSrc, isModel }) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <UploadHeader
        configComponent={<BannerConfigComponent />}
        uploadButtonComponent={<BannerConfigComponent uploadButtonOnly={true} />}
        gudieComponent={<GuideComponent videoSrc={videoSrc} />}
        configDelay={0.5}
        imageDelay={0.3}
        commerceButton={<CommerceButtonComponent />}
        blockInteraction={true}
        isModel={isModel}
      />

      <Typography
        sx={{
          color: theme => theme.palette.draph.blue,
          fontSize: { lg: '1.6rem', xs: '1.3rem' },
          cursor: 'pointer',
          fontWeight: 700,
          position: 'absolute',
          right: '50%',
          bottom: '3.5rem',
          transform: 'translate(50%, 50%)',
          animation: 'fadeIn 1s infinite alternate',
        }}
        onClick={() => {
          // ----- GA4 event -----
          window.gtag('event', 'non_member_sign_up_now', {})
          // ---------------------
          navigate('/login')
        }}
      >
        {t('upload_header_comment')}
      </Typography>
    </Box>
  )
}
