import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import MainAppBar, { APPBAR_DESKTOP, APPBAR_MOBILE } from './main/MainAppBar'
import InfoSideBar from './InfoSideBar'

import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { Box, Stack } from '@mui/material'
import MainFooter, { FOOTER_DESKTOP, FOOTER_MOBILE } from './main/MainFooter'
import MainDownBar from './main/MainDownBar'
import { CenterAlignBox } from 'components'

export const INFO_APPBAR_MOBILE = '5.2rem'
export const INFO_PERCENTAGE_WIDHT = '88%'

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('lg')]: {
    paddingTop: INFO_APPBAR_MOBILE,
  },
}))

const MainStyle = styled('div')(({ theme }) => ({
  display: 'flex',

  alignItems: 'center',
  flexDirection: 'column',

  [theme.breakpoints.down('lg')]: {
    height: '100%',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: APPBAR_DESKTOP,
  },
}))

const OutletStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minHeight: `calc(100vh - ${APPBAR_DESKTOP})`,
    paddingTop: '7.8rem',
    paddingLeft: '6.4rem',
  },

  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // 최상단, 최하단 앱발 빼줌
    minHeight: `calc(100vh - ${APPBAR_MOBILE} - ${APPBAR_MOBILE} )`,
    alignItems: 'center',
  },
}))

const InfoStyle = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
}))

const MobileInfoStyle = styled(Stack)(({ theme }) => ({}))

export default function InfoLayout() {
  const [open, setOpen] = useState(false)
  return (
    <RootStyle>
      <MainAppBar onOpenSidebar={() => setOpen(true)} />

      {/* <MainSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}
      <MainStyle>
        <Desktop>
          <CenterAlignBox sx={{ width: '128rem' }}>
            <Box
              sx={{
                // background: 'pink',
                // -----
                width: '23.6rem',
                mr: '3.2rem',
                pt: '3.6rem',
              }}
            >
              <InfoSideBar />
            </Box>
            <InfoStyle>
              <OutletStyle>
                <Outlet />
              </OutletStyle>
            </InfoStyle>
          </CenterAlignBox>
          <MainFooter />
        </Desktop>

        <Mobile>
          <OutletStyle>
            <InfoSideBar />
            <Outlet />
            <MainFooter />
          </OutletStyle>
        </Mobile>
      </MainStyle>

      <Mobile>
        <MainDownBar />
      </Mobile>
    </RootStyle>
  )
}
