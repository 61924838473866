// material
import { alpha, styled, useTheme } from '@mui/material/styles'
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Skeleton,
  Divider,
} from '@mui/material'

import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'

import { MdMenuOpen } from 'react-icons/md'

import {
  TempLayoutDiv,
  UserInfoDrawer,
  CenterAlignBox,
  LanguageSelect,
  MenuDrawer,
  ServiceTitle,
  StyledLink,
  BackgroundSkeleton,
  CenterAlignStack,
} from 'components'

import { appMenuAtom, userAtom, languageAtom, tutorialAtom } from 'atoms'
import { useRecoilValue, useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil'

import { removeUserCookie, getAccessToken } from 'utils/cookie'
import { MHidden } from 'components/@material-extend'
import { BsBorderWidth } from 'react-icons/bs'
import { useEffect } from 'react'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { MypageIcon, PricingIcon } from 'theme/icon'
import { APPBAR_MOBILE } from './main/MainAppBar'
import { MOBILE_SECOND_APPBAR } from './InfoSideBar'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.common.white,
  top: APPBAR_MOBILE,
  boxShadow: 'none',
  borderBottom: '1px solid black',
  zIndex: 1,
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: `${MOBILE_SECOND_APPBAR} !important`,
  paddingLeft: '1rem',
  paddingRight: '1rem',
}))

export default function MobileMypageAppbar() {
  const { t, i18n } = useTranslation()
  const mypageMenu = [
    {
      id: 'subscriptions',
      title: t('mypage_menu.credit_and_subscriptions_short'),
      path: '/user/subscriptions',
    },
    {
      id: 'credit',
      title: t('mypage_menu.credit'),
      path: '/user/creditInfo',
    },
    // {
    //   id: 'background',
    //   title: t('mypage_menu.background'),
    //   path: '/user/background',
    // },
    {
      id: 'bespoke',
      title: t('mypage_menu.bespoke_history'),
      path: '/user/bespoke_history',
    },
    {
      id: 'coupon',
      title: t('mypage_menu.coupon'),
      path: '/user/coupon',
    },
    {
      id: 'userInfo',
      title: t('mypage_menu.user_info_change'),
      path: '/user/profile',
    },
  ]

  const location = useLocation()
  const theme = useTheme()

  return (
    <>
      <RootStyle>
        <Box
          sx={{
            overflowX: 'auto',
            minHeight: `${MOBILE_SECOND_APPBAR} !important`,
            py: '1.2rem',
            px: '4rem',
          }}
        >
          <Stack
            direction="row"
            sx={{
              flexWrap: 'nowrap',
              width: 'max-content',
            }}
            spacing="1.5rem"
          >
            {mypageMenu.map((item, idx) => (
              <StyledLink key={item.id} to={item.path}>
                <Typography
                  fontWeight={700}
                  fontSize="1.4rem"
                  sx={{
                    color: location.pathname.includes(item.path)
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                  }}
                >
                  {item.title}
                </Typography>
              </StyledLink>
            ))}
          </Stack>
        </Box>
      </RootStyle>
    </>
  )
}
